import { useMutation, useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import { Field, FormikProps } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import { cloneDeep } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { toast } from 'react-toastify';
import { GET_TIME_TRACKER_CONFIG } from '../../schema/config';
import { BudgetUnit } from '../../schema/graphql-global-types';
import { CREATE_TIME_TRACKER_PROJECT, UPDATE_TIME_TRACKER_ENTRY_BULK } from '../../schema/timeTracker';
import {
  createTimeTrackerProject,
  createTimeTrackerProjectVariables,
} from '../../schema/types/createTimeTrackerProject';
import { getTimeTrackerConfig } from '../../schema/types/getTimeTrackerConfig';
import { Product } from '../../schema/types/Product';
import { TimeTrackerProjectEntry } from '../../schema/types/TimeTrackerProjectEntry';
import {
  updateTimeTrackerEntryBulk,
  updateTimeTrackerEntryBulkVariables,
} from '../../schema/types/updateTimeTrackerEntryBulk';
import { toastErrorQuery } from '../../utils/error';
import { useViewer } from '../../utils/hooks';
import { getTimeTrackerEntryPrice, sToTime } from '../../utils/utils';
import CurrencyDisplay from '../common/CurrencyDisplay';
import DateFormat from '../common/DateFormat';
import NumberDisplay from '../common/NumberDisplay';
import UserAvatar from '../common/UserAvatar';
import ButtonDelete from '../form/ButtonDelete';
import ButtonEdit from '../form/ButtonEdit';
import FieldDatepicker from '../form/FieldDatepicker';
import FieldTime from '../form/FieldTime';
import TimeTrackerCompanyProjectField from './TimeTrackerCompanyProjectField';
import TimeTrackerProductField from './TimeTrackerProductField';
import TimeTrackerProductForm from './TimeTrackerProductForm';
import TimeTrackerUserForm from './TimeTrackerUserForm';

const today = new Date();

type Props = {
  refetch: () => void;
};

const TimeTrackerEntryForm: FunctionComponent<Props> = ({ refetch }) => {
  const { data } = useViewer();
  const { data: config } = useQuery<getTimeTrackerConfig>(GET_TIME_TRACKER_CONFIG);

  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [editing, setEditing] = useState<{ [key: number]: boolean }>({});

  const [mutate] = useMutation<updateTimeTrackerEntryBulk, updateTimeTrackerEntryBulkVariables>(
    UPDATE_TIME_TRACKER_ENTRY_BULK,
  );

  const [createProject] = useMutation<createTimeTrackerProject, createTimeTrackerProjectVariables>(
    CREATE_TIME_TRACKER_PROJECT,
  );

  return (
    <Field name="entries">
      {({ field, form }: { field: FieldInputProps<TimeTrackerProjectEntry[]>; form: FormikProps<any> }) => {
        const handleChange = (value: Product) => {
          const model: any = cloneDeep(field.value);
          model.push({
            user: data?.viewer.user,
            userId: data?.viewer.user?.id || '',
            date: today,
            durationSeconds: 0,
            description: '',
            product: value,
            productId: value.id,
          });
          form.setFieldValue('entries', model);
          handleEdit(model.length - 1)();
        };

        const handleEdit = (index: number) => () => {
          setEditing((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
          }));
        };

        const handleRemove = (index: number) => () => {
          const model = cloneDeep(field.value);
          const removed = model.splice(index, 1);
          setSelected(selected.filter((el) => el !== removed[0].id));
          form.setFieldValue('entries', model);
        };

        const handleSelectAll = () => {
          if (selectAll) {
            setSelectAll(false);
            setSelected([]);
          } else {
            setSelectAll(true);
            setSelected(field.value.map((el) => el.id));
          }
        };

        const handleSelect = (id: string) => () => {
          if (selected.includes(id)) {
            setSelected(selected.filter((el) => el !== id));
          } else {
            setSelected([...selected, id]);
          }
        };

        const handleBulkProject = async (value: any) => {
          if (!selected.length) return;

          let projectId;

          if (value.value.type === 'project') {
            projectId = value.value.id;
          } else {
            const project = await createProject({
              variables: {
                payload: {
                  customerId: value.value.id,
                  title: config?.timeTrackerConfig?.defaultProjectName || '',
                  budgetCount: 0,
                  budgetUnit: BudgetUnit.Currency,
                  enableInCustomerView: false,
                  specialRates: [],
                  entries: [],
                  addings: [],
                },
              },
            });

            projectId = project.data?.createTimeTrackerProject.timeTrackerProject.id || '';
          }

          try {
            await mutate({
              variables: {
                ids: selected,
                payload: {
                  projectId,
                },
              },
            });

            refetch();
            setSelectAll(false);
            setSelected([]);
            toast.success(i18n._(t`Success!`));
          } catch (e) {
            toastErrorQuery(e);
          }
        };

        const handleBulkProduct = async (value: Product) => {
          if (!selected.length) return;

          try {
            await mutate({
              variables: {
                ids: selected,
                payload: {
                  productId: value.id,
                },
              },
            });

            refetch();
            setSelectAll(false);
            setSelected([]);
            toast.success(i18n._(t`Success!`));
          } catch (e) {
            toastErrorQuery(e);
          }
        };

        return (
          <div className="time-tracker-entry-form">
            <div className="panel">
              <div className="block-title">
                <Trans>Add Entry</Trans>
              </div>

              <TimeTrackerProductField onSubmit={handleChange} />
            </div>

            <div className="custom-table mobile-no-table has-actions">
              {field.value.length > 0 && (
                <div className="columns custom-table-header">
                  <div className="column select">
                    <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                  </div>

                  <div className="column date">
                    <Trans>Date</Trans>
                  </div>

                  <div className="column duration">
                    <Trans>Duration</Trans>
                  </div>

                  <div className="column type">
                    <Trans>Type</Trans>
                  </div>

                  <div className="column user">
                    <Trans>User</Trans>
                  </div>

                  <div className="column total">
                    <Trans>Total</Trans>
                  </div>

                  <div className="column actions" />
                </div>
              )}

              {field.value.map((el, id) => (
                <div
                  className={classNames('columns custom-table-body', {
                    active: editing[id],
                    selected: selected.includes(el.id),
                  })}
                  key={id}
                >
                  {editing[id] ? (
                    <>
                      <div className="column select">
                        <input
                          type="checkbox"
                          className="mr-3"
                          checked={selected.includes(el.id)}
                          onChange={handleSelect(el.id)}
                        />
                      </div>

                      <div className="column date" data-title={i18n._(t`Date`)}>
                        <FieldDatepicker name={`entries[${id}].date`} />
                      </div>

                      <div className="column duration" data-title={i18n._(t`Duration`)}>
                        <FieldTime name={`entries[${id}].durationSeconds`} />
                      </div>

                      <div className="column type" data-title={i18n._(t`Type`)}>
                        <TimeTrackerProductForm name={`entries[${id}].product`} />
                      </div>

                      <div className="column user" data-title={i18n._(t`User`)}>
                        <TimeTrackerUserForm name={`entries[${id}].user`} />
                      </div>

                      <div className="column total" data-title={i18n._(t`Total`)}>
                        <NumberDisplay value={getTimeTrackerEntryPrice(form.values, el)} />
                      </div>

                      <div className="column actions">
                        <ButtonEdit onClick={handleEdit(id)} />
                      </div>

                      <div className="column full">
                        <Field type="text" className="form-control" name={`entries[${id}].description`} />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="column select">
                        <input
                          type="checkbox"
                          className="mr-3"
                          checked={selected.includes(el.id)}
                          onChange={handleSelect(el.id)}
                        />
                      </div>

                      <div className="column date" data-title={i18n._(t`Date`)}>
                        <Field name={`entries[${id}].date`}>
                          {({ field }: { field: any }) => {
                            return <DateFormat value={field.value} />;
                          }}
                        </Field>
                      </div>

                      <div className="column duration" data-title={i18n._(t`Duration`)}>
                        <Field name={`entries[${id}].durationSeconds`}>
                          {({ field }: { field: any }) => {
                            return `${sToTime(field.value)} h`;
                          }}
                        </Field>
                      </div>

                      <div className="column type" data-title={i18n._(t`Type`)}>
                        <Field name={`entries[${id}].product`}>
                          {({ field }: { field: any }) => {
                            return field.value.title;
                          }}
                        </Field>
                      </div>

                      <div className="column user" data-title={i18n._(t`User`)}>
                        <Field name={`entries[${id}].user`}>
                          {({ field }: { field: any }) => {
                            return (
                              <div className="user-info">
                                <UserAvatar user={field.value} />

                                <div className="name">{field.value.fullName}</div>
                              </div>
                            );
                          }}
                        </Field>
                      </div>

                      <div className="column total" data-title={i18n._(t`Total`)}>
                        <NumberDisplay value={getTimeTrackerEntryPrice(form.values, el)} />
                      </div>

                      <div className="column actions">
                        <ButtonEdit onClick={handleEdit(id)} />
                        <ButtonDelete onClick={handleRemove(id)} />
                      </div>

                      <Field name={`entries[${id}].description`}>
                        {({ field }: { field: any }) => {
                          if (!field.value) return null;

                          return <div className="column full">{field.value}</div>;
                        }}
                      </Field>
                    </>
                  )}
                </div>
              ))}
            </div>

            {selected.length > 0 && (
              <div className="panel">
                <div className="block-title">
                  <Trans>Assign Selected</Trans>
                </div>

                <div className="row">
                  <div className="form-group col-lg-6">
                    <label>
                      <Trans>Customer / Project</Trans>
                    </label>

                    <TimeTrackerCompanyProjectField onSubmit={handleBulkProject} />
                  </div>

                  <div className="form-group col-lg-6">
                    <label>
                      <Trans>Type</Trans>
                    </label>

                    <TimeTrackerProductField onSubmit={handleBulkProduct} />
                  </div>
                </div>
              </div>
            )}

            {field.value.length > 0 && (
              <div className="block-title text-right action-submit">
                <Trans>Total amount:</Trans>
                &nbsp;
                <CurrencyDisplay
                  value={field.value.reduce((a, b) => a + getTimeTrackerEntryPrice(form.values, b), 0)}
                />
              </div>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default TimeTrackerEntryForm;
