import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { format } from 'date-fns';
import React, { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import { AssetWithEventsAndResponses } from '../../schema/asset';
import { AssetEventType } from '../../schema/graphql-global-types';
import { GET_SIDEBAR_STATE } from '../../schema/sidebar';
import { sidebarState } from '../../schema/types/sidebarState';
import HistoryItem from '../common/HistoryItem';
import UserAvatar from '../common/UserAvatar';

type Props = {
  model: AssetWithEventsAndResponses & { displayOnCustomer: boolean };
};

const PersonAssetDashboard: FunctionComponent<Props> = ({ model }) => {
  const sidebar = useQuery<sidebarState>(GET_SIDEBAR_STATE);

  const fontColor = sidebar.data?.sidebarState.darkMode ? '#fff' : '#000';
  const backgroundColor = sidebar.data?.sidebarState.darkMode ? '#000' : '#fff';

  const dataset: any = {
    labels: [],
    type: 'line',
    datasets: [
      {
        label: i18n._(t`Downtime`),
        type: 'line',
        data: [],
        backgroundColor: 'rgba(240, 58, 100, 0.2)',
        borderColor: '#F03A64',
        borderWidth: 0,
        pointStyle: 'circle',
        pointRadius: 0,
        pointBorderColor: '#F03A64',
        pointBackgroundColor: '#fff',
      },
      {
        label: i18n._(t`Response Time`),
        type: 'line',
        data: [],
        backgroundColor: 'rgba(116, 89, 254, 0.2)',
        borderColor: '#7459FE',
        borderWidth: 2,
        pointStyle: 'circle',
        pointRadius: 2,
        pointBorderColor: '#7459FE',
        pointBackgroundColor: '#fff',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'index',
      titleFontSize: 12,
      titleFontColor: fontColor,
      bodyFontColor: fontColor,
      backgroundColor,
      bodyFontFamily: 'Montserrat',
      cornerRadius: 3,
      intersect: false,
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          const value = tooltipItem.datasetIndex === 1 ? `${tooltipItem.value} ms` : tooltipItem.label;
          return `${data.datasets[tooltipItem.datasetIndex].label}: ${value}`;
        },
      },
    },
    legend: {
      display: false,
      labels: {
        usePointStyle: true,
        fontFamily: 'Montserrat',
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor,
          },
          display: true,
          gridLines: {
            color: 'rgba(0,0,0,0.061)',
          },
          scaleLabel: {
            display: false,
            labelString: i18n._(t`Uptime`),
            fontColor,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor,
          },
          display: true,
          gridLines: {
            display: false,
            drawBorder: true,
          },
          scaleLabel: {
            display: true,
            labelString: i18n._(t`Milliseconds`),
            fontColor,
          },
        },
      ],
    },
    title: {
      display: false,
      text: 'Normal Legend',
    },
  };

  const list: any[] = [];
  let max = 0;

  model.responseTimes.forEach((el: any) => {
    if (max < el.value) max = el.value;

    list.push({
      date: new Date(el.time).getTime(),
      type: 'bar',
      value: el.value,
    });
  });

  model.events.forEach((el: any) => {
    if (el.type === AssetEventType.Down) {
      list.push({
        date: new Date(el.time).getTime(),
        type: 'line',
        value: max,
      });
    }
  });

  list.sort((a, b) => a.date - b.date);

  list.forEach((el, id) => {
    const time = format(el.date, 'HH:mm');

    if (dataset.labels.indexOf(time) === -1) {
      dataset.labels.push(time);
    }

    if (el.type === 'line') {
      dataset.datasets[0].data.push(el.value);
      dataset.datasets[1].data.push(dataset.datasets[1].data[id - 1] || 0);
    } else if (el.type === 'bar') {
      dataset.datasets[0].data.push(undefined);
      dataset.datasets[1].data.push(el.value);
    }
  });

  return (
    <div className="asset-dashboard">
      <div className="columns">
        {model.description && (
          <div className="column column-big">
            <div className="label-lg">
              <Trans>Public Description</Trans>
            </div>

            <div className="post-content">{model.description}</div>
          </div>
        )}

        <div className="column">
          <div className="label-lg">
            <Trans>Responsibilities</Trans>
          </div>

          <div className="row">
            {model.responsibleUsers.map((el, index) => (
              <div key={index} className="form-group text-break col-xl-6">
                <div className="user-info">
                  <UserAvatar user={el} />

                  <div className="name">{el.fullName}</div>
                </div>

                <a href={`mailto:${el.email}`} className="email">
                  {el.email}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="columns">
        {model.displayOnCustomer && (
          <div className="column column-big">
            <div className="monitor-charts">
              <Bar data={dataset} options={options} />
            </div>
          </div>
        )}

        {model.lastLog && (
          <div className="column">
            <div className="label-lg">
              <Trans>Last Log</Trans>
            </div>

            <HistoryItem
              key={model.lastLog.id}
              date={model.lastLog.createdAt}
              creator={model.lastLog.creator}
              content={model.lastLog.content}
              tags={model.lastLog.tags}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonAssetDashboard;
