import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { pick } from 'lodash';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import UserCreateForms from '../components/user/UserCreateForm';
import ErrorQuery from '../components/form/ErrorQuery';
import LoadingForm from '../components/common/LoadingForm';
import { getUser, getUserVariables } from '../schema/types/getUser';
import { GET_USER, UPDATE_USER, userKeys, validateUserForm } from '../schema/user';
import { updateUser, updateUserVariables } from '../schema/types/updateUser';
import { DefaultRouteParams } from '../utils/const';
import { toastErrorQuery } from '../utils/error';
import NotFoundPage from './NotFoundPage';

const UserUpdatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ match }) => {
  const [mutate] = useMutation<updateUser, updateUserVariables>(UPDATE_USER);

  const { data, loading, error } = useQuery<getUser, getUserVariables>(GET_USER, {
    variables: { query: match.params.id },
  });

  if (!data && loading) return <LoadingForm />;
  if (error) return <ErrorQuery error={error} />;
  if (!data) return <NotFoundPage />;

  return (
    <div className="user-form-page">
      <Formik
        enableReinitialize
        initialValues={data.user}
        validationSchema={yup.object().shape(validateUserForm)}
        onSubmit={async (values, { setSubmitting, setValues }) => {
          try {
            const payload: any = pick(values, userKeys);

            const result: any = await mutate({
              variables: {
                id: match.params.id,
                payload,
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              setValues(result.data.updateUser.user);
              toast.success(i18n._(t`Success!`));
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => <UserCreateForms loading={isSubmitting} />}
      </Formik>
    </div>
  );
};

export default withRouter(UserUpdatePage);
