import { forOwn, isObject, omit, padStart, remove } from 'lodash';
import { personAdminRoutes, personUserRoutes } from '../config/person-router';
import { adminRoutes, userRoutes } from '../config/router';
import { CompanyPermission, LoginMode, UserRole } from '../schema/graphql-global-types';
import { PersonCompany } from '../schema/types/PersonCompany';
import { TimeTrackerProject } from '../schema/types/TimeTrackerProject';
import { TimeTrackerProjectEntry } from '../schema/types/TimeTrackerProjectEntry';
import { TimeTrackerProjectSpecialRate } from '../schema/types/TimeTrackerProjectSpecialRate';
import { Viewer } from '../schema/types/Viewer';
import { defaultQuery } from './const';
import { getStorage } from './storage';

export const nl2br = (value?: string) => String(value).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1<br>$2`);

export const getPhone = (phone?: string) => phone && phone.replace(/[^A-Za-z0-9+]/g, '');

export const getFilter = (state: any, map: any = {}) => {
  return state.filtered.reduce((acc: any, cur: any) => {
    if (cur.value || cur.value === 0) {
      let label = map[cur.id] || cur.id;
      acc[label] = cur.value;
    }

    return acc;
  }, {});
};

export const getOrder = (state: any, map: any = {}, orderBy: string = defaultQuery.query.orderBy) => {
  let order = state.sorted[0] ? state.sorted[0].id : orderBy;

  return map[order] || order;
};

export const getRoutes = (viewer: Viewer) => {
  if (viewer.mode === LoginMode.User) {
    return getStorage('viewAs') ? personAdminRoutes : viewer.user!.role === UserRole.Admin ? adminRoutes : userRoutes;
  }

  return viewer.companies.some(
    (el: PersonCompany) => el.company.id === getStorage('companyId') && el.permission === CompanyPermission.Admin,
  )
    ? personAdminRoutes
    : personUserRoutes;
};

export const omitDeep = (obj: any, props: any) => {
  const isArray = Array.isArray(obj);
  const o: any = isArray ? [] : {};

  forOwn(obj, function (value, key) {
    if (isObject(value)) {
      o[key] = omitDeep(value, props);
    } else {
      o[key] = value;
    }
  });

  if (isArray) {
    remove(o, props);
    return o;
  } else {
    return omit(o, props);
  }
};

export const focusTitle = (index: number) => {
  setTimeout(() => {
    const item: any = document.querySelectorAll('.editor-item-title')[index];
    item?.focus();
  }, 10);
};

export const getTimeTrackerEntryPrice = (project: TimeTrackerProject, item: TimeTrackerProjectEntry) => {
  const time = item.durationSeconds / 3600;

  let projectRate: TimeTrackerProjectSpecialRate | undefined = project.specialRates?.find(
    (el: TimeTrackerProjectSpecialRate) => el.productId === item.productId,
  );

  if (!projectRate)
    projectRate = project.customer.timeTrackerSpecialRates?.find(
      (el: TimeTrackerProjectSpecialRate) => el.productId === item.productId,
    );

  if (projectRate) return time * projectRate.amount;

  return time * (item.product.price || 0);
};

export const msToTime = (s: number) => {
  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;

  return padStart(String(hrs), 2, '0') + ':' + padStart(String(mins), 2, '0');
};

export const sToTime = (second: number): string => msToTime(second * 1000);
