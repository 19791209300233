import { cloneDeep } from '@apollo/client/utilities';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, FormikProps } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import React, { FunctionComponent, useCallback, useState } from 'react';
import Modal from 'react-modal';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Company } from '../../schema/types/Company';
import { setStorage } from '../../utils/storage';
import AssetReminderForm from '../asset/AssetReminderForm';
import LogForm from '../common/LogForm';
import ButtonDelete from '../form/ButtonDelete';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';
import TimeTrackerRateForm from '../timeTracker/TimeTrackerRateForm';
import CompanyContracts from './CompanyContracts';
import CompanyDetailForm from './CompanyDetailForm';
import CompanyInfoForm from './CompanyInfoForm';
import CompanyLogs from './CompanyLogs';
import CompanyOffers from './CompanyOffers';
import CompanyPersonForm from './CompanyPersonForm';
import CompanyPersonLogs from './CompanyPersonLogs';
import CompanyResponsibleForm from './CompanyResponsibleForm';
import CompanyTickets from './CompanyTickets';

type Props = {
  loading?: boolean;
};

const CompanyUpdateForms: FunctionComponent<Props> = ({ loading }) => {
  const [showNumber, setShowNumber] = useState(false);
  const [confirmIndex, setConfirmIndex] = useState(0);

  const [showConfirm, setShowConfirm] = useState(false);
  const toggleShowConfirm = useCallback(() => setShowConfirm((prevState) => !prevState), [setShowConfirm]);
  const [includeSystemMessages, setIncludeSystemMessages] = useState(false);

  const handleLogin = useCallback(
    (item: Company) => () => {
      setStorage('companyId', item.id);
      setStorage('viewAs', item.name);
      window.location.href = '/';
    },
    [],
  );

  return (
    <Form>
      <div className="form-breadcrumbs-area">
        <NavLink to="/crm/company">
          <Trans>Company</Trans>
        </NavLink>

        <i className="fa fa-angle-left" />

        <Field name="id">
          {({ field }: { field: FieldInputProps<string> }) => {
            if (field.value) return <Trans>Edit company</Trans>;

            return <Trans>Add new company</Trans>;
          }}
        </Field>
      </div>

      <div className="table-actions form-table-actions">
        <div className="breadcrumbs-area">
          <Field name="name">
            {({ field }: { field: FieldInputProps<string> }) => {
              return <div className="main-title">{field.value}</div>;
            }}
          </Field>
        </div>

        <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save updates`)} loading={loading} />
      </div>

      <div className="row form-group">
        <div className="col-xl-6">
          <div className="d-flex justify-content-between">
            <label>
              <Trans>Number</Trans>
            </label>

            {!showNumber && (
              <div className="value">
                <Field name="idNumber">{({ field }: { field: any }) => <>{field.value}</>}</Field>

                <a data-tip data-for="change" className="ml-2" onClick={() => setShowNumber(true)}>
                  <i className="icon-edit" />
                </a>
                <ReactTooltip id="change">
                  <Trans>Change</Trans>
                </ReactTooltip>
              </div>
            )}
          </div>

          {showNumber && (
            <>
              <Field type="number" className="form-control" name="idNumber" />

              <FieldErrorMessage name="idNumber" />
            </>
          )}
        </div>

        <div className="col-xl-6">
          <div className="d-flex justify-content-end">
            <Field name="id">
              {({ form }: { form: any }) => (
                <button type="button" className="btn-link" onClick={handleLogin(form.values)}>
                  <Trans>Open as Customer</Trans>
                  <i className="icon-customer-view ml-1" />
                </button>
              )}
            </Field>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="row">
          <div className="col-xl-6 panel-column-left">
            <div className="card-header">
              <Trans>Company Details</Trans>
            </div>

            <CompanyDetailForm />
          </div>

          <div className="col-xl-6">
            <div className="card-header">
              <Trans>Contact Information</Trans>
            </div>

            <div className="card-body">
              <CompanyInfoForm />
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="row">
          <div className="col-xl-6 panel-column-left">
            <div className="card-header">
              <Trans>Company People</Trans>
            </div>

            <div className="form-group">
              <CompanyPersonForm />
            </div>
          </div>

          <div className="col-xl-6">
            <div className="card-header">
              <Trans>Special Rates</Trans>
            </div>

            <label className="form-label">
              <Trans>Add Special Rates</Trans>
            </label>

            <TimeTrackerRateForm name="timeTrackerSpecialRates" />

            <div className="row">
              <div className="col-sm-6">
                <label className="form-label">
                  <Trans>Add Special M365 Rates</Trans>
                </label>

                <div className="input-group">
                  <Field type="text" className="form-control" name="m365Margin" />

                  <div className="input-group-append">
                    <div className="input-group-text">%</div>
                  </div>
                </div>

                <FieldErrorMessage name="m365Margin" />
              </div>

              <div className="col-sm-6">
                <label className="form-label">
                  <Trans>Add Special Azure RI Rates</Trans>
                </label>

                <div className="input-group">
                  <Field type="text" className="form-control" name="azureRIMargin" />

                  <div className="input-group-append">
                    <div className="input-group-text">%</div>
                  </div>
                </div>

                <FieldErrorMessage name="azureRIMargin" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="row">
          <div className="col-xl-6 panel-column-left">
            <div className="card-header">
              <Trans>Ticket System Domains</Trans>
            </div>

            <div className="form-group">
              <div className="form-group">
                <Field name="ticketDomains">
                  {({ field, form }: { field: any; form: FormikProps<any> }) => {
                    const handleAdd = () => {
                      const value = cloneDeep(field.value);
                      value.push('');
                      form.setFieldValue('ticketDomains', value);
                    };

                    const handleRemove = (index: number) => () => {
                      const value = cloneDeep(field.value);
                      value.splice(index, 1);
                      form.setFieldValue('ticketDomains', value);
                    };

                    const handleBlur = (index: number) => () => {
                      if (form.values.ticketDomains[index]) {
                        setConfirmIndex(index);
                        toggleShowConfirm();
                      }
                    };

                    const handleConfirm = () => {
                      toggleShowConfirm();
                    };

                    const handleCancel = () => {
                      handleRemove(confirmIndex)();
                      toggleShowConfirm();
                    };

                    return (
                      <>
                        {field.value.map((el: any, index: number) => (
                          <div key={index} className="form-group d-flex align-items-center">
                            <Field
                              name={`ticketDomains[${index}]`}
                              className="form-control"
                              placeholder="example.com"
                              onBlur={handleBlur(index)}
                            />

                            <div className="ml-4">
                              <ButtonDelete onClick={handleRemove(index)} />
                            </div>
                          </div>
                        ))}

                        <div className="form-group">
                          <button type="button" className="btn btn-link" onClick={handleAdd}>
                            <Trans>Add Domain</Trans>
                          </button>
                        </div>

                        <Modal
                          isOpen={showConfirm}
                          className="Modal modal-open"
                          overlayClassName="Overlay"
                          closeTimeoutMS={200}
                        >
                          <div className="modal fade show" style={{ display: 'block' }}>
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h2 className="modal-title">
                                    <Trans>Confirm</Trans>
                                  </h2>
                                  <button type="button" className="close" aria-label="Close" onClick={handleCancel}>
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>

                                <div className="modal-body text-danger">
                                  {form.values.name} <Trans>will be able to see all tickets from e-mail senders</Trans>{' '}
                                  {form.values.ticketDomains[confirmIndex]},&nbsp;
                                  <Trans>please confirm.</Trans>
                                </div>

                                <div className="modal-footer">
                                  <button type="button" className="btn btn-info" onClick={handleCancel}>
                                    <Trans>Cancel</Trans>
                                  </button>

                                  <button type="button" className="btn btn-primary" onClick={handleConfirm}>
                                    <Trans>Confirm</Trans>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal>
                      </>
                    );
                  }}
                </Field>
              </div>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="card-header">
              <Trans>Responsibilities</Trans>
            </div>

            <CompanyResponsibleForm />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <Trans>Detailed information</Trans>
        </div>

        <div className="row">
          <div className="col-xl-4 panel-column-left">
            <CompanyOffers />
          </div>

          <div className="col-xl-4 panel-column-left">
            <CompanyContracts />
          </div>

          <div className="col-xl-4 panel-column-left">
            <CompanyTickets />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <Trans>Company Reminder</Trans>
        </div>
        <div className="card-body">
          <Field name="reminders">
            {({ field, form }: { field: any; form: FormikProps<any> }) => {
              const handleAdd = (model: any) => {
                const value = cloneDeep(field.value);
                value.push(model);
                form.setFieldValue('reminders', value);
              };

              const handleEdit = (model: any, index: number) => {
                const value = cloneDeep(field.value);
                value[index] = model;
                form.setFieldValue('reminders', value);
              };

              const handleRemove = (index: number) => {
                const value = cloneDeep(field.value);
                value.splice(index, 1);
                form.setFieldValue('reminders', value);
              };

              return (
                <AssetReminderForm items={field.value} onAdd={handleAdd} onEdit={handleEdit} onRemove={handleRemove} />
              );
            }}
          </Field>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <Trans>Log History</Trans>
        </div>

        <div className="history-item mb-3">
          <div className="row">
            <div className="form-group col-md-6">
              <label>
                <Trans>Internal note</Trans>
              </label>

              <Field component="textarea" className="form-control" name="manualLogV2.content" rows={3} />
            </div>

            <div className="form-group col-md-4">
              <label>
                <Trans>Tags for the internal note</Trans>
              </label>
              <LogForm />
            </div>

            <div className="form-group col-md-2">
              <label>&nbsp;</label>
              <br />
              <ButtonSubmit className="btn btn-link" title={i18n._(t`Add Log`)} loading={loading} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 panel-column-left">
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col-6">
                  <Trans>Log</Trans>
                </div>

                <div className="col-6 text-right">
                  <button
                    type="button"
                    className="text-info"
                    onClick={() => setIncludeSystemMessages((prevState) => !prevState)}
                  >
                    {!includeSystemMessages ? <Trans>Show system messages</Trans> : <Trans>Hide system messages</Trans>}
                  </button>
                </div>
              </div>
            </div>

            <Field name="id">
              {({ field }: { field: any }) => (
                <CompanyLogs companyId={field.value} includeSystemMessages={includeSystemMessages} />
              )}
            </Field>
          </div>

          <div className="col-xl-6">
            <div className="card-header">
              <Trans>Login</Trans>
            </div>

            <Field name="id">{({ field }: { field: any }) => <CompanyPersonLogs companyId={field.value} />}</Field>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default CompanyUpdateForms;
