import classnames from 'classnames';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { CompanyPerson } from '../../schema/types/CompanyPerson';
import ButtonDelete from '../form/ButtonDelete';
import UserSendModal from '../user/UserSendModal';

type Props = {
  index: number;
  primary: boolean;
  model: CompanyPerson;
  onPrimary: () => void;
  onRemove: () => void;
};

const PersonCompanyPersonFormItem: FunctionComponent<Props> = ({ index, primary, model, onPrimary, onRemove }) => {
  const [showSend, setShowSend] = useState(false);

  const toggleSend = useCallback(() => setShowSend((prevState) => !prevState), []);

  return (
    <div className="d-flex align-items-center">
      <div className="d-md-flex flex-grow-1 mr-3">
        <div className="form-group w-100 mb-2 mb-sm-0 mr-3">
          <label>
            <Trans>Person</Trans>
          </label>

          <input type="text" className="form-control" value={String(model.person.fullName)} disabled />
        </div>

        <div className="form-group w-100">
          <label data-tip data-for={`role-${index}`}>
            <Trans>Access Level</Trans>
          </label>
          <ReactTooltip id={`role-${index}`}>
            <Trans>Chose between Admin, User and no Portal Access</Trans>
            <br />
            <Trans>Users don't have access to Settings</Trans>
          </ReactTooltip>

          <Field className="form-control" component="select" name={`persons[${index}].permission`}>
            <option value="Admin">{i18n._(t`Admin`)}</option>
            <option value="User">{i18n._(t`User`)}</option>
            <option value="NoAccess">{i18n._(t`No Access`)}</option>
          </Field>
        </div>
      </div>

      <div className="form-group">
        <div className="col-form-label mt-2">
          <a data-tip data-for={`send-${index}`} className="button text-dark mr-3" onClick={toggleSend}>
            <i className="icon-send-email" />
          </a>
          <ReactTooltip id={`send-${index}`}>
            <Trans>(Re-) send reset password link for the Portal</Trans>
          </ReactTooltip>

          <UserSendModal show={showSend} userId={model.person.id} onClose={toggleSend} />

          <a
            data-tip
            data-for={`primary-${index}`}
            className={classnames('button text-dark mr-3', {
              primary,
            })}
            onClick={onPrimary}
          >
            {!primary ? <i className="icon-star" /> : <i className="icon-star---filled text-orange" />}
          </a>
          <ReactTooltip id={`primary-${index}`}>
            <Trans>Set a primary contact for this company</Trans>
          </ReactTooltip>

          <ButtonDelete onClick={onRemove} />
        </div>
      </div>
    </div>
  );
};

export default PersonCompanyPersonFormItem;
