import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { SEND_OFFER_EMAIL } from '../../schema/offer';
import { sendOfferEmail, sendOfferEmailVariables } from '../../schema/types/sendOfferEmail';
import { TextTemplateDetail } from '../../schema/types/TextTemplateDetail';
import { toastErrorQuery } from '../../utils/error';
import { validateRequired } from '../../utils/validations';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldCheckbox from '../form/FieldCheckbox';
import FieldEditor from '../form/FieldEditor';
import FieldErrorMessage from '../form/FieldErrorMessage';
import OfferTextTemplates from './OfferTextTemplates';
import { pick } from 'lodash';

type Props = {
  to: string;
  offerId: any;
  template?: any;
  show: boolean;
  onClose: () => void;
};

const transform = (template: TextTemplateDetail, to: string) => ({
  to,
  subject: template.title,
  content: template.content,
  variables: template.variables,
  copy: false,
  followUpReminder: true,
});

const OfferTextTemplateModal: FunctionComponent<Props> = ({ to, offerId, template, show, onClose }) => {
  const [model, setModel] = useState<any>(transform(template || {}, to));

  useEffect(() => {
    setModel(transform(template || {}, to));
  }, [template, to, setModel]);

  const handleSelect = useCallback((item: TextTemplateDetail) => setModel(transform(item, to)), [to, setModel]);

  const [send] = useMutation<sendOfferEmail, sendOfferEmailVariables>(SEND_OFFER_EMAIL);

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Send offer</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              enableReinitialize
              initialValues={model}
              validationSchema={yup.object().shape({
                to: validateRequired,
                subject: validateRequired,
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  const payload: any = pick(values, ['to', 'subject', 'content', 'copy', 'followUpReminder']);

                  const result: any = await send({
                    variables: {
                      id: offerId,
                      payload,
                    },
                  });

                  if (result.errors) {
                    toastErrorQuery(result.errors);
                  } else {
                    onClose();
                    resetForm();
                    toast.success(i18n._(t`Success!`));
                  }
                } catch (e) {
                  toastErrorQuery(e);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting, submitForm }) => {
                const handleSubmit = (e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  submitForm();
                };

                return (
                  <Form onSubmitCapture={handleSubmit}>
                    <div className="modal-body">
                      <div className="form-group">
                        <label>
                          <Trans>To</Trans>
                        </label>

                        <Field type="text" className="form-control" name="to" />

                        <FieldErrorMessage name="to" />
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <Trans>Subject</Trans>
                            </label>

                            <Field type="text" className="form-control" name="subject" />

                            <FieldErrorMessage name="subject" />
                          </div>
                        </div>

                        <div className="col-md-1 text-center text-xs">
                          <label>&nbsp;</label>
                          <br />
                          <label className="col-form-label form-label mt-1">
                            <Trans>or</Trans>
                          </label>
                        </div>

                        <div className="col-md-5">
                          <div className="form-group">
                            <label>
                              <Trans>Text Templates</Trans>
                            </label>

                            <OfferTextTemplates onSelect={handleSelect} />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <FieldEditor name="content" />
                      </div>

                      <div style={{ display: 'flex' }}>
                        <div className="form-group" style={{ marginRight: '1em' }}>
                          <FieldCheckbox name="copy" label={i18n._(t`Copy me`)} />
                        </div>

                        <div className="form-group">
                          <FieldCheckbox name="followUpReminder" label={i18n._(t`Add Followup Reminder`)} />
                        </div>
                      </div>

                      <Field name="variables">
                        {({ field, form }: { field: any; form: FormikProps<any> }) => {
                          const handleSelect = (item: string) => () => {
                            form.setFieldValue('content', `${form.values.content} {{${item}}}`);
                          };

                          if (!field.value || !field.value.length) return '';

                          return (
                            <div className="history-item">
                              <label className="mr-2">
                                <Trans>Variables</Trans>:
                              </label>
                              <a
                                className="badge text-dark mr-2"
                                onClick={handleSelect('generatedLink')}
                              >{`{{generatedLink}}`}</a>

                              {field.value.map((el: any, index: any) => (
                                <a
                                  key={index}
                                  className="badge text-dark mr-2"
                                  onClick={handleSelect(el)}
                                >{`{{${el}}}`}</a>
                              ))}
                            </div>
                          );
                        }}
                      </Field>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={onClose}>
                        <Trans>Close</Trans>
                      </button>

                      <ButtonSubmit
                        className="btn btn-primary ml-3"
                        title={i18n._(t`Send offer`)}
                        loading={isSubmitting}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OfferTextTemplateModal;
