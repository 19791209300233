import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import * as yup from 'yup';
import ButtonSubmit from '../components/form/ButtonSubmit';
import ErrorQuery from '../components/form/ErrorQuery';
import FieldErrorMessage from '../components/form/FieldErrorMessage';
import logo from '../img/logo_homer_150x150.svg';
import logoWhite from '../img/logo_homer_150x150_white.svg';
import { GET_VIEWER, LOGIN_OTP } from '../schema/auth';
import { LoginMode } from '../schema/graphql-global-types';
import { GET_SIDEBAR_STATE } from '../schema/sidebar';
import { loginOtp, loginOtpVariables } from '../schema/types/loginOtp';
import { sidebarState } from '../schema/types/sidebarState';
import { DefaultRouteParams } from '../utils/const';
import { setStorage } from '../utils/storage';
import useAuthLayout from '../utils/useAuthLayout';
import { validateRequired } from '../utils/validations';

const MfaPage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ match, history }) => {
  useAuthLayout();

  const client = useApolloClient();
  const sidebar = useQuery<sidebarState>(GET_SIDEBAR_STATE);

  const [mutate, { error }] = useMutation<loginOtp, loginOtpVariables>(LOGIN_OTP, {
    onCompleted: async (data2) => {
      if (data2) {
        setStorage('accessToken', data2.loginOtp.accessToken);

        const result: any = await client.query({
          query: GET_VIEWER,
          fetchPolicy: 'network-only',
        });

        document.querySelector('body')!.classList.remove('bg-gradient-primary');

        history.push(result.data.viewer.mode === LoginMode.User ? '/' : '/select-company');
      }
    },
  });

  return (
    <div className="auth-area">
      <div className="content">
        <div className="logo">
          {sidebar.data?.sidebarState.darkMode ? (
            <img src={logoWhite} alt="MultiCloud Center" style={{ width: '150px' }} />
          ) : (
            <img src={logo} alt="MultiCloud Center" style={{ width: '150px' }} />
          )}
        </div>

        <h1 className="main-title">
          <Trans>Enter OTP from Google Authenticator or Email/SMS</Trans>
        </h1>

        {error && <ErrorQuery error={error} />}

        <Formik
          initialValues={{
            otp: '',
          }}
          validationSchema={yup.object().shape({
            otp: validateRequired,
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await mutate({
                variables: {
                  payload: {
                    mfaToken: match.params.id,
                    otp: values.otp,
                  },
                },
              });
            } catch (e) {}

            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="form">
              <div className="form-group">
                <label>
                  <Trans>Password</Trans>
                </label>

                <div className="has-icon">
                  <i className="icon-password" />
                  <Field
                    className="form-control"
                    type="text"
                    name="otp"
                    autoComplete="one-time-code"
                    placeholder={i18n._(t`OTP`)}
                    autoFocus
                  />
                </div>
              </div>

              <FieldErrorMessage name="otp" />

              <div className="actions">
                <ButtonSubmit title={i18n._(t`Login`)} className="btn btn-primary btn-full" loading={isSubmitting} />
              </div>
            </Form>
          )}
        </Formik>

        <div className="form-group">
          <NavLink to="/login" className="text-primary">
            <Trans>Go to Login</Trans>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default withRouter(MfaPage);
