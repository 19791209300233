import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { GET_MESSAGE_CATEGORIES } from '../../schema/messageCategory';
import { getMessageCategories, getMessageCategoriesVariables } from '../../schema/types/getMessageCategories';
import { useVariables } from '../../utils/hooks';
import MultipleSelect from '../form/MultipleSelect';

type Props = {
  onChange: (value: []) => void;
};

const PersonMessageCategory: FunctionComponent<Props> = ({ onChange }) => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getMessageCategories, getMessageCategoriesVariables>(GET_MESSAGE_CATEGORIES, {
    variables,
  });

  if (!data && !loading) return <div />;

  return (
    <Formik
      initialValues={{
        categoryIds: [],
      }}
      onSubmit={() => {}}
    >
      {() => (
        <Form>
          <MultipleSelect
            name="categoryIds"
            search$={search$}
            loading={loading}
            data={data?.messageCategories.items}
            placeholder={i18n._(t`All Categories`)}
            onChange={onChange}
            nameField="name"
          />
        </Form>
      )}
    </Formik>
  );
};

export default PersonMessageCategory;
