import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import { GET_DOCUMENT_TAGS } from '../../schema/tag';
import { getDocumentTags, getDocumentTagsVariables } from '../../schema/types/getDocumentTags';
import { useVariables } from '../../utils/hooks';
import TagSelect from '../form/TagSelect';

type Props = {};

const DocumentationTagForm: FunctionComponent<Props> = () => {
  const { search$, search, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
      },
    },
  };

  const { data, loading } = useQuery<getDocumentTags, getDocumentTagsVariables>(GET_DOCUMENT_TAGS, {
    variables,
  });

  if (!data && !loading) return <div />;

  return (
    <TagSelect
      name="tagNames"
      search$={search$}
      loading={loading}
      data={data?.documentTags.items}
      placeholder={i18n._(t`Tags`)}
    />
  );
};

export default DocumentationTagForm;
