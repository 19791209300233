import gql from 'graphql-tag';
import { validateNumber, validateRequired } from '../utils/validations';
import { TAG } from './tag';
import { ProductDetail } from './types/ProductDetail';
import { ProductReport } from './types/ProductReport';

export const PRODUCT = gql`
  fragment Product on Product {
    id
    title
    price
    billing
    tags {
      ...Tag
    }
  }
  ${TAG}
`;

export const PRODUCTS = gql`
  fragment Products on ProductCollection {
    limit
    offset
    total
    items {
      ...Product
    }
  }
  ${PRODUCT}
`;

export const PRODUCT_REPORT = gql`
  fragment ProductReport on ProductReport {
    contractType {
      id
      name
    }
    product {
      id
      title
    }
    pieces
    contractTypeId @client
    productId @client
  }
`;

export const ExtendProductReport = {
  contractTypeId: (obj: ProductReport) => obj.contractType.id,
  productId: (obj: ProductReport) => obj.product.id,
};

export const GET_PRODUCTS = gql`
  query getProducts($query: ProductsQuery!) {
    products(query: $query) {
      ...Products
    }
  }
  ${PRODUCTS}
`;

export const GET_PRODUCTS_FOR_CONTRACT = gql`
  query getContractProducts($query: ProductsQuery!) {
    products(query: $query) {
      limit
      offset
      total
      items {
        ...Product
        reports {
          ...ProductReport
        }
      }
    }
  }
  ${PRODUCT}
  ${PRODUCT_REPORT}
`;

export const GET_PRODUCTS_FOR_FILTER = gql`
  query getFilterProducts($query: ProductsQuery!) {
    products(query: $query) {
      limit
      offset
      total
      items {
        id
        title
      }
    }
  }
`;

export const PRODUCT_DETAIL = gql`
  fragment ProductDetail on Product {
    id
    idNumber
    title
    price
    youtubeUrl
    billing
    content
    reactionTime
    tdArticle
    photos {
      id
      url
    }
    tags {
      ...Tag
    }
    reports {
      ...ProductReport
    }
    bexioAccountId
    timeTrackerEnabled
    tagNames @client
    photoIds @client
  }
  ${TAG}
  ${PRODUCT_REPORT}
`;

export const ExtendProduct = {
  tagNames: (obj: ProductDetail) => obj.tags.map((el) => el.name),
  photoIds: (obj: ProductDetail) => obj.photos.map((el) => el.id),
};

export const GET_PRODUCT = gql`
  query getProduct($query: ID!) {
    product(id: $query) {
      ...ProductDetail
    }
  }
  ${PRODUCT_DETAIL}
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct($payload: CreateProductPayload!) {
    createProduct(payload: $payload) {
      product {
        ...ProductDetail
      }
    }
  }
  ${PRODUCT_DETAIL}
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($id: ID!, $payload: UpdateProductPayload!) {
    updateProduct(id: $id, payload: $payload) {
      product {
        ...ProductDetail
      }
    }
  }
  ${PRODUCT_DETAIL}
`;

export const ARCHIVE_PRODUCT = gql`
  mutation archiveProduct($id: ID!) {
    archiveProduct(id: $id) {
      product {
        id
      }
    }
  }
`;

export const UNARCHIVE_PRODUCT = gql`
  mutation unarchiveProduct($id: ID!) {
    unarchiveProduct(id: $id) {
      product {
        id
      }
    }
  }
`;

export const validateProductForm = {
  title: validateRequired,
  price: validateNumber,
};
