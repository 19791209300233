import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { GET_COMPANIES_FOR_PERSON } from '../../schema/company';
import { getCompanies, getCompaniesVariables } from '../../schema/types/getCompanies';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {};

const DocumentationCustomerForm: FunctionComponent<Props> = () => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getCompanies, getCompaniesVariables>(GET_COMPANIES_FOR_PERSON, {
    variables,
  });

  if (!data && !loading) return <div />;

  return (
    <Field name="customers">
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (values: any) => {
          form.setFieldValue('customerIds', values ? values.map((el: any) => el.value) : []);
          form.setFieldValue(
            'customers',
            values
              ? values.map((el: any) => ({
                  id: el.value,
                  name: el.label,
                }))
              : [],
          );
        };

        const handleLoad = (value: string) => search$.next(value);

        const model = field.value
          ? field.value.map((el: any) => ({
              value: el.id,
              label: el.name,
            }))
          : null;

        const options = loading
          ? []
          : data!.companies.items.map((el: any) => ({
              value: el.id,
              label: el.name,
            }));

        return (
          <Select
            value={model}
            options={options}
            isLoading={loading}
            isMulti
            onChange={handleChange}
            onInputChange={handleLoad}
            placeholder={i18n._(t`All Customers`)}
            {...selectOptions}
          />
        );
      }}
    </Field>
  );
};

export default DocumentationCustomerForm;
