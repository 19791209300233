import { gql } from '@apollo/client';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Log_clientInfo } from '../../schema/types/Log';
import { dateTimeOptions } from '../../utils/const';
import { nl2br } from '../../utils/utils';
import ButtonDelete from '../form/ButtonDelete';
import linkifyHtml from 'linkifyjs/html';

import { client } from '../..';
import pdf from '../../img/pdf.svg';
import excel from '../../img/excel.svg';
import image from '../../img/image.svg';
import word from '../../img/word.svg';
import DateFormat from './DateFormat';
import UserAvatarName from './UserAvatarName';

export const storageObjectDownloadQuery = gql`
  query storageObjectDownloadUrl($storageId: ID!) {
    storageObject(id: $storageId) {
      url
    }
  }
`;

const getImage = (filename: string) => {
  const ext = filename.substring(filename.lastIndexOf('.') + 1);

  switch (ext) {
    case 'pdf':
      return pdf;
    case 'xls':
    case 'xlsx':
      return excel;
    case 'doc':
    case 'docx':
      return word;
    default:
      return image;
  }
};

type Props = {
  date?: string | number;
  content?: string | null;
  contentHtml?: string | null;
  creator?: any | null;
  tags?: any[] | null;
  clientInfo?: Log_clientInfo | null;
  attachments?: any[] | null;
  onRemove?: () => void;
};

const HistoryItem: FunctionComponent<Props> = ({
  date = 0,
  content,
  contentHtml = null,
  creator,
  tags,
  clientInfo,
  attachments,
  onRemove,
}) => {
  const [showHtml, setShowHtml] = useState(false);
  const iframe = useRef<any>(null);

  const toggleHtml = useCallback(() => setShowHtml((prevState) => !prevState), []);

  useEffect(() => {
    if (showHtml) {
      const doc = iframe.current.contentDocument
        ? iframe.current.contentDocument
        : iframe.current.contentWindow.document;
      doc.open('text/html');
      doc.write(contentHtml);
      doc.close();
    }
  }, [showHtml, contentHtml]);

  const [html, setHtml] = useState('');
  useEffect(() => {
    if (showHtml) setHtml('');

    const reg = /"cid:(.+)"/gi;

    content?.match(reg)?.forEach((match) => {
      client
        .query({ query: storageObjectDownloadQuery, variables: { storageId: match.slice(5, -1) } })
        .then((response) => {
          const imageUrl = response.data?.storageObject?.url;
          setHtml(content.replace(match, `"${imageUrl}"`));
        });
    });

    try {
      // Fix linkify bug https://github.com/Soapbox/linkifyjs/issues/301
      setHtml(linkifyHtml(nl2br(content || '')));
    } catch (e) {
      setHtml('');
    }
  }, [showHtml, content]);

  return (
    <div className="history-item list-group-item">
      <div className="meta">
        <div>
          {creator && (
            <div className="creator">
              <UserAvatarName user={creator} />
            </div>
          )}

          <div className="tags">
            {tags &&
              tags.map((el: any) => (
                <span key={el.id} className="badge">
                  {el.name}
                </span>
              ))}
          </div>
        </div>

        <div className="actions">
          {contentHtml && (
            <a onClick={toggleHtml} className="ml-2 text-primary">
              {!showHtml ? <Trans>View HTML</Trans> : <Trans>Hide HTML</Trans>}
            </a>
          )}

          <div className="ml-2 date">
            <DateFormat value={new Date(date)} format={dateTimeOptions} />
          </div>

          {onRemove && (
            <div className="ml-2">
              <ButtonDelete onClick={onRemove} />
            </div>
          )}
        </div>
      </div>

      <div className="text text-break">
        {showHtml ? (
          <iframe className="d-block w-100" ref={iframe} title="Content" style={{ height: 500 }} />
        ) : (
          <div className="post-content history-content" dangerouslySetInnerHTML={{ __html: html }} />
        )}

        {clientInfo && (
          <div className="post-content history-content">
            <Trans>Browser:</Trans> {clientInfo.browser}
            <br />
            <Trans>Device:</Trans> {clientInfo.device}
            <br />
            <Trans>IP:</Trans> {clientInfo.ip}
            <br />
            <Trans>OS:</Trans> {clientInfo.os}
          </div>
        )}

        {attachments && (
          <div className="attachments">
            {attachments.map((el) => (
              <a href={el.url} key={el.id} target="_blank" className="attachment">
                <img src={getImage(el.filename)} alt="" className="attachment-icons" />

                <div data-tip data-for={`attachment-${el.id}`} className="attachment-name text-ellipsis">
                  {el.filename}
                </div>

                <ReactTooltip id={`attachment-${el.id}`}>{el.filename}</ReactTooltip>
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoryItem;
