import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import { cloneDeep, get } from 'lodash';
import React, { FunctionComponent } from 'react';
import { Product } from '../../schema/types/Product';
import { TimeTrackerProjectAdding } from '../../schema/types/TimeTrackerProjectAdding';
import CurrencyDisplay from '../common/CurrencyDisplay';
import NumberDisplay from '../common/NumberDisplay';
import ButtonDelete from '../form/ButtonDelete';
import TimeTrackerProductField from './TimeTrackerProductField';

type Props = {};

const TimeTrackerAddingForm: FunctionComponent<Props> = () => {
  return (
    <Field name="addings">
      {({ field, form }: { field: FieldInputProps<TimeTrackerProjectAdding[]>; form: FormikProps<any> }) => {
        const handleChange = (value: Product) => {
          const model: any = cloneDeep(field.value);
          model.push({
            title: value.title,
            price: value.price,
            pieces: 1,
            discountPercent: 0,
            product: value,
            productId: value.id,
          });
          form.setFieldValue('addings', model);
        };

        const handleRemove = (index: number) => () => {
          const model = cloneDeep(field.value);
          model.splice(index, 1);
          form.setFieldValue('addings', model);
        };

        const subtotal = field.value.reduce((value: number, el: TimeTrackerProjectAdding) => {
          return el.price * el.pieces + value;
        }, 0);

        const total = field.value.reduce((value: number, el: TimeTrackerProjectAdding) => {
          return (el.price - (el.price * el.discountPercent) / 100) * el.pieces + value;
        }, 0);

        return (
          <div className="time-tracker-entry-form">
            <div className="panel mb-4">
              <div className="block-title">
                <Trans>Add Entry</Trans>
              </div>

              <TimeTrackerProductField onSubmit={handleChange} all />
            </div>

            {field.value.length > 0 && (
              <div className="custom-table">
                <div className="custom-table-header mb-1 row">
                  <div className="col-lg-6 mb-lg-0">
                    <Trans>Product</Trans>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="mb-lg-0 col-6 col-sm-3">
                        <Trans>Price</Trans>
                      </div>
                      <div className="col-6 col-sm-3">
                        <Trans>Pieces</Trans>
                      </div>
                      <div className="col-6 col-sm-3">
                        <Trans>Discount Percent</Trans>
                      </div>
                      <div className="col-5 col-sm-2">
                        <Trans>Total</Trans>
                      </div>
                      <div className="col" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {field.value.map((el, id) => (
              <div className="form-group row adding-entry" key={id}>
                <div className="col-lg-6 mb-2 mb-lg-0">
                  <Field
                    name={`addings[${id}].title`}
                    type="text"
                    className="form-control"
                    placeholder={i18n._(t`Product`)}
                  />
                </div>
                <div className="col-lg-6">
                  <div className="row align-items-center">
                    <div className="mb-2 mb-lg-0 col-6 col-sm-3">
                      <Field
                        name={`addings[${id}].price`}
                        type="text"
                        className="form-control"
                        placeholder={i18n._(t`Price`)}
                      />
                    </div>
                    <div className="col-6 col-sm-3">
                      <Field
                        name={`addings[${id}].pieces`}
                        type="text"
                        className="form-control"
                        placeholder={i18n._(t`Pieces`)}
                      />
                    </div>
                    <div className="col-6 col-sm-3">
                      <Field
                        name={`addings[${id}].discountPercent`}
                        type="text"
                        className="form-control"
                        placeholder={i18n._(t`Discount Percent`)}
                      />
                    </div>
                    <div className="col-5 col-sm-2">
                      <Field name={`addings[${id}].price`}>
                        {({ form }: { form: FormikProps<any> }) => {
                          const price = get(form.values, `addings[${id}].price`);
                          const pieces = get(form.values, `addings[${id}].pieces`);
                          const discountPercent = get(form.values, `addings[${id}].discountPercent`);
                          const value = (price - (price * discountPercent) / 100) * pieces;

                          return <NumberDisplay value={value} />;
                        }}
                      </Field>
                    </div>
                    <label className="col">
                      <ButtonDelete onClick={handleRemove(id)} />
                    </label>
                  </div>
                </div>
              </div>
            ))}

            {subtotal > 0 && (
              <div className="row">
                <div className="col-md-6 offset-md-6 text-right">
                  <div className="form-group border-bottom" />
                  <div className="form-group font-weight-bold">
                    <Trans>Subtotal</Trans>
                    &nbsp;
                    <CurrencyDisplay value={subtotal} />
                  </div>
                  <div className="block-title">
                    <Trans>Total</Trans>
                    &nbsp;
                    <CurrencyDisplay value={total} />
                  </div>
                  <div className="form-group font-italic text-xs">
                    (<Trans>Total Discount</Trans>
                    &nbsp;
                    <CurrencyDisplay value={subtotal - total} />)
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default TimeTrackerAddingForm;
