import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import NumberDisplay from '../common/NumberDisplay';
import ButtonDelete from '../form/ButtonDelete';
import { get } from 'lodash';

type Props = {
  name: string;
  onRemove: () => void;
};

const ContractInfoFormItem: FunctionComponent<Props> = ({ name, onRemove }) => {
  return (
    <>
      <td className="column title">
        <Field
          name={`${name}.title`}
          type="text"
          className="form-control"
          placeholder={i18n._(t`Product Name (short / title)`)}
        />
      </td>
      <td className="column number">
        <Field name={`${name}.price`} type="text" className="form-control" placeholder={i18n._(t`Price`)} />
      </td>
      <td className="column number">
        <Field name={`${name}.pieces`}>
          {({ field, form }: { field: any; form: FormikProps<any> }) => {
            const handleChange = (e: any) => {
              form.setFieldValue(`${name}.pieces`, e.target.value);

              const reportingProducts = form.values.reportingProducts;

              reportingProducts.forEach((el2: any, index2: number) => {
                if (
                  (el2.virtualProductId && el2.virtualProductId === get(form.values, `${name}.virtualProductId`)) ||
                  (el2.threadId && el2.threadId === get(form.values, `${name}.threadId`))
                ) {
                  form.setFieldValue(`reportingProducts[${index2}].pieces`, e.target.value);
                }
              });
            };

            return <input value={field.value} type="number" className="form-control" onChange={handleChange} />;
          }}
        </Field>
      </td>
      <td className="column number">
        <Field name={`${name}.discountPercent`} type="text" className="form-control" placeholder={i18n._(t`%`)} />
      </td>
      <td className="column total">
        <Field name={`${name}.price`}>
          {({ form }: { form: FormikProps<any> }) => {
            const price = get(form.values, `${name}.price`);
            const pieces = get(form.values, `${name}.pieces`);
            const discountPercent = get(form.values, `${name}.discountPercent`);
            const value = (price - (price * discountPercent) / 100) * pieces;

            return <NumberDisplay value={value} />;
          }}
        </Field>
      </td>
      <td className="column action">
        <ButtonDelete onClick={onRemove} />
      </td>
    </>
  );
};

export default ContractInfoFormItem;
