import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { omit, pick } from 'lodash';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import CompanyUpdateForms from '../components/company/CompanyUpdateForms';
import ErrorQuery from '../components/form/ErrorQuery';
import LoadingForm from '../components/common/LoadingForm';
import { companyKeys, GET_COMPANY, UPDATE_COMPANY, validateCompanyForm } from '../schema/company';
import { CompanyStatus } from '../schema/graphql-global-types';
import { UPDATE_COMPANY_TIME_TRACKER_SPECIAL_RATES } from '../schema/timeTracker';
import { getCompany, getCompanyVariables } from '../schema/types/getCompany';
import { updateCompany, updateCompanyVariables } from '../schema/types/updateCompany';
import {
  updateCompanyTimeTrackerSpecialRates,
  updateCompanyTimeTrackerSpecialRatesVariables,
} from '../schema/types/updateCompanyTimeTrackerSpecialRates';
import { DefaultRouteParams } from '../utils/const';
import { toastErrorQuery } from '../utils/error';
import { omitDeep } from '../utils/utils';
import NotFoundPage from './NotFoundPage';

const CompanyUpdatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ match }) => {
  const [mutate] = useMutation<updateCompany, updateCompanyVariables>(UPDATE_COMPANY);

  const { data, loading, error } = useQuery<getCompany, getCompanyVariables>(GET_COMPANY, {
    variables: { query: match.params.id },
  });

  const [updateRates] = useMutation<
    updateCompanyTimeTrackerSpecialRates,
    updateCompanyTimeTrackerSpecialRatesVariables
  >(UPDATE_COMPANY_TIME_TRACKER_SPECIAL_RATES);

  if (!data && loading) return <LoadingForm />;
  if (error) return <ErrorQuery error={error} />;
  if (!data) return <NotFoundPage />;

  return (
    <div className="company-form-page">
      <Formik
        enableReinitialize
        initialValues={data.company}
        validationSchema={yup.object().shape(validateCompanyForm)}
        onSubmit={async (values, { setSubmitting, setValues }) => {
          if (values.status === CompanyStatus.Customer && !values.invoiceEmail) {
            toast.error(i18n._(t`Invoice Email is mandatory`));
            setSubmitting(false);
            return;
          }

          try {
            const payload: any = omitDeep(pick(values, companyKeys), ['__typename']);
            payload.persons = payload.persons.map((el: any) => pick(el, ['personId', 'permission']));
            payload.ticketDomains = payload.ticketDomains.filter(Boolean);
            payload.heatLevel = Number(payload.heatLevel);
            payload.m365Margin = payload.m365Margin ? Number(payload.m365Margin) : -1;
            payload.azureRIMargin = payload.azureRIMargin ? Number(payload.azureRIMargin) : -1;
            payload.timeTrackerSpecialRates = payload.timeTrackerSpecialRates.map((el: any) => ({
              ...pick(el, ['productId', 'amount']),
              amount: Number(el.amount),
            }));
            payload.reminders = payload.reminders
              .filter((el: any) => !el.archivedAt)
              .map((el: any) => omit(el, ['archivedAt']));

            await updateRates({
              variables: {
                id: match.params.id,
                payload: payload.timeTrackerSpecialRates,
              },
            });

            const result: any = await mutate({
              variables: {
                id: match.params.id,
                payload,
              },
            });

            if (result.errors) {
              if (result.errors.length && result.errors[0].message === 'Conflicted') {
                toastErrorQuery([
                  {
                    message:
                      i18n._(t`This domain is already in use by:`) +
                      ' ' +
                      result.errors[0].extensions.exception.extra[0].company.name,
                  },
                ]);
              } else {
                toastErrorQuery(result.errors);
              }
            } else {
              setValues(result.data.updateCompany.company);
              toast.success(i18n._(t`Success!`));
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => <CompanyUpdateForms loading={isSubmitting} />}
      </Formik>
    </div>
  );
};

export default withRouter(CompanyUpdatePage);
