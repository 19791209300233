import Select from 'react-select';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import {
  GET_CURRENCY_CONFIG,
  GET_TIMEZONE_CONFIG,
  UPDATE_CURRENCY_CONFIG,
  UPDATE_TIMEZONE_CONFIG,
} from '../../schema/config';
import { getCurrencyConfig } from '../../schema/types/getCurrencyConfig';
import { getTimezoneConfig } from '../../schema/types/getTimezoneConfig';
import { updateCurrencyConfig, updateCurrencyConfigVariables } from '../../schema/types/updateCurrencyConfig';
import { updateTimezoneConfig, updateTimezoneConfigVariables } from '../../schema/types/updateTimezoneConfig';
import { timezones } from '../../utils/const';
import { toastErrorQuery } from '../../utils/error';
import ButtonSubmit from '../form/ButtonSubmit';

type Props = {
  show: boolean;
  onClose: () => void;
};

const ConfigurationCountryModal: FunctionComponent<Props> = ({ show, onClose }) => {
  const { data: timezone, refetch: refetchTimezone } = useQuery<getTimezoneConfig>(GET_TIMEZONE_CONFIG);

  const [updateTimezone] = useMutation<updateTimezoneConfig, updateTimezoneConfigVariables>(UPDATE_TIMEZONE_CONFIG);

  const { data: currency, refetch: refetchCurrency } = useQuery<getCurrencyConfig>(GET_CURRENCY_CONFIG);

  const [updateCurrency] = useMutation<updateCurrencyConfig, updateCurrencyConfigVariables>(UPDATE_CURRENCY_CONFIG);

  const initialValues = {
    timezoneConfig: timezone?.timezoneConfig || '',
    currencyConfig: currency?.currencyConfig.currency || '',
  };

  return (
    <>
      <div className="info">
        {timezone?.timezoneConfig && timezone.timezoneConfig.length > 0 && (
          <div className="column">
            <label className="form-label">
              <Trans>Timezone</Trans>
            </label>

            <div className="value">{timezone.timezoneConfig}</div>
          </div>
        )}

        {currency?.currencyConfig && currency.currencyConfig.currency.length > 0 && (
          <div className="column">
            <label className="form-label">
              <Trans>Currency</Trans>
            </label>

            <div className="value">{currency.currencyConfig.currency}</div>
          </div>
        )}
      </div>

      <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
        <div className="modal fade show" style={{ display: 'block' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title">
                  <Trans>Country Configuration</Trans>
                </h2>
                <button type="button" className="close" aria-label="Close" onClick={onClose}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    const result: any = await updateTimezone({
                      variables: {
                        timezone: values.timezoneConfig,
                      },
                    });

                    await updateCurrency({
                      variables: {
                        payload: {
                          currency: values.currencyConfig,
                        },
                      },
                    });

                    if (result.errors) {
                      toastErrorQuery(result.errors);
                    } else {
                      toast.success(i18n._(t`Success!`));
                      refetchTimezone();
                      refetchCurrency();
                      onClose();
                    }
                  } catch (e) {
                    toastErrorQuery(e);
                  }

                  setSubmitting(false);
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="modal-body">
                      <div className="form-group row">
                        <label className="col-form-label col-sm-2">
                          <Trans>Timezone</Trans>
                        </label>
                        <div className="col">
                          <Field name="timezoneConfig">
                            {({ field, form }: { field: any; form: FormikProps<any> }) => {
                              const handleChange = (value: any) => {
                                form.setFieldValue('timezoneConfig', value.value);
                              };

                              const model = field.value
                                ? {
                                    value: field.value,
                                    label: field.value,
                                  }
                                : null;

                              const options = timezones.map((el) => ({
                                value: el,
                                label: el,
                              }));

                              return (
                                <Select
                                  defaultValue={model}
                                  options={options}
                                  onChange={handleChange}
                                  placeholder={i18n._(t`Select...`)}
                                />
                              );
                            }}
                          </Field>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-form-label col-sm-2">
                          <Trans>Currency</Trans>
                        </label>
                        <div className="col">
                          <Field type="text" name="currencyConfig" className="form-control" />
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={onClose}>
                        <Trans>Close</Trans>
                      </button>

                      <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConfigurationCountryModal;
