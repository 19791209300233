import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Formik } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import PersonalTicketInternalCreateForms from '../components/personalTicket/PersonalTicketInternalCreateForms';
import { CREATE_INTERNAL_TICKET, validateInternalTicketForm } from '../schema/ticket';
import { createInternalTicket, createInternalTicketVariables } from '../schema/types/createInternalTicket';
import { CreateInternalTicketPayload } from '../schema/graphql-global-types';
import { getCompanyId } from '../utils/auth';
import { toastErrorQuery } from '../utils/error';
import { pick } from 'lodash';

const PersonTicketCreatePage: FunctionComponent = () => {
  const [show, setShow] = useState(true);

  const [mutate] = useMutation<createInternalTicket, createInternalTicketVariables>(CREATE_INTERNAL_TICKET);

  if (!show) return <div />;

  const initialValues: CreateInternalTicketPayload & { attachments: [] } = {
    subject: '',
    content: '',
    attachmentIds: [],
    attachments: [],
    companyId: getCompanyId(),
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yup.object().shape(validateInternalTicketForm)}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          const result: any = await mutate({
            variables: {
              payload: pick(values, ['subject', 'content', 'attachmentIds', 'companyId']),
            },
          });

          if (result.errors) {
            toastErrorQuery(result.errors);
          } else {
            toast.success(i18n._(t`Success!`));
            resetForm();

            setTimeout(() => {
              setShow(true);
            }, 10);
            setShow(false);
          }
        } catch (e) {
          toastErrorQuery(e);
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => <PersonalTicketInternalCreateForms loading={isSubmitting} />}
    </Formik>
  );
};

export default PersonTicketCreatePage;
