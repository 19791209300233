import { i18n } from '@lingui/core';
import React, { FC } from 'react';

type Props = {
  value: number;
  format?: Intl.NumberFormatOptions;
};

const NumberFormat: FC<Props> = ({ value, format }) => <>{i18n.number(value, format)}</>;

export default NumberFormat;
