import { t } from '@lingui/macro';
import gql from 'graphql-tag';
import * as yup from 'yup';
import { validateRequired } from '../utils/validations';
import { ASSET_REMINDER } from './asset';
import { LOG, LOGS } from './log';
import { OFFER_COMPANY } from './offer';
import { TAG } from './tag';
import { TIME_TRACKER_PROJECT_SPECIAL_RATE } from './timeTracker';
import { CompanyDetail } from './types/CompanyDetail';
import { CompanyPerson } from './types/CompanyPerson';

export const COMPANY = gql`
  fragment Company on Company {
    id
    name
    primaryPerson {
      id
      fullName
    }
    generalPhone
    generalEmail
    lastLog {
      ...Log
    }
    status
    heatLevel
  }
  ${LOG}
`;

export const COMPANIES = gql`
  fragment Companies on CompanyCollection {
    limit
    offset
    total
    items {
      ...Company
    }
  }
  ${COMPANY}
`;

export const GET_COMPANIES = gql`
  query getCompanies($query: CompaniesQuery!) {
    companies(query: $query) {
      ...Companies
    }
  }
  ${COMPANIES}
`;

export const GET_COMPANIES_FOR_PERSON = gql`
  query getPersonCompanies($query: CompaniesQuery!) {
    companies(query: $query) {
      limit
      offset
      total
      items {
        id
        name
        logo {
          id
          url
        }
      }
    }
  }
`;

export const GET_COMPANIES_FOR_TIME_TRACKER = gql`
  query getTimeTrackerCompanies($query: CompaniesQuery!) {
    companies(query: $query) {
      limit
      offset
      total
      items {
        id
        name
        timeTrackerProjects(query: { limit: 5, orderBy: id, orderDirection: Desc, filter: { archived: false } }) {
          items {
            id
            title
          }
        }
      }
    }
  }
`;

export const GET_COMPANIES_FOR_FILTER = gql`
  query getFilterCompanies($query: CompaniesQuery!) {
    companies(query: $query) {
      limit
      offset
      total
      items {
        id
        name
        responsibleUsers {
          id
          fullName
        }
      }
    }
  }
`;

export const GET_COMPANIES_FOR_LOGIN = gql`
  query getLoginCompanies($query: CompaniesQuery!) {
    companies(query: $query) {
      items {
        id
        name
        logo {
          id
          url
        }
      }
    }
  }
`;

export const OFFER_COMPANIES = gql`
  fragment OfferCompanies on Company {
    ...OfferCompany
    responsibleUsers {
      id
      fullName
      firstName
      lastName
      function
      email
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
  }
  ${OFFER_COMPANY}
`;

export const GET_COMPANIES_FOR_OFFER = gql`
  query getOfferCompanies($query: CompaniesQuery!) {
    companies(query: $query) {
      limit
      offset
      total
      items {
        ...OfferCompanies
      }
    }
  }
  ${OFFER_COMPANIES}
`;

export const COMPANY_PERSON = gql`
  fragment CompanyPerson on CompanyPerson {
    company {
      id
      name
    }
    permission
    person {
      id
      fullName
    }
    personId @client
  }
`;

export const ExtendCompanyPerson = {
  personId: (obj: CompanyPerson) => obj.person.id,
  companyId: (obj: CompanyPerson) => obj.company.id,
};

export const COMPANY_DETAIL = gql`
  fragment CompanyDetail on Company {
    id
    idNumber
    name
    address1
    address2
    zip
    canton
    city
    country
    generalPhone
    generalEmail
    invoiceEmail
    website
    size
    responsibleUsers {
      id
      fullName
    }
    logo {
      id
      url
    }
    status
    tags {
      ...Tag
    }
    persons {
      ...CompanyPerson
    }
    primaryPerson {
      id
    }
    ticketDomains
    reminders {
      ...AssetReminder
    }
    heatLevel
    bexioNoVat
    timeTrackerSpecialRates {
      ...TimeTrackerProjectSpecialRate
    }
    tagNames @client
    responsibleUserIds @client
    manualLogV2 @client {
      content
      tagNames
    }
    m365Margin
    azureRIMargin
  }
  ${TAG}
  ${COMPANY_PERSON}
  ${ASSET_REMINDER}
  ${TIME_TRACKER_PROJECT_SPECIAL_RATE}
`;

export const ExtendCompany = {
  tagNames: (obj: CompanyDetail) => obj.tags.map((el) => el.name),
  responsibleUserIds: (obj: CompanyDetail) => obj.responsibleUsers.map((el) => el.id),
  manualLogV2: () => ({
    content: '',
    tagNames: [],
    __typename: 'ManualLogV2',
  }),
};

export const GET_COMPANY_CONTRACT = gql`
  query getCompanyContracts($id: ID!, $query: ContractsQuery!) {
    company(id: $id) {
      id
      contracts(query: $query) {
        limit
        offset
        total
        items {
          id
          name
          cycle
        }
      }
    }
  }
`;

export const GET_COMPANY_TICKET = gql`
  query getCompanyTickets($id: ID!, $query: TicketsQuery!) {
    company(id: $id) {
      id
      tickets(query: $query) {
        limit
        offset
        total
        items {
          id
          opener
          status
        }
      }
    }
  }
`;

export const GET_COMPANY_OFFER = gql`
  query getCompanyOffers($id: ID!, $query: OffersQuery!) {
    company(id: $id) {
      id
      offers(query: $query) {
        limit
        offset
        total
        items {
          id
          title
          createdAt
        }
      }
    }
  }
`;

export const GET_COMPANY = gql`
  query getCompany($query: ID!) {
    company(id: $query) {
      ...CompanyDetail
    }
  }
  ${COMPANY_DETAIL}
`;

export const GET_COMPANY_LOGS = gql`
  query getCompanyLogs($id: ID!, $query: LogsQuery!) {
    company(id: $id) {
      id
      logs(query: $query) {
        ...Logs
      }
    }
  }
  ${LOGS}
`;

export const GET_COMPANY_PERSON_LOGS = gql`
  query getCompanyPersonLogs($id: ID!, $query: LogsQuery!) {
    company(id: $id) {
      id
      personLoginLogs(query: $query) {
        ...Logs
      }
    }
  }
  ${LOGS}
`;

export const EXTERNAL_COMPANY = gql`
  fragment ExternalCompany on ExternalCompany {
    address1
    address2
    zip
    city
    canton
    generalEmail
    generalPhone
    name
  }
`;

export const GET_EXTERNAL_COMPANIES = gql`
  query getExternalCompanies($query: String!) {
    searchExternalCompany(query: $query) {
      ...ExternalCompany
    }
  }
  ${EXTERNAL_COMPANY}
`;

export const PERSON_COMPANY_DETAIL = gql`
  fragment PersonCompanyDetail on Company {
    id
    idNumber
    name
    address1
    address2
    zip
    canton
    city
    country
    generalPhone
    generalEmail
    website
    size
    logo {
      id
      url
    }
    primaryPerson {
      id
      fullName
    }
    responsibleUsers {
      id
      fullName
      function
      cellphone
      companyPhone
      email
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
    ticketDomains
    persons {
      ...CompanyPerson
    }
  }
  ${COMPANY_PERSON}
`;

export const PERSON_GET_COMPANY = gql`
  query personGetCompany($query: ID!) {
    company(id: $query) {
      ...PersonCompanyDetail
    }
  }
  ${PERSON_COMPANY_DETAIL}
`;

export const CREATE_COMPANY = gql`
  mutation createCompany($payload: CreateCompanyPayload!) {
    createCompany(payload: $payload) {
      company {
        ...CompanyDetail
      }
    }
  }
  ${COMPANY_DETAIL}
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany($id: ID!, $payload: UpdateCompanyPayload!) {
    updateCompany(id: $id, payload: $payload) {
      company {
        ...CompanyDetail
      }
    }
  }
  ${COMPANY_DETAIL}
`;

export const PERSON_UPDATE_COMPANY = gql`
  mutation personUpdateCompany($id: ID!, $payload: UpdateCompanyPayload!) {
    updateCompany(id: $id, payload: $payload) {
      company {
        ...PersonCompanyDetail
      }
    }
  }
  ${PERSON_COMPANY_DETAIL}
`;

export const ARCHIVE_COMPANY = gql`
  mutation archiveCompany($id: ID!) {
    archiveCompany(id: $id) {
      company {
        id
      }
    }
  }
`;

export const UNARCHIVE_COMPANY = gql`
  mutation unarchiveCompany($id: ID!) {
    unarchiveCompany(id: $id) {
      company {
        id
      }
    }
  }
`;

export const GET_COMPANY_SELF_AUDIT = gql`
  query getCompanySelfAudit($query: ID!) {
    company(id: $query) {
      id
      selfAuditConfig {
        complianceRequirements
        contactMe
        itStrategy
        itStrategySupport
        assesment
        assesmentSupport
        proactiveAction
        proactiveActionSupport
        identification
        identificationSupport
        reaction
        reactionSupport
        recovery
        recoverySupport
      }
    }
  }
`;

export const UPDATE_COMPANY_SELF_AUDIT_CONFIG = gql`
  mutation updateCompanySelfAuditConfig($id: ID!, $payload: SelfAuditConfigPayload!) {
    updateCompanySelfAuditConfig(id: $id, payload: $payload) {
      company {
        id
      }
    }
  }
`;

export const companyKeys = [
  'name',
  'idNumber',
  'address1',
  'address2',
  'zip',
  'canton',
  'city',
  'country',
  'region',
  'generalPhone',
  'generalEmail',
  'invoiceEmail',
  'website',
  'size',
  'status',
  'logoId',
  'responsibleUserIds',
  'tagNames',
  'manualLogV2',
  'reminders',
  'primaryPersonId',
  'persons',
  'ticketDomains',
  'heatLevel',
  'bexioNoVat',
  'timeTrackerSpecialRates',
  'm365Margin',
  'azureRIMargin',
];

export const validateCompanyForm = {
  name: validateRequired,
  address1: validateRequired,
  m365Margin: yup
    .number()
    .nullable()
    .typeError(t`Invalid number`)
    .min(5, t`Must be at least 5`),
  azureRIMargin: yup
    .number()
    .nullable()
    .typeError(t`Invalid number`)
    .min(5, t`Must be at least 5`),
};
