import { useMutation } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { CONNECT_NINJA_RMM } from '../../schema/config';
import { connectNinjaRmm, connectNinjaRmmVariables } from '../../schema/types/connectNinjaRmm';
import { toastErrorQuery } from '../../utils/error';
import { validateRequired } from '../../utils/validations';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';
import NinjaCompanyForm from './NinjaCompanyForm';
import NinjaOrganizationForm from './NinjaOrganizationForm';

type Props = {
  onSuccess: () => void;
};

const NinjaForm: FunctionComponent<Props> = ({ onSuccess }) => {
  const [mutate] = useMutation<connectNinjaRmm, connectNinjaRmmVariables>(CONNECT_NINJA_RMM);

  const initialValues = {
    companyId: '',
    ninjaRmmOrganizationId: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        companyId: validateRequired,
        ninjaRmmOrganizationId: validateRequired,
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const result: any = await mutate({
            variables: {
              ...values,
            },
          });

          if (result.errors) {
            toastErrorQuery(result.errors);
          } else {
            toast.success(i18n._(t`Success!`));
            onSuccess();
          }
        } catch (e) {
          toastErrorQuery(e);
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="form-breadcrumbs-area">
            <NavLink to="/service/asset">
              <Trans>Managed Assets</Trans>
            </NavLink>

            <i className="fa fa-angle-left" />

            <Trans>Manage NinjaRMM</Trans>
          </div>

          <div className="table-actions form-table-actions">
            <div className="breadcrumbs-area">
              <div className="main-title">
                <Trans>Manage NinjaRMM</Trans>
              </div>
            </div>

            <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save, Connect & Sync`)} loading={isSubmitting} />
          </div>

          <div className="card mb-4">
            <div className="card-header">
              <Trans>Connect</Trans>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-6 form-group">
                  <label>
                    <Trans>ProCloud Center</Trans>
                  </label>
                  <NinjaCompanyForm />

                  <FieldErrorMessage name="companyId" />
                </div>

                <div className="col-md-6 form-group">
                  <label>
                    <Trans>NinjaRMM</Trans>
                  </label>
                  <NinjaOrganizationForm />

                  <FieldErrorMessage name="ninjaRmmOrganizationId" />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default NinjaForm;
