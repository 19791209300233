import { Field, FormikProps } from 'formik';
import React, { FC } from 'react';
import FieldEditor from '../form/FieldEditor';
import AssetFileUploader from './AssetFileUploader';

type Props = {};

const AssetDocumentationForm: FC<Props> = () => {
  return (
    <div className="">
      <div className="form-group">
        <FieldEditor name="playbook" />
      </div>

      <div className="form-group files d-flex flex-wrap flex-grow-1 text-center">
        <Field name="attachments">
          {({ field, form }: { field: any; form: FormikProps<any> }) => {
            const handleUpload = (value: any) => {
              const photoIds = form.values.attachmentIds;
              photoIds.push(value.storageObject.id);
              form.setFieldValue('attachmentIds', photoIds);

              const photos = form.values.attachments;
              photos.push(value.storageObject);
              form.setFieldValue('attachments', photos);

              const attachments_v2 = form.values.attachments_v2;
              attachments_v2.push({
                permanentLink: value.permanentLink,
              });
              form.setFieldValue('attachments_v2', attachments_v2);
            };

            const handleRemove = (index: number) => {
              const photoIds = form.values.attachmentIds;
              photoIds.splice(index, 1);
              form.setFieldValue('attachmentIds', photoIds);

              const photos = form.values.attachments;
              photos.splice(index, 1);
              form.setFieldValue('attachments', photos);

              const attachments_v2 = form.values.attachments_v2;
              attachments_v2.splice(index, 1);
              form.setFieldValue('attachments_v2', attachments_v2);
            };

            return (
              <AssetFileUploader
                documentId={form.values.id}
                onUpload={handleUpload}
                onRemove={handleRemove}
                files={field.value}
                attachments={form.values.attachments_v2}
              />
            );
          }}
        </Field>
      </div>
    </div>
  );
};

export default AssetDocumentationForm;
