import { useMutation, useQuery } from '@apollo/client';
import { DataProxy } from '@apollo/client/cache';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik, FormikProps } from 'formik';
import { cloneDeep } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { SendMessagePayload } from '../../schema/graphql-global-types';
import { ARCHIVE_MESSAGE, GET_MESSAGES, SEND_MESSAGE, validateMessageForm } from '../../schema/message';
import { archiveMessage, archiveMessageVariables } from '../../schema/types/archiveMessage';
import { getMessages, getMessagesVariables } from '../../schema/types/getMessages';
import { MessageDetail } from '../../schema/types/MessageDetail';
import { sendMessage, sendMessageVariables } from '../../schema/types/sendMessage';
import { toastErrorQuery } from '../../utils/error';
import { useVariables } from '../../utils/hooks';
import LoadingQuery from '../common/LoadingQuery';
import { HomeMessageItem } from './HomeMessageItem';
import ErrorQuery from '../form/ErrorQuery';
import FieldCheckbox from '../form/FieldCheckbox';
import FieldErrorMessage from '../form/FieldErrorMessage';
import ReportCompanyForm from '../report/ReportCompanyForm';
import ReportProductForm from '../report/ReportProductForm';
import HomeMessageCategoryForm from './HomeMessageCategoryForm';

const HomeMessage: FunctionComponent = () => {
  const [showForm, setShowForm] = useState(true);
  const [loaded, setLoaded] = useState(false);

  const { search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = useMemo(
    () => ({
      query: {
        limit,
        offset,
        orderBy,
        orderDirection,
        filter: {
          search,
          archived,
          ...filter,
        },
      },
    }),
    [limit, offset, orderBy, orderDirection, search, archived, filter],
  );

  const [mutate] = useMutation<sendMessage, sendMessageVariables>(SEND_MESSAGE);

  const [mutateArchive] = useMutation<archiveMessage, archiveMessageVariables>(ARCHIVE_MESSAGE);

  const { data, loading, error, client, fetchMore, refetch } = useQuery<getMessages, getMessagesVariables>(
    GET_MESSAGES,
    {
      variables,
      fetchPolicy: 'cache-first',
    },
  );

  const [fetching, setFetching] = useState(false);

  const updateQuery = useCallback(
    (proxy: DataProxy, id: any) => {
      const data: any = cloneDeep(proxy.readQuery({ query: GET_MESSAGES, variables }));
      data.messages.items = data.messages.items.filter((el: any) => el.id !== id);
      proxy.writeQuery({ query: GET_MESSAGES, variables, data });
    },
    [variables],
  );

  const handleMore = useCallback(async () => {
    setFetching(true);

    await fetchMore({
      variables: {
        query: {
          ...variables.query,
          offset: data!.messages.items.length,
        },
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        if (!fetchMoreResult) return prev;
        return {
          messages: {
            ...fetchMoreResult.messages,
            items: [...prev.messages.items, ...fetchMoreResult.messages.items],
          },
        };
      },
    });

    setFetching(false);
  }, [fetchMore, variables, data]);

  const handleRemove = useCallback(
    (id: any) => () => {
      mutateArchive({
        variables: {
          id,
        },
        update: (proxy, { data }) => {
          updateQuery(proxy, data!.archiveMessage.message.id);
        },
        optimisticResponse: {
          archiveMessage: {
            message: {
              id,
              __typename: 'Message',
            },
            __typename: 'MutateMessageResponse',
          },
        },
      });
    },
    [mutateArchive, updateQuery],
  );

  const handleAdd = useCallback(
    (item: MessageDetail) => {
      const data: any = cloneDeep(client.readQuery({ query: GET_MESSAGES, variables }));
      data.messages.items.unshift(item);
      client.writeQuery({ query: GET_MESSAGES, variables, data });
    },
    [client, variables],
  );

  useEffect(() => {
    refetch();
  }, []);

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  if (!loaded) return <LoadingQuery wrapper />;

  const hasMore = data!.messages.items.length < data!.messages.total;

  const model: SendMessagePayload = {
    content: '',
    sendToTwitter: false,
    customerIds: [],
    productIds: [],
    categoryIds: [],
  };

  return (
    <>
      <div className="card home-messages">
        <div className="card-header">
          <Trans>Message</Trans>
        </div>

        <div className="card-body">
          <Formik
            initialValues={model}
            validationSchema={yup.object().shape(validateMessageForm)}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                const result: any = await mutate({
                  variables: {
                    payload: values,
                  },
                });

                if (result.errors) {
                  toastErrorQuery(result.errors);
                } else {
                  handleAdd(result.data.sendMessage.message);
                  resetForm();
                  toast.success(i18n._(t`Success!`));

                  // Fis select not reset
                  setShowForm(false);
                  setTimeout(() => {
                    setShowForm(true);
                  }, 150);
                }
              } catch (e) {
                toastErrorQuery(e);
              }

              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                {showForm && (
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="control-label">
                          <Trans>Customers</Trans>
                        </label>

                        <ReportCompanyForm name="customerIds" />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="control-label">
                          <Trans>Products</Trans>
                        </label>

                        <ReportProductForm name="productIds" />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="control-label">
                          <Trans>Categories</Trans>
                        </label>

                        <HomeMessageCategoryForm name="categoryIds" />
                      </div>
                    </div>
                  </div>
                )}

                <div className="form-group">
                  <label className="control-label">
                    <Trans>Message</Trans>
                  </label>

                  <Field name="sendToTwitter">
                    {({ field }: { field: any }) => {
                      return (
                        <Field
                          component="textarea"
                          name="content"
                          className="form-control"
                          placeholder={i18n._(t`Message`)}
                          rows={3}
                          maxLength={field.value ? 280 : undefined}
                        />
                      );
                    }}
                  </Field>

                  <FieldErrorMessage name="content" />
                </div>

                <Field name="content">
                  {({ field, form }: { field: any; form: FormikProps<any> }) => {
                    if (!form.values.sendToTwitter) return '';

                    return (
                      <div className="form-group">
                        <small>{field.value.length}/280</small>
                      </div>
                    );
                  }}
                </Field>

                <div className="actions">
                  <FieldCheckbox name="sendToTwitter" label={i18n._(t`Post to connected Twitter Channel`)} />

                  <button type="submit" className="btn btn-primary ml-4" disabled={isSubmitting}>
                    {isSubmitting ? <i className="fa fa-spinner fa-spin" /> : <Trans>Send</Trans>}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <hr />

        <div className="card-header">
          <Trans>Message History</Trans>
        </div>

        <div className="card-body">
          <div className="list-group">
            {data?.messages.items.map((el) => (
              <HomeMessageItem key={el.id} message={el} onRemove={handleRemove(el.id)} />
            ))}
          </div>

          {hasMore && (
            <div className="text-right mt-3 form-group">
              <button type="button" className="btn btn-link" disabled={loading || fetching} onClick={handleMore}>
                {loading || fetching ? <i className="fa fa-spinner fa-spin" /> : <Trans>Load more</Trans>}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HomeMessage;
