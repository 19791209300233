import gql from 'graphql-tag';

export const MESSAGE_CENTER = gql`
  fragment MessageCenter on MessageCenter {
    id
    subject
    message
    read
    userId
    createdAt
  }
`;

export const GET_MESSAGE_CENTER_MESSAGES = gql`
  query getMessageCenterMessages($query: MessageCenterMessageQuery!) {
    messageCenterMessages(query: $query) {
      limit
      offset
      total
      items {
        ...MessageCenter
      }
    }
  }
  ${MESSAGE_CENTER}
`;

export const MARK_MESSAGE_CENTER_MESSAGES_AS_READ = gql`
  mutation markMessageCenterMessagesAsRead($ids: [ID!]!) {
    markMessageCenterMessagesAsRead(ids: $ids) {
      ...MessageCenter
    }
  }
  ${MESSAGE_CENTER}
`;
