import { useMutation, useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import LoadingForm from '../components/common/LoadingForm';
import MainTitle from '../components/common/MainTitle';
import ButtonSubmit from '../components/form/ButtonSubmit';
import FieldCheckbox from '../components/form/FieldCheckbox';
import FieldSlider from '../components/form/FieldSlider';
import { GET_COMPANY_SELF_AUDIT, UPDATE_COMPANY_SELF_AUDIT_CONFIG } from '../schema/company';
import { getCompanySelfAudit, getCompanySelfAuditVariables } from '../schema/types/getCompanySelfAudit';
import { getCompanyId } from '../utils/auth';
import ErrorQuery from '../components/form/ErrorQuery';
import { toastErrorQuery } from '../utils/error';
import { omit } from 'lodash';
import NotFoundPage from './NotFoundPage';

const SelfAuditPage: FunctionComponent = () => {
  const [mutate] = useMutation(UPDATE_COMPANY_SELF_AUDIT_CONFIG);

  const { data, loading, error } = useQuery<getCompanySelfAudit, getCompanySelfAuditVariables>(GET_COMPANY_SELF_AUDIT, {
    variables: { query: getCompanyId() },
  });

  if (loading) return <LoadingForm />;
  if (error) return <ErrorQuery error={error} />;
  if (!data) return <NotFoundPage />;

  return (
    <div className="user-page">
      <div className="breadcrumbs-area">
        <MainTitle title={i18n._(t`Self Audit`)} />
      </div>

      <Formik
        initialValues={data!.company.selfAuditConfig}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const result: any = await mutate({
              variables: {
                id: getCompanyId(),
                payload: omit(values, ['__typename']),
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              toast.success(i18n._(t`Success!`));
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="self-audit-area card">
            <div className="card-body post-content">
              <h2 className="block-title">
                <Trans>Cybersecurity Self Audit</Trans>
              </h2>

              <div className="info">
                <i className="icon-details" />

                <div className="description">
                  <Trans>
                    IT cybersecurity and compliance is an important topic, but not all clients have to meet the same
                    requirements.
                  </Trans>
                  <br />
                  <Trans>
                    To serve you better as a customer - as well for your internal discussions and processes - feel free
                    to use our self audit.
                  </Trans>
                  <br />
                  <Trans>Please note, that this will be shared with us to help us supporting you.</Trans>
                </div>
              </div>

              <h3 className="title">
                <Trans>My Companies Cybersecurity and Compliance requirements</Trans>
              </h3>

              <label>
                <Trans>My companies cybersecurity and compliance requirements in general.</Trans>
              </label>

              <div className="form-group slider">
                <FieldSlider name="complianceRequirements" />
              </div>

              <div className="form-group support">
                <FieldCheckbox name="contactMe" label={i18n._(t`Please contact me for an individual assessment`)} />
              </div>

              <h3 className="title">
                <Trans>IT Strategy</Trans>
              </h3>

              <label>
                <Trans>
                  Assess how well cybersecurity responsibilities are organized within your organization. Is
                  cybersecurity anchored in the top of your organization? Is the topic part in the board, the top
                  management and as well a function of one specific, operational person?
                </Trans>
                <br />
              </label>

              <div className="form-group slider">
                <FieldSlider name="itStrategy" />
              </div>

              <div className="form-group support">
                <FieldCheckbox name="itStrategySupport" label={i18n._(t`Request Support`)} />
              </div>

              <hr />

              <h2 className="block-title">
                <Trans>Self Audit - NIST Cybersecurity Framework</Trans>
              </h2>

              <h3 className="title">
                <Trans>Identify</Trans>
              </h3>

              <label>
                <Trans>
                  Assess how well you identified your current situation. Do you know your assets? Do you know your
                  "golden eggs"? Do you have a risk management in place to identify risks?
                </Trans>
                <br />
              </label>

              <div className="form-group slider">
                <FieldSlider name="assesment" />
              </div>

              <div className="form-group support">
                <FieldCheckbox name="assesmentSupport" label={i18n._(t`Request Support`)} />
              </div>

              <h3 className="title">
                <Trans>Protect</Trans>
              </h3>

              <label>
                <Trans>
                  Assess your ability to protect your identified assets. Do you protect those assets with data security,
                  information protection and protective technology? Do you have access control in place? Do you do
                  awareness trainings for the whole company?
                </Trans>
                <br />
              </label>

              <div className="form-group slider">
                <FieldSlider name="proactiveAction" />
              </div>

              <div className="form-group support">
                <FieldCheckbox name="proactiveActionSupport" label={i18n._(t`Request Support`)} />
              </div>

              <h3 className="title">
                <Trans>Detect</Trans>
              </h3>

              <label>
                <Trans>
                  Assess your ability to identify anomalies and events within your environment. Do you have a continuous
                  security monitoring and detection processes in place?
                </Trans>
                <br />
              </label>

              <div className="form-group slider">
                <FieldSlider name="identification" />
              </div>

              <div className="form-group support">
                <FieldCheckbox name="identificationSupport" label={i18n._(t`Request Support`)} />
              </div>

              <h3 className="title">
                <Trans>Respond</Trans>
              </h3>

              <label>
                <Trans>
                  Assess your ability to respond during an attack. Do you have a plan of communication? The ability to
                  analyze the ongoing attack? The ability to mitigate the attack and implement improvements?{' '}
                </Trans>
                <br />
              </label>

              <div className="form-group slider">
                <FieldSlider name="reaction" />
              </div>

              <div className="form-group support">
                <FieldCheckbox name="reactionSupport" label={i18n._(t`Request Support`)} />
              </div>

              <h3 className="title">
                <Trans>Recover</Trans>
              </h3>

              <label>
                <Trans>
                  Assess your ability to recover from an attack. Do you have a recovery plan? Are you able to recover
                  the assets? Do you have a communication plan for "after the attack"?
                </Trans>
                <br />
              </label>

              <div className="form-group slider">
                <FieldSlider name="recovery" />
              </div>

              <div className="form-group support">
                <FieldCheckbox name="recoverySupport" label={i18n._(t`Request Support`)} />
              </div>

              <hr />

              <div className="action-submit">
                <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SelfAuditPage;
