import { useQuery } from '@apollo/client';
import React, { FunctionComponent } from 'react';
import { GET_ASSET_TYPE_TAGS } from '../../schema/tag';
import { getAssetTypeTags, getAssetTypeTagsVariables } from '../../schema/types/getAssetTypeTags';
import { useVariables } from '../../utils/hooks';
import SingleTagSelect from '../form/SingleTagSelect';

type Props = {};

const AssetTypeForm: FunctionComponent<Props> = () => {
  const { search$, search, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
      },
    },
  };

  const { data, loading } = useQuery<getAssetTypeTags, getAssetTypeTagsVariables>(GET_ASSET_TYPE_TAGS, {
    variables,
  });

  if (!data && !loading) return <div />;

  return <SingleTagSelect name="assetTypeName" search$={search$} loading={loading} data={data?.assetTypeTags.items} />;
};

export default AssetTypeForm;
