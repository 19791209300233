import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import { cloneDeep, uniqBy } from 'lodash';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import { GET_COMPANIES_FOR_PERSON } from '../../schema/company';
import { CompanyPermission } from '../../schema/graphql-global-types';
import { getCompanies, getCompaniesVariables } from '../../schema/types/getCompanies';
import { PersonCompany } from '../../schema/types/PersonCompany';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';
import UserAvatarName from '../common/UserAvatarName';
import ButtonDelete from '../form/ButtonDelete';

type Props = {};

const PersonCompanyForm: FC<Props> = () => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getCompanies, getCompaniesVariables>(GET_COMPANIES_FOR_PERSON, {
    variables,
  });

  if (!data && !loading) return <div />;

  return (
    <Field name="companies">
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (value: any) => {
          const model = cloneDeep(field.value);

          form.setFieldValue(
            'companies',
            uniqBy(
              [
                ...model,
                {
                  company: value.value,
                  companyId: value.value.id,
                  permission: CompanyPermission.NoAccess,
                },
              ],
              (c: PersonCompany) => c.company.id,
            ),
          );
        };

        const handleRemove = (index: number) => () => {
          const model = cloneDeep(field.value);
          model.splice(index, 1);
          form.setFieldValue('companies', model);
        };

        const handleLoad = (value: string) => search$.next(value);

        const options = loading
          ? []
          : data!.companies.items.map((el) => ({
              value: el,
              label: el.name,
            }));

        return (
          <>
            <div className="form-group">
              <label>
                <Trans>Connect to a Company</Trans>
              </label>
              <Select
                value={null}
                options={options}
                isLoading={loading}
                onChange={handleChange}
                onInputChange={handleLoad}
                placeholder={i18n._(t`Search...`)}
                {...selectOptions}
              />
            </div>

            {field.value.map((el: PersonCompany, index: number) => {
              if (!el.company) return '';

              return (
                <div key={el.company.id} className="form-group d-flex align-items-center">
                  <div className="flex-grow-1 mr-2">
                    <NavLink to={`/crm/company/${el.company.id}`} className="text-dark">
                      <UserAvatarName image={el.company.logo?.url} name={el.company.name} />
                    </NavLink>
                  </div>

                  <ButtonDelete onClick={handleRemove(index)} />
                </div>
              );
            })}
          </>
        );
      }}
    </Field>
  );
};

export default PersonCompanyForm;
