import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { pick } from 'lodash';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { useMutation } from '@apollo/client';
import Modal from 'react-modal';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { CREATE_OFFER, offerKeys, validateOfferForm } from '../../schema/offer';
import { CreateOfferPayload, OfferItemType } from '../../schema/graphql-global-types';
import { createOffer, createOfferVariables } from '../../schema/types/createOffer';
import { OfferItem } from '../../schema/types/OfferItem';
import { OfferProductItem } from '../../schema/types/OfferProductItem';
import { OfferProjectItem } from '../../schema/types/OfferProjectItem';
import { DefaultRouteParams } from '../../utils/const';
import { toastErrorQuery } from '../../utils/error';
import { omitDeep } from '../../utils/utils';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';
import OfferCustomerForm from './OfferCustomerForm';
import OfferResponsibleForm from './OfferResponsibleForm';
import OfferTagForm from './OfferTagForm';
import OfferTemplateForm from './OfferTemplateForm';

type Props = {
  show: boolean;
  onClose: () => void;
};

const OfferCreateModal: FunctionComponent<Props & RouteComponentProps<DefaultRouteParams>> = ({
  history,
  show,
  onClose,
}) => {
  const [step, setStep] = useState(1);

  const [mutate] = useMutation<createOffer, createOfferVariables>(CREATE_OFFER);

  const initialValues: CreateOfferPayload & {
    company: any;
    responsibleUsers: [];
  } = {
    title: '',
    company: null,
    companyId: '',
    firstName: '',
    lastName: '',
    function: '',
    email: '',
    headGraphicId: null,
    responsibleUsers: [],
    responsibleUserIds: [],
    tagNames: [],
    items: [],
    favoriteTextTemplateId: null,
    template: false,
    index: false,
    favorite: false,
  };

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Add new offer - Step</Trans> {step}
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={yup.object().shape(validateOfferForm)}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const payload: any = omitDeep(pick(values, offerKeys), ['__typename']);

                  payload.items = payload.items.map((el: OfferItem) => ({
                    ...pick(el, ['type', 'index', 'title', 'content', 'templateElementId']),
                    discountPercent: Number(el.discountPercent),
                    productItems:
                      el.type === OfferItemType.Product
                        ? el.productItems!.map((item: OfferProductItem) => {
                            const result = pick(item, ['productId', 'title', 'price', 'pieces', 'discountPercent']);

                            result.price = Number(result.price);
                            result.pieces = Number(result.pieces);
                            result.discountPercent = Number(result.discountPercent);

                            return result;
                          })
                        : [],
                    projectItems:
                      el.type === OfferItemType.Project
                        ? el.projectItems!.map((item: OfferProjectItem) =>
                            pick(item, ['userId', 'description', 'startDate', 'endDate', 'status']),
                          )
                        : [],
                  }));

                  const result: any = await mutate({
                    variables: {
                      payload,
                    },
                  });

                  if (result.errors) {
                    toastErrorQuery(result.errors);
                  } else {
                    toast.success(i18n._(t`Success!`));
                    setStep(1);
                    onClose();
                    history.push(`/crm/offer/${result.data.createOffer.offer.id}`);
                  }
                } catch (e) {
                  toastErrorQuery(e);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="modal-body">
                    <div className="progress mb-4">
                      <div className="progress-bar" style={{ width: `${(step / 2) * 100}%` }} />
                    </div>

                    {step === 1 && (
                      <>
                        <div className="form-group">
                          <label>
                            <Trans>Offer Title</Trans>
                          </label>
                          <Field type="text" className="form-control" name="title" autoFocus />

                          <FieldErrorMessage name="title" />
                        </div>

                        <div className="form-group">
                          <label>
                            <Trans>Customer (Company)</Trans>
                          </label>
                          <OfferCustomerForm />

                          <FieldErrorMessage name="companyId" />
                        </div>

                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label>
                                <Trans>First Name</Trans>
                              </label>
                              <Field type="text" className="form-control" name="firstName" />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label>
                                <Trans>Last Name</Trans>
                              </label>
                              <Field type="text" className="form-control" name="lastName" />
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <label>
                            <Trans>Email</Trans>
                          </label>
                          <Field type="email" className="form-control" name="email" />
                        </div>

                        <div className="form-group">
                          <label>
                            <Trans>Position</Trans>
                          </label>
                          <Field type="text" className="form-control" name="function" />
                        </div>
                      </>
                    )}

                    {step === 2 && (
                      <>
                        <OfferResponsibleForm />

                        <div className="form-group">
                          <div className="d-flex justify-content-between">
                            <label className="form-label">
                              <Trans>Tags</Trans>
                            </label>

                            <label className="form-label">
                              <Trans>Optional</Trans>
                            </label>
                          </div>

                          <OfferTagForm />
                        </div>

                        <div className="form-group">
                          <div className="d-flex justify-content-between">
                            <label className="form-label">
                              <Trans>Select Template</Trans>
                            </label>

                            <label className="form-label">
                              <Trans>Optional</Trans>
                            </label>
                          </div>

                          <OfferTemplateForm />
                        </div>
                      </>
                    )}
                  </div>

                  {step === 1 ? (
                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={onClose}>
                        <Trans>Cancel</Trans>
                      </button>

                      <button type="button" className="btn btn-primary" onClick={() => setStep(2)}>
                        <Trans>Continue</Trans>
                      </button>
                    </div>
                  ) : (
                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={() => setStep(1)}>
                        <i className="icon-send-status" />
                        <Trans>Back</Trans>
                      </button>

                      <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withRouter(OfferCreateModal);
