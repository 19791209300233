import { Trans } from '@lingui/macro';
import { Field } from 'formik';
import React, { FunctionComponent } from 'react';
import FieldErrorMessage from '../form/FieldErrorMessage';
import AssetCompanyForm from './AssetCompanyForm';
import AssetLocationForm from './AssetLocationForm';
import AssetProductForm from './AssetProductForm';
import AssetTagForm from './AssetTagForm';
import AssetTypeForm from './AssetTypeForm';

type Props = {};

const AssetDetailForm: FunctionComponent<Props> = () => {
  return (
    <div className="row">
      <div className="col-xl-6">
        <div className="form-group">
          <label>
            <Trans>Asset Name</Trans>
          </label>

          <Field type="text" className="form-control" name="name" autoFocus />

          <FieldErrorMessage name="name" />
        </div>

        <div className="form-group">
          <label>
            <Trans>Company</Trans>
          </label>

          <AssetCompanyForm />

          <FieldErrorMessage name="companyId" />
        </div>

        <div className="form-group">
          <label>
            <Trans>Package</Trans>
          </label>

          <AssetProductForm />
        </div>
      </div>

      <div className="col-xl-6">
        <div className="form-group">
          <label>
            <Trans>Asset Location</Trans>
          </label>

          <AssetLocationForm />

          <FieldErrorMessage name="assetLocationName" />
        </div>

        <div className="form-group">
          <label>
            <Trans>Asset Type</Trans>
          </label>

          <AssetTypeForm />

          <FieldErrorMessage name="assetTypeName" />
        </div>

        <div className="form-group">
          <label>
            <Trans>Tags</Trans>
          </label>

          <AssetTagForm />
        </div>
      </div>

      <div className="form-group">
        <label>
          <Trans>Assets Description (visible to customer)</Trans>
        </label>
        <Field component="textarea" className="form-control" name="description" rows={3} />
      </div>
    </div>
  );
};

export default AssetDetailForm;
