import { t } from '@lingui/macro';
import * as yup from 'yup';

export const validateRequired = yup
  .string()
  .required(t`This field is required`);

export const validateArrayRequired = yup
  .array()
  .required(t`This field is required`);

export const validateUrl = yup.string().url(t`Please enter URL`);

export const validateRequiredUrl = validateUrl.required(
  t`This field is required`
);

export const validateNumber = yup
  .number()
  .typeError(t`Invalid number`)
  .required(t`This field is required`);

export const validateInterval = yup
  .number()
  .typeError(t`Invalid number`)
  .min(60, t`Invalid number`)
  .required(t`This field is required`);

export const validateEmail = validateRequired.email(t`Please enter email`);

export const validatePassword = yup.string().min(8, t`Minimum 8 characters`);
