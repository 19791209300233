import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { GET_COMPANIES_FOR_PERSON } from '../../schema/company';
import { getCompanies, getCompaniesVariables } from '../../schema/types/getCompanies';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {};

const AssetCompanyForm: FunctionComponent<Props> = () => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getCompanies, getCompaniesVariables>(GET_COMPANIES_FOR_PERSON, {
    variables,
  });

  if (!data && !loading) return <div />;

  return (
    <Field name="company">
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (value: any) => {
          form.setFieldValue('companyId', value.value.id);
          form.setFieldValue('company', {
            id: value.value.id,
            name: value.label,
          });

          const responsibleUsers = value.value.responsibleUsers;

          if (responsibleUsers) {
            const modelResponsibleUserIds = form.values.responsibleUserIds;
            const modelResponsibleUsers = form.values.responsibleUsers;

            if (responsibleUsers[1]) {
              modelResponsibleUserIds[0] = responsibleUsers[1].id;
              modelResponsibleUsers[0] = {
                id: responsibleUsers[1].id,
                fullName: responsibleUsers[1].fullName,
              };
            }

            if (responsibleUsers[2]) {
              modelResponsibleUserIds[1] = responsibleUsers[2].id;
              modelResponsibleUsers[1] = {
                id: responsibleUsers[2].id,
                fullName: responsibleUsers[2].fullName,
              };
            }

            form.setFieldValue('responsibleUserIds', modelResponsibleUserIds);
            form.setFieldValue('responsibleUsers', modelResponsibleUsers);
          }
        };

        const handleLoad = (value: string) => search$.next(value);

        const model = field.value
          ? {
              value: field.value,
              label: field.value.name,
            }
          : null;

        const options = loading
          ? []
          : data!.companies.items.map((el: any) => ({
              value: el,
              label: el.name,
            }));

        return (
          <Select
            value={model}
            options={options}
            isLoading={loading}
            onChange={handleChange}
            onInputChange={handleLoad}
            placeholder={i18n._(t`Search...`)}
            {...selectOptions}
          />
        );
      }}
    </Field>
  );
};

export default AssetCompanyForm;
