import { useMutation } from '@apollo/client';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import React, { FC, useCallback, useMemo, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { NavLink } from 'react-router-dom';
import deFlag from '../../img/germany_flag.jpg';
import usFlag from '../../img/us_flag.jpg';
import { LOGOUT, UPDATE_VIEWER_CONFIG } from '../../schema/auth';
import { LoginMode, UserRole } from '../../schema/graphql-global-types';
import { TOGGLE_LOCALE, useLanguage } from '../../schema/locale';
import { updateViewerConfig, updateViewerConfigVariables } from '../../schema/types/updateViewerConfig';
import { useViewer } from '../../utils/hooks';
import { clearStore } from '../../utils/storage';
import UserAvatar from '../common/UserAvatar';

type Props = unknown;

const HeaderProfile: FC<Props> = () => {
  const { data } = useViewer();

  const [logout] = useMutation(LOGOUT);
  const [mutate] = useMutation<updateViewerConfig, updateViewerConfigVariables>(UPDATE_VIEWER_CONFIG);

  const language = useLanguage();
  const [toggleLanguage] = useMutation(TOGGLE_LOCALE);

  const handleLanguage = useCallback(
    (language: string) => () => {
      toggleLanguage({
        variables: {
          payload: language,
        },
      });

      mutate({
        variables: {
          payload: {
            language,
          },
        },
      });
    },
    [toggleLanguage, mutate],
  );

  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((prevState) => !prevState), []);

  const handleLogout = useCallback(async () => {
    const mode = data!.viewer.mode;
    await logout();
    clearStore();
    window.location.href = mode === LoginMode.User ? '/admin-login' : '/login';
  }, [data, logout]);

  const avatar = useMemo(
    () => (data?.viewer.mode === LoginMode.User ? data.viewer.user : data?.viewer.person) || undefined,
    [data],
  );

  if (!data) return <div />;

  return (
    <div className="nav-item dropdown">
      <OutsideClickHandler onOutsideClick={() => setActive(false)}>
        <button
          aria-expanded="false"
          aria-haspopup="true"
          className="button nav-link"
          data-toggle="dropdown"
          onClick={toggleActive}
        >
          <div className="media align-items-center">
            <UserAvatar user={avatar} />
          </div>
        </button>
        <div className={classNames('dropdown-menu dropdown-menu-arrow dropdown-menu-right', { show: active })}>
          <div className="dropdown-header">
            <h3 className="text-capitalize text-dark mb-1">
              {data.viewer.mode === LoginMode.User ? data.viewer.user?.fullName : data.viewer.person?.fullName}
            </h3>

            {data.viewer.mode === LoginMode.User && data.viewer.user?.role === UserRole.Admin && (
              <h6 className="text-overflow m-0">
                <Trans>Administrator</Trans>
              </h6>
            )}
          </div>

          <button
            className={classNames('button dropdown-item d-flex align-items-center', {
              active: language === 'en',
            })}
            onClick={handleLanguage('en')}
          >
            <img
              src={usFlag}
              alt="flag-img"
              className="avatar avatar-sm mr-3 align-self-center"
              style={{ width: 20 }}
            />
            <Trans>English</Trans>
          </button>

          <button
            className={classNames('button dropdown-item d-flex align-items-center', {
              active: language === 'de',
            })}
            onClick={handleLanguage('de')}
          >
            <img
              src={deFlag}
              alt="flag-img"
              className="avatar avatar-sm mr-3 align-self-center"
              style={{ width: 20 }}
            />
            <Trans>Germany</Trans>
          </button>

          <hr />

          <NavLink to="/profile" className="dropdown-item" onClick={toggleActive}>
            <i className="icon-settings" />
            <Trans>My Profile</Trans>
          </NavLink>

          <NavLink to="/change-password" className="dropdown-item" onClick={toggleActive}>
            <i className="icon-password" />
            <Trans>Change Password</Trans>
          </NavLink>

          {data.viewer.mode === LoginMode.Person && data.viewer.companies.length > 1 && (
            <NavLink to="/select-company" className="dropdown-item" onClick={toggleActive}>
              <i className="icon-accounts" />
              <Trans>Switch Company</Trans>
            </NavLink>
          )}

          <hr />

          <div className="dropdown-divider" />
          <button className="button dropdown-item" onClick={handleLogout}>
            <i className="icon-customer-view" />
            <Trans>Logout</Trans>
          </button>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default HeaderProfile;
