import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import LoadingTicket from '../components/common/LoadingTicket';
import MainTitle from '../components/common/MainTitle';
import ErrorQuery from '../components/form/ErrorQuery';
import TicketAddModal from '../components/ticket/TicketAddModal';
import TicketFormPanel from '../components/ticket/TicketFormPanel';
import TicketMessagePanel from '../components/ticket/TicketMessagePanel';
import TicketUserFilter from '../components/ticket/TicketUserFilter';
import TicketBatchActions from '../components/ticket/TicketBatchActions';
import TimeTrackerCreateEntryModal from '../components/timeTracker/TimeTrackerCreateEntryModal';
import { TicketsQueryOrderBy, TicketStatus } from '../schema/graphql-global-types';
import { CLOSE_TICKET, GET_TICKETS_WITH_DETAIL } from '../schema/ticket';
import { closeTicket, closeTicketVariables } from '../schema/types/closeTicket';
import { getTicketsWithDetail, getTicketsWithDetailVariables } from '../schema/types/getTicketsWithDetail';
import { TicketDetail } from '../schema/types/TicketDetail';
import { tablePollingInterval } from '../utils/const';
import { cloneDeep } from 'lodash';
import classNames from 'classnames';
import { useVariables, useViewer } from '../utils/hooks';

const SupportTicketPage: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);
  const [filter, setFilter] = useState('Me & Unassigned');
  // const [refetching, setRefetching] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const { search, search$, archived, limit, setLimit, offset, orderDirection } = useVariables();

  const me = useViewer();

  const [showTickets, setShowTickets] = useState(false);
  const toggleTickets = useCallback(() => setShowTickets((prevState) => !prevState), [setShowTickets]);

  const [showQueues, setShowQueues] = useState(false);
  const toggleQueues = useCallback(() => setShowQueues((prevState) => !prevState), [setShowQueues]);

  const [showSearch, setShowSearch] = useState(false);

  const [selected, setSelected] = useState<any>(null);
  const handleSelect = useCallback(
    (value: any) => {
      setShowQueues(false);
      setShowTickets(false);
      setSelected(cloneDeep(value));
    },
    [setShowQueues, setShowTickets, setSelected],
  );

  const toggleShowAdd = useCallback(() => setShowAdd((prevState) => !prevState), [setShowAdd]);

  const lastEditorId = useMemo(() => {
    if (!me.data?.viewer.user?.id) return undefined;

    switch (filter) {
      case 'Show all':
        return undefined;
      case 'Me & Unassigned':
        return [me.data?.viewer.user?.id, 'unassigned'];
      case 'Unassigned':
        return ['unassigned'];
      case 'Me':
        return [me.data?.viewer.user?.id];
      default:
        return [filter];
    }
  }, [filter, me]);

  const currentVariables = useMemo(
    () => ({
      query: {
        limit: limit,
        offset: offset,
        orderBy: TicketsQueryOrderBy.lastActiveTime,
        orderDirection: orderDirection,
        filter: {
          search,
          archived,
          statuses: [TicketStatus.Opened, TicketStatus.InProgress],
          lastEditorId,
        },
      },
    }),
    [archived, lastEditorId, limit, offset, orderDirection, search],
  );

  const [mutateCloseTicket] = useMutation<closeTicket, closeTicketVariables>(CLOSE_TICKET);

  const client = useApolloClient();

  const { data, previousData, loading, error, refetch, variables } = useQuery<
    getTicketsWithDetail,
    getTicketsWithDetailVariables
  >(GET_TICKETS_WITH_DETAIL, {
    variables: currentVariables,
    pollInterval: tablePollingInterval,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const results = (data ?? previousData)?.tickets;

  useEffect(() => {
    if (results?.items.length) {
      if (!selected) {
        setSelected(cloneDeep(results.items[0]));
      } else {
        const index = results.items.findIndex((el: any) => el.id === selected.id);

        if (index >= results.items.length) {
          setSelected(cloneDeep(results.items[results.items.length - 1]));
        } else if (index === -1) {
          setSelected(null);
        }
      }
    }
  }, [results, selected]);

  const [selectedTickets, setSelectedTickets] = useState<string[]>([]);
  const handleChecked = useCallback(
    (value: string) => {
      if (!selectedTickets.includes(value)) {
        setSelectedTickets([...selectedTickets, value]);
      } else {
        setSelectedTickets(selectedTickets.filter((id) => value !== id));
      }
    },
    [selectedTickets, setSelectedTickets],
  );

  const [selectAll, setSelectAll] = useState(false);
  const toggleSelectAll = useCallback(() => {
    if (selectAll) {
      setSelectedTickets([]);
    } else {
      setSelectedTickets(results ? results.items.map((el) => el.id) : []);
    }
    setSelectAll((prevState) => !prevState);
  }, [selectAll, setSelectAll, setSelectedTickets, results]);

  const handleFilter = useCallback(
    (value: string) => {
      setShowQueues(false);
      setShowTickets(true);
      setSelectedTickets([]);
      setSelectAll(false);
      setFilter(value);
    },
    [setShowQueues, setShowTickets, setFilter, setSelectedTickets, setSelectAll],
  );

  const handleMore = useCallback(() => setLimit((prevState) => prevState + limit), [limit, setLimit]);

  const handleBatchSuccess = useCallback(() => {
    refetch();
    handleFilter(filter);
  }, [refetch, handleFilter, filter]);

  const hasMore = results && results.items.length < results.total;

  const handleRemove = useCallback(
    (id: any) => {
      mutateCloseTicket({
        variables: {
          id: id,
          payload: {
            message: null,
          },
        },
        optimisticResponse: {
          closeTicket: id,
        },
        update: (proxy) => {
          const results: any = cloneDeep(proxy.readQuery({ query: GET_TICKETS_WITH_DETAIL, variables }));
          results.tickets.items = results.tickets.items.filter((el: any) => el.id !== id);
          proxy.writeQuery({
            query: GET_TICKETS_WITH_DETAIL,
            variables,
            data: results,
          });
        },
      });
    },
    [mutateCloseTicket, variables],
  );

  // const handleRefetch = useCallback(async () => {
  //   setRefetching(true);
  //   await refetch();
  //   setRefetching(false);
  // }, [setRefetching, refetch]);

  const handleAddSuccess = useCallback(
    async (item: TicketDetail) => {
      toggleShowAdd();

      const results: any = cloneDeep(client.readQuery({ query: GET_TICKETS_WITH_DETAIL, variables }));
      results.tickets.items.unshift(item);
      client.writeQuery({
        query: GET_TICKETS_WITH_DETAIL,
        variables,
        data: results,
      });

      setSelected(cloneDeep(results.tickets.items[0]));
    },
    [toggleShowAdd, client, variables, setSelected],
  );

  const [addTime, setAddTime] = useState<any>();
  const hideAddTime = useCallback(() => setAddTime(undefined), []);

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;
  if (!loaded) return <LoadingTicket />;

  if (!me.data) return <div />;

  return (
    <div className="ticket-page">
      <div className="breadcrumbs-area">
        <MainTitle title={i18n._(t`Open Tickets`)} tips="Support/Tickets" />
      </div>

      <div className="ticket-area">
        <div
          className={classNames('panel-users', {
            active: !showQueues,
          })}
        >
          <button type="button" className="add-sm btn btn-sm btn-primary" onClick={toggleShowAdd}>
            <i className="icon-add" />
          </button>

          <div className="header">
            <button type="button" className="add btn btn-sm btn-primary btn-full" onClick={toggleShowAdd}>
              <span>
                <Trans>Add new ticket</Trans>
              </span>
            </button>

            <a className="toggle" onClick={toggleQueues}>
              <i className="icon-filter" />
              <div className="label">
                <Trans>FILTER</Trans>
              </div>

              <div className="text text-primary visible-desktop">
                <Trans>Hide</Trans>
              </div>

              <div className="text text-primary hidden-desktop">
                <Trans>Toggle</Trans>
              </div>
            </a>
          </div>

          <div className="body">
            <TicketUserFilter selected={filter} onChange={handleFilter} />
          </div>
        </div>

        <div className="panel-message">
          <a className="px-3 text-lg d-xl-none mb-1" onClick={toggleTickets}>
            <i className="icon-my-tickets" />

            <Trans>Toggle Tickets</Trans>
          </a>

          <div
            className={classNames('d-xl-block', {
              'd-none': !showTickets,
            })}
          >
            <div className="header d-flex align-items-center">
              <input type="checkbox" className="mx-2" checked={selectAll} onChange={toggleSelectAll} />

              <div className="flex-grow-1">
                <TicketBatchActions selectedTickets={selectedTickets} onSuccess={handleBatchSuccess} />
              </div>

              <div className="search">
                <input
                  className={classNames('form-control', { active: showSearch })}
                  placeholder={i18n._(t`Search`)}
                  onChange={(e) => search$.next(e.target.value)}
                />
                <button type="button" className="button" onClick={() => setShowSearch((prevState) => !prevState)}>
                  <i className="icon-search" />
                </button>
              </div>
            </div>

            <div className="ticket-message-panel">
              {results && results.items.length > 0 ? (
                <TicketMessagePanel
                  selected={selected}
                  selectedTickets={selectedTickets}
                  tickets={results.items}
                  onSelect={handleSelect}
                  onRemove={handleRemove}
                  onChecked={handleChecked}
                />
              ) : (
                <div className="empty">
                  <Trans>No active tickets</Trans>
                </div>
              )}

              {hasMore && (
                <div className="text-right mt-3 form-group">
                  <button type="button" className="btn btn-link" disabled={loading} onClick={handleMore}>
                    {loading ? <i className="fa fa-spinner fa-spin" /> : <Trans>Load more</Trans>}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className={classNames('panel-form mb-4 d-xl-block', {
            'd-none': showTickets,
          })}
        >
          {results?.items.length && selected ? (
            <TicketFormPanel model={selected} variables={variables} onSelect={handleSelect} onAddTime={setAddTime} />
          ) : (
            <div className="empty">
              <Trans>Select the ticket</Trans>
            </div>
          )}
        </div>
      </div>

      <TicketAddModal show={showAdd} onClose={toggleShowAdd} onSuccess={handleAddSuccess} />

      <TimeTrackerCreateEntryModal
        show={!!addTime}
        company={addTime?.company}
        project={addTime?.project}
        description={addTime?.description}
        onClose={hideAddTime}
      />
    </div>
  );
};

export default SupportTicketPage;
