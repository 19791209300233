import gql from 'graphql-tag';

export default gql`
  directive @client on FIELD

  type ManualLogV2 {
    content: String!
    tagNames: [String!]
  }

  extend type Company {
    tagNames: [String!]!
    responsibleUserIds: [String!]!
    manualLogV2: ManualLogV2!
  }

  extend type CompanyPerson {
    personId: String!
    companyId: String!
  }

  extend type Document {
    tagNames: [String!]!
    categoryId: String!
    customerIds: [String!]!
    attachmentIds: [String!]!
    headGraphicId: String
  }

  extend type DocumentElement {
    templateElementId: String!
  }

  extend type OfferItem {
    templateElementId: String!
  }

  extend type OfferItemProductItem {
    productId: String!
  }

  extend type OfferItemProjectItem {
    userId: String!
  }

  extend type Offer {
    tagNames: [String!]!
    companyId: ID
    responsibleUserIds: [ID!]!
    headGraphicId: String
    favoriteTextTemplateId: String
  }

  extend type Product {
    tagNames: [String!]!
    photoIds: [String!]!
  }

  extend type ProductReport {
    contractTypeId: String!
    productId: String!
  }

  extend type Asset {
    tagNames: [String!]!
    companyId: String
    assetLocationName: String
    assetTypeName: String
    productId: String
    responsibleUserIds: [String!]!
    customerResponsiblePersonIds: [String!]!
    manualLog: String!
    manualLogViewableByCustomer: Boolean!
    manualLogTagNames: [String!]!
    attachmentIds: [String!]!
  }

  extend type Person {
    tagNames: [String!]!
    manualLogV2: ManualLogV2!
  }

  extend type User {
    password: String!
    timeTrackerDefaultProductId: String
  }

  extend type TimeTrackerProject {
    customerId: String!
  }

  extend type TimeTrackerProjectSpecialRate {
    productId: String!
  }

  extend type TimeTrackerProjectEntry {
    userId: String!
    productId: String!
  }

  extend type TimeTrackerProjectAdding {
    productId: String!
  }

  extend type TimeTrackerConfig {
    defaultEntryProductId: String
  }

  type SidebarState {
    open: Boolean
    openItem: [Boolean]!
    darkMode: Boolean
  }

  extend type Query {
    sidebarState: SidebarState!
  }

  extend type Mutation {
    toggleSidebar(payload: Boolean): Boolean!
    toggleSidebarItem(index: Int!, payload: Boolean): Boolean!
    toggleDarkMode(payload: Boolean): Boolean!
  }

  type LocaleState {
    language: String!
  }

  extend type Query {
    localeState: LocaleState!
  }

  extend type Mutation {
    toggleLocale(payload: String!): Boolean!
  }
`;
