import { Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import { cloneDeep } from 'lodash';
import React, { FC } from 'react';
import { OfferItemType } from '../../schema/graphql-global-types';
import { focusTitle } from '../../utils/utils';

type Props = {
  index: number;
};

const DocumentationAddModules: FC<Props> = ({ index }) => {
  return (
    <div className="add-modules">
      <div className="icons">
        <i className="icon-add" />
      </div>

      <Trans>Add new module</Trans>

      <div className="dropdown">
        <div className="label-lg">
          <Trans>Builder Options</Trans>
        </div>

        <Field name="elements">
          {({ field, form }: { field: any; form: FormikProps<any> }) => {
            const handleClick = () => {
              const values = cloneDeep(field.value);
              values.splice(index, 0, {
                title: '',
                content: '',
                index: true,
                item: {
                  type: OfferItemType.Product,
                  title: '',
                  index: true,
                  content: '',
                  discountPercent: 0,
                  productItems: [],
                  projectItems: [],
                },
                templateElementId: null,
              });
              form.setFieldValue('elements', values);
              focusTitle(index);
            };

            return (
              <button type="button" className="item" onClick={handleClick}>
                <i className="icon-crm" />
                <div className="text">
                  <Trans>Product</Trans>
                </div>
              </button>
            );
          }}
        </Field>

        <Field name="elements">
          {({ field, form }: { field: any; form: FormikProps<any> }) => {
            const handleClick = () => {
              const values = cloneDeep(field.value);
              values.splice(index, 0, {
                title: '',
                content: '',
                index: true,
                item: {
                  type: OfferItemType.Project,
                  title: '',
                  index: true,
                  content: '',
                  discountPercent: 0,
                  productItems: [],
                  projectItems: [],
                },
                templateElementId: null,
              });
              form.setFieldValue('elements', values);
              focusTitle(index);
            };

            return (
              <button type="button" className="item" onClick={handleClick}>
                <i className="icon-project" />
                <div className="text">
                  <Trans>Project</Trans>
                </div>
              </button>
            );
          }}
        </Field>

        <Field name="elements">
          {({ field, form }: { field: any; form: FormikProps<any> }) => {
            const handleClick = () => {
              const values = cloneDeep(field.value);
              values.splice(index, 0, {
                title: '',
                content: '',
                index: true,
                item: null,
                templateElementId: null,
              });
              form.setFieldValue('elements', values);
              focusTitle(index);
            };

            return (
              <button type="button" className="item" onClick={handleClick}>
                <i className="icon-text" />
                <div className="text">
                  <Trans>Text</Trans>
                </div>
              </button>
            );
          }}
        </Field>

        <Field name="elements">
          {({ field, form }: { field: any; form: FormikProps<any> }) => {
            const handleClick = () => {
              const values = cloneDeep(field.value);
              values.splice(index, 0, {
                title: '',
                content: '',
                index: true,
                item: null,
                templateElementId: 0,
                templateElement: {
                  id: 0,
                  content: '',
                },
              });
              form.setFieldValue('elements', values);
              focusTitle(index);
            };

            return (
              <button type="button" className="item" onClick={handleClick}>
                <i className="icon-document" />
                <div className="text">
                  <Trans>Centralized content</Trans>
                </div>
              </button>
            );
          }}
        </Field>
      </div>
    </div>
  );
};

export default DocumentationAddModules;
