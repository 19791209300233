import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Select from 'react-select';
import { LoginMode, ThingType, UserRole } from '../schema/graphql-global-types';
import { GET_THINGS } from '../schema/thing';
import { getThings, getThingsVariables } from '../schema/types/getThings';
import { selectOptions } from '../utils/const';
import { useVariables, useViewer } from '../utils/hooks';
import { getStorage } from '../utils/storage';

const getType = (type: string) => {
  switch (type) {
    case ThingType.Asset:
      return i18n._(t`Asset`);
    case ThingType.Company:
      return i18n._(t`Company`);
    case ThingType.Contract:
      return i18n._(t`Contract`);
    case ThingType.Document:
      return i18n._(t`Document`);
    case ThingType.Person:
      return i18n._(t`Person`);
    case ThingType.Product:
      return i18n._(t`Product`);
    case ThingType.Offer:
      return i18n._(t`Offer`);
    default:
      return '';
  }
};

const getUrl = (el: any) => {
  switch (el.type) {
    case ThingType.Asset:
      return `/service/asset/${el.id}`;
    case ThingType.Company:
      return `/crm/company/${el.id}`;
    case ThingType.Contract:
      return `/crm/contract/${el.id}`;
    case ThingType.Document:
      return `/documentation/edit/${el.id}`;
    case ThingType.Person:
      return `/crm/person/${el.id}`;
    case ThingType.Offer:
      return `/crm/offer/${el.id}`;
    case ThingType.Product:
      return `/settings/product/${el.id}`;
    default:
      return '';
  }
};

const Searchbar: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const me = useViewer();

  const { search$, search, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
      },
    },
  };

  const handleChange = useCallback(
    (value: any) => {
      history.push(getUrl(value.value));
    },
    [history],
  );

  const handleLoad = useCallback((value: string) => search$.next(value), [search$]);

  const { data, loading } = useQuery<getThings, getThingsVariables>(GET_THINGS, {
    variables,
  });

  if (!data && !loading) return <div />;

  if (!me.data) return <div />;

  const viewAs = getStorage('viewAs');

  if (me.data.viewer.mode === LoginMode.Person || viewAs) return <div />;

  let items = data?.things.items;

  if (me.data.viewer.user?.role === UserRole.Member) {
    items = items?.filter((el) => el.type !== ThingType.Product);
  }

  const options = loading
    ? []
    : items?.map((el) => ({
        value: el,
        label: `${getType(el.type)}: ${el.name}`,
      }));

  return (
    <Select
      value={null}
      options={options}
      isLoading={loading}
      cacheOptions
      defaultOptions
      onChange={handleChange}
      onInputChange={handleLoad}
      placeholder={i18n._(t`Search`)}
      {...selectOptions}
    />
  );
};

export default withRouter(Searchbar);
