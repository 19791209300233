import { Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import LoadingQuery from '../components/common/LoadingQuery';
import ErrorQuery from '../components/form/ErrorQuery';
import logo from '../img/logo_homer_150x150.svg';
import logoWhite from '../img/logo_homer_150x150_white.svg';
import { LOGOUT } from '../schema/auth';
import { GET_COMPANIES_FOR_LOGIN } from '../schema/company';
import { GET_SIDEBAR_STATE } from '../schema/sidebar';
import { getCompanies, getCompaniesVariables } from '../schema/types/getCompanies';
import { sidebarState } from '../schema/types/sidebarState';
import { useVariables } from '../utils/hooks';
import { clearStore, setStorage } from '../utils/storage';
import useAuthLayout from '../utils/useAuthLayout';
import emptyLogo from '../img/icon_homer_collapse_40x40.svg';

const SelectCompanyPage: FunctionComponent<RouteComponentProps> = ({ history }) => {
  useAuthLayout();

  const sidebar = useQuery<sidebarState>(GET_SIDEBAR_STATE);
  const [logout] = useMutation(LOGOUT);

  const { search, filter, archived, offset, orderBy, orderDirection } = useVariables();

  const handleSelect = useCallback(
    (id: any) => () => {
      document.querySelector('body')!.classList.remove('bg-gradient-primary');
      setStorage('companyId', id);
      history.push('/');
    },
    [history],
  );

  const handleLogout = useCallback(async () => {
    await logout();
    clearStore();
    window.location.href = '/login';
  }, [logout]);

  const variables = {
    query: {
      limit: 50,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading, error } = useQuery<getCompanies, getCompaniesVariables>(GET_COMPANIES_FOR_LOGIN, {
    variables,
  });

  if (loading) return <LoadingQuery />;
  if (error) return <ErrorQuery error={error} />;

  let items = data && data.companies ? data.companies.items : [];

  if (items.length === 1) {
    handleSelect(items[0].id)();
    return <div />;
  }

  return (
    <div className="auth-area">
      <div className="content">
        <div className="logo">
          {sidebar.data?.sidebarState.darkMode ? (
            <img src={logoWhite} alt="MultiCloud Center" style={{ width: '150px' }} />
          ) : (
            <img src={logo} alt="MultiCloud Center" style={{ width: '150px' }} />
          )}
        </div>

        <h1 className="main-title">
          <Trans>Select Company</Trans>
        </h1>

        <div className="companies">
          {items.map((el: any) => (
            <a key={el.id} className="item" onClick={handleSelect(el.id)}>
              <div className="company-logo">
                {el.logo ? <img src={el.logo.url} alt="" /> : <img src={emptyLogo} alt="" />}
              </div>

              <div className="name">{el.name}</div>
            </a>
          ))}
        </div>

        <div className="form-group">
          <a className="text-primary" onClick={handleLogout}>
            <Trans>Logout</Trans>
          </a>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SelectCompanyPage);
