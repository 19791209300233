import { Trans } from '@lingui/macro';
import { Field } from 'formik';
import React, { FunctionComponent } from 'react';
import { MonitorPortType } from '../../schema/graphql-global-types';

type Props = {};

const AssetMonitorPortForm: FunctionComponent<Props> = () => {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Port</Trans>
          </label>
          <Field type="number" className="form-control" name="port.port" />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Type</Trans>
          </label>
          <Field component="select" className="form-control" name="port.type">
            {Object.keys(MonitorPortType).map((el: any, index: number) => (
              <option key={index} value={el}>
                {el}
              </option>
            ))}
          </Field>
        </div>
      </div>
    </div>
  );
};

export default AssetMonitorPortForm;
