import classnames from 'classnames';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { CompanyPerson } from '../../schema/types/CompanyPerson';
import ButtonDelete from '../form/ButtonDelete';
import UserSendModal from '../user/UserSendModal';

type Props = {
  index: number;
  primary: boolean;
  model: CompanyPerson;
  onPrimary: () => void;
  onRemove: () => void;
};

const CompanyPersonFormItem: FunctionComponent<Props> = ({ index, primary, model, onPrimary, onRemove }) => {
  const [showSend, setShowSend] = useState(false);

  const toggleSend = useCallback(() => setShowSend((prevState) => !prevState), []);

  return (
    <div className="form-group d-flex align-items-center">
      <div className="d-sm-flex flex-grow-1 mr-3">
        <label className="w-100 mb-2 mb-sm-0 mr-sm-3 col-form-label">
          <NavLink to={`/crm/person/${model.person.id}`}>{model.person.fullName}</NavLink>
        </label>
        <Field className="form-control w-100" component="select" name={`persons[${index}].permission`}>
          <option value="Admin">{i18n._(t`Admin`)}</option>
          <option value="User">{i18n._(t`User`)}</option>
          <option value="NoAccess">{i18n._(t`No Access`)}</option>
        </Field>
      </div>

      <div className="mt-5 mt-sm-0">
        <a data-tip data-for="send" className="mr-3 text-xs" onClick={toggleSend}>
          <i className="fa fa-location-arrow" />
        </a>
        <ReactTooltip id="send">
          <Trans>(Re-) send reset password link for the Portal</Trans>
        </ReactTooltip>

        <UserSendModal show={showSend} userId={model.person.id} onClose={toggleSend} />

        <a
          data-tip
          data-for="primary"
          className={classnames('button-make-primary mr-3 text-xs', {
            primary,
          })}
          onClick={onPrimary}
        >
          <i className="fa fa-check" />
        </a>
        <ReactTooltip id="primary">
          <Trans>Set a primary contact for this company</Trans>
        </ReactTooltip>

        <ButtonDelete onClick={onRemove} />
      </div>
    </div>
  );
};

export default CompanyPersonFormItem;
