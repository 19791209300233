import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import { endOfYear, startOfYear } from 'date-fns';
import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import icon from '../../img/top-responder.svg';
import { GET_TOP_RESPONSE_USERS } from '../../schema/stats';
import { topResponseUsers, topResponseUsersVariables } from '../../schema/types/topResponseUsers';
import UserAvatar from '../common/UserAvatar';
import ErrorQuery from '../form/ErrorQuery';

const today = new Date();

type Props = {};

const HomeTopResponder: FC<Props> = () => {
  const { data, error } = useQuery<topResponseUsers, topResponseUsersVariables>(GET_TOP_RESPONSE_USERS, {
    variables: {
      filter: {
        from: startOfYear(today).toISOString(),
        to: endOfYear(today).toISOString(),
        minimumTicketCount: 1,
      },
      limit: 3,
    },
    fetchPolicy: 'cache-first',
  });

  if (error) return <ErrorQuery error={error} />;

  return (
    <div className="item item-3">
      <div className="card">
        <div className="icons">
          <img src={icon} alt="" />
        </div>

        <div className="block-title">
          <Trans>Top responder of the year</Trans>
        </div>

        <div className="content users">
          {data &&
            data.topResponseUsers.map((el, id) => (
              <div key={el.id} className="user">
                <span data-tip data-for={`user-${el.id}`}>
                  <UserAvatar user={el} />
                </span>
                <ReactTooltip id={`user-${el.id}`} place="bottom">
                  {el.fullName}
                </ReactTooltip>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default HomeTopResponder;
