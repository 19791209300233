import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import { GET_LOG_TAGS } from '../../schema/tag';
import { getLogTags, getLogTagsVariables } from '../../schema/types/getLogTags';
import { useVariables } from '../../utils/hooks';
import TagSelect from '../form/TagSelect';

type Props = {};

const LogForm: FunctionComponent<Props> = () => {
  const { search$, search, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
      },
    },
  };

  const { data, loading } = useQuery<getLogTags, getLogTagsVariables>(GET_LOG_TAGS, {
    variables,
  });

  if (!data && !loading) return <div />;

  return (
    <TagSelect
      name="manualLogV2.tagNames"
      search$={search$}
      loading={loading}
      data={data?.logTags.items}
      placeholder={i18n._(t`Add Log Tags...`)}
    />
  );
};

export default LogForm;
