import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { pick } from 'lodash';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import DocumentationElementsCreateForms from '../components/documentation/DocumentationElementsCreateForms';
import {
  CREATE_DOCUMENT_TEMPLATE_ELEMENT,
  validateDocumentTemplateElementForm,
} from '../schema/documentTemplateElement';
import { CreateDocumentTemplateElementPayload } from '../schema/graphql-global-types';
import {
  createDocumentTemplateElement,
  createDocumentTemplateElementVariables,
} from '../schema/types/createDocumentTemplateElement';
import { DefaultRouteParams } from '../utils/const';
import { toastErrorQuery } from '../utils/error';

const keys = ['title', 'content', 'public'];

const DocumentationElementCreatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({
  match,
  history,
}) => {
  const [mutate] = useMutation<createDocumentTemplateElement, createDocumentTemplateElementVariables>(
    CREATE_DOCUMENT_TEMPLATE_ELEMENT,
  );

  const initialValues: CreateDocumentTemplateElementPayload = {
    title: '',
    content: '',
    public: true,
  };

  return (
    <div className="document-form-page">
      <div className="card-body">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={yup.object().shape(validateDocumentTemplateElementForm)}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const payload: any = pick(values, keys);

              const result: any = await mutate({
                variables: {
                  payload,
                },
              });

              if (result.errors) {
                toastErrorQuery(result.errors);
              } else {
                toast.success(i18n._(t`Success!`));
                history.push(
                  `/documentation/element/${result.data.createDocumentTemplateElement.documentTemplateElement.id}`,
                );
              }
            } catch (e) {
              toastErrorQuery(e);
            }

            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => <DocumentationElementsCreateForms loading={isSubmitting} />}
        </Formik>
      </div>
    </div>
  );
};

export default withRouter(DocumentationElementCreatePage);
