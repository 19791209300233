import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { GET_VIEWER } from '../schema/auth';
import { GET_CURRENCY_CONFIG } from '../schema/config';
import { OrderDirection } from '../schema/graphql-global-types';
import { getCurrencyConfig } from '../schema/types/getCurrencyConfig';
import { getViewer } from '../schema/types/getViewer';
import { defaultQuery } from './const';

export type useSearch$Type = <T>(cb: (value: T) => void) => Subject<T>;

export const useSearch$: useSearch$Type = <T>(cb: (value: T) => void) => {
  const search$ = useMemo(() => new Subject<T>(), []);
  const destroy$ = useMemo(() => new Subject(), []);

  useEffect(() => {
    search$.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(destroy$)).subscribe(cb);

    return () => {
      destroy$.next('');
    };
  }, [cb, destroy$, search$]);

  return search$;
};

export type UseVariablesOptions = {
  filter?: any;
  limit?: number;
  offset?: number;
  orderBy?: any;
  orderDirection?: OrderDirection;
};

export const useVariables = (options: UseVariablesOptions = {}) => {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(options.filter || {});
  const [archived, setArchived] = useState(false);
  const [limit, setLimit] = useState(options.limit || defaultQuery.query.limit);
  const [offset, setOffset] = useState(options.offset || defaultQuery.query.offset);
  const [orderBy, setOrderBy] = useState(options.orderBy || defaultQuery.query.orderBy);
  const [orderDirection, setOrderDirection] = useState(options.orderDirection || defaultQuery.query.orderDirection);
  const search$ = useSearch$(setSearch);
  const filter$ = useSearch$(setFilter);

  return {
    search$,
    search,
    setSearch,
    filter$,
    filter,
    setFilter,
    archived,
    setArchived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
  };
};

export const useViewer = () => {
  return useQuery<getViewer>(GET_VIEWER, {
    fetchPolicy: 'cache-only',
  });
};

export const useCurrency = () => {
  return useQuery<getCurrencyConfig>(GET_CURRENCY_CONFIG, {
    fetchPolicy: 'cache-first',
  });
};
