import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';

type Props = {
  name: string;
  label: string;
  value: any;
};

const FieldRadio: FunctionComponent<Props> = ({ name, label, value }) => {
  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (e: any) => {
          if (e.target.checked) {
            form.setFieldValue(name, value);
          }
        };

        return (
          <div className="custom-control custom-radio">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`${name}-${value}`}
              checked={field.value === value}
              onChange={handleChange}
              value={value}
            />
            <label
              className="custom-control-label"
              htmlFor={`${name}-${value}`}
            >
              {label}
            </label>
          </div>
        );
      }}
    </Field>
  );
};

export default FieldRadio;
