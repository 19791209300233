import React, { FunctionComponent, HTMLAttributes } from 'react';
import { Route, RouteProps } from 'react-router';
import classNames from 'classnames';

const MenuLink: FunctionComponent<RouteProps & HTMLAttributes<{}>> = ({
  path,
  exact,
  className,
  children,
  ...rest
}) => (
  <Route
    path={path}
    exact={exact || path === '/'}
    children={({ match }) => (
      <li
        className={classNames(className, {
          active: match,
          'is-expanded': match,
        })}
        {...rest}
      >
        {children}
      </li>
    )}
  />
);

export default MenuLink;
