import gql from 'graphql-tag';
import { validateRequired } from '../utils/validations';

export const DOCUMENT_CATEGORY = gql`
  fragment DocumentCategory on DocumentCategory {
    id
    name
    type
  }
`;

export const DOCUMENT_CATEGORIES = gql`
  fragment DocumentCategories on DocumentCategoryCollection {
    limit
    offset
    total
    items {
      ...DocumentCategory
    }
  }
  ${DOCUMENT_CATEGORY}
`;

export const GET_DOCUMENT_CATEGORIES = gql`
  query getDocumentCategories($query: DocumentCategoriesQuery!) {
    documentCategories(query: $query) {
      ...DocumentCategories
    }
  }
  ${DOCUMENT_CATEGORIES}
`;

export const DOCUMENT_CATEGORY_DETAIL = gql`
  fragment DocumentCategoryDetail on DocumentCategory {
    ...DocumentCategory
  }
  ${DOCUMENT_CATEGORY}
`;

export const GET_DOCUMENT_CATEGORY = gql`
  query getDocumentCategory($query: ID!) {
    documentCategory(id: $query) {
      ...DocumentCategoryDetail
    }
  }
  ${DOCUMENT_CATEGORY_DETAIL}
`;

export const CREATE_DOCUMENT_CATEGORY = gql`
  mutation createDocumentCategory($payload: CreateDocumentCategoryPayload!) {
    createDocumentCategory(payload: $payload) {
      documentCategory {
        ...DocumentCategoryDetail
      }
    }
  }
  ${DOCUMENT_CATEGORY_DETAIL}
`;

export const UPDATE_DOCUMENT_CATEGORY = gql`
  mutation updateDocumentCategory(
    $id: ID!
    $payload: UpdateDocumentCategoryPayload!
  ) {
    updateDocumentCategory(id: $id, payload: $payload) {
      documentCategory {
        ...DocumentCategoryDetail
      }
    }
  }
  ${DOCUMENT_CATEGORY_DETAIL}
`;

export const ARCHIVE_DOCUMENT_CATEGORY = gql`
  mutation archiveDocumentCategory($id: ID!) {
    archiveDocumentCategory(id: $id) {
      documentCategory {
        id
      }
    }
  }
`;

export const UNARCHIVE_DOCUMENT_CATEGORY = gql`
  mutation unarchiveDocumentCategory($id: ID!) {
    unarchiveDocumentCategory(id: $id) {
      documentCategory {
        id
      }
    }
  }
`;

export const validateDocumentCategoryForm = {
  name: validateRequired,
};
