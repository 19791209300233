import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent, useCallback } from 'react';
import { GET_PRODUCT_CONTRACT_TYPES } from '../../schema/productContractType';
import { getProductContractTypes, getProductContractTypesVariables } from '../../schema/types/getProductContractTypes';
import { useVariables } from '../../utils/hooks';
import MultipleSelect from '../form/MultipleSelect';
import { uniq } from 'lodash';

type Props = {
  name: string;
  onChange: (value: string[]) => void;
};

const ReportContractTypeForm: FunctionComponent<Props> = ({ name, onChange }) => {
  const { search, search$, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getProductContractTypes, getProductContractTypesVariables>(
    GET_PRODUCT_CONTRACT_TYPES,
    {
      variables,
    },
  );

  const handleChange = useCallback(
    (values: string[]) => {
      let results: string[] = [];

      data!.productContractTypes.items.forEach((el) => {
        if (values.find((value) => el.id === value)) {
          results = results.concat(el.emails);
        }
      });

      onChange(uniq(results));
    },
    [onChange, data],
  );

  if (!data && !loading) return <div />;

  return (
    <MultipleSelect
      name={name}
      search$={search$}
      loading={loading}
      data={data?.productContractTypes.items}
      placeholder={i18n._(t`All Contract Types`)}
      onChange={handleChange}
    />
  );
};

export default ReportContractTypeForm;
