import { toast } from 'react-toastify';

export const toastErrorQuery = (error: any) => {
  if (error.graphQLErrors && error.graphQLErrors.length) {
    error.graphQLErrors.forEach(({ message }: any) =>
      toast.error(message.error || message)
    );
  } else if (error.length) {
    error.forEach(({ message }: any) => toast.error(message.error || message));
  }
};
