import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import MainTitle from '../components/common/MainTitle';
import ImportForm from '../components/import/ImportForm';
import ImportFormAzure from '../components/import/ImportFormAzure';
import ImportFormOld from '../components/import/ImportFormOld';

const ImportPage: FunctionComponent = () => {
  return (
    <div className="report-page">
      <div className="breadcrumbs-area">
        <MainTitle title={i18n._(t`Import`)} tips="Reporting/Import" />
      </div>

      <div className="row">
        <ImportFormOld />
        <ImportForm />
        <ImportFormAzure />
      </div>
    </div>
  );
};

export default ImportPage;
