import { useMutation, useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { GET_COMPANIES_FOR_PERSON } from '../../schema/company';
import {
  GET_BEXIO_EXCLUDE_COMPANIES_AZURE_RI_CONFIG,
  UPDATE_BEXIO_EXCLUDE_COMPANIES_AZURE_RI_CONFIG,
} from '../../schema/import';
import { Company } from '../../schema/types/Company';
import { getBexioExcludeCompaniesAzureRIConfig } from '../../schema/types/getBexioExcludeCompaniesAzureRIConfig';
import { getCompanies, getCompaniesVariables } from '../../schema/types/getCompanies';
import {
  updateBexioExcludeCompaniesAzureRIConfig,
  updateBexioExcludeCompaniesAzureRIConfigVariables,
} from '../../schema/types/updateBexioExcludeCompaniesAzureRIConfig';
import { selectOptions } from '../../utils/const';
import { toastErrorQuery } from '../../utils/error';
import { useVariables } from '../../utils/hooks';

type Props = {};

const ImportExcludeCompaniesAzure: FunctionComponent<Props> = () => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getCompanies, getCompaniesVariables>(GET_COMPANIES_FOR_PERSON, {
    variables,
    fetchPolicy: 'cache-first',
  });

  const { data: config } = useQuery<getBexioExcludeCompaniesAzureRIConfig>(GET_BEXIO_EXCLUDE_COMPANIES_AZURE_RI_CONFIG);

  const [mutate, { loading: submitting }] = useMutation<
    updateBexioExcludeCompaniesAzureRIConfig,
    updateBexioExcludeCompaniesAzureRIConfigVariables
  >(UPDATE_BEXIO_EXCLUDE_COMPANIES_AZURE_RI_CONFIG);

  const [items, setItems] = useState<Company[]>([]);

  useEffect(() => setItems(config?.bexioExcludeCompaniesAzureRIConfig || []), [config]);

  const handleChange = useCallback(
    (value: any) => {
      if (!items.find((el) => el.id === value.value.id)) {
        setItems([...items, value.value]);
      }
    },
    [items],
  );

  const handleRemove = useCallback(
    (id: any) => () => {
      const index = items.findIndex((el) => el.id === id);
      setItems([...items.slice(0, index), ...items.slice(index + 1)]);
    },
    [items],
  );

  const handleLoad = useCallback((value: string) => search$.next(value), [search$]);

  const options = loading
    ? []
    : data?.companies.items.map((el) => ({
        value: el,
        label: el.name,
      }));

  const handleSubmit = useCallback(async () => {
    try {
      await mutate({
        variables: {
          payload: items.map((el) => el.id),
        },
      });

      toast.success(i18n._(t`Success!`));
    } catch (e) {
      toastErrorQuery(e);
    }
  }, [items, mutate]);

  return (
    <div>
      <label className="label-lg">
        <Trans>Exclude</Trans>
      </label>

      <div className="history-item form-group">
        <label>
          <Trans>Company</Trans>
        </label>

        <Select
          options={options}
          isLoading={loading}
          cacheOptions
          defaultOptions
          onChange={handleChange}
          onInputChange={handleLoad}
          placeholder={i18n._(t`Select...`)}
          {...selectOptions}
        />
      </div>

      {items.length > 0 && (
        <label className="label-lg">
          <Trans>Companies</Trans>
        </label>
      )}

      {items.map((el, index) => (
        <div key={index} className="history-item d-flex justify-content-between">
          {el.name}

          <button type="button" onClick={handleRemove(el.id)}>
            <i className="icon-delete" />
          </button>
        </div>
      ))}

      <button type="button" className="btn btn-primary ml-0" disabled={submitting} onClick={handleSubmit}>
        {submitting ? <i className="fa fa-spinner fa-spin" /> : <Trans>Save</Trans>}
      </button>
    </div>
  );
};

export default ImportExcludeCompaniesAzure;
