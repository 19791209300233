import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent, useCallback } from 'react';
import Select from 'react-select';
import { GET_COMPANIES_FOR_PERSON } from '../../schema/company';
import { getCompanies, getCompaniesVariables } from '../../schema/types/getCompanies';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {
  value: any;
  onChange: (value: any) => void;
};

const DocumentationCustomerFilter: FunctionComponent<Props> = ({ value, onChange }) => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const model = value
    ? {
        value: value.id,
        label: value.name,
      }
    : null;

  const handleChange = useCallback(
    (value: any) => {
      onChange({
        id: value.value,
        name: value.label,
      });
    },
    [onChange],
  );

  const { data, loading } = useQuery<getCompanies, getCompaniesVariables>(GET_COMPANIES_FOR_PERSON, {
    variables,
  });

  const handleLoad = useCallback((value: string) => search$.next(value), [search$]);

  if (!data && !loading) return <div />;

  const options = loading
    ? []
    : data!.companies.items.map((el) => ({
        value: el.id,
        label: el.name,
      }));

  return (
    <Select
      value={model}
      options={options}
      isLoading={loading}
      onChange={handleChange}
      onInputChange={handleLoad}
      placeholder={i18n._(t`All Customers`)}
      {...selectOptions}
    />
  );
};

export default DocumentationCustomerFilter;
