import gql from 'graphql-tag';

export const CREATE_VENDOR_REPORT = gql`
  mutation createVendorReport($payload: CreateVendorReportPayload!) {
    createVendorReport(payload: $payload)
  }
`;

export const CREATE_INVOICE_REPORT = gql`
  mutation createInvoiceReport($payload: CreateInvoiceReportPayload!) {
    createInvoiceReport(payload: $payload)
  }
`;

export const CREATE_TIME_TRACKER_REPORT = gql`
  mutation createTimeTrackerReport($payload: CreateTimeTrackerReportPayload!) {
    createTimeTrackerReport(payload: $payload)
  }
`;

export const CREATE_CONTACT_REPORT = gql`
  mutation createContactReport($payload: CreateContactReportPayload!) {
    createContactReport(payload: $payload)
  }
`;

export const CREATE_ASSET_REPORT = gql`
  mutation createAssetReport($payload: CreateAssetReportPayload!) {
    createAssetReport(payload: $payload)
  }
`;

export const CREATE_CONTRACT_REPORT = gql`
  mutation createContractReport($payload: CreateContractReportPayload!) {
    createContractReport(payload: $payload)
  }
`;

export const GENERATE_INVOICE_REPORT = gql`
  mutation generateInvoiceReport($payload: GenerateInvoiceReportPayload!) {
    generateInvoiceReport(payload: $payload)
  }
`;

export const GENERATE_TIME_TRACKER_INVOICE_REPORT = gql`
  mutation generateTimeTrackerInvoiceReport($payload: GenerateTimeTrackerInvoicePayload!) {
    generateTimeTrackerInvoiceReport(payload: $payload)
  }
`;

export const CREATE_M365_REPORT = gql`
  mutation createM365Report($payload: CreateM365ReportPayload!) {
    createM365Report(payload: $payload)
  }
`;

export const CREATE_REMINDER_REPORT = gql`
  mutation createReminderReport($payload: CreateReminderReportPayload!) {
    createReminderReport(payload: $payload)
  }
`;

export const CREATE_REPORT_LOG_REPORT = gql`
  mutation createReportLogReport($payload: CreateReportLogReportPayload!) {
    createReportLogReport(payload: $payload)
  }
`;
