import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { getStorage, setStorage } from '../utils/storage';
import { localeState } from './types/localeState';

export const localeInitialState = {
  language: getStorage('language') || 'en',
  __typename: 'LocaleState',
};

export const GET_LOCALE_STATE = gql`
  query localeState {
    localeState @client {
      language
    }
  }
`;

export const TOGGLE_LOCALE = gql`
  mutation toggleLocale($payload: String!) {
    toggleLocale(payload: $payload) @client
  }
`;

export const toggleLocale = (_root: any, variables: any, { cache }: any) => {
  const { localeState } = cache.readQuery({
    query: GET_LOCALE_STATE,
  });

  const language = variables.payload;
  setStorage('language', language);

  cache.writeQuery({
    query: GET_LOCALE_STATE,
    data: {
      localeState: {
        ...localeState,
        language,
      },
    },
  });

  return language;
};

export const useLanguage = () => {
  const locale = useQuery<localeState>(GET_LOCALE_STATE);
  return locale.data!.localeState.language;
};
