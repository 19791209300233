import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { Gender } from '../../schema/graphql-global-types';
import FieldCheckbox from '../form/FieldCheckbox';
import FieldErrorMessage from '../form/FieldErrorMessage';
import ImageUploader from '../form/ImageUploader';
import PersonCompanyForm from './PersonCompanyForm';
import PersonTagForm from './PersonTagForm';

type Props = {};

const PersonDetailForm: FunctionComponent<Props> = () => {
  return (
    <div className="person-detail-form">
      <div className="card-header">
        <Trans>Person Details</Trans>
      </div>

      <div className="meta">
        <div className="avatar form-group">
          <label>
            <Trans>Avatar</Trans>
          </label>
          <Field name="avatar.url">
            {({ field, form }: { field: any; form: FormikProps<any> }) => (
              <ImageUploader
                onUpload={(id) => form.setFieldValue('avatarId', id)}
                image={field.value}
                placeholder="300x300"
              />
            )}
          </Field>
        </div>

        <div className="info">
          <div className="form-group">
            <label>
              <Trans>First Name</Trans>
            </label>
            <Field type="text" className="form-control" name="firstName" autoFocus />

            <FieldErrorMessage name="firstName" />
          </div>

          <div className="form-group">
            <label>
              <Trans>Last Name</Trans>
            </label>
            <Field type="text" className="form-control" name="lastName" />

            <FieldErrorMessage name="lastName" />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>
              <Trans>Gender</Trans>
            </label>
            <Field component="select" className="form-control" name="gender">
              <option value={Gender.Unspecified}>{i18n._(t`Unspecified`)}</option>
              <option value={Gender.Male}>{i18n._(t`Male`)}</option>
              <option value={Gender.Female}>{i18n._(t`Female`)}</option>
            </Field>

            <FieldErrorMessage name="gender" />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              <Trans>Position</Trans>
            </label>
            <Field type="text" className="form-control" name="function" />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              <Trans>Tags</Trans>
            </label>
            <PersonTagForm />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="mb-3">&nbsp;</label>
            <FieldCheckbox name="isVIP" label={i18n._(t`Set as VIP`)} />
          </div>
        </div>

        <div className="col-md-6">
          <PersonCompanyForm />
        </div>
      </div>
    </div>
  );
};

export default PersonDetailForm;
