import { gql } from '@apollo/client';
// @ts-ignore
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
// @ts-ignore
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// @ts-ignore
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
// @ts-ignore
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
// @ts-ignore
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
// @ts-ignore
import Blockquote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
// @ts-ignore
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
// @ts-ignore
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// @ts-ignore
import Fontcolor from '@ckeditor/ckeditor5-font/src/fontcolor';
// @ts-ignore
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
// @ts-ignore
import Image from '@ckeditor/ckeditor5-image/src/image';
// @ts-ignore
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
// @ts-ignore
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
// @ts-ignore
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
// @ts-ignore
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
// @ts-ignore
import Link from '@ckeditor/ckeditor5-link/src/link';
// @ts-ignore
import List from '@ckeditor/ckeditor5-list/src/list';
// @ts-ignore
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
// @ts-ignore
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
// @ts-ignore
import Table from '@ckeditor/ckeditor5-table/src/table';
// @ts-ignore
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
// @ts-ignore
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
// @ts-ignore
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
// @ts-ignore
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { client } from '../..';
import { getStorage } from '../../utils/storage';

const storageObjectDownloadQuery = gql`
  query storageObjectDownloadUrl($storageId: ID!) {
    storageObject(id: $storageId) {
      url
    }
  }
`;

class PrivateImageEditting extends Plugin {
  init() {
    const editor = (this as any).editor as any;
    const imageUtils = editor.plugins.get('ImageUtils');
    editor.conversion.for('downcast').add((dispatcher: any) => {
      dispatcher.on('attribute:src:imageBlock', dispatch);
      dispatcher.on('attribute:src:imageInline', dispatch);
    });
    function dispatch(_evt: any, data: any, conversionApi: any) {
      // const writer = conversionApi.writer;
      const element = conversionApi.mapper.toViewElement(data.item);
      const img = imageUtils.getViewImageFromWidget(element);
      if (data.attributeNewValue) {
        const src = data.attributeNewValue;
        if (src && src.startsWith('cid')) {
          client
            .query({ query: storageObjectDownloadQuery, variables: { storageId: src.slice(4) } })
            .then((response) => {
              const imageUrl = response.data?.storageObject?.url;
              editor.editing.view.change((writer: any) => {
                writer.setAttribute('src', imageUrl, img);
              });
            });
        }
      }
    }
  }
}

type Props = {
  name: string;
};

const FieldEditor: FunctionComponent<Props> = ({ name }) => {
  const ref = React.useRef<any>();

  const config = {
    plugins: [
      Essentials,
      RemoveFormat,
      Heading,
      Fontcolor,
      Alignment,
      Bold,
      Italic,
      Underline,
      Strikethrough,
      List,
      Indent,
      Link,
      Blockquote,
      Paragraph,
      MediaEmbed,
      Table,
      TableToolbar,
      TableProperties,
      TableCellProperties,
      Image,
      ImageToolbar,
      ImageStyle,
      ImageUpload,
      SimpleUploadAdapter,
    ],
    toolbar: {
      items: [
        'removeFormat',
        'undo',
        'redo',
        '|',
        'heading',
        'fontColor',
        'alignment',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        '|',
        'bulletedList',
        'numberedList',
        'outdent',
        'indent',
        '|',
        'link',
        'blockQuote',
        'imageUpload',
        'mediaEmbed',
        'insertTable',
      ],
    },
    mediaEmbed: {
      previewsInData: true,
      removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook'],
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
      tableProperties: {},
      tableCellProperties: {},
    },
    simpleUpload: {
      uploadUrl: `${process.env.REACT_APP_URL}ckeditor/imageUpload`,
      // withCredentials: true,
      headers: {
        Authorization: `Bearer ${getStorage('accessToken')}`,
      },
    },
    image: {
      styles: ['alignLeft', 'alignCenter', 'alignRight'],
      toolbar: ['imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight'],
    },
    extraPlugins: [PrivateImageEditting],
  };

  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (_event: any, editor: any) => {
          form.setFieldValue(name, editor.getData());
        };

        return <CKEditor ref={ref} editor={ClassicEditor} data={field.value} onChange={handleChange} config={config} />;
      }}
    </Field>
  );
};

export default FieldEditor;
