import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import Modal from 'react-modal';
import ReactTable, { CellInfo } from 'react-table';
import ReactTooltip from 'react-tooltip';
import { dateTimeOptions } from '../../utils/const';
import DateFormat from '../common/DateFormat';
import DocumentationRecoverModal from './DocumentationRecoverModal';

type Props = {
  show: boolean;
  id: any;
  items: [];
  onClose: () => void;
};

const DocumentationVersionModal: FunctionComponent<Props> = ({ show, id, items, onClose }) => {
  const [showModal, setShowModal] = useState(false);
  const [recovering, setRecovering] = useState(null);

  const toggleRecover = useCallback(
    (versionId) => () => {
      setRecovering(versionId);
      setShowModal(true);
    },
    [setRecovering, setShowModal],
  );

  const handleClose = useCallback(() => {
    setRecovering(null);
    setShowModal(false);
  }, [setRecovering, setShowModal]);

  const columns = [
    {
      Header: i18n._(t`Date`),
      accessor: 'createdAt',
      className: 'text-center',
      Cell: (cell: CellInfo) => <DateFormat value={new Date(cell.value)} format={dateTimeOptions} />,
    },
    {
      Header: i18n._(t`Title`),
      accessor: 'title',
    },
    {
      Header: i18n._(t`Actions`),
      width: 100,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <>
          <a
            href={cell.original.publicUrl}
            data-tip
            data-for={`preview-${cell.original.id}`}
            className="button text-muted"
            target="_blank"
          >
            <i className="icon-preview" />
          </a>
          <ReactTooltip id={`preview-${cell.original.id}`}>
            <Trans>Preview</Trans>
          </ReactTooltip>

          <span
            data-tip
            data-for={`recover-${cell.original.id}`}
            className="button ml-2"
            onClick={toggleRecover(cell.original.id)}
          >
            <i className="icon-restore" />
          </span>
          <ReactTooltip id={`recover-${cell.original.id}`}>
            <Trans>Recover</Trans>
          </ReactTooltip>
        </>
      ),
    },
  ];

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Version History</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              <ReactTable data={items} columns={columns} />
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-link" onClick={onClose}>
                <Trans>Close</Trans>
              </button>
            </div>
          </div>
        </div>
      </div>

      <DocumentationRecoverModal show={showModal} id={id} versionId={recovering} onClose={handleClose} />
    </Modal>
  );
};

export default DocumentationVersionModal;
