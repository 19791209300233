import { cloneDeep } from 'lodash';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { pick } from 'lodash';
import { Formik } from 'formik';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import ContractUpdateForms from '../components/contract/ContractUpdateForms';
import ErrorQuery from '../components/form/ErrorQuery';
import LoadingForm from '../components/common/LoadingForm';
import { GET_CONTRACT, UPDATE_CONTRACT, validateContractForm } from '../schema/contract';
import { getContract, getContractVariables } from '../schema/types/getContract';
import { updateContract, updateContractVariables } from '../schema/types/updateContract';
import { DefaultRouteParams } from '../utils/const';
import { toastErrorQuery } from '../utils/error';
import NotFoundPage from './NotFoundPage';

const keys = [
  'name',
  'customerId',
  'cycle',
  'yearlyInterval',
  'startedAt',
  'adjustmentDate',
  'products',
  'reportingProducts',
  'isAutoRenew',
  'contractLength',
  'cancelPeriod',
];

const now = new Date();
const next =
  now.getMonth() === 11
    ? new Date(Date.UTC(now.getFullYear() + 1, 0, 1))
    : new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 1));

const transform = (model: any, adjustment: string) => {
  const current = new Date(adjustment);
  model.adjustmentDate = new Date(current.getFullYear(), current.getMonth(), 2);

  const start = new Date(model.startedAt);
  model.startedAt = new Date(start.getFullYear(), start.getMonth(), start.getDate());

  model.customerId = model.customer.id;
  model.products = model.products.map((el: any) => ({
    ...el,
    productId: el.product.id,
  }));
  model.reportingProducts = model.reportingProducts.map((el: any) => ({
    ...el,
    productId: el.product.id,
    contractTypeId: el.contractType ? el.contractType.id : null,
  }));

  return model;
};

const ContractUpdatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ match }) => {
  const [mutate] = useMutation<updateContract, updateContractVariables>(UPDATE_CONTRACT);

  const [showForm, setShowForm] = useState(true);
  const [adjustment, setAdjustment] = useState(next.toISOString());

  const handleAdjustmentChange = useCallback((item: string) => setAdjustment(item), [setAdjustment]);

  const { data, loading, error, refetch } = useQuery<getContract, getContractVariables>(GET_CONTRACT, {
    variables: {
      query: match.params.id,
      query2: {
        effectiveDate: adjustment,
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (loading || !showForm) return <LoadingForm />;
  if (error) return <ErrorQuery error={error} />;
  if (!data) return <NotFoundPage />;

  const model = transform(cloneDeep(data.contract), adjustment);

  return (
    <div className="contract-form-page">
      <Formik
        initialValues={model}
        validationSchema={yup.object().shape(validateContractForm)}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const payload: any = pick(
              {
                ...values,
                yearlyInterval: Number(values.yearlyInterval),
              },
              keys,
            );

            const adjustment = new Date(payload.adjustmentDate);
            payload.adjustmentDate = new Date(Date.UTC(adjustment.getFullYear(), adjustment.getMonth(), 1));

            const now = new Date(payload.startedAt);
            payload.startedAt = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));

            payload.reportingProducts = payload.reportingProducts.map((el: any) => {
              let parentIndex = payload.products.findIndex(
                (product: any) => el.virtualProductId && product.virtualProductId === el.virtualProductId,
              );
              parentIndex = parentIndex !== -1 ? parentIndex : null;

              return {
                ...pick(el, ['productId', 'threadId', 'parentIndex', 'pieces', 'contractTypeId', 'manual']),
                pieces: Number(el.pieces),
                parentIndex,
              };
            });

            payload.products = payload.products.map((el: any) => ({
              ...pick(el, ['productId', 'threadId', 'title', 'price', 'pieces', 'discountPercent']),
              price: Number(el.price),
              pieces: Number(el.pieces),
              discountPercent: Number(el.discountPercent),
            }));

            const result: any = await mutate({
              variables: {
                id: match.params.id,
                payload,
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              toast.success(i18n._(t`Success!`));
              await refetch();
              setShowForm(false);

              setTimeout(() => setShowForm(true), 250);
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <ContractUpdateForms
            loading={isSubmitting}
            onAdjustmentChange={handleAdjustmentChange}
            contractActivities={data.contract.productAdjustments.items}
            reportingActivities={data.contract.reportingProductAdjustments.items}
          />
        )}
      </Formik>
    </div>
  );
};

export default withRouter(ContractUpdatePage);
