import { useQuery } from '@apollo/client';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { GET_PRODUCT_CONTRACT_TYPES } from '../../schema/productContractType';
import { getProductContractTypes, getProductContractTypesVariables } from '../../schema/types/getProductContractTypes';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {
  name: string;
};

const ProductContractForm: FunctionComponent<Props> = ({ name }) => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getProductContractTypes, getProductContractTypesVariables>(
    GET_PRODUCT_CONTRACT_TYPES,
    {
      variables,
      fetchPolicy: 'cache-first',
    },
  );

  if (!data && !loading) return <div />;

  return (
    <Field name={`${name}.contractType`}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (value: any) => {
          form.setFieldValue(`${name}.contractTypeId`, value.value);
          form.setFieldValue(`${name}.contractType`, {
            id: value.value,
            name: value.label,
          });
        };

        const handleLoad = (value: string) => search$.next(value);

        const model = field.value
          ? {
              value: field.value.id,
              label: field.value.name,
            }
          : null;

        const options = loading
          ? []
          : data!.productContractTypes.items.map((el: any) => ({
              value: el.id,
              label: el.name,
            }));

        return (
          <Select
            value={model}
            options={options}
            isLoading={loading}
            onChange={handleChange}
            onInputChange={handleLoad}
            placeholder=""
            {...selectOptions}
          />
        );
      }}
    </Field>
  );
};

export default ProductContractForm;
