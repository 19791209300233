import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent, useCallback } from 'react';
import CreatableSelect from 'react-select/creatable';
import { selectOptions } from '../../utils/const';

type Props = {
  name: string;
  search$: any;
  loading: boolean;
  data?: any[];
  placeholder?: string | null;
};

const TagSelect: FunctionComponent<Props> = ({ name, search$, loading, data, placeholder }) => {
  const handleLoad = useCallback((value: string) => search$.next(value), [search$]);

  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (values: any) => {
          form.setFieldValue(name, values ? values.map((el: any) => el.value) : []);
        };

        const model = field.value
          ? field.value.map((el: any) => ({
              value: el,
              label: el,
            }))
          : [];

        const options = loading
          ? []
          : data?.map((el: any) => ({
              value: el.name,
              label: el.name,
            })) || [];

        return (
          <CreatableSelect
            value={model}
            options={options}
            isLoading={loading}
            isMulti
            cacheOptions
            defaultOptions
            onChange={handleChange}
            onInputChange={handleLoad}
            placeholder={placeholder || i18n._(t`Select tags...`)}
            {...selectOptions}
          />
        );
      }}
    </Field>
  );
};

export default TagSelect;
