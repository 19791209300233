import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import { Field, Form } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ButtonSubmit from '../form/ButtonSubmit';
import DocumentationEditor from './DocumentationEditor';
import DocumentationEditorSortable from './DocumentationEditorSortable';
import DocumentationSaveTemplate from './DocumentationSaveTemplate';
import DocumentationSidebar from './DocumentationSidebar';

type Props = {
  loading: boolean;
};

const DocumentationCreateForm: FunctionComponent<Props> = ({ loading }) => {
  const [sortable, setSortable] = useState(false);

  const handleSortable = useCallback(() => setSortable((prevState) => !prevState), []);

  return (
    <Form>
      <div className="form-breadcrumbs-area">
        <NavLink to="/documentation/all">
          <Trans>Documentations</Trans>
        </NavLink>

        <i className="fa fa-angle-left" />

        <Field name="id">
          {({ field }: { field: FieldInputProps<string> }) => {
            if (field.value) return <Trans>Edit document</Trans>;

            return <Trans>Add new document</Trans>;
          }}
        </Field>
      </div>

      <div className="table-actions form-table-actions">
        <div className="breadcrumbs-area">
          <Field name="title">
            {({ field }: { field: FieldInputProps<string> }) => {
              return <div className="main-title">{field.value}</div>;
            }}
          </Field>
        </div>

        <DocumentationSaveTemplate />

        <ButtonSubmit className="btn btn-primary btn-sm ml-4" title={i18n._(t`Save`)} loading={loading} />
      </div>

      <div className="documentation-form">
        <div className={classNames('documentation-editor', { 'd-none': sortable })}>
          <DocumentationEditor />
        </div>

        {sortable && <DocumentationEditorSortable />}

        <DocumentationSidebar onSortable={handleSortable} />
      </div>
    </Form>
  );
};

export default DocumentationCreateForm;
