import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import Select from 'react-select';
import { OrderDirection, UsersQueryOrderBy } from '../../schema/graphql-global-types';
import { CLOSE_TICKET, UPDATE_TICKET_RESPONSIBLE_USER } from '../../schema/ticket';
import { closeTicket, closeTicketVariables } from '../../schema/types/closeTicket';
import { getUsers, getUsersVariables } from '../../schema/types/getUsers';
import {
  updateTicketResponsibleUser,
  updateTicketResponsibleUserVariables,
} from '../../schema/types/updateTicketResponsibleUser';
import { GET_USERS_FOR_COMPANY } from '../../schema/user';
import { selectOptions } from '../../utils/const';
import { toastErrorQuery } from '../../utils/error';
import { useVariables, useViewer } from '../../utils/hooks';

type Props = {
  selectedTickets: string[];
  onSuccess: () => void;
};

const TicketBatchActions: FunctionComponent<Props> = ({ selectedTickets, onSuccess }) => {
  const me = useViewer();

  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables({
    orderBy: UsersQueryOrderBy.fullName,
    orderDirection: OrderDirection.Asc,
  });

  const variables = useMemo(
    () => ({
      query: {
        limit,
        offset,
        orderBy,
        orderDirection,
        filter: {
          search,
          archived,
          supportDisabled: false,
          ...filter,
        },
      },
    }),
    [limit, offset, orderBy, orderDirection, search, archived, filter],
  );

  const initial = useMemo(
    () => ({
      value: 'select',
      label: i18n._(t`Assign to...`),
    }),
    [],
  );

  const [selected, setSelected] = useState(initial);

  const [mutateCloseTicket] = useMutation<closeTicket, closeTicketVariables>(CLOSE_TICKET);

  const [mutateUpdateTicketResponsibleUser] = useMutation<
    updateTicketResponsibleUser,
    updateTicketResponsibleUserVariables
  >(UPDATE_TICKET_RESPONSIBLE_USER);

  const handleChange = useCallback(
    async (value: any) => {
      if (value.value !== 'select') {
        setSelected(value);
        let result: any[];

        try {
          if (value.value === 'close') {
            result = await Promise.all(
              selectedTickets.map((id) =>
                mutateCloseTicket({
                  variables: {
                    id,
                    payload: {
                      message: null,
                    },
                  },
                }),
              ),
            );
          } else if (value.value === 'me') {
            result = await Promise.all(
              selectedTickets.map((id) =>
                mutateUpdateTicketResponsibleUser({
                  variables: {
                    id: id,
                    payload: {
                      responsibleUserId: me.data!.viewer.user!.id,
                    },
                  },
                }),
              ),
            );
          } else {
            result = await Promise.all(
              selectedTickets.map((id) =>
                mutateUpdateTicketResponsibleUser({
                  variables: {
                    id: id,
                    payload: {
                      responsibleUserId: value.value,
                    },
                  },
                }),
              ),
            );
          }

          if (result[0] && result[0].errors) {
            toastErrorQuery(result[0].errors);
          } else {
            toast.success(i18n._(t`Success!`));

            setSelected(initial);
            onSuccess();
          }
        } catch (e) {
          toastErrorQuery(e);
        }
      }
    },
    [initial, me, setSelected, onSuccess, selectedTickets, mutateCloseTicket, mutateUpdateTicketResponsibleUser],
  );

  const { data, loading } = useQuery<getUsers, getUsersVariables>(GET_USERS_FOR_COMPANY, {
    variables,
    fetchPolicy: 'cache-first',
  });

  const handleLoad = useCallback((value: string) => search$.next(value), [search$]);

  const options = loading
    ? []
    : data!.users.items.map((el) => ({
        value: el.id,
        label: el.fullName,
      }));

  options.unshift({
    value: 'me',
    label: i18n._(t`Assign to me`),
  });

  options.unshift({
    value: 'close',
    label: i18n._(t`Close (status change only)`),
  });

  options.unshift(initial);

  return (
    <Select
      value={selected}
      options={options}
      isLoading={loading}
      onChange={handleChange}
      onInputChange={handleLoad}
      placeholder={i18n._(t`Show tickets from`)}
      {...selectOptions}
    />
  );
};

export default TicketBatchActions;
