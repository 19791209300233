import { cloneDeep } from 'lodash';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { pick } from 'lodash';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import ErrorQuery from '../components/form/ErrorQuery';
import LoadingForm from '../components/common/LoadingForm';
import TextTemplateCreateForms from '../components/textTemplate/TextTemplateCreateForms';
import { GET_TEXT_TEMPLATE, UPDATE_TEXT_TEMPLATE, validateTextTemplateForm } from '../schema/textTemplate';
import { getTextTemplate, getTextTemplateVariables } from '../schema/types/getTextTemplate';
import { updateTextTemplate, updateTextTemplateVariables } from '../schema/types/updateTextTemplate';
import { DefaultRouteParams } from '../utils/const';
import { toastErrorQuery } from '../utils/error';
import NotFoundPage from './NotFoundPage';

const keys = ['systemName', 'title', 'content', 'public', 'to', 'cc', 'bcc', 'messageCenterTo'];

const TextTemplateUpdatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ match, history }) => {
  const [mutate] = useMutation<updateTextTemplate, updateTextTemplateVariables>(UPDATE_TEXT_TEMPLATE);

  const { data, loading, error } = useQuery<getTextTemplate, getTextTemplateVariables>(GET_TEXT_TEMPLATE, {
    variables: { query: match.params.id },
  });

  if (!data && loading) return <LoadingForm />;
  if (error) return <ErrorQuery error={error} />;
  if (!data) return <NotFoundPage />;

  const model: any = cloneDeep(data.textTemplate);
  model.to = model.to || [];
  model.cc = model.cc || [];
  model.bcc = model.bcc || [];

  if (model.type === 'Public') {
    model.public = true;
  } else if (model.type === 'Private') {
    model.public = false;
  }

  return (
    <div className="document-form-page">
      <Formik
        enableReinitialize
        initialValues={model}
        validationSchema={yup.object().shape(validateTextTemplateForm)}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const payload: any = pick(values, keys);

            const result: any = await mutate({
              variables: {
                id: match.params.id,
                payload,
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              toast.success(i18n._(t`Success!`));
              history.push('/settings/text-template');
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => <TextTemplateCreateForms loading={isSubmitting} />}
      </Formik>
    </div>
  );
};

export default withRouter(TextTemplateUpdatePage);
