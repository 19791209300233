import '@fortawesome/fontawesome-free/css/all.css';
import 'react-table/react-table.css';
import 'placeholder-loading/dist/css/placeholder-loading.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-input-2/lib/style.css';

import './css/bootstrap-reboot.css';
import './css/bootstrap-grid.css';
import './css/bootstrap-spacing.css';
import './css/main.scss';

import { i18n } from '@lingui/core';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core';
import { HttpLink } from '@apollo/client';
import React from 'react';
import { ApolloProvider, from } from '@apollo/client';
import { registerLocale } from 'react-datepicker';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router-dom';
import { ReactTableDefaults } from 'react-table';
import { ToastContainer } from 'react-toastify';
import dateDe from 'date-fns/locale/de/index';
import App from './App';
import { AppBrowserRouter } from './config/router';
import AcceptOfferPage from './containers/AcceptOfferPage';
import DocumentationPublicPage from './containers/DocumentationPublicPage';
import ForgotPage from './containers/ForgotPage';
import I18nLoader from './components/I18nLoader';
import LoginPage from './containers/LoginPage';
import MfaPage from './containers/MfaPage';
import SelectCompanyPage from './containers/SelectCompanyPage';
import { GET_LOCALE_STATE, localeInitialState } from './schema/locale';
import resolvers from './schema/resolvers';
import { GET_SIDEBAR_STATE, sidebarInitialState } from './schema/sidebar';
import typeDefs from './schema/typeDefs';
import * as serviceWorker from './serviceWorker';
import { authMiddleware, unauthorizedMiddleware } from './utils/auth';
import Modal from 'react-modal';

registerLocale('de', dateDe);

Modal.setAppElement('#root');

const cache = new InMemoryCache();

const link = from([
  authMiddleware,
  unauthorizedMiddleware,
  new HttpLink({
    uri: (operation) => {
      return `${process.env.REACT_APP_API_URL}?operationName=${operation.operationName}`;
    },
    credentials: 'include',
  }),
]);

export const client = new ApolloClient({
  cache,
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  resolvers,
  typeDefs,
});

client.writeQuery({
  query: GET_SIDEBAR_STATE,
  data: {
    sidebarState: sidebarInitialState,
  },
});

client.writeQuery({
  query: GET_LOCALE_STATE,
  data: {
    localeState: localeInitialState,
  },
});

client.writeQuery({
  query: GET_SIDEBAR_STATE,
  data: {
    sidebarState: sidebarInitialState,
  },
});

Object.assign(ReactTableDefaults, {
  minRows: 0,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <I18nLoader i18n={i18n}>
      <AppBrowserRouter>
        <Switch>
          <Route path="/public" component={DocumentationPublicPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/forgot" component={ForgotPage} />
          <Route path="/admin-login" component={LoginPage} />
          <Route path="/admin-forgot" component={ForgotPage} />
          <Route path="/select-company" component={SelectCompanyPage} />
          <Route path="/mfa/:id" component={MfaPage} />
          <Route path="/offer-accept/:id" component={AcceptOfferPage} />
          <Route path="/" component={App} />
        </Switch>
        <ToastContainer />
      </AppBrowserRouter>
    </I18nLoader>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
