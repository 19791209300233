import { Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { GET_VIEWER, UPDATE_FAVORITE_TEMPLATES } from '../../schema/auth';
import { DocumentDetail } from '../../schema/types/DocumentDetail';
import { TextTemplateDetail } from '../../schema/types/TextTemplateDetail';
import { updateFavoriteTemplates, updateFavoriteTemplatesVariables } from '../../schema/types/updateFavoriteTemplates';
import { useViewer } from '../../utils/hooks';
import TicketDocTemModal from './TicketDocTemModal';
import { cloneDeep } from 'lodash';

type Props = {
  addSubject?: boolean;
};

const TicketDocTem: FunctionComponent<Props> = ({ addSubject }) => {
  const [showDocTem, setShowDocTem] = useState(false);

  const me = useViewer();

  const toggleShowDocTem = useCallback(() => setShowDocTem((prevState) => !prevState), [setShowDocTem]);

  const [mutate] = useMutation<updateFavoriteTemplates, updateFavoriteTemplatesVariables>(UPDATE_FAVORITE_TEMPLATES, {
    update: (proxy, { data, errors }) => {
      if (data && !errors) {
        const results: any = cloneDeep(proxy.readQuery({ query: GET_VIEWER }));
        results.viewer = data.updateFavoriteTemplates;
        proxy.writeQuery({
          query: GET_VIEWER,
          data: results,
        });
      }
    },
  });

  const handleChangeFavorites = useCallback(
    (items: any[]) => {
      mutate({
        variables: {
          ids: items.map((el) => el.id),
        },
      });
    },
    [mutate],
  );

  if (!me.data) return <div />;

  return (
    <Field name="id">
      {({ form }: { form: FormikProps<any> }) => {
        const handleSelectTemplate = (item: TextTemplateDetail) => {
          form.setFieldValue('content', item.content);
          if (addSubject) {
            form.setFieldValue('subject', item.title);
          }
          toggleShowDocTem();
        };

        const selectTemplate = (item: TextTemplateDetail) => () => {
          form.setFieldValue('content', item.content);
          if (addSubject) {
            form.setFieldValue('subject', item.title);
          }
        };

        const handleSelectDocument = (item: DocumentDetail) => {
          const link = `<a href="${item.publicUrl}">${item.title}</a>`;
          form.setFieldValue('content', `${form.values.content}<p>${link}</p>`);
          if (addSubject) {
            form.setFieldValue('subject', item.title);
          }
          toggleShowDocTem();
        };

        return (
          <div className="dropdowns text-right">
            <a className="text-info d-block pb-2" onClick={toggleShowDocTem}>
              <Trans>Templates & Documentations</Trans>
              <i className="fa fa-chevron-down ml-2 text-sm" />
            </a>

            {me.data!.viewer.favoriteTemplates.length > 0 && (
              <div className="dropdown">
                {me.data!.viewer.favoriteTemplates.map((el: any) => (
                  <a key={el.id} className="text-info" onClick={selectTemplate(el)}>
                    {el.title}
                  </a>
                ))}
              </div>
            )}

            <TicketDocTemModal
              show={showDocTem}
              favorites={me.data!.viewer.favoriteTemplates}
              onChangeFavorites={handleChangeFavorites}
              onSelectTemplate={handleSelectTemplate}
              onSelectDocument={handleSelectDocument}
              onClose={toggleShowDocTem}
            />
          </div>
        );
      }}
    </Field>
  );
};

export default TicketDocTem;
