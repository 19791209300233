import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import { GET_SMS } from '../../schema/sms';
import { getSmsLogs, getSmsLogsVariables } from '../../schema/types/getSmsLogs';
import { Log } from '../../schema/types/Log';
import { useVariables } from '../../utils/hooks';
import HistoryItem from '../common/HistoryItem';

type Props = unknown;

const SMSLogs: FC<Props> = () => {
  const { limit, offset } = useVariables({
    limit: 10,
  });

  const variables = {
    query: {
      limit,
      offset,
    },
  };

  const { data, loading } = useQuery<getSmsLogs, getSmsLogsVariables>(GET_SMS, {
    variables,
    fetchPolicy: 'cache-first',
  });

  if (loading)
    return (
      <div>
        <Trans>Loading</Trans>
      </div>
    );
  if (!data) return null;

  return (
    <>
      {data.smsLogs.items.map((el: Log) => (
        <HistoryItem
          key={el.id}
          date={el.createdAt}
          creator={el.creator}
          content={el.content.replace(/\r\n|\n|\r/g, '')}
          tags={el.tags}
        />
      ))}
    </>
  );
};

export default SMSLogs;
