import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import { GET_OFFER_TAGS } from '../../schema/tag';
import { getOfferTags, getOfferTagsVariables } from '../../schema/types/getOfferTags';
import { useVariables } from '../../utils/hooks';
import TagSelect from '../form/TagSelect';

type Props = {};

const OfferTagForm: FunctionComponent<Props> = () => {
  const { search$, search, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
      },
    },
  };

  const { data, loading } = useQuery<getOfferTags, getOfferTagsVariables>(GET_OFFER_TAGS, {
    variables,
    fetchPolicy: 'cache-first',
  });

  if (!data && !loading) return <div />;

  return (
    <TagSelect
      name="tagNames"
      search$={search$}
      loading={loading}
      data={data?.offerTags.items}
      placeholder={i18n._(t`Tags`)}
    />
  );
};

export default OfferTagForm;
