import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Formik, Form, FormikProps, Field } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_BEXIO_CONFIG } from '../../schema/config';
import { CREATE_INVOICE_REPORT } from '../../schema/report';
import { createInvoiceReport, createInvoiceReportVariables } from '../../schema/types/createInvoiceReport';
import { getBexioConfig } from '../../schema/types/getBexioConfig';
import { toastErrorQuery } from '../../utils/error';
import ReportCompanyForm from './ReportCompanyForm';
import ReportContractForm from './ReportContractForm';
import ReportDownloadModal from './ReportDownloadModal';
import ReportGenerateInvoiceModal from './ReportGenerateInvoiceModal';
import ReportMailModal from './ReportMailModal';
import ReportProductForm from './ReportProductForm';

type Props = {
  effectiveDate: string;
};

const ReportInvoiceForm: FunctionComponent<Props> = ({ effectiveDate }) => {
  const [url, setUrl] = useState('');

  const [showMail, setShowMail] = useState(false);
  const toggleShowMail = useCallback(() => setShowMail((prevState) => !prevState), [setShowMail]);

  const [showDownload, setShowDownload] = useState(false);
  const toggleShowDownload = useCallback(() => {
    setShowDownload((prevState) => {
      if (!prevState) {
        setUrl('');
      }

      return !prevState;
    });
  }, [setShowDownload, setUrl]);

  const [showGenerate, setShowGenerate] = useState(false);
  const toggleShowGenerate = useCallback(() => setShowGenerate((prevState) => !prevState), [setShowGenerate]);

  const [mutate] = useMutation<createInvoiceReport, createInvoiceReportVariables>(CREATE_INVOICE_REPORT);

  const { data } = useQuery<getBexioConfig>(GET_BEXIO_CONFIG);

  const model = {
    includeFilter: {
      companyIds: [],
      contractIds: [],
      productIds: [],
    },
    excludeFilter: {
      companyIds: [],
      contractIds: [],
      productIds: [],
    },
  };

  const now = new Date(effectiveDate);
  const date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())).toISOString();

  let sendToEmail: any = null;

  return (
    <Formik
      initialValues={model}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const result: any = await mutate({
            variables: {
              payload: {
                effectiveDate: date,
                ...values,
                sendToEmail,
              },
            },
          });

          if (result.errors) {
            toastErrorQuery(result.errors);
          } else {
            setUrl(result.data.createInvoiceReport);
            toast.success(i18n._(t`Success!`));
          }
        } catch (e) {
          toastErrorQuery(e);
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting, submitForm }) => {
        const handleSubmitEmail = (email: string) => {
          sendToEmail = email;
          submitForm();
        };

        const handleSubmitDownload = () => {
          sendToEmail = null;
          submitForm();
        };

        return (
          <Form className="panel">
            <div className="card-header d-lg-flex align-items-center">
              <h2 className="block-title">
                <Trans>Invoice Report</Trans>
              </h2>

              {data && data.bexioConfig && data.bexioConfig.apiKey.length > 0 && (
                <button type="button" className="btn btn-bexio" onClick={toggleShowGenerate}>
                  <span className="icons-bexio" />
                  <Trans>Generate Invoice</Trans>
                </button>
              )}

              <button type="button" className="btn btn-download" onClick={toggleShowDownload}>
                <i className="icon-download" />
                <Trans>Download</Trans>
              </button>

              <button type="button" className="btn btn-send" onClick={toggleShowMail}>
                <i className="icon-send-email" />
                <Trans>Send Email</Trans>
              </button>
            </div>

            <div className="row d-flex align-items-center">
              <div className="col-xl-6 panel-column-left">
                <label className="label-lg">
                  <Trans>Include</Trans>
                </label>

                <div className="form-group">
                  <label>
                    <Trans>Customers</Trans>
                  </label>

                  <ReportCompanyForm name="includeFilter.companyIds" />
                </div>

                <div className="form-group">
                  <label>
                    <Trans>Contracts</Trans>
                  </label>

                  <ReportContractForm name="includeFilter.contractIds" />
                </div>

                <div className="form-group">
                  <label>
                    <Trans>Products</Trans>
                  </label>

                  <ReportProductForm name="includeFilter.productIds" />
                </div>
              </div>

              <div className="col-xl-6">
                <label className="label-lg">
                  <Trans>Exclude</Trans>
                </label>

                <div className="form-group">
                  <label>
                    <Trans>Customers</Trans>
                  </label>

                  <ReportCompanyForm name="excludeFilter.companyIds" />
                </div>

                <div className="form-group">
                  <label>
                    <Trans>Contracts</Trans>
                  </label>

                  <ReportContractForm name="excludeFilter.contractIds" />
                </div>

                <div className="form-group">
                  <label>
                    <Trans>Products</Trans>
                  </label>

                  <ReportProductForm name="excludeFilter.productIds" />
                </div>
              </div>
            </div>

            <ReportMailModal
              show={showMail}
              loading={isSubmitting}
              onSubmit={handleSubmitEmail}
              onClose={toggleShowMail}
            />

            <ReportDownloadModal
              show={showDownload}
              loading={isSubmitting}
              url={url}
              onSubmit={handleSubmitDownload}
              onClose={toggleShowDownload}
            />

            <Field name="email">
              {({ form }: { form: FormikProps<any> }) => {
                return (
                  <ReportGenerateInvoiceModal
                    show={showGenerate}
                    effectiveDate={effectiveDate}
                    includeFilter={form.values.includeFilter}
                    excludeFilter={form.values.excludeFilter}
                    onClose={toggleShowGenerate}
                  />
                );
              }}
            </Field>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ReportInvoiceForm;
