import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import { endOfMonth, startOfMonth } from 'date-fns';
import React, { FC } from 'react';
import icon from '../../img/respond-avg.svg';
import { GET_AVG_TICKET_RESPONSE_TIME_MS } from '../../schema/stats';
import { avgTicketResponseTimeMs, avgTicketResponseTimeMsVariables } from '../../schema/types/avgTicketResponseTimeMs';
import { msToTime } from '../../utils/utils';
import ErrorQuery from '../form/ErrorQuery';

const today = new Date();

type Props = {};

const HomeResponseTime: FC<Props> = () => {
  const { data, error } = useQuery<avgTicketResponseTimeMs, avgTicketResponseTimeMsVariables>(
    GET_AVG_TICKET_RESPONSE_TIME_MS,
    {
      variables: {
        filter: {
          from: startOfMonth(today).toISOString(),
          to: endOfMonth(today).toISOString(),
        },
      },
      fetchPolicy: 'cache-first',
    },
  );

  if (error) return <ErrorQuery error={error} />;

  return (
    <div className="item item-4">
      <div className="card">
        <div className="icons">
          <img src={icon} alt="" />
        </div>

        <div className="block-title">
          <Trans>This month's average response time</Trans>
        </div>

        <div className="content time text-info">{msToTime(data?.avgTicketResponseTimeMs || 0)} h</div>
      </div>
    </div>
  );
};

export default HomeResponseTime;
