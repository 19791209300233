import gql from 'graphql-tag';
import { validateRequired } from '../utils/validations';
import { LOG } from './log';

export const GET_SMS = gql`
  query getSmsLogs($query: LogsQuery!) {
    smsLogs(query: $query) {
      limit
      offset
      total
      items {
        ...Log
      }
    }
  }
  ${LOG}
`;

export const SEND_SMS = gql`
  mutation sendSms($payload: SendTicketSmsPayload!) {
    sendSms(payload: $payload)
  }
`;

export const SMSKeys = ['cellphone', 'message', 'shadowLog'];

export const validateSMSForm = {
  cellphone: validateRequired,
  message: validateRequired,
};
