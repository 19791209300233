import gql from 'graphql-tag';
import { validateNumber, validateRequired } from '../utils/validations';
import { ASSET_LOG, ASSET_LOGS } from './log';
import { TAG } from './tag';
import { Asset } from './types/Asset';
import { AssetDetail } from './types/AssetDetail';
import { AssetEvent } from './types/AssetEvent';
import { AssetResponseTime } from './types/AssetResponseTime';

export const ASSET = gql`
  fragment Asset on Asset {
    id
    company {
      id
      name
      responsibleUsers {
        id
        fullName
      }
    }
    name
    assetLocation {
      ...Tag
    }
    assetType {
      ...Tag
    }
    product {
      id
      title
      reactionTime
    }
    tags {
      ...Tag
    }
    uptimeSla
    uptime365: uptime(days: 365)
    uptime30: uptime(days: 30)
    uptime1: uptime(days: 1)
    uptimeStatus
    description
    playbook
    lastLog {
      ...AssetLog
    }
    responsibleUsers {
      id
      fullName
      email
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
    uptimeEvents(limit: 10) {
      type
      durationSeconds
      time
    }
  }
  ${TAG}
  ${ASSET_LOG}
`;

export const ASSET_EVENT = gql`
  fragment AssetEvent on AssetEvent {
    time
    type
  }
`;

export const ASSET_RESPONSE_TIME = gql`
  fragment AssetResponseTime on AssetResponseTime {
    time
    value
  }
`;

export const GET_ASSETS = gql`
  query getAssets($query: AssetsQuery!, $query2: AssetEventsQuery, $query3: AssetResponseTimesQuery) {
    assets(query: $query) {
      limit
      offset
      total
      items {
        ...Asset
        events(query: $query2) {
          ...AssetEvent
        }
        responseTimes(query: $query3) {
          ...AssetResponseTime
        }
      }
    }
  }
  ${ASSET}
  ${ASSET_EVENT}
  ${ASSET_RESPONSE_TIME}
`;

export const GET_DASHBOARD_ASSETS = gql`
  query getDashboardAssets($query1: AssetsQuery!, $query2: AssetEventsQuery, $query3: AssetResponseTimesQuery) {
    assets(query: $query1) {
      limit
      offset
      total
      items {
        id
        name
        events(query: $query2) {
          ...AssetEvent
        }
        responseTimes(query: $query3) {
          ...AssetResponseTime
        }
      }
    }
  }
  ${ASSET_EVENT}
  ${ASSET_RESPONSE_TIME}
`;

export const PERSON_GET_ASSETS = gql`
  query personGetAssets($id: ID!, $query: AssetsQuery!, $query2: AssetEventsQuery, $query3: AssetResponseTimesQuery) {
    company(id: $id) {
      id
      assets(query: $query) {
        limit
        offset
        total
        items {
          id
          name
          assetLocation {
            ...Tag
          }
          assetType {
            ...Tag
          }
          product {
            id
            title
            reactionTime
          }
          uptimeSla
          lastLog {
            ...AssetLog
          }
          responsibleUsers {
            id
            fullName
            email
            avatar {
              id
              url
              imageInstance(profile: "thumbnail") {
                object {
                  id
                  url
                }
              }
            }
          }
          uptimeStatus
          description
          displayOnCustomer
          events(query: $query2) {
            ...AssetEvent
          }
          responseTimes(query: $query3) {
            ...AssetResponseTime
          }
        }
      }
    }
  }
  ${TAG}
  ${ASSET_LOG}
  ${ASSET_EVENT}
  ${ASSET_RESPONSE_TIME}
`;

export const ASSET_REMINDER = gql`
  fragment AssetReminder on AssetReminder {
    id
    title
    content
    cycle
    startDate
    customRecipients
    customTo
    customCc
    customBcc
    linkEnabled
    archivedAt
  }
`;

export const ASSET_DETAIL = gql`
  fragment AssetDetail on Asset {
    ...Asset
    logs(query: { limit: 20 }) {
      ...AssetLogs
    }
    displayOnDashboard
    displayOnCustomer
    customerResponsiblePersons {
      id
      fullName
    }
    monitorConnected
    monitor {
      id
      http {
        headers {
          key
          value
        }
        statuses {
          mode
          status
        }
        username
      }
      interval
      keyword {
        type
        value
      }
      port {
        port
        type
      }
      status
      type
      url
      heartbeat {
        url
      }
    }
    reminders {
      ...AssetReminder
    }
    attachments {
      id
      url
      filename
    }
    attachments_v2 {
      permanentLink
    }
    ninjaRmmDeviceUrl
    tagNames @client
    companyId @client
    assetLocationName @client
    assetTypeName @client
    productId @client
    responsibleUserIds @client
    customerResponsiblePersonIds @client
    manualLog @client
    manualLogViewableByCustomer @client
    manualLogTagNames @client
    attachmentIds @client
  }
  ${ASSET}
  ${ASSET_LOGS}
  ${ASSET_REMINDER}
`;

export const ExtendAsset = {
  tagNames: (obj: AssetDetail) => obj.tags.map((el) => el.name),
  companyId: (obj: AssetDetail) => (obj.company ? obj.company.id : ''),
  assetLocationName: (obj: AssetDetail) => (obj.assetLocation ? obj.assetLocation.name : ''),
  assetTypeName: (obj: AssetDetail) => (obj.assetType ? obj.assetType.name : ''),
  productId: (obj: AssetDetail) => (obj.product ? obj.product.id : ''),
  responsibleUserIds: (obj: AssetDetail) => obj.responsibleUsers.map((el) => el.id),
  customerResponsiblePersonIds: (obj: AssetDetail) => obj.customerResponsiblePersons.map((el) => el.id),
  manualLog: () => '',
  manualLogViewableByCustomer: () => false,
  manualLogTagNames: () => [],
  attachmentIds: (obj: AssetDetail) => obj.attachments.map((el) => el.id),
};

export const GET_ASSET = gql`
  query getAsset($query: ID!, $query2: AssetEventsQuery, $query3: AssetResponseTimesQuery) {
    asset(id: $query) {
      ...AssetDetail
      events(query: $query2) {
        ...AssetEvent
      }
      responseTimes(query: $query3) {
        ...AssetResponseTime
      }
    }
  }
  ${ASSET_DETAIL}
  ${ASSET_EVENT}
  ${ASSET_RESPONSE_TIME}
`;

export const CREATE_ASSET = gql`
  mutation createAsset($payload: CreateAssetPayload!) {
    createAsset(payload: $payload) {
      asset {
        ...AssetDetail
      }
    }
  }
  ${ASSET_DETAIL}
`;

export const UPDATE_ASSET = gql`
  mutation updateAsset($id: ID!, $payload: UpdateAssetPayload!) {
    updateAsset(id: $id, payload: $payload) {
      asset {
        ...AssetDetail
      }
    }
  }
  ${ASSET_DETAIL}
`;

export const ARCHIVE_ASSET = gql`
  mutation archiveAsset($id: ID!) {
    archiveAsset(id: $id) {
      asset {
        id
      }
    }
  }
`;

export const UNARCHIVE_ASSET = gql`
  mutation unarchiveAsset($id: ID!) {
    unarchiveAsset(id: $id) {
      asset {
        id
      }
    }
  }
`;

export const ARCHIVE_ASSETS = gql`
  mutation archiveAssets($ids: [ID!]!) {
    archiveAssets(ids: $ids)
  }
`;

export const ACTIVATE_MAINTENANCE_ASSETS = gql`
  mutation activateMaintenanceAssets($ids: [ID!]!) {
    activateMaintenanceAssets(ids: $ids)
  }
`;

export const DEACTIVATE_MAINTENANCE_ASSETS = gql`
  mutation deactivateMaintenanceAssets($ids: [ID!]!) {
    deactivateMaintenanceAssets(ids: $ids)
  }
`;

export const assetKeys = [
  'name',
  'companyId',
  'productId',
  'description',
  'uptimeSla',
  'playbook',
  'assetLocationName',
  'assetTypeName',
  'tagNames',
  'responsibleUserIds',
  'customerResponsiblePersonIds',
  'monitor',
  'manualLog',
  'manualLogViewableByCustomer',
  'manualLogTagNames',
  'reminders',
  'displayOnDashboard',
  'displayOnCustomer',
  'disconnectMonitor',
  'attachmentIds',
];

export const validateAssetForm = {
  name: validateRequired,
  companyId: validateRequired,
  uptimeSla: validateNumber,
  assetLocationName: validateRequired,
  assetTypeName: validateRequired,
};

export type AssetWithEventsAndResponses = Asset & {
  events: AssetEvent[];
  responseTimes: AssetResponseTime[];
};
