import { Trans } from '@lingui/macro';
import { Field } from 'formik';
import React, { FunctionComponent } from 'react';
import { OfferItem } from '../../schema/types/OfferItem';
import { OfferProductItem } from '../../schema/types/OfferProductItem';
import CurrencyDisplay from '../common/CurrencyDisplay';

const getRowTotal = (item: OfferProductItem) => item.price * item.pieces * (1 - item.discountPercent / 100);

const getSubTotal = (items: OfferProductItem[]) => items.reduce((acc, curr) => acc + getRowTotal(curr), 0);

const getTotal = (item: OfferItem) => getSubTotal(item.productItems!) * (1 - item.discountPercent! / 100);

type Props = {
  name: string;
  model: OfferItem;
};

const OfferEditorSortableProduct: FunctionComponent<Props> = ({ name, model }) => {
  return (
    <div className="product-table document-product-table table-responsive">
      <table className="custom-table">
        <thead className="custom-table-header">
          <tr>
            <th className="column">
              <Trans>Title</Trans>
            </th>
            <th className="column number">
              <Trans>Price</Trans>
            </th>
            <th className="column number">
              <Trans>Pieces</Trans>
            </th>
            <th className="column number">
              <Trans>Discount %</Trans>
            </th>
            <th className="column total">
              <Trans>Total</Trans>
            </th>
          </tr>
        </thead>

        <tbody className="custom-table-body">
          {model.productItems!.map((item: OfferProductItem, index: number) => {
            const total = getRowTotal(item);

            return (
              <tr key={index}>
                <td className="column">
                  <Field name={`${name}.productItems[${index}].title`}>
                    {({ field }: { field: any }) => field.value}
                  </Field>
                </td>
                <td className="column number">
                  <Field name={`${name}.productItems[${index}].price`}>
                    {({ field }: { field: any }) => field.value}
                  </Field>
                </td>
                <td className="column number">
                  <Field name={`${name}.productItems[${index}].pieces`}>
                    {({ field }: { field: any }) => field.value}
                  </Field>
                </td>
                <td className="column number">
                  <Field name={`${name}.productItems[${index}].discountPercent`}>
                    {({ field }: { field: any }) => field.value}
                  </Field>
                </td>
                <td className="column total">
                  <CurrencyDisplay value={total} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <table className="table text-right mt-3">
        <tbody>
          <tr>
            <td>
              <Trans>Subtotal</Trans>
            </td>
            <td className="total text-left">
              <CurrencyDisplay value={getSubTotal(model.productItems!)} />
            </td>
          </tr>
          <tr>
            <td>
              <Trans>Discount %</Trans>
            </td>
            <td className="total text-left">
              <Field name={`${name}.discountPercent`}>{({ field }: { field: any }) => field.value}</Field>
            </td>
          </tr>
          <tr>
            <td>
              <strong>
                <Trans>TOTAL</Trans>
              </strong>
            </td>
            <td className="total text-left">
              <strong>
                <CurrencyDisplay value={getTotal(model)} />
              </strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OfferEditorSortableProduct;
