import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import {
  CREATE_PRODUCT_CONTRACT_TYPE,
  GET_PRODUCT_CONTRACT_TYPES,
  UPDATE_PRODUCT_CONTRACT_TYPE,
  validateProductContractTypeForm,
} from '../../schema/productContractType';
import {
  createProductContractType,
  createProductContractTypeVariables,
} from '../../schema/types/createProductContractType';
import { ProductContractType } from '../../schema/types/ProductContractType';
import {
  updateProductContractType,
  updateProductContractTypeVariables,
} from '../../schema/types/updateProductContractType';
import { toastErrorQuery } from '../../utils/error';
import { useVariables } from '../../utils/hooks';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';
import { cloneDeep, pick } from 'lodash';
import ProductContracts from './ProductContracts';
import ProductContractUserForm from './ProductContractUserForm';

const initial: any = {
  name: '',
  emails: [],
};

type Props = {
  show: boolean;
  onSelect: (item: ProductContractType) => void;
  onClose: () => void;
};

const ProductContractModal: FunctionComponent<Props> = ({ show, onSelect, onClose }) => {
  const [model, setModel] = useState(cloneDeep(initial));
  const { search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const [add] = useMutation<createProductContractType, createProductContractTypeVariables>(
    CREATE_PRODUCT_CONTRACT_TYPE,
    {
      refetchQueries: [
        {
          query: GET_PRODUCT_CONTRACT_TYPES,
          variables,
        },
      ],
    },
  );

  const [edit] = useMutation<updateProductContractType, updateProductContractTypeVariables>(
    UPDATE_PRODUCT_CONTRACT_TYPE,
  );

  const handleEdit = useCallback((item: any) => setModel(cloneDeep(item)), [setModel]);

  const handleCancel = useCallback(() => setModel(cloneDeep(initial)), [setModel]);

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Manage vendor contracts</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              <Formik
                enableReinitialize
                initialValues={model}
                validationSchema={yup.object().shape(validateProductContractTypeForm)}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  try {
                    const payload: any = pick(values, ['name', 'emails']);

                    const result: any = model.id
                      ? await edit({
                          variables: {
                            id: model.id,
                            payload,
                          },
                        })
                      : await add({
                          variables: {
                            payload,
                          },
                        });

                    if (result.errors) {
                      toastErrorQuery(result.errors);
                    } else {
                      handleCancel();
                      resetForm();
                      toast.success(i18n._(t`Success!`));
                    }
                  } catch (e) {
                    toastErrorQuery(e);
                  }

                  setSubmitting(false);
                }}
              >
                {({ isSubmitting, submitForm }) => {
                  const handleSubmit = (e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    submitForm();
                  };

                  return (
                    <Form onSubmitCapture={handleSubmit}>
                      <div className="row">
                        <div className="col-sm-6 col-lg-4 form-group">
                          <label>
                            <Trans>Name</Trans>
                          </label>
                          <Field type="text" className="form-control" name="name" />

                          <FieldErrorMessage name="name" />
                        </div>

                        <div className="col-sm-6 col-lg-5 form-group">
                          <label>
                            <Trans>Email</Trans>
                          </label>
                          <ProductContractUserForm name="emails" />
                        </div>

                        <div className="form-group col">
                          <label className="d-none d-lg-block">&nbsp;</label>

                          {model.id && (
                            <button type="button" className="mr-4" onClick={handleCancel}>
                              <Trans>Cancel</Trans>
                            </button>
                          )}

                          <ButtonSubmit
                            className="btn btn-link"
                            title={model.id ? i18n._(t`Save`) : i18n._(t`Add`)}
                            loading={isSubmitting}
                          />
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>

              <ProductContracts onSelect={onSelect} onEdit={handleEdit} />
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-link" onClick={onClose}>
                <Trans>Close</Trans>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProductContractModal;
