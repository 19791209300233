import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import ReactTable, { CellInfo } from 'react-table';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ButtonArchive from '../../components/form/ButtonArchive';
import ButtonEdit from '../../components/form/ButtonEdit';
import {
  ARCHIVE_MESSAGE_CATEGORY,
  GET_MESSAGE_CATEGORIES,
  SET_DEFAULT_MESSAGE_CATEGORY,
  UNARCHIVE_MESSAGE_CATEGORY,
} from '../../schema/messageCategory';
import { archiveMessageCategory, archiveMessageCategoryVariables } from '../../schema/types/archiveMessageCategory';
import { getMessageCategories, getMessageCategoriesVariables } from '../../schema/types/getMessageCategories';
import {
  setDefaultMessageCategory,
  setDefaultMessageCategoryVariables,
} from '../../schema/types/setDefaultMessageCategory';
import {
  unarchiveMessageCategory,
  unarchiveMessageCategoryVariables,
} from '../../schema/types/unarchiveMessageCategory';
import { tableOptions } from '../../utils/const';
import ErrorQuery from '../../components/form/ErrorQuery';
import { MessageCategory } from '../../schema/types/MessageCategory';
import { cloneDeep } from 'lodash';
import { DataProxy } from '@apollo/client/cache';
import { useVariables } from '../../utils/hooks';
import ShowArchive from '../common/ShowArchive';

type Props = {
  defaultId: string;
  onEdit: (item: MessageCategory) => void;
};

const HomeMessageCategories: FunctionComponent<Props> = ({ defaultId, onEdit }) => {
  const { search, filter, archived, setArchived, limit, setLimit, offset, setOffset, orderBy, orderDirection } =
    useVariables();

  const variables = useMemo(
    () => ({
      query: {
        limit,
        offset,
        orderBy,
        orderDirection,
        filter: {
          search,
          archived,
          ...filter,
        },
      },
    }),
    [limit, offset, orderBy, orderDirection, search, archived, filter],
  );

  const updateQuery = (proxy: DataProxy, id: any) => {
    const data: any = cloneDeep(proxy.readQuery({ query: GET_MESSAGE_CATEGORIES, variables }));
    data.messageCategories.items = data.messageCategories.items.filter((el: any) => el.id !== id);
    proxy.writeQuery({ query: GET_MESSAGE_CATEGORIES, variables, data });
  };

  const [mutateArchive] = useMutation<archiveMessageCategory, archiveMessageCategoryVariables>(
    ARCHIVE_MESSAGE_CATEGORY,
    {
      update: (proxy, { data }) => {
        updateQuery(proxy, data!.archiveMessageCategory.messageCategory.id);
      },
    },
  );

  const [mutateUnarchive] = useMutation<unarchiveMessageCategory, unarchiveMessageCategoryVariables>(
    UNARCHIVE_MESSAGE_CATEGORY,
    {
      update: (proxy, { data }) => {
        updateQuery(proxy, data!.unarchiveMessageCategory.messageCategory.id);
      },
    },
  );

  const handleArchive = useCallback(
    (id: any) => () => {
      if (archived) {
        mutateUnarchive({
          variables: {
            id,
          },
          optimisticResponse: {
            unarchiveMessageCategory: {
              messageCategory: {
                id,
                __typename: 'MessageCategory',
              },
              __typename: 'MutateMessageCategoryResponse',
            },
          },
        });
      } else {
        mutateArchive({
          variables: {
            id,
          },
          optimisticResponse: {
            archiveMessageCategory: {
              messageCategory: {
                id,
                __typename: 'MessageCategory',
              },
              __typename: 'MutateMessageCategoryResponse',
            },
          },
        });
      }
    },
    [archived, mutateArchive, mutateUnarchive],
  );

  const [updateDefaultMessageCategory] = useMutation<setDefaultMessageCategory, setDefaultMessageCategoryVariables>(
    SET_DEFAULT_MESSAGE_CATEGORY,
  );

  const handleDefault = useCallback(
    (id: string) => () => {
      updateDefaultMessageCategory({
        variables: {
          id,
        },
        update: (proxy, { data, errors }) => {
          if (data && !errors) {
            const results: any = cloneDeep(proxy.readQuery({ query: GET_MESSAGE_CATEGORIES, variables }));
            results.messageCategories.items = results.messageCategories.items.map((el: any) => {
              if (el.id === id)
                return {
                  ...el,
                  default: true,
                };

              return {
                ...el,
                default: false,
              };
            });
            proxy.writeQuery({
              query: GET_MESSAGE_CATEGORIES,
              variables,
              data: results,
            });
          }
        },
        optimisticResponse: {
          setDefaultMessageCategory: true,
        },
      });
    },
    [variables, updateDefaultMessageCategory],
  );

  const handleEdit = useCallback((item: MessageCategory) => () => onEdit(item), [onEdit]);

  const { data, error } = useQuery<getMessageCategories, getMessageCategoriesVariables>(GET_MESSAGE_CATEGORIES, {
    variables,
  });

  if (error) return <ErrorQuery error={error} />;

  let items = data && data.messageCategories ? data.messageCategories.items : [];
  const pages = data && data.messageCategories ? Math.ceil(data.messageCategories.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Name`),
      accessor: 'name',
      Cell: (cell: CellInfo) => (
        <NavLink to={`/crm/messageCategory/${cell.original.id}`} target="_blank">
          {cell.value}
        </NavLink>
      ),
    },
    {
      Header: i18n._(t`Actions`),
      width: 100,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <>
          <span
            data-tip
            data-for={`default-${cell.original.id}`}
            className="button text-xs"
            onClick={handleDefault(cell.original.id)}
          >
            <i
              className={classNames({
                'icon-like': defaultId !== cell.original.id,
                'icon-like---filled text-warning': defaultId === cell.original.id,
              })}
            />
          </span>
          <ReactTooltip id={`default-${cell.original.id}`}>
            <Trans>Make Default</Trans>
          </ReactTooltip>
          <ButtonEdit onClick={handleEdit(cell.original)} />
          <ButtonArchive onClick={handleArchive(cell.original.id)} />
        </>
      ),
    },
  ];

  return (
    <>
      <div className="form-group text-right">
        <ShowArchive model={archived} onChange={setArchived} />
      </div>

      <ReactTable
        data={items}
        columns={columns}
        pages={pages}
        pageSize={limit}
        manual
        onFetchData={(state) => {
          setOffset(state.page * state.pageSize);
        }}
        onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
        {...tableOptions()}
      />
    </>
  );
};

export default HomeMessageCategories;
