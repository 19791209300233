import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTable, { CellInfo } from 'react-table';
import { ContractReportingProductAdjustment } from '../../schema/types/ContractReportingProductAdjustment';
import { dateOptions, tableOptions } from '../../utils/const';
import DateFormat from '../common/DateFormat';
import UserAvatarName from '../common/UserAvatarName';

type Props = {
  items: ContractReportingProductAdjustment[];
};

const ContractReportingActivity: FunctionComponent<Props> = ({ items }) => {
  const columns = [
    {
      Header: i18n._(t`Product`),
      accessor: 'product.title',
      sortable: true,
      Cell: (cell: CellInfo) => <NavLink to={`/settings/product/${cell.original.product.id}`}>{cell.value}</NavLink>,
    },
    {
      Header: i18n._(t`User`),
      accessor: 'user.fullName',
      sortable: true,
      Cell: (cell: CellInfo) => (
        <NavLink to={`/settings/user/${cell.original.user.id}`}>
          <UserAvatarName user={cell.original.user} />
        </NavLink>
      ),
    },
    {
      Header: i18n._(t`Contract`),
      accessor: 'contractType.name',
      sortable: true,
    },
    {
      Header: i18n._(t`Effective Date`),
      accessor: 'adjustmentDate',
      sortable: true,
      width: 200,
      className: 'text-center',
      Cell: (cell: CellInfo) => <DateFormat value={new Date(cell.value)} format={dateOptions} />,
    },
    {
      Header: i18n._(t`Change`),
      accessor: 'piecesAdjustment',
      width: 150,
      className: 'text-center',
      Cell: (cell: CellInfo) => {
        return (
          <>
            {cell.value > 0 ? '+' : ''}
            {cell.value !== 0 ? cell.value : ''}
          </>
        );
      },
    },
  ];

  const data = items.filter((el: any) => el.piecesAdjustment);

  return <ReactTable data={data} columns={columns} {...tableOptions()} />;
};

export default ContractReportingActivity;
