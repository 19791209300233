import { cloneDeep } from '@apollo/client/utilities';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, FormikProps } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import React, { FunctionComponent, useState } from 'react';
import { NavLink } from 'react-router-dom';
import AssetReminderForm from '../asset/AssetReminderForm';
import LogForm from '../common/LogForm';
import ButtonSubmit from '../form/ButtonSubmit';
import PersonDetailForm from './PersonDetailForm';
import PersonInfoForm from './PersonInfoForm';
import PersonLogs from './PersonLogs';

type Props = {
  loading?: boolean;
};

const PersonUpdateForms: FunctionComponent<Props> = ({ loading }) => {
  const [includeSystemMessages, setIncludeSystemMessages] = useState(false);

  return (
    <Form>
      <div className="form-breadcrumbs-area">
        <NavLink to="/crm/person">
          <Trans>Person</Trans>
        </NavLink>

        <i className="fa fa-angle-left" />

        <Field name="id">
          {({ field }: { field: FieldInputProps<string> }) => {
            if (field.value) return <Trans>Edit person</Trans>;

            return <Trans>Add new person</Trans>;
          }}
        </Field>
      </div>

      <div className="table-actions form-table-actions">
        <div className="breadcrumbs-area">
          <div className="main-title">
            <Field name="firstName">{({ field }: { field: FieldInputProps<string> }) => field.value}</Field>{' '}
            <Field name="lastName">{({ field }: { field: FieldInputProps<string> }) => field.value}</Field>
          </div>
        </div>

        <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save updates`)} loading={loading} />
      </div>

      <div className="card">
        <div className="row">
          <div className="col-xl-6 panel-column-left">
            <PersonDetailForm />
          </div>

          <div className="col-xl-6">
            <PersonInfoForm />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <Trans>Person Reminder</Trans>
        </div>
        <div className="card-body">
          <Field name="reminders">
            {({ field, form }: { field: any; form: FormikProps<any> }) => {
              const handleAdd = (model: any) => {
                const value = cloneDeep(field.value);
                value.push(model);
                form.setFieldValue('reminders', value);
              };

              const handleEdit = (model: any, index: number) => {
                const value = cloneDeep(field.value);
                value[index] = model;
                form.setFieldValue('reminders', value);
              };

              const handleRemove = (index: number) => {
                const value = cloneDeep(field.value);
                value.splice(index, 1);
                form.setFieldValue('reminders', value);
              };

              return (
                <AssetReminderForm items={field.value} onAdd={handleAdd} onEdit={handleEdit} onRemove={handleRemove} />
              );
            }}
          </Field>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col-6">
              <Trans>Log History</Trans>
            </div>

            <div className="col-6 text-right">
              <button
                type="button"
                className="text-info"
                onClick={() => setIncludeSystemMessages((prevState) => !prevState)}
              >
                {!includeSystemMessages ? <Trans>Show system messages</Trans> : <Trans>Hide system messages</Trans>}
              </button>
            </div>
          </div>
        </div>

        <div className="history-item mb-3">
          <div className="row">
            <div className="form-group col-md-6">
              <label>
                <Trans>Internal note</Trans>
              </label>

              <Field type="text" className="form-control" name="manualLogV2.content" />
            </div>

            <div className="form-group col-md-4">
              <label>
                <Trans>Tags for the internal note</Trans>
              </label>
              <LogForm />
            </div>

            <div className="form-group col-md-2">
              <label>&nbsp;</label>
              <br />
              <ButtonSubmit className="btn btn-link" title={i18n._(t`Add Log`)} loading={loading} />
            </div>
          </div>
        </div>

        <Field name="id">
          {({ field }: { field: any }) => (
            <PersonLogs personId={field.value} includeSystemMessages={includeSystemMessages} />
          )}
        </Field>
      </div>
    </Form>
  );
};

export default PersonUpdateForms;
