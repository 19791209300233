import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Formik } from 'formik';
import { pick } from 'lodash';
import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import UserCreateForms from '../components/user/UserCreateForm';
import { CREATE_USER, userKeys, validateUserForm } from '../schema/user';
import { CreateUserPayload, UserRole } from '../schema/graphql-global-types';
import { createUser, createUserVariables } from '../schema/types/createUser';
import { DefaultRouteParams } from '../utils/const';
import { toastErrorQuery } from '../utils/error';

const UserCreatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ history }) => {
  const [mutate] = useMutation<createUser, createUserVariables>(CREATE_USER);

  const initialValues: CreateUserPayload = {
    role: UserRole.Member,
    firstName: '',
    lastName: '',
    function: '',
    email: '',
    password: '',
    companyPhone: '',
    cellphone: '',
    address1: '',
    address2: '',
    zip: '',
    city: '',
    ticketSignature: '',
    avatarId: null,
    timeTrackerDefaultProductId: null,
  };

  return (
    <div className="user-form-page">
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validateUserForm)}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const payload: any = pick(values, userKeys);

            const result: any = await mutate({
              variables: {
                payload,
              },
            });

            if (result.errors) {
              if (result.errors.length && result.errors[0].message === 'Conflicted') {
                toastErrorQuery([
                  {
                    message:
                      i18n._(t`This e-mail address is already in use by:`) +
                      ' ' +
                      result.errors[0].extensions.exception.extra[0].fullName,
                  },
                ]);
              } else {
                toastErrorQuery(result.errors);
              }
            } else {
              toast.success(i18n._(t`Success!`));
              history.push(`/settings/user/${result.data.createUser.user.id}`);
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => <UserCreateForms loading={isSubmitting} />}
      </Formik>
    </div>
  );
};

export default withRouter(UserCreatePage);
