import { ExtendAsset } from './asset';
import { ExtendCompany, ExtendCompanyPerson } from './company';
import { ExtendTimeTrackerConfig } from './config';
import { ExtendDocument, ExtendDocumentElement } from './document';
import { toggleLocale } from './locale';
import { ExtendOffer, ExtendOfferItem, ExtendOfferItemProductItem, ExtendOfferItemProjectItem } from './offer';
import { ExtendPerson } from './person';
import { ExtendProduct, ExtendProductReport } from './product';
import { toggleDarkMode, toggleSidebar, toggleSidebarItem } from './sidebar';
import {
  ExtendTimeTrackerProject,
  ExtendTimeTrackerProjectAdding,
  ExtendTimeTrackerProjectEntry,
  ExtendTimeTrackerProjectSpecialRate,
} from './timeTracker';
import { ExtendUser } from './user';

const resolvers = {
  Company: ExtendCompany,
  CompanyPerson: ExtendCompanyPerson,
  Document: ExtendDocument,
  DocumentElement: ExtendDocumentElement,
  Offer: ExtendOffer,
  OfferItem: ExtendOfferItem,
  OfferItemProductItem: ExtendOfferItemProductItem,
  OfferItemProjectItem: ExtendOfferItemProjectItem,
  Product: ExtendProduct,
  ProductReport: ExtendProductReport,
  Asset: ExtendAsset,
  User: ExtendUser,
  Person: ExtendPerson,
  TimeTrackerProject: ExtendTimeTrackerProject,
  TimeTrackerProjectSpecialRate: ExtendTimeTrackerProjectSpecialRate,
  TimeTrackerProjectEntry: ExtendTimeTrackerProjectEntry,
  TimeTrackerProjectAdding: ExtendTimeTrackerProjectAdding,
  TimeTrackerConfig: ExtendTimeTrackerConfig,
  Mutation: {
    toggleSidebar,
    toggleSidebarItem,
    toggleDarkMode,
    toggleLocale,
  },
};

export default resolvers;
