import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Formik } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import Modal from 'react-modal';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import CompanyCreateForms from '../components/company/CompanyCreateForms';
import ButtonSubmit from '../components/form/ButtonSubmit';
import { CREATE_COMPANY, validateCompanyForm } from '../schema/company';
import { CompanyStatus, CreateCompanyPayload } from '../schema/graphql-global-types';
import { createCompany, createCompanyVariables } from '../schema/types/createCompany';
import { DefaultRouteParams } from '../utils/const';
import { toastErrorQuery } from '../utils/error';

const CompanyCreatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ history }) => {
  const [mutate, { loading }] = useMutation<createCompany, createCompanyVariables>(CREATE_COMPANY);

  const [showConfirm, setShowConfirm] = useState(false);
  const [saving, setSaving] = useState<createCompanyVariables>();
  const [message, setMessage] = useState('');

  const handleConfirm = useCallback(
    async (event) => {
      event.preventDefault();

      try {
        const result = await mutate({
          variables: saving,
        });

        if (result.errors) {
          toastErrorQuery(result.errors);
        } else {
          toast.success(i18n._(t`Success!`));
          window.open(`/crm/company/${result.data?.createCompany.company.id}`, '_blank');
        }

        setShowConfirm(false);
      } catch (e) {
        toastErrorQuery(e);
      }
    },
    [mutate, saving],
  );

  const initialValues: CreateCompanyPayload = {
    name: '',
    address1: '',
    address2: '',
    zip: '',
    canton: '',
    city: '',
    country: '',
    region: '',
    generalEmail: '',
    invoiceEmail: '',
    generalPhone: '',
    website: '',
    size: 1,
    status: CompanyStatus.Default,
    logoId: null,
    responsibleUserIds: [],
    tagNames: [],
    persons: [],
    manualLogV2: {
      content: '',
      tagNames: [],
    },
    reminders: [],
    primaryPersonId: null,
    ticketDomains: [],
    heatLevel: 0,
    bexioNoVat: false,
    timeTrackerSpecialRates: [],
    m365Margin: null,
    azureRIMargin: null,
  };

  return (
    <div className="company-form-page">
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validateCompanyForm)}
        onSubmit={async (values, { setSubmitting }) => {
          if (values.status === CompanyStatus.Customer && !values.invoiceEmail) {
            toast.error(i18n._(t`Invoice Email is mandatory`));
            setSubmitting(false);
            return;
          }

          try {
            const payload = {
              ...values,
              heatLevel: Number(values.heatLevel),
              timeTrackerDefaultPrice: Number(values.timeTrackerDefaultPrice),
              m365Margin: values.m365Margin ? Number(values.m365Margin) : -1,
              azureRIMargin: values.azureRIMargin ? Number(values.azureRIMargin) : -1,
            };

            const result = await mutate({
              variables: {
                payload,
              },
            });

            if (result.errors) {
              if (result.errors[0]?.message.startsWith('Similar')) {
                const data = JSON.parse(result.errors[0].message.replace('Similar Company name: ', ''));

                setMessage(
                  i18n._(
                    t`It looks like this company already exists under "${data.name} ${data.address1} ${data.zip} ${data.city}". Are you sure you'd like to save?`,
                  ),
                );
                setShowConfirm(true);
                setSaving({
                  payload: {
                    ...payload,
                    forceCreate: true,
                  },
                });
              } else {
                toastErrorQuery(result.errors);
              }
            } else {
              toast.success(i18n._(t`Success!`));
              history.push(`/crm/company/${result.data?.createCompany.company.id}`);
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => <CompanyCreateForms loading={isSubmitting} />}
      </Formik>

      <Modal isOpen={showConfirm} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
        <div className="modal fade show" style={{ display: 'block' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title">
                  <Trans>Confirm</Trans>
                </h2>
                <button type="button" className="close" aria-label="Close" onClick={() => setShowConfirm(false)}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <div className="modal-body text-danger">{message}</div>

              <form className="modal-footer" onSubmit={handleConfirm}>
                <button type="button" className="btn btn-info" onClick={() => setShowConfirm(false)} disabled={loading}>
                  <Trans>No</Trans>
                </button>

                <ButtonSubmit className="btn btn-primary" title={i18n._(t`Yes`)} loading={loading} />
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(CompanyCreatePage);
