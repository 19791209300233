import { Trans } from '@lingui/macro';
import { Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import Modal from 'react-modal';
import FieldErrorMessage from '../form/FieldErrorMessage';
import AdjustmentDatepicker from './ContractAdjustmentDatepicker';

const now = new Date();
const next =
  now.getMonth() === 11 ? new Date(now.getFullYear() + 1, 0, 1) : new Date(now.getFullYear(), now.getMonth() + 1, 1);

type Props = {
  show: boolean;
  onSubmit: (date: string) => void;
  onClose: () => void;
};

const ContractArchiveModal: FunctionComponent<Props> = ({ show, onSubmit, onClose }) => {
  return (
    <Modal isOpen={Boolean(show)} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Archive</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              initialValues={{
                date: next.toISOString(),
              }}
              onSubmit={async (values, { setSubmitting }) => {
                const now = new Date(values.date);
                const next = new Date(
                  now.getMonth() === 11
                    ? Date.UTC(now.getFullYear() + 1, 0, 1, 0, -1)
                    : Date.UTC(now.getFullYear(), now.getMonth() + 1, 1, 0, -1),
                );

                onSubmit(next.toISOString());
                onClose();
                setSubmitting(false);
              }}
            >
              {() => (
                <Form>
                  <div className="modal-body">
                    <div className="form-group">
                      <label>
                        <Trans>Archive contract by END of</Trans>
                      </label>
                      <AdjustmentDatepicker name="date" />

                      <FieldErrorMessage name="date" />
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button type="button" className="btn btn-link" onClick={onClose}>
                      <Trans>Close</Trans>
                    </button>

                    <button type="submit" className="btn btn-primary">
                      <Trans>Archive</Trans>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContractArchiveModal;
