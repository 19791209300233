import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import AccountSettingPage from '../containers/AccountSettingPage';
import AutoresponderPage from '../containers/AutoresponderPage';
import ChangePasswordPage from '../containers/ChangePasswordPage';
import CompanyCreatePage from '../containers/CompanyCreatePage';
import CompanyUpdatePage from '../containers/CompanyUpdatePage';
import CompanyPage from '../containers/CompanyPage';
import ConfigurationPage from '../containers/ConfigurationPage';
import ContractCreatePage from '../containers/ContractCreatePage';
import ContractPage from '../containers/ContractPage';
import ContractUpdatePage from '../containers/ContractUpdatePage';
import DocumentationElementCreatePage from '../containers/DocumentationElementCreatePage';
import DocumentationElementPage from '../containers/DocumentationElementPage';
import DocumentationElementUpdatePage from '../containers/DocumentationElementUpdatePage';
import DocumentationPage from '../containers/DocumentationPage';
import DocumentationTemplatePage from '../containers/DocumentationTemplatePage';
import DocumentationUpdatePage from '../containers/DocumentationUpdatePage';
import HelpConfigurationPage from '../containers/HelpConfigurationPage';
import ImportPage from '../containers/ImportPage';
import InvoiceReportPage from '../containers/InvoiceReportPage';
import HomePage from '../containers/HomePage';
import NinjaRmmCreatePage from '../containers/NinjaRmmCreatePage';
import OfferTemplatePage from '../containers/OfferTemplatePage';
import OfferUpdatePage from '../containers/OfferUpdatePage';
import ProfilePage from '../containers/ProfilePage';
import SupportTicketDetailPage from '../containers/SupportTicketDetailPage';
import TextTemplateCreatePage from '../containers/TextTemplateCreatePage';
import TextTemplatePage from '../containers/TextTemplatePage';
import TextTemplateUpdatePage from '../containers/TextTemplateUpdatePage';
import OfferPage from '../containers/OfferPage';
import PersonCreatePage from '../containers/PersonCreatePage';
import PersonPage from '../containers/PersonPage';
import PersonUpdatePage from '../containers/PersonUpdatePage';
import ProductCreatePage from '../containers/ProductCreatePage';
import ProductPage from '../containers/ProductPage';
import ProductUpdatePage from '../containers/ProductUpdatePage';
import ServicesAssetCreatePage from '../containers/ServicesAssetCreatePage';
import ServicesAssetPage from '../containers/ServicesAssetPage';
import ServicesAssetUpdatePage from '../containers/ServicesAssetUpdatePage';
import SupportClosedPage from '../containers/SupportClosedPage';
import SupportTicketPage from '../containers/SupportTicketPage';
import TimeTrackerPage from '../containers/TimeTrackerPage';
import TimeTrackerUpdatePage from '../containers/TimeTrackerUpdatePage';
import UserCreatePage from '../containers/UserCreatePage';
import UserPage from '../containers/UserPage';
import UserUpdatePage from '../containers/UserUpdatePage';

export type Route = {
  help?: string;
  icon?: string;
  exact?: boolean;
  hide?: boolean;
  name: string;
  href: string;
  children?: Array<Route>;
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
};

export const userRoutes: Route[] = [
  {
    help: 'Admin/Dashboard',
    icon: 'icon-accounts',
    name: i18n._(t`Dashboard`),
    href: '/',
    exact: true,
    component: HomePage,
  },
  {
    icon: 'icon-tickets',
    name: i18n._(t`Support`),
    href: '/support',
    children: [
      {
        help: 'Support/Tickets',
        href: '/support/ticket',
        name: i18n._(t`Open Tickets`),
        component: SupportTicketPage,
      },
      {
        help: 'Support/Tickets',
        href: '/support/ticket/:id',
        name: i18n._(t`Open Tickets`),
        hide: true,
        component: SupportTicketDetailPage,
      },
      {
        help: 'Support/Closed',
        href: '/support/closed',
        name: i18n._(t`Closed Tickets`),
        component: SupportClosedPage,
      },
      {
        help: 'Support/Time Tracking',
        href: '/support/time-tracking',
        name: i18n._(t`Time Tracking`),
        component: TimeTrackerPage,
      },
      {
        help: 'Support/Time Tracking',
        href: '/support/time-tracking/:id',
        name: i18n._(t`Time Tracking`),
        component: TimeTrackerUpdatePage,
        hide: true,
      },
    ],
  },
  {
    icon: 'icon-assets',
    name: i18n._(t`Managed Services`),
    href: '/service',
    children: [
      {
        help: 'Managed Services/Managed Assets',
        href: '/service/asset',
        name: i18n._(t`Managed Assets`),
        component: ServicesAssetPage,
      },
      {
        help: 'Managed Services/Managed Assets',
        href: '/service/asset/add',
        name: i18n._(t`Add Assets`),
        component: ServicesAssetCreatePage,
        hide: true,
      },
      {
        help: 'Managed Services/Managed Assets',
        href: '/service/asset/:id',
        name: i18n._(t`Edit Assets`),
        component: ServicesAssetUpdatePage,
        hide: true,
      },
      {
        href: '/service/ninjarmm/add',
        name: i18n._(t`Manage NinjaRMM`),
        component: NinjaRmmCreatePage,
        hide: true,
      },
    ],
  },
  {
    icon: 'icon-document',
    name: i18n._(t`Documentation`),
    href: '/documentation',
    children: [
      {
        help: 'Documentation/Docu',
        href: '/documentation/all',
        name: i18n._(t`Documentations`),
        component: DocumentationPage,
      },
      {
        help: 'Documentation/Docu',
        href: '/documentation/edit/:id',
        name: i18n._(t`Edit Documentation`),
        component: DocumentationUpdatePage,
        hide: true,
      },
      {
        help: 'Documentation/Templates',
        href: '/documentation/template',
        name: i18n._(t`Templates`),
        component: DocumentationTemplatePage,
        hide: true,
      },
      {
        help: 'Documentation/Templates',
        href: '/documentation/element',
        name: i18n._(t`Centralized Content`),
        component: DocumentationElementPage,
      },
      {
        help: 'Documentation/Templates',
        href: '/documentation/element/add',
        name: i18n._(t`Add Centralized Content`),
        component: DocumentationElementCreatePage,
        hide: true,
      },
      {
        help: 'Documentation/Templates',
        href: '/documentation/element/:id',
        name: i18n._(t`Edit Centralized Content`),
        component: DocumentationElementUpdatePage,
        hide: true,
      },
    ],
  },
  {
    icon: 'icon-crm',
    name: i18n._(t`CRM`),
    href: '/crm',
    children: [
      {
        help: 'CRM/Company',
        href: '/crm/company',
        name: i18n._(t`Company`),
        component: CompanyPage,
      },
      {
        help: 'CRM/Company',
        href: '/crm/company/add',
        name: i18n._(t`Add Company`),
        component: CompanyCreatePage,
        hide: true,
      },
      {
        help: 'CRM/Company',
        href: '/crm/company/:id',
        name: i18n._(t`Edit Company`),
        component: CompanyUpdatePage,
        hide: true,
      },
      {
        help: 'CRM/Person',
        href: '/crm/person',
        name: i18n._(t`Person`),
        component: PersonPage,
      },
      {
        help: 'CRM/Person',
        href: '/crm/person/add',
        name: i18n._(t`Add Person`),
        component: PersonCreatePage,
        hide: true,
      },
      {
        help: 'CRM/Person',
        href: '/crm/person/:id',
        name: i18n._(t`Edit Person`),
        component: PersonUpdatePage,
        hide: true,
      },
      {
        help: 'CRM/Offers',
        href: '/crm/offer',
        name: i18n._(t`Offer`),
        component: OfferPage,
      },
      {
        help: 'CRM/Offers',
        href: '/crm/offer/:id',
        name: i18n._(t`Edit Offer`),
        component: OfferUpdatePage,
        hide: true,
      },
      {
        help: 'CRM/Offers',
        href: '/crm/template',
        name: i18n._(t`Templates`),
        component: OfferTemplatePage,
        hide: true,
      },
      {
        help: 'CRM/Contracts',
        href: '/crm/contract',
        name: i18n._(t`Contract`),
        component: ContractPage,
      },
      {
        help: 'CRM/Contracts',
        href: '/crm/contract/add',
        name: i18n._(t`Add Contract`),
        component: ContractCreatePage,
        hide: true,
      },
      {
        help: 'CRM/Contracts',
        href: '/crm/contract/:id',
        name: i18n._(t`Edit Contract`),
        component: ContractUpdatePage,
        hide: true,
      },
    ],
  },
  {
    icon: 'icon-reports',
    name: i18n._(t`Reporting`),
    href: '/reporting',
    children: [
      {
        help: 'Reporting/Invoices & Reports',
        href: '/reporting/invoice-reports',
        name: i18n._(t`Invoice & Reports`),
        component: InvoiceReportPage,
      },
      {
        help: 'Reporting/Import',
        href: '/reporting/import',
        name: i18n._(t`Import`),
        component: ImportPage,
      },
    ],
  },
  {
    help: 'My Profile',
    href: '/profile',
    name: i18n._(t`My Profile`),
    component: ProfilePage,
    hide: true,
  },
  {
    href: '/change-password',
    name: i18n._(t`Change Password`),
    component: ChangePasswordPage,
    hide: true,
  },
];

export const adminRoutes: Route[] = [
  ...userRoutes,
  {
    icon: 'icon-settings',
    name: i18n._(t`Settings`),
    href: '/settings',
    children: [
      {
        help: 'Settings/System User',
        href: '/settings/user',
        name: i18n._(t`System Users`),
        component: UserPage,
      },
      {
        help: 'Settings/System User',
        href: '/settings/user/add',
        name: i18n._(t`Add Users`),
        component: UserCreatePage,
        hide: true,
      },
      {
        help: 'Settings/System User',
        href: '/settings/user/:id',
        name: i18n._(t`Edit Users`),
        component: UserUpdatePage,
        hide: true,
      },
      {
        help: 'Settings/Products',
        href: '/settings/product',
        name: i18n._(t`Products`),
        component: ProductPage,
      },
      {
        help: 'Settings/Products',
        href: '/settings/product/add',
        name: i18n._(t`Add Products`),
        component: ProductCreatePage,
        hide: true,
      },
      {
        help: 'Settings/Products',
        href: '/settings/product/:id',
        name: i18n._(t`Edit Products`),
        component: ProductUpdatePage,
        hide: true,
      },
      {
        help: 'Settings/Text Templates',
        href: '/settings/text-template',
        name: i18n._(t`Text Templates`),
        component: TextTemplatePage,
      },
      {
        help: 'Settings/Text Templates',
        href: '/settings/text-template/add',
        name: i18n._(t`Add Text Template`),
        component: TextTemplateCreatePage,
        hide: true,
      },
      {
        help: 'Settings/Text Templates',
        href: '/settings/text-template/:id',
        name: i18n._(t`Edit Text Template`),
        component: TextTemplateUpdatePage,
        hide: true,
      },
      {
        help: 'Settings/Configuration',
        href: '/settings/configuration',
        name: i18n._(t`Configuration`),
        component: ConfigurationPage,
      },
      {
        help: 'Settings/Autoresponder',
        href: '/settings/autoresponder',
        name: i18n._(t`Autoresponder`),
        component: AutoresponderPage,
      },
      {
        help: 'Settings/Help Config',
        href: '/settings/help-configuration',
        name: i18n._(t`Help Configuration`),
        component: HelpConfigurationPage,
      },
      {
        href: '/settings/account',
        name: i18n._(t`Account Settings`),
        component: AccountSettingPage,
      },
    ],
  },
];

export const history = createBrowserHistory();
let prevLocation: any = {};
history.listen((location) => {
  const pathChanged = prevLocation.pathname !== location.pathname;
  const hashChanged = prevLocation.hash !== location.hash;
  if (pathChanged || hashChanged) window.scrollTo(0, 0);
  prevLocation = location;
});

export class AppBrowserRouter extends BrowserRouter {
  history = history;
}
