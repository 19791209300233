import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { GET_BEXIO_CONFIG, UPDATE_BEXIO_CONFIG } from '../../schema/config';
import { BexioConfigPayload } from '../../schema/graphql-global-types';
import { getBexioConfig } from '../../schema/types/getBexioConfig';
import { updateBexioConfig, updateBexioConfigVariables } from '../../schema/types/updateBexioConfig';
import { toastErrorQuery } from '../../utils/error';
import { validateRequired } from '../../utils/validations';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';
import { merge, omit } from 'lodash';

type Props = {
  show: boolean;
  onClose: () => void;
};

const ConfigurationBexioModal: FunctionComponent<Props> = ({ show, onClose }) => {
  const { data } = useQuery<getBexioConfig>(GET_BEXIO_CONFIG);

  const [mutate] = useMutation<updateBexioConfig, updateBexioConfigVariables>(UPDATE_BEXIO_CONFIG);

  const model: BexioConfigPayload = merge(
    {
      apiKey: '',
    },
    data && data.bexioConfig,
  );

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Bexio Connector</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              initialValues={model}
              validationSchema={yup.object().shape({
                apiKey: validateRequired,
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  const payload: any = omit(values, ['__typename']);

                  const result: any = await mutate({
                    variables: {
                      payload,
                    },
                  });

                  if (result.errors) {
                    toastErrorQuery(result.errors);
                  } else {
                    toast.success(i18n._(t`Success!`));
                    resetForm();
                    onClose();
                  }
                } catch (e) {
                  toastErrorQuery(e);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => {
                return (
                  <Form>
                    <div className="modal-body">
                      <div className="form-group">
                        <label>
                          <Trans>API Key</Trans>
                        </label>
                        <Field type="text" className="form-control" name="apiKey" autoFocus />

                        <FieldErrorMessage name="apiKey" />
                      </div>

                      <div className="form-group">
                        <label>
                          <Trans>Limit requests per hour</Trans>
                        </label>
                        <Field type="number" className="form-control" name="limitPerHour" />

                        <FieldErrorMessage name="limitPerHour" />
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={onClose}>
                        <Trans>Close</Trans>
                      </button>

                      <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfigurationBexioModal;
