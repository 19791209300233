import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import TimeTrackerCompanyProjectField from './TimeTrackerCompanyProjectField';

type Props = {};

const TimeTrackerCompanyProjectForm: FunctionComponent<Props> = () => {
  return (
    <Field name="company">
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (value: any) => {
          if (value.value.type === 'project') {
            form.setFieldValue('projectId', value.value.id);
            form.setFieldValue('companyId', '');
            form.setFieldValue('project', value.value);
            form.setFieldValue('company', value.value.customer);
          } else {
            form.setFieldValue('projectId', '');
            form.setFieldValue('companyId', value.value.id);
            form.setFieldValue('project', null);
            form.setFieldValue('company', value.value);
          }
        };

        return <TimeTrackerCompanyProjectField value={field.value} onSubmit={handleChange} />;
      }}
    </Field>
  );
};

export default TimeTrackerCompanyProjectForm;
