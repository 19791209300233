import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import {
  OrderDirection,
  TicketsQueryOrderBy,
  TicketStatus,
  UsersQueryOrderBy,
} from '../../schema/graphql-global-types';
import { GET_TICKETS } from '../../schema/ticket';
import { getCompanyUsers, getCompanyUsersVariables } from '../../schema/types/getCompanyUsers';
import { getTickets, getTicketsVariables } from '../../schema/types/getTickets';
import { GET_USERS_FOR_COMPANY } from '../../schema/user';
import { tablePollingInterval } from '../../utils/const';
import { useVariables, useViewer } from '../../utils/hooks';
import UserAvatarName from '../common/UserAvatarName';

type Props = {
  selected: string;
  onChange: (value: any) => void;
};

const TicketUserFilter: FunctionComponent<Props> = ({ selected, onChange }) => {
  const me = useViewer();

  const { search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables({
    orderBy: UsersQueryOrderBy.fullName,
    orderDirection: OrderDirection.Asc,
  });

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        supportDisabled: false,
        ...filter,
      },
    },
  };

  const handleChange = useCallback((value: string) => () => onChange(value), [onChange]);

  const { data, refetch: refetchUsers } = useQuery<getCompanyUsers, getCompanyUsersVariables>(GET_USERS_FOR_COMPANY, {
    variables,
    fetchPolicy: 'cache-first',
  });

  const { data: myTickets, refetch: refetchMyTickets } = useQuery<getTickets, getTicketsVariables>(GET_TICKETS, {
    variables: {
      query: {
        limit: 1,
        offset: 0,
        orderBy: TicketsQueryOrderBy.lastActiveTime,
        orderDirection: OrderDirection.Desc,
        filter: {
          search: '',
          archived: false,
          statuses: [TicketStatus.Opened],
          lastEditorId: [me.data?.viewer.user?.id || ''],
        },
      },
    },
    skip: !me,
    pollInterval: tablePollingInterval,
    fetchPolicy: 'cache-first',
  });

  const myNumber = useMemo(() => myTickets?.tickets.total || 0, [myTickets]);

  const { data: unTickets, refetch: refetchUnTickets } = useQuery<getTickets, getTicketsVariables>(GET_TICKETS, {
    variables: {
      query: {
        limit: 1,
        offset: 0,
        orderBy: TicketsQueryOrderBy.lastActiveTime,
        orderDirection: OrderDirection.Desc,
        filter: {
          search: '',
          archived: false,
          statuses: [TicketStatus.Opened],
          lastEditorId: ['unassigned'],
        },
      },
    },
    pollInterval: tablePollingInterval,
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    refetchUsers();
    refetchMyTickets();
    refetchUnTickets();
  }, [refetchMyTickets, refetchUnTickets, refetchUsers]);

  const unNumber = useMemo(() => unTickets?.tickets.total || 0, [unTickets]);

  return (
    <div className="filters">
      <button
        type="button"
        className={classNames('item', {
          active: selected === 'Me & Unassigned',
        })}
        onClick={handleChange('Me & Unassigned')}
      >
        <i className="icon-unassigned-ticket" />

        <div className="label">
          <Trans>My tickets & Unassigned</Trans>
        </div>

        {myNumber + unNumber > 0 && <div className="number">{myNumber + unNumber}</div>}
      </button>

      <button type="button" className={classNames('item', { active: selected === 'Me' })} onClick={handleChange('Me')}>
        <i className="icon-my-tickets" />

        <div className="label">
          <Trans>My tickets</Trans>
        </div>

        {myNumber > 0 && <div className="number">{myNumber}</div>}
      </button>

      <button
        type="button"
        className={classNames('item', { active: selected === 'Unassigned' })}
        onClick={handleChange('Unassigned')}
      >
        <i className="icon-my-tickets" />

        <div className="label">
          <Trans>Unassigned</Trans>
        </div>

        {unNumber > 0 && <div className="number">{unNumber}</div>}
      </button>

      <button
        type="button"
        className={classNames('item', {
          active: selected === 'Show all',
        })}
        onClick={handleChange('Show all')}
      >
        <i className="icon-inbox" />

        <div className="label">
          <Trans>All tickets</Trans>
        </div>
      </button>

      <hr />

      <div className="other label-lg">
        <Trans>OTHER PERSONS</Trans>
      </div>

      {data?.users.items.map((el) => (
        <button
          type="button"
          key={el.id}
          className={classNames('item', { active: selected === el.id })}
          onClick={handleChange(el.id)}
        >
          <UserAvatarName user={el} />

          {el.id === me.data?.viewer.user?.id && myNumber > 0 && <div className="number">{myNumber}</div>}
        </button>
      ))}
    </div>
  );
};

export default TicketUserFilter;
