import { Trans } from '@lingui/macro';
import React, { ErrorInfo } from 'react';

type Props = {};

type State = {
  error: Error;
  errorInfo: ErrorInfo;
};

class AppError extends React.Component<Props, State> {
  state = {
    error: new Error(),
    errorInfo: {
      componentStack: '',
    },
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    const { children } = this.props;
    const { error, errorInfo } = this.state;

    return (
      <>
        {errorInfo.componentStack ? (
          <div className="card">
            <div className="card-body">
              <h2 className="text-danger text-center">
                <Trans>
                  Oh-no! Something went wrong, please reload the page
                </Trans>
              </h2>
              <p className="text-center">
                <a
                  href={`mailto:?subject=Error at ${
                    window.location.pathname
                  }&body=${error
                    .toString()
                    .replace(
                      /\r\n|\n|\r/g,
                      '%0D%0A'
                    )}%0D%0A%0D%0A${errorInfo.componentStack.replace(
                    /\r\n|\n|\r/g,
                    '%0D%0A'
                  )}`}
                  className="text-primary"
                >
                  <Trans>Email this error</Trans>
                </a>
              </p>
              {process.env.REACT_APP_ENV !== 'production' && (
                <>
                  <pre className="text-danger mb-3">{error.toString()}</pre>
                  <pre className="text-danger">{errorInfo.componentStack}</pre>
                </>
              )}
            </div>
          </div>
        ) : (
          children
        )}
      </>
    );
  }
}

export default AppError;
