import { DataProxy } from '@apollo/client/cache';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { cloneDeep } from 'lodash';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import ReactTable, { CellInfo } from 'react-table';
import ReactTooltip from 'react-tooltip';
import DateFormat from '../components/common/DateFormat';
import LoadingTable from '../components/common/LoadingTable';
import MainTitle from '../components/common/MainTitle';
import TableActions from '../components/common/TableActions';
import UserAvatarName from '../components/common/UserAvatarName';
import DocumentationTemplateCreateModal from '../components/documentation/DocumentationTemplateCreateModal';
import ButtonArchive from '../components/form/ButtonArchive';
import ButtonEdit from '../components/form/ButtonEdit';
import ErrorQuery from '../components/form/ErrorQuery';
import { ARCHIVE_DOCUMENT, GET_DOCUMENTS, UNARCHIVE_DOCUMENT } from '../schema/document';
import { OrderDirection } from '../schema/graphql-global-types';
import { archiveDocument, archiveDocumentVariables } from '../schema/types/archiveDocument';
import { getDocuments, getDocumentsVariables } from '../schema/types/getDocuments';
import { unarchiveDocument, unarchiveDocumentVariables } from '../schema/types/unarchiveDocument';
import { dateTimeOptions, tableOptions } from '../utils/const';
import { useVariables } from '../utils/hooks';
import { getOrder } from '../utils/utils';

type Props = {};

const DocumentationTemplatePage: FunctionComponent<Props> = () => {
  const [loaded, setLoaded] = useState(false);
  const {
    search$,
    search,
    filter,
    archived,
    setArchived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
  } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
        template: true,
      },
    },
  };

  const updateQuery = (proxy: DataProxy, id: any) => {
    const data: any = cloneDeep(proxy.readQuery({ query: GET_DOCUMENTS, variables }));
    data.documents.items = data.documents.items.filter((el: any) => el.id !== id);
    proxy.writeQuery({ query: GET_DOCUMENTS, variables, data });
  };

  const [mutateArchive] = useMutation<archiveDocument, archiveDocumentVariables>(ARCHIVE_DOCUMENT, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.archiveDocument.document.id);
    },
  });
  const [mutateUnarchive] = useMutation<unarchiveDocument, unarchiveDocumentVariables>(UNARCHIVE_DOCUMENT, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.unarchiveDocument.document.id);
    },
  });

  const handleArchive = useCallback(
    (id: any) => () => {
      if (archived) {
        mutateUnarchive({
          variables: {
            id,
          },
          optimisticResponse: {
            unarchiveDocument: {
              document: {
                id,
                __typename: 'Document',
              },
              __typename: 'ArchiveDocumentResponse',
            },
          },
        });
      } else {
        mutateArchive({
          variables: {
            id,
          },
          optimisticResponse: {
            archiveDocument: {
              document: {
                id,
                __typename: 'Document',
              },
              __typename: 'ArchiveDocumentResponse',
            },
          },
        });
      }
    },
    [archived, mutateArchive, mutateUnarchive],
  );

  const [showAdd, setShowAdd] = useState(false);
  const toggleAdd = useCallback(() => setShowAdd((prevState) => !prevState), [setShowAdd]);

  const { data, previousData, loading, error } = useQuery<getDocuments, getDocumentsVariables>(GET_DOCUMENTS, {
    variables,
  });

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.documents.items ?? previousData?.documents.items ?? [];
  const pages = data && data.documents ? Math.ceil(data.documents.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Title`),
      accessor: 'title',
      Cell: (cell: CellInfo) => <NavLink to={`/documentation/edit/${cell.original.id}`}>{cell.value}</NavLink>,
    },
    {
      Header: i18n._(t`Editor`),
      accessor: 'editor.fullName',
      Cell: (cell: CellInfo) => (
        <NavLink to={`/settings/user/${cell.original.editor.id}`}>
          <UserAvatarName user={cell.original.editor} />
        </NavLink>
      ),
    },
    {
      Header: i18n._(t`Last Edit`),
      accessor: 'updatedAt',
      Cell: (cell: CellInfo) => <DateFormat value={new Date(cell.value)} format={dateTimeOptions} />,
    },
    {
      Header: i18n._(t`Actions`),
      width: 90,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <>
          <NavLink to={`/documentation/edit/${cell.original.id}`}>
            <ButtonEdit />
          </NavLink>
          <ButtonArchive onClick={handleArchive(cell.original.id)} />
        </>
      ),
    },
  ];

  return (
    <div className="document-page">
      <div className="card-body">
        <div className="table-actions">
          <NavLink to="/documentation/all" data-tip data-for="toggle-template" className="link-action">
            D
          </NavLink>

          <ReactTooltip id="toggle-template">
            <Trans>Documentations</Trans>
          </ReactTooltip>

          <div className="breadcrumbs-area">
            <MainTitle title={i18n._(t`Templates`)} tips="Documentation/Templates" />
          </div>

          <TableActions onSearch={(e) => search$.next(e.target.value)} archived={archived} onArchive={setArchived} />

          <button type="button" className="btn btn-sm btn-primary" onClick={toggleAdd}>
            <i className="icon-add" />
            <span>
              <Trans>Add new document template</Trans>
            </span>
          </button>
        </div>

        {!loaded ? (
          <LoadingTable />
        ) : (
          <ReactTable
            data={items}
            columns={columns}
            pages={pages}
            pageSize={limit}
            manual
            onFetchData={(state) => {
              let orderDir = OrderDirection.Desc;
              if (state.sorted[0]) {
                orderDir = state.sorted[0].desc ? OrderDirection.Desc : OrderDirection.Asc;
              }

              setOffset(state.page * state.pageSize);
              setOrderBy(
                getOrder(state, {
                  'editor.fullName': 'editorName',
                }),
              );
              setOrderDirection(orderDir);
            }}
            onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
            {...tableOptions()}
          />
        )}

        <DocumentationTemplateCreateModal show={showAdd} onClose={toggleAdd} />
      </div>
    </div>
  );
};

export default DocumentationTemplatePage;
