const countries = {
  en: [
    'Switzerland',
    'Afghanistan',
    'Aland Islands',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antarctica',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Ascension',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia',
    'Bonaire, Saint Eustatius and Saba',
    'Bosnia and Herzegovina',
    'Botswana',
    'Bouvet Island',
    'Brazil',
    'British Territories in the Indian Ocean',
    'British Virgin Islands',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cayman Islands',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Christmas Island',
    'Cocos (Keeling) Islands',
    'Colombia',
    'Comoros',
    'Congo (Brazzaville)',
    'Congo (Kinshasa)',
    'Cook Islands',
    'Costa Rica',
    "Côte d'Ivoire",
    'Croatia',
    'Cuba',
    'Curaçao',
    'Cyprus',
    'Czechia',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini',
    'Ethiopia',
    'Faeroe Islands',
    'Falkland Islands',
    'Fiji',
    'Finland',
    'France',
    'French Guyana',
    'French Polynesia',
    'French Southern and Antarctic Lands',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Heard Island and McDonald Islands',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jersey',
    'Johnston Island',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Kosovo',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macao',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Mexico',
    'Micronesia',
    'Midway Island',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Niue',
    'Norfolk Island',
    'North Korea',
    'Northern Marianas',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestine',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Pitcairn Islands',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Réunion',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Barthélemy',
    'Saint Helena',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Martin (France)',
    'Saint Pierre and Miquelon',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'São Tomé and Príncipe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Sint Maarten (Netherlands)',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Georgia and the South Sandwich Islands',
    'South Korea',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Svalbard and Jan Mayen',
    'Sweden',
    'Syria',
    'Taiwan (Chinese Taipei)',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Timor-Leste',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad and Tobago',
    'Tristan da Cunha',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks and Caicos Islands',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'US Virgin Islands',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Vietnam',
    'Wake Island',
    'Wallis and Futuna',
    'Western Sahara',
    'Yemen',
    'Zambia',
    'Zimbabwe',
  ],
  de: [
    'Schweiz',
    'Afghanistan',
    'Alandinseln',
    'Albanien',
    'Algerien',
    'Amerikanisch-Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antarktis',
    'Antigua und Barbuda',
    'Argentinien',
    'Armenien',
    'Aruba',
    'Ascension',
    'Australien',
    'Österreich',
    'Aserbaidschan',
    'Bahamas',
    'Bahrain',
    'Bangladesch',
    'Barbados',
    'Belarus',
    'Belgien',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivien',
    'Bonaire, Saint Eustatius und Saba',
    'Bosnien und Herzegowina',
    'Botsuana',
    'Bouvetinsel',
    'Brasilien',
    'Britische Territorien im Indischen Ozean',
    'Jungferninseln (UK)',
    'Brunei Darussalam',
    'Bulgarien',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Kambodscha',
    'Kamerun',
    'Kanada',
    'Kaimaninseln',
    'Zentralafrikanische Republik',
    'Tschad',
    'Chile',
    'China',
    'Weihnachtsinsel',
    'Kokosinseln',
    'Kolumbien',
    'Komoren',
    'Kongo (Brazzaville)',
    'Kongo (Kinshasa)',
    'Cookinseln',
    'Costa Rica',
    "Côte d'Ivoire",
    'Kroatien',
    'Kuba',
    'Curaçao',
    'Zypern',
    'Tschechien',
    'Dänemark',
    'Dschibuti',
    'Dominica',
    'Dominikanische Republik',
    'Ecuador',
    'Ägypten',
    'El Salvador',
    'Äquatorialguinea',
    'Eritrea',
    'Estland',
    'Eswatini',
    'Äthiopien',
    'Färöer',
    'Falklandinseln',
    'Fidschi',
    'Finnland',
    'Frankreich',
    'Französisch-Guayana',
    'Französisch-Polynesien',
    'Französische Süd- und Antarktisgebiete',
    'Gabun',
    'Gambia',
    'Georgien',
    'Deutschland',
    'Ghana',
    'Gibraltar',
    'Griechenland',
    'Grönland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Heard und McDonaldinseln',
    'Honduras',
    'Hongkong',
    'Ungarn',
    'Island',
    'Indien',
    'Indonesien',
    'Iran',
    'Irak',
    'Irland',
    'Insel Man',
    'Israel',
    'Italien',
    'Jamaika',
    'Japan',
    'Jersey',
    'Johnstoninsel',
    'Jordanien',
    'Kasachstan',
    'Kenia',
    'Kiribati',
    'Kosovo',
    'Kuwait',
    'Kirgisistan',
    'Laos',
    'Lettland',
    'Libanon',
    'Lesotho',
    'Liberia',
    'Libyen',
    'Liechtenstein',
    'Litauen',
    'Luxemburg',
    'Macao',
    'Mazedonien',
    'Madagaskar',
    'Malawi',
    'Malaysia',
    'Malediven',
    'Mali',
    'Malta',
    'Marshallinseln',
    'Martinique',
    'Mauretanien',
    'Mauritius',
    'Mayotte',
    'Mexiko',
    'Mikronesien',
    'Midwayinseln',
    'Moldova',
    'Monaco',
    'Mongolei',
    'Montenegro',
    'Montserrat',
    'Marokko',
    'Mosambik',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Niederlande',
    'Neukaledonien',
    'Neuseeland',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Niue',
    'Norfolkinsel',
    'Korea (Nord-)',
    'Nördliche Marianen',
    'Norwegen',
    'Oman',
    'Pakistan',
    'Palau',
    'Palästina',
    'Panama',
    'Papua-Neuguinea',
    'Paraguay',
    'Peru',
    'Philippinen',
    'Pitcairninseln',
    'Polen',
    'Portugal',
    'Puerto Rico',
    'Katar',
    'Reunion',
    'Rumänien',
    'Russland',
    'Ruanda',
    'Saint-Barthélemy',
    'St. Helena',
    'St. Kitts und Nevis',
    'St. Lucia',
    'Saint-Martin (Frankreich)',
    'St. Pierre und Miquelon',
    'St. Vincent und die Grenadinen',
    'Samoa',
    'San Marino',
    'São Tomé und Príncipe',
    'Saudi-Arabien',
    'Senegal',
    'Serbien',
    'Seychellen',
    'Sierra Leone',
    'Singapur',
    'Sint Maarten (Niederlande)',
    'Slowakei',
    'Slowenien',
    'Salomoninseln',
    'Somalia',
    'Südafrika',
    'Südgeorgien und Südliche Sandwichinseln',
    'Korea (Süd-)',
    'Südsudan',
    'Spanien',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Svalbard und Jan Mayen',
    'Schweden',
    'Syrien',
    'Taiwan (Chinesisches Taipei)',
    'Tadschikistan',
    'Tansania',
    'Thailand',
    'Timor-Leste',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad und Tobago',
    'Tristan da Cunha',
    'Tunesien',
    'Türkei',
    'Turkmenistan',
    'Turks- und Caicosinseln',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'Vereinigte Arabische Emirate',
    'Vereinigtes Königreich',
    'Vereinigte Staaten',
    'Uruguay',
    'Jungferninseln (USA)',
    'Usbekistan',
    'Vanuatu',
    'Vatikanstadt',
    'Venezuela',
    'Vietnam',
    'Wakeinsel',
    'Wallis und Futuna',
    'Westsahara',
    'Jemen',
    'Sambia',
    'Simbabwe',
  ],
};

export default countries;
