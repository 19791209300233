import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import { Field } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { GET_VIEWER } from '../../schema/auth';
import { LoginMode, UserRole } from '../../schema/graphql-global-types';
import { getViewer } from '../../schema/types/getViewer';
import FieldErrorMessage from '../form/FieldErrorMessage';
import PhoneInput from '../form/PhoneInput';
import UserSendModal from '../user/UserSendModal';
import PersonDisableMfaModal from './PersonDisableMfaModal';

type Props = {};

const PersonInfoForm: FunctionComponent<Props> = () => {
  const [showSend, setShowSend] = useState(false);
  const [showDisableMfa, setShowDisableMfa] = useState(false);

  const toggleSend = useCallback(() => setShowSend((prevState) => !prevState), [setShowSend]);

  const toggleDisableMfa = useCallback(() => setShowDisableMfa((prevState) => !prevState), [setShowDisableMfa]);

  const { data } = useQuery<getViewer>(GET_VIEWER, {
    fetchPolicy: 'cache-first',
  });

  if (!data) return <div />;

  return (
    <div className="person-info-form">
      <div className="card-header">
        <Trans>Contact Information</Trans>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>
              <Trans>Private Address 1</Trans>
            </label>
            <Field type="text" className="form-control" name="privateAddress1" />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              <Trans>Private Address 2</Trans>
            </label>
            <Field type="text" className="form-control" name="privateAddress2" />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              <Trans>Private Zip</Trans>
            </label>
            <Field type="text" className="form-control" name="privateZip" />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              <Trans>Private City</Trans>
            </label>
            <Field type="text" className="form-control" name="privateCity" />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              <Trans>Company Phone</Trans>
            </label>
            <PhoneInput name="companyPhone" />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              <Trans>Cellphone</Trans>
            </label>
            <PhoneInput name="cellphone" />

            <FieldErrorMessage name="cellphone" />
          </div>
        </div>
      </div>

      <div className="form-group">
        <label>
          <Trans>Email</Trans>
        </label>
        <Field type="email" className="form-control" name="email" />

        <FieldErrorMessage name="email" />
      </div>

      <div className="d-md-flex">
        <Field name="id">
          {({ field }: { field: any }) => {
            if (!field.value) return '';

            return (
              <>
                <button type="button" className="btn btn-link mr-4" onClick={toggleSend}>
                  <i className="icon-send-email" />
                  <Trans>Send Credentials</Trans>
                </button>

                <UserSendModal show={showSend} userId={field.value} onClose={toggleSend} />
              </>
            );
          }}
        </Field>

        <Field name="mfaEnabled">
          {({ field }: { field: any }) => {
            if (!field.value) return '';

            return (
              <button type="button" className="btn btn-link" onClick={toggleDisableMfa}>
                <i className="icon-2FA" />
                <Trans>Disable Two Factor</Trans>
              </button>
            );
          }}
        </Field>

        {data!.viewer.mode === LoginMode.User && data!.viewer.user!.role === UserRole.Admin && (
          <div className="form-group">
            <Field name="id">
              {({ field }: { field: any }) => (
                <PersonDisableMfaModal id={field.value} show={showDisableMfa} onClose={toggleDisableMfa} />
              )}
            </Field>
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonInfoForm;
