import gql from 'graphql-tag';

export const GET_TIMEZONE_CONFIG = gql`
  query getTimezoneConfig {
    timezoneConfig
  }
`;

export const UPDATE_TIMEZONE_CONFIG = gql`
  mutation updateTimezoneConfig($timezone: String!) {
    updateTimezoneConfig(timezone: $timezone)
  }
`;

export const GET_CURRENCY_CONFIG = gql`
  query getCurrencyConfig {
    currencyConfig {
      currency
    }
  }
`;

export const UPDATE_CURRENCY_CONFIG = gql`
  mutation updateCurrencyConfig($payload: CurrencyConfigPayload!) {
    updateCurrencyConfig(payload: $payload)
  }
`;

export const GET_SMS_CONFIG = gql`
  query getSmsConfig {
    smsConfig {
      serviceType
      defaultSenderId
      clickSend {
        username
        password
      }
    }
  }
`;

export const UPDATE_SMS_CONFIG = gql`
  mutation updateSmsConfig($config: SmsConfigPayload!) {
    updateSmsConfig(config: $config)
  }
`;

export const GET_ASSET_UPTIME_CONFIG = gql`
  query getAssetUptimeConfig {
    assetUptimeConfig
  }
`;

export const UPDATE_ASSET_UPTIME_CONFIG = gql`
  mutation updateAssetUptimeConfig($apiKey: String!) {
    updateAssetUptimeConfig(apiKey: $apiKey)
  }
`;

export const GET_TWITTER_CONFIG = gql`
  query getTwitterConfig {
    twitterConfig {
      consumerKey
      tokenKey
    }
  }
`;

export const UPDATE_TWITTER_CONFIG = gql`
  mutation updateTwitterConfig($config: TwitterConfigPayload!) {
    updateTwitterConfig(config: $config)
  }
`;

export const GET_OUTLOOK_APP_CONFIG = gql`
  query getOutlookAppConfig {
    outlookAppConfig {
      appId
    }
  }
`;

export const UPDATE_OUTLOOK_APP_CONFIG = gql`
  mutation updateOutlookAppConfig($payload: OutlookAppConfigPayload!) {
    updateOutlookAppConfig(payload: $payload)
  }
`;

export const GET_OUTLOOK_SYSTEM_ACCOUNT = gql`
  query getOutlookSystemAccount {
    outlookSystemAccount {
      email
    }
  }
`;

export const CONFIGURE_OUTLOOK_SYSTEM_ACCOUNT = gql`
  mutation configureOutlookSystemAccount($payload: ConfigureOutlookSystemAccountPayload!) {
    configureOutlookSystemAccount(payload: $payload)
  }
`;

export const GET_OUTLOOK_TICKET_ACCOUNT = gql`
  query getOutlookTicketAccount {
    outlookTicketAccount {
      email
    }
  }
`;

export const CONFIGURE_OUTLOOK_TICKET_ACCOUNT = gql`
  mutation configureOutlookTicketAccount($payload: ConfigureOutlookTicketAccountPayload!) {
    configureOutlookTicketAccount(payload: $payload)
  }
`;

export const GET_OUTLOOK_TICKET_MAIL_FOLDERS = gql`
  query getOutlookTicketMailFolders {
    outlookTicketMailFolders {
      id
      displayName
    }
  }
`;

export const GET_OUTLOOK_TICKET_DONE_MAIL_FOLDER = gql`
  query getOutlookTicketDoneMailFolder {
    outlookTicketDoneMailFolder {
      id
      displayName
    }
  }
`;

export const UPDATE_OUTLOOK_TICKET_DONE_MAIL_FOLDER = gql`
  mutation updateOutlookTicketDoneMailFolder($payload: String) {
    updateOutlookTicketDoneMailFolder(id: $payload)
  }
`;

export const GET_MESSAGE_SUBSCRIPTION_CONFIG = gql`
  query getMessageSubscriptionConfig {
    messageSubscriptionConfig {
      entries {
        category {
          id
          name
        }
        email
        push
      }
    }
  }
`;

export const UPDATE_MESSAGE_SUBSCRIPTION_CONFIG = gql`
  mutation updateMessageSubscriptionConfig($payload: MessageSubscriptionConfigPayload!) {
    updateMessageSubscriptionConfig(payload: $payload)
  }
`;

export const GET_NINJA_RMM_CONFIG = gql`
  query getNinjaRmmConfig {
    ninjaRmmConfig {
      apiKey
      location
      syncIntervalSeconds
    }
  }
`;

export const UPDATE_NINJA_RMM_CONFIG = gql`
  mutation updateNinjaRmmConfig($payload: NinjaRmmPayload!) {
    updateNinjaRmmConfig(payload: $payload)
  }
`;

export const NINJA_RMM_ORGANIZATION = gql`
  fragment NinjaRmmOrganization on NinjaRmmOrganization {
    id
    name
    company {
      id
      name
    }
  }
`;

export const NINJA_RMM_ORGANIZATIONS = gql`
  fragment NinjaRmmOrganizations on NinjaRmmOrganizationCollection {
    limit
    offset
    total
    items {
      ...NinjaRmmOrganization
    }
  }
  ${NINJA_RMM_ORGANIZATION}
`;

export const GET_NINJA_RMM_ORGANIZATIONS = gql`
  query getNinjaRmmOrganizations($query: NinjaRmmOrganizationsQuery!) {
    ninjaRmmOrganizations(query: $query) {
      ...NinjaRmmOrganizations
    }
  }
  ${NINJA_RMM_ORGANIZATIONS}
`;

export const CONNECT_NINJA_RMM = gql`
  mutation connectNinjaRmm($companyId: ID!, $ninjaRmmOrganizationId: ID!) {
    connectNinjaRmm(companyId: $companyId, ninjaRmmOrganizationId: $ninjaRmmOrganizationId)
  }
`;

export const DISCONNECT_NINJA_RMM = gql`
  mutation disconnectNinjaRmm($ninjaRmmOrganizationId: ID!) {
    disconnectNinjaRmm(ninjaRmmOrganizationId: $ninjaRmmOrganizationId)
  }
`;

export const GET_BEXIO_CONFIG = gql`
  query getBexioConfig {
    bexioConfig {
      apiKey
      limitPerHour
    }
  }
`;

export const GET_BEXIO_INVOICE_CONFIG = gql`
  query getBexioInvoiceConfig {
    bexioInvoiceConfig {
      bankAccountId
      paymentTypeId
      mwstType
      mswtIsNet
      taxId
      noVatTaxId
      defaultProductAccountId
    }
  }
`;

export const GET_BEXIO_BANK_ACCOUNTS = gql`
  query getBexioBankAccounts {
    bexioBankAccounts {
      id
      name
    }
  }
`;

export const GET_BEXIO_PAYMENT_TYPES = gql`
  query getBexioPaymentTypes {
    bexioPaymentTypes {
      id
      name
    }
  }
`;

export const GET_BEXIO_TAXES = gql`
  query getBexioTaxes {
    bexioTaxes {
      id
      name
    }
  }
`;

export const GET_BEXIO_ACCOUNTS = gql`
  query getBexioAccounts {
    bexioAccounts {
      id
      name
    }
  }
`;

export const UPDATE_BEXIO_CONFIG = gql`
  mutation updateBexioConfig($payload: BexioConfigPayload!) {
    updateBexioConfig(payload: $payload)
  }
`;

export const UPDATE_BEXIO_INVOICE_CONFIG = gql`
  mutation updateBexioInvoiceConfig($payload: BexioInvoiceConfigPayload!) {
    updateBexioInvoiceConfig(payload: $payload)
  }
`;

export const GET_TIME_TRACKER_CONFIG = gql`
  query getTimeTrackerConfig {
    timeTrackerConfig {
      defaultEntryProduct {
        id
        title
      }
      defaultProjectName
      defaultEntryProductId @client
    }
  }
`;

export const ExtendTimeTrackerConfig = {
  defaultEntryProductId: (obj: any) => obj.defaultEntryProduct?.id || null,
};

export const UPDATE_TIME_TRACKER_CONFIG = gql`
  mutation updateTimeTrackerConfig($payload: TimeTrackerConfigPayload!) {
    updateTimeTrackerConfig(payload: $payload)
  }
`;

export const GET_PERSONAL_OUTLOOK_TICKET_ACCOUNT = gql`
  query getPersonalOutlookTicketAccount($id: ID!) {
    company(id: $id) {
      id
      personalOutlookTicketAccount {
        email
      }
    }
  }
`;

export const CONFIGURE_PERSONAL_OUTLOOK_TICKET_ACCOUNT = gql`
  mutation configurePersonalOutlookTicketAccount($id: ID!, $payload: ConfigureOutlookTicketAccountPayload!) {
    configurePersonalOutlookTicketAccount(companyId: $id, payload: $payload)
  }
`;

export const GET_PERSONAL_OUTLOOK_TICKET_MAIL_FOLDERS = gql`
  query getPersonalOutlookTicketMailFolders($id: ID!) {
    company(id: $id) {
      id
      personalOutlookTicketMailFolders {
        id
        displayName
      }
    }
  }
`;

export const GET_PERSONAL_OUTLOOK_TICKET_DONE_MAIL_FOLDER = gql`
  query getPersonalOutlookTicketDoneMailFolder($id: ID!) {
    company(id: $id) {
      id
      personalOutlookTicketDoneMailFolder {
        id
        displayName
      }
    }
  }
`;

export const UPDATE_PERSONAL_OUTLOOK_TICKET_DONE_MAIL_FOLDER = gql`
  mutation updatePersonalOutlookTicketDoneMailFolder($id: ID!, $payload: String) {
    updatePersonalOutlookTicketDoneMailFolder(companyId: $id, id: $payload)
  }
`;

export const GET_M365_REPORT_CONFIG = gql`
  query getM365ReportConfig {
    m365ReportConfig {
      product {
        id
        title
      }
      productId
      margin
    }
  }
`;

export const ExtendM365ReportConfig = {
  productId: (obj: any) => obj.product?.id || null,
};

export const UPDATE_M365_REPORT_CONFIG = gql`
  mutation updateM365ReportConfig($payload: M365ConfigPayload!) {
    updateM365ReportConfig(payload: $payload)
  }
`;

export const GET_OPEN_AI_CONFIG = gql`
  query getOpenAIConfig {
    openAIConfig {
      apiKey
      endpoint
      prompt
    }
  }
`;

export const UPDATE_OPEN_AI_CONFIG = gql`
  mutation updateOpenAIConfig($payload: OpenAIConfigPayload!) {
    updateOpenAIConfig(config: $payload)
  }
`;
