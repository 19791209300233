import { GraphQLError } from 'graphql';
import { toast } from 'react-toastify';
import { getStorage, removeStorage } from './storage';
import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { history } from '../config/router';

export const authMiddleware = new ApolloLink((operation, forward) => {
  if (getStorage('accessToken')) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${getStorage('accessToken')}`,
      },
    });
  }

  return forward(operation);
});

export const unauthorizedMiddleware = onError(
  ({ response, graphQLErrors }: { response?: any; graphQLErrors?: ReadonlyArray<GraphQLError> }) => {
    if (response && !response.data) response.data = false;

    if (!response && graphQLErrors) {
      try {
        graphQLErrors.forEach(({ message }: { message: any }) => toast.error(message.error || message));
      } catch (e) {}
    }

    if (response && response.errors && response.errors[0] && response.errors[0].message === 'Unauthorized') {
      removeStorage('accessToken');
      history.push('/login');
    }
  },
);

export const getCompanyId = () => getStorage('companyId') || 0;
