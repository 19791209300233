import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { lastDayOfMonth } from 'date-fns';
import React, { FunctionComponent, useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import MainTitle from '../components/common/MainTitle';
import ReportAllContractForm from '../components/report/ReportAllContractForm';
import ReportAssetForm from '../components/report/ReportAssetForm';
import ReportAzureForm from '../components/report/ReportAzureForm';
import ReportContactForm from '../components/report/ReportContactForm';
import ReportInvoiceForm from '../components/report/ReportInvoiceForm';
import ReportLogForm from '../components/report/ReportLogForm';
import ReportM365FormOld from '../components/report/ReportM365FormOld';
import ReportReminderForm from '../components/report/ReportReminderForm';
import ReportTimeTrackerForm from '../components/report/ReportTimeTrackerForm';
import ReportVendorForm from '../components/report/ReportVendorForm';
import ReportM365Form from '../components/report/ReportM365Form';
import { useLanguage } from '../schema/locale';

const today = new Date();

type Props = {};

const InvoiceReportPage: FunctionComponent<Props> = () => {
  const [date, setDate] = useState(today.toISOString());

  const language = useLanguage();

  const handleDate = useCallback(
    (e: Date) => {
      const date = lastDayOfMonth(e);
      setDate(date.toISOString());
    },
    [setDate],
  );

  return (
    <div className="report-page">
      <div className="breadcrumbs-area">
        <MainTitle title={i18n._(t`Invoice & Reports`)} tips="Reporting/Invoices & Reports" />
      </div>

      <div className="card">
        <div className="card-header d-md-flex align-items-center">
          <div className="mr-3 my-2">
            <Trans>Reporting Effective Date</Trans>
          </div>

          <div className="input-group date">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="icon-calendar" />
              </div>
            </div>
            <div className="form-control">
              <DatePicker
                selected={new Date(date)}
                onChange={handleDate}
                className="datepicker-control"
                dateFormat="yyyy/MM"
                locale={language}
                showMonthYearPicker
              />
            </div>
          </div>
        </div>

        <ReportVendorForm effectiveDate={date} />

        <ReportInvoiceForm effectiveDate={date} />

        <ReportM365FormOld effectiveDate={date} />

        <ReportM365Form effectiveDate={date} />

        <ReportAzureForm effectiveDate={date} />
      </div>

      <ReportTimeTrackerForm effectiveDate={date} />

      <ReportContactForm effectiveDate={date} />

      <ReportAssetForm effectiveDate={date} />

      <ReportAllContractForm />

      <ReportReminderForm />

      <ReportLogForm />
    </div>
  );
};

export default InvoiceReportPage;
