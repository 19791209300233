import { Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import ContractReportingInfoFormItem from './ContractReportingInfoFormItem';
import ContractReportingInfoSearch from './ContractReportingInfoSearch';

type Props = {};

const ContractReportingInfoForm: FunctionComponent<Props> = () => {
  return (
    <div className="contract-detail-form">
      <div className="history-item form-group">
        <label>
          <Trans>Product</Trans>
        </label>

        <ContractReportingInfoSearch name="reportingProducts" />
      </div>

      <div className="document-product-table table-responsive">
        <Field name="reportingProducts">
          {({ field, form }: { field: any; form: FormikProps<any> }) => {
            const handleRemove = (index: number) => () => {
              field.value.splice(index, 1);
              form.setFieldValue('reportingProducts', field.value);
            };

            if (!field.value || !field.value.length) return '';

            return (
              <table className="custom-table">
                <thead className="custom-table-header">
                  <tr>
                    <th className="column title">
                      <Trans>Product</Trans>
                    </th>
                    <th className="column number">
                      <Trans>Pieces</Trans>
                    </th>
                    <th className="column title">
                      <Trans>Vendor</Trans>
                    </th>
                    <th className="column action" />
                  </tr>
                </thead>

                <tbody>
                  {field.value &&
                    field.value.map((el: any, index: number) => (
                      <ContractReportingInfoFormItem
                        key={index}
                        name={`reportingProducts[${index}]`}
                        onRemove={handleRemove(index)}
                      />
                    ))}
                </tbody>
              </table>
            );
          }}
        </Field>
      </div>
    </div>
  );
};

export default ContractReportingInfoForm;
