import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent, useCallback } from 'react';
import Select from 'react-select';
import { selectOptions } from '../../utils/const';

type Props = {
  name: string;
  nameField?: string;
  valueField?: string;
  search$: any;
  loading: boolean;
  data?: any;
  placeholder?: string | null;
  onChange?: (value: []) => void | null;
};

const MultipleSelect: FunctionComponent<Props> = ({
  name,
  search$,
  loading,
  data,
  placeholder,
  nameField = 'name',
  valueField = 'id',
  onChange,
}) => {
  const handleLoad = useCallback((value: string) => search$.next(value), [search$]);

  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (values: any) => {
          const result = values ? values.map((el: any) => el.value) : [];
          form.setFieldValue(name, result);
          if (onChange) onChange(result);
        };

        const model = field.value
          ? field.value.map((el: any) => ({
              value: el[valueField],
              label: el[nameField],
            }))
          : [];

        const options = loading
          ? []
          : data.map((el: any) => ({
              value: el[valueField],
              label: el[nameField],
            }));

        return (
          <Select
            defaultValue={model}
            options={options}
            isLoading={loading}
            isMulti
            cacheOptions
            defaultOptions
            onChange={handleChange}
            onInputChange={handleLoad}
            placeholder={placeholder || i18n._(t`Select...`)}
            {...selectOptions}
          />
        );
      }}
    </Field>
  );
};

export default MultipleSelect;
