import { useMutation, useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { GET_OPEN_AI_CONFIG, UPDATE_OPEN_AI_CONFIG } from '../../schema/config';
import { OpenAIConfigPayload } from '../../schema/graphql-global-types';
import { getOpenAIConfig } from '../../schema/types/getOpenAIConfig';
import { updateOpenAIConfig, updateOpenAIConfigVariables } from '../../schema/types/updateOpenAIConfig';
import { toastErrorQuery } from '../../utils/error';
import { validateRequired } from '../../utils/validations';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';

type Props = {
  show: boolean;
  onClose: () => void;
};

const ConfigurationChatGPTModal: FunctionComponent<Props> = ({ show, onClose }) => {
  const { data, refetch } = useQuery<getOpenAIConfig>(GET_OPEN_AI_CONFIG);

  const [mutate] = useMutation<updateOpenAIConfig, updateOpenAIConfigVariables>(UPDATE_OPEN_AI_CONFIG);

  const model: OpenAIConfigPayload = {
    apiKey: data?.openAIConfig?.apiKey || '',
    endpoint: data?.openAIConfig?.endpoint || '',
    prompt:
      data?.openAIConfig?.prompt ||
      "You are a helpful IT Support Assistant. Your answers are polite, short and clear. Always use a formal Salutation. Don't add a Signature at the end.",
  };

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Azure ChatGPT Connector</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              enableReinitialize
              initialValues={model}
              validationSchema={yup.object().shape({
                apiKey: validateRequired,
              })}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const result: any = await mutate({
                    variables: {
                      payload: values,
                    },
                  });

                  if (result.errors) {
                    toastErrorQuery(result.errors);
                  } else {
                    toast.success(i18n._(t`Success!`));
                    refetch();
                    onClose();
                  }
                } catch (e) {
                  toastErrorQuery(e);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting, submitForm }) => {
                const handleSubmit = (e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  submitForm();
                };

                return (
                  <Form onSubmitCapture={handleSubmit}>
                    <div className="modal-body">
                      <div className="form-group">
                        <label>
                          <Trans>API Key</Trans>
                        </label>

                        <Field type="text" className="form-control" name="apiKey" autoFocus />

                        <FieldErrorMessage name="apiKey" />
                      </div>

                      <div className="form-group">
                        <label>
                          <Trans>Endpoint</Trans>
                        </label>

                        <Field type="text" className="form-control" name="endpoint" />

                        <FieldErrorMessage name="endpoint" />
                      </div>

                      <div className="form-group">
                        <label>
                          <Trans>Prompt</Trans>
                        </label>

                        <Field type="text" component="textarea" className="form-control" name="prompt" rows={5} />

                        <FieldErrorMessage name="prompt" />
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={onClose}>
                        <Trans>Close</Trans>
                      </button>

                      <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfigurationChatGPTModal;
