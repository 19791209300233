import { useMutation, useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import { pick } from 'lodash';
import React, { FunctionComponent } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { GET_M365_REPORT_CONFIG, UPDATE_M365_REPORT_CONFIG } from '../../schema/config';
import { getM365ReportConfig } from '../../schema/types/getM365ReportConfig';
import { updateM365ReportConfig, updateM365ReportConfigVariables } from '../../schema/types/updateM365ReportConfig';
import { toastErrorQuery } from '../../utils/error';
import { validateNumber, validateRequired } from '../../utils/validations';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';
import TimeTrackerProductForm from '../timeTracker/TimeTrackerProductForm';

type Props = {
  show: boolean;
  onClose: () => void;
};

const ConfigurationM365ImportModal: FunctionComponent<Props> = ({ show, onClose }) => {
  const { data, refetch } = useQuery<getM365ReportConfig>(GET_M365_REPORT_CONFIG);

  const [mutate] = useMutation<updateM365ReportConfig, updateM365ReportConfigVariables>(UPDATE_M365_REPORT_CONFIG);

  const model: any = {
    product: data?.m365ReportConfig?.product || '',
    productId: data?.m365ReportConfig?.productId || '',
    margin: data?.m365ReportConfig?.margin || 0,
  };

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>M365 Import</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              enableReinitialize
              initialValues={model}
              validationSchema={yup.object().shape({
                productId: validateRequired,
                margin: validateNumber.min(5, t`Must be at least 5`),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const payload: any = pick(values, ['productId', 'margin']);
                  payload.margin = Number(payload.margin);

                  const result: any = await mutate({
                    variables: {
                      payload,
                    },
                  });

                  if (result.errors) {
                    toastErrorQuery(result.errors);
                  } else {
                    toast.success(i18n._(t`Success!`));
                    refetch();
                    onClose();
                  }
                } catch (e) {
                  toastErrorQuery(e);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting, submitForm }) => {
                const handleSubmit = (e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  submitForm();
                };

                return (
                  <Form onSubmitCapture={handleSubmit}>
                    <div className="modal-body">
                      <div className="form-group">
                        <label>
                          <Trans>Default M365 Product</Trans>
                        </label>

                        <TimeTrackerProductForm name="product" all />

                        <FieldErrorMessage name="productId" />
                      </div>

                      <div className="form-group">
                        <label>
                          <Trans>Default M365 Margin</Trans>
                        </label>

                        <div className="input-group" style={{ maxWidth: 150 }}>
                          <Field type="text" className="form-control" name="margin" />

                          <div className="input-group-append">
                            <div className="input-group-text">%</div>
                          </div>
                        </div>

                        <FieldErrorMessage name="margin" />
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={onClose}>
                        <Trans>Close</Trans>
                      </button>

                      <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfigurationM365ImportModal;
