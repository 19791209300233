import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import ButtonSubmit from '../form/ButtonSubmit';
import TimeTrackerAddingForm from './TimeTrackerAddingForm';
import TimeTrackerDetailForm from './TimeTrackerDetailForm';
import TimeTrackerEntryForm from './TimeTrackerEntryForm';

type Props = {
  loading?: boolean;
  refetch: () => void;
};

const TimeTrackerUpdateForm: FunctionComponent<Props> = ({ loading, refetch }) => {
  return (
    <Form>
      <div className="form-breadcrumbs-area">
        <NavLink to="/support/time-tracking">
          <Trans>Time Tracking</Trans>
        </NavLink>

        <i className="fa fa-angle-left" />

        <Field name="id">
          {({ field }: { field: FieldInputProps<string> }) => {
            if (field.value) return <Trans>Edit project</Trans>;

            return <Trans>Add new project</Trans>;
          }}
        </Field>
      </div>

      <div className="table-actions form-table-actions">
        <div className="breadcrumbs-area">
          <Field name="title">
            {({ field }: { field: FieldInputProps<string> }) => {
              return <div className="main-title">{field.value}</div>;
            }}
          </Field>
        </div>

        <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save updates`)} loading={loading} />
      </div>

      <div className="card">
        <div className="card-body">
          <TimeTrackerDetailForm />
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <Trans>Time Entries</Trans>
        </div>
        <div className="card-body">
          <TimeTrackerEntryForm refetch={refetch} />
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <Trans>Addings</Trans>
        </div>
        <div className="card-body" style={{ minHeight: 300 }}>
          <TimeTrackerAddingForm />
        </div>
      </div>
    </Form>
  );
};

export default TimeTrackerUpdateForm;
