import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import ReactTable, { CellInfo } from 'react-table';
import { NavLink } from 'react-router-dom';
import MainTitle from '../components/common/MainTitle';
import TableActions from '../components/common/TableActions';
import UserAvatarName from '../components/common/UserAvatarName';
import ButtonArchive from '../components/form/ButtonArchive';
import ButtonEdit from '../components/form/ButtonEdit';
import LoadingTable from '../components/common/LoadingTable';
import { getUsers, getUsersVariables } from '../schema/types/getUsers';
import { GET_USERS, ARCHIVE_USER, UNARCHIVE_USER } from '../schema/user';
import { archiveUser, archiveUserVariables } from '../schema/types/archiveUser';
import { unarchiveUser, unarchiveUserVariables } from '../schema/types/unarchiveUser';
import { tableOptions, tablePollingInterval } from '../utils/const';
import ErrorQuery from '../components/form/ErrorQuery';
import { cloneDeep } from 'lodash';
import { DataProxy } from '@apollo/client/cache';
import { useVariables } from '../utils/hooks';
import { getFilter, getPhone } from '../utils/utils';

const UserPage: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);
  const {
    search$,
    search,
    filter$,
    filter,
    archived,
    setArchived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    orderDirection,
  } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const updateQuery = (proxy: DataProxy, id: any) => {
    const data: any = cloneDeep(proxy.readQuery({ query: GET_USERS, variables }));
    data.users.items = data.users.items.filter((el: any) => el.id !== id);
    proxy.writeQuery({ query: GET_USERS, variables, data });
  };

  const [mutateArchive] = useMutation<archiveUser, archiveUserVariables>(ARCHIVE_USER, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.archiveUser.user.id);
    },
  });
  const [mutateUnarchive] = useMutation<unarchiveUser, unarchiveUserVariables>(UNARCHIVE_USER, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.unarchiveUser.user.id);
    },
  });

  const handleArchive = useCallback(
    (id: any) => () => {
      if (archived) {
        mutateUnarchive({
          variables: {
            id,
          },
          optimisticResponse: {
            unarchiveUser: {
              user: {
                id,
                __typename: 'User',
              },
              __typename: 'MutateUserResponse',
            },
          },
        });
      } else {
        mutateArchive({
          variables: {
            id,
          },
          optimisticResponse: {
            archiveUser: {
              user: {
                id,
                __typename: 'User',
              },
              __typename: 'MutateUserResponse',
            },
          },
        });
      }
    },
    [archived, mutateArchive, mutateUnarchive],
  );

  const { data, previousData, loading, error } = useQuery<getUsers, getUsersVariables>(GET_USERS, {
    variables,
    pollInterval: tablePollingInterval,
  });

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.users.items ?? previousData?.users.items ?? [];
  const pages = data && data.users ? Math.ceil(data.users.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Name`),
      accessor: 'fullName',
      Cell: (cell: CellInfo) => (
        <NavLink to={`/settings/user/${cell.original.id}`}>
          <UserAvatarName user={cell.original} />
        </NavLink>
      ),
    },
    {
      Header: i18n._(t`Phone`),
      accessor: 'companyPhone',
      Cell: (cell: CellInfo) => <a href={`tel:${getPhone(cell.value)}`}>{cell.value}</a>,
    },
    {
      Header: i18n._(t`Email`),
      accessor: 'email',
      Cell: (cell: CellInfo) => <a href={`mailto:${cell.value}`}>{cell.value}</a>,
    },
    {
      Header: i18n._(t`Role`),
      accessor: 'role',
      filterable: true,
      width: 100,
      Cell: (cell: CellInfo) => i18n._(cell.value),
      Filter: (params: any) => (
        <select
          className="form-control"
          onChange={(event) => params.onChange(event.target.value ? [event.target.value] : '')}
          value={params.filter ? params.filter.value[0] : ''}
        >
          <option value="">{i18n._(t`All`)}</option>
          <option value="Admin">{i18n._(t`Admin`)}</option>
          <option value="Member">{i18n._(t`Member`)}</option>
        </select>
      ),
    },
    {
      Header: i18n._(t`Position`),
      accessor: 'function',
    },
    {
      Header: i18n._(t`Actions`),
      width: 90,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <>
          <NavLink to={`/settings/user/${cell.original.id}`}>
            <ButtonEdit />
          </NavLink>
          <ButtonArchive onClick={handleArchive(cell.original.id)} />
        </>
      ),
    },
  ];

  return (
    <div className="user-page">
      <div className="card-body">
        <div className="table-actions">
          <div className="breadcrumbs-area">
            <MainTitle title={i18n._(t`System Users`)} tips="Settings/System User" />
          </div>

          <TableActions onSearch={(e) => search$.next(e.target.value)} archived={archived} onArchive={setArchived} />

          <NavLink to="/settings/user/add" type="button" className="btn btn-sm btn-primary">
            <i className="icon-add" />
            <Trans>Add new user</Trans>
          </NavLink>
        </div>

        {!loaded ? (
          <LoadingTable />
        ) : (
          <ReactTable
            data={items}
            columns={columns}
            pages={pages}
            pageSize={limit}
            manual
            onFetchData={(state) => {
              filter$.next(
                getFilter(state, {
                  role: 'roles',
                }),
              );

              setOffset(state.page * state.pageSize);
            }}
            onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
            {...tableOptions()}
          />
        )}
      </div>
    </div>
  );
};

export default UserPage;
