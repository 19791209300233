import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import ReactTable, { CellInfo } from 'react-table';
import LoadingTable from '../components/common/LoadingTable';
import MainTitle from '../components/common/MainTitle';
import FilterHeader from '../components/form/FilterHeader';
import { PERSON_GET_DOCUMENTS } from '../schema/document';
import { GET_DOCUMENT_CATEGORIES } from '../schema/documentCategory';
import { DocumentVisibility, OrderDirection } from '../schema/graphql-global-types';
import { GET_DOCUMENT_TAGS } from '../schema/tag';
import { personGetDocuments, personGetDocumentsVariables } from '../schema/types/personGetDocuments';
import { Tag } from '../schema/types/Tag';
import { getCompanyId } from '../utils/auth';
import { tableOptions, tablePollingInterval } from '../utils/const';
import ErrorQuery from '../components/form/ErrorQuery';
import { useVariables } from '../utils/hooks';
import { getFilter, getOrder } from '../utils/utils';

const PersonDocumentationPage: FunctionComponent<RouteComponentProps> = ({ match }) => {
  const [loaded, setLoaded] = useState(false);
  const {
    search$,
    search,
    filter,
    filter$,
    archived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
  } = useVariables();

  const variables = {
    id: getCompanyId(),
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
        visibility:
          match.url === '/documentation/public'
            ? [DocumentVisibility.Public]
            : [DocumentVisibility.AllCustomers, DocumentVisibility.SelectedCustomers],
        template: false,
      },
    },
  };

  const { data, previousData, loading, error } = useQuery<personGetDocuments, personGetDocumentsVariables>(
    PERSON_GET_DOCUMENTS,
    {
      variables,
      pollInterval: tablePollingInterval,
    },
  );

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.company.documents.items ?? previousData?.company.documents.items ?? [];
  const pages = data && data.company ? Math.ceil(data.company.documents.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Title`),
      accessor: 'title',
      sortable: true,
      Cell: (cell: CellInfo) => (
        <a href={cell.original.publicUrl} target="_blank">
          {cell.value}
        </a>
      ),
    },
    {
      Header: i18n._(t`Category`),
      accessor: 'category.name',
      filterable: true,
      Filter: (params: any) => (
        <FilterHeader
          query={GET_DOCUMENT_CATEGORIES}
          objectType="documentCategories"
          nameField="name"
          onChange={params.onChange}
        />
      ),
    },
    {
      Header: i18n._(t`Tags`),
      accessor: 'tags',
      filterable: true,
      Cell: (cell: CellInfo) => (
        <>
          {cell.value.map((el: Tag) => (
            <span key={el.id} className="badge mr-1 mb-1">
              {el.name}
            </span>
          ))}
        </>
      ),
      Filter: (params: any) => (
        <FilterHeader query={GET_DOCUMENT_TAGS} objectType="documentTags" nameField="name" onChange={params.onChange} />
      ),
    },
  ];

  return (
    <div className="document-page">
      <div className="card-body">
        <div className="table-actions">
          <div className="breadcrumbs-area">
            <MainTitle title={i18n._(t`Documentations`)} />
          </div>

          <div className="item search input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="icon-search" />
              </div>
            </div>
            <input
              className="form-control"
              placeholder={i18n._(t`Search`)}
              onChange={(e) => search$.next(e.target.value)}
            />
          </div>
        </div>

        {!loaded ? (
          <LoadingTable />
        ) : (
          <ReactTable
            data={items}
            columns={columns}
            pages={pages}
            pageSize={limit}
            manual
            onFetchData={(state) => {
              filter$.next(
                getFilter(state, {
                  customers: 'visibility',
                  tags: 'tagIds',
                  'category.name': 'categoryIds',
                  'editor.fullName': 'editorIds',
                }),
              );

              let orderDir = OrderDirection.Desc;
              if (state.sorted[0]) {
                orderDir = state.sorted[0].desc ? OrderDirection.Desc : OrderDirection.Asc;
              }

              setOffset(state.page * state.pageSize);
              setOrderBy(
                getOrder(state, {
                  'editor.fullName': 'editorName',
                }),
              );
              setOrderDirection(orderDir);
            }}
            onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
            {...tableOptions()}
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(PersonDocumentationPage);
