import React, { FunctionComponent, useCallback } from 'react';
import classNames from 'classnames';
import { useMutation, useQuery } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import MenuLink from '../components/MenuLink';
import logo from '../img/logo_homer_150x40.svg';
import logoSmall from '../img/icon_homer_collapse_40x40.svg';
import logoWhite from '../img/logo_homer_150x40_white.svg';
import logoSmallWhite from '../img/icon_homer_collapse_40x40_white.svg';
import { Route } from '../config/router';
import { GET_PERSONAL_OUTLOOK_TICKET_ACCOUNT } from '../schema/config';
import { GET_SIDEBAR_STATE, TOGGLE_SIDEBAR_ITEM } from '../schema/sidebar';
import {
  getPersonalOutlookTicketAccount,
  getPersonalOutlookTicketAccountVariables,
} from '../schema/types/getPersonalOutlookTicketAccount';
import { sidebarState } from '../schema/types/sidebarState';
import { getCompanyId } from '../utils/auth';
import { useViewer } from '../utils/hooks';
import { getRoutes } from '../utils/utils';

type Props = {};

const Sidebar: FunctionComponent<Props> = () => {
  const { data } = useViewer();

  const sidebar = useQuery<sidebarState>(GET_SIDEBAR_STATE);
  const openItem = sidebar.data!.sidebarState.openItem;

  const [toggleSidebarItem] = useMutation(TOGGLE_SIDEBAR_ITEM);

  const toggle = useCallback(
    (index: number, element: Route) => (e: any) => {
      if (!element.href) {
        e.preventDefault();
        toggleSidebarItem({
          variables: {
            index,
          },
        });
      }
    },
    [toggleSidebarItem],
  );

  const outlook = useQuery<getPersonalOutlookTicketAccount, getPersonalOutlookTicketAccountVariables>(
    GET_PERSONAL_OUTLOOK_TICKET_ACCOUNT,
    {
      variables: {
        id: getCompanyId(),
      },
    },
  );

  if (!data) return <div />;
  if (getCompanyId() && !outlook.data) return <div />;

  const routes = getRoutes(data.viewer);

  return (
    <aside className={classNames('sidebar-area', { active: sidebar.data!.sidebarState.open })}>
      {sidebar.data?.sidebarState.darkMode ? (
        <NavLink className="navbar-brand" to="/" activeClassName="active" exact>
          <img alt="MultiCloud" className="navbar-brand-img logo" src={logoSmallWhite} style={{ width: '40px' }} />
          <img alt="MultiCloud" className="navbar-brand-img main-logo" src={logoWhite} style={{ width: '150px' }} />
        </NavLink>
      ) : (
        <NavLink className="navbar-brand" to="/" activeClassName="active" exact>
          <img alt="MultiCloud" className="navbar-brand-img logo" src={logoSmall} style={{ width: '40px' }} />
          <img alt="MultiCloud" className="navbar-brand-img main-logo" src={logo} style={{ width: '150px' }} />
        </NavLink>
      )}

      <ul className="side-menu">
        {routes.map((el, id) => {
          if (el.hide) return '';

          return (
            <MenuLink
              key={id}
              className={classNames('slide', {
                'is-expanded': openItem[id],
              })}
              path={el.href}
              exact={el.exact}
            >
              <NavLink
                className="side-menu__item"
                to={el.href}
                exact={el.exact}
                onClick={toggle(id, el)}
                activeClassName="active"
              >
                <i className={classNames('side-menu__icon', el.icon)} />
                <span className="side-menu__label">{el.name}</span>
                {el.children && <i className="angle fa fa-angle-down" />}
              </NavLink>

              {el.children && (
                <ul className="slide-menu">
                  {el.children.map((child, id2) => {
                    if (
                      (child.href === '/support/ticket/internal' || child.href === '/support/ticket/closed') &&
                      !outlook.data!.company.personalOutlookTicketAccount
                    )
                      return '';

                    return (
                      !child.hide && (
                        <MenuLink key={id2} path={child.href} exact={el.exact}>
                          <NavLink className="slide-item" exact={el.exact} to={child.href} activeClassName="active">
                            {child.name}
                          </NavLink>
                        </MenuLink>
                      )
                    );
                  })}
                </ul>
              )}
            </MenuLink>
          );
        })}
      </ul>
    </aside>
  );
};

export default Sidebar;
