import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import striptags from 'striptags';
import { TicketDetail } from '../../schema/types/TicketDetail';
import { nl2br } from '../../utils/utils';
import TimeAgo from '../common/TimeAgo';

const truncate = (value: string, length: number) => {
  const trim = striptags(value).trim();
  const result = trim.substr(0, length);

  return result.length < trim.length ? `${nl2br(result)}...` : nl2br(result);
};

type Props = {
  model: TicketDetail;
  type?: 'waiting' | 'working';
};

const PersonalTicketMessagePreview: FunctionComponent<Props> = ({ model, type }) => {
  return (
    <NavLink to={`/support/procloud/${model.id}`} className="message">
      <div className="meta">
        <h3 className="title">{model.subject}</h3>

        {type === 'waiting' && (
          <span className="badge waiting">
            <Trans>Waiting for reply</Trans>
          </span>
        )}
        {type === 'working' && (
          <span className="badge working">
            <Trans>Our Team is working on it</Trans>
          </span>
        )}
      </div>

      <div
        className="description post-content"
        dangerouslySetInnerHTML={{
          __html: truncate(model.body, 120),
        }}
      />

      <div className="meta">
        <div className="email text-break">{model.lastSender}</div>

        <div className="date">
          <TimeAgo id={model.id} value={model.lastActiveTime} />
        </div>
      </div>
    </NavLink>
  );
};

export default PersonalTicketMessagePreview;
