import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import { GET_COMPANIES_FOR_PERSON } from '../../schema/company';
import { CompanyStatus } from '../../schema/graphql-global-types';
import { getCompanies, getCompaniesVariables } from '../../schema/types/getCompanies';
import { useVariables } from '../../utils/hooks';
import MultipleSelect from '../form/MultipleSelect';

type Props = {
  name: string;
  customerOnly?: boolean;
};

const ReportCompanyForm: FunctionComponent<Props> = ({ name, customerOnly }) => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
        status: customerOnly ? [CompanyStatus.Customer] : undefined,
      },
    },
  };

  const { data, loading } = useQuery<getCompanies, getCompaniesVariables>(GET_COMPANIES_FOR_PERSON, {
    variables,
    fetchPolicy: 'cache-first',
  });

  if (!data && !loading) return <div />;

  return (
    <MultipleSelect
      name={name}
      search$={search$}
      loading={loading}
      data={data?.companies.items}
      placeholder={i18n._(t`All Customers`)}
    />
  );
};

export default ReportCompanyForm;
