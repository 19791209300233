import { useMutation, useQuery } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import { get } from 'lodash';
import { setHours, setMinutes } from 'date-fns';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { ChangeEvent, FC } from 'react';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import NotFoundPage from '../../containers/NotFoundPage';
import { i18n } from '@lingui/core';
import { useLanguage } from '../../schema/locale';
import { GET_WEEKDAY_SCHEDULES, UPDATE_WEEK_WORKDAY_SCHEDULES, workdayScheduleKeys } from '../../schema/responder';
import { getWeekdaySchedules } from '../../schema/types/getWeekdaySchedules';
import {
  updateWeekWorkdaySchedules,
  updateWeekWorkdaySchedulesVariables,
} from '../../schema/types/updateWeekWorkdaySchedules';
import { weekdayNames } from '../../utils/const';
import { toastErrorQuery } from '../../utils/error';
import LoadingForm from '../common/LoadingForm';
import ButtonSubmit from '../form/ButtonSubmit';
import ErrorQuery from '../form/ErrorQuery';
import { pick } from 'lodash';

const getDate = (seconds = 0) => {
  const today = new Date();
  today.setHours(seconds / 3600);
  today.setMinutes((seconds % 3600) / 60);
  today.setSeconds(0);

  return today;
};

type Props = {};

const AutoresponderWorkdayForm: FC<Props> = () => {
  const language = useLanguage();

  const [mutate] = useMutation<updateWeekWorkdaySchedules, updateWeekWorkdaySchedulesVariables>(
    UPDATE_WEEK_WORKDAY_SCHEDULES,
  );

  const { data, loading, error } = useQuery<getWeekdaySchedules>(GET_WEEKDAY_SCHEDULES);

  if (loading) return <LoadingForm />;
  if (error) return <ErrorQuery error={error} />;
  if (!data) return <NotFoundPage />;

  const model = {
    weekWorkdaySchedules: data.weekWorkdaySchedules,
  };

  return (
    <Formik
      initialValues={model}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const payloads: any = values.weekWorkdaySchedules.map((el) => pick(el, workdayScheduleKeys));

          const result: any = await mutate({
            variables: {
              payloads,
            },
          });

          if (result.errors) {
            toastErrorQuery(result.errors);
          } else {
            toast.success(i18n._(t`Success!`));
          }
        } catch (e) {
          toastErrorQuery(e);
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="table-responsive">
            <table className="custom-table">
              <thead className="custom-table-header">
                <tr>
                  <th className="column">
                    <Trans>Day</Trans>
                  </th>
                  <th className="column">
                    <Trans>Autoresponder</Trans>
                  </th>
                  <th className="column" />
                </tr>
              </thead>

              <tbody className="custom-table-body">
                {weekdayNames().map((el) => (
                  <tr key={el.id}>
                    <td className="column">{el.label}</td>
                    <td className="column">
                      <Field name={`weekWorkdaySchedules[${el.id}].fromTimeSeconds`}>
                        {({ form }: { form: FormikProps<any> }) => {
                          const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
                            form.setFieldValue(`weekWorkdaySchedules[${el.id}].dayOfWeek`, el.id);

                            if (event.target.value === 'all') {
                              form.setFieldValue(`weekWorkdaySchedules[${el.id}].fromTimeSeconds`, 0);
                              form.setFieldValue(`weekWorkdaySchedules[${el.id}].toTimeSeconds`, 0);
                            } else if (event.target.value === 'no') {
                              form.setFieldValue(`weekWorkdaySchedules[${el.id}].fromTimeSeconds`, 0);
                              form.setFieldValue(`weekWorkdaySchedules[${el.id}].toTimeSeconds`, 24 * 3600 - 1);
                            } else if (event.target.value === 'except') {
                              form.setFieldValue(`weekWorkdaySchedules[${el.id}].fromTimeSeconds`, 8 * 3600);
                              form.setFieldValue(`weekWorkdaySchedules[${el.id}].toTimeSeconds`, 17 * 3600);
                            }
                          };

                          let selected;

                          if (
                            get(form.values, `weekWorkdaySchedules[${el.id}].fromTimeSeconds`) === 0 &&
                            get(form.values, `weekWorkdaySchedules[${el.id}].toTimeSeconds`) === 0
                          ) {
                            selected = 'all';
                          } else if (
                            get(form.values, `weekWorkdaySchedules[${el.id}].fromTimeSeconds`) === 0 &&
                            get(form.values, `weekWorkdaySchedules[${el.id}].toTimeSeconds`) === 24 * 3600 - 1
                          ) {
                            selected = 'no';
                          } else {
                            selected = 'except';
                          }

                          return (
                            <select className="form-control" value={selected} onChange={handleChange}>
                              <option value={'no'}>{i18n._(t`No responder`)}</option>
                              <option value={'all'}>{i18n._(t`All day autoresponder`)}</option>
                              <option value={'except'}>{i18n._(t`Except working hours`)}</option>
                            </select>
                          );
                        }}
                      </Field>
                    </td>

                    <td className="column">
                      <Field name={`weekWorkdaySchedules[${el.id}].fromTimeSeconds`}>
                        {({ form }: { form: FormikProps<any> }) => {
                          if (
                            get(form.values, `weekWorkdaySchedules[${el.id}].fromTimeSeconds`) === 0 &&
                            get(form.values, `weekWorkdaySchedules[${el.id}].toTimeSeconds`) === 0
                          ) {
                            return null;
                          } else if (
                            get(form.values, `weekWorkdaySchedules[${el.id}].fromTimeSeconds`) === 0 &&
                            get(form.values, `weekWorkdaySchedules[${el.id}].toTimeSeconds`) === 24 * 3600 - 1
                          ) {
                            return null;
                          }

                          return (
                            <div className="d-flex align-items-center">
                              <label className="label-lg mr-2">
                                <Trans>Working Hours:</Trans>
                              </label>

                              <Field name={`weekWorkdaySchedules[${el.id}].fromTimeSeconds`}>
                                {({ field, form }: { field: any; form: FormikProps<any> }) => {
                                  const handleChange = (date: Date) => {
                                    if (date) {
                                      form.setFieldValue(`weekWorkdaySchedules[${el.id}].dayOfWeek`, el.id);
                                      form.setFieldValue(
                                        `weekWorkdaySchedules[${el.id}].fromTimeSeconds`,
                                        date.getHours() * 3600 + date.getMinutes() * 60,
                                      );
                                    }
                                  };

                                  const selected = getDate(field.value);

                                  return (
                                    <div className="form-control" style={{ width: 100 }}>
                                      <DatePicker
                                        selected={selected}
                                        onChange={handleChange}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption={i18n._(t`Time`)}
                                        injectTimes={[setHours(setMinutes(new Date(), 59), 23)]}
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        className="datepicker-control"
                                        locale={language}
                                      />
                                    </div>
                                  );
                                }}
                              </Field>

                              <div className="mx-2">—</div>

                              <Field name={`weekWorkdaySchedules[${el.id}].toTimeSeconds`}>
                                {({ field, form }: { field: any; form: FormikProps<any> }) => {
                                  const handleChange = (date: Date) => {
                                    if (date) {
                                      form.setFieldValue(`weekWorkdaySchedules[${el.id}].dayOfWeek`, el.id);
                                      form.setFieldValue(
                                        `weekWorkdaySchedules[${el.id}].toTimeSeconds`,
                                        date.getHours() * 3600 + date.getMinutes() * 60,
                                      );
                                    }
                                  };

                                  const selected = getDate(field.value);

                                  return (
                                    <div className="form-control" style={{ width: 100 }}>
                                      <DatePicker
                                        selected={selected}
                                        onChange={handleChange}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption={i18n._(t`Time`)}
                                        injectTimes={[setHours(setMinutes(new Date(), 59), 23)]}
                                        dateFormat="HH:mm"
                                        timeFormat="HH:mm"
                                        className="datepicker-control"
                                        locale={language}
                                      />
                                    </div>
                                  );
                                }}
                              </Field>
                            </div>
                          );
                        }}
                      </Field>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default AutoresponderWorkdayForm;
