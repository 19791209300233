import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import HistoryItem from '../components/common/HistoryItem';
import LoadingQuery from '../components/common/LoadingQuery';
import MainTitle from '../components/common/MainTitle';
import PersonHomeContact from '../components/home/PersonHomeContact';
import PersonMessageCategory from '../components/home/PersonMessageCategory';
import { PERSON_GET_MESSAGES } from '../schema/message';
import { MessageDetail } from '../schema/types/MessageDetail';
import { personGetMessages, personGetMessagesVariables } from '../schema/types/personGetMessages';
import { getCompanyId } from '../utils/auth';
import { useVariables } from '../utils/hooks';

const PersonHomePage: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);

  const { search, filter, setFilter, archived, limit, offset, orderBy, orderDirection } = useVariables({
    filter: {
      categoryIds: [],
    },
  });

  const variables = useMemo(
    () => ({
      id: getCompanyId(),
      query: {
        limit,
        offset,
        orderBy,
        orderDirection,
        filter: {
          search,
          archived,
          ...filter,
        },
      },
    }),
    [limit, offset, orderBy, orderDirection, search, archived, filter],
  );

  const handleFilter = useCallback((value: string[]) => setFilter({ categoryIds: value }), [setFilter]);

  const { data, loading, fetchMore } = useQuery<personGetMessages, personGetMessagesVariables>(PERSON_GET_MESSAGES, {
    variables,
  });

  const handleMore = useCallback(() => {
    fetchMore({
      variables: {
        query: {
          ...variables.query,
          offset: data!.company.messages.items.length,
        },
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        if (!fetchMoreResult) return prev;

        return {
          company: {
            ...prev.company,
            messages: {
              ...fetchMoreResult.company.messages,
              items: [...prev.company.messages.items, ...fetchMoreResult.company.messages.items],
            },
          },
        };
      },
    });
  }, [fetchMore, variables, data]);

  if (!loading && !loaded) setLoaded(true);

  const hasMore = data && data.company.messages.items.length < data.company.messages.total;

  return (
    <>
      <div className="breadcrumbs-area">
        <MainTitle title={i18n._(t`Dashboard`)} tips="Customer/Dashboard" />
      </div>

      <PersonHomeContact />

      {!loaded ? (
        <LoadingQuery wrapper />
      ) : (
        <div className="card person-home-messages">
          <div className="card-header d-flex justify-content-between">
            <Trans>Message History</Trans>

            <a
              href={`${process.env.REACT_APP_URL}exports/companies/${getCompanyId()}/messages?q=${JSON.stringify(
                variables.query,
              )}`}
              className="download"
              target="_blank"
            >
              <i className="icon-download" />
              <Trans>Export messages with selected categories</Trans>
            </a>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="form-group col-xxl-4 col-sm-6">
                <label>
                  <Trans>Categories</Trans>
                </label>

                <PersonMessageCategory onChange={handleFilter} />
              </div>
            </div>

            {data && (
              <div className="list-group">
                {data.company.messages.items.map((el: MessageDetail) => (
                  <HistoryItem key={el.id} date={el.createdAt} content={el.content} tags={el.categories} />
                ))}

                {data.company.messages.items.length === 0 && <div>No results</div>}
              </div>
            )}

            {hasMore && (
              <div className="text-right mt-3 form-group">
                <button type="button" className="btn btn-link" disabled={loading} onClick={handleMore}>
                  {loading ? <i className="fa fa-spinner fa-spin" /> : <Trans>Load more</Trans>}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PersonHomePage;
