import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { Field } from 'formik';
import React, { FunctionComponent } from 'react';
import { OfferItem } from '../../schema/types/OfferItem';
import { OfferProjectItem } from '../../schema/types/OfferProjectItem';
import { dateOptions } from '../../utils/const';
import DateFormat from '../common/DateFormat';
import UserAvatarName from '../common/UserAvatarName';

type Props = {
  name: string;
  model: OfferItem;
};

const OfferEditorSortableProject: FunctionComponent<Props> = ({ name, model }) => {
  return (
    <div className="product-table document-product-table table-responsive">
      <table className="custom-table">
        <thead className="custom-table-header">
          <tr>
            <th className="column">
              <Trans>Task</Trans>
            </th>
            <th className="column number">
              <Trans>User Assignment</Trans>
            </th>
            <th className="column date-range-wrapper">
              <Trans>Dates</Trans>
            </th>
            <th className="column status">
              <Trans>Status</Trans>
            </th>
          </tr>
        </thead>

        <tbody className="custom-table-body">
          {model.projectItems!.map((item: OfferProjectItem, index: number) => {
            return (
              <tr key={index}>
                <td className="column">
                  <Field name={`${name}.projectItems[${index}].description`}>
                    {({ field }: { field: any }) => field.value}
                  </Field>
                </td>
                <td className="column number">
                  <Field name={`${name}.projectItems[${index}].user`}>
                    {({ field }: { field: any }) => <UserAvatarName user={field.value} />}
                  </Field>
                </td>
                <td className="column date-range-wrapper">
                  <span className="item">
                    <Field name={`${name}.projectItems[${index}].endDate`}>
                      {({ field }: { field: any }) => <DateFormat value={new Date(field.value)} format={dateOptions} />}
                    </Field>
                  </span>
                  -
                  <span className="item">
                    <Field name={`${name}.projectItems[${index}].startDate`}>
                      {({ field }: { field: any }) => <DateFormat value={new Date(field.value)} format={dateOptions} />}
                    </Field>
                  </span>
                </td>
                <td className="column total">
                  <Field name={`${name}.projectItems[${index}].status`}>
                    {({ field }: { field: any }) => (
                      <div className={classNames('btn btn-status w-100', field.value)}>{field.value}</div>
                    )}
                  </Field>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default OfferEditorSortableProject;
