import React, { FC } from 'react';

type Props = {
  value: number;
};

const HeatLevel: FC<Props> = ({ value }) => {
  return (
    <div className="heat-level">
      <div
        className="heat"
        style={{
          width: `${(value / 5) * 100}%`,
        }}
        title={String(value)}
      />
    </div>
  );
};

export default HeatLevel;
