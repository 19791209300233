import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { Product } from '../../schema/types/Product';
import TimeTrackerProductField from './TimeTrackerProductField';

type Props = {
  name: string;
  all?: boolean;
};

const TimeTrackerProductForm: FunctionComponent<Props> = ({ name, all }) => {
  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (value: Product) => {
          form.setFieldValue(name, value);
          form.setFieldValue(`${name}Id`, value.id);
        };

        return <TimeTrackerProductField value={field.value} onSubmit={handleChange} all={all} />;
      }}
    </Field>
  );
};

export default TimeTrackerProductForm;
