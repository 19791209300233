import { useMutation, useQuery } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { FC, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import { i18n } from '@lingui/core';
import { GET_AUTO_RESPONDER_EXCLUSION, UPDATE_AUTO_RESPONDER_EXCLUSION } from '../../schema/responder';
import { getAutoResponderExclusion } from '../../schema/types/getAutoResponderExclusion';
import {
  updateAutoResponderExclusion,
  updateAutoResponderExclusionVariables,
} from '../../schema/types/updateAutoResponderExclusion';
import { selectOptions } from '../../utils/const';
import { toastErrorQuery } from '../../utils/error';
import LoadingTable from '../common/LoadingTable';
import ButtonSubmit from '../form/ButtonSubmit';
import ErrorQuery from '../form/ErrorQuery';

type Props = {};

const AutoresponderExclusionForm: FC<Props> = () => {
  const [loaded, setLoaded] = useState(false);

  const { data, loading, error } = useQuery<getAutoResponderExclusion>(GET_AUTO_RESPONDER_EXCLUSION);

  const [mutate] = useMutation<updateAutoResponderExclusion, updateAutoResponderExclusionVariables>(
    UPDATE_AUTO_RESPONDER_EXCLUSION,
  );

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const model = {
    emails: data?.autoResponderExclusion.emails || [],
  };

  return (
    <div>
      {!loaded ? (
        <LoadingTable />
      ) : (
        <Formik
          initialValues={model}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const result: any = await mutate({
                variables: {
                  payload: {
                    emails: values.emails,
                  },
                },
              });

              if (result.errors) {
                toastErrorQuery(result.errors);
              } else {
                toast.success(i18n._(t`Success!`));
              }
            } catch (e) {
              toastErrorQuery(e);
            }

            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="form-group">
                <label>
                  <Trans>Exclude</Trans>
                </label>

                <Field name="emails">
                  {({ field, form }: { field: any; form: FormikProps<any> }) => {
                    const handleChange = (values: any) => {
                      form.setFieldValue('emails', values ? values.map((el: any) => el.value) : []);
                    };

                    const model = field.value
                      ? field.value.map((el: any) => ({
                          value: el,
                          label: el,
                        }))
                      : [];

                    return (
                      <CreatableSelect
                        value={model}
                        isMulti
                        cacheOptions
                        defaultOptions
                        onChange={handleChange}
                        placeholder=" "
                        {...selectOptions}
                      />
                    );
                  }}
                </Field>
              </div>

              <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default AutoresponderExclusionForm;
