import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent, useState } from 'react';
import ReactTable, { CellInfo } from 'react-table';
import { NavLink } from 'react-router-dom';
import LoadingTable from '../components/common/LoadingTable';
import MainTitle from '../components/common/MainTitle';
import { PERSON_GET_CONTRACTS } from '../schema/contract';
import { OrderDirection } from '../schema/graphql-global-types';
import { personGetContracts, personGetContractsVariables } from '../schema/types/personGetContracts';
import { getCompanyId } from '../utils/auth';
import { monthNames, tableOptions, tablePollingInterval } from '../utils/const';
import ErrorQuery from '../components/form/ErrorQuery';
import { useVariables } from '../utils/hooks';
import { getFilter, getOrder } from '../utils/utils';

const PersonContractPage: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);
  const {
    search$,
    search,
    filter,
    filter$,
    archived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
  } = useVariables();

  const variables = {
    id: getCompanyId(),
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, previousData, loading, error } = useQuery<personGetContracts, personGetContractsVariables>(
    PERSON_GET_CONTRACTS,
    {
      variables,
      pollInterval: tablePollingInterval,
    },
  );

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.company.contracts.items ?? previousData?.company.contracts.items ?? [];
  const pages = data && data.company ? Math.ceil(data.company.contracts.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Contract`),
      accessor: 'name',
      sortable: true,
      Cell: (cell: CellInfo) => <NavLink to={`/service/contract/${cell.original.id}`}>{cell.value}</NavLink>,
    },
    {
      Header: i18n._(t`Cycle`),
      accessor: 'cycle',
      sortable: true,
      filterable: true,
      width: 100,
      className: 'text-center',
      Cell: (cell: CellInfo) => i18n._(cell.value),
      Filter: (params: any) => (
        <select
          className="form-control"
          onChange={(event) => params.onChange(event.target.value ? [event.target.value] : '')}
          value={params.filter ? params.filter.value : ''}
        >
          <option value="">{i18n._(t`All`)}</option>
          <option value="Yearly">{i18n._(t`Yearly`)}</option>
          <option value="Monthly">{i18n._(t`Monthly`)}</option>
        </select>
      ),
    },
    {
      Header: i18n._(t`Cycle Date`),
      accessor: 'yearlyInterval',
      sortable: true,
      width: 160,
      className: 'text-center',
      Cell: (cell: CellInfo) =>
        cell.original.cycle === 'Yearly'
          ? i18n._(t`First of`) + ` ${monthNames()[cell.value]}`
          : i18n._(t`First of Month`),
    },
  ];

  return (
    <div className="contract-page">
      <div className="card-body">
        <div className="table-actions">
          <div className="breadcrumbs-area">
            <MainTitle title={i18n._(t`My Contract`)} />
          </div>

          <div className="item search input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="icon-search" />
              </div>
            </div>
            <input
              className="form-control"
              placeholder={i18n._(t`Search`)}
              onChange={(e) => search$.next(e.target.value)}
            />
          </div>
        </div>

        {!loaded ? (
          <LoadingTable />
        ) : (
          <ReactTable
            data={items}
            columns={columns}
            pages={pages}
            pageSize={limit}
            manual
            onFetchData={(state) => {
              filter$.next(
                getFilter(state, {
                  'customer.name': 'companyIds',
                  cycle: 'cycles',
                }),
              );

              let orderDir = OrderDirection.Desc;
              if (state.sorted[0]) {
                orderDir = state.sorted[0].desc ? OrderDirection.Desc : OrderDirection.Asc;
              }

              setOffset(state.page * state.pageSize);
              setOrderBy(
                getOrder(state, {
                  'customer.name': 'customerName',
                }),
              );
              setOrderDirection(orderDir);
            }}
            onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
            {...tableOptions()}
          />
        )}
      </div>
    </div>
  );
};

export default PersonContractPage;
