// @ts-ignore
import Hotkeys from 'react-hot-keys';
import React, { FunctionComponent, useCallback } from 'react';
import { TicketDetail } from '../../schema/types/TicketDetail';
import TicketMessagePreview from './TicketMessagePreview';

type Props = {
  selected: any;
  selectedTickets?: string[];
  tickets: any[];
  onSelect: (value: any) => void;
  onRemove: (id: string) => void;
  onChecked?: (id: string) => void;
};

const TicketMessagePanel: FunctionComponent<Props> = ({
  selected,
  selectedTickets,
  tickets,
  onSelect,
  onRemove,
  onChecked,
}) => {
  const handleSelect = useCallback(
    (value: any) => () => {
      onSelect(value);
    },
    [onSelect]
  );

  const handleChecked = useCallback(
    (value: any) => () => {
      if (onChecked) onChecked(value);
    },
    [onChecked]
  );

  const handleKey = useCallback(
    (keyName: any, e: any) => {
      if (e.target.nodeName === 'BODY') {
        e.preventDefault();
        const index = tickets.findIndex((el: any) => el.id === selected.id);

        if (keyName === 'up') {
          if (index > 0) {
            onSelect(tickets[index - 1]);
          }
        } else if (keyName === 'down') {
          if (index < tickets.length - 1) {
            onSelect(tickets[index + 1]);
          }
        } else if (keyName === 'backspace' || keyName === 'delete') {
          if (index >= tickets.length - 1) {
            onSelect(tickets[index - 1]);
          } else {
            onSelect(tickets[index + 1]);
          }

          onRemove(tickets[index].id);
        }
      }
    },
    [selected, tickets, onSelect, onRemove]
  );

  return (
    <Hotkeys keyName="up, down, backspace, delete" onKeyDown={handleKey}>
      <div className="form-group ticket-message-items">
        {selected &&
          tickets.map((el: TicketDetail) => (
            <TicketMessagePreview
              key={el.id}
              selected={selected.id === el.id}
              selectedTickets={selectedTickets}
              model={el}
              onSelect={handleSelect(el)}
              onChecked={handleChecked(el.id)}
            />
          ))}
      </div>
    </Hotkeys>
  );
};

export default TicketMessagePanel;
