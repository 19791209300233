import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import ProfileActivateMfaForm from './ProfileActivateMfaForm';
import ProfileEnableMfaForm from './ProfileEnableMfaForm';

type Props = {
  phone: string;
  show: boolean;
  onClose: () => void;
};

const ProfileMfaModal: FunctionComponent<Props> = ({ phone, show, onClose }) => {
  const [url, setUrl] = useState('');

  const handleSuccess = useCallback(() => {
    toast.success(i18n._(t`Success!`));
    onClose();
  }, [onClose]);

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Configure Two Factor Authentication</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            {!url ? (
              <ProfileEnableMfaForm phone={phone} onSuccess={setUrl} onClose={onClose} />
            ) : (
              <ProfileActivateMfaForm qr={url} onSuccess={handleSuccess} onClose={onClose} />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProfileMfaModal;
