import { cloneDeep } from '@apollo/client/utilities';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, FormikProps } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { AssetReminderCycle, BudgetUnit } from '../../schema/graphql-global-types';
import { AssetLog } from '../../schema/types/AssetLog';
import HistoryItem from '../common/HistoryItem';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldCheckbox from '../form/FieldCheckbox';
import AssetDashboard from './AssetDashboard';
import AssetDetailForm from './AssetDetailForm';
import AssetDocumentationForm from './AssetDocumentationForm';
import AssetInfoForm from './AssetInfoForm';
import AssetLogForm from './AssetLogForm';
import AssetMonitoringForm from './AssetMonitoringForm';
import AssetReminderForm from './AssetReminderForm';

type Props = {
  loading?: boolean;
};

const AssetCreateForms: FunctionComponent<Props> = ({ loading }) => {
  return (
    <Form>
      <div className="form-breadcrumbs-area">
        <NavLink to="/service/asset">
          <Trans>Managed Assets</Trans>
        </NavLink>

        <i className="fa fa-angle-left" />

        <Field name="id">
          {({ field }: { field: FieldInputProps<string> }) => {
            if (field.value) return <Trans>Edit asset</Trans>;

            return <Trans>Add new asset</Trans>;
          }}
        </Field>
      </div>

      <div className="table-actions form-table-actions">
        <div className="breadcrumbs-area">
          <Field name="name">
            {({ field }: { field: FieldInputProps<BudgetUnit> }) => {
              return <div className="main-title">{field.value}</div>;
            }}
          </Field>
        </div>

        <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save updates`)} loading={loading} />
      </div>

      <div className="asset-page">
        <div className="card">
          <div className="card-header">
            <Trans>General Information</Trans>
          </div>
          <div className="card-body">
            <AssetDetailForm />
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <Trans>Add Log</Trans>
          </div>

          <div className="card-body">
            <div className="asset-log">
              <div className="row">
                <div className="form-group col-xl-6">
                  <label className="form-label">
                    <Trans>Tags</Trans>
                  </label>
                  <Field type="text" className="form-control" name="manualLog" />
                </div>

                <div className="col-xl-6">
                  <label className="form-label">
                    <Trans>Tags</Trans>
                  </label>

                  <div className="row align-items-center">
                    <div className="form-group col-md-6">
                      <AssetLogForm />
                    </div>

                    <div className="form-group col-md-3">
                      <FieldCheckbox name="manualLogViewableByCustomer" label={i18n._(t`Visible to Customer`)} />
                    </div>

                    <div className="form-group col-md-3">
                      <ButtonSubmit className="btn btn-link" title={i18n._(t`Add Log`)} loading={loading} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Field name="logs.items">
              {({ field }: { field: any }) => {
                if (!field.value) return '';

                return (
                  <div className="card-body">
                    {field.value.map((el: AssetLog) => (
                      <HistoryItem
                        key={el.id}
                        date={el.createdAt}
                        creator={el.creator}
                        content={el.content}
                        tags={el.tags}
                      />
                    ))}
                  </div>
                );
              }}
            </Field>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <Trans>Asset Reminder</Trans>
          </div>
          <div className="card-body">
            <Field name="reminders">
              {({ field, form }: { field: any; form: FormikProps<any> }) => {
                const handleAdd = (model: any) => {
                  const value = cloneDeep(field.value);
                  value.push(model);
                  form.setFieldValue('reminders', value);
                };

                const handleEdit = (model: any, index: number) => {
                  const value = cloneDeep(field.value);
                  value[index] = model;
                  form.setFieldValue('reminders', value);
                };

                const handleRemove = (index: number) => {
                  const value = cloneDeep(field.value);
                  value.splice(index, 1);
                  form.setFieldValue('reminders', value);
                };

                return (
                  <AssetReminderForm
                    items={field.value}
                    defaultCycle={AssetReminderCycle.Yearly}
                    onAdd={handleAdd}
                    onEdit={handleEdit}
                    onRemove={handleRemove}
                  />
                );
              }}
            </Field>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <Trans>Responsibilities</Trans>
          </div>
          <div className="card-body">
            <AssetInfoForm />
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <Trans>Monitoring / SLA</Trans>
          </div>
          <div className="card-body">
            <AssetMonitoringForm />
          </div>
        </div>

        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <Trans>Uptime Statistic</Trans>

            <FieldCheckbox name="displayOnDashboard" label={i18n._(t`Display on ProCloud Dashboard`)} />
          </div>
          <div className="card-body">
            <Field name="id">
              {({ field, form }: { field: any; form: any }) => {
                if (!field.value) return '';

                return <AssetDashboard model={form.values} simple={true} />;
              }}
            </Field>
          </div>
        </div>

        <Field name="id">
          {({ field }: { field: any }) => {
            if (!field.value) return '';

            return (
              <div className="card">
                <div className="card-header">
                  <Trans>Documentation</Trans>
                </div>
                <div className="card-body">
                  <AssetDocumentationForm />
                </div>
              </div>
            );
          }}
        </Field>
      </div>
    </Form>
  );
};

export default AssetCreateForms;
