import DateFormat from '../components/common/DateFormat';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import ReactTable, { CellInfo } from 'react-table';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import MainTitle from '../components/common/MainTitle';
import TableActions from '../components/common/TableActions';
import UserAvatarName from '../components/common/UserAvatarName';
import DocumentationCreateModal from '../components/documentation/DocumentationCreateModal';
import DocumentationCustomerFilter from '../components/documentation/DocumentationCustomerFilter';
import ButtonArchive from '../components/form/ButtonArchive';
import ButtonEdit from '../components/form/ButtonEdit';
import LoadingTable from '../components/common/LoadingTable';
import FilterHeader from '../components/form/FilterHeader';
import { GET_DOCUMENTS, ARCHIVE_DOCUMENT, UNARCHIVE_DOCUMENT } from '../schema/document';
import { GET_DOCUMENT_CATEGORIES } from '../schema/documentCategory';
import { DocumentVisibility, OrderDirection } from '../schema/graphql-global-types';
import { GET_DOCUMENT_TAGS } from '../schema/tag';
import { archiveDocument, archiveDocumentVariables } from '../schema/types/archiveDocument';
import { getDocuments, getDocumentsVariables } from '../schema/types/getDocuments';
import { Tag } from '../schema/types/Tag';
import { unarchiveDocument, unarchiveDocumentVariables } from '../schema/types/unarchiveDocument';
import { GET_USERS_FOR_COMPANY } from '../schema/user';
import { dateTimeOptions, tableOptions, tablePollingInterval } from '../utils/const';
import ErrorQuery from '../components/form/ErrorQuery';
import { useVariables } from '../utils/hooks';
import { cloneDeep } from 'lodash';
import { DataProxy } from '@apollo/client/cache';
import { getFilter, getOrder } from '../utils/utils';

const DocumentationPage: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);
  const {
    search$,
    search,
    filter$,
    filter,
    archived,
    setArchived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
  } = useVariables();

  const [customer, setCustomer] = useState<any>(null);

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
        template: false,
      },
    },
  };

  const updateQuery = (proxy: DataProxy, id: any) => {
    const data: any = cloneDeep(proxy.readQuery({ query: GET_DOCUMENTS, variables }));
    data.documents.items = data.documents.items.filter((el: any) => el.id !== id);
    proxy.writeQuery({ query: GET_DOCUMENTS, variables, data });
  };

  const [mutateArchive] = useMutation<archiveDocument, archiveDocumentVariables>(ARCHIVE_DOCUMENT, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.archiveDocument.document.id);
    },
  });
  const [mutateUnarchive] = useMutation<unarchiveDocument, unarchiveDocumentVariables>(UNARCHIVE_DOCUMENT, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.unarchiveDocument.document.id);
    },
  });

  const handleArchive = useCallback(
    (id: any) => () => {
      if (archived) {
        mutateUnarchive({
          variables: {
            id,
          },
          optimisticResponse: {
            unarchiveDocument: {
              document: {
                id,
                __typename: 'Document',
              },
              __typename: 'ArchiveDocumentResponse',
            },
          },
        });
      } else {
        mutateArchive({
          variables: {
            id,
          },
          optimisticResponse: {
            archiveDocument: {
              document: {
                id,
                __typename: 'Document',
              },
              __typename: 'ArchiveDocumentResponse',
            },
          },
        });
      }
    },
    [archived, mutateArchive, mutateUnarchive],
  );

  const [showAdd, setShowAdd] = useState(false);
  const toggleAdd = useCallback(() => setShowAdd((prevState) => !prevState), [setShowAdd]);

  const { data, previousData, loading, error } = useQuery<getDocuments, getDocumentsVariables>(GET_DOCUMENTS, {
    variables,
    pollInterval: tablePollingInterval,
  });

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.documents.items ?? previousData?.documents.items ?? [];
  const pages = data && data.documents ? Math.ceil(data.documents.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Title`),
      accessor: 'title',
      sortable: true,
      Cell: (cell: CellInfo) => <NavLink to={`/documentation/edit/${cell.original.id}`}>{cell.value}</NavLink>,
    },
    {
      Header: i18n._(t`Viewable`),
      accessor: 'customers',
      filterable: true,
      Cell: (cell: CellInfo) => {
        switch (cell.original.visibility) {
          case DocumentVisibility.Internal:
            return i18n._(t`Internal`);
          case DocumentVisibility.Public:
            return i18n._(t`Public`);
          case DocumentVisibility.AllCustomers:
            return i18n._(t`All Customers`);
          case DocumentVisibility.SelectedCustomers:
            return (
              <div className="w-100">
                {cell.value.map((el: Tag) => (
                  <div key={el.id} className="my-2">
                    <NavLink to={`/crm/company/${el.id}`}>{el.name}</NavLink>
                  </div>
                ))}
              </div>
            );
          default:
            return '';
        }
      },
      Filter: (params: any) => {
        const handleCustomer = (value: any) => {
          setCustomer(value);
          params.onChange(params.filter.value);
        };

        return (
          <>
            <select
              className="form-control"
              onChange={(event) => {
                params.onChange(event.target.value ? [event.target.value] : '');

                if (event.target.value !== 'SelectedCustomers') {
                  setTimeout(() => {
                    setCustomer(null);
                  }, 100);
                }
              }}
              value={params.filter ? params.filter.value : ''}
            >
              <option value="">{i18n._(t`All`)}</option>
              <option value="Internal">{i18n._(t`Internal`)}</option>
              <option value="SelectedCustomers">{i18n._(t`Selected Customers`)}</option>
              <option value="AllCustomers">{i18n._(t`All Customers`)}</option>
              <option value="Public">{i18n._(t`Public`)}</option>
            </select>

            {params.filter && params.filter.value[0] === 'SelectedCustomers' && (
              <DocumentationCustomerFilter value={customer} onChange={handleCustomer} />
            )}
          </>
        );
      },
    },
    {
      Header: i18n._(t`Category`),
      accessor: 'category.name',
      filterable: true,
      Filter: (params: any) => (
        <FilterHeader
          query={GET_DOCUMENT_CATEGORIES}
          objectType="documentCategories"
          nameField="name"
          onChange={params.onChange}
        />
      ),
    },
    {
      Header: i18n._(t`Tags`),
      accessor: 'tags',
      filterable: true,
      Cell: (cell: CellInfo) => (
        <>
          {cell.value.map((el: Tag) => (
            <span key={el.id} className="badge mr-1 mb-1">
              {el.name}
            </span>
          ))}
        </>
      ),
      Filter: (params: any) => (
        <FilterHeader query={GET_DOCUMENT_TAGS} objectType="documentTags" nameField="name" onChange={params.onChange} />
      ),
    },
    {
      Header: i18n._(t`Editor`),
      accessor: 'editor.fullName',
      sortable: true,
      filterable: true,
      Cell: (cell: CellInfo) => (
        <NavLink to={`/settings/user/${cell.original.editor.id}`}>
          <UserAvatarName user={cell.original.editor} />
        </NavLink>
      ),
      Filter: (params: any) => (
        <FilterHeader
          query={GET_USERS_FOR_COMPANY}
          objectType="users"
          nameField="fullName"
          onChange={params.onChange}
        />
      ),
    },
    {
      Header: i18n._(t`Last Edit`),
      accessor: 'updatedAt',
      sortable: true,
      Cell: (cell: CellInfo) => <DateFormat value={new Date(cell.value)} format={dateTimeOptions} />,
    },
    {
      Header: i18n._(t`Actions`),
      width: 110,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <>
          <a
            href={cell.original.publicUrl}
            data-tip
            data-for={`view-${cell.original.id}`}
            className="button text-xs"
            target="_blank"
          >
            <i className="icon-preview" />
          </a>
          <ReactTooltip id={`view-${cell.original.id}`}>
            <Trans>Preview</Trans>
          </ReactTooltip>

          <NavLink to={`/documentation/edit/${cell.original.id}`}>
            <ButtonEdit />
          </NavLink>
          <ButtonArchive onClick={handleArchive(cell.original.id)} />
        </>
      ),
    },
  ];

  return (
    <div className="document-page">
      <div className="card-body">
        <div className="table-actions">
          <NavLink to="/documentation/template" data-tip data-for="toggle-template" className="link-action">
            T
          </NavLink>

          <ReactTooltip id="toggle-template">
            <Trans>Templates</Trans>
          </ReactTooltip>

          <div className="breadcrumbs-area">
            <MainTitle title={i18n._(t`Documentations`)} tips="Documentation/Docu" />
          </div>

          <TableActions onSearch={(e) => search$.next(e.target.value)} archived={archived} onArchive={setArchived} />

          <button type="button" className="btn btn-sm btn-primary" onClick={toggleAdd}>
            <i className="icon-add" />
            <Trans>Add new document</Trans>
          </button>
        </div>

        {!loaded ? (
          <LoadingTable />
        ) : (
          <ReactTable
            data={items}
            columns={columns}
            pages={pages}
            pageSize={limit}
            manual
            onFetchData={(state) => {
              const f = getFilter(state, {
                customers: 'visibility',
                tags: 'tagIds',
                'category.name': 'categoryIds',
                'editor.fullName': 'editorIds',
              });

              if (f.visibility && f.visibility[0] === 'SelectedCustomers' && customer) {
                f.companyIds = [customer!.id];
              }

              filter$.next(f);

              let orderDir = OrderDirection.Desc;
              if (state.sorted[0]) {
                orderDir = state.sorted[0].desc ? OrderDirection.Desc : OrderDirection.Asc;
              }

              setOffset(state.page * state.pageSize);
              setOrderBy(
                getOrder(state, {
                  'editor.fullName': 'editorName',
                }),
              );
              setOrderDirection(orderDir);
            }}
            onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
            {...tableOptions()}
          />
        )}

        <DocumentationCreateModal show={showAdd} onClose={toggleAdd} />
      </div>
    </div>
  );
};

export default DocumentationPage;
