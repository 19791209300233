import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import { endOfWeek, startOfWeek } from 'date-fns';
import React, { FC } from 'react';
import icon from '../../img/fastest-closer.svg';
import { GET_FASTEST_CLOSE_USERS } from '../../schema/stats';
import { fastestCloseUsers, fastestCloseUsersVariables } from '../../schema/types/fastestCloseUsers';
import UserAvatar from '../common/UserAvatar';
import ErrorQuery from '../form/ErrorQuery';

const today = new Date();

type Props = {};

const HomeFastestCloser: FC<Props> = () => {
  const { data, error } = useQuery<fastestCloseUsers, fastestCloseUsersVariables>(GET_FASTEST_CLOSE_USERS, {
    variables: {
      filter: {
        from: startOfWeek(today).toISOString(),
        to: endOfWeek(today).toISOString(),
        minimumTicketCount: 10,
      },
      limit: 1,
    },
    fetchPolicy: 'cache-first',
  });

  if (error) return <ErrorQuery error={error} />;

  return (
    <div className="item item-2">
      <div className="card">
        <div className="icons">
          <img src={icon} alt="" />
        </div>

        <div className="block-title">
          <Trans>This week's Fastest closer</Trans>
        </div>

        {data && data.fastestCloseUsers.length > 0 && (
          <div className="content week">
            <UserAvatar user={data.fastestCloseUsers[0]} />
            <div className="name">{data.fastestCloseUsers[0]?.fullName}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeFastestCloser;
