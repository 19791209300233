import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import copy from 'copy-to-clipboard';
import { Field, FormikProps } from 'formik';
import linkifyHtml from 'linkifyjs/html';
import { cloneDeep } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { client } from '../..';
import { GET_DOCUMENT_TEMPLATE_ELEMENTS } from '../../schema/documentTemplateElement';
import { DocumentElement } from '../../schema/types/DocumentElement';
import {
  getDocumentTemplateElements,
  getDocumentTemplateElementsVariables,
} from '../../schema/types/getDocumentTemplateElements';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';
import { focusTitle, nl2br } from '../../utils/utils';
import { storageObjectDownloadQuery } from '../common/HistoryItem';
import ButtonDelete from '../form/ButtonDelete';
import ButtonIndex from '../form/ButtonIndex';
import FieldCheckbox from '../form/FieldCheckbox';
import FieldEditor from '../form/FieldEditor';
import ImageUploader from '../form/ImageUploader';
import DocumentationAddModules from './DocumentationAddModules';
import DocumentationEditorItem from './DocumentationEditorItem';
import DocumentationFileUploader from './DocumentationFileUploader';
import DocumentChatGPTForm from './DocumentChatGPTForm';

const handleCopy = (value: string) => () => {
  copy(value);
  toast.success(i18n._(t`Copied!`));
};

type Props = {};

const DocumentationEditor: FunctionComponent<Props> = () => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getDocumentTemplateElements, getDocumentTemplateElementsVariables>(
    GET_DOCUMENT_TEMPLATE_ELEMENTS,
    {
      variables,
      fetchPolicy: 'cache-first',
    },
  );

  const handleLoad = useCallback((value: string) => search$.next(value), [search$]);

  const options = loading
    ? []
    : data!.documentTemplateElements.items.map((el: any) => ({
        value: el,
        label: el.title,
      }));

  return (
    <div className="border-right">
      <div className="container">
        <div className="form-group header-graphic elements">
          <Field name="headGraphic.url">
            {({ field, form }: { field: any; form: FormikProps<any> }) => (
              <ImageUploader
                onUpload={(id) => form.setFieldValue('headGraphicId', id)}
                image={field.value}
                placeholder="900x300px"
              />
            )}
          </Field>
        </div>

        <Field name="elements">
          {({ field, form }: { field: any; form: FormikProps<any> }) => {
            const handleRemove = (index: number) => () => {
              const values = cloneDeep(field.value);
              values.splice(index, 1);
              form.setFieldValue('elements', values);
            };

            const handleChange = (index: number) => (value: any) => {
              const values = cloneDeep(field.value);
              values[index] = {
                ...values[index],
                templateElementId: value.value.id,
                templateElement: {
                  id: value.value.id,
                  content: value.value.content,
                },
              };

              form.setFieldValue('elements', values);
              focusTitle(index);
            };

            const getIndex = (el: DocumentElement, index: number) => {
              if (!el.index) return '';

              let count = 0;

              for (let i = 0; i < field.value.length; i++) {
                if (field.value[i].index) {
                  count++;
                }

                if (i === index) {
                  break;
                }
              }

              return count ? `${count}.` : '';
            };

            return (
              <>
                {field.value.length === 0 && (
                  <div className="document-add-actions">
                    <DocumentationAddModules index={0} />

                    <div className="ml-auto">
                      <FieldCheckbox name="index" label={i18n._(t`Show Index`)} />
                    </div>
                  </div>
                )}

                {field.value.map((el: any, index: number) => (
                  <div key={index}>
                    {index === 0 && (
                      <div className="document-add-actions">
                        <DocumentationAddModules index={index} />

                        <div className="ml-auto">
                          <FieldCheckbox name="index" label={i18n._(t`Show Index`)} />
                        </div>
                      </div>
                    )}

                    <div className="elements card">
                      {el.templateElementId === null ? (
                        <div className="d-flex align-items-center">
                          <div className="mr-2" style={{ width: '25px' }}></div>

                          <div className="form-label">
                            {el.item?.type ? i18n._(el.item.type) : ''} <Trans>Title</Trans>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex align-items-center">
                          <div className="mr-2" style={{ width: '25px' }}></div>

                          <div className="row w-100 document-element-heading">
                            <div className="form-label col-md-7">
                              <Trans>Title</Trans>
                            </div>

                            <div className="form-label col-md-5 hidden-phone">
                              <Trans>Apply centralized content</Trans>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="form-group d-flex align-items-center">
                        <div className="mr-2" style={{ width: '25px' }}>
                          {getIndex(el, index)}
                        </div>

                        {el.templateElementId === null ? (
                          <Field
                            type="text"
                            className="form-control editor-item-title"
                            name={`elements[${index}].title`}
                            placeholder={i18n._(t`Title`)}
                          />
                        ) : (
                          <div className="row w-100">
                            <div className="col-md-7">
                              <Field
                                type="text"
                                className="form-control editor-item-title"
                                name={`elements[${index}].title`}
                                placeholder={i18n._(t`Title`)}
                              />
                            </div>

                            <div className="col-md-5 mt-2 mt-md-0">
                              <Select
                                options={options}
                                isLoading={loading}
                                onChange={handleChange(index)}
                                onInputChange={handleLoad}
                                placeholder={i18n._(t`Select...`)}
                                {...selectOptions}
                              />
                            </div>
                          </div>
                        )}

                        <Field name={`elements[${index}].index`}>
                          {({ field, form }: { field: any; form: FormikProps<any> }) => {
                            const handleClick = () => {
                              form.setFieldValue(`elements[${index}].index`, !field.value);
                            };

                            return (
                              <div className="ml-4">
                                <ButtonIndex active={field.value} onClick={handleClick} />
                              </div>
                            );
                          }}
                        </Field>
                        <div className="ml-4">
                          <ButtonDelete onClick={handleRemove(index)} />
                        </div>
                      </div>

                      <div className="form-group">
                        {el.item ? (
                          <DocumentationEditorItem title={el.title} index={index} model={el.item} />
                        ) : el.templateElementId === null ? (
                          <>
                            <FieldEditor name={`elements[${index}].content`} />
                            <DocumentChatGPTForm
                              title={el.title}
                              onChange={(val) => form.setFieldValue(`elements[${index}].content`, val)}
                            />
                          </>
                        ) : (
                          <DocumentTemplateContent el={el} />
                        )}
                      </div>
                    </div>

                    <div className="document-add-actions">
                      <DocumentationAddModules index={index + 1} />

                      <div className="ml-auto">
                        <FieldCheckbox name="index" label={i18n._(t`Show Index`)} />
                      </div>
                    </div>
                  </div>
                ))}

                <div className="card">
                  <div className="form-group">
                    <label>
                      <Trans>Attach files</Trans>
                    </label>
                  </div>

                  <Field name="attachments">
                    {({ field, form }: { field: any; form: FormikProps<any> }) => {
                      const handleUpload = (value: any) => {
                        const photoIds = form.values.attachmentIds;
                        photoIds.push(value.storageObject.id);
                        form.setFieldValue('attachmentIds', photoIds);

                        const photos = form.values.attachments;
                        photos.push(value.storageObject);
                        form.setFieldValue('attachments', photos);

                        const attachments_v2 = form.values.attachments_v2;
                        attachments_v2.push({
                          permanentLink: value.permanentLink,
                        });
                        form.setFieldValue('attachments_v2', attachments_v2);
                      };

                      const handleRemove = (index: number) => {
                        const photoIds = form.values.attachmentIds;
                        photoIds.splice(index, 1);
                        form.setFieldValue('attachmentIds', photoIds);

                        const photos = form.values.attachments;
                        photos.splice(index, 1);
                        form.setFieldValue('attachments', photos);

                        const attachments_v2 = form.values.attachments_v2;
                        attachments_v2.splice(index, 1);
                        form.setFieldValue('attachments_v2', attachments_v2);
                      };

                      const onCopy = (index: number) => {
                        try {
                          handleCopy(form.values.attachments_v2[index].permanentLink)();
                        } catch (e) {
                          toast.error('Cannot copy');
                        }
                      };

                      return (
                        <DocumentationFileUploader
                          documentId={form.values.id}
                          onUpload={handleUpload}
                          onRemove={handleRemove}
                          files={field.value}
                          onCopy={onCopy}
                        />
                      );
                    }}
                  </Field>
                </div>
              </>
            );
          }}
        </Field>
      </div>
    </div>
  );
};

export const DocumentTemplateContent: React.FC<{ el: any }> = ({ el }) => {
  const [html, setHtml] = useState('');
  const content = useMemo<string>(() => (el.templateElement ? el.templateElement.content : ''), [el]);
  useEffect(() => {
    // initial state
    setHtml(linkifyHtml(nl2br(content)));
    const htmlTree = document.createElement('html');
    htmlTree.innerHTML = content;
    const matches = htmlTree.getElementsByTagName('img');

    for (let i = 0; i < matches.length; i++) {
      const match = matches.item(i);
      if (match?.src?.startsWith('cid')) {
        client
          .query({ query: storageObjectDownloadQuery, variables: { storageId: match.src.slice(4) } })
          .then((response) => {
            const imageUrl = response.data?.storageObject?.url;
            match.src = imageUrl;
            try {
              // Fix linkify bug https://github.com/Soapbox/linkifyjs/issues/301
              setHtml(linkifyHtml(nl2br(htmlTree.innerHTML || '')));
            } catch (e) {
              setHtml(linkifyHtml(nl2br(content)));
            }
          });
      }
    }
  }, [content]);

  return (
    <div
      className="post-content"
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
};

export default DocumentationEditor;
