import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { CREATE_TICKET, validateTicketForm } from '../../schema/ticket';
import { TicketDetail } from '../../schema/types/TicketDetail';
import { toastErrorQuery } from '../../utils/error';
import { validateRequired } from '../../utils/validations';
import ButtonSubmit from '../form/ButtonSubmit';
import TicketDocTem from './TicketDocTem';
import TicketMainForm from './TicketMainForm';
import { omit } from 'lodash';

type Props = {
  show: boolean;
  onSuccess: (item: TicketDetail) => void;
  onClose: () => void;
};

const TicketAddModal: FunctionComponent<Props> = ({ show, onSuccess, onClose }) => {
  const [mutate] = useMutation(CREATE_TICKET);

  const model: any = {
    to: [],
    cc: [],
    subject: '',
    content: '',
    attachmentIds: [],
    attachments: [],
  };

  return (
    <Modal isOpen={show} className="Modal modal-open ticket-modal" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Add new ticket</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              initialValues={model}
              validationSchema={yup.object().shape({
                ...validateTicketForm,
                content: validateRequired,
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  const result: any = await mutate({
                    variables: {
                      payload: omit(values, ['attachments']),
                    },
                  });

                  if (result.errors) {
                    toastErrorQuery(result.errors);
                  } else {
                    resetForm();
                    toast.success(i18n._(t`Success!`));
                    onSuccess(result.data.createTicket.ticket);
                  }
                } catch (e) {
                  toastErrorQuery(e);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting, submitForm }) => {
                const handleSubmit = (e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  submitForm();
                };

                return (
                  <Form onSubmitCapture={handleSubmit}>
                    <div className="modal-body">
                      <TicketDocTem addSubject />

                      <TicketMainForm />
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={onClose}>
                        <Trans>Cancel</Trans>
                      </button>

                      <ButtonSubmit className="btn btn-primary" title={i18n._(t`Send`)} loading={isSubmitting} />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TicketAddModal;
