import DatePicker from 'react-datepicker';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { useLanguage } from '../../schema/locale';

type Props = {
  name: string;
  onChange?: (date: string) => void;
};

const ContractAdjustmentDatepicker: FunctionComponent<Props> = ({
  name,
  onChange,
}) => {
  const language = useLanguage();

  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (now: Date) => {
          form.setFieldValue(name, now.toISOString());
          const next = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1));
          if (onChange) onChange(next.toISOString());
        };

        const model = new Date(field.value);

        return (
          <div className="input-group">
            <div className="form-control">
              <DatePicker
                selected={new Date(model.getFullYear(), model.getMonth(), 2)}
                onChange={handleChange}
                className="datepicker-control"
                locale={language}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </div>
            <div className="input-group-append">
              <div className="input-group-text">
                <i className="fas fa-calendar" />
              </div>
            </div>
          </div>
        );
      }}
    </Field>
  );
};

export default ContractAdjustmentDatepicker;
