import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import Modal from 'react-modal';
import CreatableSelect from 'react-select/creatable';
import * as yup from 'yup';
import { selectOptions } from '../../utils/const';
import { validateArrayRequired, validateEmail } from '../../utils/validations';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';

type Props = {
  show: boolean;
  multiple?: boolean;
  presets?: string[];
  loading: boolean;
  onSubmit: (email: any) => void;
  onClose: () => void;
};

const ReportMailModal: FunctionComponent<Props> = ({ show, multiple, presets = [], loading, onSubmit, onClose }) => {
  const model: any = multiple
    ? {
        email: presets || [],
      }
    : {
        email: '',
      };

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Send Email</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              initialValues={model}
              validationSchema={yup.object().shape({
                email: multiple ? validateArrayRequired : validateEmail,
              })}
              onSubmit={(values, { setSubmitting }) => {
                onSubmit(values.email);
                setSubmitting(false);
              }}
            >
              {({ submitForm }) => {
                const handleSubmit = (e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  submitForm();
                };

                return (
                  <Form onSubmitCapture={handleSubmit}>
                    <div className="modal-body">
                      <div className="form-group">
                        <label>
                          <Trans>Email</Trans>
                        </label>

                        {multiple ? (
                          <Field name="email">
                            {({ field, form }: { field: any; form: FormikProps<any> }) => {
                              const handleChange = (values: any) => {
                                form.setFieldValue('email', values ? values.map((el: any) => el.value) : []);
                              };

                              const model = field.value
                                ? field.value.map((el: any) => ({
                                    value: el,
                                    label: el,
                                  }))
                                : [];

                              const options = presets.map((el) => ({
                                value: el,
                                label: el,
                              }));

                              return (
                                <CreatableSelect
                                  value={model}
                                  options={options}
                                  isMulti
                                  cacheOptions
                                  defaultOptions
                                  onChange={handleChange}
                                  placeholder=" "
                                  {...selectOptions}
                                />
                              );
                            }}
                          </Field>
                        ) : (
                          <Field type="text" className="form-control" name="email" />
                        )}

                        <FieldErrorMessage name="email" />
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={onClose}>
                        <Trans>Close</Trans>
                      </button>

                      <ButtonSubmit className="btn btn-primary" title={i18n._(t`Send Email`)} loading={loading} />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReportMailModal;
