import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { GET_BEXIO_ACCOUNTS, GET_BEXIO_CONFIG } from '../../schema/config';
import { ProductBilling } from '../../schema/graphql-global-types';
import { getBexioAccounts } from '../../schema/types/getBexioAccounts';
import { getBexioConfig } from '../../schema/types/getBexioConfig';
import FieldCheckbox from '../form/FieldCheckbox';
import FieldEditor from '../form/FieldEditor';
import FieldErrorMessage from '../form/FieldErrorMessage';
import FieldRadio from '../form/FieldRadio';
import FieldTime from '../form/FieldTime';
import MultipleImageUploader from '../form/MultipleImageUploader';
import ProductTagForm from './ProductTagForm';

type Props = {};

const ProductDetailForm: FunctionComponent<Props> = () => {
  const { data } = useQuery<getBexioConfig>(GET_BEXIO_CONFIG);
  const { data: accounts } = useQuery<getBexioAccounts>(GET_BEXIO_ACCOUNTS);

  return (
    <div className="product-detail-form">
      <div className="row">
        <div className="col-xl-6">
          <div className="form-group">
            <label>
              <Trans>Title</Trans>
            </label>

            <Field type="text" className="form-control" name="title" autoFocus />

            <FieldErrorMessage name="title" />
          </div>

          <div className="form-group">
            <label>
              <Trans>Youtube URL</Trans>
            </label>

            <Field type="url" className="form-control" name="youtubeUrl" />
          </div>

          <div className="form-group">
            <label>
              <Trans>Distributor Article Number</Trans>
            </label>

            <Field type="text" className="form-control" name="tdArticle" />
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <Trans>Price</Trans>
                </label>

                <Field type="text" className="form-control" name="price" />

                <FieldErrorMessage name="price" />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <Trans>Response Time</Trans>
                </label>

                <FieldTime name="reactionTime" />
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="form-group mb-4">
            <label>
              <Trans>Billing</Trans>
            </label>

            <div className="d-flex col-form-label">
              <div className="mr-4">
                <FieldRadio name="billing" label={i18n._(t`One Time`)} value={ProductBilling.OneTime} />
              </div>
              <div className="mr-4">
                <FieldRadio name="billing" label={i18n._(t`Monthly`)} value={ProductBilling.Monthly} />
              </div>
              <div>
                <FieldRadio name="billing" label={i18n._(t`Yearly`)} value={ProductBilling.Yearly} />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>
              <Trans>Tags</Trans>
            </label>

            <ProductTagForm />
          </div>

          {data && data.bexioConfig && data.bexioConfig.apiKey.length > 0 && (
            <div className="form-group">
              <label>
                <Trans>Bexio Account</Trans>
              </label>

              {accounts && accounts.bexioAccounts.length > 0 && (
                <Field component="select" className="form-control" name="bexioAccountId">
                  <option value={''}>{i18n._(t`Default`)}</option>
                  {accounts.bexioAccounts.map((el) => (
                    <option key={el.id} value={el.id}>
                      {el.name}
                    </option>
                  ))}
                </Field>
              )}
            </div>
          )}

          <div className="form-group">
            <label>
              <Trans>Time Tracker</Trans>
            </label>

            <FieldCheckbox name="timeTrackerEnabled" label={i18n._(t`Active`)} />
          </div>
        </div>
      </div>

      <div className="form-group">
        <label>
          <Trans>Description</Trans>
        </label>

        <FieldEditor name="content" />
      </div>

      <div className="form-group">
        <label>
          <Trans>Upload Pictures</Trans>
        </label>

        <Field name="photos">
          {({ field, form }: { field: any; form: FormikProps<any> }) => {
            const handleUpload = (value: any) => {
              const photoIds = form.values.photoIds || [];
              photoIds.push(value.id);
              form.setFieldValue('photoIds', photoIds);

              const photos = form.values.photos;
              photos.push(value);
              form.setFieldValue('photos', photos);
            };

            const handleRemove = (index: number) => {
              const photoIds = form.values.photoIds || [];
              photoIds.splice(index, 1);
              form.setFieldValue('photoIds', photoIds);

              if (form.values.photos && form.values.photos.length >= index) {
                const photos = form.values.photos;
                photos.splice(index, 1);
                form.setFieldValue('photos', photos);
              }
            };

            return <MultipleImageUploader onUpload={handleUpload} onRemove={handleRemove} images={field.value} />;
          }}
        </Field>
      </div>
    </div>
  );
};

export default ProductDetailForm;
