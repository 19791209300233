import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import ButtonSubmit from '../form/ButtonSubmit';
import ProductDetailForm from './ProductDetailForm';

type Props = {
  loading?: boolean;
};

const ProductCreateForms: FunctionComponent<Props> = ({ loading }) => {
  return (
    <Form>
      <div className="form-breadcrumbs-area">
        <NavLink to="/settings/product">
          <Trans>Products</Trans>
        </NavLink>

        <i className="fa fa-angle-left" />

        <Field name="id">
          {({ field }: { field: FieldInputProps<string> }) => {
            if (field.value) return <Trans>Edit product</Trans>;

            return <Trans>Add new product</Trans>;
          }}
        </Field>
      </div>

      <div className="table-actions form-table-actions">
        <div className="breadcrumbs-area">
          <Field name="title">
            {({ field }: { field: FieldInputProps<string> }) => {
              return <div className="main-title">{field.value}</div>;
            }}
          </Field>
        </div>

        <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save updates`)} loading={loading} />
      </div>

      <div className="product-form-page">
        <div className="card">
          <div className="card-header">
            <Trans>Customer View</Trans>
          </div>
          <div className="card-body">
            <ProductDetailForm />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default ProductCreateForms;
