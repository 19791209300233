import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import HistoryItem from '../components/common/HistoryItem';
import ErrorQuery from '../components/form/ErrorQuery';
import LoadingForm from '../components/common/LoadingForm';
import PersonalTicketInternalUpdateForms from '../components/personalTicket/PersonalTicketInternalUpdateForms';
import { PERSON_GET_TICKET, UPDATE_INTERNAL_TICKET } from '../schema/ticket';
import { Log } from '../schema/types/Log';
import { personGetTicket, personGetTicketVariables } from '../schema/types/personGetTicket';
import { updateInternalTicket, updateInternalTicketVariables } from '../schema/types/updateInternalTicket';
import { getCompanyId } from '../utils/auth';
import { DefaultRouteParams, getTicketStatus } from '../utils/const';
import { toastErrorQuery } from '../utils/error';
import { validateRequired } from '../utils/validations';
import NotFoundPage from './NotFoundPage';
import { pick } from 'lodash';

const PersonTicketUpdatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ match }) => {
  const [mutate] = useMutation<updateInternalTicket, updateInternalTicketVariables>(UPDATE_INTERNAL_TICKET);

  const { data, loading, error } = useQuery<personGetTicket, personGetTicketVariables>(PERSON_GET_TICKET, {
    variables: {
      id: getCompanyId(),
      query: match.params.id,
    },
  });

  if (!data && loading) return <LoadingForm />;
  if (error) return <ErrorQuery error={error} />;
  if (!data) return <NotFoundPage />;

  return (
    <div className="user-form-page">
      <div className="form-breadcrumbs-area">
        <NavLink to="/support/ticket/procloud">
          <Trans>Your Tickets</Trans>
        </NavLink>

        <i className="fa fa-angle-left" />

        {match.params.id ? <Trans>Update ticket</Trans> : <Trans>Add new ticket</Trans>}

        <span
          className={classNames(
            'ml-2 status badge',
            data.company.ticket.status,
            data.company.ticket.inProgressSubStatus,
          )}
        >
          {getTicketStatus(data.company.ticket.inProgressSubStatus || data.company.ticket.status)}
        </span>
      </div>

      <Formik
        initialValues={{
          content: '',
          attachmentIds: [],
          attachments: [],
        }}
        validationSchema={yup.object().shape({
          content: validateRequired,
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const result: any = await mutate({
              variables: {
                id: match.params.id,
                payload: pick(values, ['content', 'attachmentIds']),
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              resetForm();
              toast.success(i18n._(t`Success!`));
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => <PersonalTicketInternalUpdateForms loading={isSubmitting} />}
      </Formik>

      <hr />

      <div className="card">
        {data.company.ticket.logs.items.map((el: Log) => (
          <HistoryItem key={el.id} date={el.createdAt} creator={el.creator || el.personCreator} content={el.content} />
        ))}
      </div>
    </div>
  );
};

export default withRouter(PersonTicketUpdatePage);
