import React, { FunctionComponent } from 'react';

type Props = {
  wrapper?: boolean;
};

const LoadingQuery: FunctionComponent<Props> = ({ wrapper }) => {
  const content = (
    <div className="ph-item">
      <div className="ph-col-12">
        <div className="ph-picture" />
        <div className="ph-row">
          <div className="ph-col-12 big" />
          <div className="ph-col-12 big" />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {wrapper ? (
        <div className="card">
          <div className="card-body">{content}</div>
        </div>
      ) : (
        content
      )}
    </>
  );
};

export default LoadingQuery;
