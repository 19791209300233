import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { Formik } from 'formik';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router';
import * as yup from 'yup';
import LoadingForm from '../components/common/LoadingForm';
import DocumentationCreateForm from '../components/documentation/DocumentationCreateForm';
import ErrorQuery from '../components/form/ErrorQuery';
import { extractDocumentData, GET_DOCUMENT, UPDATE_DOCUMENT, validateDocumentForm } from '../schema/document';
import { getDocument, getDocumentVariables } from '../schema/types/getDocument';
import { updateDocument, updateDocumentVariables } from '../schema/types/updateDocument';
import { autoSaveInterval, DefaultRouteParams } from '../utils/const';
import { toastErrorQuery } from '../utils/error';
import NotFoundPage from './NotFoundPage';

const DocumentationUpdatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ match }) => {
  const [saved, setSaved] = useState(false);
  const [prevModel, setPrevModel] = useState<any>(null);
  const saveInterval = useRef<any>();

  useEffect(() => {
    return () => {
      if (saveInterval.current) {
        clearInterval(saveInterval.current);
      }
    };
  }, [saveInterval]);

  const [mutate] = useMutation<updateDocument, updateDocumentVariables>(UPDATE_DOCUMENT);

  const { data, loading, error } = useQuery<getDocument, getDocumentVariables>(GET_DOCUMENT, {
    variables: { query: match.params.id },
  });

  if (!data && loading) return <LoadingForm />;
  if (error) return <ErrorQuery error={error} />;
  if (!data) return <NotFoundPage />;

  if (!prevModel) {
    setPrevModel(extractDocumentData(data!.document));
  }

  return (
    <div className="document-form-page">
      <Formik
        initialValues={data!.document}
        validationSchema={yup.object().shape(validateDocumentForm)}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const payload = extractDocumentData(values);

            const result: any = await mutate({
              variables: {
                id: match.params.id,
                payload,
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              setSaved(true);
              setPrevModel(payload);

              setTimeout(() => {
                setSaved(false);
              }, 2000);
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting, submitForm, values }) => {
          if (saveInterval.current) {
            clearInterval(saveInterval.current);
          }

          saveInterval.current = setInterval(() => {
            if (!isEqual(extractDocumentData(values), prevModel)) {
              submitForm();
            }
          }, autoSaveInterval);

          return <DocumentationCreateForm loading={isSubmitting} />;
        }}
      </Formik>

      <div className={classNames('saved', { active: saved })}>
        <Trans>Saved!</Trans>
      </div>
    </div>
  );
};

export default withRouter(DocumentationUpdatePage);
