import gql from 'graphql-tag';
import { validateEmail, validateRequired } from '../utils/validations';
import { LOGS } from './log';
import { UserDetail } from './types/UserDetail';

export const USER = gql`
  fragment User on User {
    id
    fullName
    cellphone
    companyPhone
    email
    role
    function
    avatar {
      id
      url
      imageInstance(profile: "thumbnail") {
        object {
          id
          url
        }
      }
    }
  }
`;

export const USERS = gql`
  fragment Users on UserCollection {
    limit
    offset
    total
    items {
      ...User
    }
  }
  ${USER}
`;

export const GET_USERS = gql`
  query getUsers($query: UsersQuery!) {
    users(query: $query) {
      ...Users
    }
  }
  ${USERS}
`;

export const GET_USERS_FOR_COMPANY = gql`
  query getCompanyUsers($query: UsersQuery!) {
    users(query: $query) {
      limit
      offset
      total
      items {
        id
        fullName
        function
        avatar {
          id
          url
          imageInstance(profile: "thumbnail") {
            object {
              id
              url
            }
          }
        }
      }
    }
  }
`;

export const USER_DETAIL = gql`
  fragment UserDetail on User {
    id
    firstName
    lastName
    function
    email
    companyPhone
    cellphone
    address1
    address2
    zip
    city
    ticketSignature
    avatar {
      id
      url
    }
    role
    mfaEnabled
    supportDisabled
    timeTrackerDefaultProduct {
      id
      title
    }
    password @client
    timeTrackerDefaultProductId @client
  }
`;

export const ExtendUser = {
  password: () => '',
  timeTrackerDefaultProductId: (obj: UserDetail) => obj.timeTrackerDefaultProduct?.id || null,
};

export const GET_USER = gql`
  query getUser($query: ID!) {
    user(id: $query) {
      ...UserDetail
    }
  }
  ${USER_DETAIL}
`;

export const GET_USER_LOGS = gql`
  query getUserLogs($id: ID!, $query: LogsQuery!) {
    user(id: $id) {
      id
      logs(query: $query) {
        ...Logs
      }
    }
  }
  ${LOGS}
`;

export const CREATE_USER = gql`
  mutation createUser($payload: CreateUserPayload!) {
    createUser(payload: $payload) {
      user {
        ...UserDetail
      }
    }
  }
  ${USER_DETAIL}
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $payload: UpdateUserPayload!) {
    updateUser(id: $id, payload: $payload) {
      user {
        ...UserDetail
      }
    }
  }
  ${USER_DETAIL}
`;

export const ARCHIVE_USER = gql`
  mutation archiveUser($id: ID!) {
    archiveUser(id: $id) {
      user {
        id
      }
    }
  }
`;

export const UNARCHIVE_USER = gql`
  mutation unarchiveUser($id: ID!) {
    unarchiveUser(id: $id) {
      user {
        id
      }
    }
  }
`;

export const SEND_USER_CREDENTIALS = gql`
  mutation sendUserCredentials($id: ID!) {
    sendUserCredentials(id: $id)
  }
`;

export const PERSON_SEND_USER_CREDENTIALS = gql`
  mutation sendPersonCredentials($id: ID!) {
    sendPersonCredentials(id: $id)
  }
`;

export const RESET_USER_PASSWORD = gql`
  mutation resetUserPassword($payload: ResetPasswordPayload!) {
    resetUserPassword(payload: $payload)
  }
`;

export const DISABLE_USER_MFA = gql`
  mutation disableUserMfa($id: ID!) {
    disableUserMfa(id: $id) {
      user {
        id
        mfaEnabled
      }
    }
  }
`;

export const userKeys = [
  'role',
  'status',
  'firstName',
  'lastName',
  'gender',
  'function',
  'email',
  'password',
  'companyPhone',
  'cellphone',
  'address1',
  'address2',
  'zip',
  'city',
  'ticketSignature',
  'avatarId',
  'password',
  'supportDisabled',
  'timeTrackerDefaultProductId',
];

export const validateUserForm = {
  firstName: validateRequired,
  lastName: validateRequired,
  email: validateEmail,
};
