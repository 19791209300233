import { useQuery } from '@apollo/client';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import CreatableSelect from 'react-select/creatable';
import { GET_PERSONS_FOR_OFFER } from '../../schema/person';
import { getPersons, getPersonsVariables } from '../../schema/types/getPersons';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {
  name: string;
  isMulti?: boolean;
  disabled?: boolean;
};

const TicketPersonForm: FunctionComponent<Props> = ({ name, isMulti = true, disabled }) => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getPersons, getPersonsVariables>(GET_PERSONS_FOR_OFFER, {
    variables,
    fetchPolicy: 'cache-first',
  });

  if (!data && !loading) return <div />;

  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (values: any) => {
          form.setFieldValue(
            name,
            values ? (isMulti ? values.map((el: any) => el.value) : values.value) : isMulti ? [] : '',
          );
        };

        const handleLoad = (value: string) => search$.next(value);

        const model = field.value
          ? isMulti
            ? field.value.map((el: any) => ({
                value: el,
                label: el,
              }))
            : {
                value: field.value,
                label: field.value,
              }
          : isMulti
          ? []
          : '';

        const options = loading
          ? []
          : data!.persons.items.map((el) => ({
              value: el.email,
              label: el.email,
            }));

        return (
          <CreatableSelect
            value={model}
            options={options}
            isLoading={loading}
            isMulti={isMulti}
            cacheOptions
            defaultOptions
            onChange={handleChange}
            onInputChange={handleLoad}
            placeholder=" "
            isDisabled={disabled}
            {...selectOptions}
          />
        );
      }}
    </Field>
  );
};

export default TicketPersonForm;
