import gql from 'graphql-tag';
import { validateRequired } from '../utils/validations';

export const MESSAGE = gql`
  fragment Message on Message {
    id
    content
    categories {
      id
      name
    }
    createdAt
    allCustomers
    creator {
      id
      fullName
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
    products {
      id
      title
    }
    companies {
      id
      name
    }
  }
`;

export const MESSAGES = gql`
  fragment Messages on MessageCollection {
    limit
    offset
    total
    items {
      ...Message
    }
  }
  ${MESSAGE}
`;

export const GET_MESSAGES = gql`
  query getMessages($query: MessagesQuery!) {
    messages(query: $query) {
      ...Messages
    }
  }
  ${MESSAGES}
`;

export const PERSON_GET_MESSAGES = gql`
  query personGetMessages($id: ID!, $query: MessagesQuery!) {
    company(id: $id) {
      id
      messages(query: $query) {
        ...Messages
      }
    }
  }
  ${MESSAGES}
`;

export const MESSAGE_DETAIL = gql`
  fragment MessageDetail on Message {
    ...Message
  }
  ${MESSAGE}
`;

export const GET_MESSAGE = gql`
  query getMessage($query: ID!) {
    message(id: $query) {
      ...MessageDetail
    }
  }
  ${MESSAGE_DETAIL}
`;

export const SEND_MESSAGE = gql`
  mutation sendMessage($payload: SendMessagePayload!) {
    sendMessage(payload: $payload) {
      message {
        ...MessageDetail
      }
    }
  }
  ${MESSAGE_DETAIL}
`;

export const ARCHIVE_MESSAGE = gql`
  mutation archiveMessage($id: ID!) {
    archiveMessage(id: $id) {
      message {
        id
      }
    }
  }
`;

export const UNARCHIVE_MESSAGE = gql`
  mutation unarchiveMessage($id: ID!) {
    unarchiveMessage(id: $id) {
      message {
        id
      }
    }
  }
`;

export const validateMessageForm = {
  content: validateRequired,
};
