import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_MESSAGE_SUBSCRIPTION_CONFIG, UPDATE_MESSAGE_SUBSCRIPTION_CONFIG } from '../../schema/config';
import { getMessageSubscriptionConfig } from '../../schema/types/getMessageSubscriptionConfig';
import {
  updateMessageSubscriptionConfig,
  updateMessageSubscriptionConfigVariables,
} from '../../schema/types/updateMessageSubscriptionConfig';
import { toastErrorQuery } from '../../utils/error';
import LoadingQuery from '../common/LoadingQuery';
import ButtonSubmit from '../form/ButtonSubmit';
import { cloneDeep, pick } from 'lodash';
import FieldCheckbox from '../form/FieldCheckbox';

type Props = {};

const ProfileSubscriptionForm: FunctionComponent<Props> = () => {
  const { data, loading } = useQuery<getMessageSubscriptionConfig>(GET_MESSAGE_SUBSCRIPTION_CONFIG);

  const [mutate] = useMutation<updateMessageSubscriptionConfig, updateMessageSubscriptionConfigVariables>(
    UPDATE_MESSAGE_SUBSCRIPTION_CONFIG,
  );

  if (loading) return <LoadingQuery wrapper />;

  if (!data) return <div />;

  const model: any = {
    entries: data.messageSubscriptionConfig.entries.map((el) => ({
      name: el.category.name,
      categoryId: el.category.id,
      email: el.email,
      push: el.push,
    })),
  };

  return (
    <Formik
      enableReinitialize
      initialValues={model}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const payload = cloneDeep(values);
          payload.entries = payload.entries.map((el: any) => pick(el, ['categoryId', 'email', 'push']));

          const result: any = await mutate({
            variables: {
              payload,
            },
          });

          if (result.errors) {
            toastErrorQuery(result.errors);
          } else {
            toast.success(i18n._(t`Success!`));
          }
        } catch (e) {
          toastErrorQuery(e);
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <div className="card">
              <div className="card-header">
                <Trans>Subscriptions</Trans>
              </div>

              <div className="card-body">
                <div className="row">
                  {model.entries.map((el: any, index: number) => (
                    <div className="mb-4 col-sm-6 col-lg-4 col-xl-3" key={index}>
                      <label className="mb-3">{el.name}</label>

                      <div className="form-group">
                        <FieldCheckbox name={`entries[${index}].email`} label={i18n._(t`Email`)} />
                      </div>

                      <div className="form-group">
                        <FieldCheckbox name={`entries[${index}].push`} label={i18n._(t`Push`)} />
                      </div>
                    </div>
                  ))}
                </div>

                <div className="action-submit">
                  <ButtonSubmit
                    className="btn btn-primary"
                    title={i18n._(t`Save Subscriptions`)}
                    loading={isSubmitting}
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProfileSubscriptionForm;
