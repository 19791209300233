import { useMutation } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import { pick } from 'lodash';
import React, { FC } from 'react';
import * as yup from 'yup';
import { helpDocumentKeys, UPDATE_HELP_DOCUMENT, validateHelpDocumentForm } from '../../schema/helpDocument';
import { HelpDocument } from '../../schema/types/HelpDocument';
import { updateHelpDocument, updateHelpDocumentVariables } from '../../schema/types/updateHelpDocument';
import { toastErrorQuery } from '../../utils/error';

type Props = {
  model: HelpDocument;
};

const HelpDocumentForm: FC<Props> = ({ model }) => {
  const [mutate] = useMutation<updateHelpDocument, updateHelpDocumentVariables>(UPDATE_HELP_DOCUMENT);

  return (
    <Formik
      enableReinitialize
      initialValues={model}
      validationSchema={yup.object().shape(validateHelpDocumentForm)}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const payload: any = pick(values, helpDocumentKeys);

          await mutate({
            variables: {
              id: model.id,
              payload,
            },
          });
        } catch (e) {
          toastErrorQuery(e);
        }

        setSubmitting(false);
      }}
    >
      {({ submitForm }) => (
        <Form className="custom-table-body">
          <div className="columns">
            <div className="column title">
              <label>{model.name}</label>
            </div>

            <div className="column text" data-title={i18n._(t`Tip`)}>
              <Field type="text" name="shortText" className="form-control" onBlur={submitForm} />
            </div>

            <div className="column url" data-title={i18n._(t`Document URL`)}>
              <Field
                type="url"
                name="documentUrl"
                className="form-control"
                onBlur={submitForm}
                placeholder="https://"
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default HelpDocumentForm;
