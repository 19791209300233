import { Field } from 'formik';
import React, { FunctionComponent } from 'react';
import AssetResponsibleForm from './AssetResponsibleForm';
import AssetPersonForm from './AssetPersonForm';

type Props = {};

const AssetInfoForm: FunctionComponent<Props> = () => {
  return (
    <div className="row">
      <div className="col-xl-6">
        <div className="form-group">
          <AssetResponsibleForm />
        </div>
      </div>

      <Field name="companyId">
        {({ field }: { field: any }) => {
          if (!field.value) return '';

          return (
            <div className="col-xl-6">
              <div className="form-group">
                <AssetPersonForm companyId={field.value} />
              </div>
            </div>
          );
        }}
      </Field>
    </div>
  );
};

export default AssetInfoForm;
