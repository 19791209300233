import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent, useCallback } from 'react';
import Select from 'react-select';
import { GET_PRODUCTS } from '../../schema/product';
import { getProducts, getProductsVariables } from '../../schema/types/getProducts';
import { Product } from '../../schema/types/Product';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {
  value?: Product | null;
  onSubmit: (value: Product) => void;
  all?: boolean;
};

const TimeTrackerProductField: FunctionComponent<Props> = ({ value, onSubmit, all }) => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        timeTrackerEnabled: !all ? true : undefined,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getProducts, getProductsVariables>(GET_PRODUCTS, {
    variables,
    fetchPolicy: 'cache-first',
  });

  const handleChange = useCallback(
    (value: any) => {
      onSubmit(value.value);
    },
    [onSubmit],
  );

  const handleLoad = (value: string) => search$.next(value);

  const options = loading
    ? []
    : data!.products.items.map((el: any) => ({
        value: el,
        label: el.title,
      }));

  const model = value
    ? {
        value: value.id,
        label: value.title,
      }
    : null;

  if (!data && !loading) return <div />;

  return (
    <Select
      value={model}
      options={options}
      isLoading={loading}
      onChange={handleChange}
      onInputChange={handleLoad}
      placeholder={i18n._(t`Search...`)}
      {...selectOptions}
    />
  );
};

export default TimeTrackerProductField;
