import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import { GET_CONTRACTS_FOR_REPORT } from '../../schema/contract';
import { getContracts, getContractsVariables } from '../../schema/types/getContracts';
import { useVariables } from '../../utils/hooks';
import MultipleSelect from '../form/MultipleSelect';

type Props = {
  name: string;
};

const ReportContractForm: FunctionComponent<Props> = ({ name }) => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getContracts, getContractsVariables>(GET_CONTRACTS_FOR_REPORT, {
    variables,
  });

  if (!data && !loading) return <div />;

  return (
    <MultipleSelect
      name={name}
      search$={search$}
      loading={loading}
      data={data?.contracts.items}
      placeholder={i18n._(t`All Contracts`)}
    />
  );
};

export default ReportContractForm;
