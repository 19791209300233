import React, { FunctionComponent } from 'react';

type Props = {};

const LoadingTicket: FunctionComponent<Props> = () => {
  return (
    <div className="ticket-page">
      <div className="ticket-area">
        <div className="panel-users active p-3">
          <div className="container">
            <div className="ph-item">
              <div className="ph-col-12">
                <div className="ph-row">
                  <div className="ph-col-12 big" />
                  <div className="ph-col-4" />
                  <div className="ph-col-8 empty" />
                  <div className="ph-col-6" />
                  <div className="ph-col-6 empty" />
                  <div className="ph-col-12" />
                </div>

                <div className="ph-row">
                  <div className="ph-col-12 big" />
                  <div className="ph-col-4" />
                  <div className="ph-col-8 empty" />
                  <div className="ph-col-6" />
                  <div className="ph-col-6 empty" />
                  <div className="ph-col-12" />
                </div>

                <div className="ph-row">
                  <div className="ph-col-12 big" />
                  <div className="ph-col-4" />
                  <div className="ph-col-8 empty" />
                  <div className="ph-col-6" />
                  <div className="ph-col-6 empty" />
                  <div className="ph-col-12" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="panel-message py-3">
          <div className="container">
            <div className="ph-item">
              <div className="ph-col-12">
                <div className="ph-row">
                  <div className="ph-col-12 big" />
                  <div className="ph-col-4" />
                  <div className="ph-col-8 empty" />
                  <div className="ph-col-6" />
                  <div className="ph-col-6 empty" />
                  <div className="ph-col-12" />
                </div>

                <div className="ph-row">
                  <div className="ph-col-12 big" />
                  <div className="ph-col-4" />
                  <div className="ph-col-8 empty" />
                  <div className="ph-col-6" />
                  <div className="ph-col-6 empty" />
                  <div className="ph-col-12" />
                </div>

                <div className="ph-row">
                  <div className="ph-col-12 big" />
                  <div className="ph-col-4" />
                  <div className="ph-col-8 empty" />
                  <div className="ph-col-6" />
                  <div className="ph-col-6 empty" />
                  <div className="ph-col-12" />
                </div>

                <div className="ph-row">
                  <div className="ph-col-12 big" />
                  <div className="ph-col-4" />
                  <div className="ph-col-8 empty" />
                  <div className="ph-col-6" />
                  <div className="ph-col-6 empty" />
                  <div className="ph-col-12" />
                </div>

                <div className="ph-row">
                  <div className="ph-col-12 big" />
                  <div className="ph-col-4" />
                  <div className="ph-col-8 empty" />
                  <div className="ph-col-6" />
                  <div className="ph-col-6 empty" />
                  <div className="ph-col-12" />
                </div>

                <div className="ph-row">
                  <div className="ph-col-12 big" />
                  <div className="ph-col-4" />
                  <div className="ph-col-8 empty" />
                  <div className="ph-col-6" />
                  <div className="ph-col-6 empty" />
                  <div className="ph-col-12" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="panel-form">
          <div className="container">
            <div className="ph-item">
              <div className="ph-col-12">
                <div className="ph-row">
                  <div className="ph-col-6 big" />
                  <div className="ph-col-4 empty big" />
                  <div className="ph-col-2 big" />
                  <div className="ph-col-4" />
                  <div className="ph-col-8 empty" />
                  <div className="ph-col-6" />
                  <div className="ph-col-6 empty" />
                  <div className="ph-col-12" />
                </div>

                <div className="ph-picture" />

                <div className="ph-row">
                  <div className="ph-col-6 big" />
                  <div className="ph-col-4 empty big" />
                  <div className="ph-col-2 big" />
                  <div className="ph-col-4" />
                  <div className="ph-col-8 empty" />
                  <div className="ph-col-6" />
                  <div className="ph-col-6 empty" />
                  <div className="ph-col-12" />
                </div>

                <div className="ph-row">
                  <div className="ph-col-6 big" />
                  <div className="ph-col-4 empty big" />
                  <div className="ph-col-2 big" />
                  <div className="ph-col-4" />
                  <div className="ph-col-8 empty" />
                  <div className="ph-col-6" />
                  <div className="ph-col-6 empty" />
                  <div className="ph-col-12" />
                </div>

                <div className="ph-row">
                  <div className="ph-col-6 big" />
                  <div className="ph-col-4 empty big" />
                  <div className="ph-col-2 big" />
                  <div className="ph-col-4" />
                  <div className="ph-col-8 empty" />
                  <div className="ph-col-6" />
                  <div className="ph-col-6 empty" />
                  <div className="ph-col-12" />
                </div>

                <div className="ph-row">
                  <div className="ph-col-6 big" />
                  <div className="ph-col-4 empty big" />
                  <div className="ph-col-2 big" />
                  <div className="ph-col-4" />
                  <div className="ph-col-8 empty" />
                  <div className="ph-col-6" />
                  <div className="ph-col-6 empty" />
                  <div className="ph-col-12" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingTicket;
