import gql from 'graphql-tag';
import { validateRequired } from '../utils/validations';
import { TimeTrackerProject } from './types/TimeTrackerProject';
import { TimeTrackerProjectAdding } from './types/TimeTrackerProjectAdding';
import { TimeTrackerProjectEntry } from './types/TimeTrackerProjectEntry';
import { TimeTrackerProjectSpecialRate } from './types/TimeTrackerProjectSpecialRate';

export const TIME_TRACKER_PROJECT_SPECIAL_RATE = gql`
  fragment TimeTrackerProjectSpecialRate on TimeTrackerProjectSpecialRate {
    product {
      id
      title
    }
    amount
    productId @client
  }
`;

export const ExtendTimeTrackerProjectSpecialRate = {
  productId: (obj: TimeTrackerProjectSpecialRate) => obj.product.id,
};

export const TIME_TRACKER_PROJECT_ENTRY = gql`
  fragment TimeTrackerProjectEntry on TimeTrackerProjectEntry {
    id
    user {
      id
      fullName
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
    product {
      id
      title
      price
    }
    date
    durationSeconds
    description
    userId @client
    productId @client
  }
`;

export const ExtendTimeTrackerProjectEntry = {
  userId: (obj: TimeTrackerProjectEntry) => obj.user.id,
  productId: (obj: TimeTrackerProjectEntry) => obj.product.id,
};

export const TIME_TRACKER_PROJECT_ADDING = gql`
  fragment TimeTrackerProjectAdding on TimeTrackerProjectAdding {
    product {
      id
      title
    }
    title
    price
    pieces
    discountPercent
    productId @client
  }
`;

export const ExtendTimeTrackerProjectAdding = {
  productId: (obj: TimeTrackerProjectAdding) => obj.product.id,
};

export const TIME_TRACKER_PROJECT = gql`
  fragment TimeTrackerProject on TimeTrackerProject {
    id
    title
    customer {
      id
      name
      timeTrackerSpecialRates {
        ...TimeTrackerProjectSpecialRate
      }
    }
    budgetCount
    budgetUnit
    enableInCustomerView
    specialRates {
      ...TimeTrackerProjectSpecialRate
    }
    entries {
      ...TimeTrackerProjectEntry
    }
    addings {
      ...TimeTrackerProjectAdding
    }
    customerId @client
  }
  ${TIME_TRACKER_PROJECT_SPECIAL_RATE}
  ${TIME_TRACKER_PROJECT_ENTRY}
  ${TIME_TRACKER_PROJECT_ADDING}
`;

export const ExtendTimeTrackerProject = {
  customerId: (obj: TimeTrackerProject) => obj.customer.id,
};

export const TIME_TRACKER_PROJECTS = gql`
  fragment TimeTrackerProjects on TimeTrackerProjectCollection {
    limit
    offset
    total
    items {
      ...TimeTrackerProject
    }
  }
  ${TIME_TRACKER_PROJECT}
`;

export const GET_TIME_TRACKER_PROJECTS = gql`
  query getTimeTrackerProjects($query: TimeTrackerProjectsQuery!) {
    timeTrackerProjects(query: $query) {
      ...TimeTrackerProjects
    }
  }
  ${TIME_TRACKER_PROJECTS}
`;

export const PERSON_GET_TIME_TRACKER_PROJECTS = gql`
  query personGetTimeTrackerProjects($id: ID!, $query: TimeTrackerProjectsQuery!) {
    company(id: $id) {
      id
      timeTrackerProjects(query: $query) {
        ...TimeTrackerProjects
      }
    }
  }
  ${TIME_TRACKER_PROJECTS}
`;

export const GET_TIME_TRACKER_PROJECT = gql`
  query getTimeTrackerProject($query: ID!) {
    timeTrackerProject(id: $query) {
      ...TimeTrackerProject
    }
  }
  ${TIME_TRACKER_PROJECT}
`;

export const TIME_TRACKER_ENTRIES = gql`
  fragment TimeTrackerEntries on TimeTrackerProjectEntryCollection {
    limit
    offset
    total
    items {
      ...TimeTrackerProjectEntry
    }
  }
  ${TIME_TRACKER_PROJECT_ENTRY}
`;

export const GET_TIME_TRACKER_ENTRIES = gql`
  query getTimeTrackerEntrys($query: TimeTrackerProjectEntryQuery!) {
    timeTrackerEntries(query: $query) {
      ...TimeTrackerEntries
    }
  }
  ${TIME_TRACKER_ENTRIES}
`;

export const TIME_TRACKER_PROJECT_ADDINGS = gql`
  fragment TimeTrackerProjectAddings on TimeTrackerProjectAddingCollection {
    limit
    offset
    total
    items {
      ...TimeTrackerProjectAdding
    }
  }
  ${TIME_TRACKER_PROJECT_ADDING}
`;

export const GET_TIME_TRACKER_PROJECT_ADDINGS = gql`
  query getTimeTrackerProjectAddings($query: TimeTrackerProjectAddingQuery!) {
    timeTrackerProjectAddings(query: $query) {
      ...TimeTrackerProjectAddings
    }
  }
  ${TIME_TRACKER_PROJECT_ADDINGS}
`;

export const CREATE_TIME_TRACKER_PROJECT = gql`
  mutation createTimeTrackerProject($payload: CreateTimeTrackerProjectPayload!) {
    createTimeTrackerProject(payload: $payload) {
      timeTrackerProject {
        ...TimeTrackerProject
      }
    }
  }
  ${TIME_TRACKER_PROJECT}
`;

export const UPDATE_TIME_TRACKER_PROJECT = gql`
  mutation updateTimeTrackerProject($id: ID!, $payload: UpdateTimeTrackerProjectPayload!) {
    updateTimeTrackerProject(id: $id, payload: $payload) {
      timeTrackerProject {
        ...TimeTrackerProject
      }
    }
  }
  ${TIME_TRACKER_PROJECT}
`;

export const ARCHIVE_TIME_TRACKER_PROJECT = gql`
  mutation archiveTimeTrackerProject($id: ID!) {
    archiveTimeTrackerProject(id: $id) {
      timeTrackerProject {
        id
      }
    }
  }
`;

export const UNARCHIVE_TIME_TRACKER_PROJECT = gql`
  mutation unarchiveTimeTrackerProject($id: ID!) {
    unarchiveTimeTrackerProject(id: $id) {
      timeTrackerProject {
        id
      }
    }
  }
`;

export const CREATE_TIME_TRACKER_PROJECT_ENTRY = gql`
  mutation createTimeTrackerProjectEntry($projectId: ID!, $payload: TimeTrackerProjectEntryPayload!) {
    createTimeTrackerProjectEntry(projectId: $projectId, payload: $payload) {
      timeTrackerProject {
        ...TimeTrackerProject
      }
      entry {
        ...TimeTrackerProjectEntry
      }
    }
  }
  ${TIME_TRACKER_PROJECT}
  ${TIME_TRACKER_PROJECT_ENTRY}
`;

export const UPDATE_TIME_TRACKER_ENTRY_BULK = gql`
  mutation updateTimeTrackerEntryBulk($ids: [ID!]!, $payload: UpdateTimeTrackerEntryBulkPayload!) {
    updateTimeTrackerEntryBulk(ids: $ids, payload: $payload)
  }
`;

export const UPDATE_COMPANY_TIME_TRACKER_SPECIAL_RATES = gql`
  mutation updateCompanyTimeTrackerSpecialRates($id: ID!, $payload: [TimeTrackerProjectSpecialRatePayload!]!) {
    updateCompanyTimeTrackerSpecialRates(id: $id, payload: $payload)
  }
`;

export const timeTrackerProjectKeys = [
  'title',
  'budgetCount',
  'budgetUnit',
  'enableInCustomerView',
  'specialRates',
  'entries',
  'addings',
  'customerId',
];

export const validateTimeTrackerProjectForm = {
  title: validateRequired,
};

export const timeTrackerProjectEntryKeys = ['userId', 'productId', 'date', 'durationSeconds', 'description'];

export const validateTimeTrackerProjectEntryForm = {};
