import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import arrayMove from 'array-move';
import { Field, FormikProps } from 'formik';
import { cloneDeep } from 'lodash';
import React, { FunctionComponent, useCallback } from 'react';
import Select from 'react-select';
import { GET_DOCUMENT_TEMPLATE_ELEMENTS } from '../../schema/documentTemplateElement';
import { OfferItemProjectItemStatus, OfferItemType } from '../../schema/graphql-global-types';
import { DocumentTemplateElement } from '../../schema/types/DocumentTemplateElement';
import {
  getDocumentTemplateElements,
  getDocumentTemplateElementsVariables,
} from '../../schema/types/getDocumentTemplateElements';
import { OfferDetail } from '../../schema/types/OfferDetail';
import { OfferItem } from '../../schema/types/OfferItem';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';
import { focusTitle } from '../../utils/utils';
import UserAvatarName from '../common/UserAvatarName';
import { DocumentTemplateContent } from '../documentation/DocumentationEditor';
import DocumentChatGPTForm from '../documentation/DocumentChatGPTForm';
import ButtonDelete from '../form/ButtonDelete';
import ButtonIndex from '../form/ButtonIndex';
import FieldCheckbox from '../form/FieldCheckbox';
import FieldEditor from '../form/FieldEditor';
import ImageUploader from '../form/ImageUploader';
import OfferAddModules from './OfferAddModules';
import OfferEditorProduct from './OfferEditorProduct';
import OfferEditorProject from './OfferEditorProject';

type Props = {};

const OfferEditor: FunctionComponent<Props> = () => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getDocumentTemplateElements, getDocumentTemplateElementsVariables>(
    GET_DOCUMENT_TEMPLATE_ELEMENTS,
    {
      variables,
    },
  );

  const handleLoad = useCallback((value: string) => search$.next(value), [search$]);

  const options = loading
    ? []
    : data!.documentTemplateElements.items.map((el: any) => ({
        value: el,
        label: el.title,
      }));

  return (
    <div className="border-right">
      <div className="container">
        <div className="form-group header-graphic elements offer">
          <Field name="headGraphic.url">
            {({ field, form }: { field: any; form: FormikProps<any> }) => (
              <ImageUploader
                onUpload={(id) => form.setFieldValue('headGraphicId', id)}
                image={field.value}
                placeholder="900x600"
              />
            )}
          </Field>
        </div>

        <Field name="id">
          {({ form }: { form: FormikProps<OfferDetail> }) => {
            const model = form.values;

            return (
              <div className="offer-meta">
                <div className="column">
                  <div className="elements card">
                    <label className="label-lg">
                      <Trans>Customer</Trans>
                    </label>

                    {model.company && model.company.logo && (
                      <div className="company-logo mb-3">
                        <img src={model.company!.logo.url} alt={model.company.name} />
                      </div>
                    )}

                    <div className="form-group">
                      <Trans>Name:</Trans> {model.firstName} {model.lastName}
                    </div>

                    <div className="form-group">
                      <Trans>Position:</Trans> {model.function}
                    </div>

                    {model.company && (
                      <>
                        <div className="form-group">
                          <Trans>Company:</Trans> {model.company.name}
                        </div>

                        <div className="form-group">
                          <Trans>Address:</Trans> {model.company.address1}
                          <div>{model.company.address2}</div>
                          <div>
                            {model.company.zip} {model.company.city}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="column">
                  <div className="elements card">
                    <label className="label-lg">
                      <Trans>Responsible Side</Trans>
                    </label>

                    {model.responsibleUsers.map((el, index) => (
                      <div key={index} className="form-group user">
                        <div className="label">
                          {index === 0 && <Trans>Account Manager:</Trans>}
                          {index === 1 && <Trans>Primary Engineer:</Trans>}
                        </div>

                        <UserAvatarName user={el} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          }}
        </Field>

        <Field name="items">
          {({ field, form }: { field: any; form: FormikProps<any> }) => {
            const handleRemove = (index: number) => () => {
              const values = cloneDeep(field.value);
              values.splice(index, 1);
              form.setFieldValue('items', values);
            };

            const handleChange = (index: number) => (value: any) => {
              const values = cloneDeep(field.value);
              values[index] = {
                ...values[index],
                templateElementId: value.value.id,
                templateElement: {
                  id: value.value.id,
                  content: value.value.content,
                },
              };

              form.setFieldValue('items', values);
              focusTitle(index);
            };

            const handleSortProduct = (index: number) => (result: any) => {
              const productItems: any = cloneDeep(field.value[index].productItems);
              form.setFieldValue(
                `items[${index}].productItems`,
                arrayMove(productItems, result.source.index, result.destination.index),
              );
            };

            const handleSortProject = (index: number) => (result: any) => {
              const projectItems: any = cloneDeep(field.value[index].projectItems);
              form.setFieldValue(
                `items[${index}].projectItems`,
                arrayMove(projectItems, result.source.index, result.destination.index),
              );
            };

            const handleRemoveProduct = (index: number) => (index2: number) => () => {
              const productItems: any = cloneDeep(field.value[index].productItems);
              productItems.splice(index2, 1);
              form.setFieldValue(`items[${index}].productItems`, productItems);
            };

            const handleRemoveProject = (index: number) => (index2: number) => () => {
              const projectItems: any = cloneDeep(field.value[index].projectItems);
              projectItems.splice(index2, 1);
              form.setFieldValue(`items[${index}].projectItems`, projectItems);
            };

            const handleAddProduct = (index: number) => (value: any) => {
              const productItems: any = cloneDeep(field.value[index].productItems);
              productItems.push({
                title: value.value.title,
                pieces: 1,
                price: value.value.price,
                discountPercent: 0,
                product: {
                  id: value.value.id,
                  title: value.value.title,
                },
                productId: value.value.id,
              });
              form.setFieldValue(`items[${index}].productItems`, productItems);
            };

            const handleAddProject = (index: number) => (value: any) => {
              const projectItems: any = cloneDeep(field.value[index].projectItems);
              projectItems.push({
                description: '',
                startDate: new Date(),
                endDate: new Date(),
                status: OfferItemProjectItemStatus.Open,
                user: {
                  id: value.value.id,
                  fullName: value.value.fullName,
                  avatar: value.value.avatar,
                },
                userId: value.value.id,
              });
              form.setFieldValue(`items[${index}].projectItems`, projectItems);
            };

            const getIndex = (el: OfferItem, index: number) => {
              if (!el.index) return '';

              let count = 0;

              for (let i = 0; i < field.value.length; i++) {
                if (field.value[i].index) {
                  count++;
                }

                if (i === index) {
                  break;
                }
              }

              return count ? `${count}.` : '';
            };

            return (
              <>
                {field.value.length === 0 && (
                  <div className="document-add-actions">
                    <OfferAddModules index={0} />

                    <div className="ml-auto">
                      <FieldCheckbox name="index" label={i18n._(t`Show Index`)} />
                    </div>
                  </div>
                )}

                {field.value.map((el: OfferItem & { templateElement?: DocumentTemplateElement }, index: number) => (
                  <div key={index}>
                    {index === 0 && (
                      <div className="document-add-actions">
                        <OfferAddModules index={index} />

                        <div className="ml-auto">
                          <FieldCheckbox name="index" label={i18n._(t`Show Index`)} />
                        </div>
                      </div>
                    )}

                    <div className="elements card">
                      {el.type !== OfferItemType.TemplateElement ? (
                        <div className="d-flex align-items-center">
                          <div className="mr-2" style={{ width: '25px' }}></div>

                          <div className="form-label">
                            {el.type ? i18n._(el.type) : ''} <Trans>Title</Trans>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex align-items-center">
                          <div className="mr-2" style={{ width: '25px' }}></div>

                          <div className="row w-100 document-element-heading">
                            <div className="form-label col-md-7">
                              <Trans>Title</Trans>
                            </div>

                            <div className="form-label col-md-5 hidden-phone">
                              <Trans>Apply centralized content</Trans>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="form-group d-flex align-items-center">
                        <div className="mr-2" style={{ width: '25px' }}>
                          {getIndex(el, index)}
                        </div>
                        {el.templateElementId === null ? (
                          <Field
                            type="text"
                            className="form-control editor-item-title"
                            name={`items[${index}].title`}
                            placeholder={i18n._(t`Title`)}
                          />
                        ) : (
                          <div className="row w-100">
                            <div className="col-md-7">
                              <Field
                                type="text"
                                className="form-control editor-item-title"
                                name={`items[${index}].title`}
                                placeholder={i18n._(t`Title`)}
                              />
                            </div>

                            <div className="col-md-5 mt-2 mt-md-0">
                              <Select
                                options={options}
                                isLoading={loading}
                                onChange={handleChange(index)}
                                onInputChange={handleLoad}
                                placeholder={i18n._(t`Select...`)}
                                {...selectOptions}
                              />
                            </div>
                          </div>
                        )}
                        <Field name={`items[${index}].index`}>
                          {({ field, form }: { field: any; form: FormikProps<any> }) => {
                            const handleClick = () => {
                              form.setFieldValue(`items[${index}].index`, !field.value);
                            };

                            return (
                              <div className="ml-4">
                                <ButtonIndex active={field.value} onClick={handleClick} />
                              </div>
                            );
                          }}
                        </Field>
                        <div className="ml-4">
                          <ButtonDelete onClick={handleRemove(index)} />
                        </div>
                      </div>

                      <div className="form-group">
                        {el.type === OfferItemType.Product ? (
                          <OfferEditorProduct
                            model={el}
                            name={`items[${index}]`}
                            onSort={handleSortProduct(index)}
                            onAdd={handleAddProduct(index)}
                            onRemove={handleRemoveProduct(index)}
                          />
                        ) : el.type === OfferItemType.Project ? (
                          <OfferEditorProject
                            model={el}
                            name={`items[${index}]`}
                            onSort={handleSortProject(index)}
                            onAdd={handleAddProject(index)}
                            onRemove={handleRemoveProject(index)}
                          />
                        ) : el.type === OfferItemType.Text ? (
                          <>
                            <FieldEditor name={`items[${index}].content`} />
                            <DocumentChatGPTForm
                              title={el.title}
                              onChange={(val) => form.setFieldValue(`items[${index}].content`, val)}
                            />
                          </>
                        ) : (
                          <DocumentTemplateContent el={el} />
                        )}
                      </div>
                    </div>

                    <div className="document-add-actions">
                      <OfferAddModules index={index + 1} />

                      <div className="ml-auto">
                        <FieldCheckbox name="index" label={i18n._(t`Show Index`)} />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            );
          }}
        </Field>
      </div>
    </div>
  );
};

export default OfferEditor;
