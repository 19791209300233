import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import copy from 'copy-to-clipboard';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import slug from 'slug';
import { DocumentCategory } from '../../schema/types/DocumentCategory';
import { getStorage, setStorage } from '../../utils/storage';
import FieldErrorMessage from '../form/FieldErrorMessage';
import DocumentationCategoryForm from './DocumentationCategoryForm';
import DocumentationCategoryModal from './DocumentationCategoryModal';
import DocumentationCustomerForm from './DocumentationCustomerForm';
import DocumentationTagForm from './DocumentationTagForm';
import DocumentationTemplateForm from './DocumentationTemplateForm';
import DocumentationVersionModal from './DocumentationVersionModal';
import DocumentationVisibilityForm from './DocumentationVisibilityForm';

const handleCopy = (value: string) => () => {
  copy(value);
  toast.success(i18n._(t`Copied!`));
};

type Props = {
  onSortable: () => void;
};

const DocumentationSidebar: FunctionComponent<Props> = ({ onSortable }) => {
  const [showCategory, setShowCategory] = useState(false);
  const [showVersion, setShowVersion] = useState(false);

  const toggleCategoryModal = useCallback(() => setShowCategory((prevState) => !prevState), []);

  const toggleVersionModal = useCallback(() => setShowVersion((prevState) => !prevState), []);

  const [showGeneral, setShowGeneral] = useState<boolean>(getStorage('showDocumentationGeneral') ?? true);
  const toggleShowGeneral = useCallback(
    () =>
      setShowGeneral((prevState) => {
        setStorage('showDocumentationGeneral', !prevState);
        return !prevState;
      }),
    [],
  );

  return (
    <div className="documentation-sidebar">
      <div className="container">
        <div className="panel d-flex justify-content-between">
          <Field name="publicUrl">
            {({ field }: { field: any }) => {
              if (!field.value) return '';

              return (
                <a href={field.value} className="button" target="_blank">
                  <Trans>Preview Link</Trans>
                  <i className="icon-preview" />
                </a>
              );
            }}
          </Field>

          <Field name="permanentLink">
            {({ field, form }: { field: any; form: FormikProps<any> }) => {
              const domain = process.env.REACT_APP_URL!.slice(0, process.env.REACT_APP_URL!.lastIndexOf('/'));
              const url = `${domain}/documents/${field.value}/${form.values.id}`;

              return (
                <button type="button" className="button" onClick={handleCopy(url)}>
                  Copy Link
                  <i className="icon-copy" />
                </button>
              );
            }}
          </Field>
        </div>

        <div className="panel">
          <button type="button" className="panel-header" onClick={toggleShowGeneral}>
            <Trans>General information</Trans>

            {showGeneral ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" />}
          </button>

          {showGeneral && (
            <>
              <Field name="template">
                {({ field }: { field: any }) => {
                  if (!field.value) return '';

                  return (
                    <div className="form-group text-danger text-center">
                      <Trans>This is a Template</Trans>
                    </div>
                  );
                }}
              </Field>

              <div className="form-group">
                <label>
                  <Trans>Document Title</Trans>
                </label>

                <Field name="title">
                  {({ field, form }: { field: any; form: FormikProps<any> }) => {
                    const handleChange = (e: any) => {
                      form.setFieldValue('title', e.target.value);
                      form.setFieldValue('permanentLink', slug(e.target.value).toLowerCase());
                    };

                    return (
                      <input
                        type="text"
                        className="form-control"
                        value={field.value}
                        placeholder={i18n._(t`Title`)}
                        onChange={handleChange}
                      />
                    );
                  }}
                </Field>

                <FieldErrorMessage name="title" />
              </div>

              <div className="form-group">
                <label>
                  <Trans>Category</Trans>
                </label>

                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <DocumentationCategoryForm />
                  </div>

                  <span data-tip data-for="category" className="button ml-2" onClick={toggleCategoryModal}>
                    <i className="icon-edit" />
                  </span>
                  <ReactTooltip id="category">
                    <Trans>Manage Categories</Trans>
                  </ReactTooltip>
                </div>

                <FieldErrorMessage name="categoryId" />
              </div>

              <Field name="category">
                {({ form }: { form: FormikProps<any> }) => {
                  const handleSelect = (item: DocumentCategory) => {
                    form.setFieldValue('categoryId', item.id);
                    form.setFieldValue('category', {
                      id: item.id,
                      name: item.name,
                    });
                    toggleCategoryModal();
                  };

                  return (
                    <DocumentationCategoryModal
                      show={showCategory}
                      onSelect={handleSelect}
                      onClose={toggleCategoryModal}
                    />
                  );
                }}
              </Field>

              <div className="form-group">
                <label>
                  <Trans>Tags</Trans>
                </label>

                <DocumentationTagForm />
              </div>

              <div className="form-group">
                <label>
                  <Trans>Access Level</Trans>
                </label>

                <DocumentationVisibilityForm />
              </div>

              <Field name="visibility">
                {({ field }: { field: any }) => {
                  if (field.value !== 'SelectedCustomers') return '';

                  return (
                    <div className="form-group">
                      <label>
                        <Trans>Select Customers</Trans>
                      </label>

                      <DocumentationCustomerForm />
                    </div>
                  );
                }}
              </Field>

              <div className="d-flex justify-content-between">
                <label className="form-label">
                  <Trans>Select template</Trans>
                </label>

                <label className="form-label">
                  <Trans>Optional</Trans>
                </label>
              </div>

              <DocumentationTemplateForm />
            </>
          )}
        </div>

        <div className="panel d-flex justify-content-between">
          <Field name="versions.items">
            {({ field, form }: { field: any; form: FormikProps<any> }) => {
              if (!field.value) return '';

              return (
                <>
                  <button type="button" className="button" onClick={toggleVersionModal}>
                    <label>
                      <Trans>Version History</Trans>
                    </label>
                    <i className="icon-restore" />
                  </button>

                  <DocumentationVersionModal
                    show={showVersion}
                    id={form.values.id}
                    items={field.value}
                    onClose={toggleVersionModal}
                  />
                </>
              );
            }}
          </Field>

          <button type="button" className="button" onClick={onSortable}>
            <Trans>Toggle Sort</Trans>
            <i className="icon-sort" />
          </button>
        </div>

        <div className="form-group">&nbsp;</div>
      </div>
    </div>
  );
};

export default DocumentationSidebar;
