import { useMutation, useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { cloneDeep } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingForm from '../components/common/LoadingForm';
import MainTitle from '../components/common/MainTitle';
import ErrorQuery from '../components/form/ErrorQuery';
import PersonalTicketMessagePreview from '../components/personalTicket/PersonalTicketMessagePreview';
import { GET_VIEWER, UPDATE_VIEWER_CONFIG } from '../schema/auth';
import {
  CompanyPermission,
  LoginMode,
  TicketInProgressSubStatus,
  TicketsQueryOrderBy,
  TicketStatus,
} from '../schema/graphql-global-types';
import { PERSON_GET_TICKETS } from '../schema/ticket';
import { PersonCompany } from '../schema/types/PersonCompany';
import { personGetTickets, personGetTicketsVariables } from '../schema/types/personGetTickets';
import { updateViewerConfig, updateViewerConfigVariables } from '../schema/types/updateViewerConfig';
import { getCompanyId } from '../utils/auth';
import { tablePollingInterval } from '../utils/const';
import { useVariables, useViewer } from '../utils/hooks';
import { getStorage } from '../utils/storage';

const PersonProCloudTicketPage: FunctionComponent = () => {
  const me = useViewer();

  const [loaded, setLoaded] = useState(false);
  const { search, search$, archived, offset, orderDirection } = useVariables();

  const [selected, setSelected] = useState<any>(null);

  const variables = {
    id: getCompanyId(),
    query: {
      limit: 50,
      orderBy: TicketsQueryOrderBy.lastActiveTime,
      orderDirection: orderDirection,
      filter: {
        search,
        archived,
        onlyMe: me.data && me.data.viewer ? !me.data.viewer.config.confirmShowAllEmail : false,
      },
      offset: offset,
    },
  };

  const { data, previousData, loading, error, refetch } = useQuery<personGetTickets, personGetTicketsVariables>(
    PERSON_GET_TICKETS,
    {
      variables,
      pollInterval: tablePollingInterval,
    },
  );

  const items = useMemo(
    () => data?.company.tickets.items ?? previousData?.company.tickets.items ?? [],
    [data, previousData],
  );

  useEffect(() => {
    if (items.length) {
      if (!selected) {
        setSelected(cloneDeep(items[0]));
      } else {
        const index = items.findIndex((el: any) => el.id === selected.id);

        if (index >= items.length) {
          setSelected(cloneDeep(items[items.length - 1]));
        }
      }
    }
  }, [items, selected]);

  const [mutate] = useMutation<updateViewerConfig, updateViewerConfigVariables>(UPDATE_VIEWER_CONFIG, {
    optimisticResponse: {
      updateViewerConfig: true,
    },
    update: (proxy) => {
      const data: any = cloneDeep(proxy.readQuery({ query: GET_VIEWER }));
      data.viewer.config.confirmShowAllEmail = !data.viewer.config.confirmShowAllEmail;
      proxy.writeQuery({ query: GET_VIEWER, data });
    },
  });

  const handleChange = useCallback(
    async (e: any) => {
      await mutate({
        variables: {
          payload: {
            confirmShowAllEmail: e.target.checked,
          },
        },
      });
      refetch();

      toast.success(i18n._(t`Success!`));
    },
    [mutate, refetch],
  );

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;
  if (!loaded) return <LoadingForm />;
  if (!me.data) return <div />;

  const open: any[] = [];
  const waiting: any[] = [];
  const working: any[] = [];
  const done: any[] = [];

  items.forEach((el: any) => {
    switch (el.status) {
      case TicketStatus.Opened:
        open.push(el);
        break;
      case TicketStatus.InProgress:
        if (el.inProgressSubStatus === TicketInProgressSubStatus.WaitingForReply) {
          waiting.push(el);
        } else if (el.inProgressSubStatus === TicketInProgressSubStatus.Working) {
          working.push(el);
        }
        break;
      case TicketStatus.Closed:
        done.push(el);
        break;
      default:
        break;
    }
  });

  return (
    <div className="procloud-ticket-page">
      <div className="table-actions">
        <div className="breadcrumbs-area">
          <MainTitle title={i18n._(t`Your Tickets`)} />
        </div>

        {(me.data.viewer.mode === LoginMode.User ||
          me.data.viewer.companies.some(
            (el: PersonCompany) =>
              el.company.id === getStorage('companyId') && el.permission === CompanyPermission.Admin,
          )) && (
          <div className="custom-control custom-checkbox my-2">
            <input
              type="checkbox"
              className="custom-control-input"
              id="send-email"
              checked={me.data.viewer.config.confirmShowAllEmail}
              onChange={handleChange}
            />
            <label className="custom-control-label" htmlFor="send-email">
              <Trans>I confirm to be allowed to see all company tickets</Trans>
            </label>
          </div>
        )}

        <div className="item search input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <i className="icon-search" />
            </div>
          </div>
          <input
            className="form-control"
            placeholder={i18n._(t`Search`)}
            onChange={(e) => search$.next(e.target.value)}
          />
        </div>

        <NavLink to="/support/procloud/add" type="button" className="btn btn-sm btn-primary">
          <i className="icon-add" />
          <Trans>Add new ticket</Trans>
        </NavLink>
      </div>

      <div className="row">
        <div className="form-group col-lg-4 d-flex">
          <div className="card flex-grow-1">
            <div className="form-group">
              <span className="badge open">
                <Trans>Open</Trans>
              </span>
            </div>

            <div className="form-group">
              {open.length > 0 ? (
                open.map((el: any) => <PersonalTicketMessagePreview key={el.id} model={el} />)
              ) : (
                <div className="label-lg">
                  <Trans>No tickets</Trans>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="form-group col-lg-4 d-flex">
          <div className="card flex-grow-1">
            <div className="form-group">
              <span className="badge in-progress">
                <Trans>In Progress</Trans>
              </span>
            </div>

            <div className="form-group label-lg">
              <i className="fa fa-angle-down mr-2" />
              <Trans>Waiting for reply</Trans>
            </div>

            <div className="form-group">
              {waiting.length > 0 ? (
                waiting.map((el: any) => <PersonalTicketMessagePreview key={el.id} model={el} type="waiting" />)
              ) : (
                <div className="label-lg">
                  <Trans>No tickets</Trans>
                </div>
              )}
            </div>

            <div className="form-group label-lg">
              <i className="fa fa-angle-down mr-2" />
              <Trans>Tickets in work</Trans>
            </div>

            <div className="form-group">
              {working.length > 0 ? (
                working.map((el: any) => <PersonalTicketMessagePreview key={el.id} model={el} type="working" />)
              ) : (
                <div className="label-lg">
                  <Trans>No tickets</Trans>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="form-group col-lg-4 d-flex">
          <div className="card flex-grow-1">
            <div className="form-group">
              <span className="badge closed">
                <Trans>Closed</Trans>
              </span>
            </div>

            <div className="form-group">
              {done.length > 0 ? (
                done.map((el: any) => <PersonalTicketMessagePreview key={el.id} model={el} />)
              ) : (
                <div className="label-lg">
                  <Trans>No tickets</Trans>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonProCloudTicketPage;
