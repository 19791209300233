import { I18n } from '@lingui/core';
import React, { FunctionComponent, useEffect } from 'react';
import { I18nProvider } from '@lingui/react';
import { useLanguage } from '../schema/locale';
import { messages as en } from '../locales/en/messages';
import { messages as de } from '../locales/de/messages';

type Props = {
  children: any;
  i18n: I18n;
};

const I18nLoader: FunctionComponent<Props> = ({ children, i18n }) => {
  const language = useLanguage();

  useEffect(() => {
    i18n.load('en', en);
    i18n.load('de', de);
  }, [i18n]);

  useEffect(() => {
    i18n.activate(language);
  }, [i18n, language]);

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};

export default I18nLoader;
