import { useQuery } from '@apollo/client';
import { cloneDeep } from 'lodash';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import PersonContractForm from '../components/contract/PersonContractForm';
import ErrorQuery from '../components/form/ErrorQuery';
import LoadingForm from '../components/common/LoadingForm';
import { PERSON_GET_CONTRACT } from '../schema/contract';
import { personGetContract, personGetContractVariables } from '../schema/types/personGetContract';
import { getCompanyId } from '../utils/auth';
import { DefaultRouteParams } from '../utils/const';
import NotFoundPage from './NotFoundPage';

const now = new Date();
const next =
  now.getMonth() === 11
    ? new Date(Date.UTC(now.getFullYear() + 1, 0, 1))
    : new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 1));

const transform = (model: any, adjustment: string) => {
  const current = new Date(adjustment);
  model.adjustmentDate = new Date(current.getFullYear(), current.getMonth(), 2);

  return model;
};

const PersonContractDetailPage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ match }) => {
  const [adjustment, setAdjustment] = useState(next.toISOString());

  const handleAdjustmentChange = useCallback((item: string) => setAdjustment(item), [setAdjustment]);

  const { data, loading, error } = useQuery<personGetContract, personGetContractVariables>(PERSON_GET_CONTRACT, {
    variables: {
      id: getCompanyId(),
      query: match.params.id,
      query2: {
        effectiveDate: adjustment,
      },
    },
  });

  if (loading) return <LoadingForm />;
  if (error) return <ErrorQuery error={error} />;
  if (!data) return <NotFoundPage />;

  const model = transform(cloneDeep(data.company.contract), adjustment);

  return (
    <div className="contract-form-page">
      <PersonContractForm model={model} onAdjustmentChange={handleAdjustmentChange} />
    </div>
  );
};

export default withRouter(PersonContractDetailPage);
