import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import { Field } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { GET_VIEWER } from '../../schema/auth';
import { LoginMode, UserRole } from '../../schema/graphql-global-types';
import { getViewer } from '../../schema/types/getViewer';
import FieldErrorMessage from '../form/FieldErrorMessage';
import PhoneInput from '../form/PhoneInput';
import UserDisableMfaModal from './UserDisableMfaModal';
import UserSendModal from './UserSendModal';

type Props = {};

const UserInfoForm: FunctionComponent<Props> = () => {
  const [showSend, setShowSend] = useState(false);
  const [showDisableMfa, setShowDisableMfa] = useState(false);

  const toggleSend = useCallback(() => setShowSend((prevState) => !prevState), []);

  const toggleDisableMfa = useCallback(() => setShowDisableMfa((prevState) => !prevState), []);

  const { data } = useQuery<getViewer>(GET_VIEWER, {
    fetchPolicy: 'cache-first',
  });

  if (!data) return <div />;

  return (
    <div className="person-info-form">
      <div className="card-header">
        <Trans>Contact Information</Trans>
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>
                <Trans>Address 1</Trans>
              </label>
              <Field type="text" className="form-control" name="address1" />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>
                <Trans>Address 2</Trans>
              </label>
              <Field type="text" className="form-control" name="address2" />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>
                <Trans>Zip</Trans>
              </label>
              <Field type="text" className="form-control" name="zip" />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>
                <Trans>City</Trans>
              </label>
              <Field type="text" className="form-control" name="city" />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>
                <Trans>Company Phone</Trans>
              </label>
              <PhoneInput name="companyPhone" />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>
                <Trans>Cellphone</Trans>
              </label>
              <PhoneInput name="cellphone" />

              <FieldErrorMessage name="cellphone" />
            </div>
          </div>
        </div>

        <div className="form-group">
          <label>
            <Trans>Email</Trans>
          </label>
          <Field name="id">
            {({ field }: { field: any }) => {
              return <Field type="email" className="form-control" name="email" readOnly={field.value} />;
            }}
          </Field>

          <FieldErrorMessage name="email" />
        </div>

        <Field name="id">
          {({ field }: { field: any }) => {
            if (!field.value) return '';

            return (
              <>
                <button type="button" className="btn btn-link btn-icon" onClick={toggleSend}>
                  <i className="icon-send-email" />
                  <Trans>Send Credentials</Trans>
                </button>

                <UserSendModal show={showSend} mode="user" userId={field.value} onClose={toggleSend} />
              </>
            );
          }}
        </Field>

        <Field name="mfaEnabled">
          {({ field }: { field: any }) => {
            if (!field.value) return '';

            return (
              <button type="button" className="btn btn-link btn-icon" onClick={toggleDisableMfa}>
                <i className="icon-2FA" />
                <Trans>Disable Two Factor</Trans>
              </button>
            );
          }}
        </Field>
      </div>

      {data!.viewer.mode === LoginMode.User && data!.viewer.user!.role === UserRole.Admin && (
        <Field name="id">
          {({ field }: { field: any }) => (
            <UserDisableMfaModal id={field.value} show={showDisableMfa} onClose={toggleDisableMfa} />
          )}
        </Field>
      )}
    </div>
  );
};

export default UserInfoForm;
