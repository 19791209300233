import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { subHours } from 'date-fns';
import React, { FunctionComponent, useCallback, useState } from 'react';
import ReactTable, { CellInfo, RowInfo } from 'react-table';
import ReactTooltip from 'react-tooltip';
import PersonAssetDashboard from '../components/asset/PersonAssetDashboard';
import LoadingTable from '../components/common/LoadingTable';
import MainTitle from '../components/common/MainTitle';
import FilterHeader from '../components/form/FilterHeader';
import { PERSON_GET_ASSETS } from '../schema/asset';
import { AssetsQueryOrderBy, OrderDirection } from '../schema/graphql-global-types';
import { GET_PRODUCTS_FOR_FILTER } from '../schema/product';
import { GET_ASSET_LOCATION_TAGS, GET_ASSET_TYPE_TAGS } from '../schema/tag';
import { personGetAssets, personGetAssetsVariables } from '../schema/types/personGetAssets';
import { getCompanyId } from '../utils/auth';
import { tableOptions } from '../utils/const';
import ErrorQuery from '../components/form/ErrorQuery';
import { useVariables } from '../utils/hooks';
import { getFilter, getOrder } from '../utils/utils';

const today = new Date();
const hour24 = subHours(today, 24);

const PersonAssetPage: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);
  const {
    search$,
    search,
    filter$,
    filter,
    archived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
  } = useVariables({ orderBy: AssetsQueryOrderBy.uptimeStatus });

  const variables = {
    id: getCompanyId(),
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
    query2: {
      filter: {
        fromTime: hour24.toISOString(),
        toTime: today.toISOString(),
      },
    },
    query3: {
      step: 3600,
      filter: {
        fromTime: hour24.toISOString(),
        toTime: today.toISOString(),
      },
    },
  };

  const { data, previousData, loading, error } = useQuery<personGetAssets, personGetAssetsVariables>(
    PERSON_GET_ASSETS,
    {
      variables,
    },
  );

  const getRow = useCallback((state: any, rowInfo?: RowInfo) => {
    return {
      className: rowInfo!.original.uptimeStatus,
    };
  }, []);

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.company.assets.items ?? previousData?.company.assets.items ?? [];
  const pages = data && data.company ? Math.ceil(data.company.assets.total / limit) : -1;

  const columns = [
    {
      Header: '',
      expander: true,
      width: 50,
      className: 'text-center',
      Expander: (cell: CellInfo) => (
        <>
          <span data-tip data-for={`expand-${cell.original.id}`} className="button button-expand text-xs">
            {cell.isExpanded ? <i className="icon-dropdown" /> : <i className="icon-expand" />}
          </span>
          <ReactTooltip id={`expand-${cell.original.id}`}>
            <Trans>Expand</Trans>
          </ReactTooltip>
        </>
      ),
    },
    {
      Header: i18n._(t`Name`),
      accessor: 'name',
      sortable: true,
      filterable: true,
    },
    {
      Header: i18n._(t`Location`),
      accessor: 'assetLocation.name',
      filterable: true,
      Filter: (params: any) => (
        <FilterHeader
          query={GET_ASSET_LOCATION_TAGS}
          objectType="assetLocationTags"
          nameField="name"
          onChange={params.onChange}
        />
      ),
    },
    {
      Header: i18n._(t`Type`),
      accessor: 'assetType.name',
      filterable: true,
      Filter: (params: any) => (
        <FilterHeader
          query={GET_ASSET_TYPE_TAGS}
          objectType="assetTypeTags"
          nameField="name"
          onChange={params.onChange}
        />
      ),
    },
    {
      Header: i18n._(t`Package`),
      accessor: 'product.title',
      sortable: true,
      Filter: (params: any) => (
        <FilterHeader
          query={GET_PRODUCTS_FOR_FILTER}
          objectType="products"
          nameField="title"
          onChange={params.onChange}
        />
      ),
    },
    {
      id: 'uptimeSla',
      Header: i18n._(t`SLA`),
      accessor: 'product.reactionTime',
      Cell: (cell: CellInfo) => {
        const result = [`${cell.original.uptimeSla}%`];

        if (cell.value) {
          result.push(`${(cell.value / 3600).toFixed(1)}h`);
        }

        return result.join(' / ');
      },
    },
  ];

  return (
    <div className="asset-page">
      <div className="table-actions">
        <div className="breadcrumbs-area">
          <MainTitle title={i18n._(t`Managed Services`)} />
        </div>

        <div className="item search input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <i className="icon-search" />
            </div>
          </div>
          <input
            className="form-control"
            placeholder={i18n._(t`Search`)}
            onChange={(e) => search$.next(e.target.value)}
          />
        </div>
      </div>

      {!loaded ? (
        <LoadingTable />
      ) : (
        <ReactTable
          data={items}
          columns={columns}
          pages={pages}
          pageSize={limit}
          manual
          onFetchData={(state) => {
            filter$.next(
              getFilter(state, {
                name: 'searchName',
                'company.name': 'companyIds',
                'assetLocation.name': 'assetLocationIds',
                'assetType.name': 'assetTypeIds',
                'product.title': 'productIds',
                tags: 'tagIds',
              }),
            );

            let orderDir = OrderDirection.Desc;
            if (state.sorted[0]) {
              orderDir = state.sorted[0].desc ? OrderDirection.Desc : OrderDirection.Asc;
            }

            setOffset(state.page * state.pageSize);
            setOrderBy(
              getOrder(
                state,
                {
                  'company.name': 'companyName',
                  'product.title': 'primaryContactFullName',
                  uptime365: 'uptimeStatus',
                },
                AssetsQueryOrderBy.uptimeStatus,
              ),
            );
            setOrderDirection(orderDir);
          }}
          onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
          getTrProps={getRow}
          SubComponent={(rowInfo: RowInfo) => <PersonAssetDashboard model={rowInfo.original} />}
          freezeWhenExpanded
          {...tableOptions()}
        />
      )}
    </div>
  );
};

export default PersonAssetPage;
