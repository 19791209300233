import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import ReactTable, { CellInfo } from 'react-table';
import { NavLink } from 'react-router-dom';
import MainTitle from '../components/common/MainTitle';
import TableActions from '../components/common/TableActions';
import ButtonArchive from '../components/form/ButtonArchive';
import ButtonEdit from '../components/form/ButtonEdit';
import { getTextTemplates, getTextTemplatesVariables } from '../schema/types/getTextTemplates';
import { useVariables } from '../utils/hooks';
import { getFilter } from '../utils/utils';
import DateFormat from '../components/common/DateFormat';
import LoadingTable from '../components/common/LoadingTable';
import { GET_TEXT_TEMPLATES, ARCHIVE_TEXT_TEMPLATE, UNARCHIVE_TEXT_TEMPLATE } from '../schema/textTemplate';
import { archiveTextTemplate, archiveTextTemplateVariables } from '../schema/types/archiveTextTemplate';
import { unarchiveTextTemplate, unarchiveTextTemplateVariables } from '../schema/types/unarchiveTextTemplate';
import { dateTimeOptions, tableOptions } from '../utils/const';
import ErrorQuery from '../components/form/ErrorQuery';
import { cloneDeep } from 'lodash';
import { DataProxy } from '@apollo/client/cache';
import UserAvatarName from '../components/common/UserAvatarName';

const TextTemplatePage: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);
  const {
    search$,
    search,
    filter,
    filter$,
    archived,
    setArchived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    orderDirection,
  } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const updateQuery = (proxy: DataProxy, id: any) => {
    const data: any = cloneDeep(proxy.readQuery({ query: GET_TEXT_TEMPLATES, variables }));
    data.textTemplates.items = data.textTemplates.items.filter((el: any) => el.id !== id);
    proxy.writeQuery({ query: GET_TEXT_TEMPLATES, variables, data });
  };

  const [mutateArchive] = useMutation<archiveTextTemplate, archiveTextTemplateVariables>(ARCHIVE_TEXT_TEMPLATE, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.archiveTextTemplate.textTemplate.id);
    },
  });
  const [mutateUnarchive] = useMutation<unarchiveTextTemplate, unarchiveTextTemplateVariables>(
    UNARCHIVE_TEXT_TEMPLATE,
    {
      update: (proxy, { data }) => {
        updateQuery(proxy, data!.unarchiveTextTemplate.textTemplate.id);
      },
    },
  );

  const handleArchive = useCallback(
    (id: any) => () => {
      if (archived) {
        mutateUnarchive({
          variables: {
            id,
          },
          optimisticResponse: {
            unarchiveTextTemplate: {
              textTemplate: {
                id,
                __typename: 'TextTemplate',
              },
              __typename: 'MutateTextTemplateResponse',
            },
          },
        });
      } else {
        mutateArchive({
          variables: {
            id,
          },
          optimisticResponse: {
            archiveTextTemplate: {
              textTemplate: {
                id,
                __typename: 'TextTemplate',
              },
              __typename: 'MutateTextTemplateResponse',
            },
          },
        });
      }
    },
    [archived, mutateArchive, mutateUnarchive],
  );

  const { data, previousData, loading, error } = useQuery<getTextTemplates, getTextTemplatesVariables>(
    GET_TEXT_TEMPLATES,
    {
      variables,
    },
  );

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.textTemplates.items ?? previousData?.textTemplates.items ?? [];
  const pages = data && data.textTemplates ? Math.ceil(data.textTemplates.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Name`),
      accessor: 'systemName',
      Cell: (cell: CellInfo) => <NavLink to={`/settings/text-template/${cell.original.id}`}>{cell.value}</NavLink>,
    },
    {
      Header: i18n._(t`Subject`),
      accessor: 'title',
      Cell: (cell: CellInfo) => <NavLink to={`/settings/text-template/${cell.original.id}`}>{cell.value}</NavLink>,
    },
    {
      Header: i18n._(t`Editor`),
      accessor: 'editor.fullName',
      Cell: (cell: CellInfo) => {
        if (!cell.value) return <UserAvatarName name={i18n._(t`System`)} />;

        return (
          <NavLink to={`/settings/user/${cell.original.editor.id}`}>
            <UserAvatarName user={cell.original.editor} />
          </NavLink>
        );
      },
    },
    {
      Header: i18n._(t`Type`),
      accessor: 'type',
      width: 100,
      className: 'text-center',
      filterable: true,
      Cell: (cell: CellInfo) => i18n._(cell.value),
      Filter: (params: any) => (
        <select
          className="form-control"
          onChange={(event) => params.onChange(event.target.value ? [event.target.value] : '')}
          value={params.filter ? params.filter.value[0] : ''}
        >
          <option value="">{i18n._(t`All`)}</option>
          <option value="Public">{i18n._(t`Public`)}</option>
          <option value="Private">{i18n._(t`Private`)}</option>
          <option value="System">{i18n._(t`System`)}</option>
        </select>
      ),
    },
    {
      Header: i18n._(t`Last Edit`),
      accessor: 'updatedAt',
      Cell: (cell: CellInfo) => <DateFormat value={new Date(cell.value)} format={dateTimeOptions} />,
    },
    {
      Header: i18n._(t`Actions`),
      width: 90,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <>
          <NavLink to={`/settings/text-template/${cell.original.id}`}>
            <ButtonEdit />
          </NavLink>
          {cell.original.type !== 'System' && <ButtonArchive onClick={handleArchive(cell.original.id)} />}
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="table-actions">
        <div className="breadcrumbs-area">
          <MainTitle title={i18n._(t`Text Templates`)} tips="Settings/Text Templates" />
        </div>

        <TableActions onSearch={(e) => search$.next(e.target.value)} archived={archived} onArchive={setArchived} />

        <NavLink to="/settings/text-template/add" type="button" className="btn btn-sm btn-primary">
          <i className="icon-add" />
          <Trans>Add new text template</Trans>
        </NavLink>
      </div>

      {!loaded ? (
        <LoadingTable />
      ) : (
        <ReactTable
          data={items}
          columns={columns}
          pages={pages}
          pageSize={limit}
          manual
          onFetchData={(state) => {
            filter$.next(
              getFilter(state, {
                type: 'types',
              }),
            );

            setOffset(state.page * state.pageSize);
          }}
          onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
          {...tableOptions()}
        />
      )}
    </div>
  );
};

export default TextTemplatePage;
