import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { GET_OFFERS_WITH_DETAIL } from '../../schema/offer';
import { getOffers, getOffersVariables } from '../../schema/types/getOffers';
import { Tag } from '../../schema/types/Tag';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';
import { cloneDeep } from 'lodash';

const OfferTemplateForm: FunctionComponent = () => {
  const [show, setShow] = useState(false);
  const [model, setModel] = useState<any>(null);
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
        template: true,
      },
    },
  };

  const toggleModal = useCallback(() => setShow((prevState) => !prevState), [setShow]);

  const { data, loading } = useQuery<getOffers, getOffersVariables>(GET_OFFERS_WITH_DETAIL, {
    variables,
    fetchPolicy: 'cache-first',
  });

  if (!data && !loading) return <div />;

  return (
    <Field name="category">
      {({ form }: { form: FormikProps<any> }) => {
        const handleChange = (value: any) => {
          setModel(cloneDeep(value.value));
          toggleModal();
        };

        const handleSubmit =
          (update = false) =>
          () => {
            model.tagNames = model.tags.map((el: Tag) => el.name);
            model.responsibleUserIds = model.responsibleUsers.map((el: any) => el.id);
            model.favoriteTextTemplateId = model.favoriteTextTemplate ? model.favoriteTextTemplate.id : null;
            model.items = model.items.map((el: any) => ({
              ...el,
              productItems: el.productItems.map((product: any) => ({
                ...product,
                productId: product.product.id,
              })),
              projectItems: el.projectItems.map((product: any) => ({
                ...product,
                userId: product.user.id,
              })),
            }));

            // if (model.company) {
            //   form.setFieldValue('companyId', model.company.id);
            //   form.setFieldValue('company', model.company);
            // }

            if (model.headGraphic) {
              form.setFieldValue('headGraphicId', model.headGraphic.id);
              form.setFieldValue('headGraphic', model.headGraphic);
            }

            if (update) {
              model.items.forEach((el: any) => {
                if (el.type === 'Product') {
                  el.productItems.forEach((product: any) => {
                    product.price = product.product.price;
                  });
                }
              });
            }

            form.setFieldValue('items', model.items);
            // form.setFieldValue('responsibleUsers', model.responsibleUsers);
            // form.setFieldValue(
            //   'responsibleUserIds',
            //   model.responsibleUserIds
            // );
            form.setFieldValue('favoriteTextTemplate', model.favoriteTextTemplate);
            form.setFieldValue('favoriteTextTemplateId', model.favoriteTextTemplateId);

            form.setFieldValue('tags', model.tags);
            form.setFieldValue('tagNames', model.tagNames);

            // form.setFieldValue('firstName', model.firstName);
            // form.setFieldValue('lastName', model.lastName);
            // form.setFieldValue('function', model.function);
            // form.setFieldValue('email', model.email);

            form.setFieldValue('index', model.index);

            toggleModal();
          };

        const handleLoad = (value: string) => search$.next(value);

        const options = loading
          ? []
          : data!.offers.items.map((el: any) => ({
              value: el,
              label: el.title,
            }));

        return (
          <>
            <Select
              options={options}
              isLoading={loading}
              onChange={handleChange}
              onInputChange={handleLoad}
              placeholder={i18n._(t`Choose Template`)}
              {...selectOptions}
            />

            <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
              <div className="modal fade show" style={{ display: 'block' }}>
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h2 className="modal-title">
                        <Trans>Are you sure want to choose this template?</Trans>
                      </h2>
                      <button type="button" className="close" aria-label="Close" onClick={toggleModal}>
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>

                    <div className="modal-body text-center">
                      <p className="text-danger">
                        <Trans>All data will be overwritten.</Trans>
                      </p>

                      <p>
                        <Trans>Would you like to update the prices?</Trans>
                      </p>
                    </div>

                    <div className="modal-footer flex-wrap">
                      <button type="button" className="btn btn-link" onClick={toggleModal}>
                        <Trans>Close</Trans>
                      </button>

                      <button type="button" className="btn btn-primary ml-auto" onClick={handleSubmit(true)}>
                        <Trans>Yes</Trans>
                      </button>

                      <button type="button" className="btn btn-info ml-2" onClick={handleSubmit(false)}>
                        <Trans>No</Trans>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </>
        );
      }}
    </Field>
  );
};

export default OfferTemplateForm;
