import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import ReactTable, { CellInfo } from 'react-table';
import { NavLink } from 'react-router-dom';
import ButtonArchive from '../../components/form/ButtonArchive';
import ButtonEdit from '../../components/form/ButtonEdit';
import { ARCHIVE_OFFER, GET_OFFERS_WITH_DETAIL, UNARCHIVE_OFFER } from '../../schema/offer';
import { archiveOffer, archiveOfferVariables } from '../../schema/types/archiveOffer';
import { getOffers, getOffersVariables } from '../../schema/types/getOffers';
import { unarchiveOffer, unarchiveOfferVariables } from '../../schema/types/unarchiveOffer';
import { dateOptions, tableOptions } from '../../utils/const';
import ErrorQuery from '../../components/form/ErrorQuery';
import { cloneDeep } from 'lodash';
import { DataProxy } from '@apollo/client/cache';
import { useVariables } from '../../utils/hooks';
import DateFormat from '../common/DateFormat';
import ShowArchive from '../common/ShowArchive';

type Props = {};

const OfferTemplates: FunctionComponent<Props> = () => {
  const {
    search$,
    search,
    filter,
    archived,
    setArchived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    orderDirection,
  } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
        template: true,
      },
    },
  };

  const updateQuery = (proxy: DataProxy, id: any) => {
    const data: any = cloneDeep(proxy.readQuery({ query: GET_OFFERS_WITH_DETAIL, variables }));
    data.offers.items = data.offers.items.filter((el: any) => el.id !== id);
    proxy.writeQuery({ query: GET_OFFERS_WITH_DETAIL, variables, data });
  };

  const [mutateArchive] = useMutation<archiveOffer, archiveOfferVariables>(ARCHIVE_OFFER, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.archiveOffer.offer.id);
    },
  });
  const [mutateUnarchive] = useMutation<unarchiveOffer, unarchiveOfferVariables>(UNARCHIVE_OFFER, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.unarchiveOffer.offer.id);
    },
  });

  const handleArchive = useCallback(
    (id: any) => () => {
      if (archived) {
        mutateUnarchive({
          variables: {
            id,
          },
          optimisticResponse: {
            unarchiveOffer: {
              offer: {
                id,
                __typename: 'Offer',
              },
              __typename: 'MutateOfferResponse',
            },
          },
        });
      } else {
        mutateArchive({
          variables: {
            id,
          },
          optimisticResponse: {
            archiveOffer: {
              offer: {
                id,
                __typename: 'Offer',
              },
              __typename: 'MutateOfferResponse',
            },
          },
        });
      }
    },
    [archived, mutateArchive, mutateUnarchive],
  );

  const { data, error } = useQuery<getOffers, getOffersVariables>(GET_OFFERS_WITH_DETAIL, {
    variables,
  });

  if (error) return <ErrorQuery error={error} />;

  let items = data && data.offers ? data.offers.items : [];
  const pages = data && data.offers ? Math.ceil(data.offers.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Title`),
      accessor: 'title',
      Cell: (cell: CellInfo) => (
        <NavLink to={`/crm/offer/${cell.original.id}`} target="_blank">
          {cell.value}
        </NavLink>
      ),
    },
    {
      Header: i18n._(t`Date`),
      accessor: 'createdAt',
      width: 150,
      className: 'text-center',
      Cell: (cell: CellInfo) => <DateFormat value={new Date(cell.value)} format={dateOptions} />,
    },
    {
      Header: i18n._(t`Actions`),
      width: 90,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <>
          <NavLink to={`/crm/offer/${cell.original.id}`} target="_blank">
            <ButtonEdit />
          </NavLink>
          <ButtonArchive onClick={handleArchive(cell.original.id)} />
        </>
      ),
    },
  ];

  return (
    <>
      <div className="form-group input-group">
        <div className="input-group-prepend">
          <div className="input-group-text">
            <i className="icon-search" />
          </div>
        </div>
        <input
          className="form-control"
          placeholder={i18n._(t`Search`)}
          onInput={(e: any) => search$.next(e.target.value)}
        />
      </div>

      <div className="form-group text-right">
        <ShowArchive model={archived} onChange={setArchived} />
      </div>

      <ReactTable
        data={items}
        columns={columns}
        pages={pages}
        pageSize={limit}
        manual
        onFetchData={(state) => {
          setOffset(state.page * state.pageSize);
        }}
        onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
        {...tableOptions()}
      />
    </>
  );
};

export default OfferTemplates;
