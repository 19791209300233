import React, { Component } from 'react';
import AppError from './components/AppError';
import AppRouter from './components/AppRouter';
import Sidebar from './components/Sidebar';
import Searchbar from './components/Searchbar';
import Topbar from './components/Topbar';

type Props = {};

type State = {};

class App extends Component<Props, State> {
  render() {
    return (
      <div className="App">
        <Sidebar />
        <div className="app-content">
          <div className="side-app">
            <div className="main-content">
              <Topbar />

              <div className="d-block d-sm-none navbar-sm-search ml-lg-auto">
                <Searchbar />
              </div>

              <div className="container-fluid">
                <AppError>
                  <AppRouter />
                </AppError>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
