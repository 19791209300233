import { Trans } from '@lingui/macro';
import { Field } from 'formik';
import React, { FunctionComponent } from 'react';
import { MonitorKeywordType } from '../../schema/graphql-global-types';

type Props = {};

const AssetMonitorKeywordForm: FunctionComponent<Props> = () => {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Type</Trans>
          </label>
          <Field
            component="select"
            className="form-control"
            name="keyword.type"
          >
            {Object.keys(MonitorKeywordType).map((el: any, index: number) => (
              <option key={index} value={el}>
                {el}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Value</Trans>
          </label>
          <Field type="text" className="form-control" name="keyword.value" />
        </div>
      </div>
    </div>
  );
};

export default AssetMonitorKeywordForm;
