import arrayMove from 'array-move';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { OfferItemProjectItemStatus, OfferItemType } from '../../schema/graphql-global-types';
import { OfferItem } from '../../schema/types/OfferItem';
import FieldEditor from '../form/FieldEditor';
import { cloneDeep } from 'lodash';
import OfferEditorProduct from '../offer/OfferEditorProduct';
import OfferEditorProject from '../offer/OfferEditorProject';
import DocumentChatGPTForm from './DocumentChatGPTForm';

type Props = {
  title: string;
  index: number;
  model: OfferItem;
};

const DocumentationEditorItem: FunctionComponent<Props> = ({ title, index, model }) => {
  return (
    <Field name="elements">
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleSortProduct = (index: number) => (result: any) => {
          const productItems: any = cloneDeep(field.value[index].item.productItems);
          form.setFieldValue(
            `elements[${index}].item.productItems`,
            arrayMove(productItems, result.source.index, result.destination.index),
          );
        };

        const handleSortProject = (index: number) => (result: any) => {
          const projectItems: any = cloneDeep(field.value[index].item.projectItems);
          form.setFieldValue(
            `elements[${index}].item.projectItems`,
            arrayMove(projectItems, result.source.index, result.destination.index),
          );
        };

        const handleRemoveProduct = (index: number) => (index2: number) => () => {
          const productItems: any = cloneDeep(field.value[index].item.productItems);
          productItems.splice(index2, 1);
          form.setFieldValue(`elements[${index}].item.productItems`, productItems);
        };

        const handleRemoveProject = (index: number) => (index2: number) => () => {
          const projectItems: any = cloneDeep(field.value[index].item.projectItems);
          projectItems.splice(index2, 1);
          form.setFieldValue(`elements[${index}].item.projectItems`, projectItems);
        };

        const handleAddProduct = (index: number) => (value: any) => {
          const productItems: any = cloneDeep(field.value[index].item.productItems);
          productItems.push({
            title: value.value.title,
            pieces: 1,
            price: value.value.price,
            discountPercent: 0,
            product: {
              id: value.value.id,
              title: value.value.title,
            },
            productId: value.value.id,
          });
          form.setFieldValue(`elements[${index}].item.productItems`, productItems);
        };

        const handleAddProject = (index: number) => (value: any) => {
          const projectItems: any = cloneDeep(field.value[index].item.projectItems);
          projectItems.push({
            description: '',
            startDate: new Date(),
            endDate: new Date(),
            status: OfferItemProjectItemStatus.Open,
            user: {
              id: value.value.id,
              fullName: value.value.fullName,
              avatar: value.value.avatar,
            },
            userId: value.value.id,
          });
          form.setFieldValue(`elements[${index}].item.projectItems`, projectItems);
        };

        return (
          <div className="form-group">
            {model.type === OfferItemType.Product ? (
              <OfferEditorProduct
                model={model}
                name={`elements[${index}].item`}
                onSort={handleSortProduct(index)}
                onAdd={handleAddProduct(index)}
                onRemove={handleRemoveProduct(index)}
              />
            ) : model.type === OfferItemType.Project ? (
              <OfferEditorProject
                model={model}
                name={`elements[${index}].item`}
                onSort={handleSortProject(index)}
                onAdd={handleAddProject(index)}
                onRemove={handleRemoveProject(index)}
              />
            ) : (
              <>
                <FieldEditor name={`elements[${index}].item.content`} />
                <DocumentChatGPTForm
                  title={title}
                  onChange={(val) => form.setFieldValue(`elements[${index}].item.content`, val)}
                />
              </>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default DocumentationEditorItem;
