import { Trans } from '@lingui/macro';
import arrayMove from 'array-move';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ContractProduct } from '../../schema/types/ContractProduct';
import CurrencyDisplay from '../common/CurrencyDisplay';
import ContractInfoFormItem from './ContractInfoFormItem';
import ContractInfoSearch from './ContractInfoSearch';
import { cloneDeep } from 'lodash';

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  display: isDragging ? 'table' : undefined,
  ...draggableStyle,
});

type Props = {};

const ContractInfoForm: FunctionComponent<Props> = () => {
  return (
    <div className="contract-detail-form">
      <div className="history-item form-group">
        <label>
          <Trans>Product</Trans>
        </label>

        <ContractInfoSearch name="products" reportingName="reportingProducts" />
      </div>

      <Field name="products">
        {({ field, form }: { field: any; form: FormikProps<any> }) => {
          const handleRemove = (index: number) => () => {
            const reportingProducts = form.values.reportingProducts.filter(
              (el: any) =>
                !(
                  (el.virtualProductId && el.virtualProductId === field.value[index].virtualProductId) ||
                  (el.threadId && el.threadId === field.value[index].threadId)
                ),
            );
            field.value.splice(index, 1);

            form.setFieldValue('products', field.value);
            form.setFieldValue('reportingProducts', reportingProducts);
          };

          if (!field.value || !field.value.length) return '';

          const subtotal = field.value.reduce((value: number, el: ContractProduct) => {
            return el.price * el.pieces + value;
          }, 0);

          const total = field.value.reduce((value: number, el: ContractProduct) => {
            return (el.price - (el.price * el.discountPercent) / 100) * el.pieces + value;
          }, 0);

          const handleSort = (result: any) => {
            const productItems: any = cloneDeep(field.value);
            form.setFieldValue('products', arrayMove(productItems, result.source.index, result.destination.index));
          };

          return (
            <div className="document-product-table table-responsive">
              <table className="custom-table">
                <thead className="custom-table-header">
                  <tr>
                    <th className="column title">
                      <Trans>Product</Trans>
                    </th>
                    <th className="column number">
                      <Trans>Price</Trans>
                    </th>
                    <th className="column number">
                      <Trans>Pieces</Trans>
                    </th>
                    <th className="column number">
                      <Trans>Discount %</Trans>
                    </th>
                    <th className="column total">
                      <Trans>Total</Trans>
                    </th>
                    <th className="column action" />
                    <th className="column handle" />
                  </tr>
                </thead>

                <DragDropContext onDragEnd={handleSort}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <tbody {...provided.droppableProps} ref={provided.innerRef}>
                        {field.value &&
                          field.value.map((el: any, index: number) => (
                            <Draggable key={index} draggableId={String(index)} index={index}>
                              {(provided, snapshot) => (
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                  className="custom-table-body"
                                >
                                  <ContractInfoFormItem
                                    key={index}
                                    name={`products[${index}]`}
                                    onRemove={handleRemove(index)}
                                  />
                                  <td className="column handle" {...provided.dragHandleProps}>
                                    <i className="icon-drag-and-drop" />
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              </table>

              {subtotal > 0 && (
                <table className="table text-right mt-3">
                  <tbody>
                    <tr>
                      <td>
                        <Trans>Subtotal</Trans>
                      </td>
                      <td className="total text-left">
                        <CurrencyDisplay value={subtotal} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Trans>Total Discount</Trans>
                      </td>
                      <td className="total text-left">
                        <CurrencyDisplay value={subtotal - total} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <Trans>TOTAL</Trans>
                        </strong>
                      </td>
                      <td className="total text-left">
                        <strong>
                          <CurrencyDisplay value={total} />
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          );
        }}
      </Field>
    </div>
  );
};

export default ContractInfoForm;
