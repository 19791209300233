import gql from 'graphql-tag';
import { validateRequired } from '../utils/validations';

export const DOCUMENT_TEMPLATE_ELEMENT = gql`
  fragment DocumentTemplateElement on DocumentTemplateElement {
    id
    title
    content
    public
    editor {
      id
      fullName
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
    updatedAt
  }
`;

export const DOCUMENT_TEMPLATE_ELEMENTS = gql`
  fragment DocumentTemplateElements on DocumentTemplateElementCollection {
    limit
    offset
    total
    items {
      ...DocumentTemplateElement
    }
  }
  ${DOCUMENT_TEMPLATE_ELEMENT}
`;

export const GET_DOCUMENT_TEMPLATE_ELEMENTS = gql`
  query getDocumentTemplateElements($query: DocumentTemplateElementsQuery!) {
    documentTemplateElements(query: $query) {
      ...DocumentTemplateElements
    }
  }
  ${DOCUMENT_TEMPLATE_ELEMENTS}
`;

export const DOCUMENT_TEMPLATE_ELEMENT_DETAIL = gql`
  fragment DocumentTemplateElementDetail on DocumentTemplateElement {
    ...DocumentTemplateElement
  }
  ${DOCUMENT_TEMPLATE_ELEMENT}
`;

export const GET_DOCUMENT_TEMPLATE_ELEMENT = gql`
  query getDocumentTemplateElement($query: ID!) {
    documentTemplateElement(id: $query) {
      ...DocumentTemplateElementDetail
    }
  }
  ${DOCUMENT_TEMPLATE_ELEMENT_DETAIL}
`;

export const CREATE_DOCUMENT_TEMPLATE_ELEMENT = gql`
  mutation createDocumentTemplateElement($payload: CreateDocumentTemplateElementPayload!) {
    createDocumentTemplateElement(payload: $payload) {
      documentTemplateElement {
        ...DocumentTemplateElementDetail
      }
    }
  }
  ${DOCUMENT_TEMPLATE_ELEMENT_DETAIL}
`;

export const UPDATE_DOCUMENT_TEMPLATE_ELEMENT = gql`
  mutation updateDocumentTemplateElement($id: ID!, $payload: UpdateDocumentTemplateElementPayload!) {
    updateDocumentTemplateElement(id: $id, payload: $payload) {
      documentTemplateElement {
        ...DocumentTemplateElementDetail
      }
    }
  }
  ${DOCUMENT_TEMPLATE_ELEMENT_DETAIL}
`;

export const ARCHIVE_DOCUMENT_TEMPLATE_ELEMENT = gql`
  mutation archiveDocumentTemplateElement($id: ID!) {
    archiveDocumentTemplateElement(id: $id) {
      documentTemplateElement {
        id
      }
    }
  }
`;

export const UNARCHIVE_DOCUMENT_TEMPLATE_ELEMENT = gql`
  mutation unarchiveDocumentTemplateElement($id: ID!) {
    unarchiveDocumentTemplateElement(id: $id) {
      documentTemplateElement {
        id
      }
    }
  }
`;

export const validateDocumentTemplateElementForm = {
  title: validateRequired,
};
