import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';

type Props = {
  onClick?: () => void;
};

const ButtonDelete: FunctionComponent<Props> = ({ onClick }) => {
  const key = Math.random();

  return (
    <>
      <span data-tip data-for={`delete-${key}`} className="button button-delete text-xs" onClick={onClick}>
        <i className="icon-delete" />
      </span>
      <ReactTooltip id={`delete-${key}`}>
        <Trans>Delete</Trans>
      </ReactTooltip>
    </>
  );
};

export default ButtonDelete;
