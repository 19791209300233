import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { GET_COMPANIES_FOR_OFFER } from '../../schema/company';
import { getCompanies, getCompaniesVariables } from '../../schema/types/getCompanies';
import { OfferCompanies } from '../../schema/types/OfferCompanies';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {};

const OfferCustomerForm: FunctionComponent<Props> = () => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getCompanies, getCompaniesVariables>(GET_COMPANIES_FOR_OFFER, {
    variables,
    fetchPolicy: 'cache-first',
  });

  if (!data && !loading) return <div />;

  return (
    <Field name="company">
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (value: any) => {
          const company: OfferCompanies = value.value;

          form.setFieldValue('company', company);
          form.setFieldValue('companyId', company.id);

          if (company.primaryPerson) {
            form.setFieldValue('firstName', company.primaryPerson.firstName || '');
            form.setFieldValue('lastName', company.primaryPerson.lastName || '');
            form.setFieldValue('function', company.primaryPerson.function || '');
            form.setFieldValue('email', company.primaryPerson.email || '');
          }

          if (company.responsibleUsers && company.responsibleUsers[0]) {
            form.setFieldValue('responsibleUsers[0]', company.responsibleUsers[0]);
            form.setFieldValue('responsibleUserIds[0]', company.responsibleUsers[0].id);
          }

          if (company.responsibleUsers && company.responsibleUsers[1]) {
            form.setFieldValue('responsibleUsers[1]', company.responsibleUsers[1]);
            form.setFieldValue('responsibleUserIds[1]', company.responsibleUsers[1].id);
          }
        };

        const handleLoad = (value: string) => search$.next(value);

        const options = loading
          ? []
          : data?.companies.items.map((el) => ({
              value: el,
              label: el.name,
            })) || [];

        const current = !field.value
          ? null
          : {
              value: field.value,
              label: field.value.name,
            };

        return (
          <Select
            value={current}
            options={options}
            isLoading={loading}
            onChange={handleChange}
            onInputChange={handleLoad}
            placeholder={i18n._(t`Choose Customer`)}
            {...selectOptions}
          />
        );
      }}
    </Field>
  );
};

export default OfferCustomerForm;
