import { useQuery } from '@apollo/client';
import React, { FunctionComponent } from 'react';
import { GET_ASSET_LOG_TAGS } from '../../schema/tag';
import { getAssetLogTags, getAssetLogTagsVariables } from '../../schema/types/getAssetLogTags';
import { useVariables } from '../../utils/hooks';
import TagSelect from '../form/TagSelect';

type Props = {};

const AssetLogForm: FunctionComponent<Props> = () => {
  const { search$, search, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
      },
    },
  };

  const { data, loading } = useQuery<getAssetLogTags, getAssetLogTagsVariables>(GET_ASSET_LOG_TAGS, {
    variables,
  });

  if (!data && !loading) return <div />;

  return <TagSelect name="manualLogTagNames" search$={search$} loading={loading} data={data?.assetLogTags.items} />;
};

export default AssetLogForm;
