import { Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import {
  HttpHeaderPayload,
  HttpStatusMode,
  HttpStatusPayload,
} from '../../schema/graphql-global-types';
import ButtonDelete from '../form/ButtonDelete';

type Props = {};

const AssetMonitorHTTPForm: FunctionComponent<Props> = () => {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Username</Trans>
          </label>
          <Field type="text" className="form-control" name="http.username" />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Password</Trans>
          </label>
          <Field type="text" className="form-control" name="http.password" />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Headers</Trans>
          </label>

          <Field name="http.headers">
            {({ field, form }: { field: any; form: FormikProps<any> }) => {
              const handleAdd = () => {
                const values: HttpHeaderPayload[] = form.values.http.headers;
                values.push({
                  key: '',
                  value: '',
                });

                form.setFieldValue('http.headers', values);
              };

              const handleDelete = (index: number) => () => {
                const values: HttpHeaderPayload[] = form.values.http.headers;
                values.splice(index, 1);

                form.setFieldValue('http.headers', values);
              };

              return (
                <>
                  {field.value &&
                    field.value.map((el: any, index: number) => (
                      <React.Fragment key={index}>
                        <div className="row">
                          <div className="col-5">
                            <div className="form-group">
                              <label>
                                <Trans>Key</Trans>
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                name={`http.headers[${index}].key`}
                              />
                            </div>
                          </div>

                          <div className="col-5">
                            <div className="form-group">
                              <label>
                                <Trans>Value</Trans>
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                name={`http.headers[${index}].value`}
                              />
                            </div>
                          </div>

                          <div className="col-2 d-flex align-items-center pt-3">
                            <ButtonDelete onClick={handleDelete(index)} />
                          </div>
                        </div>
                      </React.Fragment>
                    ))}

                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-info btn-sm"
                      onClick={handleAdd}
                    >
                      <Trans>Add Header</Trans>
                    </button>
                  </div>
                </>
              );
            }}
          </Field>
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Statuses</Trans>
          </label>

          <Field name="http.statuses">
            {({ field, form }: { field: any; form: FormikProps<any> }) => {
              const handleAdd = () => {
                const values: HttpStatusPayload[] = form.values.http.statuses;
                values.push({
                  status: 200,
                  mode: HttpStatusMode.Include,
                });

                form.setFieldValue('http.statuses', values);
              };

              const handleDelete = (index: number) => () => {
                const values: HttpHeaderPayload[] = form.values.http.statuses;
                values.splice(index, 1);

                form.setFieldValue('http.statuses', values);
              };

              return (
                <>
                  {field.value &&
                    field.value.map((el: any, index: number) => (
                      <React.Fragment key={index}>
                        <div className="row">
                          <div className="col-5">
                            <div className="form-group">
                              <label>
                                <Trans>Status</Trans>
                              </label>
                              <Field
                                type="number"
                                className="form-control"
                                name={`http.statuses[${index}].status`}
                              />
                            </div>
                          </div>

                          <div className="col-5">
                            <div className="form-group">
                              <label>
                                <Trans>Mode</Trans>
                              </label>
                              <Field
                                component="select"
                                className="form-control"
                                name={`http.statuses[${index}].mode`}
                              >
                                {Object.keys(HttpStatusMode).map(
                                  (el: any, index: number) => (
                                    <option key={index} value={el}>
                                      {el}
                                    </option>
                                  )
                                )}
                              </Field>
                            </div>
                          </div>

                          <div className="col-2 d-flex align-items-center pt-3">
                            <ButtonDelete onClick={handleDelete(index)} />
                          </div>
                        </div>
                      </React.Fragment>
                    ))}

                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-info btn-sm"
                      onClick={handleAdd}
                    >
                      <Trans>Add Status</Trans>
                    </button>
                  </div>
                </>
              );
            }}
          </Field>
        </div>
      </div>
    </div>
  );
};

export default AssetMonitorHTTPForm;
