import classnames from 'classnames';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { createStorageObject } from '../../schema/types/createStorageObject';
import { CREATE_STORAGE_OBJECT, createStorageObjectVariables } from '../../schema/upload';
import { cloneDeep } from 'lodash';

type Props = {
  files?: any[] | false;
  onUpload: (storageObject: any) => void;
  onRemove: (index: any) => void;
};

const MultipleFileUploader: FunctionComponent<Props> = ({ files, onUpload, onRemove }) => {
  const [preview, setPreview] = useState<any[]>([]);

  useEffect(() => {
    setPreview([]);
  }, [files]);

  const [mutate] = useMutation<createStorageObject, createStorageObjectVariables>(CREATE_STORAGE_OBJECT);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const upload = async (files: File[]) => {
        for (let index = 0; index < files.length; index += 1) {
          const file = files[index];
          const reader = new FileReader();

          reader.readAsDataURL(file);

          try {
            const response = await mutate({
              variables: {
                payload: {
                  filename: file.name,
                  mime: file.type,
                },
              },
            });

            await new Promise((resolve) => {
              const xhr = new XMLHttpRequest();

              // progress bar
              xhr.upload.addEventListener(
                'progress',
                (e: ProgressEvent) => {
                  setPreview((prevState) =>
                    prevState.map((img) => {
                      if (img.filename === response.data?.createStorageObject.storageObject.filename) {
                        img.progress = Number((e.loaded / e.total) * 100);
                      }

                      return img;
                    }),
                  );
                },
                false,
              );

              // done upload
              xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                  if (xhr.status === 200 || xhr.status === 201) {
                    setPreview((prevState) => {
                      if (
                        prevState.some(
                          (img) => img.filename === response.data?.createStorageObject.storageObject.filename,
                        )
                      ) {
                        setTimeout(() => {
                          onUpload(response.data?.createStorageObject.storageObject);
                        }, 100);
                      }

                      return prevState.filter(
                        (img) => img.filename !== response.data?.createStorageObject.storageObject.filename,
                      );
                    });
                  } else {
                    toast.error(`Unable to upload: ${response.data?.createStorageObject.storageObject.filename}`);
                  }

                  resolve(xhr.status);
                }
              };

              if (response.data) {
                xhr.open('PUT', response.data.createStorageObject.uploadUrl, true);
                response.data.createStorageObject.uploadExtraHeaders.forEach((el) => {
                  xhr.setRequestHeader(el.key, el.value);
                });
                xhr.send(acceptedFiles[index]);
              } else {
                resolve(null);
              }
            });
          } catch (e) {
            toast.error(`Unable to upload: ${file.name}`);
          }
        }
      };

      acceptedFiles.forEach(async (file: File) => {
        setPreview((prevState) => [
          ...prevState,
          {
            filename: file.name,
            progress: 1,
          },
        ]);
      });
      upload(acceptedFiles);
    },
    [mutate, setPreview, onUpload],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const totalImage = (files && files.length) || 0;

  const handleRemove = (index: number) => () => {
    if (index >= totalImage) {
      preview.splice(index - totalImage, 1);
      setPreview(cloneDeep(preview));
    }

    onRemove(index);
  };

  return (
    <div className="multiple-file-uploader">
      <div className={classnames('image-uploader', { active: isDragActive })} {...getRootProps()}>
        <input {...getInputProps({ multiple: true })} />

        <div className="image-uploader-content">
          {!isDragActive ? <Trans>Drag & drop or click</Trans> : <Trans>Drop</Trans>}
        </div>
      </div>

      <div className="images">
        {files &&
          files.map((el: any, index) => (
            <div key={index} className="image">
              <i className="image-icons icon-pdf" />

              <div data-tip data-for={`image-${index}`} className="image-name text-ellipsis">
                {el.filename}
              </div>

              <ReactTooltip id={`image-${index}`}>{el.filename}</ReactTooltip>

              <button type="button" className="image-delete" onClick={handleRemove(index)}>
                <i className="icon-close" />
              </button>
            </div>
          ))}

        {preview.map((el, index) => (
          <div key={totalImage + index} className="image">
            <i className="image-icons icon-pdf" />

            <div data-tip data-for={`image-${totalImage + index}`} className="image-name text-ellipsis">
              {el.filename}
            </div>

            <ReactTooltip id={`image-${totalImage + index}`}>{el.filename}</ReactTooltip>

            {el.progress > 0 && el.progress < 99 ? (
              <div className="image-delete">
                <i className="fa fa-spinner fa-spin" />
              </div>
            ) : (
              <button type="button" className="image-delete" onClick={handleRemove(totalImage + index)}>
                <i className="icon-close" />
              </button>
            )}

            {el.progress > 0 && <div className="progress-bar" style={{ width: `${el.progress}%` }} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultipleFileUploader;
