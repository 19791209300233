import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {
  query: any;
  filters?: any;
  objectType: string;
  nameField?: string;
  onChange: (value: any) => void;
};

const FilterHeader: FunctionComponent<Props> = ({ query, filters = {}, objectType, nameField = 'name', onChange }) => {
  const { search$, search, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filters,
      },
    },
  };

  const handleChange = useCallback((value: any) => onChange(value.value ? [value.value] : ''), [onChange]);

  const { data, loading } = useQuery(query, {
    variables,
    fetchPolicy: 'cache-first',
  });

  const handleLoad = useCallback((value: string) => search$.next(value), [search$]);

  const options = loading
    ? []
    : data![objectType].items.map((el: any) => ({
        value: el.id,
        label: el[nameField],
      }));

  options.unshift({
    value: '',
    label: i18n._(t`All`),
  });

  return (
    <Select
      options={options}
      isLoading={loading}
      onChange={handleChange}
      onInputChange={handleLoad}
      placeholder={i18n._(t`All`)}
      {...selectOptions}
    />
  );
};

export default FilterHeader;
