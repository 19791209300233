import gql from 'graphql-tag';
import { CreateStorageObjectPayload } from './graphql-global-types';

export const CREATE_STORAGE_OBJECT = gql`
  mutation createStorageObject($payload: CreateStorageObjectPayload!) {
    createStorageObject(payload: $payload) {
      uploadUrl
      storageObject {
        id
        url
        filename
      }
      uploadExtraHeaders {
        key
        value
      }
    }
  }
`;

export const CREATE_DOCUMENT_ATTACHMENT = gql`
  mutation createDocumentAttachment($id: ID!, $payload: CreateStorageObjectPayload!) {
    createDocumentAttachment(id: $id, payload: $payload) {
      uploadUrl
      permanentLink
      storageObject {
        id
        url
        filename
      }
      uploadExtraHeaders {
        key
        value
      }
    }
  }
`;

export const CREATE_ASSET_ATTACHMENT = gql`
  mutation createAssetAttachment($id: ID!, $payload: CreateStorageObjectPayload!) {
    createAssetAttachment(id: $id, payload: $payload) {
      uploadUrl
      permanentLink
      storageObject {
        id
        url
        filename
      }
      uploadExtraHeaders {
        key
        value
      }
    }
  }
`;

export interface createStorageObjectVariables {
  payload: CreateStorageObjectPayload;
}
