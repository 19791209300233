import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { GET_SMS_CONFIG, UPDATE_SMS_CONFIG } from '../../schema/config';
import { SmsConfigPayload, SmsServiceType } from '../../schema/graphql-global-types';
import { getSmsConfig } from '../../schema/types/getSmsConfig';
import { updateSmsConfig, updateSmsConfigVariables } from '../../schema/types/updateSmsConfig';
import { toastErrorQuery } from '../../utils/error';
import { validateRequired } from '../../utils/validations';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';
import { merge, omit } from 'lodash';

type Props = {
  show: boolean;
  onClose: () => void;
};

const ConfigurationSmsModal: FunctionComponent<Props> = ({ show, onClose }) => {
  const { data } = useQuery<getSmsConfig>(GET_SMS_CONFIG);

  const [mutate] = useMutation<updateSmsConfig, updateSmsConfigVariables>(UPDATE_SMS_CONFIG);

  const model: SmsConfigPayload = merge(
    {
      serviceType: SmsServiceType.ClickSend,
      defaultSenderId: '',
      clickSend: {
        username: '',
        password: '',
      },
    },
    data && data.smsConfig,
  );

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>SMS Gateway</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              enableReinitialize
              initialValues={model}
              validationSchema={yup.object().shape({
                defaultSenderId: validateRequired,
                clickSend: yup.object().shape({
                  username: validateRequired,
                  password: validateRequired,
                }),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  const config: any = omit(values, ['__typename']);

                  const result: any = await mutate({
                    variables: {
                      config,
                    },
                  });

                  if (result.errors) {
                    toastErrorQuery(result.errors);
                  } else {
                    toast.success(i18n._(t`Success!`));
                    resetForm();
                    onClose();
                  }
                } catch (e) {
                  toastErrorQuery(e);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting, submitForm }) => {
                const handleSubmit = (e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  submitForm();
                };

                return (
                  <Form onSubmitCapture={handleSubmit}>
                    <div className="modal-body">
                      <div className="form-group">
                        <label>
                          <Trans>Default Sender ID</Trans>
                        </label>
                        <Field type="text" className="form-control" name="defaultSenderId" autoFocus />

                        <FieldErrorMessage name="defaultSenderId" />
                      </div>

                      <div className="form-group">
                        <label>
                          <Trans>ClickSend Username</Trans>
                        </label>
                        <Field type="text" className="form-control" name="clickSend.username" />

                        <FieldErrorMessage name="clickSend.username" />
                      </div>

                      <div className="form-group">
                        <label>
                          <Trans>ClickSend API Key</Trans>
                        </label>
                        <Field type="text" className="form-control" name="clickSend.password" />

                        <FieldErrorMessage name="clickSend.password" />
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={onClose}>
                        <Trans>Close</Trans>
                      </button>

                      <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfigurationSmsModal;
