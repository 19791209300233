import { useMutation } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FC, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { createStorageObject } from '../../schema/types/createStorageObject';
import { CREATE_STORAGE_OBJECT, createStorageObjectVariables } from '../../schema/upload';
import ImportExcludeCompaniesAzure from './ImportExcludeCompaniesAzure';
import ImportGenerateInvoiceModalAzure from './ImportGenerateInvoiceModalAzure';

type Props = unknown;

const ImportFormAzure: FC<Props> = () => {
  const [showGenerate, setShowGenerate] = useState(false);
  const toggleShowGenerate = useCallback(() => setShowGenerate((prevState) => !prevState), [setShowGenerate]);

  const [object, setObject] = useState('');

  const [mutate] = useMutation<createStorageObject, createStorageObjectVariables>(CREATE_STORAGE_OBJECT);

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const handleUpload = useCallback(
    async (e: any) => {
      const file = e.currentTarget.files[0];
      const xhr = new XMLHttpRequest();
      setLoading(true);
      setProgress(0);

      const result = await mutate({
        variables: {
          payload: {
            filename: file.name,
            mime: file.type,
          },
        },
      });

      // progress bar
      xhr.upload.addEventListener(
        'progress',
        (e: ProgressEvent) => {
          setProgress(Number((e.loaded / e.total) * 100));
        },
        false,
      );

      // done mutate
      xhr.onreadystatechange = async () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 201) {
            setObject(result.data?.createStorageObject.storageObject.id || '');
            setShowGenerate(true);
            toast.success(i18n._(t`Success!`));
          } else {
            toast.error(`Unable to upload: ${result.data?.createStorageObject.storageObject.filename}`);
          }

          setLoading(false);
          setProgress(0);
        }
      };

      if (result.data) {
        // up!
        xhr.open('PUT', result.data.createStorageObject.uploadUrl, true);
        result.data.createStorageObject.uploadExtraHeaders.forEach((el) => {
          xhr.setRequestHeader(el.key, el.value);
        });
        xhr.send(file);
      }
    },
    [mutate],
  );

  return (
    <div className="col-lg-6">
      <div className="card">
        <div className="d-flex align-items-center form-group">
          <h2 className="block-title">
            <Trans>Azure RI</Trans>
          </h2>

          {loading ? (
            <div className="col">
              <div className="btn btn-link">
                <Trans>Uploading</Trans> {progress}%
              </div>
            </div>
          ) : (
            <div className="col">
              <input
                type="file"
                id="file3"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleUpload}
                style={{ display: 'none' }}
              />
              <label className="btn btn-link" htmlFor="file3">
                <Trans>Import File</Trans>
              </label>
            </div>
          )}
        </div>

        <ImportExcludeCompaniesAzure />

        <ImportGenerateInvoiceModalAzure storageObjectId={object} show={showGenerate} onClose={toggleShowGenerate} />
      </div>
    </div>
  );
};

export default ImportFormAzure;
