import { useQuery } from '@apollo/client';
import React, { FunctionComponent } from 'react';
import { GET_PRODUCT_TAGS } from '../../schema/tag';
import { getProductTags, getProductTagsVariables } from '../../schema/types/getProductTags';
import { useVariables } from '../../utils/hooks';
import TagSelect from '../form/TagSelect';

type Props = {};

const ProductTagForm: FunctionComponent<Props> = () => {
  const { search$, search, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
      },
    },
  };

  const { data, loading } = useQuery<getProductTags, getProductTagsVariables>(GET_PRODUCT_TAGS, {
    variables,
  });

  if (!data && !loading) return <div />;

  return <TagSelect name="tagNames" search$={search$} loading={loading} data={data?.productTags.items} />;
};

export default ProductTagForm;
