import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { TicketDetail } from '../../schema/types/TicketDetail';
import { getPhone } from '../../utils/utils';

type Props = {
  model: TicketDetail;
};

const TicketInfoPopup: FunctionComponent<Props> = ({ model }) => {
  if (!model.person) return null;

  return (
    <div className="ticket-info-popup">
      {model.person.companies.length && (
        <div className="item">
          <div className="label">
            <Trans>Company</Trans>
          </div>

          <div className="value">
            <NavLink to={`/crm/company/${model.person.companies[0].company.id}`}>
              {model.person.companies[0].company.name}
            </NavLink>
          </div>
        </div>
      )}

      {model.person.companies.length && model.person.companies[0].company.generalPhone && (
        <div className="item">
          <div className="label">
            <Trans>Company Number</Trans>
          </div>

          <div className="value">
            <a href={`tel:${getPhone(model.person.companies[0].company.generalPhone)}`} className="text-break">
              {model.person.companies[0].company.generalPhone}
            </a>
          </div>
        </div>
      )}

      {model.person.cellphone && (
        <div className="item">
          <div className="label">
            <Trans>Mobile Number</Trans>
          </div>

          <div className="value">
            <a href={`tel:${getPhone(model.person.cellphone)}`} className="text-break">
              {model.person.cellphone}
            </a>
          </div>
        </div>
      )}

      {model.person.email && (
        <div className="item">
          <div className="label">
            <Trans>Email</Trans>
          </div>

          <div className="value">
            <a href={`mailto:${model.person.email}`} className="text-break">
              {model.person.email}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketInfoPopup;
