import { Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import { cloneDeep } from 'lodash';
import React, { FunctionComponent } from 'react';
import ProductReportingFormItem from './ProductReportingFormItem';
import ProductReportingSearch from './ProductReportingSearch';

type Props = {};

const ProductReportingForm: FunctionComponent<Props> = () => {
  return (
    <div className="product-reporting-form select-min-height">
      <div className="form-group history-item mb-3">
        <label>
          <Trans>Product</Trans>
        </label>

        <ProductReportingSearch name="reports" />
      </div>

      <Field name="reports">
        {({ field, form }: { field: any; form: FormikProps<any> }) => {
          const handleRemove = (index: number) => () => {
            const model: any = cloneDeep(field.value);
            model.splice(index, 1);
            form.setFieldValue('reports', model);
          };

          if (!field.value.length) return null;

          return (
            <div className="table-responsive">
              <table className="custom-table document-product-table">
                <thead className="custom-table-header">
                  <tr>
                    <th className="column title">
                      <Trans>Product</Trans>
                    </th>
                    <th className="column number">
                      <Trans>Number</Trans>
                    </th>
                    <th className="column number" />
                    <th className="column">
                      <Trans>Vendor Contract</Trans>
                    </th>
                    <th className="column action" />
                  </tr>
                </thead>

                <tbody className="custom-table-body">
                  {field.value &&
                    field.value.map((el: any, index: number) => (
                      <ProductReportingFormItem key={index} name={`reports[${index}]`} onRemove={handleRemove(index)} />
                    ))}
                </tbody>
              </table>
            </div>
          );
        }}
      </Field>
    </div>
  );
};

export default ProductReportingForm;
