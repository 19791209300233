import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Modal from 'react-modal';
import { CONFIGURE_OUTLOOK_SYSTEM_ACCOUNT, GET_OUTLOOK_SYSTEM_ACCOUNT } from '../../schema/config';
import {
  configureOutlookSystemAccount,
  configureOutlookSystemAccountVariables,
} from '../../schema/types/configureOutlookSystemAccount';
import { getOutlookSystemAccount } from '../../schema/types/getOutlookSystemAccount';
import { toastErrorQuery } from '../../utils/error';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';

type Props = {
  show: boolean;
  onClose: () => void;
};

const ConfigurationSystemMailModal: FunctionComponent<Props> = ({ show, onClose }) => {
  const outlookSystemAccount = useQuery<getOutlookSystemAccount>(GET_OUTLOOK_SYSTEM_ACCOUNT);

  const [mutate] = useMutation<configureOutlookSystemAccount, configureOutlookSystemAccountVariables>(
    CONFIGURE_OUTLOOK_SYSTEM_ACCOUNT,
  );

  const model: any = {
    email:
      (outlookSystemAccount.data &&
        outlookSystemAccount.data.outlookSystemAccount &&
        outlookSystemAccount.data.outlookSystemAccount.email) ||
      '',
  };

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>System Mail Connector</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              enableReinitialize
              initialValues={model}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const result: any = await mutate({
                    variables: {
                      payload: {
                        redirectUri: `${window.location.href}?success=1`,
                      },
                    },
                  });

                  if (result.errors) {
                    toastErrorQuery(result.errors);
                  } else {
                    window.location.href = result.data.configureOutlookSystemAccount;
                  }
                } catch (e) {
                  toastErrorQuery(e);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting, submitForm }) => {
                const handleSubmit = (e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  submitForm();
                };

                return (
                  <Form onSubmitCapture={handleSubmit}>
                    <div className="modal-body">
                      <div className="form-group">
                        <label>
                          <Trans>Email</Trans>
                        </label>
                        <Field type="text" className="form-control" name="email" autoFocus />

                        <FieldErrorMessage name="email" />
                      </div>

                      <div className="form-group">
                        <Trans>You will be redirected to Microsoft.</Trans>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={onClose}>
                        <Trans>Close</Trans>
                      </button>

                      <ButtonSubmit className="btn btn-primary" title={i18n._(t`Go`)} loading={isSubmitting} />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfigurationSystemMailModal;
