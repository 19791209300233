import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-modal';
import { DocumentVisibility } from '../../schema/graphql-global-types';

const getText = (value: string) => {
  switch (value) {
    case DocumentVisibility.Internal:
      return '';
    case DocumentVisibility.Public:
      return i18n._(t`Everybody without login`);
    case DocumentVisibility.AllCustomers:
      return i18n._(t`All customers`);
    case DocumentVisibility.SelectedCustomers:
      return i18n._(t`Specific customers`);
    default:
      return '';
  }
};

type Props = {};

const DocumentationVisibilityForm: FunctionComponent<Props> = () => {
  const [show, setShow] = useState(false);
  const [model, setModel] = useState('');

  return (
    <Field name="visibility">
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (e: any) => {
          if (getText(e.target.value)) {
            setModel(e.target.value);
            toggleModal();
          } else {
            form.setFieldValue('visibility', e.target.value);
          }
        };

        const toggleModal = () => setShow((prevState) => !prevState);

        const handleSubmit = () => {
          form.setFieldValue('visibility', model);
          toggleModal();
        };

        return (
          <>
            <select className="form-control" value={field.value} onChange={handleChange}>
              <option value="Internal">{i18n._(t`Internal`)}</option>
              <option value="SelectedCustomers">{i18n._(t`Selected Customers`)}</option>
              <option value="AllCustomers">{i18n._(t`All Customers`)}</option>
              <option value="Public">{i18n._(t`Public`)}</option>
            </select>

            <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
              <div className="modal fade show" style={{ display: 'block' }}>
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h2 className="modal-title">
                        <Trans>Are you sure?</Trans>
                      </h2>
                      <button type="button" className="close" aria-label="Close" onClick={toggleModal}>
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>

                    <div className="modal-body text-danger text-center">
                      <Trans>Are you sure to display this information to</Trans>
                      &nbsp;
                      {getText(model)}
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={toggleModal}>
                        <Trans>No</Trans>
                      </button>

                      <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                        <Trans>Yes</Trans>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </>
        );
      }}
    </Field>
  );
};

export default DocumentationVisibilityForm;
