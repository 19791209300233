import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import Modal from 'react-modal';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { CREATE_CONTRACT } from '../../schema/contract';
import { ContractCycle, CreateContractPayload, ProductBilling } from '../../schema/graphql-global-types';
import { ARCHIVE_OFFER, GET_OFFER_FOR_CONTRACT } from '../../schema/offer';
import { archiveOffer, archiveOfferVariables } from '../../schema/types/archiveOffer';
import { createContract, createContractVariables } from '../../schema/types/createContract';
import { getOfferForContract, getOfferForContractVariables } from '../../schema/types/getOfferForContract';
import {
  OfferContract,
  OfferContract_items,
  OfferContract_items_productItems,
  OfferContract_items_productItems_product_reports,
} from '../../schema/types/OfferContract';
import { DefaultRouteParams } from '../../utils/const';
import { toastErrorQuery } from '../../utils/error';
import { setSession } from '../../utils/session';

type Props = {
  model: any;
  show: boolean;
  onClose: () => void;
};

const now = new Date();
const next =
  now.getMonth() === 11
    ? new Date(Date.UTC(now.getFullYear() + 1, 0, 1))
    : new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 1));

const OfferConvertModal: FunctionComponent<Props & RouteComponentProps<DefaultRouteParams>> = ({
  model,
  show,
  onClose,
  history,
}) => {
  const [loading, setLoading] = useState(false);

  const [mutate] = useMutation<createContract, createContractVariables>(CREATE_CONTRACT);

  const [mutateArchive] = useMutation<archiveOffer, archiveOfferVariables>(ARCHIVE_OFFER);

  const client = useApolloClient();

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    const result = await client.query<getOfferForContract, getOfferForContractVariables>({
      query: GET_OFFER_FOR_CONTRACT,
      variables: {
        query: model.id,
      },
      fetchPolicy: 'network-only',
    });

    const offer: OfferContract = result.data.offer;

    const contract: CreateContractPayload = {
      name: offer.title,
      customerId: offer.company ? offer.company.id : '',
      cycle: ContractCycle.Monthly,
      yearlyInterval: now.getMonth() === 11 ? 0 : now.getMonth() + 1,
      startedAt: next.toISOString(),
      adjustmentDate: next.toISOString(),
      products: [],
      reportingProducts: [],
    };

    let yearly = 0;
    let monthly = 0;
    let type = ProductBilling.Monthly;
    const notAdded: any[] = [];

    offer.items!.forEach((item: OfferContract_items) => {
      item.productItems!.forEach((product: OfferContract_items_productItems) => {
        if (product.product.billing === ProductBilling.Yearly) {
          yearly++;
        } else if (product.product.billing === ProductBilling.Monthly) {
          monthly++;
        }
      });
    });

    if (yearly > monthly) {
      contract.cycle = ContractCycle.Yearly;
      type = ProductBilling.Yearly;
    }

    offer.items!.forEach((item: OfferContract_items) => {
      item.productItems!.forEach((product: OfferContract_items_productItems) => {
        if (product.product.billing !== type) {
          notAdded.push({
            id: product.product.id,
            title: product.product.title,
          });
          return;
        }

        contract.products!.push({
          productId: product.product.id,
          title: product.title,
          pieces: product.pieces,
          price: product.price,
          discountPercent: product.discountPercent,
        });

        product.product.reports.forEach((report: OfferContract_items_productItems_product_reports) => {
          contract.reportingProducts!.push({
            productId: report.product.id,
            pieces: product.pieces,
            contractTypeId: report.contractType.id,
            parentIndex: contract.products!.length - 1,
          });
        });
      });
    });

    try {
      const done: any = await mutate({
        variables: {
          payload: contract,
        },
      });

      if (done.errors) {
        toastErrorQuery(done.errors);

        setLoading(false);
      } else {
        const archive: any = await mutateArchive({
          variables: {
            id: model.id,
          },
        });

        if (archive.errors) {
          toastErrorQuery(archive.errors);

          setLoading(false);
        } else {
          setSession('notAdded', notAdded);
          toast.success(i18n._(t`Success!`));
          history.push(`/crm/contract/${done.data.createContract.contract.id}`);
        }
      }
    } catch (e) {
      toastErrorQuery(e);

      setLoading(false);
    }
  }, [setLoading, client, mutate, mutateArchive, history, model]);

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Are you sure want to create a contract from this offer?</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body text-danger">
              <Trans>This offer will be archived. A new contract will be created from this offer.</Trans>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-link" onClick={onClose} disabled={loading}>
                {loading ? <i className="fa fa-spinner fa-spin" /> : <Trans>Close</Trans>}
              </button>

              <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={loading}>
                {loading ? <i className="fa fa-spinner fa-spin" /> : <Trans>Convert Offer</Trans>}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withRouter(OfferConvertModal);
