import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import Modal from 'react-modal';

type Props = {
  show: boolean;
  loading: boolean;
  url?: string | null;
  onSubmit: () => void;
  onClose: () => void;
};

const ReportDownloadModal: FunctionComponent<Props> = ({ show, loading, url, onSubmit, onClose }) => {
  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                {url ? (
                  <i className="icon-done text-success text-xl" />
                ) : !loading ? (
                  <Trans>Download</Trans>
                ) : (
                  <i className="fa fa-spinner fa-spin" />
                )}
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              {loading && (
                <div className="block-title">
                  <Trans>Please, wait a second</Trans>
                </div>
              )}

              {url ? (
                <>
                  <div className="block-title">
                    <Trans>Excel generated</Trans>
                  </div>

                  <a href={url} className="btn btn-primary" target="_blank" download>
                    <Trans>Download</Trans>
                  </a>
                </>
              ) : (
                <Trans>It will take a while to generate Excel.</Trans>
              )}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-link" onClick={onClose}>
                <Trans>Close</Trans>
              </button>

              <button type="button" className="btn btn-primary" disabled={loading} onClick={onSubmit}>
                {loading ? <i className="fa fa-spinner fa-spin" /> : <Trans>Generate</Trans>}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReportDownloadModal;
