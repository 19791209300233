import gql from 'graphql-tag';

export const TAG = gql`
  fragment Tag on Tag {
    id
    name
  }
`;

export const GET_COMPANY_TAGS = gql`
  query getCompanyTags($query: TagsQuery!) {
    companyTags(query: $query) {
      items {
        ...Tag
      }
    }
  }
  ${TAG}
`;

export const GET_PRODUCT_TAGS = gql`
  query getProductTags($query: TagsQuery!) {
    productTags(query: $query) {
      items {
        ...Tag
      }
    }
  }
  ${TAG}
`;

export const GET_ASSET_TAGS = gql`
  query getAssetTags($query: TagsQuery!) {
    assetTags(query: $query) {
      items {
        ...Tag
      }
    }
  }
  ${TAG}
`;

export const GET_DOCUMENT_TAGS = gql`
  query getDocumentTags($query: DocumentTagsQuery!) {
    documentTags(query: $query) {
      items {
        ...Tag
      }
    }
  }
  ${TAG}
`;

export const GET_PERSON_TAGS = gql`
  query getPersonTags($query: TagsQuery!) {
    personTags(query: $query) {
      items {
        ...Tag
      }
    }
  }
  ${TAG}
`;

export const GET_OFFER_TAGS = gql`
  query getOfferTags($query: TagsQuery!) {
    offerTags(query: $query) {
      items {
        ...Tag
      }
    }
  }
  ${TAG}
`;

export const GET_USER_TAGS = gql`
  query getUserTags($query: TagsQuery!) {
    userTags(query: $query) {
      items {
        ...Tag
      }
    }
  }
  ${TAG}
`;

export const GET_ASSET_LOCATION_TAGS = gql`
  query getAssetLocationTags($query: TagsQuery!) {
    assetLocationTags(query: $query) {
      items {
        ...Tag
      }
    }
  }
  ${TAG}
`;

export const GET_ASSET_TYPE_TAGS = gql`
  query getAssetTypeTags($query: TagsQuery!) {
    assetTypeTags(query: $query) {
      items {
        ...Tag
      }
    }
  }
  ${TAG}
`;

export const GET_LOG_TAGS = gql`
  query getLogTags($query: TagsQuery!) {
    logTags(query: $query) {
      items {
        ...Tag
      }
    }
  }
  ${TAG}
`;

export const GET_ASSET_LOG_TAGS = gql`
  query getAssetLogTags($query: TagsQuery!) {
    assetLogTags(query: $query) {
      items {
        ...Tag
      }
    }
  }
  ${TAG}
`;
