import { Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import excel from '../../img/excel.svg';
import image from '../../img/image.svg';
import pdf from '../../img/pdf.svg';
import word from '../../img/word.svg';
import { getMessages_messages_items } from '../../schema/types/getMessages';
import { dateTimeOptions } from '../../utils/const';
import ButtonDelete from '../form/ButtonDelete';
import DateFormat from '../common/DateFormat';
import UserAvatarName from '../common/UserAvatarName';

const getImage = (filename: string) => {
  const ext = filename.substring(filename.lastIndexOf('.') + 1);

  switch (ext) {
    case 'pdf':
      return pdf;
    case 'xls':
    case 'xlsx':
      return excel;
    case 'doc':
    case 'docx':
      return word;
    default:
      return image;
  }
};

const MAX = 5;

type Props = {
  message: getMessages_messages_items;
  attachments?: any[] | null;
  onRemove?: () => void;
};

export const HomeMessageItem: FunctionComponent<Props> = ({ message, attachments, onRemove }) => {
  const { creator, createdAt, content, products, companies, categories } = message;

  const [more, setMore] = useState(false);
  const toggleMore = useCallback(() => setMore((prevState) => !prevState), []);

  const customers = useMemo(() => (companies ? (more ? companies : companies.slice(0, MAX)) : []), [companies, more]);

  return (
    <div className="history-item list-group-item">
      <div className="meta">
        <div className="creator">{creator && <UserAvatarName user={creator} />}</div>

        <div className="actions">
          <div className="ml-2 date">
            <DateFormat value={new Date(createdAt)} format={dateTimeOptions} />
          </div>

          {onRemove && (
            <div className="ml-2">
              <ButtonDelete onClick={onRemove} />
            </div>
          )}
        </div>
      </div>

      <div className="tags">
        <div className="mr-2">
          <Trans>Customers:</Trans>
        </div>

        {message.allCustomers
          ? 'All'
          : customers.map((el) => (
              <div key={el.id} className="badge">
                {el.name}
              </div>
            ))}

        {!message.allCustomers && companies && companies.length > MAX && (
          <a className="text-primary m-1" onClick={toggleMore}>
            {more ? <Trans>View less</Trans> : <Trans>View more</Trans>}
          </a>
        )}
      </div>

      {products && products.length > 0 && (
        <div className="tags">
          <div className="mr-2">
            <Trans>Products:</Trans>
          </div>

          {products.map((el) => (
            <div key={el.id} className="badge">
              {el.title}
            </div>
          ))}
        </div>
      )}

      {categories && categories.length > 0 && (
        <div className="tags">
          <div className="mr-2">
            <Trans>Categories:</Trans>
          </div>

          {categories.map((el) => (
            <div key={el.id} className="badge">
              {el.name}
            </div>
          ))}
        </div>
      )}

      <div className="text text-break">
        <div className="post-content history-content">{content}</div>

        {attachments && (
          <div className="attachments">
            {attachments.map((el) => (
              <a href={el.url} key={el.id} target="_blank" className="attachment">
                <img src={getImage(el.filename)} alt="" className="attachment-icons" />

                <div data-tip data-for={`attachment-${el.id}`} className="attachment-name text-ellipsis">
                  {el.filename}
                </div>

                <ReactTooltip id={`attachment-${el.id}`}>{el.filename}</ReactTooltip>
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
