import gql from 'graphql-tag';
import { validateRequired } from '../utils/validations';
import { LOGS } from './log';

export const TICKET = gql`
  fragment Ticket on Ticket {
    id
    opener
    lastSender
    subject
    company {
      id
      name
    }
    openedAt
    closedAt
    lastEditor {
      id
      fullName
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
    person {
      id
      isVIP
      companies {
        company {
          id
          name
        }
      }
    }
  }
`;

export const TICKETS = gql`
  fragment Tickets on TicketCollection {
    limit
    offset
    total
    items {
      ...Ticket
    }
  }
  ${TICKET}
`;

export const GET_TICKETS = gql`
  query getTickets($query: TicketsQuery!) {
    tickets(query: $query) {
      ...Tickets
    }
  }
  ${TICKETS}
`;

export const PERSON_GET_TICKETS = gql`
  query personGetTickets($id: ID!, $query: TicketsQuery!) {
    company(id: $id) {
      id
      tickets(query: $query) {
        limit
        offset
        total
        items {
          id
          opener
          lastSender
          subject
          body
          status
          inProgressSubStatus
          lastActiveTime
        }
      }
    }
  }
`;

export const TICKET_DETAIL = gql`
  fragment TicketDetail on Ticket {
    ...Ticket
    cc
    subject
    body
    status
    inProgressSubStatus
    lastActiveTime
    isFromMsTeams
    logs(query: { limit: 20 }) {
      ...Logs
    }
    person {
      id
      fullName
      cellphone
      email
      companies {
        company {
          id
          name
          generalPhone
        }
      }
    }
  }
  ${TICKET}
  ${LOGS}
`;

export const TICKET_DETAIL_NO_LOGS = gql`
  fragment TicketDetailNoLogs on Ticket {
    ...Ticket
    cc
    subject
    body
    status
    inProgressSubStatus
    lastActiveTime
    isFromMsTeams
    person {
      id
      fullName
      cellphone
      email
      companies {
        company {
          id
          name
          generalPhone
        }
      }
    }
  }
  ${TICKET}
`;

export const TICKET_LOGS = gql`
  fragment TicketLogs on Ticket {
    id
    logs(query: { limit: 20 }) {
      ...Logs
    }
  }
  ${LOGS}
`;

export const GET_TICKET = gql`
  query getTicket($query: ID!) {
    ticket(id: $query) {
      ...TicketDetail
    }
  }
  ${TICKET_DETAIL}
`;

export const GET_TICKET_LOGS = gql`
  query getTicketLogs($query: ID!) {
    ticket(id: $query) {
      ...TicketLogs
    }
  }
  ${TICKET_LOGS}
`;

export const PERSON_GET_TICKET = gql`
  query personGetTicket($id: ID!, $query: ID!) {
    company(id: $id) {
      id
      ticket(id: $query) {
        id
        subject
        status
        inProgressSubStatus
        logs(query: { limit: 20 }) {
          ...Logs
        }
      }
    }
  }
  ${LOGS}
`;

export const GET_TICKETS_WITH_DETAIL = gql`
  query getTicketsWithDetail($query: TicketsQuery!) {
    tickets(query: $query) {
      limit
      offset
      total
      items {
        ...TicketDetailNoLogs
      }
    }
  }
  ${TICKET_DETAIL_NO_LOGS}
`;

export const CREATE_TICKET = gql`
  mutation createTicket($payload: CreateTicketMessagePayload!) {
    createTicket(payload: $payload) {
      ticket {
        ...TicketDetail
      }
    }
  }
  ${TICKET_DETAIL}
`;

export const CREATE_INTERNAL_TICKET = gql`
  mutation createInternalTicket($payload: CreateInternalTicketPayload!) {
    createInternalTicket(payload: $payload) {
      ticket {
        ...TicketDetail
      }
    }
  }
  ${TICKET_DETAIL}
`;

export const UPDATE_INTERNAL_TICKET = gql`
  mutation updateInternalTicket($id: ID!, $payload: UpdateInternalTicketPayload!) {
    updateInternalTicket(id: $id, payload: $payload) {
      ticket {
        ...TicketDetail
      }
    }
  }
  ${TICKET_DETAIL}
`;

export const ARCHIVE_TICKET = gql`
  mutation archiveTicket($id: ID!) {
    archiveTicket(id: $id) {
      ticket {
        id
      }
    }
  }
`;

export const UNARCHIVE_TICKET = gql`
  mutation unarchiveTicket($id: ID!) {
    unarchiveTicket(id: $id) {
      ticket {
        id
      }
    }
  }
`;

export const SEND_TICKET_SMS = gql`
  mutation sendTicketSms($id: ID!, $payload: SendTicketSmsPayload!) {
    sendTicketSms(id: $id, payload: $payload)
  }
`;

export const SEND_TICKET_MESSAGE = gql`
  mutation sendTicketMessage($id: ID!, $payload: SendTicketMessagePayload!) {
    sendTicketMessage(id: $id, payload: $payload)
  }
`;

export const UPDATE_TICKET_RESPONSIBLE_USER = gql`
  mutation updateTicketResponsibleUser($id: ID!, $payload: UpdateTicketResponsibleUserPayload!) {
    updateTicketResponsibleUser(id: $id, payload: $payload) {
      ticket {
        id
      }
    }
  }
`;

export const ADD_TICKET_INTERNAL_NOTE = gql`
  mutation addTicketInternalNote($id: ID!, $payload: CreateTicketMessagePayload!) {
    addTicketInternalNote(id: $id, payload: $payload)
  }
`;

export const CLOSE_TICKET = gql`
  mutation closeTicket($id: ID!, $payload: CloseTicketPayload!) {
    closeTicket(id: $id, payload: $payload)
  }
`;

export const REOPEN_TICKET = gql`
  mutation reopenTicket($id: ID!) {
    reopenTicket(id: $id)
  }
`;

export const validateTicketForm = {
  subject: validateRequired,
};

export const validateInternalTicketForm = {
  subject: validateRequired,
  content: validateRequired,
};
