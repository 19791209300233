import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { cloneDeep, pick } from 'lodash';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import TimeTrackerUpdateForm from '../components/timeTracker/TimeTrackerUpdateForm';
import ErrorQuery from '../components/form/ErrorQuery';
import LoadingForm from '../components/common/LoadingForm';
import {
  GET_TIME_TRACKER_PROJECT,
  timeTrackerProjectEntryKeys,
  timeTrackerProjectKeys,
  UPDATE_TIME_TRACKER_PROJECT,
  validateTimeTrackerProjectForm,
} from '../schema/timeTracker';
import { getTimeTrackerProject, getTimeTrackerProjectVariables } from '../schema/types/getTimeTrackerProject';
import { TimeTrackerProject } from '../schema/types/TimeTrackerProject';
import { updateTimeTrackerProject, updateTimeTrackerProjectVariables } from '../schema/types/updateTimeTrackerProject';
import { DefaultRouteParams } from '../utils/const';
import { toastErrorQuery } from '../utils/error';
import NotFoundPage from './NotFoundPage';

const transform = (model: TimeTrackerProject) => {
  const data = cloneDeep(model);

  data.addings.forEach((el) => {
    if (!el.title) {
      el.title = el.product.title;
    }
  });

  return data;
};

const TimeTrackerUpdatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ match }) => {
  const [mutate] = useMutation<updateTimeTrackerProject, updateTimeTrackerProjectVariables>(
    UPDATE_TIME_TRACKER_PROJECT,
  );

  const { data, loading, error, refetch } = useQuery<getTimeTrackerProject, getTimeTrackerProjectVariables>(
    GET_TIME_TRACKER_PROJECT,
    {
      variables: { query: match.params.id },
    },
  );

  if (!data && loading) return <LoadingForm />;
  if (error) return <ErrorQuery error={error} />;
  if (!data) return <NotFoundPage />;

  return (
    <div className="user-form-page">
      <Formik
        enableReinitialize
        initialValues={transform(data.timeTrackerProject)}
        validationSchema={yup.object().shape(validateTimeTrackerProjectForm)}
        onSubmit={async (values, { setSubmitting, setValues }) => {
          try {
            const payload: any = pick(values, timeTrackerProjectKeys);

            payload.budgetCount = Number(payload.budgetCount);

            payload.specialRates = payload.specialRates.map((el: any) => ({
              ...pick(el, ['productId', 'amount']),
              amount: Number(el.amount),
            }));

            payload.entries = payload.entries.map((el: any) => ({
              ...pick(el, timeTrackerProjectEntryKeys),
              durationSeconds: Number(el.durationSeconds),
            }));

            payload.addings = payload.addings.map((el: any) => ({
              ...pick(el, ['productId', 'title', 'price', 'pieces', 'discountPercent']),
              price: Number(el.price),
              pieces: Number(el.pieces),
              discountPercent: Number(el.discountPercent),
            }));

            const result: any = await mutate({
              variables: {
                id: match.params.id,
                payload,
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              setValues(result.data.updateTimeTrackerProject.timeTrackerProject);
              toast.success(i18n._(t`Success!`));
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => <TimeTrackerUpdateForm refetch={refetch} loading={isSubmitting} />}
      </Formik>
    </div>
  );
};

export default withRouter(TimeTrackerUpdatePage);
