import { i18n } from '@lingui/core';
import React, { FC } from 'react';
import { dateOptions } from '../../utils/const';

type Props = {
  value: string | Date;
  format?: Intl.DateTimeFormatOptions;
};

const DateFormat: FC<Props> = ({ value, format }) => <>{i18n.date(value, format || dateOptions)}</>;

export default DateFormat;
