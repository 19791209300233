import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form } from 'formik';
import React, { FunctionComponent } from 'react';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';

type Props = {
  loading?: boolean;
};

const ProfilePasswordForm: FunctionComponent<Props> = ({ loading }) => {
  return (
    <Form>
      <div className="card password-page">
        <div className="card-body">
          <div className="row">
            <div className="form-group col-md-6">
              <label>
                <Trans>New Password</Trans>
              </label>
              <Field type="password" className="form-control" name="password" autoFocus />

              <FieldErrorMessage name="password" />
            </div>

            <div className="form-group col-md-6">
              <label>
                <Trans>Confirm Password</Trans>
              </label>
              <Field type="password" className="form-control" name="confirmPassword" />

              <FieldErrorMessage name="confirmPassword" />
            </div>
          </div>

          <div className="action-submit">
            <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={loading} />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default ProfilePasswordForm;
