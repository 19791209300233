import { Field } from 'formik';
import React, { FunctionComponent } from 'react';
import ButtonDelete from '../form/ButtonDelete';

type Props = {
  name: string;
  onRemove: () => void;
};

const AssetPersonFormItem: FunctionComponent<Props> = ({ name, onRemove }) => {
  return (
    <div className="custom-table-body columns">
      <div className="column col">
        <Field name={`${name}.fullName`}>
          {({ field }: { field: any }) => {
            return <label>{field.value}</label>;
          }}
        </Field>
      </div>
      <div className="column col-1">
        <label>
          <ButtonDelete onClick={onRemove} />
        </label>
      </div>
    </div>
  );
};

export default AssetPersonFormItem;
