import gql from 'graphql-tag';
import { validateRequired } from '../utils/validations';

export const HELP_DOCUMENT = gql`
  fragment HelpDocument on HelpDocument {
    id
    name
    shortText
    documentUrl
  }
`;

export const GET_HELP_DOCUMENTS = gql`
  query getHelpDocuments($language: String!) {
    helpDocuments(language: $language) {
      ...HelpDocument
    }
  }
  ${HELP_DOCUMENT}
`;

export const UPDATE_HELP_DOCUMENT = gql`
  mutation updateHelpDocument($id: ID!, $payload: UpdateHelpDocumentPayload!) {
    updateHelpDocument(id: $id, payload: $payload) {
      helpDocument {
        ...HelpDocument
      }
    }
  }
  ${HELP_DOCUMENT}
`;

export const helpDocumentKeys = ['shortText', 'documentUrl'];

export const validateHelpDocumentForm = {
  shortText: validateRequired,
};
