import { Trans } from '@lingui/macro';
import arrayMove from 'array-move';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Modal from 'react-modal';
import { GET_DOCUMENTS_FOR_TICKET } from '../../schema/document';
import { GET_TEXT_TEMPLATE_WITH_DETAIL } from '../../schema/textTemplate';
import { DocumentDetail } from '../../schema/types/DocumentDetail';
import { TextTemplateDetail } from '../../schema/types/TextTemplateDetail';
import { defaultQuery } from '../../utils/const';
import ButtonDelete from '../form/ButtonDelete';
import TicketDocumentations from './TicketDocumentations';
import TicketTextTemplates from './TicketTextTemplates';
import { cloneDeep } from 'lodash';

type Props = {
  show: boolean;
  favorites: any[];
  onChangeFavorites: (items: any[]) => void;
  onSelectTemplate: (item: TextTemplateDetail) => void;
  onSelectDocument: (item: DocumentDetail) => void;
  onClose: () => void;
};

const TicketDocTemModal: FunctionComponent<Props> = ({
  favorites,
  show,
  onChangeFavorites,
  onSelectTemplate,
  onSelectDocument,
  onClose,
}) => {
  const [local, setLocal] = useState<TextTemplateDetail[]>(favorites);

  const handleFavoriteTemplate = useCallback(
    (item: TextTemplateDetail) => {
      const model = cloneDeep(local);
      const index = model.findIndex((el: TextTemplateDetail) => el.id === item.id);

      if (index === -1) {
        model.push(item);
      } else {
        model.splice(index, 1);
      }

      setLocal(model);
      onChangeFavorites(model);
    },
    [local, setLocal, onChangeFavorites],
  );

  const handleSelectTemplate = useCallback(
    (item: TextTemplateDetail) => () => {
      onSelectTemplate(item);
    },
    [onSelectTemplate],
  );

  const handleRemoveTemplate = useCallback(
    (index: number) => () => {
      const model = cloneDeep(local);
      model.splice(index, 1);

      setLocal(model);
      onChangeFavorites(model);
    },
    [local, setLocal, onChangeFavorites],
  );

  const handleSort = useCallback(
    (result: any) => {
      const model = arrayMove(local, result.source.index, result.destination.index);

      setLocal(model);
      onChangeFavorites(model);
    },
    [local, setLocal, onChangeFavorites],
  );

  useQuery(GET_TEXT_TEMPLATE_WITH_DETAIL, {
    variables: {
      query: {
        limit: defaultQuery.query.limit,
        offset: defaultQuery.query.offset,
        orderBy: defaultQuery.query.orderBy,
        orderDirection: defaultQuery.query.orderDirection,
        filter: {
          search: '',
          archived: false,
        },
      },
    },
  });

  useQuery(GET_DOCUMENTS_FOR_TICKET, {
    variables: {
      query: {
        limit: defaultQuery.query.limit,
        offset: defaultQuery.query.offset,
        orderBy: defaultQuery.query.orderBy,
        orderDirection: defaultQuery.query.orderDirection,
        filter: {
          search: '',
          archived: false,
          template: false,
        },
      },
    },
  });

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show ticket-modal" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Templates and Documentations</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              {local.length ? (
                <div className="row sortable-container">
                  <div className="col-sm-6">
                    <label className="block-title">
                      <Trans>Favorite Templates</Trans>
                    </label>

                    <DragDropContext onDragEnd={handleSort}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            {local.map((item: any, index: number) => (
                              <Draggable key={index} draggableId={String(index)} index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    className="form-group d-flex align-items-center"
                                  >
                                    <div className="handle button mr-2" {...provided.dragHandleProps}>
                                      <i className="icon-sort" />
                                    </div>

                                    <a onClick={handleSelectTemplate(item)} className="text-info flex-grow-1 mr-2">
                                      {item.title}
                                    </a>

                                    <ButtonDelete onClick={handleRemoveTemplate(index)} />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className="row">
                <div className="col-sm-6 panel-column-left">
                  <label className="block-title">
                    <Trans>Text Templates</Trans>
                  </label>

                  <TicketTextTemplates
                    favorites={local}
                    onSelect={onSelectTemplate}
                    onFavorite={handleFavoriteTemplate}
                  />
                </div>

                <div className="col-sm-6">
                  <label className="block-title">
                    <Trans>Documentations</Trans>
                  </label>

                  <TicketDocumentations onSelect={onSelectDocument} />
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-link" onClick={onClose}>
                <Trans>Close</Trans>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TicketDocTemModal;
