import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { format, setHours, setMinutes, setSeconds, subDays } from 'date-fns';
import React, { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import { GET_SIDEBAR_STATE } from '../../schema/sidebar';
import { GET_STATS_TICKET } from '../../schema/stats';
import { sidebarState } from '../../schema/types/sidebarState';
import { statsTicket, statsTicketVariables } from '../../schema/types/statsTicket';
import { useViewer } from '../../utils/hooks';
import LoadingQuery from '../common/LoadingQuery';
import ErrorQuery from '../form/ErrorQuery';

const today = setSeconds(setMinutes(setHours(new Date(), 0), 0), 0);
const day30 = subDays(today, 30);

const HomeTicketVolume: FunctionComponent = () => {
  const sidebar = useQuery<sidebarState>(GET_SIDEBAR_STATE);

  const fontColor = sidebar.data?.sidebarState.darkMode ? '#fff' : '#000';

  const dataset: any = {
    labels: [],
    type: 'line',
    defaultFontFamily: 'Nunito',
    datasets: [
      {
        label: i18n._(t`Opened tickets`),
        data: [],
        backgroundColor: 'rgba(255, 159, 25, 0.2)',
        borderColor: '#FFBE52',
        borderWidth: 2,
        pointStyle: 'circle',
        pointRadius: 2,
        pointBorderColor: '#FFBE52',
        pointBackgroundColor: '#fff',
      },
      {
        label: i18n._(t`Closed tickes`),
        data: [],
        backgroundColor: 'rgba(129, 201, 69, 0.4)',
        borderColor: '#81C945',
        borderWidth: 2,
        pointStyle: 'circle',
        pointRadius: 2,
        pointBorderColor: '#81C945',
        pointBackgroundColor: '#fff',
      },
      {
        label: i18n._(t`Responses sent`),
        data: [],
        backgroundColor: 'rgba(123, 98, 254, 0.4)',
        borderColor: '#7b62fe',
        borderWidth: 2,
        pointStyle: 'circle',
        pointRadius: 2,
        pointBorderColor: '#7b62fe',
        pointBackgroundColor: '#fff',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'index',
      titleFontSize: 12,
      titleFontColor: fontColor,
      bodyFontColor: fontColor,
      backgroundColor: sidebar.data?.sidebarState.darkMode ? '#000' : '#fff',
      titleFontFamily: 'Nunito',
      bodyFontFamily: 'Nunito',
      cornerRadius: 3,
      intersect: false,
    },
    legend: {
      display: false,
      labels: {
        usePointStyle: true,
        fontFamily: 'Nunito',
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor,
          },
          display: true,
          gridLines: {
            color: 'rgba(0,0,0,0.061)',
          },
          scaleLabel: {
            display: true,
            labelString: i18n._(t`Day`),
            fontColor,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor,
          },
          display: true,
          gridLines: {
            display: false,
            drawBorder: true,
          },
          scaleLabel: {
            display: true,
            labelString: i18n._(t`Number of Tickets`),
            fontColor,
          },
        },
      ],
    },
    title: {
      display: false,
      text: 'Normal Legend',
    },
  };

  const me = useViewer();

  const variables = {
    query: {
      step: 24 * 3600,
      filter: {
        fromTime: day30.toISOString(),
        toTime: today.toISOString(),
        responsibleUserId: null,
      },
    },
  };

  const { data, loading, error } = useQuery<statsTicket, statsTicketVariables>(GET_STATS_TICKET, {
    variables,
    fetchPolicy: 'cache-first',
  });

  if (error) return <ErrorQuery error={error} />;
  if (loading && !data) return <LoadingQuery />;
  if (!me.data) return <div />;

  data?.statsTicket.forEach((el) => {
    dataset.labels.push(format(new Date(el.time), 'd.M'));
    dataset.datasets[0].data.push(el.openCount);
    dataset.datasets[1].data.push(el.closeCount);
    dataset.datasets[2].data.push(el.responseCount);
  });

  return (
    <div className="ticket-charts">
      <div className="legend">
        <div className="item closed">Closed tickets</div>

        <div className="item opened">Opened tickets</div>

        <div className="item responses">Responses sent</div>
      </div>

      <Line data={dataset} options={options} />
    </div>
  );
};

export default HomeTicketVolume;
