import gql from 'graphql-tag';
import { validateRequired } from '../utils/validations';
import { ASSET_REMINDER } from './asset';
import { COMPANY_PERSON } from './company';
import { LOG, LOGS } from './log';
import { TAG } from './tag';
import { PersonDetail } from './types/PersonDetail';

export const PERSON = gql`
  fragment Person on Person {
    id
    fullName
    avatar {
      id
      url
      imageInstance(profile: "thumbnail") {
        object {
          id
          url
        }
      }
    }
    companies {
      ...CompanyPerson
    }
    cellphone
    email
    lastLog {
      ...Log
    }
  }
  ${LOG}
  ${COMPANY_PERSON}
`;

export const PERSONS = gql`
  fragment Persons on PersonCollection {
    limit
    offset
    total
    items {
      ...Person
    }
  }
  ${PERSON}
`;

export const GET_PERSONS = gql`
  query getPersons($query: PersonsQuery!) {
    persons(query: $query) {
      ...Persons
    }
  }
  ${PERSONS}
`;

export const GET_PERSONS_FOR_COMPANY = gql`
  query getCompanyPersons($query: PersonsQuery!) {
    persons(query: $query) {
      limit
      offset
      total
      items {
        id
        fullName
        cellphone
      }
    }
  }
`;

export const GET_PERSONS_FOR_OFFER = gql`
  query getOfferPersons($query: PersonsQuery!) {
    persons(query: $query) {
      limit
      offset
      total
      items {
        id
        fullName
        firstName
        lastName
        function
        email
      }
    }
  }
`;

export const PERSON_COMPANY = gql`
  fragment PersonCompany on CompanyPerson {
    company {
      id
      name
      logo {
        id
        url
      }
    }
    permission
    person {
      id
      fullName
    }
    companyId @client
  }
`;

export const PERSON_DETAIL = gql`
  fragment PersonDetail on Person {
    id
    firstName
    lastName
    gender
    function
    email
    companyPhone
    cellphone
    privateAddress1
    privateAddress2
    privateZip
    privateCity
    avatar {
      id
      url
    }
    mfaEnabled
    companies {
      ...PersonCompany
    }
    tags {
      ...Tag
    }
    reminders {
      ...AssetReminder
    }
    isVIP
    tagNames @client
    manualLogV2 @client {
      content
      tagNames
    }
  }
  ${TAG}
  ${PERSON_COMPANY}
  ${ASSET_REMINDER}
`;

export const ExtendPerson = {
  tagNames: (obj: PersonDetail) => obj.tags.map((el) => el.name),
  manualLogV2: () => ({
    content: '',
    tagNames: [],
    __typename: 'ManualLogV2',
  }),
};

export const GET_PERSON = gql`
  query getPerson($query: ID!) {
    person(id: $query) {
      ...PersonDetail
    }
  }
  ${PERSON_DETAIL}
`;

export const GET_PERSON_LOGS = gql`
  query getPersonLogs($id: ID!, $query: LogsQuery!) {
    person(id: $id) {
      id
      logs(query: $query) {
        ...Logs
      }
    }
  }
  ${LOGS}
`;

export const CREATE_PERSON = gql`
  mutation createPerson($payload: CreatePersonPayload!) {
    createPerson(payload: $payload) {
      person {
        ...PersonDetail
      }
    }
  }
  ${PERSON_DETAIL}
`;

export const UPDATE_PERSON = gql`
  mutation updatePerson($id: ID!, $payload: UpdatePersonPayload!) {
    updatePerson(id: $id, payload: $payload) {
      person {
        ...PersonDetail
      }
    }
  }
  ${PERSON_DETAIL}
`;

export const ARCHIVE_PERSON = gql`
  mutation archivePerson($id: ID!) {
    archivePerson(id: $id) {
      person {
        id
      }
    }
  }
`;

export const UNARCHIVE_PERSON = gql`
  mutation unarchivePerson($id: ID!) {
    unarchivePerson(id: $id) {
      person {
        id
      }
    }
  }
`;

export const DISABLE_PERSON_MFA = gql`
  mutation disablePersonMfa($id: ID!) {
    disablePersonMfa(id: $id) {
      person {
        id
        mfaEnabled
      }
    }
  }
`;

export const personKeys = [
  'firstName',
  'lastName',
  'gender',
  'function',
  'email',
  'companyPhone',
  'cellphone',
  'privateAddress1',
  'privateAddress2',
  'privateZip',
  'privateCity',
  'status',
  'companies',
  'companyId',
  'avatarId',
  'tagNames',
  'manualLogV2',
  'reminders',
  'password',
  'isVIP',
];

export const validatePersonForm = {
  firstName: validateRequired,
  lastName: validateRequired,
};
