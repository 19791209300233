import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';

type Props = {
  onClick: () => void;
};

const ButtonLogin: FunctionComponent<Props> = ({ onClick }) => {
  const key = Math.random();

  return (
    <>
      <span data-tip data-for={`login-${key}`} className="button button-archive text-xs" onClick={onClick}>
        <i className="icon-customer-view" />
      </span>
      <ReactTooltip id={`login-${key}`}>
        <Trans>Open as Customer</Trans>
      </ReactTooltip>
    </>
  );
};

export default ButtonLogin;
