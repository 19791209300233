import gql from 'graphql-tag';
import { validateRequired } from '../utils/validations';

export const CONTRACT = gql`
  fragment Contract on Contract {
    id
    customer {
      id
      name
    }
    name
    cycle
    yearlyInterval
  }
`;

export const CONTRACTS = gql`
  fragment Contracts on ContractCollection {
    limit
    offset
    total
    items {
      ...Contract
    }
  }
  ${CONTRACT}
`;

export const GET_CONTRACTS = gql`
  query getContracts($query: ContractsQuery!) {
    contracts(query: $query) {
      ...Contracts
    }
  }
  ${CONTRACTS}
`;

export const GET_CONTRACTS_FOR_REPORT = gql`
  query getReportContracts($query: ContractsQuery!) {
    contracts(query: $query) {
      limit
      offset
      total
      items {
        id
        name
      }
    }
  }
`;

export const PERSON_GET_CONTRACTS = gql`
  query personGetContracts($id: ID!, $query: ContractsQuery!) {
    company(id: $id) {
      id
      contracts(query: $query) {
        limit
        offset
        total
        items {
          id
          name
          cycle
          yearlyInterval
        }
      }
    }
  }
`;

export const CONTRACT_PRODUCT_ADJUSTMENT = gql`
  fragment ContractProductAdjustment on ContractProductAdjustment {
    product {
      id
      title
    }
    piecesAdjustment
    adjustmentDate
    user {
      id
      fullName
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
  }
`;

export const CONTRACT_REPORTING_PRODUCT_ADJUSTMENT = gql`
  fragment ContractReportingProductAdjustment on ContractReportingProductAdjustment {
    product {
      id
      title
    }
    piecesAdjustment
    adjustmentDate
    user {
      id
      fullName
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
    contractType {
      id
      name
    }
  }
`;

export const CONTRACT_PRODUCT = gql`
  fragment ContractProduct on ContractProduct {
    product {
      id
      title
      content
      reports {
        product {
          id
        }
      }
    }
    threadId
    title
    pieces
    price
    discountPercent
  }
`;

export const CONTRACT_REPORTING_PRODUCT = gql`
  fragment ContractReportingProduct on ContractReportingProduct {
    contractType {
      id
      name
    }
    product {
      id
      title
    }
    pieces
    threadId
  }
`;

export const PERSON_GET_CONTRACT = gql`
  query personGetContract($id: ID!, $query: ID!, $query2: ContractProductsQuery) {
    company(id: $id) {
      id
      contract(id: $query) {
        id
        name
        cycle
        yearlyInterval
        productAdjustments(query: { limit: 50 }) {
          items {
            ...ContractProductAdjustment
          }
        }
        products(query: $query2) {
          product {
            id
            title
            content
          }
          title
          pieces
          price
          discountPercent
        }
        startedAt
      }
    }
  }
  ${CONTRACT_PRODUCT_ADJUSTMENT}
`;

export const GET_CONTRACT = gql`
  query getContract($query: ID!, $query2: ContractProductsQuery) {
    contract(id: $query) {
      id
      customer {
        id
        name
      }
      name
      cycle
      yearlyInterval
      startedAt
      contractLength
      isAutoRenew
      cancelPeriod
      contractEnds
      cancellationDeadline
      productAdjustments(query: { limit: 50 }) {
        items {
          ...ContractProductAdjustment
        }
      }
      products(query: $query2) {
        ...ContractProduct
      }
      reportingProductAdjustments(query: { limit: 50 }) {
        items {
          ...ContractReportingProductAdjustment
        }
      }
      reportingProducts(query: $query2) {
        ...ContractReportingProduct
      }
    }
  }
  ${CONTRACT_PRODUCT_ADJUSTMENT}
  ${CONTRACT_PRODUCT}
  ${CONTRACT_REPORTING_PRODUCT_ADJUSTMENT}
  ${CONTRACT_REPORTING_PRODUCT}
`;

export const CREATE_CONTRACT = gql`
  mutation createContract($payload: CreateContractPayload!) {
    createContract(payload: $payload) {
      contract {
        id
      }
    }
  }
`;

export const UPDATE_CONTRACT = gql`
  mutation updateContract($id: ID!, $payload: UpdateContractPayload!) {
    updateContract(id: $id, payload: $payload) {
      contract {
        id
      }
    }
  }
`;

export const ARCHIVE_CONTRACT = gql`
  mutation archiveContract($id: ID!, $archiveDate: DateTime) {
    archiveContract(id: $id, archiveDate: $archiveDate) {
      contract {
        id
      }
    }
  }
`;

export const UNARCHIVE_CONTRACT = gql`
  mutation unarchiveContract($id: ID!) {
    unarchiveContract(id: $id) {
      contract {
        id
      }
    }
  }
`;

export const validateContractForm = {
  name: validateRequired,
  customerId: validateRequired,
};
