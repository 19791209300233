import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldCheckbox from '../form/FieldCheckbox';
import FieldEditor from '../form/FieldEditor';
import FieldErrorMessage from '../form/FieldErrorMessage';

type Props = {
  loading?: boolean;
};

const DocumentationElementsCreateForms: FunctionComponent<Props> = ({ loading }) => {
  return (
    <Form>
      <div className="form-breadcrumbs-area">
        <NavLink to="/documentation/element">
          <Trans>Centralized Content</Trans>
        </NavLink>

        <i className="fa fa-angle-left" />

        <Field name="id">
          {({ field }: { field: FieldInputProps<string> }) => {
            if (field.value) return <Trans>Edit centralized content</Trans>;

            return <Trans>Add centralized content</Trans>;
          }}
        </Field>
      </div>

      <div className="table-actions form-table-actions">
        <div className="breadcrumbs-area">
          <Field name="title">
            {({ field }: { field: FieldInputProps<string> }) => {
              return <div className="main-title">{field.value}</div>;
            }}
          </Field>
        </div>

        <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save updates`)} loading={loading} />
      </div>

      <div className="card">
        <div className="form-group">
          <label>
            <Trans>Title</Trans>
          </label>
          <Field type="text" className="form-control" name="title" />

          <FieldErrorMessage name="title" />
        </div>

        <div className="form-group">
          <FieldEditor name="content" />
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <FieldCheckbox name="public" label={i18n._(t`Available for all users`)} />
        </div>
      </div>
    </Form>
  );
};

export default DocumentationElementsCreateForms;
