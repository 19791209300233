import React, { FunctionComponent } from 'react';

type Props = {};

const LoadingTable: FunctionComponent<Props> = () => {
  return (
    <div className="ph-item">
      <div className="ph-col-12">
        <div className="ph-row">
          <div className="ph-col-8 big" />
          <div className="ph-col-2 empty big" />
          <div className="ph-col-2 big" />
          <div className="ph-col-6" />
          <div className="ph-col-6 empty" />
          <div className="ph-col-8" />
          <div className="ph-col-4 empty" />
          <div className="ph-col-12" />
        </div>
        <div className="ph-row">
          <div className="ph-col-6" />
          <div className="ph-col-6 empty" />
          <div className="ph-col-8" />
          <div className="ph-col-4 empty" />
          <div className="ph-col-12" />
        </div>
        <div className="ph-row">
          <div className="ph-col-6" />
          <div className="ph-col-6 empty" />
          <div className="ph-col-8" />
          <div className="ph-col-4 empty" />
          <div className="ph-col-12" />
        </div>
        <div className="ph-row">
          <div className="ph-col-6" />
          <div className="ph-col-6 empty" />
          <div className="ph-col-8" />
          <div className="ph-col-4 empty" />
          <div className="ph-col-12" />
        </div>
      </div>
    </div>
  );
};

export default LoadingTable;
