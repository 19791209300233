import { cloneDeep } from 'lodash';
import ReactTable, { CellInfo } from 'react-table';
import NinjaForm from '../components/asset/NinjaForm';
import LoadingTable from '../components/common/LoadingTable';
import ButtonArchive from '../components/form/ButtonArchive';
import ErrorQuery from '../components/form/ErrorQuery';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { DISCONNECT_NINJA_RMM, GET_NINJA_RMM_ORGANIZATIONS } from '../schema/config';
import { disconnectNinjaRmm, disconnectNinjaRmmVariables } from '../schema/types/disconnectNinjaRmm';
import { getNinjaRmmOrganizations, getNinjaRmmOrganizationsVariables } from '../schema/types/getNinjaRmmOrganizations';
import { tableOptions } from '../utils/const';
import { useVariables } from '../utils/hooks';

const NinjaRmmCreatePage: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);

  const { search$, search, limit, setLimit, offset, orderBy, orderDirection } = useVariables();

  const variables = useMemo(
    () => ({
      query: {
        limit,
        offset,
        orderBy,
        orderDirection,
        filter: {
          search,
          connected: true,
        },
      },
    }),
    [limit, offset, orderBy, orderDirection, search],
  );

  const { data, previousData, loading, error, refetch } = useQuery<
    getNinjaRmmOrganizations,
    getNinjaRmmOrganizationsVariables
  >(GET_NINJA_RMM_ORGANIZATIONS, {
    variables,
  });

  const [mutate] = useMutation<disconnectNinjaRmm, disconnectNinjaRmmVariables>(DISCONNECT_NINJA_RMM);

  const handleRemove = useCallback(
    (ninjaRmmOrganizationId) => () => {
      mutate({
        variables: {
          ninjaRmmOrganizationId,
        },
        optimisticResponse: {
          disconnectNinjaRmm: true,
        },
        update: (proxy) => {
          const data: any = cloneDeep(proxy.readQuery({ query: GET_NINJA_RMM_ORGANIZATIONS, variables }));
          data.ninjaRmmOrganizations.items = data.ninjaRmmOrganizations.items.filter(
            (el: any) => el.id !== ninjaRmmOrganizationId,
          );
          proxy.writeQuery({
            query: GET_NINJA_RMM_ORGANIZATIONS,
            variables,
            data,
          });
        },
      });
    },
    [mutate, variables],
  );

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.ninjaRmmOrganizations.items ?? previousData?.ninjaRmmOrganizations.items ?? [];
  const pages = data && data.ninjaRmmOrganizations ? Math.ceil(data.ninjaRmmOrganizations.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`ProCloud Center`),
      accessor: 'company.name',
    },
    {
      Header: i18n._(t`NinjaRMM`),
      accessor: 'name',
    },
    {
      Header: i18n._(t`Actions`),
      width: 180,
      className: 'text-center',
      Cell: (cell: CellInfo) => <ButtonArchive onClick={handleRemove(cell.original.id)} />,
    },
  ];

  return (
    <div className="ninjarmm-page">
      <NinjaForm onSuccess={refetch} />

      <div className="main-title">
        <Trans>Overview</Trans>
      </div>

      <div className="card-body">
        <div className="table-actions">
          <div className="item search input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="icon-search" />
              </div>
            </div>
            <input
              className="form-control"
              placeholder={i18n._(t`Search`)}
              onInput={(e: any) => search$.next(e.target.value)}
            />
          </div>
        </div>

        {!loaded ? (
          <LoadingTable />
        ) : (
          <ReactTable
            data={items}
            columns={columns}
            pages={pages}
            pageSize={limit}
            manual
            onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
            {...tableOptions()}
          />
        )}
      </div>
    </div>
  );
};

export default NinjaRmmCreatePage;
