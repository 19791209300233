import gql from 'graphql-tag';
import { TAG } from './tag';

export const LOG = gql`
  fragment Log on Log {
    id
    content
    contentHtml
    createdAt
    creator {
      id
      fullName
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
    personCreator {
      id
      fullName
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
    attachments {
      id
      filename
      url
    }
    tags {
      ...Tag
    }
    clientInfo {
      browser
      device
      ip
      os
    }
  }
  ${TAG}
`;

export const LOGS = gql`
  fragment Logs on LogCollection {
    items {
      ...Log
    }
    total
  }
  ${LOG}
`;

export const ASSET_LOG = gql`
  fragment AssetLog on AssetLog {
    id
    content
    createdAt
    creator {
      id
      fullName
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
    tags {
      ...Tag
    }
  }
  ${TAG}
`;

export const ASSET_LOGS = gql`
  fragment AssetLogs on AssetLogCollection {
    items {
      ...AssetLog
    }
  }
  ${ASSET_LOG}
`;
