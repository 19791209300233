import gql from 'graphql-tag';
import { validateRequired } from '../utils/validations';

export const PRODUCT_CONTRACT_TYPE = gql`
  fragment ProductContractType on ProductContractType {
    id
    name
    emails
  }
`;

export const PRODUCT_CONTRACT_TYPES = gql`
  fragment ProductContractTypes on ProductContractTypeCollection {
    limit
    offset
    total
    items {
      ...ProductContractType
    }
  }
  ${PRODUCT_CONTRACT_TYPE}
`;

export const GET_PRODUCT_CONTRACT_TYPES = gql`
  query getProductContractTypes($query: ProductContractTypesQuery!) {
    productContractTypes(query: $query) {
      ...ProductContractTypes
    }
  }
  ${PRODUCT_CONTRACT_TYPES}
`;

export const CREATE_PRODUCT_CONTRACT_TYPE = gql`
  mutation createProductContractType(
    $payload: CreateProductContractTypePayload!
  ) {
    createProductContractType(payload: $payload) {
      productContractType {
        ...ProductContractType
      }
    }
  }
  ${PRODUCT_CONTRACT_TYPE}
`;

export const UPDATE_PRODUCT_CONTRACT_TYPE = gql`
  mutation updateProductContractType(
    $id: ID!
    $payload: UpdateProductContractTypePayload!
  ) {
    updateProductContractType(id: $id, payload: $payload) {
      productContractType {
        ...ProductContractType
      }
    }
  }
  ${PRODUCT_CONTRACT_TYPE}
`;

export const ARCHIVE_PRODUCT_CONTRACT_TYPE = gql`
  mutation archiveProductContractType($id: ID!) {
    archiveProductContractType(id: $id) {
      productContractType {
        id
      }
    }
  }
`;

export const UNARCHIVE_PRODUCT_CONTRACT_TYPE = gql`
  mutation unarchiveProductContractType($id: ID!) {
    unarchiveProductContractType(id: $id) {
      productContractType {
        id
      }
    }
  }
`;

export const validateProductContractTypeForm = {
  name: validateRequired,
};
