import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { getUsers, getUsersVariables } from '../../schema/types/getUsers';
import { GET_USERS_FOR_COMPANY } from '../../schema/user';
import { cloneDeep } from 'lodash';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {};

const OfferResponsibleForm: FunctionComponent<Props> = () => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getUsers, getUsersVariables>(GET_USERS_FOR_COMPANY, {
    variables,
    fetchPolicy: 'cache-first',
  });

  if (!data && !loading) return <div />;

  return (
    <>
      {[0, 1].map((index) => (
        <div key={index} className="form-group">
          <label>
            {index === 0 && <Trans>Account Manager</Trans>}
            {index === 1 && <Trans>Primary Engineer</Trans>}
          </label>

          <Field name={`responsibleUsers[${index}]`}>
            {({ field, form }: { field: any; form: FormikProps<any> }) => {
              const handleChange = (value: any) => {
                const values: any = cloneDeep(form.values.responsibleUserIds);
                values[index] = value.value.id;
                form.setFieldValue('responsibleUserIds', values);

                const users = cloneDeep(form.values.responsibleUsers);
                users[index] = value.value;
                form.setFieldValue('responsibleUsers', users);
              };

              const handleLoad = (value: string) => search$.next(value);

              const options = loading
                ? []
                : data!.users.items.map((el) => ({
                    value: el,
                    label: el.fullName,
                  }));

              const current = !field.value
                ? null
                : {
                    value: field.value,
                    label: field.value.fullName,
                  };

              return (
                <Select
                  value={current}
                  options={options}
                  isLoading={loading}
                  onChange={handleChange}
                  onInputChange={handleLoad}
                  {...selectOptions}
                />
              );
            }}
          </Field>
        </div>
      ))}
    </>
  );
};

export default OfferResponsibleForm;
