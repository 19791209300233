/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AssetEventType {
  Down = 'Down',
  Paused = 'Paused',
  Started = 'Started',
  Unknown = 'Unknown',
  Up = 'Up',
}

export enum AssetReminderCycle {
  BiWeekly = 'BiWeekly',
  Daily = 'Daily',
  HalfYearly = 'HalfYearly',
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  Quarterly = 'Quarterly',
  ThreeYears = 'ThreeYears',
  Weekly = 'Weekly',
  Yearly = 'Yearly',
}

export enum AssetUptimeStatus {
  Status0NoMonitor = 'Status0NoMonitor',
  Status1Unknown = 'Status1Unknown',
  Status2Blue = 'Status2Blue',
  Status3Green = 'Status3Green',
  Status4Yellow = 'Status4Yellow',
  Status5Red = 'Status5Red',
}

export enum AssetsQueryOrderBy {
  companyName = 'companyName',
  id = 'id',
  name = 'name',
  productTitle = 'productTitle',
  uptimeStatus = 'uptimeStatus',
}

export enum BexioMswtType {
  ExcludingTax = 'ExcludingTax',
  ExemptFromTax = 'ExemptFromTax',
  IncludingTax = 'IncludingTax',
}

export enum BudgetUnit {
  Currency = 'Currency',
  Entry = 'Entry',
  Hour = 'Hour',
}

export enum ChatGPTRole {
  assistant = 'assistant',
  system = 'system',
  user = 'user',
}

export enum CompaniesQueryOrderBy {
  generalEmail = 'generalEmail',
  generalPhone = 'generalPhone',
  heatLevel = 'heatLevel',
  id = 'id',
  name = 'name',
  primaryContactFullName = 'primaryContactFullName',
  status = 'status',
}

export enum CompanyPermission {
  Admin = 'Admin',
  NoAccess = 'NoAccess',
  User = 'User',
}

export enum CompanyStatus {
  Cold = 'Cold',
  Customer = 'Customer',
  Default = 'Default',
  Partner = 'Partner',
  Touched = 'Touched',
}

export enum CompanySubscriptionType {
  Cancelled = 'Cancelled',
  PowerOffer = 'PowerOffer',
  ServiceProvider = 'ServiceProvider',
}

export enum ContractCycle {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export enum ContractsQueryOrderBy {
  customerName = 'customerName',
  cycle = 'cycle',
  id = 'id',
  name = 'name',
  yearlyInterval = 'yearlyInterval',
}

export enum DocumentCategoriesQueryOrderBy {
  id = 'id',
  name = 'name',
  type = 'type',
}

export enum DocumentCategoryType {
  Both = 'Both',
  External = 'External',
  Internal = 'Internal',
}

export enum DocumentTemplateElementsQueryOrderBy {
  id = 'id',
  title = 'title',
}

export enum DocumentVisibility {
  AllCustomers = 'AllCustomers',
  Internal = 'Internal',
  Public = 'Public',
  SelectedCustomers = 'SelectedCustomers',
}

export enum DocumentsQueryOrderBy {
  editorName = 'editorName',
  id = 'id',
  title = 'title',
  updatedAt = 'updatedAt',
}

export enum Gender {
  Female = 'Female',
  Male = 'Male',
  Unspecified = 'Unspecified',
}

export enum HolidaySchedulesQueryOrderBy {
  from = 'from',
  id = 'id',
}

export enum HttpStatusMode {
  Exclude = 'Exclude',
  Include = 'Include',
}

export enum LoginMode {
  Person = 'Person',
  User = 'User',
}

export enum MessageCategoriesQueryOrderBy {
  id = 'id',
  name = 'name',
}

export enum MessagesQueryOrderBy {
  id = 'id',
}

export enum MonitorKeywordType {
  Exists = 'Exists',
  NotExists = 'NotExists',
}

export enum MonitorPortType {
  Custom = 'Custom',
  FTP = 'FTP',
  HTTP = 'HTTP',
  HTTPS = 'HTTPS',
  IMAP = 'IMAP',
  POP3 = 'POP3',
  SMTP = 'SMTP',
}

export enum MonitorStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum MonitorType {
  HTTP = 'HTTP',
  Heartbeat = 'Heartbeat',
  Keyword = 'Keyword',
  Ping = 'Ping',
  Port = 'Port',
}

export enum NinjaRmmLocation {
  EU = 'EU',
  US = 'US',
}

export enum NinjaRmmOrganizationsQueryOrderBy {
  id = 'id',
  name = 'name',
}

export enum OfferItemProjectItemStatus {
  Done = 'Done',
  Open = 'Open',
  WaitingForFeedback = 'WaitingForFeedback',
  WorkingOnIt = 'WorkingOnIt',
}

export enum OfferItemType {
  Product = 'Product',
  Project = 'Project',
  TemplateElement = 'TemplateElement',
  Text = 'Text',
}

export enum OffersQueryOrderBy {
  companyName = 'companyName',
  createdAt = 'createdAt',
  fullName = 'fullName',
  id = 'id',
  title = 'title',
  updatedAt = 'updatedAt',
}

export enum OrderDirection {
  Asc = 'Asc',
  Desc = 'Desc',
}

export enum PersonalTicketStatus {
  Closed = 'Closed',
  InProgress = 'InProgress',
  Opened = 'Opened',
}

export enum PersonalTicketsQueryOrderBy {
  closedAt = 'closedAt',
  companyName = 'companyName',
  id = 'id',
  lastActiveTime = 'lastActiveTime',
  lastEditorName = 'lastEditorName',
  openedAt = 'openedAt',
  opener = 'opener',
  subject = 'subject',
}

export enum PersonsQueryOrderBy {
  cellphone = 'cellphone',
  companyPhone = 'companyPhone',
  email = 'email',
  fullName = 'fullName',
  id = 'id',
}

export enum ProductBilling {
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  Yearly = 'Yearly',
}

export enum ProductContractTypesQueryOrderBy {
  id = 'id',
  name = 'name',
}

export enum ProductsQueryOrderBy {
  id = 'id',
  price = 'price',
  title = 'title',
}

export enum SmsServiceType {
  ClickSend = 'ClickSend',
}

export enum TagsQueryOrderBy {
  id = 'id',
  name = 'name',
}

export enum TenantSubscriptionType {
  Cancelled = 'Cancelled',
  PowerOffer = 'PowerOffer',
  ServiceProvider = 'ServiceProvider',
}

export enum TextTemplateContentType {
  Email = 'Email',
  Text = 'Text',
}

export enum TextTemplateType {
  Private = 'Private',
  Public = 'Public',
  System = 'System',
}

export enum TextTemplatesQueryOrderBy {
  id = 'id',
  title = 'title',
}

export enum ThingType {
  Asset = 'Asset',
  Company = 'Company',
  Contract = 'Contract',
  Document = 'Document',
  Offer = 'Offer',
  Person = 'Person',
  Product = 'Product',
  Ticket = 'Ticket',
}

export enum ThingsQueryOrderBy {
  id = 'id',
}

export enum TicketInProgressSubStatus {
  WaitingForReply = 'WaitingForReply',
  Working = 'Working',
}

export enum TicketStatus {
  Closed = 'Closed',
  InProgress = 'InProgress',
  Opened = 'Opened',
}

export enum TicketsQueryOrderBy {
  closedAt = 'closedAt',
  companyName = 'companyName',
  id = 'id',
  lastActiveTime = 'lastActiveTime',
  lastEditorName = 'lastEditorName',
  openedAt = 'openedAt',
  opener = 'opener',
  subject = 'subject',
}

export enum TimeTrackerProjectsQueryOrderBy {
  id = 'id',
  title = 'title',
}

export enum UserRole {
  Admin = 'Admin',
  Member = 'Member',
}

export enum UsersQueryOrderBy {
  cellphone = 'cellphone',
  companyPhone = 'companyPhone',
  email = 'email',
  fullName = 'fullName',
  id = 'id',
  role = 'role',
}

export interface AssetEventsQuery {
  filter?: AssetEventsQueryFilter | null;
}

export interface AssetEventsQueryFilter {
  fromTime?: any | null;
  toTime?: any | null;
}

export interface AssetReminderPayload {
  id?: string | null;
  title: string;
  cycle: AssetReminderCycle;
  startDate: any;
  content: string;
  customRecipients?: boolean | null;
  customTo?: string | null;
  customCc?: string | null;
  customBcc?: string | null;
  linkEnabled?: boolean | null;
}

export interface AssetResponseTimesQuery {
  filter?: AssetResponseTimesQueryFilter | null;
  step?: number | null;
}

export interface AssetResponseTimesQueryFilter {
  fromTime?: any | null;
  toTime?: any | null;
}

export interface AssetsQuery {
  limit?: number | null;
  offset?: number | null;
  filter?: AssetsQueryFilter | null;
  orderBy?: AssetsQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
}

export interface AssetsQueryFilter {
  search?: string | null;
  archived?: boolean | null;
  tagIds?: string[] | null;
  assetTypeIds?: string[] | null;
  assetLocationIds?: string[] | null;
  assetLogTagIds?: string[] | null;
  companyIds?: string[] | null;
  productIds?: string[] | null;
  searchName?: string | null;
  dashboard?: boolean | null;
}

export interface AutoResponderExclusionPayload {
  emails: string[];
}

export interface AzureRIConfigPayload {
  productId: string;
  margin: number;
}

export interface BexioConfigPayload {
  apiKey: string;
  limitPerHour?: number | null;
}

export interface BexioInvoiceConfigPayload {
  bankAccountId: string;
  paymentTypeId: string;
  mwstType: BexioMswtType;
  mswtIsNet: boolean;
  taxId: string;
  noVatTaxId?: string | null;
  defaultProductAccountId?: string | null;
}

export interface ChatGPTAnswerConversationInput {
  messages: ChatGPTMessageInput[];
}

export interface ChatGPTAnswerInput {
  inputText: string;
}

export interface ChatGPTAnswerTicketInput {
  ticketId: string;
}

export interface ChatGPTMessageInput {
  content: string;
  role: ChatGPTRole;
}

export interface ClickSendConfigPayload {
  username: string;
  password: string;
}

export interface ClosePersonalTicketPayload {
  message?: CreatePersonalTicketMessagePayload | null;
}

export interface CloseTicketPayload {
  message?: CreateTicketMessagePayload | null;
}

export interface CompaniesQuery {
  limit?: number | null;
  offset?: number | null;
  filter?: CompaniesQueryFilter | null;
  orderBy?: CompaniesQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
}

export interface CompaniesQueryFilter {
  search?: string | null;
  archived?: boolean | null;
  searchName?: string | null;
  searchPrimaryContactName?: string | null;
  searchGeneralPhone?: string | null;
  searchGeneralEmail?: string | null;
  status?: CompanyStatus[] | null;
  heatLevel?: number | null;
}

export interface CompanyPersonPayload {
  personId: string;
  permission: CompanyPermission;
}

export interface ConfigureOutlookSystemAccountPayload {
  redirectUri: string;
}

export interface ConfigureOutlookTicketAccountPayload {
  redirectUri: string;
  trackedFolders?: string[] | null;
  archiveFolder?: string | null;
}

export interface ConnectMonitorHttpPayload {
  username?: string | null;
  password?: string | null;
  headers?: HttpHeaderPayload[] | null;
  statuses?: HttpStatusPayload[] | null;
}

export interface ConnectMonitorKeywordPayload {
  type: MonitorKeywordType;
  value: string;
}

export interface ConnectMonitorPayload {
  url?: string | null;
  type: MonitorType;
  interval: number;
  status: MonitorStatus;
  http?: ConnectMonitorHttpPayload | null;
  keyword?: ConnectMonitorKeywordPayload | null;
  port?: ConnectMonitorPortPayload | null;
}

export interface ConnectMonitorPortPayload {
  port: number;
  type: MonitorPortType;
}

export interface ContractProductPayload {
  threadId?: string | null;
  productId: string;
  title: string;
  price: number;
  pieces: number;
  discountPercent: number;
}

export interface ContractProductsQuery {
  effectiveDate?: any | null;
}

export interface ContractReportingProductPayload {
  threadId?: string | null;
  parentIndex?: number | null;
  productId: string;
  pieces: number;
  contractTypeId?: string | null;
  manual?: boolean | null;
}

export interface ContractsQuery {
  limit?: number | null;
  offset?: number | null;
  orderBy?: ContractsQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
  filter?: ContractsQueryFilter | null;
}

export interface ContractsQueryFilter {
  search?: string | null;
  archived?: boolean | null;
  companyIds?: string[] | null;
  cycles?: ContractCycle[] | null;
  yearlyIntervals?: number[] | null;
}

export interface CreateAssetPayload {
  name: string;
  companyId?: string | null;
  productId?: string | null;
  description?: string | null;
  uptimeSla?: number | null;
  playbook?: string | null;
  assetLocationName?: string | null;
  assetTypeName?: string | null;
  tagNames?: string[] | null;
  responsibleUserIds?: string[] | null;
  customerResponsiblePersonIds?: string[] | null;
  monitor?: ConnectMonitorPayload | null;
  disconnectMonitor?: boolean | null;
  manualLog?: string | null;
  manualLogViewableByCustomer?: boolean | null;
  manualLogTagNames?: string[] | null;
  reminders?: AssetReminderPayload[] | null;
  displayOnDashboard: boolean;
  displayOnCustomer: boolean;
  attachmentIds?: string[] | null;
}

export interface CreateAssetReportPayload {
  effectiveDate: any;
  sendToEmail?: string | null;
  sendToEmails?: string[] | null;
}

export interface CreateCompanyPayload {
  name: string;
  idNumber?: number | null;
  address1?: string | null;
  address2?: string | null;
  zip?: string | null;
  city?: string | null;
  region?: string | null;
  country?: string | null;
  canton?: string | null;
  website?: string | null;
  size?: number | null;
  generalPhone?: string | null;
  generalEmail?: string | null;
  status?: CompanyStatus | null;
  liveChat?: boolean | null;
  logoId?: string | null;
  responsibleUserIds?: string[] | null;
  tagNames?: string[] | null;
  manualLog?: string | null;
  manualLogV2?: CreateLogPayload | null;
  primaryPersonId?: string | null;
  persons?: CompanyPersonPayload[] | null;
  ticketDomains?: string[] | null;
  reminders?: AssetReminderPayload[] | null;
  heatLevel?: number | null;
  invoiceEmail?: string | null;
  bexioNoVat?: boolean | null;
  timeTrackerDefaultPrice?: number | null;
  timeTrackerSpecialRates?: TimeTrackerProjectSpecialRatePayload[] | null;
  subscriptionType?: CompanySubscriptionType | null;
  m365Margin?: number | null;
  azureRIMargin?: number | null;
  forceCreate?: boolean | null;
}

export interface CreateContactReportPayload {
  effectiveDate: any;
  filter?: CreateContactReportPayloadFilter | null;
  sendToEmail?: string | null;
  sendToEmails?: string[] | null;
}

export interface CreateContactReportPayloadFilter {
  status?: CompanyStatus[] | null;
  productIds?: string[] | null;
}

export interface CreateContractPayload {
  name: string;
  customerId: string;
  cycle: ContractCycle;
  yearlyInterval: number;
  startedAt: any;
  adjustmentDate: any;
  products?: ContractProductPayload[] | null;
  reportingProducts?: ContractReportingProductPayload[] | null;
  isAutoRenew?: boolean | null;
  contractLength?: number | null;
  cancelPeriod?: number | null;
}

export interface CreateContractReportPayload {
  sendToEmail?: string | null;
  sendToEmails?: string[] | null;
}

export interface CreateDocumentCategoryPayload {
  name: string;
  type: DocumentCategoryType;
}

export interface CreateDocumentPayload {
  title: string;
  categoryId: string;
  template: boolean;
  visibility: DocumentVisibility;
  permanentLink?: string | null;
  headGraphicId?: string | null;
  index?: boolean | null;
  elements: DocumentElementPayload[];
  tagNames?: string[] | null;
  customerIds?: string[] | null;
  attachmentIds?: string[] | null;
}

export interface CreateDocumentTemplateElementPayload {
  title?: string | null;
  content?: string | null;
  public: boolean;
  item?: OfferItemPayload | null;
}

export interface CreateHolidaySchedulePayload {
  from: any;
  to: any;
}

export interface CreateInternalTicketPayload {
  subject: string;
  content: string;
  attachmentIds: string[];
  companyId?: string | null;
  requestSupport?: boolean | null;
}

export interface CreateInvoiceReportPayload {
  effectiveDate: any;
  includeFilter?: CreateInvoiceReportPayloadFilter | null;
  excludeFilter?: CreateInvoiceReportPayloadFilter | null;
  sendToEmail?: string | null;
  sendToEmails?: string[] | null;
}

export interface CreateInvoiceReportPayloadFilter {
  companyIds?: string[] | null;
  contractIds?: string[] | null;
  productIds?: string[] | null;
}

export interface CreateLogPayload {
  content: string;
  tagNames?: string[] | null;
}

export interface CreateM365ReportPayload {
  sendToEmail?: string | null;
  sendToEmails?: string[] | null;
  generationMonth: any;
  logicVersion?: string | null;
}

export interface CreateMessageCategoryPayload {
  name: string;
}

export interface CreateOfferLogPayload {
  content: string;
}

export interface CreateOfferPayload {
  index?: boolean | null;
  favorite?: boolean | null;
  template: boolean;
  title: string;
  companyId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  email?: string | null;
  headGraphicId?: string | null;
  responsibleUserIds?: string[] | null;
  tagNames?: string[] | null;
  items: OfferItemPayload[];
  favoriteTextTemplateId?: string | null;
}

export interface CreatePersonPayload {
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  email?: string | null;
  companyPhone?: string | null;
  cellphone?: string | null;
  privateAddress1?: string | null;
  privateAddress2?: string | null;
  privateZip?: string | null;
  privateCity?: string | null;
  gender?: Gender | null;
  isVIP?: boolean | null;
  companies?: PersonCompanyPayload[] | null;
  reminders?: AssetReminderPayload[] | null;
  companyId?: string | null;
  avatarId?: string | null;
  tagNames?: string[] | null;
  manualLog?: string | null;
  manualLogV2?: CreateLogPayload | null;
  forceCreate?: boolean | null;
}

export interface CreatePersonalTicketMessagePayload {
  to: string[];
  cc: string[];
  subject: string;
  content: string;
  attachmentIds: string[];
}

export interface CreateProductContractTypePayload {
  name: string;
  emails?: string[] | null;
}

export interface CreateProductPayload {
  idNumber?: number | null;
  title: string;
  price: number;
  youtubeUrl?: string | null;
  content?: string | null;
  billing?: ProductBilling | null;
  reactionTime?: number | null;
  tagNames?: string[] | null;
  photoIds?: string[] | null;
  reports?: ProductReportPayload[] | null;
  tdArticle?: string | null;
  bexioAccountId?: string | null;
  timeTrackerEnabled?: boolean | null;
}

export interface CreateReminderReportPayload {
  sendToEmail?: string | null;
  sendToEmails?: string[] | null;
}

export interface CreateReportLogReportPayload {
  sendToEmail?: string | null;
  sendToEmails?: string[] | null;
}

export interface CreateStorageObjectPayload {
  filename: string;
  mime: string;
  public?: boolean | null;
}

export interface CreateTextTemplatePayload {
  systemName?: string | null;
  title: string;
  content: string;
  public: boolean;
  contentType?: TextTemplateContentType | null;
  to?: string[] | null;
  bcc?: string[] | null;
  cc?: string[] | null;
  messageCenterTo?: string[] | null;
}

export interface CreateTicketMessagePayload {
  to: string[];
  cc: string[];
  subject: string;
  content: string;
  attachmentIds: string[];
}

export interface CreateTimeTrackerProjectPayload {
  customerId: string;
  title: string;
  budgetCount: number;
  budgetUnit: BudgetUnit;
  enableInCustomerView: boolean;
  specialRates: TimeTrackerProjectSpecialRatePayload[];
  entries: TimeTrackerProjectEntryPayload[];
  addings: TimeTrackerProjectAddingPayload[];
}

export interface CreateTimeTrackerReportPayload {
  effectiveDate: any;
  includeFilter?: CreateTimeTrackerReportPayloadFilter | null;
  excludeFilter?: CreateTimeTrackerReportPayloadFilter | null;
  includeArchived?: boolean | null;
  sendToEmail?: string | null;
  sendToEmails?: string[] | null;
}

export interface CreateTimeTrackerReportPayloadFilter {
  companyIds?: string[] | null;
  projectIds?: string[] | null;
}

export interface CreateUserPayload {
  email: string;
  password?: string | null;
  role: UserRole;
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  companyPhone?: string | null;
  cellphone?: string | null;
  address1?: string | null;
  address2?: string | null;
  zip?: string | null;
  city?: string | null;
  ticketSignature?: string | null;
  avatarId?: string | null;
  supportDisabled?: boolean | null;
  timeTrackerDefaultProductId?: string | null;
}

export interface CreateVendorReportPayload {
  effectiveDate: any;
  filter?: CreateVendorReportPayloadFilter | null;
  sendToEmail?: string | null;
  sendToEmails?: string[] | null;
}

export interface CreateVendorReportPayloadFilter {
  contractIds?: string[] | null;
}

export interface CurrencyConfigPayload {
  currency: string;
}

export interface DateTimeRangeFilter {
  from: any;
  to: any;
  minimumTicketCount?: number | null;
}

export interface DocumentCategoriesQuery {
  limit?: number | null;
  offset?: number | null;
  orderBy?: DocumentCategoriesQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
  filter?: DocumentCategoriesQueryFilter | null;
}

export interface DocumentCategoriesQueryFilter {
  search?: string | null;
  archived?: boolean | null;
  documentVisibility?: DocumentVisibility | null;
}

export interface DocumentElementPayload {
  index: boolean;
  title?: string | null;
  content?: string | null;
  templateElementId?: string | null;
  item?: OfferItemPayload | null;
}

export interface DocumentTagsQuery {
  filter?: DocumentTagsQueryFilter | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: TagsQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
}

export interface DocumentTagsQueryFilter {
  search?: string | null;
  archived?: boolean | null;
  documentVisibility?: DocumentVisibility | null;
}

export interface DocumentTemplateElementFilter {
  search?: string | null;
  public?: boolean | null;
  archived?: boolean | null;
}

export interface DocumentTemplateElementsQuery {
  limit?: number | null;
  offset?: number | null;
  filter?: DocumentTemplateElementFilter | null;
  orderBy?: DocumentTemplateElementsQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
}

export interface DocumentsFilter {
  search?: string | null;
  template?: boolean | null;
  visibility?: DocumentVisibility[] | null;
  categoryIds?: string[] | null;
  tagIds?: string[] | null;
  editorIds?: string[] | null;
  archived?: boolean | null;
  companyIds?: string[] | null;
}

export interface DocumentsQuery {
  limit?: number | null;
  offset?: number | null;
  orderBy?: DocumentsQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
  filter?: DocumentsFilter | null;
}

export interface EnableMfaPayload {
  sms: boolean;
  email: boolean;
}

export interface GenerateInvoiceReportPayload {
  effectiveDate: any;
  includeFilter?: CreateInvoiceReportPayloadFilter | null;
  excludeFilter?: CreateInvoiceReportPayloadFilter | null;
  validFromDate: any;
  send: boolean;
}

export interface GenerateTimeTrackerInvoicePayload {
  effectiveDate: any;
  includeFilter?: CreateTimeTrackerReportPayloadFilter | null;
  excludeFilter?: CreateTimeTrackerReportPayloadFilter | null;
  includeArchived?: boolean | null;
  validFromDate: any;
  archiveAfter?: boolean | null;
  send: boolean;
}

export interface HolidaySchedulesQuery {
  limit?: number | null;
  offset?: number | null;
  filter?: HolidaySchedulesQueryFilter | null;
  orderBy?: HolidaySchedulesQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
}

export interface HolidaySchedulesQueryFilter {
  search?: string | null;
  archived?: boolean | null;
  from?: any | null;
  to?: any | null;
}

export interface HttpHeaderPayload {
  key: string;
  value: string;
}

export interface HttpStatusPayload {
  status: number;
  mode: HttpStatusMode;
}

export interface ImportMicrosoftOffice365InvoicePayload {
  storageObjectId: string;
  validFromDate: any;
  send: boolean;
}

export interface LoginOtpPayload {
  mfaToken: string;
  otp: string;
}

export interface LoginPayload {
  email: string;
  password: string;
  mode?: LoginMode | null;
}

export interface LogsQuery {
  limit?: number | null;
  offset?: number | null;
  includeSystemMessages?: boolean | null;
}

export interface M365ConfigPayload {
  productId: string;
  margin: number;
}

export interface MessageCategoriesQuery {
  limit?: number | null;
  offset?: number | null;
  filter?: MessageCategoriesQueryFilter | null;
  orderBy?: MessageCategoriesQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
}

export interface MessageCategoriesQueryFilter {
  search?: string | null;
  archived?: boolean | null;
}

export interface MessageCenterMessageQuery {
  limit: number;
  offset: number;
}

export interface MessageSubscriptionConfigEntryPayload {
  categoryId: string;
  email: boolean;
  push: boolean;
}

export interface MessageSubscriptionConfigPayload {
  entries: MessageSubscriptionConfigEntryPayload[];
}

export interface MessagesQuery {
  limit?: number | null;
  offset?: number | null;
  filter?: MessagesQueryFilter | null;
  orderBy?: MessagesQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
}

export interface MessagesQueryFilter {
  search?: string | null;
  archived?: boolean | null;
  categoryIds?: string[] | null;
}

export interface NinjaRmmOrganizationsQuery {
  limit?: number | null;
  offset?: number | null;
  filter?: NinjaRmmOrganizationsQueryFilter | null;
  orderBy?: NinjaRmmOrganizationsQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
}

export interface NinjaRmmOrganizationsQueryFilter {
  search?: string | null;
  connected?: boolean | null;
}

export interface NinjaRmmPayload {
  location: NinjaRmmLocation;
  apiKey: string;
  secretKey: string;
  syncIntervalSeconds: number;
}

export interface OfferItemPayload {
  type: OfferItemType;
  title: string;
  index: boolean;
  content?: string | null;
  discountPercent?: number | null;
  templateElementId?: string | null;
  productItems?: OfferItemProductItemPayload[] | null;
  projectItems?: OfferItemProjectItemPayload[] | null;
}

export interface OfferItemProductItemPayload {
  productId: string;
  title: string;
  price: number;
  pieces: number;
  discountPercent: number;
}

export interface OfferItemProjectItemPayload {
  userId: string;
  description: string;
  startDate: any;
  endDate: any;
  status: OfferItemProjectItemStatus;
}

export interface OffersQuery {
  limit?: number | null;
  offset?: number | null;
  filter?: OffersQueryFilter | null;
  orderBy?: OffersQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
}

export interface OffersQueryFilter {
  search?: string | null;
  archived?: boolean | null;
  template?: boolean | null;
  searchName?: string | null;
  companyIds?: string[] | null;
  tagIds?: string[] | null;
}

export interface OpenAIConfigPayload {
  apiKey: string;
  endpoint: string;
  prompt?: string | null;
}

export interface OutlookAppConfigPayload {
  appId: string;
  appSecret: string;
}

export interface PersonCompanyPayload {
  companyId: string;
  permission: CompanyPermission;
}

export interface PersonalTicketsQuery {
  limit?: number | null;
  offset?: number | null;
  filter?: PersonalTicketsQueryFilter | null;
  orderBy?: PersonalTicketsQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
}

export interface PersonalTicketsQueryFilter {
  search?: string | null;
  archived?: boolean | null;
  lastEditorId?: string[] | null;
  statuses?: PersonalTicketStatus[] | null;
  companyIds?: string[] | null;
  searchOpener?: string | null;
}

export interface PersonsQuery {
  limit?: number | null;
  offset?: number | null;
  orderBy?: PersonsQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
  filter?: PersonsQueryFilter | null;
}

export interface PersonsQueryFilter {
  search?: string | null;
  archived?: boolean | null;
  searchEmail?: string | null;
  companyIds?: string[] | null;
}

export interface ProductContractTypesQuery {
  limit?: number | null;
  offset?: number | null;
  filter?: ProductContractTypesQueryFilter | null;
  orderBy?: ProductContractTypesQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
}

export interface ProductContractTypesQueryFilter {
  search?: string | null;
  archived?: boolean | null;
}

export interface ProductReportPayload {
  productId: string;
  pieces: number;
  contractTypeId: string;
}

export interface ProductsQuery {
  limit?: number | null;
  offset?: number | null;
  filter?: ProductsQueryFilter | null;
  orderBy?: ProductsQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
}

export interface ProductsQueryFilter {
  search?: string | null;
  archived?: boolean | null;
  timeTrackerEnabled?: boolean | null;
}

export interface ResetPasswordPayload {
  email: string;
  mode?: LoginMode | null;
  redirectUri: string;
}

export interface SelfAuditConfigPayload {
  complianceRequirements: number;
  contactMe: boolean;
  itStrategy: number;
  itStrategySupport: boolean;
  assesment: number;
  assesmentSupport: boolean;
  proactiveAction: number;
  proactiveActionSupport: boolean;
  identification: number;
  identificationSupport: boolean;
  reaction: number;
  reactionSupport: boolean;
  recovery: number;
  recoverySupport: boolean;
}

export interface SendMessagePayload {
  content: string;
  sendToTwitter: boolean;
  customerIds?: string[] | null;
  productIds?: string[] | null;
  categoryIds?: string[] | null;
}

export interface SendOfferEmailPayload {
  to: string;
  subject: string;
  content: string;
  copy: boolean;
  followUpReminder: boolean;
}

export interface SendPersonalTicketMessagePayload {
  message?: CreatePersonalTicketMessagePayload | null;
  responsiblePersonId?: string | null;
}

export interface SendTicketMessagePayload {
  message?: CreateTicketMessagePayload | null;
  subStatus?: TicketInProgressSubStatus | null;
  responsibleUserId?: string | null;
}

export interface SendTicketSmsPayload {
  cellphone: string;
  message: string;
  shadowLog: boolean;
}

export interface SmsConfigPayload {
  serviceType: SmsServiceType;
  defaultSenderId?: string | null;
  clickSend?: ClickSendConfigPayload | null;
}

export interface StatsTicketReactionQuery {
  filter: StatsTicketReactionQueryFilter;
  step?: number | null;
}

export interface StatsTicketReactionQueryFilter {
  fromTime: any;
  toTime: any;
  responsibleUserId?: string | null;
}

export interface TagsQuery {
  filter?: TagsQueryFilter | null;
  limit?: number | null;
  offset?: number | null;
  orderBy?: TagsQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
}

export interface TagsQueryFilter {
  search?: string | null;
  archived?: boolean | null;
}

export interface TenantConfigPayload {
  logoId?: string | null;
  companyName?: string | null;
  nameSuffix?: string | null;
  website?: string | null;
  language?: string | null;
  responsibleUserFirstName?: string | null;
  responsibleUserLastName?: string | null;
  address1?: string | null;
  zip?: string | null;
  canton?: string | null;
  city?: string | null;
  country?: string | null;
  generalPhone?: string | null;
  generalEmail?: string | null;
  invoiceEmail?: string | null;
  subscriptionType?: TenantSubscriptionType | null;
}

export interface TextTemplatesQuery {
  limit?: number | null;
  offset?: number | null;
  filter?: TextTemplatesQueryFilter | null;
  orderBy?: TextTemplatesQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
}

export interface TextTemplatesQueryFilter {
  search?: string | null;
  archived?: boolean | null;
  types?: TextTemplateType[] | null;
}

export interface ThingsQuery {
  limit?: number | null;
  offset?: number | null;
  filter?: ThingsQueryFilter | null;
  orderBy?: ThingsQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
}

export interface ThingsQueryFilter {
  search?: string | null;
  archived?: boolean | null;
}

export interface TicketsQuery {
  limit?: number | null;
  offset?: number | null;
  filter?: TicketsQueryFilter | null;
  orderBy?: TicketsQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
}

export interface TicketsQueryFilter {
  search?: string | null;
  archived?: boolean | null;
  lastEditorId?: string[] | null;
  statuses?: TicketStatus[] | null;
  companyIds?: string[] | null;
  searchOpener?: string | null;
  onlyMe?: boolean | null;
}

export interface TimeTrackerConfigPayload {
  defaultProjectName: string;
  defaultEntryProductId: string;
}

export interface TimeTrackerProjectAddingPayload {
  productId: string;
  price: number;
  pieces: number;
  discountPercent: number;
  title?: string | null;
}

export interface TimeTrackerProjectAddingQuery {
  limit?: number | null;
  offset?: number | null;
}

export interface TimeTrackerProjectEntryPayload {
  userId: string;
  productId: string;
  date: any;
  durationSeconds: number;
  description: string;
}

export interface TimeTrackerProjectEntryQuery {
  limit?: number | null;
  offset?: number | null;
}

export interface TimeTrackerProjectSpecialRatePayload {
  productId: string;
  amount: number;
}

export interface TimeTrackerProjectsQuery {
  limit?: number | null;
  offset?: number | null;
  filter?: TimeTrackerProjectsQueryFilter | null;
  orderBy?: TimeTrackerProjectsQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
}

export interface TimeTrackerProjectsQueryFilter {
  search?: string | null;
  title?: string | null;
  archived?: boolean | null;
  customerIds?: string[] | null;
  enableInCustomerView?: boolean | null;
}

export interface TwitterConfigPayload {
  consumerKey: string;
  consumerSecret: string;
  tokenKey: string;
  tokenSecret: string;
}

export interface UpdateAssetPayload {
  name?: string | null;
  companyId?: string | null;
  productId?: string | null;
  description?: string | null;
  uptimeSla?: number | null;
  playbook?: string | null;
  assetLocationName?: string | null;
  assetTypeName?: string | null;
  tagNames?: string[] | null;
  responsibleUserIds?: string[] | null;
  customerResponsiblePersonIds?: string[] | null;
  monitor?: ConnectMonitorPayload | null;
  disconnectMonitor?: boolean | null;
  reminders?: AssetReminderPayload[] | null;
  manualLog?: string | null;
  manualLogViewableByCustomer?: boolean | null;
  manualLogTagNames?: string[] | null;
  displayOnDashboard?: boolean | null;
  displayOnCustomer?: boolean | null;
  attachmentIds?: string[] | null;
}

export interface UpdateCompanyPayload {
  name?: string | null;
  idNumber?: number | null;
  address1?: string | null;
  address2?: string | null;
  zip?: string | null;
  city?: string | null;
  region?: string | null;
  country?: string | null;
  canton?: string | null;
  website?: string | null;
  size?: number | null;
  generalPhone?: string | null;
  generalEmail?: string | null;
  status?: CompanyStatus | null;
  liveChat?: boolean | null;
  logoId?: string | null;
  responsibleUserIds?: string[] | null;
  tagNames?: string[] | null;
  manualLog?: string | null;
  manualLogV2?: CreateLogPayload | null;
  primaryPersonId?: string | null;
  persons?: CompanyPersonPayload[] | null;
  ticketDomains?: string[] | null;
  reminders?: AssetReminderPayload[] | null;
  heatLevel?: number | null;
  invoiceEmail?: string | null;
  bexioNoVat?: boolean | null;
  timeTrackerDefaultPrice?: number | null;
  timeTrackerSpecialRates?: TimeTrackerProjectSpecialRatePayload[] | null;
  subscriptionType?: CompanySubscriptionType | null;
  m365Margin?: number | null;
  azureRIMargin?: number | null;
}

export interface UpdateContractPayload {
  name?: string | null;
  customerId?: string | null;
  cycle?: ContractCycle | null;
  yearlyInterval?: number | null;
  startedAt?: any | null;
  contractLength?: number | null;
  cancelPeriod?: number | null;
  adjustmentDate: any;
  products?: ContractProductPayload[] | null;
  reportingProducts?: ContractReportingProductPayload[] | null;
  isAutoRenew?: boolean | null;
}

export interface UpdateDocumentCategoryPayload {
  name?: string | null;
  type?: DocumentCategoryType | null;
}

export interface UpdateDocumentPayload {
  title?: string | null;
  categoryId?: string | null;
  template?: boolean | null;
  visibility?: DocumentVisibility | null;
  permanentLink?: string | null;
  headGraphicId?: string | null;
  index?: boolean | null;
  elements?: DocumentElementPayload[] | null;
  tagNames?: string[] | null;
  customerIds?: string[] | null;
  attachmentIds?: string[] | null;
}

export interface UpdateDocumentTemplateElementPayload {
  title?: string | null;
  content?: string | null;
  public?: boolean | null;
  item?: OfferItemPayload | null;
}

export interface UpdateHelpDocumentPayload {
  shortText: string;
  documentUrl: string;
}

export interface UpdateHolidaySchedulePayload {
  from?: any | null;
  to?: any | null;
}

export interface UpdateInternalTicketPayload {
  content: string;
  attachmentIds: string[];
}

export interface UpdateMessageCategoryPayload {
  name?: string | null;
}

export interface UpdateOfferPayload {
  index?: boolean | null;
  favorite?: boolean | null;
  title?: string | null;
  companyId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  email?: string | null;
  headGraphicId?: string | null;
  responsibleUserIds?: string[] | null;
  tagNames?: string[] | null;
  items?: OfferItemPayload[] | null;
  favoriteTextTemplateId?: string | null;
}

export interface UpdatePersonPayload {
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  email?: string | null;
  companyPhone?: string | null;
  cellphone?: string | null;
  privateAddress1?: string | null;
  privateAddress2?: string | null;
  privateZip?: string | null;
  privateCity?: string | null;
  gender?: Gender | null;
  isVIP?: boolean | null;
  companies?: PersonCompanyPayload[] | null;
  reminders?: AssetReminderPayload[] | null;
  companyId?: string | null;
  avatarId?: string | null;
  tagNames?: string[] | null;
  manualLog?: string | null;
  manualLogV2?: CreateLogPayload | null;
}

export interface UpdateProductContractTypePayload {
  name?: string | null;
  emails?: string[] | null;
}

export interface UpdateProductPayload {
  idNumber?: number | null;
  title?: string | null;
  price?: number | null;
  youtubeUrl?: string | null;
  content?: string | null;
  billing?: ProductBilling | null;
  reactionTime?: number | null;
  tagNames?: string[] | null;
  photoIds?: string[] | null;
  reports?: ProductReportPayload[] | null;
  tdArticle?: string | null;
  bexioAccountId?: string | null;
  timeTrackerEnabled?: boolean | null;
}

export interface UpdateProfilePayload {
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  gender?: Gender | null;
  companyPhone?: string | null;
  cellphone?: string | null;
  address1?: string | null;
  address2?: string | null;
  zip?: string | null;
  city?: string | null;
  ticketSignature?: string | null;
  avatarId?: string | null;
  password?: string | null;
  timeTrackerDefaultProductId?: string | null;
}

export interface UpdateTextTemplatePayload {
  systemName?: string | null;
  title?: string | null;
  content?: string | null;
  public?: boolean | null;
  contentType?: TextTemplateContentType | null;
  to?: string[] | null;
  bcc?: string[] | null;
  cc?: string[] | null;
  messageCenterTo?: string[] | null;
}

export interface UpdateTicketResponsibleUserPayload {
  responsibleUserId: string;
}

export interface UpdateTimeTrackerEntryBulkPayload {
  projectId?: string | null;
  productId?: string | null;
}

export interface UpdateTimeTrackerProjectPayload {
  customerId?: string | null;
  title?: string | null;
  budgetCount?: number | null;
  budgetUnit?: BudgetUnit | null;
  enableInCustomerView?: boolean | null;
  specialRates?: TimeTrackerProjectSpecialRatePayload[] | null;
  entries?: TimeTrackerProjectEntryPayload[] | null;
  addings?: TimeTrackerProjectAddingPayload[] | null;
}

export interface UpdateUserPayload {
  email?: string | null;
  password?: string | null;
  role?: UserRole | null;
  firstName?: string | null;
  lastName?: string | null;
  function?: string | null;
  companyPhone?: string | null;
  cellphone?: string | null;
  address1?: string | null;
  address2?: string | null;
  zip?: string | null;
  city?: string | null;
  ticketSignature?: string | null;
  avatarId?: string | null;
  supportDisabled?: boolean | null;
  timeTrackerDefaultProductId?: string | null;
}

export interface UsersQuery {
  offset?: number | null;
  limit?: number | null;
  filter?: UsersQueryFilter | null;
  orderBy?: UsersQueryOrderBy | null;
  orderDirection?: OrderDirection | null;
}

export interface UsersQueryFilter {
  search?: string | null;
  archived?: boolean | null;
  roles?: UserRole[] | null;
  supportDisabled?: boolean | null;
}

export interface ViewerConfigPayload {
  pushMessages?: boolean | null;
  sendMessagesByEmail?: boolean | null;
  confirmShowAllEmail?: boolean | null;
  language?: string | null;
}

export interface WeekWorkdaySchedulePayload {
  dayOfWeek: number;
  fromTimeSeconds: number;
  toTimeSeconds: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
