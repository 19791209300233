import { Field, FormikProps } from 'formik';
import React, { FC } from 'react';

type Props = {
  name: string;
  label: string;
};

const FieldCheckbox: FC<Props> = ({ name, label }) => {
  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (e: any) => {
          form.setFieldValue(name, e.target.checked);
        };

        return (
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`cb-${name}`}
              checked={field.value}
              onChange={handleChange}
            />
            <label className="custom-control-label" htmlFor={`cb-${name}`}>
              {label}
            </label>
          </div>
        );
      }}
    </Field>
  );
};

export default FieldCheckbox;
