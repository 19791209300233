import { formatDistance } from 'date-fns';
import { enUS as en, de } from 'date-fns/locale';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useLanguage } from '../../schema/locale';
import { dateTimeOptions } from '../../utils/const';
import DateFormat from './DateFormat';

const locales: any = { en, de };

type Props = {
  id: any;
  value: string;
};

const TimeAgo: FunctionComponent<Props> = ({ id, value }) => {
  const [ago, setAgo] = useState('');
  const interval = useRef<any>();
  const language = useLanguage();

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current);
    }

    setAgo(
      formatDistance(new Date(value), new Date(), {
        locale: locales[language],
        addSuffix: true,
      }),
    );

    interval.current = setInterval(() => {
      setAgo(
        formatDistance(new Date(value), new Date(), {
          locale: locales[language],
          addSuffix: true,
        }),
      );
    }, 60000);

    return () => {
      clearInterval(interval.current);
    };
  }, [value, language]);

  return (
    <>
      <div data-tip data-for={`date-${id}`}>
        {ago}
      </div>
      <ReactTooltip id={`date-${id}`}>
        <DateFormat value={new Date(value)} format={dateTimeOptions} />
      </ReactTooltip>
    </>
  );
};

export default TimeAgo;
