import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { DISABLE_PERSON_MFA } from '../../schema/person';
import { disablePersonMfa } from '../../schema/types/disablePersonMfa';
import { toastErrorQuery } from '../../utils/error';
import ButtonSubmit from '../form/ButtonSubmit';

type Props = {
  id: any;
  show: boolean;
  onClose: () => void;
};

const PersonDisableMfaModal: FunctionComponent<Props> = ({ id, show, onClose }) => {
  const [mutate] = useMutation<disablePersonMfa>(DISABLE_PERSON_MFA, {
    variables: {
      id,
    },
  });

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Disable Two Factor Authentication</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              initialValues={{}}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  const result: any = await mutate();

                  if (result.errors) {
                    toastErrorQuery(result.errors);
                  } else {
                    resetForm();
                    toast.success(i18n._(t`Success!`));
                    onClose();
                  }
                } catch (e) {
                  toastErrorQuery(e);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting, submitForm }) => {
                const handleSubmit = (e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  submitForm();
                };

                return (
                  <Form onSubmitCapture={handleSubmit}>
                    <div className="modal-body">
                      <div className="form-group text-danger">
                        <Trans>Are you sure want to disable Two Factor Authentication?</Trans>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={onClose}>
                        <Trans>Close</Trans>
                      </button>

                      <ButtonSubmit
                        className="btn btn-primary"
                        title={i18n._(t`Disable Two Factor`)}
                        loading={isSubmitting}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PersonDisableMfaModal;
