import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent, useState } from 'react';
import { PERSON_GET_COMPANY } from '../../schema/company';
import { personGetCompany, personGetCompanyVariables } from '../../schema/types/personGetCompany';
import { getCompanyId } from '../../utils/auth';
import { getPhone } from '../../utils/utils';
import LoadingQuery from '../common/LoadingQuery';
import UserAvatar from '../common/UserAvatar';
import ErrorQuery from '../form/ErrorQuery';

const PersonHomeContact: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);

  const { data, loading, error } = useQuery<personGetCompany, personGetCompanyVariables>(PERSON_GET_COMPANY, {
    variables: {
      query: getCompanyId(),
    },
  });

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;
  if (!loaded) return <LoadingQuery wrapper />;
  if (!data?.company.responsibleUsers || data.company.responsibleUsers.length === 0) return <div />;

  return (
    <div className="card person-home-contacts">
      <div className="card-header">
        <Trans>Direct Contacts</Trans>
      </div>

      <div className="card-body">
        <div className="row">
          {data?.company.responsibleUsers.map((el, index) => {
            if (!el.id) return null;

            return (
              <div key={index} className="item col-xxl-4">
                {el.avatar && (
                  <div className="avatar">
                    <UserAvatar user={el} />
                  </div>
                )}

                <div className="content">
                  <div className="label-lg">
                    {index === 0 && <Trans>Account Manager</Trans>}
                    {index === 1 && <Trans>Primary Engineer</Trans>}
                    {index === 2 && <Trans>Secondary Engineer</Trans>}
                  </div>

                  <div className="name">{el.fullName}</div>

                  <span className="badge">{el.function}</span>
                  <br />
                  {el.companyPhone && (
                    <a href={`tel:${getPhone(el.companyPhone)}`} className="link text-break">
                      <i className="icon-phone-number" />
                      {el.companyPhone}
                    </a>
                  )}
                  <br />
                  <a href={`mailto:${el.email}`} className="link text-break">
                    <i className="icon-mail" />
                    {el.email}
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PersonHomeContact;
