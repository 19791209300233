import { useMutation, useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SignaturePad from 'signature_pad';
import ButtonSubmit from '../components/form/ButtonSubmit';
import ErrorQuery from '../components/form/ErrorQuery';
import { ACCEPT_OFFER, GET_OFFER_BY_TOKEN } from '../schema/offer';
import { acceptOffer, acceptOfferVariables } from '../schema/types/acceptOffer';
import { createStorageObject } from '../schema/types/createStorageObject';
import { getOfferByToken, getOfferByTokenVariables } from '../schema/types/getOfferByToken';
import { CREATE_STORAGE_OBJECT, createStorageObjectVariables } from '../schema/upload';
import { DefaultRouteParams } from '../utils/const';
import useAuthLayout from '../utils/useAuthLayout';
import NotFoundPage from './NotFoundPage';

const dataURLtoFile = (data: string, filename: string) => {
  const arr = data.split(',');
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

const textToImage = (text: string) => {
  const placeholder = document.createElement('canvas');

  const ctxPlaceholder = placeholder.getContext('2d');
  ctxPlaceholder!.font = '30px Calligraffitti';
  ctxPlaceholder!.fillText(text, 30, 50);

  const canvas = document.createElement('canvas');
  canvas.width = ctxPlaceholder!.measureText(text).width + 60;
  canvas.height = 80;

  const ctx = canvas.getContext('2d');
  ctx!.font = '30px Calligraffitti';
  ctx!.fillText(text, 30, 50);

  return canvas.toDataURL();
};

const AcceptOfferPage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ match }) => {
  useAuthLayout();

  const signatureRef = useRef<HTMLCanvasElement>(null);
  const [signature, setSignature] = useState<SignaturePad>();

  const [showDraw, setShowDraw] = useState(false);
  const [preview, setPreview] = useState<string>();
  const [success, setSuccess] = useState(false);

  const url = new URLSearchParams(window.location.search);
  const {
    data,
    loading,
    error: loadError,
  } = useQuery<getOfferByToken, getOfferByTokenVariables>(GET_OFFER_BY_TOKEN, {
    variables: { token: url.get('token')! },
  });

  const [upload] = useMutation<createStorageObject, createStorageObjectVariables>(CREATE_STORAGE_OBJECT);
  const [mutate, { error }] = useMutation<acceptOffer, acceptOfferVariables>(ACCEPT_OFFER);

  useEffect(() => {
    if (signatureRef.current) {
      const signaturePad = new SignaturePad(signatureRef.current, {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        penColor: 'rgb(0, 0, 0)',
      });

      setSignature(signaturePad);
    }
  }, []);

  const handleClear = useCallback(() => {
    if (signature) {
      signature.clear();
      setPreview(undefined);
    }
  }, [signature]);

  const handleSave = useCallback(() => {
    if (signature) {
      const data = signature.toDataURL('image/png');
      setPreview(signature.isEmpty() ? undefined : data);
      setShowDraw(false);
    }
  }, [signature]);

  return (
    <div className="offer-sign-area-wrap">
      <div className="auth-area">
        <div className="content offer-sign-area">
          <div className="form">
            {error && <ErrorQuery error={error} />}

            {!data && loading && (
              <div className="text-center">
                <Trans>Loading...</Trans>
              </div>
            )}

            {loadError && <ErrorQuery error={loadError} />}

            {!data && !loading && <NotFoundPage />}
          </div>

          <div className={classNames({ 'd-none': !data })}>
            <Formik
              enableReinitialize
              initialValues={{
                fullName: data?.offerByToken.fullName || '',
              }}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const storage = await upload({
                    variables: {
                      payload: {
                        filename: 'sign.png',
                        mime: 'image/png',
                      },
                    },
                  });

                  const signatureObjectId: string = await new Promise((resolve) => {
                    const xhr = new XMLHttpRequest();

                    // done upload
                    xhr.onreadystatechange = () => {
                      if (xhr.readyState === 4) {
                        if (xhr.status === 200 || xhr.status === 201) {
                          resolve(storage.data!.createStorageObject.storageObject.id);
                        }
                      }
                    };

                    if (storage.data) {
                      // up!
                      xhr.open('PUT', storage.data.createStorageObject.uploadUrl, true);
                      storage.data.createStorageObject.uploadExtraHeaders.forEach((el) => {
                        xhr.setRequestHeader(el.key, el.value);
                      });
                      xhr.send(dataURLtoFile(preview || textToImage(values.fullName), 'sign.png'));
                    }
                  });

                  await mutate({
                    variables: {
                      signatureObjectId,
                      token: url.get('token')!,
                      signatureFullname: values.fullName,
                    },
                  });

                  setSuccess(true);

                  window.location.href = `${process.env.REACT_APP_URL}offer-accepted?token=${url.get('token')}`;
                } catch (e) {}

                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="form">
                  <h1 className="main-title">{data?.offerByToken.title}</h1>

                  {success ? (
                    <div className="text-primary text-center">
                      <Trans>Success</Trans>
                    </div>
                  ) : (
                    <>
                      <div className="form-group">
                        <Field
                          className="form-control"
                          type="text"
                          name="fullName"
                          placeholder={i18n._(t`Full Name`)}
                        />
                      </div>

                      {!showDraw && (
                        <>
                          <div className="form-group">
                            <a className="text-primary" onClick={() => setShowDraw(true)}>
                              Draw / Zeichnen
                            </a>
                          </div>

                          {!preview ? (
                            <Field name="fullName">
                              {({ field }: { field: any }) => {
                                return <div className="form-group sign">{field.value}</div>;
                              }}
                            </Field>
                          ) : (
                            <img src={preview} alt="" />
                          )}
                        </>
                      )}

                      <div className={classNames({ 'd-none': !showDraw })}>
                        <div className="text-sm">Draw your signature here</div>

                        <div className="card">
                          <canvas ref={signatureRef} className="signature-pad" width="400" height="150" />
                        </div>

                        <div className="form-group">
                          <button type="button" className="mr-5 text-muted" onClick={() => setShowDraw(false)}>
                            <Trans>Cancel</Trans>
                          </button>

                          <button type="button" className="text-warning mr-5" onClick={handleClear}>
                            <Trans>Clear</Trans>
                          </button>

                          <button type="button" className="text-primary" onClick={handleSave}>
                            <Trans>Save</Trans>
                          </button>
                        </div>
                      </div>

                      <div className="form-group text-sm">
                        Ich bin einverstanden, dass meine Signatur durch die elektronische Signatur oberhalb
                        repräsentiert wird und bestätige damit das Angebot / I agree that my signature is represented by
                        the electronic signature above and I thereby confirm the offer.
                      </div>

                      {!showDraw && (
                        <div className="container-login100-form-btn">
                          <ButtonSubmit title={i18n._(t`Send`)} className="btn btn-primary" loading={isSubmitting} />
                        </div>
                      )}
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AcceptOfferPage);
