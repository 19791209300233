import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import {
  GET_OUTLOOK_TICKET_DONE_MAIL_FOLDER,
  GET_OUTLOOK_TICKET_MAIL_FOLDERS,
  UPDATE_OUTLOOK_TICKET_DONE_MAIL_FOLDER,
} from '../../schema/config';
import { getOutlookTicketDoneMailFolder } from '../../schema/types/getOutlookTicketDoneMailFolder';
import { getOutlookTicketMailFolders } from '../../schema/types/getOutlookTicketMailFolders';
import {
  updateOutlookTicketDoneMailFolder,
  updateOutlookTicketDoneMailFolderVariables,
} from '../../schema/types/updateOutlookTicketDoneMailFolder';
import { toastErrorQuery } from '../../utils/error';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';

type Props = {
  show: boolean;
  onClose: () => void;
};

const ConfigurationTicketMailFolderModal: FunctionComponent<Props> = ({ show, onClose }) => {
  const outlookTicketMailFolders = useQuery<getOutlookTicketMailFolders>(GET_OUTLOOK_TICKET_MAIL_FOLDERS);

  const outlookTicketMailDoneFolder = useQuery<getOutlookTicketDoneMailFolder>(GET_OUTLOOK_TICKET_DONE_MAIL_FOLDER);

  const [mutate] = useMutation<updateOutlookTicketDoneMailFolder, updateOutlookTicketDoneMailFolderVariables>(
    UPDATE_OUTLOOK_TICKET_DONE_MAIL_FOLDER,
    {
      refetchQueries: [
        {
          query: GET_OUTLOOK_TICKET_DONE_MAIL_FOLDER,
        },
      ],
    },
  );

  const model: any = {
    id:
      outlookTicketMailDoneFolder.data && outlookTicketMailDoneFolder.data.outlookTicketDoneMailFolder
        ? outlookTicketMailDoneFolder.data.outlookTicketDoneMailFolder.id
        : '',
  };

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Ticket Done Mail Folder</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              enableReinitialize
              initialValues={model}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const result: any = await mutate({
                    variables: {
                      payload: values.id,
                    },
                  });

                  if (result.errors) {
                    toastErrorQuery(result.errors);
                  } else {
                    toast.success(i18n._(t`Success!`));
                    onClose();
                  }
                } catch (e) {
                  toastErrorQuery(e);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting, submitForm }) => {
                const handleSubmit = (e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  submitForm();
                };

                return (
                  <Form onSubmitCapture={handleSubmit}>
                    <div className="modal-body">
                      <div className="form-group">
                        <label>
                          <Trans>Folder</Trans>
                        </label>
                        <Field component="select" className="form-control" name="id">
                          {outlookTicketMailFolders.data &&
                            outlookTicketMailFolders.data.outlookTicketMailFolders &&
                            outlookTicketMailFolders.data.outlookTicketMailFolders.map((el: any) => (
                              <option key={el.id} value={el.id}>
                                {el.displayName}
                              </option>
                            ))}
                        </Field>

                        <FieldErrorMessage name="id" />
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={onClose}>
                        <Trans>Close</Trans>
                      </button>

                      <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfigurationTicketMailFolderModal;
