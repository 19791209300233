import classNames from 'classnames';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';

type Props = {
  name: string;
};

const FieldSlider: FunctionComponent<Props> = ({ name }) => {
  const key = Math.random();

  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (value: number) => () => {
          form.setFieldValue(name, value);
        };

        return (
          <div className="field-slider">
            <button
              data-tip
              data-for={`slider-1-${key}`}
              type="button"
              className={classNames('field-slider-item field-slider-1', { active: field.value })}
              onClick={handleChange(1)}
            />
            <button
              data-tip
              data-for={`slider-2-${key}`}
              type="button"
              className={classNames('field-slider-item field-slider-2', { active: field.value > 1 })}
              onClick={handleChange(2)}
            />
            <button
              data-tip
              data-for={`slider-3-${key}`}
              type="button"
              className={classNames('field-slider-item field-slider-3', { active: field.value > 2 })}
              onClick={handleChange(3)}
            />
            <button
              data-tip
              data-for={`slider-4-${key}`}
              type="button"
              className={classNames('field-slider-item field-slider-4', { active: field.value > 3 })}
              onClick={handleChange(4)}
            />
            <button
              data-tip
              data-for={`slider-5-${key}`}
              type="button"
              className={classNames('field-slider-item field-slider-5', { active: field.value > 4 })}
              onClick={handleChange(5)}
            />

            <ReactTooltip id={`slider-1-${key}`}>1</ReactTooltip>
            <ReactTooltip id={`slider-2-${key}`}>2</ReactTooltip>
            <ReactTooltip id={`slider-3-${key}`}>3</ReactTooltip>
            <ReactTooltip id={`slider-4-${key}`}>4</ReactTooltip>
            <ReactTooltip id={`slider-5-${key}`}>5</ReactTooltip>
          </div>
        );
      }}
    </Field>
  );
};

export default FieldSlider;
