import { Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import FieldEditor from '../form/FieldEditor';
import FieldErrorMessage from '../form/FieldErrorMessage';
import ImageUploader from '../form/ImageUploader';
import PhoneInput from '../form/PhoneInput';
import TimeTrackerProductForm from '../timeTracker/TimeTrackerProductForm';
import ProfileDisableMfaModal from './ProfileDisableMfaModal';
import ProfileMfaModal from './ProfileMfaModal';

type Props = {
  mfa: boolean;
};

const ProfileAdminUpdateForm: FunctionComponent<Props> = ({ mfa }) => {
  const [showMfa, setShowMfa] = useState(false);
  const [showDisableMfa, setShowDisableMfa] = useState(false);

  const toggleMfa = useCallback(() => setShowMfa((prevState) => !prevState), []);

  const toggleDisableMfa = useCallback(() => setShowDisableMfa((prevState) => !prevState), []);

  return (
    <div>
      <div className="card">
        <div className="row">
          <div className="col-xl-6 panel-column-left">
            <div className="card-header">
              <Trans>Profile Details</Trans>
            </div>

            <div className="card-body">
              <div className="meta">
                <div className="avatar form-group">
                  <label>
                    <Trans>Avatar</Trans>
                  </label>
                  <Field name="avatar.url">
                    {({ field, form }: { field: any; form: FormikProps<any> }) => (
                      <ImageUploader
                        onUpload={(id) => form.setFieldValue('avatarId', id)}
                        image={field.value}
                        placeholder="300x300"
                      />
                    )}
                  </Field>
                </div>

                <div className="info">
                  <div className="form-group">
                    <label>
                      <Trans>First Name</Trans>
                    </label>
                    <Field type="text" className="form-control" name="firstName" autoFocus />

                    <FieldErrorMessage name="firstName" />
                  </div>

                  <div className="form-group">
                    <label>
                      <Trans>Last Name</Trans>
                    </label>
                    <Field type="text" className="form-control" name="lastName" />

                    <FieldErrorMessage name="lastName" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <Trans>Function</Trans>
                    </label>
                    <Field type="text" className="form-control" name="function" />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <Trans>Email</Trans>
                    </label>
                    <Field type="email" className="form-control" name="email" readOnly />

                    <FieldErrorMessage name="email" />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <Trans>Default Time Tracker Type</Trans>
                    </label>
                    <TimeTrackerProductForm name="timeTrackerDefaultProduct" />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label>
                      <Trans>Ticket Signature</Trans>
                    </label>

                    <FieldEditor name="ticketSignature" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="card-header">
              <Trans>Contact Information</Trans>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <Trans>Address 1</Trans>
                    </label>
                    <Field type="text" className="form-control" name="address1" />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <Trans>Address 2</Trans>
                    </label>
                    <Field type="text" className="form-control" name="address2" />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <Trans>Zip</Trans>
                    </label>
                    <Field type="text" className="form-control" name="zip" />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <Trans>City</Trans>
                    </label>
                    <Field type="text" className="form-control" name="city" />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <Trans>Company Phone</Trans>
                    </label>
                    <PhoneInput name="companyPhone" />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      <Trans>Cellphone</Trans>
                    </label>
                    <PhoneInput name="cellphone" />
                  </div>
                </div>
              </div>

              {!mfa ? (
                <button type="button" className="btn btn-link btn-icon" onClick={toggleMfa}>
                  <i className="icon-2FA" />
                  <Trans>Configure Two Factor</Trans>
                </button>
              ) : (
                <button type="button" className="btn btn-link btn-icon" onClick={toggleDisableMfa}>
                  <i className="icon-2FA" />
                  <Trans>Disable Two Factor</Trans>
                </button>
              )}

              <Field name="cellphone">
                {({ field }: any) => <ProfileMfaModal phone={field.value} show={showMfa} onClose={toggleMfa} />}
              </Field>

              <ProfileDisableMfaModal show={showDisableMfa} onClose={toggleDisableMfa} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileAdminUpdateForm;
