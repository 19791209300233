import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import FieldErrorMessage from '../form/FieldErrorMessage';
import ImageUploader from '../form/ImageUploader';

type Props = {};

const TenantDetailForm: FunctionComponent<Props> = () => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="form-group">
          <label>
            <Trans>Company Logo</Trans>
          </label>

          <div className="d-flex align-items-center">
            <Field name="logo.url">
              {({ field, form }: { field: any; form: FormikProps<any> }) => (
                <ImageUploader onUpload={(id) => form.setFieldValue('logoId', id)} image={field.value} />
              )}
            </Field>

            <label className="ml-2 flex-grow-1">
              <i className="icon-details" />
              <Trans>Logo size should be not bigger than 5 Mb</Trans>
            </label>
          </div>
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Company</Trans>
          </label>
          <Field type="text" className="form-control" name="companyName" autoFocus />

          <FieldErrorMessage name="companyName" />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Name Suffix</Trans>
          </label>
          <Field type="text" className="form-control" name="nameSuffix" />

          <FieldErrorMessage name="nameSuffix" />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Website</Trans>
          </label>

          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <Field type="url" className="form-control" name="website" />
            </div>

            <Field name="website">
              {({ field }: { field: any }) => {
                if (!field.value) return '';

                return (
                  <>
                    <a data-tip data-for="website" href={field.value} target="_blank" className="ml-2">
                      <i className="icon-preview" />
                    </a>
                    <ReactTooltip id="website">
                      <Trans>View</Trans>
                    </ReactTooltip>
                  </>
                );
              }}
            </Field>
          </div>

          <FieldErrorMessage name="website" />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Language</Trans>
          </label>

          <Field component="select" className="form-control" name="language">
            <option value="">{i18n._(t`Select`)}</option>
            <option value="en">{i18n._(t`English`)}</option>
            <option value="de">{i18n._(t`Germany`)}</option>
          </Field>
        </div>
      </div>

      <label className="mb-2">Responsible person information</label>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>First Name</Trans>
          </label>
          <Field type="text" className="form-control" name="responsibleUserFirstName" />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Last Name</Trans>
          </label>
          <Field type="text" className="form-control" name="responsibleUserLastName" />
        </div>
      </div>
    </div>
  );
};

export default TenantDetailForm;
