import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { CompanyStatus } from '../../schema/graphql-global-types';
import { selectOptions } from '../../utils/const';

type Props = {
  name: string;
};

const ReportCompanyStatusForm: FunctionComponent<Props> = ({ name }) => {
  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (values: any) => {
          form.setFieldValue(name, values ? values.map((el: any) => el.value) : []);
        };

        const model = field.value
          ? field.value.map((el: any) => ({
              value: el.value,
              label: el.label,
            }))
          : null;

        const options = [
          {
            value: CompanyStatus.Default,
            label: i18n._(t`Default`),
          },
          {
            value: CompanyStatus.Cold,
            label: i18n._(t`Cold`),
          },
          {
            value: CompanyStatus.Touched,
            label: i18n._(t`Touched`),
          },
          {
            value: CompanyStatus.Customer,
            label: i18n._(t`Customer`),
          },
          {
            value: CompanyStatus.Partner,
            label: i18n._(t`Partner`),
          },
        ];

        return (
          <Select
            defaultValue={model}
            options={options}
            isMulti
            cacheOptions
            defaultOptions
            onChange={handleChange}
            placeholder={i18n._(t`All Company Statuses`)}
            {...selectOptions}
          />
        );
      }}
    </Field>
  );
};

export default ReportCompanyStatusForm;
