import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { SendTicketSmsPayload } from '../../schema/graphql-global-types';
import { validateSMSForm } from '../../schema/sms';
import { GET_TICKET, SEND_TICKET_SMS } from '../../schema/ticket';
import { sendTicketSms, sendTicketSmsVariables } from '../../schema/types/sendTicketSms';
import { toastErrorQuery } from '../../utils/error';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldCheckbox from '../form/FieldCheckbox';
import FieldErrorMessage from '../form/FieldErrorMessage';
import PhoneInput from '../form/PhoneInput';

type Props = {
  ticketId: any;
  phone?: string | null;
};

const TicketSmsForm: FunctionComponent<Props> = ({ ticketId, phone = '' }) => {
  const [mutate] = useMutation<sendTicketSms, sendTicketSmsVariables>(SEND_TICKET_SMS, {
    refetchQueries: [
      {
        query: GET_TICKET,
        variables: {
          query: ticketId,
        },
      },
    ],
  });

  const initialValues: SendTicketSmsPayload = {
    cellphone: phone || '+41',
    message: '',
    shadowLog: true,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={yup.object().shape(validateSMSForm)}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const result: any = await mutate({
            variables: {
              id: ticketId,
              payload: values,
            },
          });

          if (result.errors) {
            toastErrorQuery(result.errors);
          } else {
            toast.success(i18n._(t`Success!`));
          }
        } catch (e) {
          toastErrorQuery(e);
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="ticket-sms-form">
            <div className="form-group">
              <label className="label">
                <Trans>Send SMS</Trans>
              </label>

              <PhoneInput name="cellphone" placeholder={i18n._(t`Phone number`)} />

              <FieldErrorMessage name="cellphone" />
            </div>

            <Field
              component="textarea"
              className="form-control"
              name="message"
              rows={3}
              placeholder={i18n._(t`SMS Text`)}
            />

            <FieldErrorMessage name="message" />
          </div>

          <div className="actions row align-items-center">
            <div className="col-sm-6">
              <FieldCheckbox name="shadowLog" label={i18n._(t`Shadow Log`)} />
            </div>

            <div className="col-sm-6 text-right">
              <ButtonSubmit className="btn btn-primary btn-sm" title={i18n._(t`Send SMS`)} loading={isSubmitting} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TicketSmsForm;
