import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import ButtonSubmit from '../form/ButtonSubmit';
import ContractDetailForm from './ContractDetailForm';

type Props = {
  loading?: boolean;
};

const ContractCreateForms: FunctionComponent<Props> = ({ loading }) => {
  return (
    <Form>
      <div className="form-breadcrumbs-area">
        <NavLink to="/crm/contract">
          <Trans>Contract</Trans>
        </NavLink>

        <i className="fa fa-angle-left" />

        <Field name="id">
          {({ field }: { field: FieldInputProps<string> }) => {
            if (field.value) return <Trans>Edit contract</Trans>;

            return <Trans>Add new contract</Trans>;
          }}
        </Field>
      </div>

      <div className="table-actions form-table-actions">
        <div className="breadcrumbs-area">
          <Field name="name">
            {({ field }: { field: FieldInputProps<string> }) => {
              return <div className="main-title">{field.value}</div>;
            }}
          </Field>
        </div>

        <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save updates`)} loading={loading} />
      </div>

      <div className="contract-form-page">
        <div className="card">
          <div className="card-body">
            <ContractDetailForm />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default ContractCreateForms;
