import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Form } from 'formik';
import React, { FunctionComponent } from 'react';
import MainTitle from '../common/MainTitle';
import ButtonSubmit from '../form/ButtonSubmit';
import PersonCompanyDetailForm from './PersonCompanyDetailForm';
import PersonCompanyInfoForm from './PersonCompanyInfoForm';
import PersonCompanyPersonForm from './PersonCompanyPersonForm';

type Props = {
  loading?: boolean;
};

const PersonCompanyForms: FunctionComponent<Props> = ({ loading }) => {
  return (
    <Form>
      <div className="breadcrumbs-area">
        <MainTitle title={i18n._(t`Company Profile`)} />
      </div>

      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-6 panel-column-left">
              <div className="card-header">
                <Trans>Company Details</Trans>
              </div>

              <PersonCompanyDetailForm />

              <PersonCompanyPersonForm />
            </div>

            <div className="col-xl-6">
              <div className="card-header">
                <Trans>Contact information</Trans>
              </div>

              <PersonCompanyInfoForm />
            </div>
          </div>

          <div className="action-submit">
            <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={loading} />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default PersonCompanyForms;
