import gql from 'graphql-tag';
import { pick } from 'lodash';
import { validateRequired } from '../utils/validations';
import { DOCUMENT_CATEGORY } from './documentCategory';
import { OfferItemType } from './graphql-global-types';
import { OFFER_ITEM } from './offer';
import { TAG } from './tag';
import { DocumentDetail } from './types/DocumentDetail';
import { DocumentElement } from './types/DocumentElement';
import { OfferProductItem } from './types/OfferProductItem';
import { OfferProjectItem } from './types/OfferProjectItem';

export const DOCUMENT = gql`
  fragment Document on Document {
    id
    title
    customers {
      id
      name
    }
    category {
      ...DocumentCategory
    }
    tags {
      ...Tag
    }
    editor {
      id
      fullName
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
    updatedAt
    visibility
    publicUrl
  }
  ${TAG}
  ${DOCUMENT_CATEGORY}
`;

export const DOCUMENTS = gql`
  fragment Documents on DocumentCollection {
    limit
    offset
    total
    items {
      ...Document
    }
  }
  ${DOCUMENT}
`;

export const DOCUMENT_ELEMENT = gql`
  fragment DocumentElement on DocumentElement {
    id
    title
    content
    index
    item {
      ...OfferItem
    }
    templateElement {
      id
      content
    }
    templateElementId @client
  }
  ${OFFER_ITEM}
`;

export const GET_DOCUMENTS = gql`
  query getDocuments($query: DocumentsQuery!) {
    documents(query: $query) {
      ...Documents
    }
  }
  ${DOCUMENTS}
`;

export const GET_DOCUMENTS_FOR_TICKET = gql`
  query getDocumentsTicket($query: DocumentsQuery!) {
    documents(query: $query) {
      limit
      offset
      total
      items {
        id
        title
        publicUrl
      }
    }
  }
`;

export const PERSON_GET_DOCUMENTS = gql`
  query personGetDocuments($id: ID!, $query: DocumentsQuery!) {
    company(id: $id) {
      id
      documents(query: $query) {
        limit
        offset
        total
        items {
          id
          title
          category {
            id
            name
          }
          tags {
            ...Tag
          }
          publicUrl
        }
      }
    }
  }
  ${TAG}
`;

export const PERSON_GET_DOCUMENTS_FOR_TICKET = gql`
  query personGetDocumentsTicket($id: ID!, $query: DocumentsQuery!) {
    company(id: $id) {
      id
      documents(query: $query) {
        limit
        offset
        total
        items {
          id
          title
          publicUrl
        }
      }
    }
  }
`;

export const DOCUMENT_DETAIL = gql`
  fragment DocumentDetail on Document {
    id
    title
    category {
      ...DocumentCategory
    }
    tags {
      ...Tag
    }
    permanentLink
    customers {
      id
      name
    }
    headGraphic {
      id
      url
    }
    elements {
      ...DocumentElement
    }
    visibility
    template
    publicUrl
    index
    versions(query: { limit: 20, orderBy: id, orderDirection: Desc }) {
      items {
        id
        title
        publicUrl
        createdAt
      }
    }
    attachments {
      id
      url
      filename
    }
    attachments_v2 {
      permanentLink
    }
    tagNames @client
    categoryId @client
    customerIds @client
    attachmentIds @client
    headGraphicId @client
  }
  ${TAG}
  ${DOCUMENT_ELEMENT}
  ${DOCUMENT_CATEGORY}
`;

export const ExtendDocument = {
  tagNames: (obj: DocumentDetail) => obj.tags.map((el) => el.name),
  categoryId: (obj: DocumentDetail) => obj.category.id,
  customerIds: (obj: DocumentDetail) => obj.customers.map((el) => el.id),
  attachmentIds: (obj: DocumentDetail) => obj.attachments.map((el) => el.id),
  headGraphicId: (obj: DocumentDetail) => (obj.headGraphic ? obj.headGraphic.id : null),
  permanentLink: (obj: DocumentDetail) => obj.permanentLink || '',
};

export const ExtendDocumentElement = {
  templateElementId: (obj: DocumentElement) => (obj.templateElement ? obj.templateElement.id : null),
};

export const DOCUMENTS_WITH_DETAIL = gql`
  fragment DocumentsDetail on DocumentCollection {
    limit
    offset
    total
    items {
      ...DocumentDetail
    }
  }
  ${DOCUMENT_DETAIL}
`;

export const GET_DOCUMENTS_WITH_DETAIL = gql`
  query getDocumentsWithDetail($query: DocumentsQuery!) {
    documents(query: $query) {
      ...DocumentsDetail
    }
  }
  ${DOCUMENTS_WITH_DETAIL}
`;

export const GET_DOCUMENT = gql`
  query getDocument($query: ID!) {
    document(id: $query) {
      ...DocumentDetail
    }
  }
  ${DOCUMENT_DETAIL}
`;

export const CREATE_DOCUMENT = gql`
  mutation createDocument($payload: CreateDocumentPayload!) {
    createDocument(payload: $payload) {
      document {
        ...DocumentDetail
      }
    }
  }
  ${DOCUMENT_DETAIL}
`;

export const UPDATE_DOCUMENT = gql`
  mutation updateDocument($id: ID!, $payload: UpdateDocumentPayload!) {
    updateDocument(id: $id, payload: $payload) {
      document {
        ...DocumentDetail
      }
    }
  }
  ${DOCUMENT_DETAIL}
`;

export const ARCHIVE_DOCUMENT = gql`
  mutation archiveDocument($id: ID!) {
    archiveDocument(id: $id) {
      document {
        id
      }
    }
  }
`;

export const UNARCHIVE_DOCUMENT = gql`
  mutation unarchiveDocument($id: ID!) {
    unarchiveDocument(id: $id) {
      document {
        id
      }
    }
  }
`;

export const RECOVER_DOCUMENT = gql`
  mutation recoverDocument($id: ID!, $versionId: ID!) {
    recoverDocument(id: $id, versionId: $versionId) {
      document {
        ...DocumentDetail
      }
    }
  }
  ${DOCUMENT_DETAIL}
`;

export const documentKeys = [
  'title',
  'categoryId',
  'template',
  'visibility',
  'permanentLink',
  'headGraphicId',
  'elements',
  'tagNames',
  'customerIds',
  'attachmentIds',
  'index',
];

export const validateDocumentForm = {
  title: validateRequired,
  categoryId: validateRequired,
};

export const extractDocumentData = (model: any) => {
  const result = pick(model, documentKeys);

  result.elements = result.elements.map((el: DocumentElement) => ({
    ...pick(el, ['index', 'title', 'content', 'templateElementId']),
    item: el.item
      ? {
          ...pick(el.item, ['type', 'index', 'title', 'content']),
          discountPercent: Number(el.item.discountPercent),
          productItems:
            el.item.type === OfferItemType.Product
              ? el.item.productItems!.map((item: OfferProductItem) => {
                  const result = pick(item, ['productId', 'title', 'price', 'pieces', 'discountPercent']);

                  result.price = Number(result.price);
                  result.pieces = Number(result.pieces);
                  result.discountPercent = Number(result.discountPercent);

                  return result;
                })
              : [],
          projectItems:
            el.item.type === OfferItemType.Project
              ? el.item.projectItems!.map((item: OfferProjectItem) =>
                  pick(item, ['userId', 'description', 'startDate', 'endDate', 'status']),
                )
              : [],
        }
      : null,
  }));

  return result;
};
