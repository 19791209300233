import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import {
  GET_BEXIO_ACCOUNTS,
  GET_BEXIO_BANK_ACCOUNTS,
  GET_BEXIO_INVOICE_CONFIG,
  GET_BEXIO_PAYMENT_TYPES,
  GET_BEXIO_TAXES,
  UPDATE_BEXIO_INVOICE_CONFIG,
} from '../../schema/config';
import { BexioInvoiceConfigPayload, BexioMswtType } from '../../schema/graphql-global-types';
import { getBexioAccounts } from '../../schema/types/getBexioAccounts';
import { getBexioBankAccounts } from '../../schema/types/getBexioBankAccounts';
import { getBexioInvoiceConfig } from '../../schema/types/getBexioInvoiceConfig';
import { getBexioPaymentTypes } from '../../schema/types/getBexioPaymentTypes';
import { getBexioTaxes } from '../../schema/types/getBexioTaxes';
import {
  updateBexioInvoiceConfig,
  updateBexioInvoiceConfigVariables,
} from '../../schema/types/updateBexioInvoiceConfig';
import { toastErrorQuery } from '../../utils/error';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldCheckbox from '../form/FieldCheckbox';
import { merge, omit } from 'lodash';

type Props = {
  show: boolean;
  onClose: () => void;
};

const ConfigurationBexioConfigModal: FunctionComponent<Props> = ({ show, onClose }) => {
  const { data, refetch } = useQuery<getBexioInvoiceConfig>(GET_BEXIO_INVOICE_CONFIG);
  const { data: banks } = useQuery<getBexioBankAccounts>(GET_BEXIO_BANK_ACCOUNTS);
  const { data: types } = useQuery<getBexioPaymentTypes>(GET_BEXIO_PAYMENT_TYPES);
  const { data: taxes } = useQuery<getBexioTaxes>(GET_BEXIO_TAXES);
  const { data: accounts } = useQuery<getBexioAccounts>(GET_BEXIO_ACCOUNTS);

  const [mutate] = useMutation<updateBexioInvoiceConfig, updateBexioInvoiceConfigVariables>(
    UPDATE_BEXIO_INVOICE_CONFIG,
  );

  const model: BexioInvoiceConfigPayload = merge(
    {
      bankAccountId: banks?.bexioBankAccounts[0]?.id,
      paymentTypeId: types?.bexioPaymentTypes[0]?.id,
      taxId: taxes?.bexioTaxes[0]?.id,
      noVatTaxId: taxes?.bexioTaxes[0]?.id,
      defaultProductAccountId: accounts?.bexioAccounts[0]?.id,
      mwstType: BexioMswtType.ExcludingTax,
      mswtIsNet: false,
    },
    data && data.bexioInvoiceConfig,
  );

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Bexio Configuration</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              enableReinitialize
              initialValues={model}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  const payload: any = omit(values, ['__typename']);

                  const result: any = await mutate({
                    variables: {
                      payload,
                    },
                  });

                  if (result.errors) {
                    toastErrorQuery(result.errors);
                  } else {
                    toast.success(i18n._(t`Success!`));
                    refetch();
                    resetForm();
                    onClose();
                  }
                } catch (e) {
                  toastErrorQuery(e);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => {
                return (
                  <Form>
                    <div className="modal-body">
                      <div className="form-group">
                        <label>
                          <Trans>Bank Account</Trans>
                        </label>

                        <Field component="select" className="form-control" name="bankAccountId">
                          {banks?.bexioBankAccounts.map((el) => (
                            <option key={el.id} value={el.id}>
                              {el.name}
                            </option>
                          ))}
                        </Field>
                      </div>

                      <div className="form-group">
                        <label>
                          <Trans>Payment Type</Trans>
                        </label>

                        <Field component="select" className="form-control" name="paymentTypeId">
                          {types?.bexioPaymentTypes.map((el) => (
                            <option key={el.id} value={el.id}>
                              {el.name}
                            </option>
                          ))}
                        </Field>
                      </div>

                      <div className="form-group">
                        <label>
                          <Trans>Tax</Trans>
                        </label>

                        <Field component="select" className="form-control" name="taxId">
                          {taxes?.bexioTaxes.map((el) => (
                            <option key={el.id} value={el.id}>
                              {el.name}
                            </option>
                          ))}
                        </Field>
                      </div>

                      <div className="form-group">
                        <label>
                          <Trans>Tax for NO VAT companies</Trans>
                        </label>

                        <Field component="select" className="form-control" name="noVatTaxId">
                          {taxes?.bexioTaxes.map((el) => (
                            <option key={el.id} value={el.id}>
                              {el.name}
                            </option>
                          ))}
                        </Field>
                      </div>

                      <div className="form-group">
                        <label>
                          <Trans>MWST Type</Trans>
                        </label>

                        <Field component="select" className="form-control" name="mwstType">
                          <option value={BexioMswtType.ExcludingTax}>{i18n._(t`Excluding Tax`)}</option>
                          <option value={BexioMswtType.IncludingTax}>{i18n._(t`Including Tax`)}</option>
                          <option value={BexioMswtType.ExemptFromTax}>{i18n._(t`Exempt From Tax`)}</option>
                        </Field>
                      </div>

                      <div className="form-group">
                        <FieldCheckbox name="mswtIsNet" label={i18n._(t`MSWT is Net`)} />
                      </div>

                      <div className="form-group">
                        <label>
                          <Trans>Default Product Account</Trans>
                        </label>

                        <Field component="select" className="form-control" name="defaultProductAccountId">
                          {accounts?.bexioAccounts.map((el) => (
                            <option key={el.id} value={el.id}>
                              {el.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={onClose}>
                        <Trans>Close</Trans>
                      </button>

                      <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfigurationBexioConfigModal;
