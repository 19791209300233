import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import Select from 'react-select';
import { GET_PRODUCTS } from '../../schema/product';
import { getProducts, getProductsVariables } from '../../schema/types/getProducts';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {
  onSubmit: (data: any) => void;
};

const TimeTrackerRateField: FunctionComponent<Props> = ({ onSubmit }) => {
  const [product, setProduct] = useState<any>(null);
  const [amount, setAmount] = useState<number>(0);

  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        timeTrackerEnabled: true,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getProducts, getProductsVariables>(GET_PRODUCTS, {
    variables,
    fetchPolicy: 'cache-first',
  });

  const handleChange = useCallback((value: any) => {
    setProduct(value.value);
  }, []);

  const handleLoad = (value: string) => search$.next(value);

  const model = product
    ? {
        value: product.id,
        label: product.title,
      }
    : null;

  const options = loading
    ? []
    : data!.products.items.map((el: any) => ({
        value: el,
        label: el.title,
      }));

  const handleSubmit = useCallback(() => {
    if (product) {
      onSubmit({
        product,
        amount,
      });

      setProduct(null);
      setAmount(0);
    }
  }, [onSubmit, product, amount]);

  if (!data && !loading) return <div />;

  return (
    <div className="row">
      <div className="col-6">
        <label className="form-label">
          <Trans>Type</Trans>
        </label>

        <Select
          value={model}
          options={options}
          isLoading={loading}
          onChange={handleChange}
          onInputChange={handleLoad}
          placeholder={i18n._(t`Search...`)}
          {...selectOptions}
        />
      </div>

      <div className="col-4">
        <label className="form-label">
          <Trans>Price</Trans>
        </label>

        <input
          type="text"
          className="form-control"
          placeholder={i18n._(t`Price`)}
          value={amount}
          onChange={(e) => setAmount(Number(e.target.value))}
        />
      </div>

      <div className="col">
        <label className="form-label">&nbsp;</label>
        <br />

        <button type="button" className="btn btn-link" onClick={handleSubmit}>
          <Trans>Add</Trans>
        </button>
      </div>
    </div>
  );
};

export default TimeTrackerRateField;
