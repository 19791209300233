import { useMutation } from '@apollo/client';
import { omit } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { CHAT_GPT_ANSWER, CHAT_GPT_ANSWER_CONVERSATION } from '../../schema/chat';
import { ChatGPTMessageInput, ChatGPTRole } from '../../schema/graphql-global-types';
import { chatGPTAnswer, chatGPTAnswerVariables } from '../../schema/types/chatGPTAnswer';
import {
  chatGPTAnswerConversation,
  chatGPTAnswerConversationVariables,
} from '../../schema/types/chatGPTAnswerConversation';
import { toastErrorQuery } from '../../utils/error';
import { nl2br } from '../../utils/utils';
import ChatGPTForm from '../form/ChatGPTForm';

type Props = {
  title: string;
  onChange: (val: string) => void;
};

const DocumentChatGPTForm: FunctionComponent<Props> = ({ title, onChange }) => {
  const [value, setValue] = useState('');
  const [answers, setAnswers] = useState<ChatGPTMessageInput[]>([]);

  const [answerTitle, { loading: answeringTitle }] = useMutation<chatGPTAnswer, chatGPTAnswerVariables>(
    CHAT_GPT_ANSWER,
    {
      variables: {
        query: {
          inputText: title,
        },
      },
    },
  );
  const [answerConversation, { loading: answeringConversation }] = useMutation<
    chatGPTAnswerConversation,
    chatGPTAnswerConversationVariables
  >(CHAT_GPT_ANSWER_CONVERSATION);

  const handleAnswerTitle = async () => {
    try {
      const result = await answerTitle();

      if (result.errors) {
        toastErrorQuery(result.errors);
      } else if (result.data?.chatGPTAnswer) {
        onChange(nl2br(result.data.chatGPTAnswer));
      }
    } catch (e: any) {
      toastErrorQuery(e);
    }
  };

  const handleAnswerConversation = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    try {
      const result = await answerConversation({
        variables: {
          query: {
            messages: [
              ...answers,
              {
                content: value,
                role: ChatGPTRole.user,
              },
            ],
          },
        },
      });

      if (result.errors) {
        toastErrorQuery(result.errors);
      } else if (result.data?.chatGPTAnswerConversation.length) {
        setAnswers(result.data.chatGPTAnswerConversation.map((el) => omit(el, ['__typename'])));
        onChange(
          nl2br(result.data.chatGPTAnswerConversation[result.data.chatGPTAnswerConversation.length - 1].content),
        );
        setValue('');
      }
    } catch (e: any) {
      toastErrorQuery(e);
    }
  };

  return (
    <ChatGPTForm
      value={value}
      onChange={setValue}
      onAnswerConversation={handleAnswerConversation}
      onAnswerAuto={handleAnswerTitle}
      loadingAnswerConversation={answeringConversation}
      loadingAnswerAuto={answeringTitle}
      type="documentation"
    />
  );
};

export default DocumentChatGPTForm;
