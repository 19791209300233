import ReactPhoneInput from 'react-phone-input-2';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';

type Props = {
  name: string;
  placeholder?: string;
};

const PhoneInput: FunctionComponent<Props> = ({ name, placeholder }) => {
  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (a: any, b: any, c: any, value: string) => form.setFieldValue(name, value);

        const fixValue = field.value
          ? field.value.length && !field.value.startsWith('+')
            ? `+${field.value}`
            : field.value
          : '';

        return (
          <ReactPhoneInput
            value={fixValue}
            onChange={handleChange}
            preferredCountries={['ch', 'us']}
            enableSearch={true}
            copyNumbersOnly={false}
            placeholder={placeholder}
          />
        );
      }}
    </Field>
  );
};

export default PhoneInput;
