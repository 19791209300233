import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useState } from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import ReactTable, { CellInfo } from 'react-table';
import { GET_COMPANY_CONTRACT } from '../../schema/company';
import { OrderDirection } from '../../schema/graphql-global-types';
import { getCompanyContracts, getCompanyContractsVariables } from '../../schema/types/getCompanyContracts';
import { DefaultRouteParams, tableOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';
import { getOrder } from '../../utils/utils';
import LoadingTable from '../common/LoadingTable';
import ShowArchive from '../common/ShowArchive';
import ErrorQuery from '../form/ErrorQuery';

type Props = {};

const CompanyContracts: FunctionComponent<Props & RouteComponentProps<DefaultRouteParams>> = ({ match }) => {
  const [loaded, setLoaded] = useState(false);
  const {
    search,
    filter,
    archived,
    setArchived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
  } = useVariables();

  const variables = {
    id: match.params.id,
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, previousData, loading, error } = useQuery<getCompanyContracts, getCompanyContractsVariables>(
    GET_COMPANY_CONTRACT,
    {
      variables,
    },
  );

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.company.contracts.items ?? previousData?.company.contracts.items ?? [];
  const pages = data && data.company ? Math.ceil(data.company.contracts.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Contract`),
      accessor: 'name',
      sortable: true,
      Cell: (cell: CellInfo) => <NavLink to={`/crm/contract/${cell.original.id}`}>{cell.value}</NavLink>,
    },
    {
      Header: i18n._(t`Cycle`),
      accessor: 'cycle',
      sortable: true,
      width: 90,
      className: 'text-center',
    },
  ];

  return (
    <div className="form-group">
      <div className="form-group d-flex justify-content-between align-items-center">
        <label className="mb-0">
          <Trans>Contracts</Trans>
        </label>

        <ShowArchive model={archived} onChange={setArchived} />
      </div>

      {!loaded ? (
        <LoadingTable />
      ) : (
        <ReactTable
          data={items}
          columns={columns}
          pages={pages}
          pageSize={limit}
          manual
          onFetchData={(state) => {
            let orderDir = OrderDirection.Desc;
            if (state.sorted[0]) {
              orderDir = state.sorted[0].desc ? OrderDirection.Desc : OrderDirection.Asc;
            }

            setOffset(state.page * state.pageSize);
            setOrderBy(
              getOrder(state, {
                'customer.name': 'customerName',
              }),
            );
            setOrderDirection(orderDir);
          }}
          onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
          showPageSizeOptions={false}
          showPageJump={false}
          {...tableOptions()}
        />
      )}
    </div>
  );
};

export default withRouter(CompanyContracts);
