import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import ReactTable, { CellInfo } from 'react-table';
import { GET_COMPANY_TICKET } from '../../schema/company';
import { OrderDirection, TicketStatus } from '../../schema/graphql-global-types';
import { getCompanyTickets, getCompanyTicketsVariables } from '../../schema/types/getCompanyTickets';
import { DefaultRouteParams, tableOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';
import { getOrder } from '../../utils/utils';
import LoadingTable from '../common/LoadingTable';
import ErrorQuery from '../form/ErrorQuery';

type Props = {};

const CompanyTickets: FunctionComponent<Props & RouteComponentProps<DefaultRouteParams>> = ({ match }) => {
  const [open, setOpen] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const {
    search,
    filter,
    archived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
  } = useVariables();

  const variables = {
    id: match.params.id,
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
        statuses: open ? [TicketStatus.Opened, TicketStatus.InProgress] : [TicketStatus.Closed],
      },
    },
  };

  const handleOpen = useCallback(() => setOpen((prevState) => !prevState), [setOpen]);

  const { data, previousData, loading, error } = useQuery<getCompanyTickets, getCompanyTicketsVariables>(
    GET_COMPANY_TICKET,
    {
      variables,
    },
  );

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.company.tickets.items ?? previousData?.company.tickets.items ?? [];
  const pages = data && data.company ? Math.ceil(data.company.tickets.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Ticket`),
      accessor: 'opener',
      sortable: true,
    },
    {
      Header: i18n._(t`Status`),
      accessor: 'status',
      className: 'text-center',
      Cell: (cell: CellInfo) => {
        switch (cell.value) {
          case 'Opened':
            return i18n._(t`Opened`);
          case 'Closed':
            return i18n._(t`Closed`);
          case 'InProgress':
            return i18n._(t`In Progress`);
          default:
            return '';
        }
      },
    },
  ];

  return (
    <div className="form-group">
      <div className="form-group d-flex justify-content-between align-items-center">
        <label className="mb-0">
          <Trans>Last Support Cases</Trans>
        </label>

        <button type="button" className="text-info" onClick={handleOpen}>
          {open ? <Trans>Show closed</Trans> : <Trans>Hide closed</Trans>}
        </button>
      </div>

      {!loaded ? (
        <LoadingTable />
      ) : (
        <ReactTable
          data={items}
          columns={columns}
          pages={pages}
          pageSize={limit}
          manual
          onFetchData={(state) => {
            let orderDir = OrderDirection.Desc;
            if (state.sorted[0]) {
              orderDir = state.sorted[0].desc ? OrderDirection.Desc : OrderDirection.Asc;
            }

            setOffset(state.page * state.pageSize);
            setOrderBy(
              getOrder(state, {
                'company.name': 'companyName',
                'lastEditor.fullName': 'lastEditorName',
              }),
            );
            setOrderDirection(orderDir);
          }}
          onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
          showPageSizeOptions={false}
          showPageJump={false}
          {...tableOptions()}
        />
      )}
    </div>
  );
};

export default withRouter(CompanyTickets);
