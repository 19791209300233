import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { useMutation } from '@apollo/client';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { CREATE_DOCUMENT, documentKeys, GET_DOCUMENTS_WITH_DETAIL, validateDocumentForm } from '../../schema/document';
import { OfferItemType } from '../../schema/graphql-global-types';
import { createDocument, createDocumentVariables } from '../../schema/types/createDocument';
import { DocumentElement } from '../../schema/types/DocumentElement';
import { OfferProductItem } from '../../schema/types/OfferProductItem';
import { OfferProjectItem } from '../../schema/types/OfferProjectItem';
import { defaultQuery } from '../../utils/const';
import { toastErrorQuery } from '../../utils/error';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';
import { cloneDeep, pick } from 'lodash';
import DocumentationCategoryForm from './DocumentationCategoryForm';

type Props = {};

const DocumentationSaveTemplate: FunctionComponent<Props> = () => {
  const [show, setShow] = useState(false);
  const [model, setModel] = useState({});

  const [mutate] = useMutation<createDocument, createDocumentVariables>(CREATE_DOCUMENT, {
    refetchQueries: [
      {
        query: GET_DOCUMENTS_WITH_DETAIL,
        variables: {
          query: {
            limit: defaultQuery.query.limit,
            offset: defaultQuery.query.offset,
            orderBy: defaultQuery.query.orderBy,
            orderDirection: defaultQuery.query.orderDirection,
            filter: {
              search: '',
              archived: false,
              template: true,
            },
          },
        },
      },
    ],
  });

  return (
    <Field name="elements">
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const toggleModal = () =>
          setShow((prevState) => {
            if (!prevState) {
              const model = cloneDeep(form.values);
              model.title = '';
              model.template = true;
              setModel(model);
            }

            return !prevState;
          });

        return (
          <>
            <button type="button" className="btn btn-link" onClick={toggleModal}>
              <Trans>Save as Template</Trans>
            </button>

            <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
              <div className="modal fade show" style={{ display: 'block' }}>
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h2 className="modal-title">
                        <Trans>Save as Template</Trans>
                      </h2>
                      <button type="button" className="close" aria-label="Close" onClick={toggleModal}>
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>

                    <Formik
                      enableReinitialize
                      initialValues={model}
                      validationSchema={yup.object().shape(validateDocumentForm)}
                      onSubmit={async (values, { setSubmitting, resetForm }) => {
                        try {
                          const payload: any = pick(values, documentKeys);
                          payload.elements = payload.elements.map((el: DocumentElement) => ({
                            ...pick(el, ['title', 'content', 'index', 'templateElementId']),
                            item: el.item
                              ? {
                                  ...pick(el.item, ['type', 'index', 'title', 'content']),
                                  discountPercent: Number(el.item.discountPercent),
                                  productItems:
                                    el.item.type === OfferItemType.Product
                                      ? el.item.productItems!.map((item: OfferProductItem) => {
                                          const result = pick(item, [
                                            'productId',
                                            'title',
                                            'price',
                                            'pieces',
                                            'discountPercent',
                                          ]);

                                          result.price = Number(result.price);
                                          result.pieces = Number(result.pieces);
                                          result.discountPercent = Number(result.discountPercent);

                                          return result;
                                        })
                                      : [],
                                  projectItems:
                                    el.item.type === OfferItemType.Project
                                      ? el.item.projectItems!.map((item: OfferProjectItem) =>
                                          pick(item, ['userId', 'description', 'startDate', 'endDate', 'status']),
                                        )
                                      : [],
                                }
                              : null,
                          }));

                          const result: any = await mutate({
                            variables: {
                              payload,
                            },
                          });

                          if (result.errors) {
                            toastErrorQuery(result.errors);
                          } else {
                            toggleModal();
                            resetForm();
                            toast.success(i18n._(t`Success!`));
                          }
                        } catch (e) {
                          toastErrorQuery(e);
                        }

                        setSubmitting(false);
                      }}
                    >
                      {({ isSubmitting, submitForm }) => {
                        const handleSubmit = (e: any) => {
                          e.preventDefault();
                          e.stopPropagation();
                          submitForm();
                        };

                        return (
                          <Form onSubmitCapture={handleSubmit}>
                            <div className="modal-body">
                              <div className="form-group">
                                <label>
                                  <Trans>Title</Trans>
                                </label>
                                <Field type="text" className="form-control" name="title" autoFocus />

                                <FieldErrorMessage name="title" />
                              </div>

                              <div className="form-group">
                                <label>
                                  <Trans>Category</Trans>
                                </label>
                                <DocumentationCategoryForm />

                                <FieldErrorMessage name="categoryId" />
                              </div>
                            </div>

                            <div className="modal-footer">
                              <button type="button" className="btn btn-link" onClick={toggleModal}>
                                <Trans>Close</Trans>
                              </button>

                              <ButtonSubmit
                                className="btn btn-primary"
                                title={i18n._(t`Add Template`)}
                                loading={isSubmitting}
                              />
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </Modal>
          </>
        );
      }}
    </Field>
  );
};

export default DocumentationSaveTemplate;
