import classNames from 'classnames';
import initials from 'initials';
import React, { FC, useMemo } from 'react';
import { Person } from '../../schema/types/Person';
import { User } from '../../schema/types/User';

type Props = {
  user?: Partial<User | Person>;
  image?: string | null;
  name?: string | null;
};

const UserAvatar: FC<Props> = ({ user, image, name }) => {
  const img = useMemo(() => user?.avatar?.imageInstance?.object.url || user?.avatar?.url || image, [image, user]);
  const initial = useMemo(() => (name ? String(initials(name)).substr(0, 3).toUpperCase() : null), [name]);

  return (
    <div className={classNames('user-avatar', { 'no-avatar': !img })}>
      {img ? <img src={img} alt={initial || ''} /> : initial || <i className="fa fa-user" />}
    </div>
  );
};

export default UserAvatar;
