import gql from 'graphql-tag';
import { validateEmail, validateRequired } from '../utils/validations';

export const TENANT_CONFIG = gql`
  fragment TenantConfig on TenantConfig {
    logo {
      id
      url
    }
    companyName
    nameSuffix
    website
    language
    responsibleUserFirstName
    responsibleUserLastName
    address1
    zip
    canton
    city
    country
    generalPhone
    generalEmail
    invoiceEmail
    subscriptionType
  }
`;

export const GET_TENANT_CONFIG = gql`
  query getTenantConfig {
    tenantConfig {
      ...TenantConfig
    }
  }
  ${TENANT_CONFIG}
`;

export const UPDATE_TENANT_CONFIG = gql`
  mutation updateTenantConfig($payload: TenantConfigPayload!) {
    updateTenantConfig(payload: $payload)
  }
`;

export const tenantConfigKeys = [
  'logoId',
  'companyName',
  'nameSuffix',
  'website',
  'language',
  'responsibleUserFirstName',
  'responsibleUserLastName',
  'address1',
  'zip',
  'canton',
  'city',
  'country',
  'generalPhone',
  'generalEmail',
  'invoiceEmail',
  'subscriptionType',
];

export const validateTenantConfigForm = {
  companyName: validateRequired,
  generalEmail: validateEmail,
};
