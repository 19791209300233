import { Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import arrayMove from 'array-move';
import ReactTooltip from 'react-tooltip';
import { OfferItemType } from '../../schema/graphql-global-types';
import { OfferItem } from '../../schema/types/OfferItem';
import OfferEditorSortableProduct from './OfferEditorSortableProduct';
import OfferEditorSortableProject from './OfferEditorSortableProject';

const getIndex = (items: OfferItem[], el: OfferItem, index: number) => {
  if (!el.index) return '';

  let count = 0;

  for (let i = 0; i < items.length; i++) {
    if (items[i].index) {
      count++;
    }

    if (i === index) {
      break;
    }
  }

  return count ? `${count}.` : '';
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  background: 'var(--white)',
  padding: '10px',
  border: '1px solid #ccc',
  borderRadius: '5px',
  marginBottom: '30px',
  position: 'relative',
  ...draggableStyle,
});

type Props = {};

const OfferEditorSortable: FunctionComponent<Props> = () => {
  return (
    <div className="documentation-editor border-right sortable-container">
      <div className="container">
        <Field name="headGraphic.url">
          {({ field, form }: { field: any; form: FormikProps<any> }) => {
            if (!field.value) return '';

            return (
              <div className="form-group header-graphic elements">
                <div className="image-uploader">
                  <img src={field.value} />
                </div>
              </div>
            );
          }}
        </Field>

        <Field name="items">
          {({ field, form }: { field: any; form: FormikProps<any> }) => {
            const handleSort = (result: any) => {
              form.setFieldValue('items', arrayMove(field.value, result.source.index, result.destination.index));
            };

            const handleUp = (index: number) => () => {
              form.setFieldValue('items', arrayMove(field.value, index, index - 1));
            };

            const handleDown = (index: number) => () => {
              form.setFieldValue('items', arrayMove(field.value, index, index + 1));
            };

            return (
              <DragDropContext onDragEnd={handleSort}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {field.value.map((item: any, index: number) => (
                        <Draggable key={index} draggableId={String(index)} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            >
                              {index !== 0 && (
                                <>
                                  <a data-tip data-for="move-up" className="arrow up" onClick={handleUp(index)}>
                                    <i className="fa fa-arrow-up" />
                                  </a>
                                  <ReactTooltip id="move-up">
                                    <Trans>Move Up</Trans>
                                  </ReactTooltip>
                                </>
                              )}

                              {index !== field.value.length - 1 && (
                                <>
                                  <a data-tip data-for="move-down" className="arrow down" onClick={handleDown(index)}>
                                    <i className="fa fa-arrow-down" />
                                  </a>
                                  <ReactTooltip id="move-down">
                                    <Trans>Move Down</Trans>
                                  </ReactTooltip>
                                </>
                              )}

                              <div className="form-group d-flex align-items-center">
                                <h3>
                                  {getIndex(field.value, item, index)} {item.title}
                                </h3>
                              </div>

                              <div className="form-group">
                                {item.type === OfferItemType.Product ? (
                                  <OfferEditorSortableProduct name={`items[${index}]`} model={item} />
                                ) : item.type === OfferItemType.Project ? (
                                  <OfferEditorSortableProject name={`items[${index}]`} model={item} />
                                ) : (
                                  <div
                                    className="post-content"
                                    dangerouslySetInnerHTML={{
                                      __html: item.content!,
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            );
          }}
        </Field>
      </div>
    </div>
  );
};

export default OfferEditorSortable;
