import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import * as yup from 'yup';
import ButtonSubmit from '../components/form/ButtonSubmit';
import ErrorQuery from '../components/form/ErrorQuery';
import FieldErrorMessage from '../components/form/FieldErrorMessage';
import logo from '../img/logo_homer_150x150.svg';
import logoWhite from '../img/logo_homer_150x150_white.svg';
import { GET_VIEWER, LOGIN } from '../schema/auth';
import { LoginMode } from '../schema/graphql-global-types';
import { GET_SIDEBAR_STATE } from '../schema/sidebar';
import { login, loginVariables } from '../schema/types/login';
import { sidebarState } from '../schema/types/sidebarState';
import { setStorage } from '../utils/storage';
import useAuthLayout from '../utils/useAuthLayout';
import { validateEmail, validatePassword, validateRequired } from '../utils/validations';

const LoginPage: FunctionComponent<RouteComponentProps> = ({ history, match }) => {
  useAuthLayout();

  const client = useApolloClient();
  const sidebar = useQuery<sidebarState>(GET_SIDEBAR_STATE);

  const [mutate, { error }] = useMutation<login, loginVariables>(LOGIN, {
    onCompleted: async (data) => {
      if (data) {
        if (data.login.accessToken) {
          setStorage('accessToken', data.login.accessToken);

          await client.query({
            query: GET_VIEWER,
            fetchPolicy: 'network-only',
          });

          if (params.get('redirect')) {
            window.location.href = params.get('redirect') || '/';
          } else if (match.url === '/admin-login') {
            document.querySelector('body')!.classList.remove('bg-gradient-primary');
            history.push('/');
          } else {
            history.push('/select-company');
          }
        } else {
          setTimeout(() => {
            history.push(`/mfa/${data.login.mfaToken}`);
          }, 250);
        }
      }
    },
  });

  const model =
    match.url === '/admin-login'
      ? {
          email: '',
          password: '',
          mode: LoginMode.User,
        }
      : {
          email: '',
          password: '',
          mode: LoginMode.Person,
        };

  const params = new URLSearchParams(window.location.search);

  return (
    <div className="auth-area">
      <div className="content">
        <div className="logo">
          {sidebar.data?.sidebarState.darkMode ? (
            <img src={logoWhite} alt="MultiCloud Center" style={{ width: '150px' }} />
          ) : (
            <img src={logo} alt="MultiCloud Center" style={{ width: '150px' }} />
          )}
        </div>

        <h1 className="main-title">
          {match.url === '/admin-login' ? <Trans>Admin Login</Trans> : <Trans>Member Login</Trans>}
        </h1>

        {error && <ErrorQuery error={error} />}

        <Formik
          enableReinitialize
          initialValues={model}
          validationSchema={yup.object().shape({
            email: validateEmail,
            password: validateRequired.concat(validatePassword),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await mutate({
                variables: {
                  payload: values,
                },
              });
            } catch (e) {}

            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="form">
              <div className="form-group">
                <label>
                  <Trans>Email</Trans>
                </label>

                <div className="has-icon">
                  <i className="icon-mail" />
                  <Field className="form-control" type="email" name="email" placeholder={i18n._(t`Email`)} autoFocus />
                </div>
              </div>

              <FieldErrorMessage name="email" />

              <div className="form-group">
                <label>
                  <Trans>Password</Trans>
                </label>

                <div className="has-icon">
                  <i className="icon-password" />
                  <Field
                    className="form-control"
                    type="password"
                    name="password"
                    autoComplete="current-password"
                    placeholder={i18n._(t`Password`)}
                  />
                </div>
              </div>

              <FieldErrorMessage name="password" />

              <div className="actions">
                <ButtonSubmit title={i18n._(t`Login`)} className="btn btn-primary btn-full" loading={isSubmitting} />
              </div>
            </Form>
          )}
        </Formik>

        <div className="form-group">
          <NavLink to={match.url === '/admin-login' ? '/admin-forgot' : '/forgot'} className="text-primary">
            <Trans>Forgot Username / Password?</Trans>
          </NavLink>
        </div>

        {match.url === '/admin-login' && (
          <div className="form-group">
            <NavLink to="/login" className="text-primary">
              <Trans>Member Login</Trans>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(LoginPage);
