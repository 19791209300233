import { Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import arrayMove from 'array-move';
import ReactTooltip from 'react-tooltip';
import { OfferItemType } from '../../schema/graphql-global-types';
import { DocumentElement } from '../../schema/types/DocumentElement';
import OfferEditorSortableProduct from '../offer/OfferEditorSortableProduct';
import OfferEditorSortableProject from '../offer/OfferEditorSortableProject';

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  background: 'var(--white)',
  padding: '10px',
  border: '1px solid #ccc',
  borderRadius: '5px',
  marginBottom: '30px',
  position: 'relative',
  ...draggableStyle,
});

type Props = {};

const DocumentationEditorSortable: FunctionComponent<Props> = () => {
  return (
    <div className="documentation-editor border-right sortable-container">
      <div className="container">
        <Field name="headGraphic.url">
          {({ field }: { field: any }) => {
            if (!field.value) return '';

            return (
              <div className="form-group header-graphic elements">
                <div className="image-uploader">
                  <img src={field.value} />
                </div>
              </div>
            );
          }}
        </Field>

        <Field name="elements">
          {({ field, form }: { field: any; form: FormikProps<any> }) => {
            const handleSort = (result: any) => {
              form.setFieldValue('elements', arrayMove(field.value, result.source.index, result.destination.index));
            };

            const handleUp = (index: number) => () => {
              form.setFieldValue('elements', arrayMove(field.value, index, index - 1));
            };

            const handleDown = (index: number) => () => {
              form.setFieldValue('elements', arrayMove(field.value, index, index + 1));
            };

            return (
              <DragDropContext onDragEnd={handleSort}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {field.value.map((item: DocumentElement, index: number) => (
                        <Draggable key={index} draggableId={String(index)} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            >
                              {index !== 0 && (
                                <>
                                  <a
                                    data-tip
                                    data-for={`move-up-${index}`}
                                    className="arrow up"
                                    onClick={handleUp(index)}
                                  >
                                    <i className="fa fa-arrow-up" />
                                  </a>
                                  <ReactTooltip id={`move-up-${index}`}>
                                    <Trans>Move Up</Trans>
                                  </ReactTooltip>
                                </>
                              )}

                              {index !== field.value.length - 1 && (
                                <>
                                  <a
                                    data-tip
                                    data-for={`move-down-${index}`}
                                    className="arrow down"
                                    onClick={handleDown(index)}
                                  >
                                    <i className="fa fa-arrow-down" />
                                  </a>
                                  <ReactTooltip id={`move-down-${index}`}>
                                    <Trans>Move Down</Trans>
                                  </ReactTooltip>
                                </>
                              )}

                              <h3 className="panel-title">{item.title}</h3>

                              {!item.item ? (
                                <div
                                  className="post-content"
                                  dangerouslySetInnerHTML={{
                                    __html: item.content!,
                                  }}
                                />
                              ) : item.item.type === OfferItemType.Product ? (
                                <OfferEditorSortableProduct name={`elements[${index}].item`} model={item.item} />
                              ) : item.item.type === OfferItemType.Project ? (
                                <OfferEditorSortableProject name={`elements[${index}].item`} model={item.item} />
                              ) : (
                                <div
                                  className="post-content"
                                  dangerouslySetInnerHTML={{
                                    __html: item.item.content!,
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            );
          }}
        </Field>
      </div>
    </div>
  );
};

export default DocumentationEditorSortable;
