import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import Modal from 'react-modal';
import * as yup from 'yup';
import { AssetReminderCycle } from '../../schema/graphql-global-types';
import { validateRequired } from '../../utils/validations';
import ShowArchive from '../common/ShowArchive';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldCheckbox from '../form/FieldCheckbox';
import FieldDatePicker from '../form/FieldDatepicker';
import FieldErrorMessage from '../form/FieldErrorMessage';
import RecipientCheckbox from '../form/RecipientCheckbox';
import TicketDocTem from '../ticket/TicketDocTem';
import TicketPersonForm from '../ticket/TicketPersonForm';
import AssetReminderFormItem from './AssetReminderFormItem';
import { cloneDeep } from 'lodash';

type Props = {
  items: any[];
  defaultCycle?: AssetReminderCycle;
  onAdd: (model: any) => void;
  onEdit: (model: any, index: number) => void;
  onRemove: (index: number) => void;
};

const today = new Date();
const morning = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 5, 0, 0, 0);

const AssetReminderForm: FunctionComponent<Props> = ({ items, defaultCycle, onAdd, onEdit, onRemove }) => {
  const initial = useMemo(
    () => ({
      title: '',
      cycle: defaultCycle || AssetReminderCycle.OneTime,
      startDate: new Date(morning).toISOString(),
      content: '',
      customRecipients: false,
      customTo: [],
      customCc: [],
      customBcc: [],
      linkEnabled: true,
      archivedAt: null,
    }),
    [defaultCycle],
  );

  const [show, setShow] = useState(false);
  const [model, setModel] = useState<any>(null);
  const [index, setIndex] = useState<number | null>(null);
  const [showArchived, setShowArchived] = useState(false);

  const toggleModal = useCallback(() => setShow((prevState) => !prevState), [setShow]);

  const handleAdd = useCallback(() => {
    setIndex(null);
    setModel(cloneDeep(initial));
    toggleModal();
  }, [setIndex, setModel, initial, toggleModal]);

  const handleEdit = useCallback(
    (index: number) => () => {
      setIndex(index);
      const model = cloneDeep(items[index]);
      model.customTo = model.customTo && model.customTo.length ? model.customTo.split(',') : [];
      model.customCc = model.customCc && model.customCc.length ? model.customCc.split(',') : [];
      model.customBcc = model.customBcc && model.customBcc.length ? model.customBcc.split(',') : [];
      setModel(model);
      toggleModal();
    },
    [setIndex, setModel, items, toggleModal],
  );

  const handleRemove = useCallback(
    (index: number) => () => {
      const model = cloneDeep(items[index]);
      model.archivedAt = !model.archivedAt ? new Date().toISOString() : null;
      onEdit(model, index);
    },
    [items, onEdit],
  );

  return (
    <div className="">
      <div className="table-actions">
        <div className="breadcrumbs-area">
          <button type="button" className="btn-link" onClick={handleAdd}>
            <Trans>Add Reminder</Trans>
          </button>
        </div>

        <ShowArchive model={showArchived} onChange={() => setShowArchived((prevState) => !prevState)} />
      </div>

      {items.length > 0 && (
        <div className="table-responsive asset-reminder-table">
          <table className="custom-table">
            <thead className="custom-table-header">
              <tr>
                <th className="column title">
                  <Trans>Title</Trans>
                </th>
                <th className="column cycle">
                  <Trans>Cycle</Trans>
                </th>
                <th className="column date">
                  <Trans>Date</Trans>
                </th>
                <th className="column actions" />
              </tr>
            </thead>

            <tbody>
              {items.map((el: any, index: number) => {
                if ((showArchived && !el.archivedAt) || (!showArchived && el.archivedAt)) return null;

                return (
                  <AssetReminderFormItem
                    key={index}
                    model={el}
                    onEdit={handleEdit(index)}
                    onRemove={handleRemove(index)}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
        <div className="modal fade show" style={{ display: 'block' }}>
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title">
                  <Trans>Reminder</Trans>
                </h2>
                <button type="button" className="close" aria-label="Close" onClick={toggleModal}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <Formik
                enableReinitialize
                initialValues={model}
                validationSchema={yup.object().shape({
                  title: validateRequired,
                })}
                onSubmit={(values, { setSubmitting }) => {
                  const payload = cloneDeep(values);
                  payload.customTo = payload.customTo.join(',');
                  payload.customCc = payload.customCc.join(',');
                  payload.customBcc = payload.customBcc.join(',');

                  if (index !== null) {
                    onEdit(payload, index);
                  } else {
                    onAdd(payload);
                  }
                  setSubmitting(false);
                  toggleModal();
                }}
              >
                {({ submitForm }) => {
                  const handleSubmit = (e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    submitForm();
                  };

                  return (
                    <Form onSubmitCapture={handleSubmit}>
                      <div className="modal-body">
                        <div className="row form-group">
                          <div className="col">
                            <RecipientCheckbox
                              name="customRecipients"
                              label={i18n._(t`Default recipients (template defined)`)}
                            />
                          </div>
                        </div>

                        <Field name="customRecipients">
                          {({ field }: { field: any }) => {
                            if (!field.value) return '';

                            return (
                              <div className="row">
                                <div className="form-group col-lg-4">
                                  <label>
                                    <Trans>To</Trans>
                                  </label>
                                  <TicketPersonForm name="customTo" />
                                </div>

                                <div className="form-group col-lg-4">
                                  <label>
                                    <Trans>CC</Trans>
                                  </label>
                                  <TicketPersonForm name="customCc" />
                                </div>

                                <div className="form-group col-lg-4">
                                  <label>
                                    <Trans>BCC</Trans>
                                  </label>
                                  <TicketPersonForm name="customBcc" />
                                </div>
                              </div>
                            );
                          }}
                        </Field>

                        <div className="row">
                          <div className="form-group col-lg-4">
                            <label>
                              <Trans>Cycle</Trans>
                            </label>
                            <Field component="select" className="form-control" name="cycle">
                              <option value="OneTime">{i18n._(t`One Time`)}</option>
                              <option value="Daily">{i18n._(t`Daily`)}</option>
                              <option value="Weekly">{i18n._(t`Weekly`)}</option>
                              <option value="BiWeekly">{i18n._(t`Biweekly`)}</option>
                              <option value="Monthly">{i18n._(t`Monthly`)}</option>
                              <option value="Quarterly">{i18n._(t`Quarterly`)}</option>
                              <option value="HalfYearly">{i18n._(t`1/2 Year`)}</option>
                              <option value="Yearly">{i18n._(t`Yearly`)}</option>
                              <option value="ThreeYears">{i18n._(t`3 Years`)}</option>
                            </Field>
                          </div>

                          <div className="form-group col-lg-4">
                            <label>
                              <Trans>Date</Trans>
                            </label>
                            <FieldDatePicker name="startDate" />
                          </div>

                          <div className="form-group col-lg-4">
                            <label>
                              <Trans>Title</Trans>
                            </label>
                            <Field type="text" className="form-control" name="title" />

                            <FieldErrorMessage name="title" />
                          </div>
                        </div>

                        <div className="form-group">
                          <label>
                            <Trans>Reminder Text</Trans>
                          </label>
                          <Field component="textarea" className="form-control" name="content" rows={5} />
                        </div>

                        <div className="row form-group">
                          <div className="col">
                            <FieldCheckbox name="linkEnabled" label={i18n._(t`Attach Center Link`)} />
                          </div>

                          <div className="col ticket-page">
                            <TicketDocTem />
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button type="button" className="btn btn-link" onClick={toggleModal}>
                          <Trans>Close</Trans>
                        </button>

                        <ButtonSubmit
                          className="btn btn-primary"
                          title={index !== null ? i18n._(t`Save Reminder`) : i18n._(t`Add Reminder`)}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AssetReminderForm;
