import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import LoadingForm from '../components/common/LoadingForm';
import MainTitle from '../components/common/MainTitle';
import ConfigurationAzureImportModal from '../components/configuration/ConfigurationAzureImportModal';
import ConfigurationBexioConfigModal from '../components/configuration/ConfigurationBexioConfigModal';
import ConfigurationChatGPTModal from '../components/configuration/ConfigurationChatGPTModal';
import ConfigurationM365ImportModal from '../components/configuration/ConfigurationM365ImportModal';
import ConfigurationNinjaRmmModal from '../components/configuration/ConfigurationNinjaRMMModal';
import ConfigurationCountryModal from '../components/configuration/ConfigurationCountryModal';
import ConfigurationAppMailModal from '../components/configuration/ConfigurationAppMailModal';
import ConfigurationTicketMailFolderModal from '../components/configuration/ConfigurationTicketMailFolderModal';
import ConfigurationTicketMailModal from '../components/configuration/ConfigurationTicketMailModal';
import ConfigurationSystemMailModal from '../components/configuration/ConfigurationSystemMailModal';
import ConfigurationSmsModal from '../components/configuration/ConfigurationSmsModal';
import ConfigurationTimeTrackerModal from '../components/configuration/ConfigurationTimeTrackerModal';
import ConfigurationTwitterModal from '../components/configuration/ConfigurationTwitterModal';
import ConfigurationBexioModal from '../components/configuration/ConfigurationBexioModal';
import ConfigurationUptimeModal from '../components/configuration/ConfigurationUptimeModal';
import { GET_OUTLOOK_APP_CONFIG, GET_OUTLOOK_TICKET_ACCOUNT } from '../schema/config';
import { getOutlookAppConfig } from '../schema/types/getOutlookAppConfig';
import { getOutlookTicketAccount } from '../schema/types/getOutlookTicketAccount';

const ConfigurationPage: FunctionComponent<RouteComponentProps> = ({ location }) => {
  const [notified, setNotified] = useState(false);

  const [showCountry, setShowCountry] = useState(false);
  const toggleShowCountry = useCallback(() => setShowCountry((prevState) => !prevState), [setShowCountry]);

  const [showAppMail, setShowAppMail] = useState(false);
  const toggleShowAppMail = useCallback(() => setShowAppMail((prevState) => !prevState), [setShowAppMail]);

  const [showTicketMail, setShowTicketMail] = useState(false);
  const toggleShowTicketMail = useCallback(() => setShowTicketMail((prevState) => !prevState), [setShowTicketMail]);

  const [showSystemMail, setShowSystemMail] = useState(false);
  const toggleShowSystemMail = useCallback(() => setShowSystemMail((prevState) => !prevState), [setShowSystemMail]);

  const [showDoneMailFolder, setShowDoneMailFolder] = useState(false);
  const toggleShowDoneMailFolder = useCallback(
    () => setShowDoneMailFolder((prevState) => !prevState),
    [setShowDoneMailFolder],
  );

  const [showUptime, setShowUptime] = useState(false);
  const toggleShowUptime = useCallback(() => setShowUptime((prevState) => !prevState), [setShowUptime]);

  const [showSms, setShowSms] = useState(false);
  const toggleShowSms = useCallback(() => setShowSms((prevState) => !prevState), [setShowSms]);

  const [showTwitter, setShowTwitter] = useState(false);
  const toggleShowTwitter = useCallback(() => setShowTwitter((prevState) => !prevState), [setShowTwitter]);

  const [showNinjaRmm, setShowNinjaRmm] = useState(false);
  const toggleShowNinjaRmm = useCallback(() => setShowNinjaRmm((prevState) => !prevState), [setShowNinjaRmm]);

  const [showBexio, setShowBexio] = useState(false);
  const toggleShowBexio = useCallback(() => setShowBexio((prevState) => !prevState), [setShowBexio]);

  const [showBexioConfig, setShowBexioConfig] = useState(false);
  const toggleShowBexioConfig = useCallback(() => setShowBexioConfig((prevState) => !prevState), [setShowBexioConfig]);

  const [showTimeTrackerConfig, setShowTimeTrackerConfig] = useState(false);
  const toggleShowTimeTrackerConfig = useCallback(
    () => setShowTimeTrackerConfig((prevState) => !prevState),
    [setShowTimeTrackerConfig],
  );

  const [showM365ImportConfig, setShowM365ImportConfig] = useState(false);
  const toggleShowM365ImportConfig = useCallback(
    () => setShowM365ImportConfig((prevState) => !prevState),
    [setShowM365ImportConfig],
  );

  const [showAzure, setShowAzure] = useState(false);
  const toggleShowAzure = useCallback(() => setShowAzure((prevState) => !prevState), [setShowAzure]);

  const outlookAppConfig = useQuery<getOutlookAppConfig>(GET_OUTLOOK_APP_CONFIG);

  const outlookTicketAccount = useQuery<getOutlookTicketAccount>(GET_OUTLOOK_TICKET_ACCOUNT);

  const [showChat, setShowChat] = useState(false);
  const toggleShowChat = useCallback(() => setShowChat((prevState) => !prevState), [setShowChat]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('success') && !notified) {
      toast.success(i18n._(t`Success!`));
      setNotified(true);
    }
  }, [notified, setNotified, location]);

  if (outlookAppConfig.loading || outlookTicketAccount.loading) return <LoadingForm />;

  return (
    <div className="configuration-page">
      <div className="breadcrumbs-area">
        <MainTitle title={i18n._(t`Configuration`)} tips="Settings/Configuration" />
      </div>

      <div className="item">
        <label className="block-title">
          <Trans>Country Configuration</Trans>
        </label>
        <div className="actions">
          <button type="button" className="btn btn-link" onClick={toggleShowCountry}>
            <Trans>Configure</Trans>
          </button>
        </div>

        <ConfigurationCountryModal show={showCountry} onClose={toggleShowCountry} />
      </div>

      <div className="item">
        <label className="block-title">
          <Trans>App Mail Connector</Trans>
        </label>
        <div className="actions">
          <button type="button" className="btn btn-link" onClick={toggleShowAppMail}>
            <Trans>Configure</Trans>
          </button>
        </div>
      </div>

      <ConfigurationAppMailModal show={showAppMail} onClose={toggleShowAppMail} />

      {outlookAppConfig.data!.outlookAppConfig && outlookAppConfig.data!.outlookAppConfig.appId && (
        <>
          <div className="item">
            <label className="block-title">
              <Trans>Ticket Mail Connector</Trans>
            </label>
            <div className="actions">
              <button type="button" className="btn btn-link" onClick={toggleShowTicketMail}>
                <Trans>Configure</Trans>
              </button>
            </div>
          </div>

          <ConfigurationTicketMailModal show={showTicketMail} onClose={toggleShowTicketMail} />

          <div className="item">
            <label className="block-title">
              <Trans>System Mail Connector</Trans>
            </label>
            <div className="actions">
              <button type="button" className="btn btn-link" onClick={toggleShowSystemMail}>
                <Trans>Configure</Trans>
              </button>
            </div>
          </div>

          <ConfigurationSystemMailModal show={showSystemMail} onClose={toggleShowSystemMail} />
        </>
      )}

      {outlookTicketAccount.data!.outlookTicketAccount && outlookTicketAccount.data!.outlookTicketAccount.email && (
        <>
          <div className="item">
            <label className="block-title">
              <Trans>Ticket Done Mail Folder</Trans>
            </label>
            <div className="actions">
              <button type="button" className="btn btn-link" onClick={toggleShowDoneMailFolder}>
                <Trans>Configure</Trans>
              </button>
            </div>
          </div>

          <ConfigurationTicketMailFolderModal show={showDoneMailFolder} onClose={toggleShowDoneMailFolder} />
        </>
      )}

      <div className="item">
        <label className="block-title">
          <Trans>Uptime Robot Connector</Trans>
        </label>
        <div className="actions">
          <button type="button" className="btn btn-link" onClick={toggleShowUptime}>
            <Trans>Configure</Trans>
          </button>
        </div>
      </div>

      <ConfigurationUptimeModal show={showUptime} onClose={toggleShowUptime} />

      <div className="item">
        <label className="block-title">
          <Trans>SMS Gateway</Trans>
        </label>
        <div className="actions">
          <button type="button" className="btn btn-link" onClick={toggleShowSms}>
            <Trans>Configure</Trans>
          </button>
        </div>
      </div>

      <ConfigurationSmsModal show={showSms} onClose={toggleShowSms} />

      <div className="item">
        <label className="block-title">
          <Trans>Twitter Authorization</Trans>
        </label>
        <div className="actions">
          <button type="button" className="btn btn-link" onClick={toggleShowTwitter}>
            <Trans>Configure</Trans>
          </button>
        </div>

        <ConfigurationTwitterModal show={showTwitter} onClose={toggleShowTwitter} />
      </div>

      <div className="item">
        <label className="block-title">
          <Trans>NinjaRmm</Trans>
        </label>
        <div className="actions">
          <button type="button" className="btn btn-link" onClick={toggleShowNinjaRmm}>
            <Trans>Configure</Trans>
          </button>
        </div>
      </div>

      <ConfigurationNinjaRmmModal show={showNinjaRmm} onClose={toggleShowNinjaRmm} />

      <div className="item">
        <label className="block-title">
          <Trans>Bexio Connector</Trans>
        </label>
        <div className="actions">
          <button type="button" className="btn btn-link" onClick={toggleShowBexio}>
            <Trans>Configure</Trans>
          </button>
        </div>
      </div>

      <ConfigurationBexioModal show={showBexio} onClose={toggleShowBexio} />

      <div className="item">
        <label className="block-title">
          <Trans>Bexio Configuration</Trans>
        </label>
        <div className="actions">
          <button type="button" className="btn btn-link" onClick={toggleShowBexioConfig}>
            <Trans>Configure</Trans>
          </button>
        </div>
      </div>

      <ConfigurationBexioConfigModal show={showBexioConfig} onClose={toggleShowBexioConfig} />

      <div className="item">
        <label className="block-title">
          <Trans>Time Tracker</Trans>
        </label>
        <div className="actions">
          <button type="button" className="btn btn-link" onClick={toggleShowTimeTrackerConfig}>
            <Trans>Configure</Trans>
          </button>
        </div>
      </div>

      <ConfigurationTimeTrackerModal show={showTimeTrackerConfig} onClose={toggleShowTimeTrackerConfig} />

      <div className="item">
        <label className="block-title">
          <Trans>M365 Import</Trans>
        </label>
        <div className="actions">
          <button type="button" className="btn btn-link" onClick={toggleShowM365ImportConfig}>
            <Trans>Configure</Trans>
          </button>
        </div>
      </div>

      <ConfigurationM365ImportModal show={showM365ImportConfig} onClose={toggleShowM365ImportConfig} />

      <div className="item">
        <label className="block-title">
          <Trans>Azure RI Import</Trans>
        </label>
        <div className="actions">
          <button type="button" className="btn btn-link" onClick={toggleShowAzure}>
            <Trans>Configure</Trans>
          </button>
        </div>
      </div>

      <ConfigurationAzureImportModal show={showAzure} onClose={toggleShowAzure} />

      <div className="item">
        <label className="block-title">
          <Trans>Azure ChatGPT Connector</Trans>
        </label>
        <div className="actions">
          <button type="button" className="btn btn-link" onClick={toggleShowChat}>
            <Trans>Configure</Trans>
          </button>
        </div>
      </div>

      <ConfigurationChatGPTModal show={showChat} onClose={toggleShowChat} />
    </div>
  );
};

export default withRouter(ConfigurationPage);
