import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';

type Props = {
  active?: boolean;
  onClick?: () => void;
};

const ButtonIndex: FunctionComponent<Props> = ({ active, onClick }) => {
  const key = Math.random();

  return (
    <div className="button-index">
      <button
        type="button"
        data-tip
        data-for={`index-${key}`}
        className={classNames('button', { active })}
        onClick={onClick}
      >
        <i className="icon-chapter-on" />
      </button>
      <button
        type="button"
        data-tip
        data-for={`index-${key}`}
        className={classNames('button', { active: !active })}
        onClick={onClick}
      >
        <i className="icon-chapter-off" />
      </button>
      <ReactTooltip id={`index-${key}`}>
        <Trans>Toggle Index</Trans>
      </ReactTooltip>
    </div>
  );
};

export default ButtonIndex;
