import gql from 'graphql-tag';
import { COMPANY } from './company';

export const IMPORT_MICROSOFT_OFFICE_365_INVOICE = gql`
  mutation importMicrosoftOffice365Invoice($payload: ImportMicrosoftOffice365InvoicePayload!) {
    importMicrosoftOffice365Invoice(payload: $payload)
  }
`;

export const IMPORT_MICROSOFT_OFFICE_365_INVOICE_NEW_LOGIC = gql`
  mutation importMicrosoftOffice365InvoiceNewLogic($payload: ImportMicrosoftOffice365InvoicePayload!) {
    importMicrosoftOffice365InvoiceNewLogic(payload: $payload)
  }
`;

export const GET_BEXIO_EXCLUDE_COMPANIES_CONFIG = gql`
  query getBexioExcludeCompaniesConfig {
    bexioExcludeCompaniesConfig {
      ...Company
    }
  }
  ${COMPANY}
`;

export const UPDATE_BEXIO_EXCLUDE_COMPANIES_CONFIG = gql`
  mutation updateBexioExcludeCompaniesConfig($payload: [ID!]!) {
    updateBexioExcludeCompaniesConfig(payload: $payload)
  }
`;

export const GET_BEXIO_EXCLUDE_COMPANIES_NEW_LOGIC_CONFIG = gql`
  query getBexioExcludeCompaniesNewLogicConfig {
    bexioExcludeCompaniesNewLogicConfig {
      ...Company
    }
  }
  ${COMPANY}
`;

export const UPDATE_BEXIO_EXCLUDE_COMPANIES_NEW_LOGIC_CONFIG = gql`
  mutation updateBexioExcludeCompaniesNewLogicConfig($payload: [ID!]!) {
    updateBexioExcludeCompaniesNewLogicConfig(payload: $payload)
  }
`;

export const IMPORT_AZURE_RI_INVOICE = gql`
  mutation importAzureRIInvoice($payload: ImportMicrosoftOffice365InvoicePayload!) {
    importAzureRIInvoice(payload: $payload)
  }
`;

export const AZURE_RI_REPORT_CONFIG = gql`
  fragment AzureRIReportConfig on AzureRIReportConfig {
    productId
    product {
      id
      title
    }
    margin
  }
`;

export const GET_AZURE_RI_REPORT_CONFIG = gql`
  query getAzureRIReportConfig {
    azureRIReportConfig {
      ...AzureRIReportConfig
    }
  }
  ${AZURE_RI_REPORT_CONFIG}
`;

export const UPDATE_AZURE_RI_REPORT_CONFIG = gql`
  mutation updateAzureRIReportConfig($payload: AzureRIConfigPayload!) {
    updateAzureRIReportConfig(payload: $payload)
  }
`;

export const GET_BEXIO_EXCLUDE_COMPANIES_AZURE_RI_CONFIG = gql`
  query getBexioExcludeCompaniesAzureRIConfig {
    bexioExcludeCompaniesAzureRIConfig {
      ...Company
    }
  }
  ${COMPANY}
`;

export const UPDATE_BEXIO_EXCLUDE_COMPANIES_AZURE_RI_CONFIG = gql`
  mutation updateBexioExcludeCompaniesAzureRIConfig($payload: [ID!]!) {
    updateBexioExcludeCompaniesAzureRIConfig(payload: $payload)
  }
`;
