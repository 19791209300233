import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import CreatableSelect from 'react-select/creatable';
import zip from '../../utils/zip';
import CountrySelect from '../form/CountrySelect';
import FieldErrorMessage from '../form/FieldErrorMessage';
import PhoneInput from '../form/PhoneInput';

type Props = {};

const CompanyInfoForm: FunctionComponent<Props> = () => {
  return (
    <div className="company-info-form">
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>
              <Trans>Address 1</Trans>
            </label>
            <Field type="text" className="form-control" name="address1" />

            <FieldErrorMessage name="address1" />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              <Trans>Zip</Trans>
            </label>
            <Field name="zip">
              {({ field, form }: { field: any; form: FormikProps<any> }) => {
                const handleChange = (value: any) => {
                  if (value.value.zip) {
                    form.setFieldValue('zip', String(value.value.zip));
                    form.setFieldValue('canton', value.value.canton);
                    form.setFieldValue('city', value.value.city);
                  } else {
                    form.setFieldValue('zip', value.value);
                  }
                };

                const model = field.value
                  ? {
                      value: field.value,
                      label: field.value,
                    }
                  : null;

                const options = zip.map((el) => ({
                  value: el,
                  label: `${el.zip}, ${el.canton}, ${el.city}`,
                }));

                return (
                  <CreatableSelect
                    value={model}
                    options={options}
                    cacheOptions
                    defaultOptions
                    onChange={handleChange}
                    placeholder={i18n._(t`Select...`)}
                    components={{
                      ClearIndicator: null,
                      IndicatorSeparator: null,
                      DropdownIndicator: null,
                    }}
                  />
                );
              }}
            </Field>

            <FieldErrorMessage name="zip" />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              <Trans>Canton</Trans>
            </label>
            <Field type="text" className="form-control" name="canton" />

            <FieldErrorMessage name="canton" />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              <Trans>City</Trans>
            </label>
            <Field type="text" className="form-control" name="city" />

            <FieldErrorMessage name="city" />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              <Trans>Country</Trans>
            </label>
            <CountrySelect name="country" />

            <FieldErrorMessage name="country" />
          </div>
        </div>

        <div className="col-12">
          <div className="form-group">
            <label>
              <Trans>General Phone</Trans>
            </label>
            <PhoneInput name="generalPhone" />

            <FieldErrorMessage name="generalPhone" />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              <Trans>General Email</Trans>
            </label>
            <Field type="email" className="form-control" name="generalEmail" />

            <FieldErrorMessage name="generalEmail" />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              <Trans>Invoice Email</Trans>
            </label>
            <Field type="email" className="form-control" name="invoiceEmail" />

            <FieldErrorMessage name="invoiceEmail" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfoForm;
