import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import ReactTable, { CellInfo } from 'react-table';
import { NavLink } from 'react-router-dom';
import MainTitle from '../components/common/MainTitle';
import TableActions from '../components/common/TableActions';
import ContractArchiveModal from '../components/contract/ContractArchiveModal';
import ButtonArchive from '../components/form/ButtonArchive';
import ButtonEdit from '../components/form/ButtonEdit';
import LoadingTable from '../components/common/LoadingTable';
import FilterHeader from '../components/form/FilterHeader';
import { GET_COMPANIES_FOR_FILTER } from '../schema/company';
import { GET_CONTRACTS, ARCHIVE_CONTRACT, UNARCHIVE_CONTRACT } from '../schema/contract';
import { OrderDirection } from '../schema/graphql-global-types';
import { archiveContract, archiveContractVariables } from '../schema/types/archiveContract';
import { getContracts, getContractsVariables } from '../schema/types/getContracts';
import { unarchiveContract, unarchiveContractVariables } from '../schema/types/unarchiveContract';
import { monthNames, tableOptions, tablePollingInterval } from '../utils/const';
import ErrorQuery from '../components/form/ErrorQuery';
import { useVariables } from '../utils/hooks';
import { cloneDeep } from 'lodash';
import { DataProxy } from '@apollo/client/cache';
import { getFilter, getOrder } from '../utils/utils';

const ContractPage: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const {
    search$,
    search,
    filter$,
    filter,
    archived,
    setArchived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
  } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const updateQuery = (proxy: DataProxy, id: any) => {
    const data: any = cloneDeep(proxy.readQuery({ query: GET_CONTRACTS, variables }));
    data.contracts.items = data.contracts.items.filter((el: any) => el.id !== id);
    proxy.writeQuery({ query: GET_CONTRACTS, variables, data });
  };

  const [mutateArchive] = useMutation<archiveContract, archiveContractVariables>(ARCHIVE_CONTRACT, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.archiveContract.contract.id);
    },
  });
  const [mutateUnarchive] = useMutation<unarchiveContract, unarchiveContractVariables>(UNARCHIVE_CONTRACT, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.unarchiveContract.contract.id);
    },
  });

  const handleArchive = useCallback(
    (id: any, date?: string) => () => {
      if (archived) {
        mutateUnarchive({
          variables: {
            id,
          },
          optimisticResponse: {
            unarchiveContract: {
              contract: {
                id,
                __typename: 'Contract',
              },
              __typename: 'MutateContractResponse',
            },
          },
        });
      } else {
        mutateArchive({
          variables: {
            id,
            archiveDate: date,
          },
          optimisticResponse: {
            archiveContract: {
              contract: {
                id,
                __typename: 'Contract',
              },
              __typename: 'MutateContractResponse',
            },
          },
        });
      }
    },
    [archived, mutateArchive, mutateUnarchive],
  );

  const toggleArchive = useCallback((id: any) => () => setShowArchive(id), [setShowArchive]);

  const submitArchive = useCallback((date: string) => handleArchive(showArchive, date)(), [showArchive, handleArchive]);

  const { data, previousData, loading, error } = useQuery<getContracts, getContractsVariables>(GET_CONTRACTS, {
    variables,
    pollInterval: tablePollingInterval,
  });

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.contracts.items ?? previousData?.contracts.items ?? [];
  const pages = data && data.contracts ? Math.ceil(data.contracts.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Contract`),
      accessor: 'name',
      sortable: true,
      Cell: (cell: CellInfo) => <NavLink to={`/crm/contract/${cell.original.id}`}>{cell.value}</NavLink>,
    },
    {
      Header: i18n._(t`Customer`),
      accessor: 'customer.name',
      sortable: true,
      filterable: true,
      Cell: (cell: CellInfo) => <NavLink to={`/crm/company/${cell.original.customer.id}`}>{cell.value}</NavLink>,
      Filter: (params: any) => (
        <FilterHeader
          query={GET_COMPANIES_FOR_FILTER}
          objectType="companies"
          nameField="name"
          onChange={params.onChange}
        />
      ),
    },
    {
      Header: i18n._(t`Cycle`),
      accessor: 'cycle',
      sortable: true,
      filterable: true,
      width: 100,
      className: 'text-center',
      Cell: (cell: CellInfo) => i18n._(cell.value),
      Filter: (params: any) => (
        <select
          className="form-control"
          onChange={(event) => params.onChange(event.target.value ? [event.target.value] : '')}
          value={params.filter ? params.filter.value : ''}
        >
          <option value="">{i18n._(t`All`)}</option>
          <option value="Yearly">{i18n._(t`Yearly`)}</option>
          <option value="Monthly">{i18n._(t`Monthly`)}</option>
        </select>
      ),
    },
    {
      Header: i18n._(t`Cycle Date`),
      accessor: 'yearlyInterval',
      sortable: true,
      filterable: true,
      width: 160,
      className: 'text-center',
      Cell: (cell: CellInfo) =>
        cell.original.cycle === 'Yearly'
          ? i18n._(t`First of`) + ` ${monthNames()[cell.value]}`
          : i18n._(t`First of Month`),
      Filter: (params: any) => (
        <select
          className="form-control"
          onChange={(event) => params.onChange(event.target.value ? [Number(event.target.value)] : '')}
          value={params.filter ? params.filter.value : ''}
        >
          <option value="">{i18n._(t`All`)}</option>
          {Array.from(Array(12), (el, index) => (
            <option value={index} key={index}>
              {i18n._(t`First of`)} {monthNames()[index]}
            </option>
          ))}
        </select>
      ),
    },
    {
      Header: i18n._(t`Actions`),
      width: 90,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <>
          <NavLink to={`/crm/contract/${cell.original.id}`}>
            <ButtonEdit />
          </NavLink>
          {archived ? (
            <ButtonArchive onClick={handleArchive(cell.original.id)} />
          ) : (
            <ButtonArchive onClick={toggleArchive(cell.original.id)} />
          )}
        </>
      ),
    },
  ];

  return (
    <div className="contract-page">
      <div className="card-body">
        <div className="table-actions">
          <div className="breadcrumbs-area">
            <MainTitle title={i18n._(t`Contract`)} tips="CRM/Contracts" />
          </div>

          <TableActions onSearch={(e) => search$.next(e.target.value)} archived={archived} onArchive={setArchived} />

          <NavLink to="/crm/contract/add" type="button" className="btn btn-sm btn-primary">
            <i className="icon-add" />
            <Trans>Add new contract</Trans>
          </NavLink>
        </div>

        {!loaded ? (
          <LoadingTable />
        ) : (
          <ReactTable
            data={items}
            columns={columns}
            pages={pages}
            pageSize={limit}
            manual
            onFetchData={(state) => {
              filter$.next(
                getFilter(state, {
                  'customer.name': 'companyIds',
                  cycle: 'cycles',
                  yearlyInterval: 'yearlyIntervals',
                }),
              );

              let orderDir = OrderDirection.Desc;
              if (state.sorted[0]) {
                orderDir = state.sorted[0].desc ? OrderDirection.Desc : OrderDirection.Asc;
              }

              setOffset(state.page * state.pageSize);
              setOrderBy(
                getOrder(state, {
                  'customer.name': 'customerName',
                }),
              );
              setOrderDirection(orderDir);
            }}
            onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
            {...tableOptions()}
          />
        )}

        <ContractArchiveModal show={showArchive} onSubmit={submitArchive} onClose={toggleArchive(null)} />
      </div>
    </div>
  );
};

export default ContractPage;
