import React, { FunctionComponent, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Redirect, Route, Switch } from 'react-router-dom';
import NotFoundPage from '../containers/NotFoundPage';
import { GET_VIEWER } from '../schema/auth';
import { getViewer } from '../schema/types/getViewer';
import { getStorage, setStorage } from '../utils/storage';
import { getRoutes } from '../utils/utils';

const AppRouter: FunctionComponent<{}> = () => {
  const { data, loading } = useQuery<getViewer>(GET_VIEWER, {
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (!loading) {
      document.getElementById('global-loader')!.style.display = 'none';
      document.querySelector('body')!.classList.remove('bg-gradient-primary');
    }
  }, [loading]);

  if (!data) return <div />;

  if (!getStorage('language')) {
    setStorage('language', data.viewer.config.language);
  }

  const routes = getRoutes(data.viewer);

  return (
    <Switch>
      {routes.map((el, id) =>
        el.children ? (
          <Redirect path={el.href} exact key={id} to={el.children[0].href} />
        ) : (
          <Route path={el.href} exact key={id} component={el.component} />
        ),
      )}

      {routes.map(
        (el, id) =>
          el.children &&
          el.children.map(
            (child, id2) => child.component && <Route path={child.href} exact key={id2} component={child.component} />,
          ),
      )}
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default AppRouter;
