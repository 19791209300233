import { Trans } from '@lingui/macro';
import { Field } from 'formik';
import React, { FunctionComponent } from 'react';
import FieldErrorMessage from '../form/FieldErrorMessage';
import PhoneInput from '../form/PhoneInput';

type Props = {};

const PersonCompanyInfoForm: FunctionComponent<Props> = () => {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Address 1</Trans>
          </label>
          <Field type="text" className="form-control" name="address1" />

          <FieldErrorMessage name="address1" />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Address 2</Trans>
          </label>
          <Field type="text" className="form-control" name="address2" />

          <FieldErrorMessage name="address2" />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Zip</Trans>
          </label>
          <Field type="text" className="form-control" name="zip" />

          <FieldErrorMessage name="zip" />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Canton</Trans>
          </label>
          <Field type="text" className="form-control" name="canton" />

          <FieldErrorMessage name="canton" />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>City</Trans>
          </label>
          <Field type="text" className="form-control" name="city" />

          <FieldErrorMessage name="city" />
        </div>
      </div>

      <div className="col-md-6">
        <div className=" form-group">
          <label>
            <Trans>Country</Trans>
          </label>
          <Field type="text" className="form-control" name="country" />

          <FieldErrorMessage name="country" />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>General Phone</Trans>
          </label>
          <PhoneInput name="generalPhone" />

          <FieldErrorMessage name="generalPhone" />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>General Email</Trans>
          </label>
          <Field type="email" className="form-control" name="generalEmail" />

          <FieldErrorMessage name="generalEmail" />
        </div>
      </div>

      <div className="col-12">
        <div className="form-group">
          <label>
            <Trans>Ticket System Domains</Trans>
          </label>

          <div className="history-item">
            <Field name="ticketDomains">
              {({ field }: { field: any }) => (
                <>
                  {field.value.map((el: any, index: number) => (
                    <span key={index} className="badge mr-2">
                      {el}
                    </span>
                  ))}
                </>
              )}
            </Field>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonCompanyInfoForm;
