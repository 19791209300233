import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import OutsideClickHandler from 'react-outside-click-handler';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { OfferItemProjectItemStatus } from '../../schema/graphql-global-types';
import { getUsers, getUsersVariables } from '../../schema/types/getUsers';
import { OfferItem } from '../../schema/types/OfferItem';
import { OfferProjectItem } from '../../schema/types/OfferProjectItem';
import { GET_USERS_FOR_COMPANY } from '../../schema/user';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';
import ButtonDelete from '../form/ButtonDelete';
import FieldDatePicker from '../form/FieldDatepicker';

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  display: isDragging ? 'table' : undefined,
  ...draggableStyle,
});

type Props = {
  name: string;
  model: OfferItem;
  onAdd: (result: any) => void;
  onSort: (result: any) => void;
  onRemove: (index: number) => () => void;
};

const OfferEditorProject: FunctionComponent<Props> = ({ name, model, onAdd, onSort, onRemove }) => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const [showStatus, setShowStatus] = useState(-1);

  const { data, loading } = useQuery<getUsers, getUsersVariables>(GET_USERS_FOR_COMPANY, {
    variables,
    fetchPolicy: 'cache-first',
  });

  const handleLoad = useCallback((value: string) => search$.next(value), [search$]);

  const options = loading
    ? []
    : data!.users.items.map((el: any) => ({
        value: el,
        label: el.fullName,
      }));

  const handleShowStatus = useCallback(
    (index: number) => () => {
      setTimeout(() => {
        setShowStatus(index);
      }, 50);
    },
    [setShowStatus],
  );

  return (
    <div className="document-product-table table-responsive">
      <div className="history-item form-group">
        <label className="form-label">
          <Trans>User</Trans>
        </label>

        <Select
          options={options}
          isLoading={loading}
          onChange={onAdd}
          onInputChange={handleLoad}
          placeholder={i18n._(t`Add User`)}
          {...selectOptions}
        />
      </div>

      <table className="custom-table">
        <thead className="custom-table-header">
          <tr>
            <th className="column title">
              <Trans>Task</Trans>
            </th>
            <th className="column number">
              <Trans>User Assignment</Trans>
            </th>
            <th className="column date">
              <Trans>Start</Trans>
            </th>
            <th className="column date">
              <Trans>End</Trans>
            </th>
            <th className="column status">
              <Trans>Status</Trans>
            </th>
            <th className="column action" />
            <th className="column handle" />
          </tr>
        </thead>

        <DragDropContext onDragEnd={onSort}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {model.projectItems!.map((item: OfferProjectItem, index: number) => {
                  return (
                    <Draggable key={index} draggableId={String(index)} index={index}>
                      {(provided, snapshot) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          className="custom-table-body"
                        >
                          <td className="column title">
                            <Field
                              type="text"
                              className="form-control"
                              name={`${name}.projectItems[${index}].description`}
                              placeholder={i18n._(t`Task`)}
                            />
                          </td>
                          <td className="column number">
                            <span
                              data-tip
                              data-for={`avatar-${name}-${index}`}
                              className="button button-delete text-xs"
                            >
                              <Field name={`${name}.projectItems[${index}].user.avatar.imageInstance.object.url`}>
                                {({ field }: { field: any }) =>
                                  field.value ? (
                                    <img src={field.value} alt="" className="rounded-circle user-avatar" />
                                  ) : (
                                    <i className="fa fa-user rounded-circle user-avatar" />
                                  )
                                }
                              </Field>
                            </span>
                            <ReactTooltip id={`avatar-${name}-${index}`}>
                              <Field name={`${name}.projectItems[${index}].user.fullName`}>
                                {({ field }: { field: any }) => field.value}
                              </Field>
                            </ReactTooltip>
                          </td>
                          <td className="column date">
                            <FieldDatePicker name={`${name}.projectItems[${index}].startDate`} />
                          </td>
                          <td className="column date">
                            <FieldDatePicker name={`${name}.projectItems[${index}].endDate`} />
                          </td>
                          <td className="column status">
                            <OutsideClickHandler onOutsideClick={handleShowStatus(-1)}>
                              <Field name={`${name}.projectItems[${index}].status`}>
                                {({ field, form }: { field: any; form: FormikProps<any> }) => {
                                  const handleSelectStatus = (status: OfferItemProjectItemStatus) => () => {
                                    form.setFieldValue(`${name}.projectItems[${index}].status`, status);
                                    handleShowStatus(-1)();
                                  };

                                  return (
                                    <div className="form-control toggle">
                                      <div className="button-wrap" onClick={handleShowStatus(index)}>
                                        <button type="button" className={classNames('badge btn-status', field.value)}>
                                          {field.value}
                                        </button>
                                      </div>

                                      {showStatus === index && (
                                        <div className="dropdown">
                                          <button
                                            type="button"
                                            className={classNames('item', {
                                              active: field.value === OfferItemProjectItemStatus.WorkingOnIt,
                                            })}
                                            onClick={handleSelectStatus(OfferItemProjectItemStatus.WorkingOnIt)}
                                          >
                                            <div className="badge btn-status WorkingOnIt">
                                              <Trans>WorkingOnIt</Trans>
                                            </div>
                                          </button>
                                          <button
                                            type="button"
                                            className={classNames('item', {
                                              active: field.value === OfferItemProjectItemStatus.Done,
                                            })}
                                            onClick={handleSelectStatus(OfferItemProjectItemStatus.Done)}
                                          >
                                            <div className="badge btn-status Done">
                                              <Trans>Done</Trans>
                                            </div>
                                          </button>
                                          <button
                                            type="button"
                                            className={classNames('item', {
                                              active: field.value === OfferItemProjectItemStatus.WaitingForFeedback,
                                            })}
                                            onClick={handleSelectStatus(OfferItemProjectItemStatus.WaitingForFeedback)}
                                          >
                                            <div className="badge btn-status WaitingForFeedback">
                                              <Trans>WaitingForFeedback</Trans>
                                            </div>
                                          </button>
                                          <button
                                            type="button"
                                            className={classNames('item', {
                                              active: field.value === OfferItemProjectItemStatus.Open,
                                            })}
                                            onClick={handleSelectStatus(OfferItemProjectItemStatus.Open)}
                                          >
                                            <div className="badge btn-status Open">
                                              <Trans>Open</Trans>
                                            </div>
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  );
                                }}
                              </Field>
                            </OutsideClickHandler>
                          </td>
                          <td className="column action">
                            <ButtonDelete onClick={onRemove(index)} />
                          </td>
                          <td className="column handle" {...provided.dragHandleProps}>
                            <i className="icon-drag-and-drop" />
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
    </div>
  );
};

export default OfferEditorProject;
