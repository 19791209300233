import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';

type Props = {
  onClick?: () => void;
};

const ButtonEdit: FunctionComponent<Props> = ({ onClick }) => {
  const key = Math.random();

  return (
    <>
      <span data-tip data-for={`edit-${key}`} className="button button-edit text-xs" onClick={onClick}>
        <i className="icon-edit" />
      </span>
      <ReactTooltip id={`edit-${key}`}>
        <Trans>Edit</Trans>
      </ReactTooltip>
    </>
  );
};

export default ButtonEdit;
