import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { GET_DOCUMENTS_WITH_DETAIL } from '../../schema/document';
import { DocumentElement } from '../../schema/types/DocumentElement';
import { getDocumentsWithDetail, getDocumentsWithDetailVariables } from '../../schema/types/getDocumentsWithDetail';
import { OfferProductItem } from '../../schema/types/OfferProductItem';
import { OfferProjectItem } from '../../schema/types/OfferProjectItem';
import { Tag } from '../../schema/types/Tag';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';
import { cloneDeep } from 'lodash';

const DocumentationTemplateForm: FunctionComponent = () => {
  const [show, setShow] = useState(false);
  const [model, setModel] = useState<any>(null);

  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
        template: true,
      },
    },
  };

  const toggleModal = useCallback(() => setShow((prevState) => !prevState), [setShow]);

  const { data, loading } = useQuery<getDocumentsWithDetail, getDocumentsWithDetailVariables>(
    GET_DOCUMENTS_WITH_DETAIL,
    {
      variables,
      fetchPolicy: 'cache-first',
    },
  );

  if (!data && !loading) return <div />;

  return (
    <Field name="category">
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (value: any) => {
          setModel(cloneDeep(value.value));
          toggleModal();
        };

        const handleSubmit = () => {
          model.tagNames = model.tags.map((el: Tag) => el.name);
          model.customerIds = model.customers.map((el: any) => el.id);
          model.elements = model.elements.map((el: DocumentElement) => ({
            ...el,
            templateElementId: el.templateElement ? el.templateElement.id : null,
            item: el.item
              ? {
                  ...el.item,
                  productItems: el.item.productItems!.map((item: OfferProductItem) => ({
                    ...item,
                    productId: item.product.id,
                  })),
                  projectItems: el.item.projectItems!.map((item: OfferProjectItem) => ({
                    ...item,
                    userId: item.user.id,
                  })),
                }
              : null,
          }));

          // if (model.category) {
          //   form.setFieldValue('categoryId', model.category.id);
          //   form.setFieldValue('category', model.category);
          // }

          if (model.headGraphic) {
            form.setFieldValue('headGraphicId', model.headGraphic.id);
            form.setFieldValue('headGraphic', model.headGraphic);
          }

          form.setFieldValue('elements', model.elements);

          form.setFieldValue('customers', model.customers);
          form.setFieldValue('customerIds', model.customerIds);

          // form.setFieldValue('tags', model.tags);
          // form.setFieldValue('tagNames', model.tagNames);

          toggleModal();
        };

        const handleLoad = (value: string) => search$.next(value);

        const options = loading
          ? []
          : data!.documents.items.map((el: any) => ({
              value: el,
              label: el.title,
            }));

        return (
          <>
            <Select
              options={options}
              isLoading={loading}
              onChange={handleChange}
              onInputChange={handleLoad}
              placeholder={i18n._(t`Choose Template`)}
              {...selectOptions}
            />

            <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
              <div className="modal fade show" style={{ display: 'block' }}>
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h2 className="modal-title">
                        <Trans>Are you sure want to choose this template?</Trans>
                      </h2>
                      <button type="button" className="close" aria-label="Close" onClick={toggleModal}>
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>

                    <div className="modal-body text-center text-danger">
                      <Trans>All data will be overwritten.</Trans>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-lnk" onClick={toggleModal}>
                        <Trans>Close</Trans>
                      </button>

                      <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                        <Trans>Use Template</Trans>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </>
        );
      }}
    </Field>
  );
};

export default DocumentationTemplateForm;
