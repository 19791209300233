import gql from 'graphql-tag';
import { DOCUMENT_ELEMENT } from './document';
import { DOCUMENT_CATEGORY } from './documentCategory';
import { TAG } from './tag';

export const PUBLIC_DOCUMENT = gql`
  fragment PublicDocument on Document {
    id
    title
    category {
      ...DocumentCategory
    }
    tags {
      ...Tag
    }
    updatedAt
    publicUrl
  }
  ${TAG}
  ${DOCUMENT_CATEGORY}
`;

export const PUBLIC_DOCUMENTS = gql`
  fragment PublicDocuments on DocumentCollection {
    limit
    offset
    total
    items {
      ...PublicDocument
    }
  }
  ${PUBLIC_DOCUMENT}
`;

export const GET_PUBLIC_DOCUMENTS = gql`
  query getPublicDocuments($query: DocumentsQuery!) {
    publicDocuments(query: $query) {
      ...PublicDocuments
    }
  }
  ${PUBLIC_DOCUMENTS}
`;

export const PUBLIC_DOCUMENT_DETAIL = gql`
  fragment PublicDocumentDetail on Document {
    id
    title
    category {
      ...DocumentCategory
    }
    tags {
      ...Tag
    }
    permanentLink
    customers {
      id
      name
    }
    headGraphic {
      id
      url
    }
    elements {
      ...DocumentElement
    }
    visibility
    template
    publicUrl
  }
  ${TAG}
  ${DOCUMENT_ELEMENT}
  ${DOCUMENT_CATEGORY}
`;

export const GET_PUBLIC_DOCUMENT = gql`
  query getPublicDocument($query: ID!) {
    publicDocument(id: $query) {
      ...PublicDocumentDetail
    }
  }
  ${PUBLIC_DOCUMENT_DETAIL}
`;
