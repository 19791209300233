import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { CREATE_OFFER_LOG, GET_OFFERS } from '../../schema/offer';
import { CreateOfferLogPayload } from '../../schema/graphql-global-types';
import { createOfferLog, createOfferLogVariables } from '../../schema/types/createOfferLog';
import { Log } from '../../schema/types/Log';
import { toastErrorQuery } from '../../utils/error';
import { validateRequired } from '../../utils/validations';
import HistoryItem from '../common/HistoryItem';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';

type Props = {
  model: any;
  variables: any;
  show: boolean;
  onClose: () => void;
};

const OfferLogModal: FunctionComponent<Props> = ({ model, variables, show, onClose }) => {
  const [mutate] = useMutation<createOfferLog, createOfferLogVariables>(CREATE_OFFER_LOG, {
    refetchQueries: [
      {
        query: GET_OFFERS,
        variables,
      },
    ],
  });

  const initialValues: CreateOfferLogPayload = {
    content: '',
  };

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Add new offer log</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={yup.object().shape({
                content: validateRequired,
              })}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const result: any = await mutate({
                    variables: {
                      id: model.id,
                      payload: values,
                    },
                  });

                  if (result.errors) {
                    toastErrorQuery(result.errors);
                  } else {
                    toast.success(i18n._(t`Success!`));
                    onClose();
                  }
                } catch (e) {
                  toastErrorQuery(e);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="modal-body">
                    <div className="form-group">
                      <label>
                        <Trans>Internal note</Trans>
                      </label>
                      <Field component="textarea" className="form-control" name="content" rows={2} autoFocus />

                      <FieldErrorMessage name="content" />
                    </div>

                    <div className="form-group action-submit">
                      <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
                    </div>

                    {model.logs.items.map((el: Log) => (
                      <HistoryItem key={el.id} date={el.createdAt} creator={el.creator} content={el.content} />
                    ))}
                  </div>

                  <div className="modal-footer">
                    <button type="button" className="btn btn-link" onClick={onClose}>
                      <Trans>Close</Trans>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OfferLogModal;
