import React, { FunctionComponent } from 'react';

type Props = {
  title: string;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
};

const ButtonSubmit: FunctionComponent<Props> = ({
  title,
  loading = false,
  type = 'submit',
  className = 'btn btn-primary',
}) => {
  return (
    <button type={type} className={className} disabled={loading}>
      {loading ? <i className="fa fa-spinner fa-spin" /> : title}
    </button>
  );
};

export default ButtonSubmit;
