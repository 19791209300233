import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field, Form, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldEditor from '../form/FieldEditor';
import FieldErrorMessage from '../form/FieldErrorMessage';
import MultipleFileUploader from '../form/MultipleFileUploader';

type Props = {
  loading: boolean;
};

const PersonalTicketInternalUpdateForms: FunctionComponent<Props> = ({ loading }) => {
  return (
    <Form>
      <div className="table-actions form-table-actions">
        <div className="breadcrumbs-area" />

        <ButtonSubmit className="btn btn-primary" title={i18n._(t`Send ticket`)} loading={loading} />
      </div>

      <div className="card">
        <div className="form-group">
          <FieldEditor name="content" />

          <FieldErrorMessage name="content" />
        </div>

        <div className="form-group files d-flex flex-wrap flex-grow-1 text-center">
          <Field name="attachments">
            {({ field, form }: { field: any; form: FormikProps<any> }) => {
              const handleUpload = (value: any) => {
                const photoIds = form.values.attachmentIds;
                photoIds.push(value.id);
                form.setFieldValue('attachmentIds', photoIds);

                const photos = form.values.attachments;
                photos.push(value);
                form.setFieldValue('attachments', photos);
              };

              const handleRemove = (index: number) => {
                const photoIds = form.values.attachmentIds;
                photoIds.splice(index, 1);
                form.setFieldValue('attachmentIds', photoIds);

                if (form.values.attachments && form.values.attachments.length >= index) {
                  const photos = form.values.attachments;
                  photos.splice(index, 1);
                  form.setFieldValue('attachments', photos);
                }
              };

              return <MultipleFileUploader onUpload={handleUpload} onRemove={handleRemove} files={field.value} />;
            }}
          </Field>
        </div>
      </div>
    </Form>
  );
};

export default PersonalTicketInternalUpdateForms;
