import { Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback } from 'react';

type Props = {
  model: boolean;
  onChange: (value: boolean) => void;
};

const ShowArchive: FunctionComponent<Props> = ({ model, onChange }) => {
  const handleChange = useCallback(() => onChange(!model), [model, onChange]);

  return (
    <button type="button" className="text-info" onClick={handleChange}>
      {!model ? <Trans>Show archived</Trans> : <Trans>Hide archived</Trans>}
    </button>
  );
};

export default ShowArchive;
