import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field } from 'formik';
import React, { FunctionComponent } from 'react';
import ButtonDelete from '../form/ButtonDelete';

type Props = {
  name: string;
  onRemove: () => void;
};

const ContractReportingInfoFormItem: FunctionComponent<Props> = ({ name, onRemove }) => {
  return (
    <tr className="custom-table-body">
      <td className="column title">
        <Field name={`${name}.product.title`}>{({ field }: { field: any }) => field.value}</Field>
      </td>
      <td className="column number">
        <Field type="number" name={`${name}.pieces`} className="form-control" placeholder={i18n._(t`Pieces`)} />
      </td>
      <td className="column title">
        <Field name={`${name}.contractType.name`}>{({ field }: { field: any }) => field.value}</Field>
      </td>
      <td className="column action">
        <ButtonDelete onClick={onRemove} />
      </td>
    </tr>
  );
};

export default ContractReportingInfoFormItem;
