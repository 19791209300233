import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import LoadingForm from '../components/common/LoadingForm';
import ErrorQuery from '../components/form/ErrorQuery';
import TicketFormPanel from '../components/ticket/TicketFormPanel';
import { GET_TICKET } from '../schema/ticket';
import { getTicket, getTicketVariables } from '../schema/types/getTicket';
import { DefaultRouteParams } from '../utils/const';
import NotFoundPage from './NotFoundPage';

const SupportTicketDetailPage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ match }) => {
  const { data, loading, error } = useQuery<getTicket, getTicketVariables>(GET_TICKET, {
    variables: { query: match.params.id },
    fetchPolicy: 'cache-and-network',
  });

  if (!data && loading) return <LoadingForm />;
  if (error) return <ErrorQuery error={error} />;
  if (!data) return <NotFoundPage />;

  return (
    <div className="ticket-page">
      <div className="form-breadcrumbs-area">
        <NavLink to="/support/closed">
          <Trans>Closed Tickets</Trans>
        </NavLink>

        <i className="fa fa-angle-left" />

        <Trans>Edit ticket</Trans>
      </div>

      <div className="table-actions form-table-actions">
        <div className="breadcrumbs-area">
          <div className="main-title">{data.ticket.subject}</div>
        </div>
      </div>

      <div className="card">
        <TicketFormPanel model={data.ticket} />
      </div>
    </div>
  );
};

export default SupportTicketDetailPage;
