import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import copy from 'copy-to-clipboard';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import {
  ConnectMonitorPayload,
  MonitorKeywordType,
  MonitorPortType,
  MonitorStatus,
  MonitorType,
} from '../../schema/graphql-global-types';
import { validateInterval } from '../../utils/validations';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';
import AssetMonitorHTTPForm from './AssetMonitorHTTPForm';
import AssetMonitorKeywordForm from './AssetMonitorKeywordForm';
import AssetMonitorPortForm from './AssetMonitorPortForm';

const handleCopy = (value: string) => () => {
  copy(value);
  toast.success(i18n._(t`Copied!`));
};

type Props = {
  value: any;
  show: boolean;
  onSubmit: (model: any) => void;
  onClose: () => void;
};

const AssetMonitorModal: FunctionComponent<Props> = ({ value, show, onSubmit, onClose }) => {
  const model: ConnectMonitorPayload = value || {
    url: '',
    type: MonitorType.HTTP,
    interval: 60,
    status: MonitorStatus.Active,
    http: {
      username: '',
      password: '',
      headers: [],
      statuses: [],
    },
    keyword: {
      type: MonitorKeywordType.Exists,
      value: '',
    },
    port: {
      port: 443,
      type: MonitorPortType.HTTPS,
    },
  };

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                {value ? (
                  <>
                    <Trans>Monitor</Trans> {value.id}
                  </>
                ) : (
                  <Trans>Uptime Monitor</Trans>
                )}
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              enableReinitialize
              initialValues={model}
              validationSchema={yup.object().shape({
                interval: validateInterval,
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                onSubmit(values);
                resetForm();
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, submitForm }) => {
                const handleSubmit = (e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  submitForm();
                };

                return (
                  <Form onSubmitCapture={handleSubmit}>
                    <div className="modal-body">
                      <div className="row">
                        <Field name="type">
                          {({ field }: { field: any }) => (
                            <>
                              {field.value !== MonitorType.Heartbeat && (
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      <Trans>URL / IP</Trans>
                                    </label>
                                    <Field type="text" className="form-control" name="url" />
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </Field>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <Trans>Interval</Trans>
                            </label>
                            <Field type="number" className="form-control" name="interval" min={60} />
                            <em>
                              <small>
                                <Trans>Interval in seconds, minimum 60 seconds</Trans>
                              </small>
                            </em>

                            <FieldErrorMessage name="interval" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <Trans>Status</Trans>
                            </label>
                            <Field component="select" className="form-control" name="status">
                              <option value="Active">{i18n._(t`Active`)}</option>
                              <option value="Inactive">{i18n._(t`Inactive`)}</option>
                            </Field>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <Trans>Type</Trans>
                            </label>
                            <Field component="select" className="form-control" name="type">
                              {Object.keys(MonitorType).map((el: any, index: number) => (
                                <option key={index} value={el}>
                                  {el}
                                </option>
                              ))}
                            </Field>
                          </div>
                        </div>
                      </div>

                      {value && value.heartbeat && value.heartbeat.url && (
                        <div className="form-group">
                          <label className="d-flex justify-content-between">
                            <Trans>URL</Trans>

                            <a className="text-primary" onClick={handleCopy(value.heartbeat.url)}>
                              Copy this URL
                              <i className="fa fa-copy ml-2" />
                            </a>
                          </label>

                          <a href={value.heartbeat.url} target="_blank" className="text-break">
                            {value.heartbeat.url}
                          </a>
                        </div>
                      )}

                      <Field name="type">
                        {({ field }: { field: any }) => (
                          <>
                            {field.value === MonitorType.HTTP && <AssetMonitorHTTPForm />}

                            {field.value === MonitorType.Keyword && <AssetMonitorKeywordForm />}

                            {field.value === MonitorType.Port && <AssetMonitorPortForm />}
                          </>
                        )}
                      </Field>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={onClose}>
                        <Trans>Close</Trans>
                      </button>

                      <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AssetMonitorModal;
