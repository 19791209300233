import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import ReactTable, { CellInfo } from 'react-table';
import ButtonArchive from '../../components/form/ButtonArchive';
import ButtonEdit from '../../components/form/ButtonEdit';
import NotFoundPage from '../../containers/NotFoundPage';
import {
  ARCHIVE_DOCUMENT_CATEGORY,
  GET_DOCUMENT_CATEGORIES,
  UNARCHIVE_DOCUMENT_CATEGORY,
} from '../../schema/documentCategory';
import { archiveDocumentCategory, archiveDocumentCategoryVariables } from '../../schema/types/archiveDocumentCategory';
import { getDocumentCategories, getDocumentCategoriesVariables } from '../../schema/types/getDocumentCategories';
import {
  unarchiveDocumentCategory,
  unarchiveDocumentCategoryVariables,
} from '../../schema/types/unarchiveDocumentCategory';
import { tableOptions } from '../../utils/const';
import ErrorQuery from '../../components/form/ErrorQuery';
import { DocumentCategory } from '../../schema/types/DocumentCategory';
import { cloneDeep } from 'lodash';
import { DataProxy } from '@apollo/client/cache';
import { useVariables } from '../../utils/hooks';
import ShowArchive from '../common/ShowArchive';

type Props = {
  onSelect: (item: DocumentCategory) => void;
  onEdit: (item: DocumentCategory) => void;
};

const DocumentationCategories: FunctionComponent<Props> = ({ onSelect, onEdit }) => {
  const { search, filter, archived, setArchived, limit, setLimit, offset, setOffset, orderBy, orderDirection } =
    useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const updateQuery = (proxy: DataProxy, id: any) => {
    const data: any = cloneDeep(proxy.readQuery({ query: GET_DOCUMENT_CATEGORIES, variables }));
    data.documentCategories.items = data.documentCategories.items.filter((el: any) => el.id !== id);
    proxy.writeQuery({ query: GET_DOCUMENT_CATEGORIES, variables, data });
  };

  const [mutateArchive] = useMutation<archiveDocumentCategory, archiveDocumentCategoryVariables>(
    ARCHIVE_DOCUMENT_CATEGORY,
    {
      update: (proxy, { data }) => {
        updateQuery(proxy, data!.archiveDocumentCategory.documentCategory.id);
      },
    },
  );
  const [mutateUnarchive] = useMutation<unarchiveDocumentCategory, unarchiveDocumentCategoryVariables>(
    UNARCHIVE_DOCUMENT_CATEGORY,
    {
      update: (proxy, { data }) => {
        updateQuery(proxy, data!.unarchiveDocumentCategory.documentCategory.id);
      },
    },
  );

  const handleArchive = useCallback(
    (id: any) => () => {
      if (archived) {
        mutateUnarchive({
          variables: {
            id,
          },
          optimisticResponse: {
            unarchiveDocumentCategory: {
              documentCategory: {
                id,
                __typename: 'DocumentCategory',
              },
              __typename: 'MutateDocumentCategoryResponse',
            },
          },
        });
      } else {
        mutateArchive({
          variables: {
            id,
          },
          optimisticResponse: {
            archiveDocumentCategory: {
              documentCategory: {
                id,
                __typename: 'DocumentCategory',
              },
              __typename: 'MutateDocumentCategoryResponse',
            },
          },
        });
      }
    },
    [archived, mutateArchive, mutateUnarchive],
  );

  const handleSelect = useCallback((item: DocumentCategory) => () => onSelect(item), [onSelect]);

  const handleEdit = useCallback((item: DocumentCategory) => () => onEdit(item), [onEdit]);

  const { data, loading, error } = useQuery<getDocumentCategories, getDocumentCategoriesVariables>(
    GET_DOCUMENT_CATEGORIES,
    {
      variables,
    },
  );

  if (error) return <ErrorQuery error={error} />;
  if (!data) return <NotFoundPage />;

  let items = data && data.documentCategories ? data.documentCategories.items : [];
  const pages = data && data.documentCategories ? Math.ceil(data.documentCategories.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Name`),
      accessor: 'name',
    },
    {
      Header: i18n._(t`Actions`),
      width: 130,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <>
          <ButtonEdit onClick={handleEdit(cell.original)} />
          <ButtonArchive onClick={handleArchive(cell.original.id)} />

          {!archived && (
            <button type="button" className="btn btn-link btn-sm" onClick={handleSelect(cell.original)}>
              <Trans>Select</Trans>
            </button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="form-group text-right">
        <ShowArchive model={archived} onChange={setArchived} />
      </div>

      <ReactTable
        data={items}
        columns={columns}
        pages={pages}
        pageSize={limit}
        loading={loading}
        manual
        onFetchData={(state) => {
          setOffset(state.page * state.pageSize);
        }}
        onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
        {...tableOptions()}
      />
    </>
  );
};

export default DocumentationCategories;
