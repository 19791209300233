import { useQuery } from '@apollo/client';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import ReactSelectCreatable from 'react-select/creatable';
import { getUsers, getUsersVariables, getUsers_users_items } from '../../schema/types/getUsers';
import { GET_USERS } from '../../schema/user';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {
  name: string;
  isMulti?: boolean;
  valueField?: keyof getUsers_users_items;
  labelField?: keyof getUsers_users_items;
  placeholderOptions?: { value: string; label?: string }[];
};

const TicketUserForm: FunctionComponent<Props> = ({
  name,
  isMulti = true,
  valueField = 'email',
  labelField = 'email',
  placeholderOptions,
}) => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getUsers, getUsersVariables>(GET_USERS, {
    variables,
    fetchPolicy: 'cache-first',
  });

  if (!data && !loading) return <div />;

  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (values: any) => {
          form.setFieldValue(
            name,
            values ? (isMulti ? values.map((el: any) => el.value) : values.value) : isMulti ? [] : '',
          );
        };

        const handleLoad = (value: string) => search$.next(value);

        const model = field.value
          ? isMulti
            ? field.value.map((el: any) => {
                const selectedItem = (data?.users.items || []).find((item) => item[valueField] === el);
                return {
                  value: el,
                  label: selectedItem ? selectedItem[labelField] : el,
                };
              })
            : {
                value: field.value,
                label: field.value,
              }
          : isMulti
          ? []
          : '';

        const options = loading
          ? []
          : [
              ...(placeholderOptions || []),
              ...data!.users.items.map((el) => ({
                value: el[valueField],
                label: el[labelField],
              })),
            ];

        return (
          <ReactSelectCreatable
            value={model}
            options={options}
            isLoading={loading}
            isMulti={isMulti}
            cacheOptions
            defaultOptions
            onChange={handleChange}
            onInputChange={handleLoad}
            placeholder=" "
            {...selectOptions}
          />
        );
      }}
    </Field>
  );
};

export default TicketUserForm;
