import { useQuery, useMutation } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { pick } from 'lodash';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import PersonCompanyForms from '../components/company/PersonCompanyForms';
import ErrorQuery from '../components/form/ErrorQuery';
import LoadingForm from '../components/common/LoadingForm';
import { PERSON_GET_COMPANY, PERSON_UPDATE_COMPANY, validateCompanyForm } from '../schema/company';
import { personGetCompany, personGetCompanyVariables } from '../schema/types/personGetCompany';
import { updateCompany, updateCompanyVariables } from '../schema/types/updateCompany';
import { getCompanyId } from '../utils/auth';
import { toastErrorQuery } from '../utils/error';
import { useViewer } from '../utils/hooks';
import NotFoundPage from './NotFoundPage';

const keys = [
  'address1',
  'address2',
  'zip',
  'canton',
  'city',
  'country',
  'region',
  'generalPhone',
  'generalEmail',
  'website',
  'size',
  'logoId',
  'responsibleUserIds',
  'primaryPersonId',
  'persons',
  'ticketDomains',
];

const transform = (model: any) => {
  model.persons = model.persons.map((el: any) => ({
    ...el,
    personId: el.person.id,
  }));
  model.responsibleUserIds = model.responsibleUsers.map((el: any) => el.id);

  return model;
};

const PersonCompanyPage: FunctionComponent = () => {
  const me = useViewer();

  const [mutate] = useMutation<updateCompany, updateCompanyVariables>(PERSON_UPDATE_COMPANY);

  const { data, loading, error } = useQuery<personGetCompany, personGetCompanyVariables>(PERSON_GET_COMPANY, {
    variables: {
      query: getCompanyId(),
    },
  });

  if (loading) return <LoadingForm />;
  if (error) return <ErrorQuery error={error} />;
  if (!data) return <NotFoundPage />;

  if (!me.data) return <div />;

  const model = transform(cloneDeep(data.company));

  return (
    <div className="company-form-page">
      <Formik
        enableReinitialize
        initialValues={model}
        validationSchema={yup.object().shape(validateCompanyForm)}
        onSubmit={async (values, { setSubmitting, setValues }) => {
          try {
            const payload: any = pick(values, keys);
            payload.persons = payload.persons.map((el: any) => pick(el, ['personId', 'permission']));

            const result: any = await mutate({
              variables: {
                id: getCompanyId(),
                payload,
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              const model = transform(cloneDeep(result.data.updateCompany.company));

              setValues(model);
              toast.success(i18n._(t`Success!`));
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => <PersonCompanyForms loading={isSubmitting} />}
      </Formik>
    </div>
  );
};

export default PersonCompanyPage;
