import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import React, { FunctionComponent, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';
import ProductDetailForm from './ProductDetailForm';
import ProductReportingForm from './ProductReportingForm';

type Props = {
  loading?: boolean;
};

const ProductUpdateForms: FunctionComponent<Props> = ({ loading }) => {
  const [showNumber, setShowNumber] = useState(false);

  return (
    <Form>
      <div className="form-breadcrumbs-area">
        <NavLink to="/settings/product">
          <Trans>Products</Trans>
        </NavLink>

        <i className="fa fa-angle-left" />

        <Field name="id">
          {({ field }: { field: FieldInputProps<string> }) => {
            if (field.value) return <Trans>Edit product</Trans>;

            return <Trans>Add new product</Trans>;
          }}
        </Field>
      </div>

      <div className="table-actions form-table-actions">
        <div className="breadcrumbs-area">
          <Field name="title">
            {({ field }: { field: FieldInputProps<string> }) => {
              return <div className="main-title">{field.value}</div>;
            }}
          </Field>
        </div>

        <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save updates`)} loading={loading} />
      </div>

      <div className="product-form-page">
        <div className="card">
          <div className="card-header">
            <Trans>Customer View</Trans>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="d-flex justify-content-between">
                    <label>
                      <Trans>Number</Trans>
                    </label>

                    {!showNumber && (
                      <div className="value">
                        <Field name="idNumber">{({ field }: { field: any }) => <>{field.value}</>}</Field>

                        <a data-tip data-for="change" className="text-xs ml-5" onClick={() => setShowNumber(true)}>
                          <i className="fa fa-pencil-alt" />
                        </a>
                        <ReactTooltip id="change">
                          <Trans>Change</Trans>
                        </ReactTooltip>
                      </div>
                    )}
                  </div>

                  {showNumber && (
                    <>
                      <Field type="number" className="form-control" name="idNumber" />

                      <FieldErrorMessage name="idNumber" />
                    </>
                  )}
                </div>
              </div>
            </div>

            <ProductDetailForm />
          </div>
        </div>

        <div className="card pb-150">
          <div className="card-header">
            <Trans>Reporting</Trans>
          </div>
          <div className="card-body">
            <ProductReportingForm />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default ProductUpdateForms;
