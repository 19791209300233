import { cloneDeep } from 'lodash';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { pick, omit } from 'lodash';
import { Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import MainTitle from '../components/common/MainTitle';
import ButtonSubmit from '../components/form/ButtonSubmit';
import ProfileAdminUpdateForm from '../components/profile/ProfileAdminUpdateForm';
import ProfileSubscriptionForm from '../components/profile/ProfileSubscriptionForm';
import ProfileUpdateForm from '../components/profile/ProfileUpdateForm';
import { GET_VIEWER, UPDATE_PROFILE } from '../schema/auth';
import { LoginMode } from '../schema/graphql-global-types';
import { updateProfile, updateProfileVariables } from '../schema/types/updateProfile';
import { userKeys, validateUserForm } from '../schema/user';
import { toastErrorQuery } from '../utils/error';
import { useViewer } from '../utils/hooks';

type Props = {};

const ProfilePage: FunctionComponent<Props> = () => {
  const { data } = useViewer();

  const [mutate] = useMutation<updateProfile, updateProfileVariables>(UPDATE_PROFILE, {
    refetchQueries: [
      {
        query: GET_VIEWER,
      },
    ],
    awaitRefetchQueries: true,
  });

  if (!data) return <div />;

  const model = cloneDeep(data!.viewer.mode === LoginMode.User ? data!.viewer.user! : data!.viewer.person!);

  return (
    <div className="user-form-page person-detail-form">
      <Formik
        enableReinitialize
        initialValues={model}
        validationSchema={yup.object().shape(validateUserForm)}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const payload: any = pick(omit(values, ['role', 'email', 'supportDisabled']), userKeys);

            const result: any = await mutate({
              variables: {
                payload,
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              toast.success(i18n._(t`Success!`));
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <div className="table-actions form-table-actions">
                <div className="breadcrumbs-area">
                  <MainTitle title={i18n._(t`Profile`)} tips="My Profile" />
                </div>

                <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save Information`)} loading={isSubmitting} />
              </div>

              {data!.viewer.mode === LoginMode.User ? (
                <ProfileAdminUpdateForm mfa={data!.viewer.mfa} />
              ) : (
                <ProfileUpdateForm companies={data!.viewer.companies} mfa={data!.viewer.mfa} />
              )}
            </Form>
          );
        }}
      </Formik>

      {data!.viewer.mode === LoginMode.Person && <ProfileSubscriptionForm />}
    </div>
  );
};

export default ProfilePage;
