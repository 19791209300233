import gql from 'graphql-tag';
import { validateRequired } from '../utils/validations';
import { LOG } from './log';
import { TAG } from './tag';
import { OfferDetail } from './types/OfferDetail';
import { OfferItem } from './types/OfferItem';
import { OfferProductItem } from './types/OfferProductItem';
import { OfferProjectItem } from './types/OfferProjectItem';

export const OFFER = gql`
  fragment Offer on Offer {
    id
    createdAt
    company {
      id
      name
      generalPhone
    }
    fullName
    email
    title
    tags {
      ...Tag
    }
    lastLog {
      ...Log
    }
    logs(query: { limit: 20 }) {
      items {
        ...Log
      }
    }
    internalUrl
    favorite
    favoriteTextTemplate {
      id
      title
      content
      variables
    }
  }
  ${LOG}
  ${TAG}
`;

export const OFFERS = gql`
  fragment Offers on OfferCollection {
    limit
    offset
    total
    items {
      ...Offer
    }
  }
  ${OFFER}
`;

export const GET_OFFERS = gql`
  query getOffers($query: OffersQuery!) {
    offers(query: $query) {
      ...Offers
    }
  }
  ${OFFERS}
`;

export const OFFER_COMPANY = gql`
  fragment OfferCompany on Company {
    id
    primaryPerson {
      id
      fullName
      firstName
      lastName
      function
      email
    }
    name
    address1
    address2
    zip
    city
    logo {
      id
      url
    }
  }
`;

export const OFFER_PRODUCT_ITEM = gql`
  fragment OfferProductItem on OfferItemProductItem {
    product {
      id
      title
      price
    }
    title
    price
    pieces
    discountPercent
    productId @client
  }
`;

export const ExtendOfferItemProductItem = {
  productId: (obj: OfferProductItem) => obj.product.id,
};

export const OFFER_PROJECT_ITEM = gql`
  fragment OfferProjectItem on OfferItemProjectItem {
    user {
      id
      fullName
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
    description
    startDate
    endDate
    status
    userId @client
  }
`;

export const ExtendOfferItemProjectItem = {
  userId: (obj: OfferProjectItem) => obj.user.id,
};

export const OFFER_ITEM = gql`
  fragment OfferItem on OfferItem {
    type
    index
    title
    content
    productItems {
      ...OfferProductItem
    }
    projectItems {
      ...OfferProjectItem
    }
    discountPercent
    templateElement {
      id
      content
    }
    templateElementId @client
  }
  ${OFFER_PRODUCT_ITEM}
  ${OFFER_PROJECT_ITEM}
`;

export const OFFER_DETAIL = gql`
  fragment OfferDetail on Offer {
    id
    company {
      ...OfferCompany
    }
    title
    firstName
    lastName
    function
    email
    responsibleUsers {
      id
      fullName
      function
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
    favoriteTextTemplate {
      id
      title
      content
      variables
    }
    tags {
      ...Tag
    }
    publicUrl
    internalUrl
    template
    headGraphic {
      id
      url
    }
    items {
      ...OfferItem
    }
    template
    index
    createdAt
    versions(query: { limit: 20, orderBy: id, orderDirection: Desc }) {
      items {
        id
        title
        publicUrl
        createdAt
      }
    }
    tagNames @client
    companyId @client
    responsibleUserIds @client
    headGraphicId @client
  }
  ${TAG}
  ${OFFER_COMPANY}
  ${OFFER_ITEM}
`;

export const ExtendOffer = {
  tagNames: (obj: OfferDetail) => obj.tags.map((el) => el.name),
  companyId: (obj: OfferDetail) => (obj.company ? obj.company.id : null),
  responsibleUserIds: (obj: OfferDetail) => obj.responsibleUsers.map((el) => el.id),
  headGraphicId: (obj: OfferDetail) => (obj.headGraphic ? obj.headGraphic.id : null),
  favoriteTextTemplateId: (obj: OfferDetail) => (obj.favoriteTextTemplate ? obj.favoriteTextTemplate.id : null),
};

export const ExtendOfferItem = {
  templateElementId: (obj: OfferItem) => (obj.templateElement ? obj.templateElement.id : null),
};

export const OFFERS_WITH_DETAIL = gql`
  fragment OffersDetail on OfferCollection {
    limit
    offset
    total
    items {
      ...OfferDetail
    }
  }
  ${OFFER_DETAIL}
`;

export const GET_OFFERS_WITH_DETAIL = gql`
  query getOffersWithDetail($query: OffersQuery!) {
    offers(query: $query) {
      ...OffersDetail
    }
  }
  ${OFFERS_WITH_DETAIL}
`;

export const GET_OFFER = gql`
  query getOffer($query: ID!) {
    offer(id: $query) {
      ...OfferDetail
    }
  }
  ${OFFER_DETAIL}
`;

export const GET_OFFER_BY_TOKEN = gql`
  query getOfferByToken($token: String!) {
    offerByToken(token: $token) {
      id
      fullName
      title
    }
  }
`;

export const OFFER_FOR_CONTRACT = gql`
  fragment OfferContract on Offer {
    id
    company {
      id
    }
    title
    items {
      productItems {
        product {
          id
          billing
          title
          reports {
            product {
              id
            }
            contractType {
              id
            }
            pieces
          }
        }
        title
        pieces
        price
        discountPercent
      }
    }
  }
`;

export const GET_OFFER_FOR_CONTRACT = gql`
  query getOfferForContract($query: ID!) {
    offer(id: $query) {
      ...OfferContract
    }
  }
  ${OFFER_FOR_CONTRACT}
`;

export const CREATE_OFFER = gql`
  mutation createOffer($payload: CreateOfferPayload!) {
    createOffer(payload: $payload) {
      offer {
        ...OfferDetail
      }
    }
  }
  ${OFFER_DETAIL}
`;

export const UPDATE_OFFER = gql`
  mutation updateOffer($id: ID!, $payload: UpdateOfferPayload!) {
    updateOffer(id: $id, payload: $payload) {
      offer {
        ...OfferDetail
      }
    }
  }
  ${OFFER_DETAIL}
`;

export const FAVORITE_OFFER = gql`
  mutation favoriteOffer($id: ID!, $payload: UpdateOfferPayload!) {
    updateOffer(id: $id, payload: $payload) {
      offer {
        id
      }
    }
  }
`;

export const ARCHIVE_OFFER = gql`
  mutation archiveOffer($id: ID!) {
    archiveOffer(id: $id) {
      offer {
        id
      }
    }
  }
`;

export const UNARCHIVE_OFFER = gql`
  mutation unarchiveOffer($id: ID!) {
    unarchiveOffer(id: $id) {
      offer {
        id
      }
    }
  }
`;

export const SEND_OFFER_EMAIL = gql`
  mutation sendOfferEmail($id: ID!, $payload: SendOfferEmailPayload!) {
    sendOfferEmail(id: $id, payload: $payload)
  }
`;

export const CREATE_OFFER_LOG = gql`
  mutation createOfferLog($id: ID!, $payload: CreateOfferLogPayload!) {
    createOfferLog(id: $id, payload: $payload)
  }
`;

export const RECOVER_OFFER = gql`
  mutation recoverOffer($id: ID!, $versionId: ID!) {
    recoverOffer(id: $id, versionId: $versionId) {
      offer {
        ...OfferDetail
      }
    }
  }
  ${OFFER_DETAIL}
`;

export const ACCEPT_OFFER = gql`
  mutation acceptOffer($token: String!, $signatureObjectId: ID!, $signatureFullname: String) {
    acceptOffer(token: $token, signatureObjectId: $signatureObjectId, signatureFullname: $signatureFullname)
  }
`;

export const offerKeys = [
  'title',
  'companyId',
  'firstName',
  'lastName',
  'function',
  'email',
  'headGraphicId',
  'responsibleUserIds',
  'tagNames',
  'items',
  'favoriteTextTemplateId',
  'template',
  'index',
  'favorite',
];

export const validateOfferForm = {
  title: validateRequired,
};
