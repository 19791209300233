import { cloneDeep } from 'lodash';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { CompanyPerson } from '../../schema/types/CompanyPerson';
import PersonCompanyPersonFormItem from './PersonCompanyPersonFormItem';

type Props = {};

const PersonCompanyPersonForm: FunctionComponent<Props> = () => {
  return (
    <Field name="persons">
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleRemove = (index: number) => () => {
          const model = cloneDeep(field.value);
          model.splice(index, 1);
          form.setFieldValue('persons', model);
        };

        const handlePrimary = (id: any) => () => {
          form.setFieldValue('primaryPersonId', id);
          form.setFieldValue('primaryPerson', { id });
        };

        return (
          <div className="company-person-form">
            <div className="form-group items">
              {field.value.map((el: CompanyPerson, index: number) => (
                <PersonCompanyPersonFormItem
                  key={el.person.id}
                  index={index}
                  primary={form.values.primaryPerson && form.values.primaryPerson.id === el.person.id}
                  model={el}
                  onPrimary={handlePrimary(el.person.id)}
                  onRemove={handleRemove(index)}
                />
              ))}
            </div>
          </div>
        );
      }}
    </Field>
  );
};

export default PersonCompanyPersonForm;
