import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { pick } from 'lodash';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import AssetCreateForms from '../components/asset/AssetCreateForms';
import { assetKeys, CREATE_ASSET, validateAssetForm } from '../schema/asset';
import { CreateAssetPayload } from '../schema/graphql-global-types';
import { createAsset, createAssetVariables } from '../schema/types/createAsset';
import { DefaultRouteParams } from '../utils/const';
import { toastErrorQuery } from '../utils/error';

const ServicesAssetCreatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ history }) => {
  const [mutate] = useMutation<createAsset, createAssetVariables>(CREATE_ASSET);

  const initialValues: CreateAssetPayload & {
    responsibleUsers: [];
    customerResponsiblePersons: [];
    monitorConnected: boolean;
  } = {
    name: '',
    companyId: '',
    productId: '',
    description: '',
    uptimeSla: 0,
    playbook: '',
    assetLocationName: '',
    assetTypeName: '',
    tagNames: [],
    responsibleUsers: [],
    responsibleUserIds: [],
    customerResponsiblePersons: [],
    customerResponsiblePersonIds: [],
    monitor: null,
    manualLog: '',
    manualLogViewableByCustomer: false,
    manualLogTagNames: [],
    reminders: [],
    attachmentIds: [],
    displayOnDashboard: false,
    displayOnCustomer: false,
    disconnectMonitor: false,
    monitorConnected: false,
  };

  return (
    <div className="asset-form-page">
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validateAssetForm)}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const payload: any = pick(values, assetKeys);
            payload.uptimeSla = Number(payload.uptimeSla);

            const result: any = await mutate({
              variables: {
                payload,
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              toast.success(i18n._(t`Success!`));
              history.push(`/service/asset/${result.data.createAsset.asset.id}`);
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => <AssetCreateForms loading={isSubmitting} />}
      </Formik>
    </div>
  );
};

export default withRouter(ServicesAssetCreatePage);
