import { useQuery } from '@apollo/client';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { GET_EXTERNAL_COMPANIES } from '../../schema/company';
import { ExternalCompany } from '../../schema/types/ExternalCompany';
import { getExternalCompanies, getExternalCompaniesVariables } from '../../schema/types/getExternalCompanies';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <div
        dangerouslySetInnerHTML={{
          __html: props.children,
        }}
      />
    </components.Option>
  );
};

type Props = {};

const CompanyExternalForm: FunctionComponent<Props> = () => {
  const { search$, search } = useVariables();

  const variables = {
    query: search,
  };

  const { data, loading } = useQuery<getExternalCompanies, getExternalCompaniesVariables>(GET_EXTERNAL_COMPANIES, {
    variables,
  });

  if (!data && !loading) return <div />;

  return (
    <Field name="name">
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (value: any) => {
          const model: ExternalCompany = value.value;

          if (!model.name) {
            form.setFieldValue('name', model || '');
          } else {
            form.setFieldValue('address1', model.address1 || '');
            form.setFieldValue('address2', model.address2 || '');
            form.setFieldValue('zip', model.zip || '');
            form.setFieldValue('canton', model.canton || '');
            form.setFieldValue('city', model.city || '');
            form.setFieldValue('generalEmail', model.generalEmail || '');
            form.setFieldValue('generalPhone', model.generalPhone || '');
            form.setFieldValue('name', model.name || '');
          }
        };

        const handleLoad = (value: string) => search$.next(value);

        const model = {
          value: field.value,
          label: field.value,
        };

        const options = loading
          ? []
          : data!.searchExternalCompany.map((el) => ({
              value: el,
              label: `<strong>${el.name}</strong><br />${el.address1} ${el.city} ${el.canton} ${el.zip}<br />${el.generalPhone}<br />${el.generalEmail}`,
            }));

        return (
          <CreatableSelect
            value={model}
            options={options}
            isLoading={loading}
            onChange={handleChange}
            onInputChange={handleLoad}
            placeholder=""
            {...selectOptions}
            components={{
              ClearIndicator: null,
              IndicatorSeparator: null,
              DropdownIndicator: null,
              Option,
            }}
            autoFocus
          />
        );
      }}
    </Field>
  );
};

export default CompanyExternalForm;
