import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { GET_TWITTER_CONFIG, UPDATE_TWITTER_CONFIG } from '../../schema/config';
import { TwitterConfigPayload } from '../../schema/graphql-global-types';
import { getTwitterConfig } from '../../schema/types/getTwitterConfig';
import { updateTwitterConfig, updateTwitterConfigVariables } from '../../schema/types/updateTwitterConfig';
import { toastErrorQuery } from '../../utils/error';
import { validateRequired } from '../../utils/validations';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';
import { merge, omit } from 'lodash';

type Props = {
  show: boolean;
  onClose: () => void;
};

const ConfigurationTwitterModal: FunctionComponent<Props> = ({ show, onClose }) => {
  const { data } = useQuery<getTwitterConfig>(GET_TWITTER_CONFIG);

  const [mutate] = useMutation<updateTwitterConfig, updateTwitterConfigVariables>(UPDATE_TWITTER_CONFIG);

  const model: TwitterConfigPayload = merge(
    {
      consumerKey: '',
      consumerSecret: '',
      tokenKey: '',
      tokenSecret: '',
    },
    data && data.twitterConfig,
  );

  return (
    <>
      <div className="info">
        {data?.twitterConfig?.tokenKey && data.twitterConfig.tokenKey.length > 0 && (
          <>
            <div className="column">
              <label className="form-label">
                <Trans>Token Key</Trans>
              </label>

              <div className="value">{data.twitterConfig.tokenKey}</div>
            </div>

            <div className="column">
              <label className="form-label">
                <Trans>Token Secret</Trans>
              </label>

              <div className="value">—</div>
            </div>
          </>
        )}

        {data?.twitterConfig?.consumerKey && data.twitterConfig.consumerKey.length > 0 && (
          <>
            <div className="column">
              <label className="form-label">
                <Trans>Consumer Key</Trans>
              </label>

              <div className="value">{data.twitterConfig.consumerKey}</div>
            </div>

            <div className="column">
              <label className="form-label">
                <Trans>Consumer Secret</Trans>
              </label>

              <div className="value">—</div>
            </div>
          </>
        )}
      </div>

      <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
        <div className="modal fade show" style={{ display: 'block' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title">
                  <Trans>Twitter Authorization</Trans>
                </h2>
                <button type="button" className="close" aria-label="Close" onClick={onClose}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <Formik
                initialValues={model}
                validationSchema={yup.object().shape({
                  consumerKey: validateRequired,
                  consumerSecret: validateRequired,
                  tokenKey: validateRequired,
                  tokenSecret: validateRequired,
                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  try {
                    const config: any = omit(values, ['__typename']);

                    const result: any = await mutate({
                      variables: {
                        config,
                      },
                    });

                    if (result.errors) {
                      toastErrorQuery(result.errors);
                    } else {
                      toast.success(i18n._(t`Success!`));
                      resetForm();
                      onClose();
                    }
                  } catch (e) {
                    toastErrorQuery(e);
                  }

                  setSubmitting(false);
                }}
              >
                {({ isSubmitting, submitForm }) => {
                  const handleSubmit = (e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    submitForm();
                  };

                  return (
                    <Form onSubmitCapture={handleSubmit}>
                      <div className="modal-body">
                        <div className="form-group">
                          <label>
                            <Trans>Token Key</Trans>
                          </label>
                          <Field type="text" className="form-control" name="tokenKey" autoFocus />

                          <FieldErrorMessage name="tokenKey" />
                        </div>

                        <div className="form-group">
                          <label>
                            <Trans>Token Secret</Trans>
                          </label>
                          <Field type="text" className="form-control" name="tokenSecret" />

                          <FieldErrorMessage name="tokenSecret" />
                        </div>

                        <div className="form-group">
                          <label>
                            <Trans>Consumer Key</Trans>
                          </label>
                          <Field type="text" className="form-control" name="consumerKey" />

                          <FieldErrorMessage name="consumerKey" />
                        </div>

                        <div className="form-group">
                          <label>
                            <Trans>Consumer Secret</Trans>
                          </label>
                          <Field type="text" className="form-control" name="consumerSecret" />

                          <FieldErrorMessage name="consumerSecret" />
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button type="button" className="btn btn-link" onClick={onClose}>
                          <Trans>Close</Trans>
                        </button>

                        <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConfigurationTwitterModal;
