import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import TextTemplateCreateForms from '../components/textTemplate/TextTemplateCreateForms';
import { CreateTextTemplatePayload, TextTemplateContentType } from '../schema/graphql-global-types';
import { CREATE_TEXT_TEMPLATE, GET_TEXT_TEMPLATES, validateTextTemplateForm } from '../schema/textTemplate';
import { createTextTemplate, createTextTemplateVariables } from '../schema/types/createTextTemplate';
import { toastErrorQuery } from '../utils/error';
import { useVariables } from '../utils/hooks';

type Props = {};

const TextTemplateCreatePage: FunctionComponent<Props> = () => {
  const { search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const [mutate] = useMutation<createTextTemplate, createTextTemplateVariables>(CREATE_TEXT_TEMPLATE, {
    refetchQueries: [
      {
        query: GET_TEXT_TEMPLATES,
        variables,
      },
    ],
  });

  const initialValues: CreateTextTemplatePayload = {
    systemName: '',
    title: '',
    content: '',
    contentType: TextTemplateContentType.Text,
    to: [],
    cc: [],
    bcc: [],
    messageCenterTo: [],
    public: true,
  };

  return (
    <div className="closed-page">
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validateTextTemplateForm)}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const result: any = await mutate({
              variables: {
                payload: values,
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              toast.success(i18n._(t`Success!`));
              resetForm();
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => <TextTemplateCreateForms loading={isSubmitting} />}
      </Formik>
    </div>
  );
};

export default TextTemplateCreatePage;
