import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field } from 'formik';
import React, { FunctionComponent, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Select from 'react-select';
import { GET_PRODUCTS } from '../../schema/product';
import { getProducts, getProductsVariables } from '../../schema/types/getProducts';
import { OfferItem } from '../../schema/types/OfferItem';
import { OfferProductItem } from '../../schema/types/OfferProductItem';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';
import CurrencyDisplay from '../common/CurrencyDisplay';
import NumberDisplay from '../common/NumberDisplay';
import ButtonDelete from '../form/ButtonDelete';

const getRowTotal = (item: OfferProductItem) => item.price * item.pieces * (1 - item.discountPercent / 100);

const getSubTotal = (items: OfferProductItem[]) => items.reduce((acc, curr) => acc + getRowTotal(curr), 0);

const getTotal = (item: OfferItem) => getSubTotal(item.productItems!) * (1 - item.discountPercent! / 100);

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  display: isDragging ? 'table' : undefined,
  ...draggableStyle,
});

type Props = {
  name: string;
  model: OfferItem;
  onAdd: (result: any) => void;
  onSort: (result: any) => void;
  onRemove: (index: number) => () => void;
};

const OfferEditorProduct: FunctionComponent<Props> = ({ name, model, onAdd, onSort, onRemove }) => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getProducts, getProductsVariables>(GET_PRODUCTS, {
    variables,
  });

  const handleLoad = useCallback((value: string) => search$.next(value), [search$]);

  const productOptions = loading
    ? []
    : data!.products.items.map((el: any) => ({
        value: el,
        label: el.title,
      }));

  return (
    <div className="document-product-table table-responsive">
      <div className="history-item form-group">
        <label>
          <Trans>Product</Trans>
        </label>

        <Select
          options={productOptions}
          isLoading={loading}
          onChange={onAdd}
          onInputChange={handleLoad}
          placeholder={i18n._(t`Add Product`)}
          {...selectOptions}
        />
      </div>

      <table className="custom-table">
        <thead className="custom-table-header">
          <tr>
            <th className="column title">
              <Trans>Product</Trans>
            </th>
            <th className="column number">
              <Trans>Price</Trans>
            </th>
            <th className="column number">
              <Trans>Pieces</Trans>
            </th>
            <th className="column number">
              <Trans>Discount %</Trans>
            </th>
            <th className="column total">
              <Trans>Total</Trans>
            </th>
            <th className="column action" />
            <th className="column handle" />
          </tr>
        </thead>

        <DragDropContext onDragEnd={onSort}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {model.productItems!.map((item: OfferProductItem, index: number) => {
                  const total = getRowTotal(item);

                  return (
                    <Draggable key={index} draggableId={String(index)} index={index}>
                      {(provided, snapshot) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          className="custom-table-body"
                        >
                          <td className="column title">
                            <Field
                              type="text"
                              className="form-control"
                              name={`${name}.productItems[${index}].title`}
                              placeholder={i18n._(t`Title`)}
                            />
                          </td>
                          <td className="column number">
                            <Field
                              type="text"
                              className="form-control"
                              name={`${name}.productItems[${index}].price`}
                              placeholder={i18n._(t`Price`)}
                            />
                          </td>
                          <td className="column number">
                            <Field
                              type="number"
                              className="form-control"
                              name={`${name}.productItems[${index}].pieces`}
                              placeholder={i18n._(t`Pieces`)}
                            />
                          </td>
                          <td className="column number">
                            <Field
                              type="text"
                              className="form-control"
                              name={`${name}.productItems[${index}].discountPercent`}
                              placeholder={i18n._(t`Discount Percent`)}
                            />
                          </td>
                          <td className="column total">
                            <NumberDisplay value={total} />
                          </td>
                          <td className="column action">
                            <ButtonDelete onClick={onRemove(index)} />
                          </td>
                          <td className="column handle" {...provided.dragHandleProps}>
                            <i className="icon-drag-and-drop" />
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>

      <table className="table text-right mt-3">
        <tbody>
          <tr>
            <td>
              <Trans>Subtotal</Trans>
            </td>
            <td className="total text-left">
              <CurrencyDisplay value={getSubTotal(model.productItems!)} />
            </td>
          </tr>
          <tr>
            <td>
              <Trans>Discount %</Trans>
            </td>
            <td className="total text-left">
              <Field
                type="text"
                className="form-control"
                name={`${name}.discountPercent`}
                placeholder={i18n._(t`Discount %`)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <strong>
                <Trans>TOTAL</Trans>
              </strong>
            </td>
            <td className="total text-left">
              <strong>
                <CurrencyDisplay value={getTotal(model)} />
              </strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OfferEditorProduct;
