import React, { FunctionComponent } from 'react';
import { useCurrency } from '../../utils/hooks';
import NumberFormat from './NumberFormat';

type Props = {
  value: number;
};

const CurrencyDisplay: FunctionComponent<Props> = ({ value }) => {
  const { data } = useCurrency();

  return (
    <NumberFormat
      value={value}
      format={{
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: data?.currencyConfig.currency || 'CHF',
        style: 'currency',
      }}
    />
  );
};

export default CurrencyDisplay;
