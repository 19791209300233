import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { pick } from 'lodash';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import Modal from 'react-modal';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import slug from 'slug';
import * as yup from 'yup';
import { CREATE_DOCUMENT, documentKeys, validateDocumentForm } from '../../schema/document';
import { CreateDocumentPayload, DocumentVisibility } from '../../schema/graphql-global-types';
import { createDocument, createDocumentVariables } from '../../schema/types/createDocument';
import { DocumentCategory } from '../../schema/types/DocumentCategory';
import { DefaultRouteParams } from '../../utils/const';
import { toastErrorQuery } from '../../utils/error';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';
import DocumentationCategoryForm from './DocumentationCategoryForm';
import DocumentationCategoryModal from './DocumentationCategoryModal';
import DocumentationTagForm from './DocumentationTagForm';

type Props = {
  show: boolean;
  onClose: () => void;
};

const DocumentationTemplateCreateModal: FunctionComponent<Props & RouteComponentProps<DefaultRouteParams>> = ({
  history,
  show,
  onClose,
}) => {
  const [showCategory, setShowCategory] = useState(false);

  const toggleCategoryModal = useCallback(() => setShowCategory((prevState) => !prevState), [setShowCategory]);

  const [mutate] = useMutation<createDocument, createDocumentVariables>(CREATE_DOCUMENT);

  const initialValues: CreateDocumentPayload & { attachments: [] } = {
    title: '',
    categoryId: '',
    template: true,
    visibility: DocumentVisibility.Internal,
    permanentLink: '',
    headGraphicId: null,
    elements: [],
    tagNames: [],
    customerIds: [],
    attachmentIds: [],
    attachments: [],
    index: false,
  };

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Create template</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={yup.object().shape(validateDocumentForm)}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const payload: any = pick(values, documentKeys);
                  payload.permanentLink = slug(payload.title).toLowerCase();

                  const result: any = await mutate({
                    variables: {
                      payload,
                    },
                  });

                  if (result.errors) {
                    toastErrorQuery(result.errors);
                  } else {
                    toast.success(i18n._(t`Success!`));
                    onClose();
                    history.push(`/documentation/edit/${result.data.createDocument.document.id}`);
                  }
                } catch (e) {
                  toastErrorQuery(e);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="modal-body">
                    <div className="form-group">
                      <label>
                        <Trans>Title</Trans>
                      </label>
                      <Field type="text" className="form-control" name="title" autoFocus />

                      <FieldErrorMessage name="title" />
                    </div>

                    <div className="form-group">
                      <label>
                        <Trans>Category</Trans>
                      </label>

                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <DocumentationCategoryForm />
                        </div>

                        <span data-tip data-for="category" className="button ml-2" onClick={toggleCategoryModal}>
                          <i className="icon-edit" />
                        </span>
                        <ReactTooltip id="category">
                          <Trans>Manage Categories</Trans>
                        </ReactTooltip>
                      </div>

                      <FieldErrorMessage name="categoryId" />
                    </div>

                    <Field name="category">
                      {({ form }: { form: FormikProps<any> }) => {
                        const handleSelect = (item: DocumentCategory) => {
                          form.setFieldValue('categoryId', item.id);
                          form.setFieldValue('category', {
                            id: item.id,
                            name: item.name,
                          });
                          toggleCategoryModal();
                        };

                        return (
                          <DocumentationCategoryModal
                            show={showCategory}
                            onSelect={handleSelect}
                            onClose={toggleCategoryModal}
                          />
                        );
                      }}
                    </Field>

                    <div className="form-group">
                      <div className="d-flex justify-content-between">
                        <label className="form-label">
                          <Trans>Select template</Trans>
                        </label>

                        <label className="form-label">
                          <Trans>Optional</Trans>
                        </label>
                      </div>
                      <DocumentationTagForm />
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button type="button" className="btn btn-link" onClick={onClose}>
                      <Trans>Close</Trans>
                    </button>

                    <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withRouter(DocumentationTemplateCreateModal);
