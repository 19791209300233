import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback } from 'react';
import ReactTable, { CellInfo } from 'react-table';
import { NavLink } from 'react-router-dom';
import ButtonEdit from '../../components/form/ButtonEdit';
import { GET_DOCUMENTS_FOR_TICKET } from '../../schema/document';
import { DocumentDetail } from '../../schema/types/DocumentDetail';
import { getDocuments, getDocumentsVariables } from '../../schema/types/getDocuments';
import { tableOptions } from '../../utils/const';
import ErrorQuery from '../../components/form/ErrorQuery';
import { useVariables } from '../../utils/hooks';
import ShowArchive from '../common/ShowArchive';

type Props = {
  onSelect: (item: DocumentDetail) => void;
};

const TicketDocumentations: FunctionComponent<Props> = ({ onSelect }) => {
  const {
    search$,
    search,
    filter,
    archived,
    setArchived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    orderDirection,
  } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
        template: false,
      },
    },
  };

  const handleSelect = useCallback((item: DocumentDetail) => () => onSelect(item), [onSelect]);

  const { data, loading, error } = useQuery<getDocuments, getDocumentsVariables>(GET_DOCUMENTS_FOR_TICKET, {
    variables,
    fetchPolicy: 'cache-first',
  });

  if (!data && !loading) return <div />;
  if (error) return <ErrorQuery error={error} />;

  let items = data && data.documents ? data.documents.items : [];
  const pages = data && data.documents ? Math.ceil(data.documents.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Title`),
      accessor: 'title',
      Cell: (cell: CellInfo) => (
        <a onClick={handleSelect(cell.original)} className="text-info">
          {cell.value}
        </a>
      ),
    },
    {
      Header: i18n._(t`Actions`),
      width: 120,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <>
          <NavLink to={`/settings/text-template/${cell.original.id}`} target="_blank">
            <ButtonEdit />
          </NavLink>

          {!archived && (
            <button type="button" className="btn btn-link btn-sm" onClick={handleSelect(cell.original)}>
              <Trans>Select</Trans>
            </button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="form-group input-group">
        <div className="input-group-prepend">
          <div className="input-group-text">
            <i className="icon-search" />
          </div>
        </div>
        <input
          className="form-control"
          placeholder={i18n._(t`Search`)}
          onInput={(e: any) => search$.next(e.target.value)}
        />
      </div>

      <div className="form-group text-right">
        <ShowArchive model={archived} onChange={setArchived} />
      </div>

      <ReactTable
        data={items}
        columns={columns}
        pages={pages}
        pageSize={limit}
        loading={loading}
        manual
        onFetchData={(state) => {
          setOffset(state.page * state.pageSize);
        }}
        onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
        {...tableOptions()}
      />
    </>
  );
};

export default TicketDocumentations;
