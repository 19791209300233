import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import { uniq } from 'lodash';
import React, { FunctionComponent, useMemo } from 'react';
import { GET_OUTLOOK_TICKET_ACCOUNT } from '../../schema/config';
import { getOutlookTicketAccount } from '../../schema/types/getOutlookTicketAccount';
import { Log } from '../../schema/types/Log';
import FieldEditor from '../form/FieldEditor';
import FieldErrorMessage from '../form/FieldErrorMessage';
import MultipleFileUploader from '../form/MultipleFileUploader';
import TicketChatGPTForm from './TicketChatGPTForm';
import TicketPersonForm from './TicketPersonForm';

type Props = {
  logs?: Log[];
  isFromMsTeams?: boolean | null;
  chatGPT?: boolean;
};

const TicketMainForm: FunctionComponent<Props> = ({ logs, isFromMsTeams, chatGPT }) => {
  const outlookTicketAccount = useQuery<getOutlookTicketAccount>(GET_OUTLOOK_TICKET_ACCOUNT);
  const exceptEmail = useMemo(
    () =>
      (outlookTicketAccount.data &&
        outlookTicketAccount.data.outlookTicketAccount &&
        outlookTicketAccount.data.outlookTicketAccount.email) ||
      null,
    [outlookTicketAccount],
  );

  return (
    <div className="ticket-form">
      <div className="form-group row">
        <label className="col-form-label label">
          <Trans>TO:</Trans>
        </label>
        <div className="col">
          <TicketPersonForm name="to" disabled={!!isFromMsTeams} />

          <FieldErrorMessage name="to" />
        </div>
      </div>

      {!isFromMsTeams && (
        <div className="form-group row">
          <Field name="cc">
            {({ form }: { form: FormikProps<any> }) => {
              const handleClick = () => {
                const re = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]{2,})/gi;
                const found = logs ? uniq(logs[0].content.match(re)).slice(1) : [];
                form.setFieldValue(
                  'cc',
                  found.filter((el) => el !== exceptEmail),
                );
              };

              if (!logs || !logs.length)
                return (
                  <label className="col-form-label label">
                    <Trans>CC:</Trans>
                  </label>
                );

              return (
                <a className="col-form-label label text-primary" onClick={handleClick}>
                  <Trans>CC:</Trans>
                </a>
              );
            }}
          </Field>

          <div className="col">
            <TicketPersonForm name="cc" />
          </div>
        </div>
      )}

      <div className="form-group">
        <label>
          <Trans>Subject</Trans>
        </label>

        <Field type="text" className="form-control" name="subject" disabled={isFromMsTeams} />

        <FieldErrorMessage name="subject" />
      </div>

      <div className="form-group">
        <FieldEditor name="content" />

        <FieldErrorMessage name="content" />

        {chatGPT && <TicketChatGPTForm />}
      </div>

      <div className="form-group files d-flex flex-wrap flex-grow-1 text-center">
        <Field name="attachments">
          {({ field, form }: { field: any; form: FormikProps<any> }) => {
            const handleUpload = (value: any) => {
              const photoIds = form.values.attachmentIds;
              photoIds.push(value.id);
              form.setFieldValue('attachmentIds', photoIds);

              const photos = form.values.attachments;
              photos.push(value);
              form.setFieldValue('attachments', photos);
            };

            const handleRemove = (index: number) => {
              const photoIds = form.values.attachmentIds;
              photoIds.splice(index, 1);
              form.setFieldValue('attachmentIds', photoIds);

              if (form.values.attachments && form.values.attachments.length >= index) {
                const photos = form.values.attachments;
                photos.splice(index, 1);
                form.setFieldValue('attachments', photos);
              }
            };

            return <MultipleFileUploader onUpload={handleUpload} onRemove={handleRemove} files={field.value} />;
          }}
        </Field>
      </div>
    </div>
  );
};

export default TicketMainForm;
