import { ApolloError } from '@apollo/client/core';
import React, { FunctionComponent } from 'react';

type Props = {
  error: ApolloError;
};

const ErrorQuery: FunctionComponent<Props> = ({ error }) => {
  return (
    <div className="text-center text-danger">
      {error.graphQLErrors && error.graphQLErrors.length ? (
        error.graphQLErrors.map(({ message }, i) => <p key={i}>{message}</p>)
      ) : (
        <>
          <p>SERVER ERROR</p>
        </>
      )}
    </div>
  );
};

export default ErrorQuery;
