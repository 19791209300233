import gql from 'graphql-tag';
import { validateRequired } from '../utils/validations';

export const MESSAGE_CATEGORY = gql`
  fragment MessageCategory on MessageCategory {
    id
    name
    default
  }
`;

export const MESSAGE_CATEGORIES = gql`
  fragment MessageCategories on MessageCategoryCollection {
    limit
    offset
    total
    items {
      ...MessageCategory
    }
  }
  ${MESSAGE_CATEGORY}
`;

export const GET_MESSAGE_CATEGORIES = gql`
  query getMessageCategories($query: MessageCategoriesQuery!) {
    messageCategories(query: $query) {
      ...MessageCategories
    }
  }
  ${MESSAGE_CATEGORIES}
`;

export const MESSAGE_CATEGORY_DETAIL = gql`
  fragment MessageCategoryDetail on MessageCategory {
    ...MessageCategory
  }
  ${MESSAGE_CATEGORY}
`;

export const GET_MESSAGE_CATEGORY = gql`
  query getMessageCategory($query: ID!) {
    messageCategory(id: $query) {
      ...MessageCategoryDetail
    }
  }
  ${MESSAGE_CATEGORY_DETAIL}
`;

export const CREATE_MESSAGE_CATEGORY = gql`
  mutation createMessageCategory($payload: CreateMessageCategoryPayload!) {
    createMessageCategory(payload: $payload) {
      messageCategory {
        ...MessageCategoryDetail
      }
    }
  }
  ${MESSAGE_CATEGORY_DETAIL}
`;

export const UPDATE_MESSAGE_CATEGORY = gql`
  mutation updateMessageCategory(
    $id: ID!
    $payload: UpdateMessageCategoryPayload!
  ) {
    updateMessageCategory(id: $id, payload: $payload) {
      messageCategory {
        ...MessageCategoryDetail
      }
    }
  }
  ${MESSAGE_CATEGORY_DETAIL}
`;

export const ARCHIVE_MESSAGE_CATEGORY = gql`
  mutation archiveMessageCategory($id: ID!) {
    archiveMessageCategory(id: $id) {
      messageCategory {
        id
      }
    }
  }
`;

export const UNARCHIVE_MESSAGE_CATEGORY = gql`
  mutation unarchiveMessageCategory($id: ID!) {
    unarchiveMessageCategory(id: $id) {
      messageCategory {
        id
      }
    }
  }
`;

export const SET_DEFAULT_MESSAGE_CATEGORY = gql`
  mutation setDefaultMessageCategory($id: ID!) {
    setDefaultMessageCategory(id: $id)
  }
`;

export const validateMessageCategoryForm = {
  name: validateRequired,
};
