import { useMutation, useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import React, { FunctionComponent, useCallback, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import OfferCreateModal from '../components/offer/OfferCreateModal';
import logoSmall from '../img/icon_homer_collapse_40x40.svg';
import { LoginMode } from '../schema/graphql-global-types';
import { GET_SIDEBAR_STATE, TOGGLE_DARK_MODE, TOGGLE_SIDEBAR } from '../schema/sidebar';
import { sidebarState } from '../schema/types/sidebarState';
import { useViewer } from '../utils/hooks';
import { getStorage, removeStorage } from '../utils/storage';
import HeaderNotifications from './layout/HeaderNotifications';
import HeaderProfile from './layout/HeaderProfile';
import Searchbar from './Searchbar';
import SendSMSModal from './sms/SendSMSModal';
import TicketAddModal from './ticket/TicketAddModal';
import TimeTrackerCreateEntryModal from './timeTracker/TimeTrackerCreateEntryModal';

type Props = {};

const Topbar: FunctionComponent<Props & RouteComponentProps> = ({ history }) => {
  const { data } = useViewer();

  const sidebar = useQuery<sidebarState>(GET_SIDEBAR_STATE);

  const [showAddOffer, setShowAddOffer] = useState(false);
  const [showAddTicket, setShowAddTicket] = useState(false);
  const [showSendSMS, setShowSendSMS] = useState(false);
  const [showAddTime, setShowAddTime] = useState(false);

  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = useCallback(() => setShowMore((prevState) => !prevState), []);

  const toggleAddOffer = useCallback(() => {
    setShowAddOffer((prevState) => !prevState);
  }, [setShowAddOffer]);

  const toggleAddTicket = useCallback(() => {
    setShowAddTicket((prevState) => !prevState);
  }, [setShowAddTicket]);

  const toggleSendSMS = useCallback(() => {
    setShowSendSMS((prevState) => !prevState);
  }, [setShowSendSMS]);

  const toggleAddTime = useCallback(() => {
    setShowAddTime((prevState) => !prevState);
  }, [setShowAddTime]);

  const [toggleSidebar] = useMutation(TOGGLE_SIDEBAR);
  const handleSidebar = useCallback(() => toggleSidebar(), [toggleSidebar]);

  const [toggleDarkMode] = useMutation(TOGGLE_DARK_MODE);
  const handleDarkMode = useCallback(() => toggleDarkMode(), [toggleDarkMode]);

  const handleAddTicketSuccess = useCallback(() => {
    toggleAddTicket();
    history.push('/support/ticket');
  }, [history, toggleAddTicket]);

  const handleExit = useCallback(() => {
    removeStorage('companyId');
    removeStorage('viewAs');
    window.location.href = '/';
  }, []);

  if (!data) return <div />;

  const viewAs = getStorage('viewAs');

  return (
    <nav className="top-area">
      <div className={classNames('overlay', { active: sidebar.data!.sidebarState.open })} onClick={handleSidebar} />

      <a
        aria-label="Hide Sidebar"
        className={classNames('sidebar-toggle', { active: sidebar.data!.sidebarState.open })}
        onClick={handleSidebar}
      >
        <i className="fa fa-angle-right" />
      </a>

      <div className="container-fluid">
        <NavLink to="/" className="navbar-brand d-md-none">
          <img src={logoSmall} className="navbar-brand-img" style={{ width: '40px' }} />
        </NavLink>

        {viewAs && (
          <small>
            <div className="d-none d-md-block">
              <Trans>You are logged in as</Trans> {viewAs}
            </div>
            <a className="text-info font-weight-bold" onClick={handleExit}>
              <Trans>Exit customer mode</Trans>
            </a>
          </small>
        )}

        {data.viewer.mode === LoginMode.User && !viewAs && (
          <>
            <div className="actions mr-3 d-none d-lg-flex align-items-center flex-wrap">
              {window.location.pathname !== '/support/ticket' && (
                <button type="button" className="btn btn-sm btn-primary my-1" onClick={toggleAddTicket}>
                  <i className="icon-tickets" />
                  <Trans>Ticket</Trans>
                </button>
              )}

              <button type="button" className="btn btn-sm btn-primary my-1" onClick={toggleAddTime}>
                <i className="icon-time" />
                <Trans>Track time</Trans>
              </button>

              <button type="button" className="btn btn-sm btn-primary my-1" onClick={toggleSendSMS}>
                <i className="icon-sms" />
                <Trans>SMS</Trans>
              </button>

              <div className="nav-item dropdown my-1">
                <OutsideClickHandler onOutsideClick={() => setShowMore(false)}>
                  <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    className="button nav-link"
                    data-toggle="dropdown"
                    onClick={toggleShowMore}
                  >
                    <div className="link-action">+</div>
                  </button>
                  <div
                    className={classNames('dropdown-menu dropdown-menu-arrow dropdown-menu-right', { show: showMore })}
                  >
                    <NavLink
                      to="/crm/company/add"
                      type="button"
                      className="button dropdown-item d-flex align-items-center"
                    >
                      <i className="icon-calendar" />
                      <Trans>Add company</Trans>
                    </NavLink>

                    <NavLink
                      to="/crm/person/add"
                      type="button"
                      className="button dropdown-item d-flex align-items-center"
                    >
                      <i className="icon-password" />
                      <Trans>Add person</Trans>
                    </NavLink>

                    <button
                      type="button"
                      className="button dropdown-item d-flex align-items-center"
                      onClick={toggleAddOffer}
                    >
                      <i className="icon-project" />
                      <Trans>Add offer</Trans>
                    </button>
                  </div>
                </OutsideClickHandler>
              </div>
            </div>

            <div className="search d-none d-sm-block ml-2 mr-auto">
              <Searchbar />
            </div>

            <OfferCreateModal show={showAddOffer} onClose={toggleAddOffer} />

            <TicketAddModal show={showAddTicket} onClose={toggleAddTicket} onSuccess={handleAddTicketSuccess} />

            <SendSMSModal show={showSendSMS} onClose={toggleSendSMS} />

            <TimeTrackerCreateEntryModal show={showAddTime} onClose={toggleAddTime} />
          </>
        )}

        <div className="ml-auto">
          <div className="navbar-nav">
            <div className="nav-item">
              <button title="Dark Mode" className="button nav-link" onClick={handleDarkMode}>
                {sidebar.data?.sidebarState.darkMode ? (
                  <i className="icon-light-mode" />
                ) : (
                  <i className="icon-dark-mode" />
                )}
              </button>
            </div>

            <HeaderNotifications />

            <HeaderProfile />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(Topbar);
