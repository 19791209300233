import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import * as yup from 'yup';
import QRCode from 'qrcode.react';
import { ACTIVATE_MFA, GET_VIEWER } from '../../schema/auth';
import { activateMfa, activateMfaVariables } from '../../schema/types/activateMfa';
import { toastErrorQuery } from '../../utils/error';
import { validateRequired } from '../../utils/validations';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';

type Props = {
  qr: string;
  onSuccess: () => void;
  onClose: () => void;
};

const ProfileActivateMfaForm: FunctionComponent<Props> = ({ qr, onSuccess, onClose }) => {
  const [mutate] = useMutation<activateMfa, activateMfaVariables>(ACTIVATE_MFA, {
    refetchQueries: [
      {
        query: GET_VIEWER,
      },
    ],
    awaitRefetchQueries: true,
  });

  return (
    <Formik
      initialValues={{
        otp: '',
      }}
      validationSchema={yup.object().shape({
        otp: validateRequired,
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          const result: any = await mutate({
            variables: {
              otp: values.otp,
            },
          });

          if (result.errors) {
            toastErrorQuery(result.errors);
          } else {
            resetForm();
            onSuccess();
          }
        } catch (e) {
          toastErrorQuery(e);
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting, submitForm }) => {
        const handleSubmit = (e: any) => {
          e.preventDefault();
          e.stopPropagation();
          submitForm();
        };

        return (
          <Form onSubmitCapture={handleSubmit}>
            <div className="modal-body text-center">
              <div className="form-group">
                <Trans>Use Google Authenticator or enter OTP from Email/SMS</Trans>
              </div>

              <div className="form-group">
                <label>
                  <Trans>Scan with Google Authenticator</Trans>
                </label>

                <div>
                  <QRCode value={qr} renderAs="svg" size={256} />
                </div>
              </div>

              <div className="form-group">
                <label>
                  <Trans>OTP</Trans>
                </label>
                <Field type="text" className="form-control" name="otp" autoFocus />

                <FieldErrorMessage name="otp" />
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-link" onClick={onClose}>
                <Trans>Close</Trans>
              </button>

              <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProfileActivateMfaForm;
