import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, FormikProps, useFormikContext } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { TextTemplateContentType, TextTemplateType } from '../../schema/graphql-global-types';
import { TextTemplateDetail } from '../../schema/types/TextTemplateDetail';
import DocumentChatGPTForm from '../documentation/DocumentChatGPTForm';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldCheckbox from '../form/FieldCheckbox';
import FieldEditor from '../form/FieldEditor';
import FieldErrorMessage from '../form/FieldErrorMessage';
import TicketPersonForm from '../ticket/TicketPersonForm';
import TicketUserForm from '../ticket/TicketUserForm';
import TextTemplateVariables from './TextTemplateVariables';

type Props = {
  loading?: boolean;
};

const TextTemplateCreateForms: FunctionComponent<Props> = ({ loading }) => {
  const { values, setFieldValue } = useFormikContext<TextTemplateDetail>();

  return (
    <Form>
      <div className="form-breadcrumbs-area">
        <NavLink to="/settings/text-template">
          <Trans>Text Templates</Trans>
        </NavLink>

        <i className="fa fa-angle-left" />

        <Field name="id">
          {({ field }: { field: FieldInputProps<string> }) => {
            if (field.value) return <Trans>Edit text template</Trans>;

            return <Trans>Add new text template</Trans>;
          }}
        </Field>
      </div>

      <div className="table-actions form-table-actions">
        <div className="breadcrumbs-area">
          <Field name="systemName">
            {({ field }: { field: FieldInputProps<string> }) => {
              return <div className="main-title">{field.value}</div>;
            }}
          </Field>
        </div>

        <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save updates`)} loading={loading} />
      </div>

      <div className="card">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>
                <Trans>Name</Trans>
              </label>

              <Field type="text" className="form-control" name="systemName" />

              <FieldErrorMessage name="systemName" />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>
                <Trans>Subject</Trans>
              </label>

              <Field type="text" className="form-control" name="title" />

              <FieldErrorMessage name="title" />
            </div>
          </div>
        </div>

        <Field name="contentType">
          {({ field }: { field: any }) => {
            if (field.value === TextTemplateContentType.Text) return null;

            return (
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>
                      <Trans>TO</Trans>
                    </label>

                    <TicketPersonForm name="to" />
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-group">
                    <label>
                      <Trans>CC</Trans>
                    </label>

                    <TicketPersonForm name="cc" />
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-group">
                    <label>
                      <Trans>BCC</Trans>
                    </label>

                    <TicketPersonForm name="bcc" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>
                      <Trans>Message Center To</Trans>
                    </label>

                    <TicketUserForm
                      placeholderOptions={[{ value: '{{messageCenterUserId}}', label: '{{messageCenterUserId}}' }]}
                      labelField={'fullName'}
                      valueField={'id'}
                      name="messageCenterTo"
                    />
                  </div>
                </div>
              </div>
            );
          }}
        </Field>

        <div className="form-group">
          <label>
            <Trans>Content</Trans>
          </label>
          <FieldEditor name="content" />

          <DocumentChatGPTForm title={values.title} onChange={(val) => setFieldValue('content', val)} />
        </div>

        <div className="form-group">
          <Field name="type">
            {({ field }: { field: any }) => {
              if (field.value === TextTemplateType.System) return <div />;

              return <FieldCheckbox name="public" label={i18n._(t`Available for all users`)} />;
            }}
          </Field>
        </div>

        <div className="action-submit">
          <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={loading} />
        </div>

        <Field name="variables">
          {({ field, form }: { field: any; form: FormikProps<any> }) => {
            const handleSelect = (item: string) => () => {
              form.setFieldValue('content', `${form.values.content} {{${item}}}`);
            };

            if (!field.value || !field.value.length) return <TextTemplateVariables onSelect={handleSelect} />;

            return (
              <div className="history-item">
                <label className="mr-2">
                  <Trans>Variables</Trans>:
                </label>

                {field.value.map((el: any, index: any) => (
                  <a key={index} className="badge text-dark mr-2" onClick={handleSelect(el)}>{`{{${el}}}`}</a>
                ))}
              </div>
            );
          }}
        </Field>
      </div>
    </Form>
  );
};

export default TextTemplateCreateForms;
