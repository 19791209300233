import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { getCountries } from '../../utils/const';
import countries from '../../utils/countries';

type Props = {
  name: string;
  placeholder?: string | null;
  onChange?: (value: string) => void | null;
};

const CountrySelect: FunctionComponent<Props> = ({ name, placeholder, onChange }) => {
  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (value: any) => {
          form.setFieldValue(name, value.value);
          if (onChange) onChange(value.value);
        };

        const model = field.value
          ? {
              value: field.value,
              label:
                // @ts-ignore
                countries[i18n.locale][countries.en.indexOf(field.value)],
            }
          : null;

        const options = getCountries().map((el: any) => ({
          value: el.key,
          label: el.value,
        }));

        return (
          <Select
            defaultValue={model}
            options={options}
            cacheOptions
            defaultOptions
            onChange={handleChange}
            placeholder={placeholder || i18n._(t`Select...`)}
            components={{
              ClearIndicator: null,
              IndicatorSeparator: null,
              DropdownIndicator: null,
            }}
          />
        );
      }}
    </Field>
  );
};

export default CountrySelect;
