import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import { endOfMonth, startOfMonth } from 'date-fns';
import React, { FC } from 'react';
import icon from '../../img/close-avg.svg';
import { GET_AVG_TICKET_CLOSE_TIME_MS } from '../../schema/stats';
import { avgTicketCloseTimeMs, avgTicketCloseTimeMsVariables } from '../../schema/types/avgTicketCloseTimeMs';
import { msToTime } from '../../utils/utils';
import ErrorQuery from '../form/ErrorQuery';

const today = new Date();

type Props = {};

const HomeCloserTime: FC<Props> = () => {
  const { data, error } = useQuery<avgTicketCloseTimeMs, avgTicketCloseTimeMsVariables>(GET_AVG_TICKET_CLOSE_TIME_MS, {
    variables: {
      filter: {
        from: startOfMonth(today).toISOString(),
        to: endOfMonth(today).toISOString(),
      },
    },
    fetchPolicy: 'cache-first',
  });

  if (error) return <ErrorQuery error={error} />;

  return (
    <div className="item item-5">
      <div className="card">
        <div className="icons">
          <img src={icon} alt="" />
        </div>

        <div className="block-title">
          <Trans>This month's average close time</Trans>
        </div>

        <div className="content time text-danger">{msToTime(data?.avgTicketCloseTimeMs || 0)} h</div>
      </div>
    </div>
  );
};

export default HomeCloserTime;
