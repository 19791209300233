import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import ReactTable, { CellInfo } from 'react-table';
import { NavLink } from 'react-router-dom';
import MainTitle from '../components/common/MainTitle';
import NumberDisplay from '../components/common/NumberDisplay';
import TableActions from '../components/common/TableActions';
import ButtonArchive from '../components/form/ButtonArchive';
import ButtonEdit from '../components/form/ButtonEdit';
import LoadingTable from '../components/common/LoadingTable';
import { GET_PRODUCTS, ARCHIVE_PRODUCT, UNARCHIVE_PRODUCT } from '../schema/product';
import { OrderDirection, ProductBilling } from '../schema/graphql-global-types';
import { archiveProduct, archiveProductVariables } from '../schema/types/archiveProduct';
import { getProducts, getProductsVariables } from '../schema/types/getProducts';
import { Tag } from '../schema/types/Tag';
import { unarchiveProduct, unarchiveProductVariables } from '../schema/types/unarchiveProduct';
import { tableOptions, tablePollingInterval } from '../utils/const';
import ErrorQuery from '../components/form/ErrorQuery';
import { cloneDeep } from 'lodash';
import { DataProxy } from '@apollo/client/cache';
import { useVariables } from '../utils/hooks';
import { getOrder } from '../utils/utils';

const ProductPage: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);
  const {
    search$,
    search,
    filter,
    archived,
    setArchived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
  } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const updateQuery = (proxy: DataProxy, id: any) => {
    const data: any = cloneDeep(proxy.readQuery({ query: GET_PRODUCTS, variables }));
    data.products.items = data.products.items.filter((el: any) => el.id !== id);
    proxy.writeQuery({ query: GET_PRODUCTS, variables, data });
  };

  const [mutateArchive] = useMutation<archiveProduct, archiveProductVariables>(ARCHIVE_PRODUCT, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.archiveProduct.product.id);
    },
  });
  const [mutateUnarchive] = useMutation<unarchiveProduct, unarchiveProductVariables>(UNARCHIVE_PRODUCT, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.unarchiveProduct.product.id);
    },
  });

  const handleArchive = useCallback(
    (id: any) => () => {
      if (archived) {
        mutateUnarchive({
          variables: {
            id,
          },
          optimisticResponse: {
            unarchiveProduct: {
              product: {
                id,
                __typename: 'Product',
              },
              __typename: 'MutateProductResponse',
            },
          },
        });
      } else {
        mutateArchive({
          variables: {
            id,
          },
          optimisticResponse: {
            archiveProduct: {
              product: {
                id,
                __typename: 'Product',
              },
              __typename: 'MutateProductResponse',
            },
          },
        });
      }
    },
    [archived, mutateArchive, mutateUnarchive],
  );

  const { data, previousData, loading, error } = useQuery<getProducts, getProductsVariables>(GET_PRODUCTS, {
    variables,
    pollInterval: tablePollingInterval,
  });

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.products.items ?? previousData?.products.items ?? [];
  const pages = data && data.products ? Math.ceil(data.products.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Name`),
      accessor: 'title',
      sortable: true,
      Cell: (cell: CellInfo) => <NavLink to={`/settings/product/${cell.original.id}`}>{cell.value}</NavLink>,
    },
    {
      Header: i18n._(t`Price`),
      accessor: 'price',
      sortable: true,
      width: 150,
      className: 'text-center',
      Cell: (cell: CellInfo) => <NumberDisplay value={cell.value} />,
    },
    {
      Header: i18n._(t`Billing`),
      accessor: 'billing',
      width: 120,
      className: 'text-center',
      Cell: (cell: CellInfo) => {
        switch (cell.value) {
          case ProductBilling.OneTime:
            return i18n._(t`One Time`);
          case ProductBilling.Monthly:
            return i18n._(t`Monthly`);
          case ProductBilling.Yearly:
            return i18n._(t`Yearly`);
          default:
            return '';
        }
      },
    },
    {
      Header: i18n._(t`Tags`),
      accessor: 'tags',
      Cell: (cell: CellInfo) => (
        <>
          {cell.value.map((el: Tag) => (
            <span key={el.id} className="badge mr-1 mb-1">
              {el.name}
            </span>
          ))}
        </>
      ),
    },
    {
      Header: i18n._(t`Actions`),
      width: 90,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <>
          <NavLink to={`/settings/product/${cell.original.id}`}>
            <ButtonEdit />
          </NavLink>
          <ButtonArchive onClick={handleArchive(cell.original.id)} />
        </>
      ),
    },
  ];

  return (
    <div className="product-page">
      <div className="card-body">
        <div className="table-actions">
          <div className="breadcrumbs-area">
            <MainTitle title={i18n._(t`Products`)} tips="Settings/Products" />
          </div>

          <TableActions onSearch={(e) => search$.next(e.target.value)} archived={archived} onArchive={setArchived} />

          <NavLink to="/settings/product/add" type="button" className="btn btn-sm btn-primary">
            <i className="icon-add" />
            <Trans>Add new product</Trans>
          </NavLink>
        </div>

        {!loaded ? (
          <LoadingTable />
        ) : (
          <ReactTable
            data={items}
            columns={columns}
            pages={pages}
            pageSize={limit}
            manual
            onFetchData={(state) => {
              let orderDir = OrderDirection.Desc;
              if (state.sorted[0]) {
                orderDir = state.sorted[0].desc ? OrderDirection.Desc : OrderDirection.Asc;
              }

              setOffset(state.page * state.pageSize);
              setOrderBy(getOrder(state));
              setOrderDirection(orderDir);
            }}
            onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
            {...tableOptions()}
          />
        )}
      </div>
    </div>
  );
};

export default ProductPage;
