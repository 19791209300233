import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { subHours } from 'date-fns';
import { pick, omit, cloneDeep, isEqual } from 'lodash';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import AssetCreateForms from '../components/asset/AssetCreateForms';
import ErrorQuery from '../components/form/ErrorQuery';
import LoadingForm from '../components/common/LoadingForm';
import { assetKeys, GET_ASSET, UPDATE_ASSET, validateAssetForm } from '../schema/asset';
import { getAsset, getAssetVariables } from '../schema/types/getAsset';
import { updateAsset, updateAssetVariables } from '../schema/types/updateAsset';
import { DefaultRouteParams } from '../utils/const';
import { toastErrorQuery } from '../utils/error';
import { omitDeep } from '../utils/utils';
import NotFoundPage from './NotFoundPage';

const today = new Date();
const hour24 = subHours(today, 24);

const ServicesAssetUpdatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ match }) => {
  const [mutate] = useMutation<updateAsset, updateAssetVariables>(UPDATE_ASSET);

  const { data, loading, error } = useQuery<getAsset, getAssetVariables>(GET_ASSET, {
    variables: {
      query: match.params.id,
      query2: {
        filter: {
          fromTime: hour24.toISOString(),
          toTime: today.toISOString(),
        },
      },
      query3: {
        step: 3600,
        filter: {
          fromTime: hour24.toISOString(),
          toTime: today.toISOString(),
        },
      },
    },
  });

  if (!data && loading) return <LoadingForm />;
  if (error) return <ErrorQuery error={error} />;
  if (!data) return <NotFoundPage />;

  const monitor = cloneDeep(omitDeep(data.asset.monitor, ['__typename']));

  return (
    <div className="asset-form-page">
      <Formik
        enableReinitialize
        initialValues={data.asset}
        validationSchema={yup.object().shape(validateAssetForm)}
        onSubmit={async (values, { setSubmitting, setValues }) => {
          try {
            const payload: any = omitDeep(pick(values, assetKeys), ['__typename']);
            payload.monitor =
              payload.monitor && !isEqual(monitor, payload.monitor) ? omit(payload.monitor, ['id', 'heartbeat']) : null;
            payload.uptimeSla = Number(payload.uptimeSla);
            payload.reminders = payload.reminders
              .filter((el: any) => !el.archivedAt)
              .map((el: any) => omit(el, ['archivedAt']));

            const result: any = await mutate({
              variables: {
                id: match.params.id,
                payload,
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              setValues(result.data.updateAsset.asset);
              toast.success(i18n._(t`Success!`));
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => <AssetCreateForms loading={isSubmitting} />}
      </Formik>
    </div>
  );
};

export default withRouter(ServicesAssetUpdatePage);
