import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import { ENABLE_MFA } from '../../schema/auth';
import { enableMfa, enableMfaVariables } from '../../schema/types/enableMfa';
import { toastErrorQuery } from '../../utils/error';
import ButtonSubmit from '../form/ButtonSubmit';

type Props = {
  phone: string;
  onSuccess: (otpAuthUrl: string) => void;
  onClose: () => void;
};

const ProfileEnableMfaForm: FunctionComponent<Props> = ({ phone, onSuccess, onClose }) => {
  const [mutate] = useMutation<enableMfa, enableMfaVariables>(ENABLE_MFA);

  return (
    <Formik
      initialValues={{
        email: !phone || phone.length <= 5,
        sms: phone ? phone.length > 5 : false,
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          const result: any = await mutate({
            variables: {
              payload: values,
            },
          });

          if (result.errors) {
            toastErrorQuery(result.errors);
          } else {
            resetForm();
            onSuccess(result.data.enableMfa.otpAuthUrl);
          }
        } catch (e) {
          toastErrorQuery(e);
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting, submitForm }) => {
        const handleSubmit = (e: any) => {
          e.preventDefault();
          e.stopPropagation();
          submitForm();
        };

        return (
          <Form onSubmitCapture={handleSubmit}>
            <div className="modal-body">
              <label className="label-lg">
                <Trans>Choose Method</Trans>
              </label>

              <Field name="sms">
                {({ form }: { form: FormikProps<any> }) => {
                  const handleGoogle = (e: any) => {
                    if (e.target.checked) {
                      form.setFieldValue('sms', false);
                      form.setFieldValue('email', false);
                    }
                  };

                  const handleSms = (e: any) => {
                    if (e.target.checked) {
                      form.setFieldValue('sms', true);
                      form.setFieldValue('email', false);
                    }
                  };

                  const handleEmail = (e: any) => {
                    if (e.target.checked) {
                      form.setFieldValue('sms', false);
                      form.setFieldValue('email', true);
                    }
                  };

                  return (
                    <>
                      {phone && phone.length > 5 ? (
                        <div className="form-group custom-control custom-radio">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="sms"
                            checked={form.values.sms}
                            onChange={handleSms}
                            value="sms"
                          />
                          <label className="custom-control-label" htmlFor="sms">
                            <Trans>SMS</Trans>
                          </label>
                        </div>
                      ) : (
                        <div className="form-group text-danger">
                          <i className="icon-alert" />
                          <Trans>You need a valid cellphone to enable SMS method</Trans>
                        </div>
                      )}

                      <div className="form-group custom-control custom-radio">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="email"
                          checked={form.values.email}
                          onChange={handleEmail}
                          value="email"
                        />
                        <label className="custom-control-label" htmlFor="email">
                          <Trans>Email</Trans>
                        </label>
                      </div>

                      <div className="form-group custom-control custom-radio">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="google"
                          checked={!form.values.sms && !form.values.email}
                          onChange={handleGoogle}
                          value="google"
                        />
                        <label className="custom-control-label" htmlFor="google">
                          <Trans>Google Authenticator</Trans>
                        </label>
                      </div>
                    </>
                  );
                }}
              </Field>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-link" onClick={onClose}>
                <Trans>Close</Trans>
              </button>

              <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProfileEnableMfaForm;
