import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, FormikProps, useFormikContext } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import { CompanyDetail } from '../../schema/types/CompanyDetail';
import FieldErrorMessage from '../form/FieldErrorMessage';
import FieldSlider from '../form/FieldSlider';
import ImageUploader from '../form/ImageUploader';
import CompanyExternalForm from './CompanyExternalForm';
import CompanyTagForm from './CompanyTagForm';

type Props = {};

const CompanyDetailForm: FunctionComponent<Props> = () => {
  const { values, setFieldValue } = useFormikContext<CompanyDetail>();
  const [showConfirm, setShowConfirm] = useState(false);

  const handleVat = useCallback(() => {
    if (!values.bexioNoVat) {
      setShowConfirm(true);
    } else {
      setFieldValue('bexioNoVat', false);
    }
  }, [setFieldValue, values]);

  const handleConfirm = useCallback(() => {
    setFieldValue('bexioNoVat', true);
    setShowConfirm(false);
  }, [setFieldValue]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="form-group">
          <label>
            <Trans>Company Logo</Trans>
          </label>

          <div className="d-flex align-items-center">
            <Field name="logo.url">
              {({ field, form }: { field: any; form: FormikProps<any> }) => (
                <ImageUploader onUpload={(id) => form.setFieldValue('logoId', id)} image={field.value} />
              )}
            </Field>

            <label className="ml-2 flex-grow-1">
              <i className="icon-details" />
              <Trans>Logo size should be not bigger than 5 Mb</Trans>
            </label>
          </div>
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <Field name="id">
            {({ field }: { field: any }) => {
              if (!field.value)
                return (
                  <div className="form-group">
                    <label>
                      <Trans>Company</Trans>
                    </label>
                    <CompanyExternalForm />

                    <FieldErrorMessage name="name" />
                  </div>
                );

              return (
                <div className="form-group">
                  <label>
                    <Trans>Company</Trans>
                  </label>
                  <Field type="text" className="form-control" name="name" autoFocus />

                  <FieldErrorMessage name="name" />
                </div>
              );
            }}
          </Field>
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Name Suffix</Trans>
          </label>
          <Field type="text" className="form-control" name="address2" />

          <FieldErrorMessage name="address2" />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Tags</Trans>
          </label>

          <CompanyTagForm />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Number of employees</Trans>
          </label>
          <Field type="number" className="form-control" name="size" />

          <FieldErrorMessage name="size" />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group field-heat-slider mb-4">
          <label className="mb-4">
            <Trans>Heat Level</Trans>
          </label>
          <FieldSlider name="heatLevel" />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Status</Trans>
          </label>
          <Field className="form-control" component="select" name="status">
            <option value="Default">{i18n._(t`Default`)}</option>
            <option value="Cold">{i18n._(t`Cold`)}</option>
            <option value="Touched">{i18n._(t`Touched`)}</option>
            <option value="Customer">{i18n._(t`Customer`)}</option>
            <option value="Partner">{i18n._(t`Partner`)}</option>
          </Field>
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Website</Trans>
          </label>

          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <Field type="url" className="form-control" name="website" />
            </div>

            <Field name="website">
              {({ field }: { field: any }) => {
                if (!field.value) return '';

                return (
                  <>
                    <a data-tip data-for="website" href={field.value} target="_blank" className="ml-2">
                      <i className="icon-preview" />
                    </a>
                    <ReactTooltip id="website">
                      <Trans>View</Trans>
                    </ReactTooltip>
                  </>
                );
              }}
            </Field>
          </div>

          <FieldErrorMessage name="website" />
        </div>
      </div>

      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id="cb-vat"
          checked={values.bexioNoVat}
          onChange={handleVat}
        />
        <label className="custom-control-label" htmlFor="cb-vat">
          <Trans>Don't charge VAT (Bexio)</Trans>
        </label>
      </div>

      <Modal isOpen={showConfirm} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
        <div className="modal fade show" style={{ display: 'block' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title">
                  <Trans>Confirm</Trans>
                </h2>
                <button type="button" className="close" aria-label="Close" onClick={() => setShowConfirm(false)}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <div className="modal-body text-danger">
                <Trans>Are you sure VAT shouldn’t be charged on invoices of this company?</Trans>
              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-info" onClick={() => setShowConfirm(false)}>
                  <Trans>Cancel</Trans>
                </button>

                <button type="button" className="btn btn-primary" onClick={handleConfirm}>
                  <Trans>Confirm</Trans>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CompanyDetailForm;
