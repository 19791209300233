import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import React, { FunctionComponent, useCallback } from 'react';
import ReactTable, { CellInfo } from 'react-table';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ButtonEdit from '../../components/form/ButtonEdit';
import { GET_TEXT_TEMPLATE_WITH_DETAIL } from '../../schema/textTemplate';
import { getTextTemplates, getTextTemplatesVariables } from '../../schema/types/getTextTemplates';
import { TextTemplateDetail } from '../../schema/types/TextTemplateDetail';
import { tableOptions } from '../../utils/const';
import ErrorQuery from '../../components/form/ErrorQuery';
import { useVariables } from '../../utils/hooks';
import ShowArchive from '../common/ShowArchive';

type Props = {
  favorites: TextTemplateDetail[];
  onSelect: (item: TextTemplateDetail) => void;
  onFavorite: (item: TextTemplateDetail) => void;
};

const TicketTextTemplates: FunctionComponent<Props> = ({ favorites, onSelect, onFavorite }) => {
  const {
    search$,
    search,
    filter,
    archived,
    setArchived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    orderDirection,
  } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const handleSelect = useCallback((item: TextTemplateDetail) => () => onSelect(item), [onSelect]);

  const handleFavorite = useCallback((item: TextTemplateDetail) => () => onFavorite(item), [onFavorite]);

  const { data, loading, error } = useQuery<getTextTemplates, getTextTemplatesVariables>(
    GET_TEXT_TEMPLATE_WITH_DETAIL,
    {
      variables,
      fetchPolicy: 'cache-first',
    },
  );

  if (!data && !loading) return <div />;
  if (error) return <ErrorQuery error={error} />;

  let items = data && data.textTemplates ? data.textTemplates.items : [];
  const pages = data && data.textTemplates ? Math.ceil(data.textTemplates.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Title`),
      accessor: 'title',
      Cell: (cell: CellInfo) => (
        <a onClick={handleSelect(cell.original)} className="text-info">
          {cell.value}
        </a>
      ),
    },
    {
      Header: i18n._(t`Actions`),
      width: 150,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <>
          <NavLink to={`/settings/text-template/${cell.original.id}`} target="_blank">
            <ButtonEdit />
          </NavLink>

          {!archived && (
            <>
              <span
                data-tip
                data-for={`favorite-${cell.original.id}`}
                className="button text-xs"
                onClick={handleFavorite(cell.original)}
              >
                <i
                  className={classNames({
                    'icon-like': !favorites.some((el: TextTemplateDetail) => el.id === cell.original.id),
                    'icon-like---filled text-warning': favorites.some(
                      (el: TextTemplateDetail) => el.id === cell.original.id,
                    ),
                  })}
                />
              </span>
              <ReactTooltip id={`favorite-${cell.original.id}`}>
                <Trans>Favorite</Trans>
              </ReactTooltip>
            </>
          )}

          {!archived && (
            <button type="button" className="btn btn-link btn-sm" onClick={handleSelect(cell.original)}>
              <Trans>Select</Trans>
            </button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="form-group input-group">
        <div className="input-group-prepend">
          <div className="input-group-text">
            <i className="icon-search" />
          </div>
        </div>
        <input
          className="form-control"
          placeholder={i18n._(t`Search`)}
          onInput={(e: any) => search$.next(e.target.value)}
        />
      </div>

      <div className="form-group text-right">
        <ShowArchive model={archived} onChange={setArchived} />
      </div>

      <ReactTable
        data={items}
        columns={columns}
        pages={pages}
        pageSize={limit}
        loading={loading}
        manual
        onFetchData={(state) => {
          setOffset(state.page * state.pageSize);
        }}
        onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
        {...tableOptions()}
      />
    </>
  );
};

export default TicketTextTemplates;
