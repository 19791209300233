import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import OutsideClickHandler from 'react-outside-click-handler';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import * as yup from 'yup';
import { GET_TIME_TRACKER_CONFIG } from '../../schema/config';
import {
  OrderDirection,
  TicketInProgressSubStatus,
  TimeTrackerProjectsQueryOrderBy,
} from '../../schema/graphql-global-types';
import {
  ADD_TICKET_INTERNAL_NOTE,
  CLOSE_TICKET,
  GET_TICKET,
  GET_TICKET_LOGS,
  GET_TICKETS_WITH_DETAIL,
  SEND_TICKET_MESSAGE,
  UPDATE_TICKET_RESPONSIBLE_USER,
  validateTicketForm,
} from '../../schema/ticket';
import { GET_TIME_TRACKER_PROJECTS } from '../../schema/timeTracker';
import { addTicketInternalNote, addTicketInternalNoteVariables } from '../../schema/types/addTicketInternalNote';
import { closeTicket, closeTicketVariables } from '../../schema/types/closeTicket';
import { getTicketLogs, getTicketLogsVariables } from '../../schema/types/getTicketLogs';
import { getTimeTrackerConfig } from '../../schema/types/getTimeTrackerConfig';
import { getTimeTrackerProjects, getTimeTrackerProjectsVariables } from '../../schema/types/getTimeTrackerProjects';
import { Log } from '../../schema/types/Log';
import { sendTicketMessage, sendTicketMessageVariables } from '../../schema/types/sendTicketMessage';
import { TicketDetail } from '../../schema/types/TicketDetail';
import {
  updateTicketResponsibleUser,
  updateTicketResponsibleUserVariables,
} from '../../schema/types/updateTicketResponsibleUser';
import { toastErrorQuery } from '../../utils/error';
import { useViewer } from '../../utils/hooks';
import HistoryItem from '../common/HistoryItem';
import LoadingForm from '../common/LoadingForm';
import { cloneDeep, pick } from 'lodash';
import LoadingQuery from '../common/LoadingQuery';
import FieldErrorMessage from '../form/FieldErrorMessage';
import TicketDocTem from './TicketDocTem';
import TicketInfoPopup from './TicketInfoPopup';
import TicketMainForm from './TicketMainForm';
import TicketSmsForm from './TicketSmsForm';

const keys = ['to', 'cc', 'subject', 'content', 'attachmentIds'];

const transform = (item: TicketDetail) => {
  const model: any = cloneDeep(item);
  model.to = item.lastSender ? [item.lastSender] : [];
  model.content = '';
  model.attachmentIds = [];
  model.attachments = [];
  model.statusOnly = false;

  return model;
};

type Props = {
  model: TicketDetail;
  variables?: any;
  onSelect?: (value: any) => void;
  onAddTime?: (value: any) => void;
};

const TicketFormPanel: FunctionComponent<Props> = ({ model, variables, onSelect, onAddTime }) => {
  const me = useViewer();

  const { data: logs } = useQuery<getTicketLogs, getTicketLogsVariables>(GET_TICKET_LOGS, {
    variables: {
      query: model.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [loadingForm, setLoadingForm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const toggleShowForm = useCallback(() => {
    setLoadingForm(true);
    setShowNote(false);

    setTimeout(() => {
      setShowForm((prevState) => !prevState);
      setLoadingForm(false);
    }, 500);
  }, [setShowForm]);

  const [showNote, setShowNote] = useState(false);
  const toggleShowNote = useCallback(() => setShowNote((prevState) => !prevState), []);

  const [showSms, setShowSms] = useState(false);
  const toggleShowSms = useCallback(() => setShowSms((prevState) => !prevState), []);

  const handleClickOutside = useCallback(() => {
    setShowSms(false);
  }, []);

  useEffect(() => setShowForm(false), [model]);

  const [mutateCloseTicket] = useMutation<closeTicket, closeTicketVariables>(CLOSE_TICKET, {
    update: (proxy, { data, errors }) => {
      if (data && !errors && variables) {
        const results: any = cloneDeep(proxy.readQuery({ query: GET_TICKETS_WITH_DETAIL, variables }));

        if (onSelect) {
          const index = results.tickets.items.findIndex((el: any) => el.id === model.id);
          if (index >= results.tickets.items.length - 1) {
            onSelect(results.tickets.items[index - 1]);
          } else {
            onSelect(results.tickets.items[index + 1]);
          }
        }

        results.tickets.items = results.tickets.items.filter((el: any) => el.id !== model.id);

        proxy.writeQuery({
          query: GET_TICKETS_WITH_DETAIL,
          variables,
          data: results,
        });
      }
    },
  });

  const [mutateSendTicketMessage] = useMutation<sendTicketMessage, sendTicketMessageVariables>(SEND_TICKET_MESSAGE, {
    refetchQueries: [
      {
        query: GET_TICKET,
        variables: {
          query: model.id,
        },
      },
    ],
  });

  const [mutateUpdateTicketResponsibleUser] = useMutation<
    updateTicketResponsibleUser,
    updateTicketResponsibleUserVariables
  >(UPDATE_TICKET_RESPONSIBLE_USER, {
    refetchQueries: [
      {
        query: GET_TICKET,
        variables: {
          query: model.id,
        },
      },
    ],
  });

  const [mutateAddTicketInternalNote] = useMutation<addTicketInternalNote, addTicketInternalNoteVariables>(
    ADD_TICKET_INTERNAL_NOTE,
    {
      refetchQueries: [
        {
          query: GET_TICKET,
          variables: {
            query: model.id,
          },
        },
      ],
    },
  );

  const { data: timeTrackerConfig } = useQuery<getTimeTrackerConfig>(GET_TIME_TRACKER_CONFIG, {
    skip: !onAddTime,
  });

  const ticketCompany = useMemo(
    () => (model.person?.companies && model.person.companies.length ? model.person.companies[0] : null),
    [model],
  );

  const { data: projects } = useQuery<getTimeTrackerProjects, getTimeTrackerProjectsVariables>(
    GET_TIME_TRACKER_PROJECTS,
    {
      variables: {
        query: {
          limit: 1,
          offset: 0,
          orderBy: TimeTrackerProjectsQueryOrderBy.id,
          orderDirection: OrderDirection.Desc,
          filter: {
            archived: false,
            search: timeTrackerConfig?.timeTrackerConfig?.defaultProjectName,
            customerIds: ticketCompany ? [ticketCompany.company.id] : [],
          },
        },
      },
      skip: !timeTrackerConfig?.timeTrackerConfig?.defaultProjectName || !ticketCompany,
    },
  );

  let handler: string = '';

  const [form, setForm] = useState<any>(transform(model));

  useEffect(() => {
    if (form.id !== model.id) {
      setForm(transform(model));
    }
  }, [form, model]);

  const isVoiceMail = useMemo(() => !model.opener.includes('@'), [model]);

  if (!me.data) return <div />;

  return (
    <div className="ticket-form">
      <div className="ticket-heading">
        <div className="info">
          {model.person && (
            <div className="name">
              {model.person.isVIP && (
                <span className="btn btn-vip btn-sm">
                  <i className="icon-star---filled" /> <Trans>VIP Request</Trans>
                </span>
              )}

              {model.person.fullName}

              <span data-tip data-for="ticket-info" className="button text-xs">
                <i className="icon-info m-2" />
              </span>
              <ReactTooltip id="ticket-info" place="bottom" effect="solid" delayHide={500} clickable>
                <TicketInfoPopup model={model} />
              </ReactTooltip>
            </div>
          )}
        </div>

        <OutsideClickHandler onOutsideClick={handleClickOutside}>
          <div className="action-wrap">
            <button type="button" className="action" onClick={toggleShowSms}>
              <i className="icon-sms" />
            </button>

            <div className={classNames('popup', { active: showSms })}>
              <TicketSmsForm phone={model.person && model.person.cellphone} ticketId={model.id} />
            </div>
          </div>
        </OutsideClickHandler>
      </div>

      <Formik
        enableReinitialize
        initialValues={form}
        validationSchema={yup.object().shape(validateTicketForm)}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const payload: any = pick(values, keys);
            const statusOnly = showForm ? values.statusOnly : true;

            let result: any;

            if (handler === 'close') {
              result = await mutateCloseTicket({
                variables: {
                  id: model.id,
                  payload: {
                    message: !statusOnly ? payload : null,
                  },
                },
              });

              if (onAddTime) {
                toast.info(i18n._(t`Report Time`), {
                  onClick: () =>
                    onAddTime({
                      company:
                        projects?.timeTrackerProjects.items && projects?.timeTrackerProjects.items.length
                          ? projects?.timeTrackerProjects.items[0].customer
                          : ticketCompany?.company,
                      project: projects?.timeTrackerProjects.items[0],
                      description: payload.subject,
                    }),
                });
              }
            } else if (handler === 'wait') {
              result = await mutateSendTicketMessage({
                variables: {
                  id: model.id,
                  payload: {
                    message: !statusOnly ? payload : null,
                    subStatus: TicketInProgressSubStatus.WaitingForReply,
                    responsibleUserId: me.data!.viewer.user!.id,
                  },
                },
              });
            } else if (handler === 'status') {
              result = await mutateSendTicketMessage({
                variables: {
                  id: model.id,
                  payload: {
                    message: !statusOnly ? payload : null,
                    subStatus: TicketInProgressSubStatus.Working,
                    responsibleUserId: me.data!.viewer.user!.id,
                  },
                },
              });
            } else if (handler === 'internal') {
              result = await mutateAddTicketInternalNote({
                variables: {
                  id: model.id,
                  payload,
                },
              });
            } else {
              result = await mutateUpdateTicketResponsibleUser({
                variables: {
                  id: model.id,
                  payload: {
                    responsibleUserId: handler === 'me' ? me.data!.viewer.user!.id : handler,
                  },
                },
              });
            }

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              setShowNote(false);
              resetForm();
              if (handler !== 'close') toast.success(i18n._(t`Success!`));
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting, submitForm }) => {
          const handleSubmitClose = () => {
            handler = 'close';
            submitForm();
          };

          const handleSubmitWait = () => {
            handler = 'wait';
            submitForm();
          };

          const handleSubmitStatus = () => {
            handler = 'status';
            submitForm();
          };

          const handleSubmitInternal = () => {
            handler = 'internal';
            submitForm();
          };

          return (
            <Form className="ticket-form-wrap">
              {loadingForm ? (
                <LoadingForm />
              ) : (
                <>
                  {!showForm ? (
                    <>
                      <div className="ticket-actions form-group">
                        <button
                          type="button"
                          className="btn btn-sm btn-info work"
                          disabled={isVoiceMail || isSubmitting}
                          onClick={toggleShowForm}
                        >
                          <i className="icon-work-on-it" />
                          <Trans>Work on it</Trans>
                        </button>

                        <button
                          type="button"
                          className="btn btn-sm send-status"
                          disabled={isSubmitting}
                          onClick={handleSubmitStatus}
                        >
                          <i className="icon-send-status" />
                          <Trans>Progress</Trans>
                        </button>

                        <button
                          type="button"
                          className="btn btn-sm send-wait"
                          disabled={isVoiceMail || isSubmitting}
                          onClick={handleSubmitWait}
                        >
                          <i className="icon-wait" />
                          <Trans>Wait</Trans>
                        </button>

                        {variables && (
                          <button
                            type="button"
                            className="btn btn-sm send-close"
                            disabled={isSubmitting}
                            onClick={handleSubmitClose}
                          >
                            <i className="icon-close" />
                            <Trans>Close</Trans>
                          </button>
                        )}
                      </div>

                      <hr />

                      <div className="ticket-form">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="block-title m-0">
                            <Trans>Feed</Trans>
                          </div>

                          {!showNote ? (
                            <button
                              type="button"
                              className="btn btn-link"
                              disabled={isSubmitting}
                              onClick={toggleShowNote}
                            >
                              <Trans>Internal note</Trans>
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-link"
                              disabled={isSubmitting}
                              onClick={handleSubmitInternal}
                            >
                              <Trans>Save Note</Trans>
                            </button>
                          )}
                        </div>

                        {showNote && (
                          <div className="form-group">
                            <Field component="textarea" className="form-control" name="content" rows={2} />

                            <FieldErrorMessage name="content" />
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="ticket-actions form-group d-flex align-items-center flex-wrap">
                        <button
                          type="button"
                          className="btn btn-link back"
                          disabled={isSubmitting}
                          onClick={() => setShowForm(false)}
                        >
                          <i className="icon-send-status" />
                          <Trans>Back</Trans>
                        </button>

                        <button
                          type="button"
                          className="btn btn-sm send-status"
                          disabled={isSubmitting}
                          onClick={handleSubmitStatus}
                        >
                          <i className="icon-send-status" />
                          <Trans>Send (Progress)</Trans>
                        </button>

                        <button
                          type="button"
                          className="btn btn-sm send-wait"
                          disabled={isSubmitting}
                          onClick={handleSubmitWait}
                        >
                          <i className="icon-wait" />
                          <Trans>Send (Waiting)</Trans>
                        </button>

                        {variables && (
                          <button
                            type="button"
                            className="btn btn-sm send-close"
                            disabled={isSubmitting}
                            onClick={handleSubmitClose}
                          >
                            <i className="icon-checkmark" />
                            <Trans>Send (Close)</Trans>
                          </button>
                        )}
                      </div>

                      <div className="templates">
                        <div className="title">
                          <Trans>Write a message:</Trans>
                        </div>

                        <TicketDocTem />
                      </div>

                      <TicketMainForm logs={logs?.ticket.logs.items} isFromMsTeams={model.isFromMsTeams} chatGPT />
                    </>
                  )}
                </>
              )}
            </Form>
          );
        }}
      </Formik>

      {!logs ? (
        <div className="ticket-history">
          <LoadingQuery />
        </div>
      ) : (
        <div className="ticket-history">
          {logs.ticket.logs.items.map((el: Log) => (
            <HistoryItem
              key={el.id}
              date={el.createdAt}
              creator={el.creator || el.personCreator}
              content={el.content}
              contentHtml={el.contentHtml}
              attachments={el.attachments}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TicketFormPanel;
