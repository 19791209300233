import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import ButtonSubmit from '../form/ButtonSubmit';
import UserDetailForm from './UserDetailForm';
import UserInfoForm from './UserInfoForm';
import UserLogs from './UserLogs';

type Props = {
  loading?: boolean;
};

const UserCreateForms: FunctionComponent<Props> = ({ loading }) => {
  return (
    <Form className="person-detail-form">
      <div className="form-breadcrumbs-area">
        <NavLink to="/settings/user">
          <Trans>System Users</Trans>
        </NavLink>

        <i className="fa fa-angle-left" />

        <Field name="id">
          {({ field }: { field: FieldInputProps<string> }) => {
            if (field.value) return <Trans>Edit user</Trans>;

            return <Trans>Add new user</Trans>;
          }}
        </Field>
      </div>

      <div className="table-actions form-table-actions">
        <div className="breadcrumbs-area">
          <div className="main-title">
            <Field name="firstName">{({ field }: { field: FieldInputProps<string> }) => field.value}</Field>{' '}
            <Field name="lastName">{({ field }: { field: FieldInputProps<string> }) => field.value}</Field>
          </div>
        </div>

        <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save updates`)} loading={loading} />
      </div>

      <div className="card">
        <div className="row">
          <div className="col-xl-6 panel-column-left">
            <UserDetailForm />
          </div>

          <div className="col-xl-6">
            <UserInfoForm />
          </div>
        </div>
      </div>

      <Field name="id">
        {({ field }: { field: any }) => {
          if (!field.value) return '';

          return (
            <div className="card">
              <div className="card-header">
                <Trans>Log History</Trans>
              </div>
              <div className="card-body">
                <UserLogs userId={field.value} />
              </div>
            </div>
          );
        }}
      </Field>
    </Form>
  );
};

export default UserCreateForms;
