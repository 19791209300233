import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { subHours } from 'date-fns';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import ReactTable, { CellInfo, RowInfo } from 'react-table';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import AssetDashboard from '../components/asset/AssetDashboard';
import MainTitle from '../components/common/MainTitle';
import TableActions from '../components/common/TableActions';
import ButtonArchive from '../components/form/ButtonArchive';
import ButtonEdit from '../components/form/ButtonEdit';
import LoadingTable from '../components/common/LoadingTable';
import FilterHeader from '../components/form/FilterHeader';
import {
  GET_ASSETS,
  ARCHIVE_ASSET,
  UNARCHIVE_ASSET,
  ARCHIVE_ASSETS,
  ACTIVATE_MAINTENANCE_ASSETS,
  DEACTIVATE_MAINTENANCE_ASSETS,
} from '../schema/asset';
import { GET_COMPANIES_FOR_FILTER } from '../schema/company';
import { GET_NINJA_RMM_CONFIG } from '../schema/config';
import { AssetsQueryOrderBy, OrderDirection } from '../schema/graphql-global-types';
import { GET_PRODUCTS_FOR_FILTER } from '../schema/product';
import { GET_ASSET_LOCATION_TAGS, GET_ASSET_TAGS, GET_ASSET_TYPE_TAGS } from '../schema/tag';
import {
  activateMaintenanceAssets,
  activateMaintenanceAssetsVariables,
} from '../schema/types/activateMaintenanceAssets';
import { archiveAsset, archiveAssetVariables } from '../schema/types/archiveAsset';
import { archiveAssets, archiveAssetsVariables } from '../schema/types/archiveAssets';
import {
  deactivateMaintenanceAssets,
  deactivateMaintenanceAssetsVariables,
} from '../schema/types/deactivateMaintenanceAssets';
import { getAssets, getAssetsVariables } from '../schema/types/getAssets';
import { Tag } from '../schema/types/Tag';
import { unarchiveAsset, unarchiveAssetVariables } from '../schema/types/unarchiveAsset';
import { tableOptions } from '../utils/const';
import ErrorQuery from '../components/form/ErrorQuery';
import { toastErrorQuery } from '../utils/error';
import { cloneDeep } from 'lodash';
import { DataProxy } from '@apollo/client/cache';
import { useVariables } from '../utils/hooks';
import { getFilter, getOrder } from '../utils/utils';

const today = new Date();
const hour24 = subHours(today, 24);

const ServicesAssetPage: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);
  const {
    search$,
    search,
    filter$,
    filter,
    archived,
    setArchived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
  } = useVariables({ orderBy: AssetsQueryOrderBy.uptimeStatus });

  const variables = useMemo(
    () => ({
      query: {
        limit,
        offset,
        orderBy,
        orderDirection,
        filter: {
          search,
          archived,
          ...filter,
        },
      },
      query2: {
        filter: {
          fromTime: hour24.toISOString(),
          toTime: today.toISOString(),
        },
      },
      query3: {
        step: 3600,
        filter: {
          fromTime: hour24.toISOString(),
          toTime: today.toISOString(),
        },
      },
    }),
    [limit, offset, orderBy, orderDirection, search, archived, filter],
  );

  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState<any>({});
  const [status, setStatus] = useState('activate');

  const updateQuery = (proxy: DataProxy, id: any) => {
    const data: any = cloneDeep(proxy.readQuery({ query: GET_ASSETS, variables }));
    data.assets.items = data.assets.items.filter((el: any) => el.id !== id);
    proxy.writeQuery({ query: GET_ASSETS, variables, data });
  };

  const [mutateArchive] = useMutation<archiveAsset, archiveAssetVariables>(ARCHIVE_ASSET, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.archiveAsset.asset.id);
    },
  });
  const [mutateUnarchive] = useMutation<unarchiveAsset, unarchiveAssetVariables>(UNARCHIVE_ASSET, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.unarchiveAsset.asset.id);
    },
  });

  const handleArchive = useCallback(
    (id: any) => () => {
      if (archived) {
        mutateUnarchive({
          variables: {
            id,
          },
          optimisticResponse: {
            unarchiveAsset: {
              asset: {
                id,
                __typename: 'Asset',
              },
              __typename: 'MutateAssetResponse',
            },
          },
        });
      } else {
        mutateArchive({
          variables: {
            id,
          },
          optimisticResponse: {
            archiveAsset: {
              asset: {
                id,
                __typename: 'Asset',
              },
              __typename: 'MutateAssetResponse',
            },
          },
        });
      }
    },
    [archived, mutateArchive, mutateUnarchive],
  );

  const [batching, setBatching] = useState(false);

  const refetchQueries = [
    {
      query: GET_ASSETS,
      variables,
    },
  ];

  const [mutateArchives] = useMutation<archiveAssets, archiveAssetsVariables>(ARCHIVE_ASSETS, { refetchQueries });

  const [mutateActivateMaintenance] = useMutation<activateMaintenanceAssets, activateMaintenanceAssetsVariables>(
    ACTIVATE_MAINTENANCE_ASSETS,
    { refetchQueries },
  );

  const [mutateDeactivateMaintenance] = useMutation<deactivateMaintenanceAssets, deactivateMaintenanceAssetsVariables>(
    DEACTIVATE_MAINTENANCE_ASSETS,
    { refetchQueries },
  );

  const handleStatus = useCallback((e: any) => setStatus(e.target.value), [setStatus]);

  const handleSubmit = useCallback(async () => {
    const ids = Object.keys(selected);
    if (ids.length) {
      setBatching(true);

      try {
        if (status === 'activate') {
          await mutateActivateMaintenance({
            variables: {
              ids,
            },
          });
        } else if (status === 'deactivate') {
          await mutateDeactivateMaintenance({
            variables: {
              ids,
            },
          });
        } else if (status === 'archive') {
          await mutateArchives({
            variables: {
              ids,
            },
          });
        }

        setSelectAll(false);
        setSelected({});
        toast.success(i18n._(t`Success!`));
      } catch (e) {
        toastErrorQuery(e);
      }

      setBatching(false);
    }
  }, [
    selected,
    status,
    mutateActivateMaintenance,
    mutateDeactivateMaintenance,
    mutateArchives,
    setSelectAll,
    setSelected,
    setBatching,
  ]);

  const { data, previousData, loading, error } = useQuery<getAssets, getAssetsVariables>(GET_ASSETS, {
    variables,
  });

  const handleSelect = useCallback(
    (id: any) => () => {
      const value = cloneDeep(selected);

      if (value[id]) {
        delete value[id];
      } else {
        value[id] = true;
      }

      setSelected(value);
    },
    [selected, setSelected],
  );

  const items = useMemo(() => data?.assets.items ?? previousData?.assets.items ?? [], [data, previousData]);
  const pages = data && data.assets ? Math.ceil(data.assets.total / limit) : -1;

  const handleSelectAll = useCallback(() => {
    setSelectAll((prevState) => {
      if (!prevState) {
        const value: any = {};

        items.forEach((el: any) => {
          value[el.id] = true;
        });

        setSelected(value);
      } else {
        setSelected({});
      }

      return !prevState;
    });
  }, [setSelectAll, setSelected, items]);

  const getRow = useCallback((state: any, rowInfo?: RowInfo) => {
    return {
      className: rowInfo!.original.uptimeStatus,
    };
  }, []);

  const ninja = useQuery(GET_NINJA_RMM_CONFIG);

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const columns = [
    {
      accessor: 'id',
      Header: '',
      width: 40,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <input type="checkbox" checked={selected[cell.value] || false} onChange={handleSelect(cell.value)} />
      ),
    },
    {
      Header: '',
      expander: true,
      width: 50,
      className: 'text-center',
      Expander: (cell: CellInfo) => (
        <>
          <span data-tip data-for={`expand-${cell.original.id}`} className="button button-expand text-xs">
            {cell.isExpanded ? <i className="icon-dropdown" /> : <i className="icon-expand" />}
          </span>
          <ReactTooltip id={`expand-${cell.original.id}`}>
            <Trans>Expand</Trans>
          </ReactTooltip>
        </>
      ),
    },
    {
      Header: i18n._(t`Name`),
      accessor: 'name',
      sortable: true,
      filterable: true,
      Cell: (cell: CellInfo) => <NavLink to={`/service/asset/${cell.original.id}`}>{cell.value}</NavLink>,
    },
    {
      Header: i18n._(t`Company`),
      accessor: 'company.name',
      sortable: true,
      filterable: true,
      Cell: (cell: CellInfo) => (
        <>{cell.original.company && <NavLink to={`/crm/company/${cell.original.company.id}`}>{cell.value}</NavLink>}</>
      ),
      Filter: (params: any) => (
        <FilterHeader
          query={GET_COMPANIES_FOR_FILTER}
          objectType="companies"
          nameField="name"
          onChange={params.onChange}
        />
      ),
    },
    {
      Header: i18n._(t`Location`),
      accessor: 'assetLocation.name',
      filterable: true,
      Filter: (params: any) => (
        <FilterHeader
          query={GET_ASSET_LOCATION_TAGS}
          objectType="assetLocationTags"
          nameField="name"
          onChange={params.onChange}
        />
      ),
    },
    {
      Header: i18n._(t`Type`),
      accessor: 'assetType.name',
      filterable: true,
      Filter: (params: any) => (
        <FilterHeader
          query={GET_ASSET_TYPE_TAGS}
          objectType="assetTypeTags"
          nameField="name"
          onChange={params.onChange}
        />
      ),
    },
    {
      Header: i18n._(t`Package`),
      accessor: 'product.title',
      sortable: true,
      filterable: true,
      Cell: (cell: CellInfo) => (
        <>
          {cell.original.product && (
            <NavLink to={`/settings/product/${cell.original.product.id}`}>{cell.value}</NavLink>
          )}
        </>
      ),
      Filter: (params: any) => (
        <FilterHeader
          query={GET_PRODUCTS_FOR_FILTER}
          objectType="products"
          nameField="title"
          onChange={params.onChange}
        />
      ),
    },
    {
      Header: i18n._(t`Tags`),
      accessor: 'tags',
      filterable: true,
      Cell: (cell: CellInfo) => (
        <>
          {cell.value.map((el: Tag) => (
            <span key={el.id} className="badge mr-1 mb-1">
              {el.name}
            </span>
          ))}
        </>
      ),
      Filter: (params: any) => (
        <FilterHeader query={GET_ASSET_TAGS} objectType="assetTags" nameField="name" onChange={params.onChange} />
      ),
    },
    {
      id: 'uptimeSla',
      Header: i18n._(t`SLA`),
      accessor: 'product.reactionTime',
      Cell: (cell: CellInfo) => {
        const result = [`${cell.original.uptimeSla}%`];

        if (cell.value) {
          result.push(`${(cell.value / 3600).toFixed(1)}h`);
        }

        return result.join(' / ');
      },
    },
    {
      Header: i18n._(t`Uptime`),
      accessor: 'uptime365',
      width: 90,
      className: 'text-center',
      Cell: (cell: CellInfo) => (cell.value ? `${parseInt(cell.value, 10)}%` : '?'),
    },
    {
      Header: i18n._(t`Actions`),
      width: 90,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <>
          <NavLink to={`/service/asset/${cell.original.id}`}>
            <ButtonEdit />
          </NavLink>
          <ButtonArchive onClick={handleArchive(cell.original.id)} />
        </>
      ),
    },
  ];

  return (
    <div className="asset-page">
      <div className="card-body">
        <div className="table-actions">
          <div className="breadcrumbs-area">
            <MainTitle title={i18n._(t`Managed Assets`)} tips="Managed Services/Managed Assets" />
          </div>

          <TableActions onSearch={(e) => search$.next(e.target.value)} archived={archived} onArchive={setArchived} />

          <NavLink to="/service/asset/add" className="btn btn-sm btn-primary">
            <i className="icon-add" />
            <Trans>Add new asset</Trans>
          </NavLink>

          {ninja.data && ninja.data.ninjaRmmConfig && ninja.data.ninjaRmmConfig.apiKey && (
            <NavLink to="/service/ninjarmm/add" className="btn btn-sm btn-primary">
              <Trans>Manage NinjaRMM</Trans>
            </NavLink>
          )}
        </div>

        <div className="form-group d-flex align-items-center">
          <div className="form-group d-flex align-items-center">
            {!archived && (
              <>
                <input type="checkbox" className="ml-3" checked={selectAll} onChange={handleSelectAll} />
                <select className="form-control ml-3" value={status} onChange={handleStatus}>
                  <option value="activate">{i18n._(t`Activate Maintenance`)}</option>
                  <option value="deactivate">{i18n._(t`Deactivate Maintenance`)}</option>
                  <option value="archive">{i18n._(t`Archive`)}</option>
                </select>
                <button
                  type="button"
                  className="btn btn-primary btn-sm ml-3"
                  onClick={handleSubmit}
                  disabled={batching}
                >
                  {batching ? <i className="fa fa-spinner fa-spin" /> : <Trans>Apply</Trans>}
                </button>
              </>
            )}
          </div>
        </div>

        {!loaded ? (
          <LoadingTable />
        ) : (
          <ReactTable
            data={items}
            columns={columns}
            pages={pages}
            pageSize={limit}
            manual
            onFetchData={(state) => {
              filter$.next(
                getFilter(state, {
                  name: 'searchName',
                  'company.name': 'companyIds',
                  'assetLocation.name': 'assetLocationIds',
                  'assetType.name': 'assetTypeIds',
                  'product.title': 'productIds',
                  tags: 'tagIds',
                }),
              );

              let orderDir = OrderDirection.Desc;
              if (state.sorted[0]) {
                orderDir = state.sorted[0].desc ? OrderDirection.Desc : OrderDirection.Asc;
              }

              setOffset(state.page * state.pageSize);
              setOrderBy(
                getOrder(
                  state,
                  {
                    'company.name': 'companyName',
                    'product.title': 'primaryContactFullName',
                    uptime365: 'uptimeStatus',
                  },
                  AssetsQueryOrderBy.uptimeStatus,
                ),
              );
              setOrderDirection(orderDir);
            }}
            onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
            getTrProps={getRow}
            SubComponent={(rowInfo: RowInfo) => <AssetDashboard model={rowInfo.original} />}
            freezeWhenExpanded
            {...tableOptions()}
          />
        )}
      </div>
    </div>
  );
};

export default ServicesAssetPage;
