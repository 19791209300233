import { Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import FieldErrorMessage from '../form/FieldErrorMessage';
import ImageUploader from '../form/ImageUploader';

type Props = {};

const PersonCompanyDetailForm: FunctionComponent<Props> = () => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="form-group">
          <label>
            <Trans>Company Logo</Trans>
          </label>

          <div className="d-flex align-items-center">
            <Field name="logo.url">
              {({ field, form }: { field: any; form: FormikProps<any> }) => (
                <ImageUploader onUpload={(id) => form.setFieldValue('logoId', id)} image={field.value} />
              )}
            </Field>

            <label className="ml-2 flex-grow-1">
              <i className="icon-details" />
              <Trans>Logo size should be not bigger than 5 Mb</Trans>
            </label>
          </div>
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Number</Trans>
          </label>

          <Field name="idNumber">
            {({ field }: { field: any }) => <input type="text" className="form-control" value={field.value} disabled />}
          </Field>
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Company name</Trans>
          </label>

          <Field name="name">
            {({ field }: { field: any }) => <input type="text" className="form-control" value={field.value} disabled />}
          </Field>
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Size</Trans>
          </label>
          <Field type="number" className="form-control" name="size" />

          <FieldErrorMessage name="size" />
        </div>
      </div>

      <div className="col-md-6">
        <div className="form-group">
          <label>
            <Trans>Website</Trans>
          </label>

          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <Field type="url" className="form-control" name="website" />
            </div>

            <Field name="website">
              {({ field }: { field: any }) => {
                if (!field.value) return '';

                return (
                  <>
                    <a data-tip data-for="website" href={field.value} target="_blank" className="ml-2">
                      <i className="icon-preview" />
                    </a>
                    <ReactTooltip id="website">
                      <Trans>View</Trans>
                    </ReactTooltip>
                  </>
                );
              }}
            </Field>
          </div>

          <FieldErrorMessage name="website" />
        </div>
      </div>
    </div>
  );
};

export default PersonCompanyDetailForm;
