import gql from 'graphql-tag';
import { USER } from './user';

export const GET_STATS_ASSETS_BY_STATUS = gql`
  query statsAssetsByStatus {
    statsAssetsByStatus {
      status
      count
    }
  }
`;

export const GET_STATS_TICKET_REACTION = gql`
  query statsTicketReaction(
    $query1: StatsTicketReactionQuery!
    $query2: StatsTicketReactionQuery!
    $query3: StatsTicketReactionQuery!
  ) {
    stats1: statsTicketReaction(query: $query1) {
      ticketTime
      duration
    }
    stats2: statsTicketReaction(query: $query2) {
      ticketTime
      duration
    }
    stats3: statsTicketReaction(query: $query3) {
      ticketTime
      duration
    }
  }
`;

export const STAT_TICKET = gql`
  fragment StatTicket on StatTicket {
    time
    openCount
    closeCount
    responseCount
  }
`;

export const GET_STATS_TICKET = gql`
  query statsTicket($query: StatsTicketReactionQuery!) {
    statsTicket(query: $query) {
      ...StatTicket
    }
  }
  ${STAT_TICKET}
`;

export const GET_AVG_TICKET_RESPONSE_TIME_MS = gql`
  query avgTicketResponseTimeMs($filter: DateTimeRangeFilter!) {
    avgTicketResponseTimeMs(filter: $filter)
  }
`;

export const GET_AVG_TICKET_CLOSE_TIME_MS = gql`
  query avgTicketCloseTimeMs($filter: DateTimeRangeFilter!) {
    avgTicketCloseTimeMs(filter: $filter)
  }
`;

export const GET_FASTEST_RESPONSE_USERS = gql`
  query fastestResponseUsers($filter: DateTimeRangeFilter!, $limit: Int!) {
    fastestResponseUsers(filter: $filter, limit: $limit) {
      ...User
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
  }
  ${USER}
`;

export const GET_TOP_RESPONSE_USERS = gql`
  query topResponseUsers($filter: DateTimeRangeFilter!, $limit: Int!) {
    topResponseUsers(filter: $filter, limit: $limit) {
      ...User
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
  }
  ${USER}
`;

export const GET_FASTEST_CLOSE_USERS = gql`
  query fastestCloseUsers($filter: DateTimeRangeFilter!, $limit: Int!) {
    fastestCloseUsers(filter: $filter, limit: $limit) {
      ...User
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
  }
  ${USER}
`;
