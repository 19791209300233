import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import React, { FC, useState } from 'react';
import { GET_COMPANY_LOGS } from '../../schema/company';
import { getCompanyLogs, getCompanyLogsVariables } from '../../schema/types/getCompanyLogs';
import { Log } from '../../schema/types/Log';
import { useVariables } from '../../utils/hooks';
import HistoryItem from '../common/HistoryItem';
import LoadingQuery from '../common/LoadingQuery';
import ErrorQuery from '../form/ErrorQuery';

type Props = {
  companyId: string;
  includeSystemMessages: boolean;
};

const CompanyLogs: FC<Props> = ({ companyId, includeSystemMessages }) => {
  const [fetchingMore, setFetchingMore] = useState(false);
  const { limit, offset } = useVariables();

  const { data, loading, error, fetchMore } = useQuery<getCompanyLogs, getCompanyLogsVariables>(GET_COMPANY_LOGS, {
    variables: {
      id: companyId,
      query: {
        limit,
        offset,
        includeSystemMessages,
      },
    },
    fetchPolicy: 'cache-first',
  });

  const handleMore = async () => {
    setFetchingMore(true);

    await fetchMore({
      variables: {
        id: companyId,
        query: {
          limit,
          offset: data?.company.logs.items.length,
          includeSystemMessages,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          ...prev,
          company: {
            ...prev.company,
            logs: {
              ...prev.company.logs,
              items: [...prev.company.logs.items, ...fetchMoreResult.company.logs.items],
            },
          },
        };
      },
    });

    setFetchingMore(false);
  };

  const hasMore = data && data.company && data.company.logs.items.length < data.company.logs.total;

  if (error) return <ErrorQuery error={error} />;
  if (loading) return <LoadingQuery />;

  return (
    <div>
      {data && data.company.logs.items.length > 0 ? (
        <>
          {data.company.logs.items.map((el: Log) => (
            <HistoryItem key={el.id} date={el.createdAt} creator={el.creator} content={el.content} tags={el.tags} />
          ))}
        </>
      ) : (
        <div className="text-center p-2">
          <Trans>No logs</Trans>
        </div>
      )}

      {hasMore && (
        <div className="text-right mt-3 form-group">
          <button type="button" className="btn btn-link" disabled={loading || fetchingMore} onClick={handleMore}>
            {loading || fetchingMore ? <i className="fa fa-spinner fa-spin" /> : <Trans>Load more</Trans>}
          </button>
        </div>
      )}
    </div>
  );
};

export default CompanyLogs;
