import { Trans } from '@lingui/macro';
import React, { Component } from 'react';

class NotFoundPage extends Component {
  render() {
    return (
      <h1 className="card card-body text-center">
        <Trans>404 Not Found</Trans>
      </h1>
    );
  }
}

export default NotFoundPage;
