import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import ReactTable, { CellInfo } from 'react-table';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import LoadingTable from '../components/common/LoadingTable';
import MainTitle from '../components/common/MainTitle';
import TableActions from '../components/common/TableActions';
import ButtonArchive from '../components/form/ButtonArchive';
import ButtonEdit from '../components/form/ButtonEdit';
import OfferTemplateCreateModal from '../components/offer/OfferTemplateCreateModal';
import { ARCHIVE_OFFER, GET_OFFERS, UNARCHIVE_OFFER } from '../schema/offer';
import { archiveOffer, archiveOfferVariables } from '../schema/types/archiveOffer';
import { getOffers, getOffersVariables } from '../schema/types/getOffers';
import { unarchiveOffer, unarchiveOfferVariables } from '../schema/types/unarchiveOffer';
import { dateOptions, tableOptions } from '../utils/const';
import ErrorQuery from '../components/form/ErrorQuery';
import { cloneDeep } from 'lodash';
import { DataProxy } from '@apollo/client/cache';
import { useVariables } from '../utils/hooks';
import DateFormat from '../components/common/DateFormat';

type Props = {};

const OfferTemplatePage: FunctionComponent<Props> = () => {
  const [loaded, setLoaded] = useState(false);
  const {
    search$,
    search,
    filter,
    archived,
    setArchived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    orderDirection,
  } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
        template: true,
      },
    },
  };

  const updateQuery = (proxy: DataProxy, id: any) => {
    const data: any = cloneDeep(proxy.readQuery({ query: GET_OFFERS, variables }));
    data.offers.items = data.offers.items.filter((el: any) => el.id !== id);
    proxy.writeQuery({ query: GET_OFFERS, variables, data });
  };

  const [mutateArchive] = useMutation<archiveOffer, archiveOfferVariables>(ARCHIVE_OFFER, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.archiveOffer.offer.id);
    },
  });
  const [mutateUnarchive] = useMutation<unarchiveOffer, unarchiveOfferVariables>(UNARCHIVE_OFFER, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.unarchiveOffer.offer.id);
    },
  });

  const handleArchive = useCallback(
    (id: any) => () => {
      if (archived) {
        mutateUnarchive({
          variables: {
            id,
          },
          optimisticResponse: {
            unarchiveOffer: {
              offer: {
                id,
                __typename: 'Offer',
              },
              __typename: 'MutateOfferResponse',
            },
          },
        });
      } else {
        mutateArchive({
          variables: {
            id,
          },
          optimisticResponse: {
            archiveOffer: {
              offer: {
                id,
                __typename: 'Offer',
              },
              __typename: 'MutateOfferResponse',
            },
          },
        });
      }
    },
    [archived, mutateArchive, mutateUnarchive],
  );

  const [showAdd, setShowAdd] = useState(false);
  const toggleAdd = useCallback(() => setShowAdd((prevState) => !prevState), [setShowAdd]);

  const { data, previousData, error, loading } = useQuery<getOffers, getOffersVariables>(GET_OFFERS, {
    variables,
  });

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.offers.items ?? previousData?.offers.items ?? [];
  const pages = data && data.offers ? Math.ceil(data.offers.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Title`),
      accessor: 'title',
      Cell: (cell: CellInfo) => (
        <NavLink to={`/crm/offer/${cell.original.id}`} target="_blank">
          {cell.value}
        </NavLink>
      ),
    },
    {
      Header: i18n._(t`Date`),
      accessor: 'createdAt',
      width: 150,
      className: 'text-center',
      Cell: (cell: CellInfo) => <DateFormat value={new Date(cell.value)} format={dateOptions} />,
    },
    {
      Header: i18n._(t`Actions`),
      width: 90,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <>
          <NavLink to={`/crm/offer/${cell.original.id}`}>
            <ButtonEdit />
          </NavLink>
          <ButtonArchive onClick={handleArchive(cell.original.id)} />
        </>
      ),
    },
  ];

  return (
    <div className="card-body">
      <div className="table-actions">
        <NavLink to="/crm/offer" data-tip data-for="toggle-template" className="link-action">
          O
        </NavLink>

        <ReactTooltip id="toggle-template">
          <Trans>Offers</Trans>
        </ReactTooltip>

        <div className="breadcrumbs-area">
          <MainTitle title={i18n._(t`Templates`)} tips="CRM/Offers" />
        </div>

        <TableActions onSearch={(e) => search$.next(e.target.value)} archived={archived} onArchive={setArchived} />

        <button type="button" className="btn btn-sm btn-primary" onClick={toggleAdd}>
          <i className="icon-add" />
          <span>
            <Trans>Add new offer template</Trans>
          </span>
        </button>
      </div>

      {!loaded ? (
        <LoadingTable />
      ) : (
        <ReactTable
          data={items}
          columns={columns}
          pages={pages}
          pageSize={limit}
          manual
          onFetchData={(state) => {
            setOffset(state.page * state.pageSize);
          }}
          onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
          {...tableOptions()}
        />
      )}

      <OfferTemplateCreateModal show={showAdd} onClose={toggleAdd} />
    </div>
  );
};

export default OfferTemplatePage;
