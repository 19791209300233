import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { GET_COMPANIES_FOR_TIME_TRACKER } from '../../schema/company';
import { CompanyStatus } from '../../schema/graphql-global-types';
import { GET_TIME_TRACKER_PROJECTS } from '../../schema/timeTracker';
import { getTimeTrackerCompanies, getTimeTrackerCompaniesVariables } from '../../schema/types/getTimeTrackerCompanies';
import { getTimeTrackerProjects, getTimeTrackerProjectsVariables } from '../../schema/types/getTimeTrackerProjects';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {
  value?: any;
  onSubmit: (data: any) => void;
};

const TimeTrackerCompanyProjectField: FunctionComponent<Props> = ({ value, onSubmit }) => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const { data: companies, loading: loadingCompanies } = useQuery<
    getTimeTrackerCompanies,
    getTimeTrackerCompaniesVariables
  >(GET_COMPANIES_FOR_TIME_TRACKER, {
    variables: {
      query: {
        limit,
        offset,
        orderBy,
        orderDirection,
        filter: {
          search,
          archived,
          ...filter,
          status: [CompanyStatus.Customer],
        },
      },
    },
    fetchPolicy: 'cache-first',
  });

  const { data: projects, loading: loadingProjects } = useQuery<
    getTimeTrackerProjects,
    getTimeTrackerProjectsVariables
  >(GET_TIME_TRACKER_PROJECTS, {
    variables: {
      query: {
        limit,
        offset,
        orderBy,
        orderDirection,
        filter: {
          search,
          archived,
          ...filter,
        },
      },
    },
    fetchPolicy: 'cache-first',
  });

  const handleLoad = (value: string) => search$.next(value);

  const model = value
    ? {
        value: value,
        label: value.name || value.title,
      }
    : null;

  let options: any[] = [];

  if (projects?.timeTrackerProjects.items) {
    options = options.concat(
      projects.timeTrackerProjects.items.map((el) => ({
        value: {
          ...el,
          type: 'project',
        },
        label: `${el.customer.name} - ${el.title}`,
      })),
    );
  }

  if (search.length && companies?.companies.items) {
    companies.companies.items.forEach((company) => {
      options = options.concat(
        company.timeTrackerProjects.items
          .filter((el) => !options.find((opt) => opt.value.id === el.id))
          .map((el) => ({
            value: {
              ...el,
              type: 'project',
            },
            label: `${company.name} - ${el.title}`,
          })),
      );
    });
  }

  if (companies?.companies.items) {
    options = options.concat(
      companies.companies.items.map((el) => ({
        value: {
          ...el,
          type: 'company',
        },
        label: el.name,
      })),
    );
  }

  if ((!companies && !loadingCompanies) || (!projects && !loadingProjects)) return <div />;

  return (
    <Select
      value={model}
      options={options}
      isLoading={loadingCompanies || loadingProjects}
      onChange={onSubmit}
      onInputChange={handleLoad}
      placeholder={i18n._(t`Search...`)}
      {...selectOptions}
    />
  );
};

export default TimeTrackerCompanyProjectField;
