import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { GET_PERSONS_FOR_OFFER } from '../../schema/person';
import { getPersons, getPersonsVariables } from '../../schema/types/getPersons';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {
  companyId?: string | null;
};

const OfferPersonForm: FunctionComponent<Props> = ({ companyId }) => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
        companyIds: companyId ? [companyId] : undefined,
      },
    },
  };

  const { data, loading } = useQuery<getPersons, getPersonsVariables>(GET_PERSONS_FOR_OFFER, {
    variables,
  });

  if (!data && !loading) return <div />;

  return (
    <Field name="firstName">
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (value: any) => {
          const person: any = value.value;

          form.setFieldValue('firstName', person.firstName);
          form.setFieldValue('lastName', person.lastName);
          form.setFieldValue('function', person.function);
          form.setFieldValue('email', person.email);
        };

        const handleLoad = (value: string) => search$.next(value);

        const options = loading
          ? []
          : data!.persons.items.map((el) => ({
              value: el,
              label: el.fullName,
            }));

        return (
          <Select
            options={options}
            isLoading={loading}
            onChange={handleChange}
            onInputChange={handleLoad}
            placeholder={i18n._(t`Choose Person`)}
            {...selectOptions}
          />
        );
      }}
    </Field>
  );
};

export default OfferPersonForm;
