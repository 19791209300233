import { Trans } from '@lingui/macro';
import { useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames';
import { pick, isEqual } from 'lodash';
import { Formik } from 'formik';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import * as yup from 'yup';
import LoadingForm from '../components/common/LoadingForm';
import OfferUpdateForm from '../components/offer/OfferUpdateForm';
import ErrorQuery from '../components/form/ErrorQuery';
import { OfferItemType } from '../schema/graphql-global-types';
import { offerKeys, GET_OFFER, UPDATE_OFFER, validateOfferForm } from '../schema/offer';
import { getOffer, getOfferVariables } from '../schema/types/getOffer';
import { OfferItem } from '../schema/types/OfferItem';
import { OfferProductItem } from '../schema/types/OfferProductItem';
import { OfferProjectItem } from '../schema/types/OfferProjectItem';
import { updateOffer, updateOfferVariables } from '../schema/types/updateOffer';
import { autoSaveInterval, DefaultRouteParams } from '../utils/const';
import { toastErrorQuery } from '../utils/error';
import NotFoundPage from './NotFoundPage';

const extract = (model: any) => {
  const result = pick(model, offerKeys);

  result.items = result.items.map((el: OfferItem) => ({
    ...pick(el, ['type', 'index', 'title', 'content', 'templateElementId']),
    discountPercent: Number(el.discountPercent),
    productItems:
      el.type === OfferItemType.Product
        ? el.productItems!.map((item: OfferProductItem) => {
            const result = pick(item, ['productId', 'title', 'price', 'pieces', 'discountPercent']);

            result.price = Number(result.price);
            result.pieces = Number(result.pieces);
            result.discountPercent = Number(result.discountPercent);

            return result;
          })
        : [],
    projectItems:
      el.type === OfferItemType.Project
        ? el.projectItems!.map((item: OfferProjectItem) =>
            pick(item, ['userId', 'description', 'startDate', 'endDate', 'status']),
          )
        : [],
  }));

  delete result.template;

  return result;
};

const OfferUpdatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ match }) => {
  const [saved, setSaved] = useState(false);
  const [prevModel, setPrevModel] = useState<any>(null);
  const saveInterval = useRef<any>();

  useEffect(() => {
    return () => {
      if (saveInterval.current) {
        clearInterval(saveInterval.current);
      }
    };
  }, []);

  const [mutate] = useMutation<updateOffer, updateOfferVariables>(UPDATE_OFFER);

  const { data, loading, error } = useQuery<getOffer, getOfferVariables>(GET_OFFER, {
    variables: { query: match.params.id },
  });

  if (!data && loading) return <LoadingForm />;
  if (error) return <ErrorQuery error={error} />;
  if (!data) return <NotFoundPage />;

  if (!prevModel) {
    setPrevModel(extract(data.offer));
  }

  return (
    <div className="offer-form-page">
      <Formik
        initialValues={data.offer}
        validationSchema={yup.object().shape(validateOfferForm)}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const payload = extract(values);

            const result: any = await mutate({
              variables: {
                id: match.params.id,
                payload,
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              setSaved(true);
              setPrevModel(payload);

              setTimeout(() => {
                setSaved(false);
              }, 2000);
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting, submitForm, values }) => {
          if (saveInterval.current) {
            clearInterval(saveInterval.current);
          }

          saveInterval.current = setInterval(() => {
            if (!isEqual(extract(values), prevModel)) {
              submitForm();
            }
          }, autoSaveInterval);

          return <OfferUpdateForm loading={isSubmitting} submitForm={submitForm} />;
        }}
      </Formik>

      <div className={classNames('saved', { active: saved })}>
        <Trans>Saved!</Trans>
      </div>
    </div>
  );
};

export default withRouter(OfferUpdatePage);
