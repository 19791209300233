import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import gpt from '../asset/gpt.svg';
import send from '../asset/send.svg';

type Props = {
  value: string;
  onChange: (val: string) => void;
  onAnswerConversation: (event: any) => void;
  onAnswerAuto: () => void;
  loadingAnswerConversation: boolean;
  loadingAnswerAuto: boolean;
  type?: 'ticket' | 'documentation';
};

const ChatGPTForm: FunctionComponent<Props> = ({
  value,
  onChange,
  onAnswerConversation,
  onAnswerAuto,
  loadingAnswerConversation,
  loadingAnswerAuto,
  type,
}) => {
  return (
    <div className="chat-gpt-form">
      <div className="inner">
        <div className="input">
          <input
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && onAnswerConversation(e)}
            placeholder={i18n._(t`Write a prompt to let ChatGPT help you...`)}
          />

          <button type="button" disabled={!value || loadingAnswerConversation} onClick={onAnswerConversation}>
            {loadingAnswerConversation ? <i className="fa fa-spinner fa-spin" /> : <img src={send} alt="" />}
          </button>
        </div>

        <button type="button" className="auto" onClick={onAnswerAuto} disabled={loadingAnswerAuto}>
          {loadingAnswerAuto ? <Trans>Loading...</Trans> : <Trans>Run automatically</Trans>}

          <img src={gpt} alt="" />
        </button>
      </div>

      {type === 'ticket' ? (
        <div className="hint">
          <i className="icon-info" /> You can use the manual prompt or click the green “auto” button on the right, to
          let ChatGPT analyse all content in the ticket and draft a response automatically
        </div>
      ) : (
        <div className="hint">
          <i className="icon-info" /> You can use the manual prompt or click the green “auto” button on the right, to
          let ChatGPT analyse the title and draft a response automatically
        </div>
      )}
    </div>
  );
};

export default ChatGPTForm;
