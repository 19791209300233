import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { GET_HELP_DOCUMENTS } from '../../schema/helpDocument';
import { useLanguage } from '../../schema/locale';
import { getHelpDocuments, getHelpDocumentsVariables } from '../../schema/types/getHelpDocuments';

type Props = {
  title: string;
  tips?: string;
};

const MainTitle: FC<Props> = ({ title, tips }) => {
  const language = useLanguage();
  const { data: helps } = useQuery<getHelpDocuments, getHelpDocumentsVariables>(GET_HELP_DOCUMENTS, {
    variables: {
      language: language.toUpperCase(),
    },
    fetchPolicy: 'cache-first',
  });

  const tooltip = tips ? helps?.helpDocuments.find((el) => el.name === tips) : null;

  return (
    <h3 className="main-title">
      {title}

      {tooltip?.shortText && (
        <>
          <span data-tip data-for="help" className="button text-xs">
            <i className="icon-info m-2" />
          </span>
          <ReactTooltip id="help" place="bottom" effect="solid" delayHide={500} clickable>
            {tooltip.shortText}

            {tooltip.documentUrl && (
              <>
                <br />
                <br />
                <a href={tooltip.documentUrl} target="_blank">
                  {tooltip.documentUrl}
                </a>
              </>
            )}
          </ReactTooltip>
        </>
      )}
    </h3>
  );
};

export default MainTitle;
