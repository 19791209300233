import { Field, FormikProps } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import { cloneDeep } from 'lodash';
import React, { FunctionComponent } from 'react';
import { TimeTrackerProjectSpecialRate } from '../../schema/types/TimeTrackerProjectSpecialRate';
import CurrencyDisplay from '../common/CurrencyDisplay';
import ButtonDelete from '../form/ButtonDelete';
import TimeTrackerRateField from './TimeTrackerRateField';

type Props = {
  name: string;
};

const TimeTrackerRateForm: FunctionComponent<Props> = ({ name }) => {
  return (
    <Field name={name}>
      {({ field, form }: { field: FieldInputProps<TimeTrackerProjectSpecialRate[]>; form: FormikProps<any> }) => {
        const handleChange = (value: any) => {
          const model = cloneDeep(field.value);
          model.push({
            ...value,
            productId: value.product.id,
          });
          form.setFieldValue(name, model);
        };

        const handleRemove = (index: number) => () => {
          const model = cloneDeep(field.value);
          model.splice(index, 1);
          form.setFieldValue(name, model);
        };

        return (
          <div className="time-tracker-rates">
            <div className="search">
              <TimeTrackerRateField onSubmit={handleChange} />
            </div>

            {field.value.map((el, id) => (
              <div className="item" key={id}>
                <div className="row">
                  <div className="col-6">{el.product.title}</div>

                  <div className="col-4">
                    <CurrencyDisplay value={el.amount} />
                  </div>

                  <div className="col">
                    <ButtonDelete onClick={handleRemove(id)} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      }}
    </Field>
  );
};

export default TimeTrackerRateForm;
