import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useState } from 'react';
import LoadingForm from '../components/common/LoadingForm';
import MainTitle from '../components/common/MainTitle';
import HelpDocumentForm from '../components/configuration/HelpDocumentForm';
import { GET_HELP_DOCUMENTS } from '../schema/helpDocument';
import { useLanguage } from '../schema/locale';
import { getHelpDocuments, getHelpDocumentsVariables } from '../schema/types/getHelpDocuments';

const HelpConfigurationPage: FunctionComponent = () => {
  const language = useLanguage();
  const [lang, setLang] = useState(language);

  const { data, loading } = useQuery<getHelpDocuments, getHelpDocumentsVariables>(GET_HELP_DOCUMENTS, {
    variables: {
      language: lang.toUpperCase(),
    },
    fetchPolicy: 'cache-first',
  });

  if (loading && !data) return <LoadingForm />;

  return (
    <div className="help-config-page">
      <div className="breadcrumbs-area">
        <MainTitle title={i18n._(t`Help Configuration`)} tips="Settings/Help Config" />
      </div>

      <div className="card">
        <div className="row form-group align-items-center heading">
          <div className="col-md-7">
            <div className="block-title">
              <Trans>Country Configuration</Trans>
            </div>
          </div>

          <div className="col-5 col-md-2">
            <label className="form-label">
              <Trans>Language</Trans>
            </label>
          </div>

          <div className="col-7 col-md-3">
            <select className="form-control" defaultValue={lang} onChange={(e) => setLang(e.target.value)}>
              <option value="en">{i18n._(t`English`)}</option>
              <option value="de">{i18n._(t`Germany`)}</option>
            </select>
          </div>
        </div>

        <div className="custom-table mobile-no-table help-config-table">
          <div className="custom-table-header columns">
            <div className="column title" />

            <div className="column text">
              <Trans>Tip</Trans>
            </div>

            <div className="column url">
              <Trans>Document URL</Trans>
            </div>
          </div>

          {data?.helpDocuments.map((el) => (
            <HelpDocumentForm key={el.id} model={el} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HelpConfigurationPage;
