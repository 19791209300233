import React, { FunctionComponent } from 'react';
import NumberFormat from './NumberFormat';

type Props = {
  value: number;
};

const NumberDisplay: FunctionComponent<Props> = ({ value }) => {
  return (
    <NumberFormat
      value={value}
      format={{
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }}
    />
  );
};

export default NumberDisplay;
