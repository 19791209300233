import { DataProxy } from '@apollo/client/cache';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { cloneDeep } from 'lodash';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import ReactTable, { CellInfo } from 'react-table';
import DateFormat from '../components/common/DateFormat';
import LoadingTable from '../components/common/LoadingTable';
import MainTitle from '../components/common/MainTitle';
import TableActions from '../components/common/TableActions';
import UserAvatarName from '../components/common/UserAvatarName';
import ButtonArchive from '../components/form/ButtonArchive';
import ButtonEdit from '../components/form/ButtonEdit';
import ErrorQuery from '../components/form/ErrorQuery';
import {
  ARCHIVE_DOCUMENT_TEMPLATE_ELEMENT,
  GET_DOCUMENT_TEMPLATE_ELEMENTS,
  UNARCHIVE_DOCUMENT_TEMPLATE_ELEMENT,
} from '../schema/documentTemplateElement';
import {
  archiveDocumentTemplateElement,
  archiveDocumentTemplateElementVariables,
} from '../schema/types/archiveDocumentTemplateElement';
import {
  getDocumentTemplateElements,
  getDocumentTemplateElementsVariables,
} from '../schema/types/getDocumentTemplateElements';
import {
  unarchiveDocumentTemplateElement,
  unarchiveDocumentTemplateElementVariables,
} from '../schema/types/unarchiveDocumentTemplateElement';
import { dateTimeOptions, defaultQuery, tableOptions } from '../utils/const';
import { useVariables } from '../utils/hooks';

type Props = {};

const DocumentationElementPage: FunctionComponent<Props> = () => {
  const [loaded, setLoaded] = useState(false);
  const { search$, search, archived, setArchived, limit, setLimit, offset, setOffset } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy: defaultQuery.query.orderBy,
      orderDirection: defaultQuery.query.orderDirection,
      filter: {
        search,
        archived,
      },
    },
  };

  const updateQuery = (proxy: DataProxy, id: any) => {
    const data: any = cloneDeep(proxy.readQuery({ query: GET_DOCUMENT_TEMPLATE_ELEMENTS, variables }));
    data.documentTemplateElements.items = data.documentTemplateElements.items.filter((el: any) => el.id !== id);
    proxy.writeQuery({
      query: GET_DOCUMENT_TEMPLATE_ELEMENTS,
      variables,
      data,
    });
  };

  const [mutateArchive] = useMutation<archiveDocumentTemplateElement, archiveDocumentTemplateElementVariables>(
    ARCHIVE_DOCUMENT_TEMPLATE_ELEMENT,
    {
      update: (proxy, { data }) => {
        updateQuery(proxy, data!.archiveDocumentTemplateElement.documentTemplateElement.id);
      },
    },
  );
  const [mutateUnarchive] = useMutation<unarchiveDocumentTemplateElement, unarchiveDocumentTemplateElementVariables>(
    UNARCHIVE_DOCUMENT_TEMPLATE_ELEMENT,
    {
      update: (proxy, { data }) => {
        updateQuery(proxy, data!.unarchiveDocumentTemplateElement.documentTemplateElement.id);
      },
    },
  );

  const handleArchive = useCallback(
    (id: any) => () => {
      if (archived) {
        mutateUnarchive({
          variables: {
            id,
          },
          optimisticResponse: {
            unarchiveDocumentTemplateElement: {
              documentTemplateElement: {
                id,
                __typename: 'DocumentTemplateElement',
              },
              __typename: 'MutateDocumentTemplateElementResponse',
            },
          },
        });
      } else {
        mutateArchive({
          variables: {
            id,
          },
          optimisticResponse: {
            archiveDocumentTemplateElement: {
              documentTemplateElement: {
                id,
                __typename: 'DocumentTemplateElement',
              },
              __typename: 'MutateDocumentTemplateElementResponse',
            },
          },
        });
      }
    },
    [archived, mutateArchive, mutateUnarchive],
  );

  const { data, previousData, loading, error } = useQuery<
    getDocumentTemplateElements,
    getDocumentTemplateElementsVariables
  >(GET_DOCUMENT_TEMPLATE_ELEMENTS, {
    variables,
  });

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.documentTemplateElements.items ?? previousData?.documentTemplateElements.items ?? [];
  const pages = data && data.documentTemplateElements ? Math.ceil(data.documentTemplateElements.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Element Template Title`),
      accessor: 'title',
      Cell: (cell: CellInfo) => <NavLink to={`/documentation/element/${cell.original.id}`}>{cell.value}</NavLink>,
    },
    {
      Header: i18n._(t`Editor`),
      accessor: 'editor.fullName',
      Cell: (cell: CellInfo) => (
        <NavLink to={`/settings/user/${cell.original.editor.id}`}>
          <UserAvatarName user={cell.original.editor} />
        </NavLink>
      ),
    },
    {
      Header: i18n._(t`Last Edit`),
      accessor: 'updatedAt',
      Cell: (cell: CellInfo) => <DateFormat value={new Date(cell.value)} format={dateTimeOptions} />,
    },
    {
      Header: i18n._(t`Actions`),
      width: 90,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <>
          <NavLink to={`/documentation/element/${cell.original.id}`}>
            <ButtonEdit />
          </NavLink>
          <ButtonArchive onClick={handleArchive(cell.original.id)} />
        </>
      ),
    },
  ];

  return (
    <div className="card-body">
      <div className="table-actions">
        <div className="breadcrumbs-area">
          <MainTitle title={i18n._(t`Centralized Content`)} tips="Documentation/Templates" />
        </div>

        <TableActions onSearch={(e) => search$.next(e.target.value)} archived={archived} onArchive={setArchived} />

        <NavLink to="/documentation/element/add" type="button" className="btn btn-sm btn-primary">
          <i className="icon-add" />
          <span>
            <Trans>Add centralized content</Trans>
          </span>
        </NavLink>
      </div>

      {!loaded ? (
        <LoadingTable />
      ) : (
        <ReactTable
          data={items}
          columns={columns}
          pages={pages}
          pageSize={limit}
          manual
          onFetchData={(state) => {
            setOffset(state.page * state.pageSize);
          }}
          onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
          {...tableOptions()}
        />
      )}
    </div>
  );
};

export default DocumentationElementPage;
