import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import React, { FunctionComponent } from 'react';
import { monthNames } from '../../utils/const';
import DateFormat from '../common/DateFormat';
import FieldCheckbox from '../form/FieldCheckbox';
import FieldDatePicker from '../form/FieldDatepicker';
import FieldErrorMessage from '../form/FieldErrorMessage';
import FieldRadio from '../form/FieldRadio';
import ContractCustomerForm from './ContractCustomerForm';

type Props = {};

const ContractDetailForm: FunctionComponent<Props> = () => {
  return (
    <div className="contract-info-form row">
      <div className="col-xxl-3 col-md-6">
        <div className="form-group">
          <label>
            <Trans>Customer</Trans>
          </label>

          <ContractCustomerForm />

          <FieldErrorMessage name="customerId" />
        </div>

        <div className="row">
          <div className="col-6 form-group">
            <label>
              <Trans>Lengths (Months)</Trans>
            </label>

            <Field type="number" className="form-control" name="contractLength" />

            <FieldErrorMessage name="contractLength" />
          </div>

          <div className="col-6 form-group">
            <label>
              <Trans>Notice Period (Months)</Trans>
            </label>

            <Field type="number" className="form-control" name="cancelPeriod" />

            <FieldErrorMessage name="cancelPeriod" />
          </div>
        </div>
      </div>

      <div className="col-xxl-3 col-md-6">
        <div className="form-group">
          <label>
            <Trans>Contract Name</Trans>
          </label>

          <Field type="text" className="form-control" name="name" />

          <FieldErrorMessage name="name" />
        </div>

        <div className="row">
          <Field name="contractEnds">
            {({ field }: { field: FieldInputProps<string> }) => {
              if (!field.value) return null;

              return (
                <div className="col-6 form-group">
                  <label>
                    <Trans>Contract Ends</Trans>
                  </label>
                  <br />
                  <DateFormat value={field.value} />
                </div>
              );
            }}
          </Field>

          <Field name="cancellationDeadline">
            {({ field }: { field: FieldInputProps<string> }) => {
              if (!field.value) return null;

              return (
                <div className="col-6 form-group">
                  <label>
                    <Trans>Cancellation Deadline</Trans>
                  </label>
                  <br />
                  <DateFormat value={field.value} />
                </div>
              );
            }}
          </Field>
        </div>
      </div>

      <div className="col-xxl-3 col-md-6">
        <div className="form-group">
          <label>
            <Trans>Contract Start</Trans>
          </label>

          <FieldDatePicker name="startedAt" />
        </div>

        <div className="form-group">
          <label>
            <Trans>Auto-renews</Trans>
          </label>

          <FieldCheckbox name="isAutoRenew" label={i18n._(t`Enable Auto-renew`)} />
        </div>
      </div>

      <div className="col-xxl-3 col-md-6">
        <label className="form-label">&nbsp;</label>

        <div className="form-group d-flex align-items-center">
          <label className="my-3">
            <Trans>Contract Cycle</Trans>
          </label>

          <div className="ml-3">
            <FieldRadio name="cycle" label={i18n._(t`Yearly`)} value="Yearly" />
          </div>

          <div className="ml-3">
            <FieldRadio name="cycle" label={i18n._(t`Monthly`)} value="Monthly" />
          </div>
        </div>

        <Field name="cycle">
          {({ field }: { field: any }) => {
            if (field.value !== 'Yearly') return '';

            return (
              <div className="row form-group">
                <label className="col-sm-4">
                  <Trans>Cycle Month</Trans>
                </label>
                <div className="col-sm-8 input-group">
                  <Field component="select" className="form-control" name="yearlyInterval">
                    {Array.from(Array(12), (el, index) => (
                      <option value={index} key={index}>
                        {i18n._(t`First of`)} {monthNames()[index]}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
            );
          }}
        </Field>
      </div>
    </div>
  );
};

export default ContractDetailForm;
