import { useQuery } from '@apollo/client';
import React, { FunctionComponent } from 'react';
import { GET_ASSET_TAGS } from '../../schema/tag';
import { getAssetTags, getAssetTagsVariables } from '../../schema/types/getAssetTags';
import { useVariables } from '../../utils/hooks';
import TagSelect from '../form/TagSelect';

type Props = {};

const AssetTagForm: FunctionComponent<Props> = () => {
  const { search$, search, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
      },
    },
  };

  const { data, loading } = useQuery<getAssetTags, getAssetTagsVariables>(GET_ASSET_TAGS, {
    variables,
  });

  if (!data && !loading) return <div />;

  return <TagSelect name="tagNames" search$={search$} loading={loading} data={data?.assetTags.items} />;
};

export default AssetTagForm;
