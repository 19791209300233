import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { ChangeEvent, FC } from 'react';
import ShowArchive from './ShowArchive';

type Props = {
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  archived: boolean;
  onArchive: (value: ((prevState: boolean) => boolean) | boolean) => void;
};

const TableActions: FC<Props> = ({ onSearch, archived, onArchive }) => {
  return (
    <>
      <div className="item search input-group">
        <div className="input-group-prepend">
          <div className="input-group-text">
            <i className="icon-search" />
          </div>
        </div>
        <input className="form-control" placeholder={i18n._(t`Search`)} onInput={onSearch} />
      </div>

      <div className="item archive">
        <ShowArchive model={archived} onChange={onArchive} />
      </div>
    </>
  );
};

export default TableActions;
