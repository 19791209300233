import gql from 'graphql-tag';

export const CHAT_GPT_ANSWER = gql`
  mutation chatGPTAnswer($query: ChatGPTAnswerInput!) {
    chatGPTAnswer(payload: $query)
  }
`;

export const CHAT_GPT_ANSWER_TICKET = gql`
  mutation chatGPTAnswerTicket($query: ChatGPTAnswerTicketInput!) {
    chatGPTAnswerTicket(payload: $query)
  }
`;

export const CHAT_GPT_MESSAGE = gql`
  fragment ChatGPTMessage on ChatGPTMessage {
    content
    role
  }
`;

export const CHAT_GPT_ANSWER_CONVERSATION = gql`
  mutation chatGPTAnswerConversation($query: ChatGPTAnswerConversationInput!) {
    chatGPTAnswerConversation(payload: $query) {
      ...ChatGPTMessage
    }
  }
  ${CHAT_GPT_MESSAGE}
`;
