import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Formik, Form } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { CREATE_M365_REPORT } from '../../schema/report';
import { createM365Report, createM365ReportVariables } from '../../schema/types/createM365Report';
import { toastErrorQuery } from '../../utils/error';
import ReportDownloadModal from './ReportDownloadModal';
import ReportMailModal from './ReportMailModal';

type Props = {
  effectiveDate: string;
};

const ReportM365FormOld: FunctionComponent<Props> = ({ effectiveDate }) => {
  const [url, setUrl] = useState('');

  const [showMail, setShowMail] = useState(false);
  const toggleShowMail = useCallback(() => setShowMail((prevState) => !prevState), [setShowMail]);

  const [showDownload, setShowDownload] = useState(false);
  const toggleShowDownload = useCallback(() => {
    setShowDownload((prevState) => {
      if (!prevState) {
        setUrl('');
      }

      return !prevState;
    });
  }, [setShowDownload, setUrl]);

  const [mutate] = useMutation<createM365Report, createM365ReportVariables>(CREATE_M365_REPORT);

  const now = new Date(effectiveDate);
  const date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())).toISOString();

  let sendToEmails: any = null;

  return (
    <Formik
      initialValues={{}}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const result: any = await mutate({
            variables: {
              payload: {
                generationMonth: date,
                sendToEmails,
              },
            },
          });

          if (result.errors) {
            toastErrorQuery(result.errors);
          } else {
            setUrl(result.data.createM365Report);
            toast.success(i18n._(t`Success!`));
          }
        } catch (e) {
          toastErrorQuery(e);
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting, submitForm }) => {
        const handleSubmitEmail = (email: string[]) => {
          sendToEmails = email;
          submitForm();
        };

        const handleSubmitDownload = () => {
          submitForm();
        };

        return (
          <Form className="panel">
            <div className="d-lg-flex align-items-center">
              <h2 className="block-title">
                <Trans>M365 Report (&lt; 2021)</Trans>
              </h2>

              <button type="button" className="btn btn-download" onClick={toggleShowDownload}>
                <i className="icon-download" />
                <Trans>Download</Trans>
              </button>

              <button type="button" className="btn btn-send" onClick={toggleShowMail}>
                <i className="icon-send-email" />
                <Trans>Send Email</Trans>
              </button>
            </div>

            <ReportMailModal
              show={showMail}
              loading={isSubmitting}
              onSubmit={handleSubmitEmail}
              onClose={toggleShowMail}
              multiple
            />

            <ReportDownloadModal
              show={showDownload}
              loading={isSubmitting}
              url={url}
              onSubmit={handleSubmitDownload}
              onClose={toggleShowDownload}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ReportM365FormOld;
