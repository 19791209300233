import { useMutation, useQuery } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import { addDays } from 'date-fns';
import { Field, Form, Formik, FormikProps } from 'formik';
import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import ReactTable, { CellInfo } from 'react-table';
import { toast } from 'react-toastify';
import { i18n } from '@lingui/core';
import { OrderDirection } from '../../schema/graphql-global-types';
import { useLanguage } from '../../schema/locale';
import { CREATE_HOLIDAY_SCHEDULE, DELETE_HOLIDAY_SCHEDULE, GET_HOLIDAY_SCHEDULES } from '../../schema/responder';
import { createHolidaySchedule, createHolidayScheduleVariables } from '../../schema/types/createHolidaySchedule';
import { deleteHolidaySchedule, deleteHolidayScheduleVariables } from '../../schema/types/deleteHolidaySchedule';
import { getHolidaySchedules, getHolidaySchedulesVariables } from '../../schema/types/getHolidaySchedules';
import { dateOptions, tableOptions } from '../../utils/const';
import { toastErrorQuery } from '../../utils/error';
import { useVariables } from '../../utils/hooks';
import DateFormat from '../common/DateFormat';
import LoadingTable from '../common/LoadingTable';
import ButtonArchive from '../form/ButtonArchive';
import ButtonSubmit from '../form/ButtonSubmit';
import ErrorQuery from '../form/ErrorQuery';

const today = new Date();

type Props = {};

const AutoresponderHolidayForm: FC<Props> = () => {
  const language = useLanguage();
  const [loaded, setLoaded] = useState(false);
  const { limit, setLimit, offset, setOffset, orderBy, setOrderBy, orderDirection, setOrderDirection } = useVariables({
    orderBy: 'from',
    orderDirection: OrderDirection.Asc,
  });

  const variables = useMemo(
    () => ({
      query: {
        limit,
        offset,
        orderBy,
        orderDirection,
        filter: {},
      },
    }),
    [limit, offset, orderBy, orderDirection],
  );

  const [mutateArchive] = useMutation<deleteHolidaySchedule, deleteHolidayScheduleVariables>(DELETE_HOLIDAY_SCHEDULE);

  const handleArchive = useCallback(
    (id: any) => () => {
      mutateArchive({
        variables: {
          id,
        },
        optimisticResponse: {
          deleteHolidaySchedule: true,
        },
        update: (proxy) => {
          const data: any = cloneDeep(proxy.readQuery({ query: GET_HOLIDAY_SCHEDULES, variables }));
          data.holidaySchedules.items = data.holidaySchedules.items.filter((el: any) => el.id !== id);
          proxy.writeQuery({ query: GET_HOLIDAY_SCHEDULES, variables, data });
        },
      });
    },
    [mutateArchive, variables],
  );

  const { data, loading, error, refetch } = useQuery<getHolidaySchedules, getHolidaySchedulesVariables>(
    GET_HOLIDAY_SCHEDULES,
    {
      variables,
    },
  );

  const [mutate] = useMutation<createHolidaySchedule, createHolidayScheduleVariables>(CREATE_HOLIDAY_SCHEDULE);

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  let items = data && data.holidaySchedules ? data.holidaySchedules.items : [];
  const pages = data && data.holidaySchedules ? Math.ceil(data.holidaySchedules.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Start`),
      accessor: 'from',
      sortable: true,
      Cell: (cell: CellInfo) => <DateFormat value={new Date(cell.value)} format={dateOptions} />,
    },
    {
      Header: i18n._(t`End`),
      accessor: 'to',
      Cell: (cell: CellInfo) => <DateFormat value={addDays(new Date(cell.value), -1)} format={dateOptions} />,
    },
    {
      Header: i18n._(t`Actions`),
      width: 90,
      className: 'text-center',
      Cell: (cell: CellInfo) => <ButtonArchive onClick={handleArchive(cell.original.id)} />,
    },
  ];

  const model = {
    from: today,
    to: today,
  };

  return (
    <div className="">
      <div className="history-item">
        <Formik
          initialValues={model}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              const from = new Date(
                Date.UTC(values.from.getFullYear(), values.from.getMonth(), values.from.getDate()),
              ).toISOString();
              const to = new Date(
                Date.UTC(values.to.getFullYear(), values.to.getMonth(), values.to.getDate() + 1),
              ).toISOString();

              const result: any = await mutate({
                variables: {
                  payload: {
                    from,
                    to,
                  },
                },
              });

              if (result.errors) {
                toastErrorQuery(result.errors);
              } else {
                toast.success(i18n._(t`Success!`));
                resetForm();
                refetch();
              }
            } catch (e) {
              toastErrorQuery(e);
            }

            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="row">
                <div className="form-group col-md-5">
                  <label>
                    <Trans>Start</Trans>
                  </label>

                  <Field name="from">
                    {({ field, form }: { field: any; form: FormikProps<any> }) => {
                      const handleChange = (date: Date) => {
                        form.setFieldValue('from', date);
                      };

                      const selected = field.value || today;

                      return (
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="icon-calendar" />
                            </div>
                          </div>
                          <div className="form-control">
                            <DatePicker
                              selected={selected}
                              onChange={handleChange}
                              className="datepicker-control"
                              dateFormat="yyyy/MM/dd"
                              locale={language}
                            />
                          </div>
                        </div>
                      );
                    }}
                  </Field>
                </div>

                <div className="form-group col-md-5">
                  <label>
                    <Trans>End</Trans>
                  </label>

                  <Field name="to">
                    {({ field, form }: { field: any; form: FormikProps<any> }) => {
                      const handleChange = (date: Date) => {
                        form.setFieldValue('to', date);
                      };

                      const selected = field.value || today;

                      return (
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="icon-calendar" />
                            </div>
                          </div>
                          <div className="form-control">
                            <DatePicker
                              selected={selected}
                              onChange={handleChange}
                              className="datepicker-control"
                              dateFormat="yyyy/MM/dd"
                              locale={language}
                            />
                          </div>
                        </div>
                      );
                    }}
                  </Field>
                </div>

                <div className="form-group col">
                  <label>&nbsp;</label>
                  <br />
                  <ButtonSubmit className="btn btn-link" title={i18n._(t`Save`)} loading={isSubmitting} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      {!loaded ? (
        <LoadingTable />
      ) : (
        <ReactTable
          data={items}
          columns={columns}
          pages={pages}
          pageSize={limit}
          manual
          onFetchData={(state) => {
            let orderDir = OrderDirection.Asc;
            if (state.sorted[0]) {
              orderDir = state.sorted[0].desc ? OrderDirection.Desc : OrderDirection.Asc;
            }

            setOffset(state.page * state.pageSize);
            setOrderBy(state.sorted[0] ? state.sorted[0].from : orderBy);
            setOrderDirection(orderDir);
          }}
          onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
          {...tableOptions()}
        />
      )}
    </div>
  );
};

export default AutoresponderHolidayForm;
