import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { pick } from 'lodash';
import React, { FunctionComponent, useCallback, useState } from 'react';
import Modal from 'react-modal';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import ButtonSubmit from '../components/form/ButtonSubmit';
import PersonCreateForms from '../components/person/PersonCreateForm';
import { CreatePersonPayload, Gender } from '../schema/graphql-global-types';
import { CREATE_PERSON, validatePersonForm } from '../schema/person';
import { createPerson, createPersonVariables } from '../schema/types/createPerson';
import { DefaultRouteParams } from '../utils/const';
import { toastErrorQuery } from '../utils/error';

const PersonCreatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ history }) => {
  const [mutate, { loading }] = useMutation<createPerson, createPersonVariables>(CREATE_PERSON);

  const [showConfirm, setShowConfirm] = useState(false);
  const [saving, setSaving] = useState<createPersonVariables>();
  const [message, setMessage] = useState('');

  const handleConfirm = useCallback(
    async (event) => {
      event.preventDefault();

      try {
        const result = await mutate({
          variables: saving,
        });

        if (result.errors) {
          toastErrorQuery(result.errors);
        } else {
          toast.success(i18n._(t`Success!`));
          window.open(`/crm/person/${result.data?.createPerson.person.id}`, '_blank');
        }

        setShowConfirm(false);
      } catch (e) {
        toastErrorQuery(e);
      }
    },
    [mutate, saving],
  );

  const initialValues: CreatePersonPayload = {
    firstName: '',
    lastName: '',
    gender: Gender.Unspecified,
    function: '',
    email: '',
    companyPhone: '',
    cellphone: '',
    privateAddress1: '',
    privateAddress2: '',
    privateZip: '',
    privateCity: '',
    companies: [],
    companyId: null,
    avatarId: null,
    tagNames: [],
    manualLogV2: {
      content: '',
      tagNames: [],
    },
    reminders: [],
    isVIP: false,
  };

  return (
    <div className="person-form-page">
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validatePersonForm)}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const payload: any = values;
            payload.companies = payload.companies.map((el: any) => pick(el, ['companyId', 'permission']));

            const result: any = await mutate({
              variables: {
                payload,
              },
            });

            if (result.errors) {
              if (result.errors.length && result.errors[0].message === 'Conflicted') {
                toastErrorQuery([
                  {
                    message:
                      i18n._(t`This e-mail address is already in use by:`) +
                      ' ' +
                      result.errors[0].extensions.exception.extra[0].fullName,
                  },
                ]);
              } else if (result.errors[0]?.message.startsWith('Duplicate')) {
                const data = JSON.parse(result.errors[0].message.replace('Duplicate Person: ', ''));

                setMessage(
                  i18n._(
                    t`It looks like this person already exists under "${data.firstName} ${data.lastName} ${data.company}". Are you sure you'd like to save?`,
                  ),
                );
                setShowConfirm(true);
                setSaving({
                  payload: {
                    ...payload,
                    forceCreate: true,
                  },
                });
              } else {
                toastErrorQuery(result.errors);
              }
            } else {
              toast.success(i18n._(t`Success!`));
              history.push(`/crm/person/${result.data.createPerson.person.id}`);
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => <PersonCreateForms loading={isSubmitting} />}
      </Formik>

      <Modal isOpen={showConfirm} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
        <div className="modal fade show" style={{ display: 'block' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title">
                  <Trans>Confirm</Trans>
                </h2>
                <button type="button" className="close" aria-label="Close" onClick={() => setShowConfirm(false)}>
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <div className="modal-body text-danger">{message}</div>

              <form className="modal-footer" onSubmit={handleConfirm}>
                <button type="button" className="btn btn-info" onClick={() => setShowConfirm(false)} disabled={loading}>
                  <Trans>No</Trans>
                </button>

                <ButtonSubmit className="btn btn-primary" title={i18n._(t`Yes`)} loading={loading} />
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(PersonCreatePage);
