import gql from 'graphql-tag';
import { validateRequired } from '../utils/validations';

export const TEXT_TEMPLATE = gql`
  fragment TextTemplate on TextTemplate {
    id
    systemName
    title
    updatedAt
    editor {
      id
      fullName
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
    type
  }
`;

export const TEXT_TEMPLATES = gql`
  fragment TextTemplates on TextTemplateCollection {
    limit
    offset
    total
    items {
      ...TextTemplate
    }
  }
  ${TEXT_TEMPLATE}
`;

export const GET_TEXT_TEMPLATES = gql`
  query getTextTemplates($query: TextTemplatesQuery!) {
    textTemplates(query: $query) {
      ...TextTemplates
    }
  }
  ${TEXT_TEMPLATES}
`;

export const GET_TEXT_TEMPLATE_VARIABLES = gql`
  query textTemplateDefaultVariables {
    textTemplateDefaultVariables
  }
`;

export const TEXT_TEMPLATE_DETAIL = gql`
  fragment TextTemplateDetail on TextTemplate {
    id
    systemName
    title
    content
    type
    contentType
    to
    cc
    bcc
    messageCenterTo
    variables
  }
`;

export const TEXT_TEMPLATE_WITH_DETAIL = gql`
  fragment TextTemplatesDetail on TextTemplateCollection {
    limit
    offset
    total
    items {
      ...TextTemplateDetail
    }
  }
  ${TEXT_TEMPLATE_DETAIL}
`;

export const GET_TEXT_TEMPLATE_WITH_DETAIL = gql`
  query getTextTemplatesWithDetail($query: TextTemplatesQuery!) {
    textTemplates(query: $query) {
      ...TextTemplatesDetail
    }
  }
  ${TEXT_TEMPLATE_WITH_DETAIL}
`;

export const GET_TEXT_TEMPLATE = gql`
  query getTextTemplate($query: ID!) {
    textTemplate(id: $query) {
      ...TextTemplateDetail
    }
  }
  ${TEXT_TEMPLATE_DETAIL}
`;

export const GET_SYSTEM_TEXT_TEMPLATE = gql`
  query getSystemTextTemplate($key: String!) {
    systemTextTemplate(key: $key) {
      ...TextTemplateDetail
    }
  }
  ${TEXT_TEMPLATE_DETAIL}
`;

export const CREATE_TEXT_TEMPLATE = gql`
  mutation createTextTemplate($payload: CreateTextTemplatePayload!) {
    createTextTemplate(payload: $payload) {
      textTemplate {
        ...TextTemplateDetail
      }
    }
  }
  ${TEXT_TEMPLATE_DETAIL}
`;

export const UPDATE_TEXT_TEMPLATE = gql`
  mutation updateTextTemplate($id: ID!, $payload: UpdateTextTemplatePayload!) {
    updateTextTemplate(id: $id, payload: $payload) {
      textTemplate {
        ...TextTemplateDetail
      }
    }
  }
  ${TEXT_TEMPLATE_DETAIL}
`;

export const ARCHIVE_TEXT_TEMPLATE = gql`
  mutation archiveTextTemplate($id: ID!) {
    archiveTextTemplate(id: $id) {
      textTemplate {
        id
      }
    }
  }
`;

export const UNARCHIVE_TEXT_TEMPLATE = gql`
  mutation unarchiveTextTemplate($id: ID!) {
    unarchiveTextTemplate(id: $id) {
      textTemplate {
        id
      }
    }
  }
`;

export const validateTextTemplateForm = {
  systemName: validateRequired,
  title: validateRequired,
};
