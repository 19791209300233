import DateFormat from '../components/common/DateFormat';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import ReactTable, { CellInfo } from 'react-table';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import MainTitle from '../components/common/MainTitle';
import TableActions from '../components/common/TableActions';
import UserAvatarName from '../components/common/UserAvatarName';
import ButtonArchive from '../components/form/ButtonArchive';
import LoadingTable from '../components/common/LoadingTable';
import FilterHeader from '../components/form/FilterHeader';
import { GET_TICKETS, ARCHIVE_TICKET, UNARCHIVE_TICKET, REOPEN_TICKET } from '../schema/ticket';
import { OrderDirection, TicketStatus } from '../schema/graphql-global-types';
import { archiveTicket, archiveTicketVariables } from '../schema/types/archiveTicket';
import { getTickets, getTicketsVariables } from '../schema/types/getTickets';
import { reopenTicket, reopenTicketVariables } from '../schema/types/reopenTicket';
import { unarchiveTicket, unarchiveTicketVariables } from '../schema/types/unarchiveTicket';
import { GET_USERS_FOR_COMPANY } from '../schema/user';
import { dateTimeOptions, tableOptions, tablePollingInterval } from '../utils/const';
import ErrorQuery from '../components/form/ErrorQuery';
import { useVariables } from '../utils/hooks';
import { cloneDeep } from 'lodash';
import { DataProxy } from '@apollo/client/cache';
import { getFilter, getOrder } from '../utils/utils';

const SupportClosedPage: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);
  const {
    search$,
    search,
    filter$,
    filter,
    archived,
    setArchived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
  } = useVariables();

  const variables = useMemo(
    () => ({
      query: {
        limit,
        offset,
        orderBy,
        orderDirection,
        filter: {
          search,
          archived,
          ...filter,
          statuses: [TicketStatus.Closed],
        },
      },
    }),
    [limit, offset, orderBy, orderDirection, search, archived, filter],
  );

  const updateQuery = useCallback(
    (proxy: DataProxy, id: any) => {
      const data: any = cloneDeep(proxy.readQuery({ query: GET_TICKETS, variables }));
      data.tickets.items = data.tickets.items.filter((el: any) => el.id !== id);
      proxy.writeQuery({ query: GET_TICKETS, variables, data });
    },
    [variables],
  );

  const [mutateArchive] = useMutation<archiveTicket, archiveTicketVariables>(ARCHIVE_TICKET, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.archiveTicket.ticket.id);
    },
  });
  const [mutateUnarchive] = useMutation<unarchiveTicket, unarchiveTicketVariables>(UNARCHIVE_TICKET, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.unarchiveTicket.ticket.id);
    },
  });

  const handleArchive = useCallback(
    (id: any) => () => {
      if (archived) {
        mutateUnarchive({
          variables: {
            id,
          },
        });
      } else {
        mutateArchive({
          variables: {
            id,
          },
        });
      }
    },
    [archived, mutateArchive, mutateUnarchive],
  );

  const [mutateReopen] = useMutation<reopenTicket, reopenTicketVariables>(REOPEN_TICKET);

  const handleReopen = useCallback(
    (id: any) => () => {
      mutateReopen({
        variables: {
          id,
        },
        optimisticResponse: {
          reopenTicket: true,
        },
        update: (proxy) => {
          updateQuery(proxy, id);
        },
      });
    },
    [mutateReopen, updateQuery],
  );

  const { data, previousData, loading, error } = useQuery<getTickets, getTicketsVariables>(GET_TICKETS, {
    variables,
    pollInterval: tablePollingInterval,
  });

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.tickets.items ?? previousData?.tickets.items ?? [];
  const pages = data && data.tickets ? Math.ceil(data.tickets.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Title`),
      accessor: 'subject',
      sortable: true,
      Cell: (cell: CellInfo) => <NavLink to={`/support/ticket/${cell.original.id}`}>{cell.value}</NavLink>,
    },
    {
      Header: i18n._(t`Opener`),
      accessor: 'opener',
      sortable: true,
      filterable: true,
      Cell: (cell: CellInfo) => <a href={`mailto:${cell.value}`}>{cell.value}</a>,
    },
    {
      Header: i18n._(t`Company`),
      accessor: 'person',
      Cell: (cell: CellInfo) => {
        if (!cell.original.person || !cell.original.person.companies || !cell.original.person.companies.length)
          return '';

        return (
          <NavLink to={`/crm/company/${cell.original.person.companies[0].company.id}`}>
            {cell.original.person.companies[0].company.name}
          </NavLink>
        );
      },
    },
    {
      Header: i18n._(t`Opened`),
      accessor: 'openedAt',
      sortable: true,
      Cell: (cell: CellInfo) => <DateFormat value={new Date(cell.value)} format={dateTimeOptions} />,
    },
    {
      Header: i18n._(t`Closed`),
      accessor: 'closedAt',
      sortable: true,
      Cell: (cell: CellInfo) => <DateFormat value={new Date(cell.value)} format={dateTimeOptions} />,
    },
    {
      Header: i18n._(t`Last Edit`),
      accessor: 'lastEditor.fullName',
      sortable: true,
      filterable: true,
      Cell: (cell: CellInfo) => (
        <>
          {cell.original.lastEditor ? (
            <NavLink to={`/settings/user/${cell.original.lastEditor.id}`}>
              <UserAvatarName user={cell.original.lastEditor} />
            </NavLink>
          ) : (
            <div />
          )}
        </>
      ),
      Filter: (params: any) => (
        <FilterHeader
          query={GET_USERS_FOR_COMPANY}
          objectType="users"
          nameField="fullName"
          onChange={params.onChange}
        />
      ),
    },
    {
      Header: i18n._(t`Actions`),
      width: 90,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <>
          <a
            data-tip
            data-for={`reopen-${cell.original.id}`}
            className="button text-xs"
            onClick={handleReopen(cell.original.id)}
          >
            <i className="icon-reopen" />
          </a>
          <ReactTooltip id={`reopen-${cell.original.id}`}>
            <Trans>Reopen</Trans>
          </ReactTooltip>
          <ButtonArchive onClick={handleArchive(cell.original.id)} />
        </>
      ),
    },
  ];

  return (
    <div className="">
      <div className="card-body">
        <div className="table-actions">
          <div className="breadcrumbs-area">
            <MainTitle title={i18n._(t`Closed Tickets`)} tips="Support/Closed" />
          </div>

          <TableActions onSearch={(e) => search$.next(e.target.value)} archived={archived} onArchive={setArchived} />
        </div>

        {!loaded ? (
          <LoadingTable />
        ) : (
          <ReactTable
            data={items}
            columns={columns}
            pages={pages}
            pageSize={limit}
            manual
            onFetchData={(state) => {
              filter$.next(
                getFilter(state, {
                  opener: 'searchOpener',
                  status: 'statuses',
                  'company.name': 'companyIds',
                  'lastEditor.fullName': 'lastEditorId',
                }),
              );

              let orderDir = OrderDirection.Desc;
              if (state.sorted[0]) {
                orderDir = state.sorted[0].desc ? OrderDirection.Desc : OrderDirection.Asc;
              }

              setOffset(state.page * state.pageSize);
              setOrderBy(
                getOrder(state, {
                  'company.name': 'companyName',
                  'lastEditor.fullName': 'lastEditorName',
                }),
              );
              setOrderDirection(orderDir);
            }}
            onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
            {...tableOptions()}
          />
        )}
      </div>
    </div>
  );
};

export default SupportClosedPage;
