import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import MaskedInput from 'react-text-mask';
import { padStart } from 'lodash';

type Props = {
  name: string;
};

const FieldTime: FunctionComponent<Props> = ({ name }) => {
  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (e: any) => {
          const val = e.target.value.split(':');

          if (val.length > 1) {
            const hour = Number(val[0].replace('_', '')) || 0;
            const minute = Number(val[1].replace('_', '')) || 0;
            form.setFieldValue(name, hour * 3600 + minute * 60);
          } else {
            form.setFieldValue(name, 0);
          }
        };

        const current = field.value || 0;
        const hour = padStart(String(Math.floor(current / 3600)), 2, '0');
        const minute = padStart(String((current % 3600) / 60), 2, '0');

        return (
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="icon-time" />
              </div>
            </div>
            <MaskedInput
              value={field.value !== null ? `${hour}:${minute}` : ''}
              mask={[/\d/, /\d/, ':', /\d/, /\d/]}
              onChange={handleChange}
              className="form-control"
              placeholder={i18n._(t`N/A`)}
            />
          </div>
        );
      }}
    </Field>
  );
};

export default FieldTime;
