import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import { subHours } from 'date-fns';
import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { GET_ASSETS } from '../../schema/asset';
import { getAssets, getAssetsVariables } from '../../schema/types/getAssets';
import { useVariables } from '../../utils/hooks';
import AssetDashboard from '../asset/AssetDashboard';
import LoadingQuery from '../common/LoadingQuery';
import ErrorQuery from '../form/ErrorQuery';

const today = new Date();
const hour24 = subHours(today, 24);

const HomeMonitorChart: FunctionComponent = () => {
  const { search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
        dashboard: true,
      },
    },
    query2: {
      filter: {
        fromTime: hour24.toISOString(),
        toTime: today.toISOString(),
      },
    },
    query3: {
      step: 3600,
      filter: {
        fromTime: hour24.toISOString(),
        toTime: today.toISOString(),
      },
    },
  };

  const { data, loading, error } = useQuery<getAssets, getAssetsVariables>(GET_ASSETS, {
    variables,
    fetchPolicy: 'cache-first',
  });

  if (error) return <ErrorQuery error={error} />;
  if (loading && !data) return <LoadingQuery />;
  if (!data) return <div />;

  return (
    <>
      {data.assets.items.map((el) => (
        <div key={el.id} className="card">
          <div className="card-header">
            <Trans>Uptime Statistic</Trans>: <NavLink to={`/service/asset/${el.id}`}>{el.name}</NavLink>
          </div>

          <div className="monitor-charts">
            <AssetDashboard model={el} simple={true} />
          </div>
        </div>
      ))}
    </>
  );
};

export default HomeMonitorChart;
