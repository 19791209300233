import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { GET_NINJA_RMM_ORGANIZATIONS } from '../../schema/config';
import {
  getNinjaRmmOrganizations,
  getNinjaRmmOrganizationsVariables,
} from '../../schema/types/getNinjaRmmOrganizations';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {};

const NinjaOrganizationForm: FunctionComponent<Props> = () => {
  const { search$, search, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
      },
    },
  };

  const { data, loading } = useQuery<getNinjaRmmOrganizations, getNinjaRmmOrganizationsVariables>(
    GET_NINJA_RMM_ORGANIZATIONS,
    {
      variables,
    },
  );

  if (!data && !loading) return <div />;

  return (
    <Field name="ninjaRmmOrganizationId">
      {({ form }: { form: FormikProps<any> }) => {
        const handleChange = (value: any) => {
          form.setFieldValue('ninjaRmmOrganizationId', value.value);
        };

        const handleLoad = (value: string) => search$.next(value);

        const options = loading
          ? []
          : data!.ninjaRmmOrganizations.items.map((el) => ({
              value: el.id,
              label: el.name,
            }));

        return (
          <Select
            options={options}
            isLoading={loading}
            onChange={handleChange}
            onInputChange={handleLoad}
            placeholder={i18n._(t`Search...`)}
            {...selectOptions}
          />
        );
      }}
    </Field>
  );
};

export default NinjaOrganizationForm;
