let storage = {};
let isLocalStorageSupported = false;

/**
 * When Safari (OS X or iOS) is in private browsing mode,
 * it appears as though localStorage is available,
 * but trying to call .setItem throws an exception.
 */
const testKey = 'test';
const store = window.localStorage;
try {
  store.setItem(testKey, '1');
  store.removeItem(testKey);
  isLocalStorageSupported = true;
} catch (e) {
  console.log('no localStorage');
}

/**
 * Set a value to storage
 * @param key
 * @param value
 */
export const setStorage = (key, value) => {
  if (isLocalStorageSupported) {
    window.localStorage[key] = JSON.stringify(value);
  } else {
    storage[key] = value;
  }
};

/**
 * Get a value from storage
 * @param key
 * @returns {*}
 */
export const getStorage = key => {
  if (isLocalStorageSupported) {
    if (key) {
      try {
        return JSON.parse(window.localStorage[key]);
      } catch (e) {
        return window.localStorage[key];
      }
    }

    return window.localStorage;
  }

  return key ? storage[key] : storage;
};

/**
 * Delete a value
 * @param key
 */
export const removeStorage = key => {
  if (isLocalStorageSupported) {
    delete window.localStorage[key];
  } else {
    delete storage[key];
  }
};

/**
 * Clear all
 */
export const clearStore = () => {
  if (isLocalStorageSupported) {
    window.localStorage.clear();
  } else {
    storage = {};
  }
};
