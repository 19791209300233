import { Trans } from '@lingui/macro';
import { differenceInHours } from 'date-fns';
import React, { FunctionComponent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { TicketStatus } from '../../schema/graphql-global-types';
import { TicketDetail } from '../../schema/types/TicketDetail';
import { getTicketStatus } from '../../utils/const';
import { useViewer } from '../../utils/hooks';
import TimeAgo from '../common/TimeAgo';
import UserAvatar from '../common/UserAvatar';

type Props = {
  selected: boolean;
  selectedTickets?: string[];
  model: TicketDetail;
  onSelect: () => void;
  onChecked?: () => void;
};

const TicketMessagePreview: FunctionComponent<Props> = ({ selected, selectedTickets, model, onSelect, onChecked }) => {
  const me = useViewer();
  const [shake, setShake] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShake(
        model.status === TicketStatus.Opened && differenceInHours(new Date(), new Date(model.lastActiveTime)) > 2,
      );
    }, Math.random() * 1000);
  }, [model, selected]);

  return (
    <div
      className={classNames('ticket-message-preview', model.status, model.inProgressSubStatus, {
        shake,
        selected,
      })}
    >
      <div className="meta">
        {selectedTickets && onChecked && (
          <div className="action">
            <input type="checkbox" checked={selectedTickets.includes(model.id)} onChange={onChecked} />
          </div>
        )}

        <div className="sender text-ellipsis" onClick={onSelect}>
          {model.lastSender}
        </div>

        <div className="time" onClick={onSelect}>
          <TimeAgo id={model.id} value={model.lastActiveTime} />
        </div>
      </div>

      <div className="content" onClick={onSelect}>
        <div className="title text-ellipsis">{model.subject}</div>

        <div className="info">
          {model.lastEditor && me.data?.viewer.user?.id !== model.lastEditor.id && (
            <div className="user">
              <UserAvatar user={model.lastEditor} />

              <div className="name text-ellipsis">{model.lastEditor.fullName}</div>
            </div>
          )}

          {model.lastEditor && me.data?.viewer.user?.id === model.lastEditor.id && (
            <div className="user">
              <div className="name text-ellipsis">
                <Trans>My ticket</Trans>
              </div>
            </div>
          )}

          <div className={classNames('status badge', model.status, model.inProgressSubStatus)}>
            {getTicketStatus(model.inProgressSubStatus || model.status)}
          </div>

          {model.person?.isVIP && (
            <div className="vip">
              <i className="icon-star---filled" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketMessagePreview;
