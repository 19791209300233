import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { GET_MESSAGE_CATEGORIES } from '../../schema/messageCategory';
import { getMessageCategories, getMessageCategoriesVariables } from '../../schema/types/getMessageCategories';
import { useVariables } from '../../utils/hooks';
import MultipleSelect from '../form/MultipleSelect';
import HomeMessageCategoryModal from './HomeMessageCategoryModal';

type Props = {
  name: string;
};

const HomeMessageCategoryForm: FunctionComponent<Props> = ({ name }) => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const [showCategory, setShowCategory] = useState(false);

  const toggleCategoryModal = useCallback(() => setShowCategory((prevState) => !prevState), [setShowCategory]);

  const { data, loading } = useQuery<getMessageCategories, getMessageCategoriesVariables>(GET_MESSAGE_CATEGORIES, {
    variables,
    fetchPolicy: 'cache-first',
  });

  if (!data && !loading) return <div />;

  const defaultCategory = data?.messageCategories.items.find((el) => el.default) || null;

  return (
    <div className="d-flex align-items-center">
      <div className="flex-grow-1">
        <MultipleSelect
          name={name}
          search$={search$}
          loading={loading}
          data={data?.messageCategories.items}
          placeholder={i18n._(t`Default Category`)}
          nameField="name"
        />
      </div>

      <span data-tip data-for="categories" className="button ml-2" onClick={toggleCategoryModal}>
        <i className="icon-edit" />
      </span>
      <ReactTooltip id="categories">
        <Trans>Manage Categories</Trans>
      </ReactTooltip>

      <HomeMessageCategoryModal
        show={showCategory}
        defaultId={defaultCategory ? defaultCategory.id : ''}
        onClose={toggleCategoryModal}
      />
    </div>
  );
};

export default HomeMessageCategoryForm;
