import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { omit } from 'lodash';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import ContractCreateForms from '../components/contract/ContractCreateForms';
import { CREATE_CONTRACT, validateContractForm } from '../schema/contract';
import { ContractCycle, CreateContractPayload } from '../schema/graphql-global-types';
import { createContract, createContractVariables } from '../schema/types/createContract';
import { DefaultRouteParams } from '../utils/const';
import { toastErrorQuery } from '../utils/error';

const now = new Date();
const next =
  now.getMonth() === 11
    ? new Date(Date.UTC(now.getFullYear() + 1, 0, 1))
    : new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 1));

const ContractCreatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ history }) => {
  const [mutate] = useMutation<createContract, createContractVariables>(CREATE_CONTRACT);

  const initialValues: CreateContractPayload = {
    name: '',
    customerId: '',
    cycle: ContractCycle.Monthly,
    yearlyInterval: now.getMonth() === 11 ? 0 : now.getMonth() + 1,
    startedAt: next.toISOString(),
    adjustmentDate: next.toISOString(),
    products: [],
    reportingProducts: [],
    isAutoRenew: false,
    contractLength: null,
    cancelPeriod: null,
  };

  return (
    <div className="contract-form-page">
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validateContractForm)}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const payload: any = omit(
              {
                ...values,
                yearlyInterval: Number(values.yearlyInterval),
              },
              ['customer'],
            );

            const now = new Date(payload.startedAt);
            payload.startedAt = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));

            const result: any = await mutate({
              variables: {
                payload,
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              toast.success(i18n._(t`Success!`));
              history.push(`/crm/contract/${result.data.createContract.contract.id}`);
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => <ContractCreateForms loading={isSubmitting} />}
      </Formik>
    </div>
  );
};

export default withRouter(ContractCreatePage);
