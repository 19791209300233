import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import {
  CREATE_DOCUMENT_CATEGORY,
  GET_DOCUMENT_CATEGORIES,
  UPDATE_DOCUMENT_CATEGORY,
  validateDocumentCategoryForm,
} from '../../schema/documentCategory';
import { DocumentCategoryType } from '../../schema/graphql-global-types';
import { createDocumentCategory, createDocumentCategoryVariables } from '../../schema/types/createDocumentCategory';
import { DocumentCategory } from '../../schema/types/DocumentCategory';
import { updateDocumentCategory, updateDocumentCategoryVariables } from '../../schema/types/updateDocumentCategory';
import { toastErrorQuery } from '../../utils/error';
import { useVariables } from '../../utils/hooks';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';
import DocumentationCategories from './DocumentationCategories';
import { cloneDeep, pick } from 'lodash';

const initial: any = {
  name: '',
  type: DocumentCategoryType.Both,
};

type Props = {
  show: boolean;
  onSelect: (item: DocumentCategory) => void;
  onClose: () => void;
};

const DocumentationCategoryModal: FunctionComponent<Props> = ({ show, onSelect, onClose }) => {
  const [model, setModel] = useState(cloneDeep(initial));
  const { search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const [add] = useMutation<createDocumentCategory, createDocumentCategoryVariables>(CREATE_DOCUMENT_CATEGORY, {
    refetchQueries: [
      {
        query: GET_DOCUMENT_CATEGORIES,
        variables,
      },
    ],
  });

  const [edit] = useMutation<updateDocumentCategory, updateDocumentCategoryVariables>(UPDATE_DOCUMENT_CATEGORY);

  const handleEdit = useCallback((item: any) => setModel(cloneDeep(item)), [setModel]);

  const handleCancel = useCallback(() => setModel(cloneDeep(initial)), [setModel]);

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Categories</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              <Formik
                enableReinitialize
                initialValues={model}
                validationSchema={yup.object().shape(validateDocumentCategoryForm)}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  try {
                    const payload: any = pick(values, ['name', 'type']);

                    const result: any = model.id
                      ? await edit({
                          variables: {
                            id: model.id,
                            payload,
                          },
                        })
                      : await add({
                          variables: {
                            payload,
                          },
                        });

                    if (result.errors) {
                      toastErrorQuery(result.errors);
                    } else {
                      handleCancel();
                      resetForm({ values: initial });
                      toast.success(i18n._(t`Success!`));
                    }
                  } catch (e) {
                    toastErrorQuery(e);
                  }

                  setSubmitting(false);
                }}
              >
                {({ isSubmitting, submitForm }) => {
                  const handleSubmit = (e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    submitForm();
                  };

                  return (
                    <Form onSubmitCapture={handleSubmit}>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>
                              <Trans>Name</Trans>
                            </label>
                            <Field type="text" className="form-control" name="name" />

                            <FieldErrorMessage name="name" />
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>
                              <Trans>Type</Trans>
                            </label>
                            <Field component="select" className="form-control" name="type">
                              <option value="Internal">{i18n._(t`Internal`)}</option>
                              <option value="External">{i18n._(t`External`)}</option>
                              <option value="Both">{i18n._(t`Both`)}</option>
                            </Field>
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <label className="d-none d-lg-block">&nbsp;</label>

                          <div className="form-group">
                            {model.id && (
                              <button type="button" className="mr-4" onClick={handleCancel}>
                                <Trans>Cancel</Trans>
                              </button>
                            )}

                            <ButtonSubmit
                              className="btn btn-link"
                              title={model.id ? i18n._(t`Save Category`) : i18n._(t`Add Category`)}
                              loading={isSubmitting}
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>

              <DocumentationCategories onSelect={onSelect} onEdit={handleEdit} />
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-link" onClick={onClose}>
                <Trans>Close</Trans>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DocumentationCategoryModal;
