import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PERSONS_FOR_COMPANY } from '../../schema/person';
import { getCompanyPersons, getCompanyPersonsVariables } from '../../schema/types/getCompanyPersons';
import { useVariables } from '../../utils/hooks';
import FieldCheckbox from '../form/FieldCheckbox';
import FieldErrorMessage from '../form/FieldErrorMessage';

type Props = {};

const SendSMSForm: FunctionComponent<Props> = () => {
  const [showMessages, setShowMessages] = useState(false);

  const toggleMessages = useCallback((value: boolean) => () => setTimeout(() => setShowMessages(value), 150), [
    setShowMessages,
  ]);

  const { setSearch, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getCompanyPersons, getCompanyPersonsVariables>(GET_PERSONS_FOR_COMPANY, {
    variables,
    fetchPolicy: 'cache-first',
  });

  const handleSearch = useCallback((e: any) => setSearch(e.currentTarget.value), [setSearch]);

  return (
    <>
      <div className="sms-form">
        <div className="form-group input">
          <Field
            type="text"
            className="form-control"
            name="cellphone"
            placeholder={i18n._(t`Phone number`)}
            onInput={handleSearch}
            onFocus={toggleMessages(true)}
            onBlur={toggleMessages(false)}
            autoComplete="off"
          />

          <FieldErrorMessage name="cellphone" />

          <div className={classNames('dropdown', { 'd-none': !showMessages })}>
            <Field name="cellphone">
              {({ form }: { form: FormikProps<any> }) => {
                const handleSelect = (data: string) => () => {
                  form.setFieldValue('cellphone', data);
                };

                return (
                  <>
                    {loading ? (
                      <i className="fa fa-spinner fa-spin" />
                    ) : data && data.persons && data.persons.items.length ? (
                      data.persons.items.map((el: any) => (
                        <a className="item text-info" key={el.id} onClick={handleSelect(el.cellphone)}>
                          {el.fullName} <strong>{el.cellphone}</strong>
                        </a>
                      ))
                    ) : (
                      <div className="item">
                        <Trans>No results</Trans>
                      </div>
                    )}
                  </>
                );
              }}
            </Field>
          </div>
        </div>

        <div className="form-group">
          <Field
            component="textarea"
            className="form-control"
            name="message"
            rows={3}
            placeholder={i18n._(t`Message`)}
          />

          <FieldErrorMessage name="message" />
        </div>
      </div>

      <div className="form-group">
        <FieldCheckbox name="shadowLog" label={i18n._(t`Shadow Log`)} />
      </div>
    </>
  );
};

export default SendSMSForm;
