import { useMutation, useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import React, { FC, useCallback, useMemo, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { GET_MESSAGE_CENTER_MESSAGES, MARK_MESSAGE_CENTER_MESSAGES_AS_READ } from '../../schema/messageCenter';
import {
  getMessageCenterMessages,
  getMessageCenterMessagesVariables,
} from '../../schema/types/getMessageCenterMessages';
import {
  markMessageCenterMessagesAsRead,
  markMessageCenterMessagesAsReadVariables,
} from '../../schema/types/markMessageCenterMessagesAsRead';
import { tablePollingInterval } from '../../utils/const';
import TimeAgo from '../common/TimeAgo';

type Props = unknown;

const HeaderNotifications: FC<Props> = () => {
  const { data } = useQuery<getMessageCenterMessages, getMessageCenterMessagesVariables>(GET_MESSAGE_CENTER_MESSAGES, {
    variables: {
      query: {
        limit: 20,
        offset: 0,
      },
    },
    pollInterval: tablePollingInterval,
  });

  const [mutate] = useMutation<markMessageCenterMessagesAsRead, markMessageCenterMessagesAsReadVariables>(
    MARK_MESSAGE_CENTER_MESSAGES_AS_READ,
  );

  const unreadMessages = useMemo(
    () =>
      data && data.messageCenterMessages.items.length > 0
        ? data.messageCenterMessages.items.filter((el) => !el.read)
        : [],
    [data],
  );

  const [active, setActive] = useState(false);
  const handleShow = useCallback(() => setActive(true), []);
  const handleHide = useCallback(() => {
    setActive((prevState) => {
      if (prevState && unreadMessages.length) {
        mutate({
          variables: {
            ids: unreadMessages.map((el) => el.id),
          },
        });
      }

      return false;
    });
  }, [mutate, unreadMessages]);

  if (!data) return null;

  return (
    <div className="nav-item dropdown notifications-area">
      <OutsideClickHandler onOutsideClick={handleHide}>
        <button
          aria-expanded="false"
          aria-haspopup="true"
          title="notifications"
          className="button nav-link"
          data-toggle="dropdown"
          onClick={handleShow}
        >
          <i className="icon-notification" />
          {unreadMessages.length > 0 && <i className="dot" />}
        </button>
        <div
          className={classNames('dropdown-menu dropdown-menu-lg dropdown-menu-arrow dropdown-menu-right', {
            show: active,
          })}
        >
          {data.messageCenterMessages.items.length === 0 && (
            <div className="item">
              <div className="title">
                <Trans>No messages</Trans>
              </div>
            </div>
          )}

          {data.messageCenterMessages.items.map((el) => (
            <div key={el.id} className="item">
              <div className="info">
                <div className="title">{el.subject}</div>

                <div className="description post-content" dangerouslySetInnerHTML={{ __html: el.message }} />
              </div>

              <div className="time">
                {!el.read && <i className="dot" />}

                <TimeAgo id={el.id} value={el.createdAt} />
              </div>
            </div>
          ))}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default HeaderNotifications;
