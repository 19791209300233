import gql from 'graphql-tag';

export const WEEK_WORKDAY_SCHEDULE = gql`
  fragment WeekWorkdaySchedule on WeekWorkdaySchedule {
    dayOfWeek
    fromTimeSeconds
    toTimeSeconds
  }
`;

export const GET_WEEKDAY_SCHEDULES = gql`
  query getWeekdaySchedules {
    weekWorkdaySchedules {
      ...WeekWorkdaySchedule
    }
  }
  ${WEEK_WORKDAY_SCHEDULE}
`;

export const UPDATE_WEEK_WORKDAY_SCHEDULES = gql`
  mutation updateWeekWorkdaySchedules($payloads: [WeekWorkdaySchedulePayload!]!) {
    updateWeekWorkdaySchedules(payloads: $payloads)
  }
`;

export const GET_AUTO_RESPONDER_EXCLUSION = gql`
  query getAutoResponderExclusion {
    autoResponderExclusion {
      emails
    }
  }
`;

export const UPDATE_AUTO_RESPONDER_EXCLUSION = gql`
  mutation updateAutoResponderExclusion($payload: AutoResponderExclusionPayload!) {
    updateAutoResponderExclusion(payload: $payload)
  }
`;

export const workdayScheduleKeys = ['dayOfWeek', 'fromTimeSeconds', 'toTimeSeconds'];

export const HOLIDAY_SCHEDULE = gql`
  fragment HolidaySchedule on HolidaySchedule {
    id
    from
    to
  }
`;

export const HOLIDAY_SCHEDULES = gql`
  fragment HolidaySchedules on HolidayScheduleCollection {
    limit
    offset
    total
    items {
      ...HolidaySchedule
    }
  }
  ${HOLIDAY_SCHEDULE}
`;

export const GET_HOLIDAY_SCHEDULES = gql`
  query getHolidaySchedules($query: HolidaySchedulesQuery) {
    holidaySchedules(query: $query) {
      ...HolidaySchedules
    }
  }
  ${HOLIDAY_SCHEDULES}
`;

export const CREATE_HOLIDAY_SCHEDULE = gql`
  mutation createHolidaySchedule($payload: CreateHolidaySchedulePayload!) {
    createHolidaySchedule(payload: $payload) {
      holidaySchedule {
        ...HolidaySchedule
      }
    }
  }
  ${HOLIDAY_SCHEDULE}
`;

export const UPDATE_HOLIDAY_SCHEDULE = gql`
  mutation updateHolidaySchedule($id: ID!, $payload: UpdateHolidaySchedulePayload!) {
    updateHolidaySchedule(id: $id, payload: $payload) {
      holidaySchedule {
        ...HolidaySchedule
      }
    }
  }
  ${HOLIDAY_SCHEDULE}
`;

export const DELETE_HOLIDAY_SCHEDULE = gql`
  mutation deleteHolidaySchedule($id: ID!) {
    deleteHolidaySchedule(id: $id)
  }
`;

export const holidayScheduleKeys = ['from', 'to'];
