import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { pick } from 'lodash';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import MainTitle from '../components/common/MainTitle';
import ProfilePasswordForm from '../components/profile/ProfilePasswordForm';
import { UPDATE_PROFILE } from '../schema/auth';
import { updateProfile, updateProfileVariables } from '../schema/types/updateProfile';
import { toastErrorQuery } from '../utils/error';
import { validatePassword, validateRequired } from '../utils/validations';

type Props = {};

const ChangePasswordPage: FunctionComponent<Props> = () => {
  const [mutate] = useMutation<updateProfile, updateProfileVariables>(UPDATE_PROFILE);

  return (
    <div className="user-form-page">
      <div className="breadcrumbs-area">
        <MainTitle title={i18n._(t`Change Password`)} />
      </div>

      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={yup.object().shape({
          password: validateRequired.concat(validatePassword),
          confirmPassword: validateRequired.oneOf([yup.ref('password')], i18n._(t`Confirm Password does not match`)),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const payload: any = pick(values, ['password']);

            const result: any = await mutate({
              variables: {
                payload,
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              resetForm();
              toast.success(i18n._(t`Success!`));
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => <ProfilePasswordForm loading={isSubmitting} />}
      </Formik>
    </div>
  );
};

export default ChangePasswordPage;
