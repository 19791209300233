import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import AutoresponderExclusionForm from '../components/autoresponder/AutoresponderExclusionForm';
import AutoresponderHolidayForm from '../components/autoresponder/AutoresponderHolidayForm';
import AutoresponderWorkdayForm from '../components/autoresponder/AutoresponderWorkdayForm';
import MainTitle from '../components/common/MainTitle';

type Props = {};

const AutoresponderPage: FunctionComponent<Props> = () => {
  return (
    <div className="closed-page">
      <div className="breadcrumbs-area">
        <MainTitle title={i18n._(t`Autoresponder`)} tips="Settings/Autoresponder" />
      </div>

      <div className="card">
        <div className="card-header">
          <Trans>Out of Office Autoresponder</Trans>
        </div>

        <div className="card-body">
          <div className="form-group">
            <i className="icon-details mr-1" />
            <Trans>
              Outside of the following working hours/days an e-mail (template "out of office") is automatically sent to
              the sender.
            </Trans>
          </div>

          <AutoresponderWorkdayForm />
        </div>
      </div>

      <div className="card">
        <div className="card-header"></div>

        <div className="card-body">
          <div className="card-header">
            <Trans>Non Working Days</Trans>
          </div>

          <div className="form-group">
            <i className="icon-details mr-1" />
            <Trans>Here you can add additional holidays which will be considered as "non working" days.</Trans>
          </div>

          <AutoresponderHolidayForm />
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <Trans>Auto Responder Exclusion</Trans>
        </div>

        <div className="card-body">
          <div className="form-group">
            <i className="icon-details mr-1" />
            <Trans>Add specific email addresses or *@domain.com to exclude all email addresses from a domain</Trans>
          </div>

          <AutoresponderExclusionForm />
        </div>
      </div>
    </div>
  );
};

export default AutoresponderPage;
