import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import MainTitle from '../components/common/MainTitle';
import HomeAssetChart from '../components/home/HomeAssetChart';
import HomeCloserTime from '../components/home/HomeCloserTime';
import HomeFastestCloser from '../components/home/HomeFastestCloser';
import HomeFastestResponder from '../components/home/HomeFastestResponder';
import HomeMonitorChart from '../components/home/HomeMonitorChart';
import HomeResponseTime from '../components/home/HomeResponseTime';
import HomeMessage from '../components/home/HomeMessage';
import HomeTicketVolume from '../components/home/HomeTicketVolume';
import HomeTopResponder from '../components/home/HomeTopResponder';

const HomePage: FunctionComponent = () => {
  return (
    <div className="home-area">
      <div className="breadcrumbs-area">
        <MainTitle title={i18n._(t`Dashboard`)} tips="Admin/Dashboard" />
      </div>

      <div className="card">
        <div className="card-header">
          <Trans>Ticket Volume</Trans>
        </div>
        <div className="card-body">
          <HomeTicketVolume />
        </div>
      </div>

      <div className="row home-boxes">
        <HomeFastestResponder />

        <HomeFastestCloser />

        <HomeTopResponder />

        <HomeResponseTime />

        <HomeCloserTime />
      </div>

      <HomeAssetChart />

      <HomeMonitorChart />

      <HomeMessage />
    </div>
  );
};

export default HomePage;
