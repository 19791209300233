import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import ProductCreateForms from '../components/product/ProductCreateForm';
import { CREATE_PRODUCT, validateProductForm } from '../schema/product';
import { CreateProductPayload, ProductBilling } from '../schema/graphql-global-types';
import { createProduct, createProductVariables } from '../schema/types/createProduct';
import { DefaultRouteParams } from '../utils/const';
import { toastErrorQuery } from '../utils/error';
import { cloneDeep } from 'lodash';

const ProductCreatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ history }) => {
  const [mutate] = useMutation<createProduct, createProductVariables>(CREATE_PRODUCT);

  const initialValues: CreateProductPayload = {
    title: '',
    price: 0,
    youtubeUrl: '',
    content: '',
    tdArticle: '',
    bexioAccountId: '',
    timeTrackerEnabled: false,
    reactionTime: 0,
    billing: ProductBilling.OneTime,
    tagNames: [],
    photoIds: [],
    reports: [],
  };

  return (
    <div className="product-form-page">
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validateProductForm)}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const payload: any = cloneDeep(values);
            payload.price = Number(payload.price);
            payload.reactionTime = Number(payload.reactionTime);

            if (!payload.bexioAccountId) payload.bexioAccountId = 'default';

            const result: any = await mutate({
              variables: {
                payload,
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              toast.success(i18n._(t`Success!`));
              history.push(`/settings/product/${result.data.createProduct.product.id}`);
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => <ProductCreateForms loading={isSubmitting} />}
      </Formik>
    </div>
  );
};

export default withRouter(ProductCreatePage);
