import { useMutation, useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import * as yup from 'yup';
import ButtonSubmit from '../components/form/ButtonSubmit';
import ErrorQuery from '../components/form/ErrorQuery';
import FieldErrorMessage from '../components/form/FieldErrorMessage';
import logo from '../img/logo_homer_150x150.svg';
import logoWhite from '../img/logo_homer_150x150_white.svg';
import { LoginMode } from '../schema/graphql-global-types';
import { GET_SIDEBAR_STATE } from '../schema/sidebar';
import { resetUserPassword, resetUserPasswordVariables } from '../schema/types/resetUserPassword';
import { sidebarState } from '../schema/types/sidebarState';
import { RESET_USER_PASSWORD } from '../schema/user';
import useAuthLayout from '../utils/useAuthLayout';
import { validateEmail } from '../utils/validations';

const urls = window.location.href.split('/');

const ResetPage: FunctionComponent<RouteComponentProps> = ({ match }) => {
  const [success, setSuccess] = useState(false);

  const sidebar = useQuery<sidebarState>(GET_SIDEBAR_STATE);
  const [mutate, { error }] = useMutation<resetUserPassword, resetUserPasswordVariables>(RESET_USER_PASSWORD);

  useAuthLayout();

  const model = {
    redirectUri: match.url === '/admin-forgot' ? `${urls[0]}//${urls[2]}/admin-login` : `${urls[0]}//${urls[2]}/login`,
    email: '',
    mode: match.url === '/admin-forgot' ? LoginMode.User : LoginMode.Person,
  };

  return (
    <div className="auth-area">
      <div className="content">
        <div className="logo">
          {sidebar.data?.sidebarState.darkMode ? (
            <img src={logoWhite} alt="MultiCloud Center" style={{ width: '150px' }} />
          ) : (
            <img src={logo} alt="MultiCloud Center" style={{ width: '150px' }} />
          )}
        </div>

        <h1 className="main-title">
          <Trans>Forgot Password</Trans>
        </h1>

        {error && <ErrorQuery error={error} />}

        {success && (
          <p className="text-center">
            <Trans>Email has been sent!</Trans>
          </p>
        )}

        <Formik
          enableReinitialize
          initialValues={model}
          validationSchema={yup.object().shape({
            email: validateEmail,
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSuccess(false);

            try {
              const result: any = await mutate({
                variables: {
                  payload: values,
                },
              });

              if (!result.errors) {
                resetForm();
                setSuccess(true);
              }
            } catch (e) {}

            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="form">
              <div className="form-group">
                <label>
                  <Trans>Email</Trans>
                </label>

                <div className="has-icon">
                  <i className="icon-mail" />
                  <Field className="form-control" type="email" name="email" placeholder={i18n._(t`Email`)} autoFocus />
                </div>
              </div>

              <FieldErrorMessage name="email" />

              <div className="actions">
                <ButtonSubmit title={i18n._(t`Send`)} className="btn btn-primary btn-full" loading={isSubmitting} />
              </div>
            </Form>
          )}
        </Formik>

        <div className="form-group">
          <NavLink to={match.url === '/admin-forgot' ? '/admin-login' : '/login'} className="text-primary">
            <Trans>Go to Login</Trans>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ResetPage);
