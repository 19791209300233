import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import React, { FC, useCallback, useState } from 'react';
import { getUserLogs, getUserLogsVariables } from '../../schema/types/getUserLogs';
import { Log } from '../../schema/types/Log';
import { GET_USER_LOGS } from '../../schema/user';
import { useVariables } from '../../utils/hooks';
import HistoryItem from '../common/HistoryItem';
import LoadingQuery from '../common/LoadingQuery';
import ErrorQuery from '../form/ErrorQuery';

type Props = {
  userId: string;
};

const UserLogs: FC<Props> = ({ userId }) => {
  const [loaded, setLoaded] = useState(false);

  const [fetchingMore, setFetchingMore] = useState(false);
  const { limit, offset } = useVariables();

  const { data, loading, error, fetchMore } = useQuery<getUserLogs, getUserLogsVariables>(GET_USER_LOGS, {
    variables: {
      id: userId,
      query: {
        limit,
        offset,
      },
    },
    fetchPolicy: 'cache-first',
  });

  const handleMore = useCallback(async () => {
    setFetchingMore(true);

    await fetchMore({
      variables: {
        id: userId,
        query: {
          limit,
          offset: data?.user.logs.items.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          ...prev,
          user: {
            ...prev.user,
            logs: {
              ...prev.user.logs,
              items: [...prev.user.logs.items, ...fetchMoreResult.user.logs.items],
            },
          },
        };
      },
    });

    setFetchingMore(false);
  }, [fetchMore, data, limit, userId]);

  const hasMore = data && data.user && data.user.logs.items.length < data.user.logs.total;

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;
  if (!loaded) return <LoadingQuery />;

  return (
    <div>
      {data!.user.logs.items.length > 0 ? (
        <>
          {data!.user.logs.items.map((el: Log) => (
            <HistoryItem key={el.id} date={el.createdAt} creator={el.creator} content={el.content} tags={el.tags} />
          ))}
        </>
      ) : (
        <div className="text-center p-2">
          <Trans>No logs</Trans>
        </div>
      )}

      {hasMore && (
        <div className="text-right mt-3 form-group">
          <button type="button" className="btn btn-link" disabled={loading || fetchingMore} onClick={handleMore}>
            {loading || fetchingMore ? <i className="fa fa-spinner fa-spin" /> : <Trans>Load more</Trans>}
          </button>
        </div>
      )}
    </div>
  );
};

export default UserLogs;
