import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import React, { FC, useState } from 'react';
import { GET_PERSON_LOGS } from '../../schema/person';
import { getPersonLogs, getPersonLogsVariables } from '../../schema/types/getPersonLogs';
import { Log } from '../../schema/types/Log';
import { useVariables } from '../../utils/hooks';
import HistoryItem from '../common/HistoryItem';
import LoadingQuery from '../common/LoadingQuery';
import ErrorQuery from '../form/ErrorQuery';

type Props = {
  personId: string;
  includeSystemMessages: boolean;
};

const PersonLogs: FC<Props> = ({ personId, includeSystemMessages }) => {
  const [fetchingMore, setFetchingMore] = useState(false);
  const { limit, offset } = useVariables();

  const { data, loading, error, fetchMore } = useQuery<getPersonLogs, getPersonLogsVariables>(GET_PERSON_LOGS, {
    variables: {
      id: personId,
      query: {
        limit,
        offset,
        includeSystemMessages,
      },
    },
    fetchPolicy: 'cache-first',
  });

  const handleMore = async () => {
    setFetchingMore(true);

    await fetchMore({
      variables: {
        id: personId,
        query: {
          limit,
          offset: data?.person.logs.items.length,
          includeSystemMessages,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          ...prev,
          person: {
            ...prev.person,
            logs: {
              ...prev.person.logs,
              items: [...prev.person.logs.items, ...fetchMoreResult.person.logs.items],
            },
          },
        };
      },
    });

    setFetchingMore(false);
  };

  const hasMore = data && data.person && data.person.logs.items.length < data.person.logs.total;

  if (error) return <ErrorQuery error={error} />;
  if (loading) return <LoadingQuery />;

  return (
    <div>
      {data && data.person.logs.items.length > 0 ? (
        <>
          {data.person.logs.items.map((el: Log) => (
            <HistoryItem key={el.id} date={el.createdAt} creator={el.creator} content={el.content} tags={el.tags} />
          ))}
        </>
      ) : (
        <div className="text-center p-2">
          <Trans>No logs</Trans>
        </div>
      )}

      {hasMore && (
        <div className="text-right mt-3 form-group">
          <button type="button" className="btn btn-link" disabled={loading || fetchingMore} onClick={handleMore}>
            {loading || fetchingMore ? <i className="fa fa-spinner fa-spin" /> : <Trans>Load more</Trans>}
          </button>
        </div>
      )}
    </div>
  );
};

export default PersonLogs;
