import DatePicker from 'react-datepicker';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { useLanguage } from '../../schema/locale';

type Props = {
  name: string;
};

const FieldDatePicker: FunctionComponent<Props> = ({ name }) => {
  const language = useLanguage();

  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (e: Date) => {
          form.setFieldValue(name, new Date(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate())).toISOString());
        };

        return (
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="icon-calendar" />
              </div>
            </div>
            <div className="form-control">
              <DatePicker
                selected={new Date(field.value)}
                onChange={handleChange}
                className="datepicker-control"
                dateFormat="yyyy/MM/dd"
                locale={language}
              />
            </div>
          </div>
        );
      }}
    </Field>
  );
};

export default FieldDatePicker;
