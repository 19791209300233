import { useMutation } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import { pick } from 'lodash';
import React, { FunctionComponent } from 'react';
import Modal from 'react-modal';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { BudgetUnit, CreateTimeTrackerProjectPayload } from '../../schema/graphql-global-types';
import {
  CREATE_TIME_TRACKER_PROJECT,
  timeTrackerProjectKeys,
  validateTimeTrackerProjectForm,
} from '../../schema/timeTracker';
import {
  createTimeTrackerProject,
  createTimeTrackerProjectVariables,
} from '../../schema/types/createTimeTrackerProject';
import { DefaultRouteParams } from '../../utils/const';
import { toastErrorQuery } from '../../utils/error';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';
import TimeTrackerCompanyForm from './TimeTrackerCompanyForm';

type Props = {
  show: boolean;
  onClose: () => void;
};

const TimeTrackerCreateEntryModal: FunctionComponent<Props & RouteComponentProps<DefaultRouteParams>> = ({
  history,
  show,
  onClose,
}) => {
  const [mutate] = useMutation<createTimeTrackerProject, createTimeTrackerProjectVariables>(
    CREATE_TIME_TRACKER_PROJECT,
  );

  const initialValues: CreateTimeTrackerProjectPayload = {
    customerId: '',
    title: '',
    budgetCount: 0,
    budgetUnit: BudgetUnit.Currency,
    enableInCustomerView: false,
    specialRates: [],
    entries: [],
    addings: [],
  };

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Add new project</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={yup.object().shape(validateTimeTrackerProjectForm)}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const payload: any = pick(values, timeTrackerProjectKeys.concat(['customerId']));

                  const result: any = await mutate({
                    variables: {
                      payload,
                    },
                  });

                  if (result.errors) {
                    toastErrorQuery(result.errors);
                  } else {
                    toast.success(i18n._(t`Success!`));
                    onClose();
                    history.push(
                      `/support/time-tracking/${result.data.createTimeTrackerProject.timeTrackerProject.id}`,
                    );
                  }
                } catch (e) {
                  toastErrorQuery(e);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="modal-body">
                    <div className="form-group">
                      <label>
                        <Trans>Customer</Trans>
                      </label>

                      <TimeTrackerCompanyForm />
                    </div>

                    <div className="form-group">
                      <label>
                        <Trans>Title</Trans>
                      </label>

                      <Field type="text" className="form-control" name="title" />

                      <FieldErrorMessage name="title" />
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button type="button" className="btn btn-link" onClick={onClose}>
                      <Trans>Cancel</Trans>
                    </button>

                    <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withRouter(TimeTrackerCreateEntryModal);
