import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import { AssetReminder } from '../../schema/types/AssetReminder';
import DateFormat from '../common/DateFormat';
import ButtonDelete from '../form/ButtonDelete';

type Props = {
  model: AssetReminder;
  onEdit: () => void;
  onRemove: () => void;
};

const AssetReminderFormItem: FunctionComponent<Props> = ({ model, onEdit, onRemove }) => {
  return (
    <tr className="custom-table-body">
      <td className="column title">{model.title}</td>
      <td className="column cycle">{model.cycle}</td>
      <td className="column date">
        <DateFormat value={model.startDate} />
      </td>
      <td className="column actions">
        <a data-tip data-for={`edit-${model.id}`} className="button button-archive text-xs mr-2" onClick={onEdit}>
          <i className="icon-edit" />
        </a>
        <ReactTooltip id={`edit-${model.id}`}>
          <Trans>Edit</Trans>
        </ReactTooltip>

        <ButtonDelete onClick={onRemove} />
      </td>
    </tr>
  );
};

export default AssetReminderFormItem;
