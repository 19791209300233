import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import { format } from 'date-fns';
import React, { FunctionComponent, useState } from 'react';
import { useQuery } from '@apollo/client';
import ReactTable, { CellInfo, RowInfo } from 'react-table';
import ReactTooltip from 'react-tooltip';
import CurrencyDisplay from '../components/common/CurrencyDisplay';
import MainTitle from '../components/common/MainTitle';
import NumberDisplay from '../components/common/NumberDisplay';
import LoadingTable from '../components/common/LoadingTable';
import UserAvatarName from '../components/common/UserAvatarName';
import { BudgetUnit } from '../schema/graphql-global-types';
import { PERSON_GET_TIME_TRACKER_PROJECTS } from '../schema/timeTracker';
import {
  personGetTimeTrackerProjects,
  personGetTimeTrackerProjectsVariables,
} from '../schema/types/personGetTimeTrackerProjects';
import { TimeTrackerProjectAdding } from '../schema/types/TimeTrackerProjectAdding';
import { TimeTrackerProjectEntry } from '../schema/types/TimeTrackerProjectEntry';
import { getCompanyId } from '../utils/auth';
import { tableOptions } from '../utils/const';
import ErrorQuery from '../components/form/ErrorQuery';
import { get } from 'lodash';
import { useVariables } from '../utils/hooks';
import { getTimeTrackerEntryPrice, sToTime } from '../utils/utils';

const PersonTimeTrackerPage: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);
  const { search$, search, filter, archived, limit, setLimit, offset, setOffset, orderBy, orderDirection } =
    useVariables();

  const variables = {
    id: getCompanyId(),
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
        enableInCustomerView: true,
      },
    },
  };

  const { data, previousData, loading, error } = useQuery<
    personGetTimeTrackerProjects,
    personGetTimeTrackerProjectsVariables
  >(PERSON_GET_TIME_TRACKER_PROJECTS, {
    variables,
  });

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.company.timeTrackerProjects.items ?? previousData?.company.timeTrackerProjects.items ?? [];
  const pages =
    data && data.company.timeTrackerProjects ? Math.ceil(data.company.timeTrackerProjects.total / limit) : -1;

  const columns = [
    {
      Header: '',
      expander: true,
      width: 50,
      className: 'text-center',
      Expander: (cell: CellInfo) => (
        <>
          <span data-tip data-for={`expand-${cell.original.id}`} className="button button-expand text-xs">
            {cell.isExpanded ? <i className="icon-dropdown" /> : <i className="icon-expand" />}
          </span>
          <ReactTooltip id={`expand-${cell.original.id}`}>
            <Trans>Expand</Trans>
          </ReactTooltip>
        </>
      ),
    },
    {
      Header: i18n._(t`Project`),
      accessor: 'title',
    },
    {
      Header: i18n._(t`Entries / Tracked h / Amount`),
      accessor: 'title',
      Cell: (cell: CellInfo) => {
        const pcs = cell.original.entries.length;

        const total = cell.original.entries.reduce(
          (a: number, b: TimeTrackerProjectEntry) => a + getTimeTrackerEntryPrice(cell.original, b),
          0,
        );

        const seconds = cell.original.entries.reduce(
          (a: number, b: TimeTrackerProjectEntry) => a + b.durationSeconds,
          0,
        );

        return (
          <div>
            {pcs} pcs / {sToTime(seconds)}h / <CurrencyDisplay value={total} />
          </div>
        );
      },
    },
    {
      Header: i18n._(t`Budget`),
      accessor: 'budgetCount',
      Cell: (cell: CellInfo) => {
        if (!cell.value) return null;

        if (cell.original.budgetUnit === BudgetUnit.Currency) {
          const total = cell.original.entries.reduce(
            (a: number, b: TimeTrackerProjectEntry) => a + getTimeTrackerEntryPrice(cell.original, b),
            0,
          );
          const result = Math.min((total / cell.value) * 100 || 0, 100);

          return (
            <div className="budget">
              <CurrencyDisplay value={cell.value} />

              <div className="progress">
                <div
                  className={classNames('progress-bar', { warning: result >= 75 })}
                  style={{ width: `${result}%` }}
                />
              </div>
            </div>
          );
        }

        if (cell.original.budgetUnit === BudgetUnit.Hour) {
          const seconds = cell.original.entries.reduce(
            (a: number, b: TimeTrackerProjectEntry) => a + b.durationSeconds,
            0,
          );
          const result = Math.min((seconds / cell.value) * 100 || 0, 100);

          return (
            <div className="budget">
              {sToTime(cell.value)}h
              <div className="progress">
                <div
                  className={classNames('progress-bar', { warning: result >= 75 })}
                  style={{ width: `${result}%` }}
                />
              </div>
            </div>
          );
        }

        const pcs = cell.original.entries.length;
        const result = Math.min((pcs / cell.value) * 100 || 0, 100);

        return (
          <div className="budget">
            {cell.value} pcs
            <div className="progress">
              <div className={classNames('progress-bar', { warning: result >= 75 })} style={{ width: `${result}%` }} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="time-trackers card-body">
      <div className="table-actions">
        <div className="breadcrumbs-area">
          <MainTitle title={i18n._(t`Projects`)} />
        </div>

        <div className="item search input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <i className="icon-search" />
            </div>
          </div>
          <input
            className="form-control"
            placeholder={i18n._(t`Search`)}
            onChange={(e) => search$.next(e.target.value)}
          />
        </div>
      </div>

      {!loaded ? (
        <LoadingTable />
      ) : (
        <ReactTable
          data={items}
          columns={columns}
          pages={pages}
          pageSize={limit}
          manual
          onFetchData={(state) => {
            setOffset(state.page * state.pageSize);
          }}
          onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
          SubComponent={(rowInfo: RowInfo) => {
            const subtotal = rowInfo.original.addings.reduce((value: number, el: TimeTrackerProjectAdding) => {
              return el.price * el.pieces + value;
            }, 0);

            const total = rowInfo.original.addings.reduce((value: number, el: TimeTrackerProjectAdding) => {
              return (el.price - (el.price * el.discountPercent) / 100) * el.pieces + value;
            }, 0);

            return (
              <div className="card-body">
                {rowInfo.original.entries.length > 0 && (
                  <div className="form-group">
                    <div className="block-title">
                      <Trans>Time Entries</Trans>
                    </div>

                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>
                              <Trans>Date</Trans>
                            </th>
                            <th>
                              <Trans>Description</Trans>
                            </th>
                            <th>
                              <Trans>Type</Trans>
                            </th>
                            <th>
                              <Trans>User</Trans>
                            </th>
                            <th>
                              <Trans>Duration</Trans>
                            </th>
                            <th>
                              <Trans>Total</Trans>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {rowInfo.original.entries.map((el: TimeTrackerProjectEntry) => (
                            <tr key={el.id}>
                              <td>{format(new Date(el.date), 'yyyy-MM-dd')}</td>
                              <td>{el.description}</td>
                              <td>{el.product.title}</td>
                              <td>
                                <UserAvatarName user={el.user} />
                              </td>
                              <td>{sToTime(el.durationSeconds)}h</td>
                              <td>
                                <NumberDisplay value={getTimeTrackerEntryPrice(rowInfo.original, el)} />
                              </td>
                            </tr>
                          ))}
                        </tbody>

                        <tfoot>
                          <tr>
                            <td colSpan={4} className="text-right">
                              <strong>
                                <Trans>Total</Trans>
                              </strong>
                            </td>
                            <td>
                              <strong>
                                {sToTime(
                                  rowInfo.original.entries.reduce(
                                    (a: number, b: TimeTrackerProjectEntry) => a + b.durationSeconds,
                                    0,
                                  ),
                                )}{' '}
                                h
                              </strong>
                            </td>
                            <td>
                              <strong>
                                <CurrencyDisplay
                                  value={rowInfo.original.entries.reduce(
                                    (a: number, b: TimeTrackerProjectEntry) =>
                                      a + getTimeTrackerEntryPrice(rowInfo.original, b),
                                    0,
                                  )}
                                />
                              </strong>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                )}

                {rowInfo.original.addings.length > 0 && (
                  <div className="form-group">
                    <div className="block-title">
                      <Trans>Addings</Trans>
                    </div>

                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>
                              <Trans>Product</Trans>
                            </th>
                            <th>
                              <Trans>Price</Trans>
                            </th>
                            <th>
                              <Trans>Pieces</Trans>
                            </th>
                            <th>
                              <Trans>Discount Percent</Trans>
                            </th>
                            <th>
                              <Trans>Total</Trans>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {rowInfo.original.addings.map((el: TimeTrackerProjectAdding, id: number) => {
                            const price = get(rowInfo.original, `addings[${id}].price`);
                            const pieces = get(rowInfo.original, `addings[${id}].pieces`);
                            const discountPercent = get(rowInfo.original, `addings[${id}].discountPercent`);
                            const value = (price - (price * discountPercent) / 100) * pieces;

                            return (
                              <tr key={id}>
                                <td>{el.title || el.product.title}</td>
                                <td>{el.price}</td>
                                <td>{el.pieces}</td>
                                <td>{el.discountPercent}</td>
                                <td>
                                  <NumberDisplay value={value} />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>

                        {subtotal > 0 && (
                          <tfoot>
                            <tr>
                              <td colSpan={4} className="text-right">
                                <Trans>Subtotal</Trans>
                              </td>
                              <td>
                                <CurrencyDisplay value={subtotal} />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={4} className="text-right">
                                <strong>
                                  <Trans>Total</Trans>
                                </strong>
                              </td>
                              <td>
                                <strong>
                                  <CurrencyDisplay value={total} />
                                </strong>
                              </td>
                            </tr>
                            <tr className="font-italic text-xs">
                              <td colSpan={4} className="text-right">
                                (<Trans>Total Discount</Trans>)
                              </td>
                              <td>
                                <CurrencyDisplay value={subtotal - total} />
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
          {...tableOptions()}
        />
      )}
    </div>
  );
};

export default PersonTimeTrackerPage;
