import { useMutation, useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik, FormikProps } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import { pick } from 'lodash';
import React, { FunctionComponent } from 'react';
import Modal from 'react-modal';
import { RouteComponentProps, withRouter } from 'react-router';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { GET_TIME_TRACKER_CONFIG } from '../../schema/config';
import { BudgetUnit, TimeTrackerProjectEntryPayload } from '../../schema/graphql-global-types';
import {
  CREATE_TIME_TRACKER_PROJECT,
  CREATE_TIME_TRACKER_PROJECT_ENTRY,
  timeTrackerProjectEntryKeys,
  validateTimeTrackerProjectEntryForm,
} from '../../schema/timeTracker';
import { Company } from '../../schema/types/Company';
import {
  createTimeTrackerProject,
  createTimeTrackerProjectVariables,
} from '../../schema/types/createTimeTrackerProject';
import {
  createTimeTrackerProjectEntry,
  createTimeTrackerProjectEntryVariables,
} from '../../schema/types/createTimeTrackerProjectEntry';
import { getTimeTrackerConfig } from '../../schema/types/getTimeTrackerConfig';
import { TimeTrackerProject } from '../../schema/types/TimeTrackerProject';
import { DefaultRouteParams } from '../../utils/const';
import { toastErrorQuery } from '../../utils/error';
import { useViewer } from '../../utils/hooks';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldDatepicker from '../form/FieldDatepicker';
import TimeTrackerCompanyProjectForm from './TimeTrackerCompanyProjectForm';
import TimeTrackerProductForm from './TimeTrackerProductForm';

type Props = {
  show: boolean;
  company?: Company | null;
  project?: TimeTrackerProject | null;
  description?: string;
  onClose: () => void;
};

const TimeTrackerCreateEntryModal: FunctionComponent<Props & RouteComponentProps<DefaultRouteParams>> = ({
  show,
  company,
  project,
  description,
  onClose,
}) => {
  const today = new Date();

  const { data } = useViewer();
  const { data: config } = useQuery<getTimeTrackerConfig>(GET_TIME_TRACKER_CONFIG);

  const [mutate] = useMutation<createTimeTrackerProjectEntry, createTimeTrackerProjectEntryVariables>(
    CREATE_TIME_TRACKER_PROJECT_ENTRY,
  );

  const [createProject] = useMutation<createTimeTrackerProject, createTimeTrackerProjectVariables>(
    CREATE_TIME_TRACKER_PROJECT,
  );

  const initialValues: TimeTrackerProjectEntryPayload & {
    project: any;
    projectId: string;
    company: any;
    companyId: string;
  } = {
    userId: data?.viewer.user?.id || '',
    productId: '',
    date: today,
    durationSeconds: 0,
    description: description || '',
    project: project || null,
    projectId: project?.id || '',
    company: company || null,
    companyId: company?.id || '',
  };

  if (!data?.viewer.user?.id) return null;

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Add time to the project</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={yup.object().shape(validateTimeTrackerProjectEntryForm)}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const payload: any = pick(values, timeTrackerProjectEntryKeys);

                  if (!payload.productId) payload.productId = data?.viewer.user?.timeTrackerDefaultProductId;

                  let projectId = values.projectId || '';

                  if (!projectId) {
                    const project = await createProject({
                      variables: {
                        payload: {
                          customerId: values.companyId,
                          title: config?.timeTrackerConfig?.defaultProjectName || '',
                          budgetCount: 0,
                          budgetUnit: BudgetUnit.Currency,
                          enableInCustomerView: false,
                          specialRates: [],
                          entries: [],
                          addings: [],
                        },
                      },
                    });

                    projectId = project.data?.createTimeTrackerProject.timeTrackerProject.id || '';
                  }

                  const result: any = await mutate({
                    variables: {
                      projectId,
                      payload,
                    },
                  });

                  if (result.errors) {
                    toastErrorQuery(result.errors);
                  } else {
                    toast.success(i18n._(t`Success!`));
                    onClose();
                  }
                } catch (e) {
                  toastErrorQuery(e);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-sm-6 form-group">
                        <label>
                          <Trans>Duration</Trans>
                        </label>

                        <Field name="durationSeconds">
                          {({ form }: { form: FormikProps<any> }) => {
                            const handleChange = (e: any) => {
                              const val = e.target.value.split(':');

                              if (val.length > 1) {
                                const hour = Number(val[0].replace('_', '')) || 0;
                                const minute = Number(val[1].replace('_', '')) || 0;
                                form.setFieldValue('durationSeconds', hour * 3600 + minute * 60);
                              }
                            };

                            return (
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="icon-time" />
                                  </div>
                                </div>
                                <MaskedInput
                                  mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                                  onChange={handleChange}
                                  className="form-control"
                                  placeholder="00:00"
                                />
                              </div>
                            );
                          }}
                        </Field>
                      </div>

                      <div className="col-sm-6 form-group">
                        <label>
                          <Trans>Date</Trans>
                        </label>

                        <FieldDatepicker name="date" />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="d-flex justify-content-between">
                        <Trans>Customer / Project</Trans>

                        <Field name="project">
                          {({ field }: { field: FieldInputProps<TimeTrackerProject> }) => {
                            if (!field.value) return null;

                            return <div className="text-dark">{field.value.title}</div>;
                          }}
                        </Field>
                      </label>

                      <TimeTrackerCompanyProjectForm />
                    </div>

                    <div className="form-group">
                      <label>
                        <Trans>Type</Trans>
                      </label>

                      <TimeTrackerProductForm name="product" />
                    </div>

                    <div className="form-group">
                      <label>
                        <Trans>Description</Trans>
                      </label>

                      <Field type="text" component="textarea" className="form-control" name="description" rows={5} />
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button type="button" className="btn btn-link" onClick={onClose}>
                      <Trans>Close</Trans>
                    </button>

                    <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withRouter(TimeTrackerCreateEntryModal);
