import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, FormikProps } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { GET_SYSTEM_TEXT_TEMPLATE } from '../../schema/textTemplate';
import ButtonSubmit from '../form/ButtonSubmit';
import OfferEditor from './OfferEditor';
import OfferEditorSortable from './OfferEditorSortable';
import OfferSaveTemplate from './OfferSaveTemplate';
import OfferSidebar from './OfferSidebar';
import classNames from 'classnames';
import OfferTextTemplateModal from './OfferTextTemplateModal';

type Props = {
  loading: boolean;
  submitForm: () => void;
};

const OfferUpdateForm: FunctionComponent<Props> = ({ loading, submitForm }) => {
  const [sortable, setSortable] = useState(false);

  const handleSortable = useCallback(() => setSortable((prevState) => !prevState), []);

  const [showText, setShowText] = useState(false);
  const toggleTextModal = useCallback(() => setShowText((prevState) => !prevState), [setShowText]);

  const defaultTemplate = useQuery(GET_SYSTEM_TEXT_TEMPLATE, {
    variables: {
      key: 'offer_email',
    },
  });

  const handleSaveSend = useCallback(() => {
    submitForm();
    toggleTextModal();
  }, [submitForm, toggleTextModal]);

  return (
    <Form>
      <div className="form-breadcrumbs-area">
        <NavLink to="/crm/offer">
          <Trans>Offer</Trans>
        </NavLink>

        <i className="fa fa-angle-left" />

        <Field name="id">
          {({ field }: { field: FieldInputProps<string> }) => {
            if (field.value) return <Trans>Edit offer</Trans>;

            return <Trans>Add new offer</Trans>;
          }}
        </Field>
      </div>

      <div className="table-actions form-table-actions">
        <div className="breadcrumbs-area">
          <Field name="title">
            {({ field }: { field: FieldInputProps<string> }) => {
              return <div className="main-title">{field.value}</div>;
            }}
          </Field>
        </div>

        <Field name="companyId">
          {({ field }: { field: any }) => {
            if (!field.value) return '';

            return <OfferSaveTemplate />;
          }}
        </Field>

        <ButtonSubmit className="btn btn-primary btn-sm ml-4" title={i18n._(t`Save`)} loading={loading} />

        <button type="button" className="btn btn-primary btn-sm ml-3" disabled={loading} onClick={handleSaveSend}>
          {loading ? <i className="fa fa-spinner fa-spin" /> : <Trans>Save And Send</Trans>}
        </button>
      </div>

      <Field name="id">
        {({ field, form }: { field: any; form: FormikProps<any> }) => {
          if (!field.value) return '';

          return (
            <OfferTextTemplateModal
              to={form.values.email}
              offerId={field.value}
              template={
                form.values.favoriteTextTemplate || (defaultTemplate.data && defaultTemplate.data.systemTextTemplate)
              }
              show={showText}
              onClose={toggleTextModal}
            />
          );
        }}
      </Field>

      <div className="documentation-form">
        <div className={classNames('documentation-editor', { 'd-none': sortable })}>
          <OfferEditor />
        </div>

        {sortable && <OfferEditorSortable />}

        <OfferSidebar onSortable={handleSortable} />
      </div>
    </Form>
  );
};

export default OfferUpdateForm;
