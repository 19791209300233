let storage = {};
let isSessionStorageSupported = false;

/**
 * When Safari (OS X or iOS) is in private browsing mode,
 * it appears as though sessionStorage is available,
 * but trying to call .setItem throws an exception.
 */
const testKey = 'test';
const session = window.sessionStorage;
try {
  session.setItem(testKey, '1');
  session.removeItem(testKey);
  isSessionStorageSupported = true;
} catch (e) {
  console.log('no sessionStorage');
}

/**
 * Set a value to storage
 * @param key
 * @param value
 */
export const setSession = (key, value) => {
  if (typeof value === 'undefined') return;

  if (isSessionStorageSupported) {
    window.sessionStorage[key] = JSON.stringify(value);
  } else {
    storage[key] = value;
  }
};

/**
 * Get a value from storage
 * @param key
 * @returns {*}
 */
export const getSession = key => {
  if (isSessionStorageSupported) {
    if (key) {
      try {
        return JSON.parse(window.sessionStorage[key]);
      } catch (e) {
        return window.sessionStorage[key];
      }
    }

    return window.sessionStorage;
  }

  return key ? storage[key] : storage;
};

/**
 * Delete a value
 * @param key
 */
export const removeSession = key => {
  if (isSessionStorageSupported) {
    delete window.sessionStorage[key];
  } else {
    delete storage[key];
  }
};

/**
 * Clear all
 */
export const clearSession = () => {
  if (isSessionStorageSupported) {
    window.sessionStorage.clear();
  } else {
    storage = {};
  }
};
