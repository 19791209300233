import gql from 'graphql-tag';
import { PERSON_COMPANY } from './person';
import { USER_DETAIL } from './user';

export const LOGIN = gql`
  mutation login($payload: LoginPayload!) {
    login(payload: $payload) {
      accessToken
      mfaToken
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const LOGIN_OTP = gql`
  mutation loginOtp($payload: LoginOtpPayload!) {
    loginOtp(payload: $payload) {
      accessToken
    }
  }
`;

export const VIEWER = gql`
  fragment Viewer on Viewer {
    mfa
    favoriteTemplates {
      id
      title
      content
    }
    user {
      ...UserDetail
      fullName
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
    person {
      id
      firstName
      lastName
      fullName
      gender
      function
      email
      companyPhone
      cellphone
      privateAddress1
      privateAddress2
      privateZip
      privateCity
      avatar {
        id
        url
        imageInstance(profile: "thumbnail") {
          object {
            id
            url
          }
        }
      }
    }
    companies {
      ...PersonCompany
    }
    mode
    config {
      confirmShowAllEmail
      language
    }
  }
  ${USER_DETAIL}
  ${PERSON_COMPANY}
`;

export const GET_VIEWER = gql`
  query getViewer {
    viewer {
      ...Viewer
    }
  }
  ${VIEWER}
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile($payload: UpdateProfilePayload!) {
    updateProfile(payload: $payload) {
      ...Viewer
    }
  }
  ${VIEWER}
`;

export const UPDATE_VIEWER_CONFIG = gql`
  mutation updateViewerConfig($payload: ViewerConfigPayload!) {
    updateViewerConfig(payload: $payload)
  }
`;

export const UPDATE_FAVORITE_TEMPLATES = gql`
  mutation updateFavoriteTemplates($ids: [ID!]!) {
    updateFavoriteTemplates(ids: $ids) {
      ...Viewer
    }
  }
  ${VIEWER}
`;

export const ENABLE_MFA = gql`
  mutation enableMfa($payload: EnableMfaPayload!) {
    enableMfa(payload: $payload) {
      otpAuthUrl
    }
  }
`;

export const ACTIVATE_MFA = gql`
  mutation activateMfa($otp: String!) {
    activateMfa(otp: $otp)
  }
`;

export const DISABLE_MFA = gql`
  mutation disableMfa {
    disableMfa
  }
`;
