import { cloneDeep } from '@apollo/client/utilities';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import { GET_PRODUCTS } from '../../schema/product';
import { GET_PRODUCT_CONTRACT_TYPES } from '../../schema/productContractType';
import { getProductContractTypes, getProductContractTypesVariables } from '../../schema/types/getProductContractTypes';
import { getProducts, getProductsVariables } from '../../schema/types/getProducts';
import { defaultQuery, selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';
import ErrorQuery from '../form/ErrorQuery';

type Props = {
  name: string;
};

const ProductReportingSearch: FunctionComponent<Props> = ({ name }) => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const contracts = useQuery<getProductContractTypes, getProductContractTypesVariables>(GET_PRODUCT_CONTRACT_TYPES, {
    variables: defaultQuery,
    fetchPolicy: 'cache-first',
  });

  const { data, loading, error } = useQuery<getProducts, getProductsVariables>(GET_PRODUCTS, {
    variables,
  });

  if (!contracts.data) return <div />;

  if (error) return <ErrorQuery error={error} />;
  if (!data && !loading) return <div />;

  return (
    <Field name={name}>
      {({ form }: { form: FormikProps<any> }) => {
        const handleChange = (value: any) => {
          const values = cloneDeep(form.values[name]);
          values.push({
            contractTypeId: contracts.data!.productContractTypes.items[0].id,
            pieces: 1,
            productId: value.value.id,
            product: {
              id: value.value.id,
              title: value.value.title,
            },
            contractType: {
              id: contracts.data!.productContractTypes.items[0].id,
              name: contracts.data!.productContractTypes.items[0].name,
            },
          });
          form.setFieldValue(name, values);
        };

        const handleLoad = (value: string) => search$.next(value);

        const options = loading
          ? []
          : data!.products.items.map((el) => ({
              value: el,
              label: el.title,
            }));

        return (
          <Select
            options={options}
            isLoading={loading}
            onChange={handleChange}
            onInputChange={handleLoad}
            placeholder={i18n._(t`Search...`)}
            {...selectOptions}
          />
        );
      }}
    </Field>
  );
};

export default ProductReportingSearch;
