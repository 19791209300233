import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import copy from 'copy-to-clipboard';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { getStorage, setStorage } from '../../utils/storage';
import FieldErrorMessage from '../form/FieldErrorMessage';
import OfferCustomerForm from './OfferCustomerForm';
import OfferPersonForm from './OfferPersonForm';
import OfferResponsibleForm from './OfferResponsibleForm';
import OfferTagForm from './OfferTagForm';
import OfferTemplateForm from './OfferTemplateForm';
import OfferTemplateModal from './OfferTemplateModal';
import classNames from 'classnames';
import OfferVersionModal from './OfferVersionModal';

const handleCopy = (value: string) => () => {
  copy(value);
  toast.success(i18n._(t`Copied!`));
};

type Props = {
  onSortable: () => void;
};

const OfferSidebar: FunctionComponent<Props> = ({ onSortable }) => {
  const [showTemplate, setShowTemplate] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showVersion, setShowVersion] = useState(false);

  const toggleTemplateModal = useCallback(() => setShowTemplate((prevState) => !prevState), []);
  const toggleMore = useCallback(() => setShowMore((prevState) => !prevState), []);

  const toggleVersionModal = useCallback(() => setShowVersion((prevState) => !prevState), []);

  const [showGeneral, setShowGeneral] = useState<boolean>(getStorage('showOfferGeneral') ?? true);
  const toggleShowGeneral = useCallback(
    () =>
      setShowGeneral((prevState) => {
        setStorage('showOfferGeneral', !prevState);
        return !prevState;
      }),
    [],
  );

  const [showAdditional, setShowAdditional] = useState<boolean>(getStorage('showOfferAdditional') ?? true);
  const toggleShowAdditional = useCallback(
    () =>
      setShowAdditional((prevState) => {
        setStorage('showOfferAdditional', !prevState);
        return !prevState;
      }),
    [],
  );

  return (
    <div className="documentation-sidebar">
      <div className="container">
        <div className="panel">
          <label className="label-lg mb-1">
            <Trans>Internal Link</Trans>
          </label>

          <div className="d-flex justify-content-between">
            <Field name="internalUrl">
              {({ field }: { field: any }) => {
                if (!field.value) return '';

                return (
                  <a href={field.value} className="button" target="_blank">
                    <Trans>Preview Link</Trans>
                    <i className="icon-preview" />
                  </a>
                );
              }}
            </Field>

            <Field name="internalUrl">
              {({ field }: { field: any }) => {
                if (!field.value) return '';

                return (
                  <button type="button" className="button" onClick={handleCopy(field.value)}>
                    Copy Link
                    <i className="icon-copy" />
                  </button>
                );
              }}
            </Field>
          </div>

          <hr />

          <label className="label-lg mb-1">
            <Trans>External Customer Link</Trans>
          </label>

          <Field name="publicUrl">
            {({ field }: { field: any }) => {
              if (!field.value) return '';

              return (
                <button type="button" className="button" onClick={handleCopy(field.value)}>
                  Copy Link
                  <i className="icon-copy" />
                </button>
              );
            }}
          </Field>
        </div>

        <div className="panel">
          <button type="button" className="panel-header" onClick={toggleShowGeneral}>
            <Trans>General information</Trans>

            {showGeneral ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" />}
          </button>

          {showGeneral && (
            <>
              <Field name="template">
                {({ field }: { field: any }) => {
                  if (!field.value) return null;

                  return (
                    <div className="form-group text-danger text-center">
                      <Trans>This is a Template</Trans>
                    </div>
                  );
                }}
              </Field>

              <div className="form-group">
                <label>
                  <Trans>Offer Title</Trans>
                </label>

                <Field type="text" className="form-control" name="title" placeholder={i18n._(t`Title`)} />

                <FieldErrorMessage name="title" />
              </div>

              <Field name="template">
                {({ field }: { field: any }) => {
                  if (field.value) return null;

                  return (
                    <div className="form-group">
                      <label>
                        <Trans>Customer (Company)</Trans>
                      </label>

                      <OfferCustomerForm />

                      <FieldErrorMessage name="companyId" />
                    </div>
                  );
                }}
              </Field>

              <label className="form-label">
                <Trans>Person</Trans>
              </label>

              <Field name="companyId">
                {({ field }: { field: any }) => {
                  return <OfferPersonForm companyId={field.value} />;
                }}
              </Field>

              <button type="button" className="btn btn-link" onClick={toggleMore}>
                <Trans>Details</Trans>

                {showMore ? <i className="fa fa-angle-up ml-2" /> : <i className="fa fa-angle-down ml-2" />}
              </button>

              <div className={classNames('history-item', { 'd-none': !showMore })}>
                <div className="form-group">
                  <label>
                    <Trans>First Name</Trans>
                  </label>
                  <Field type="text" className="form-control" name="firstName" />
                </div>

                <div className="form-group">
                  <label>
                    <Trans>Last Name</Trans>
                  </label>
                  <Field type="text" className="form-control" name="lastName" />
                </div>

                <div className="form-group">
                  <label>
                    <Trans>Email</Trans>
                  </label>
                  <Field type="email" className="form-control" name="email" />
                </div>

                <div className="form-group">
                  <label>
                    <Trans>Position</Trans>
                  </label>
                  <Field type="text" className="form-control" name="function" />
                </div>
              </div>

              <div className="form-group">
                <OfferResponsibleForm />
              </div>
            </>
          )}
        </div>

        <div className="panel">
          <button type="button" className="panel-header" onClick={toggleShowAdditional}>
            <Trans>Additional settings</Trans>

            {showAdditional ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" />}
          </button>

          {showAdditional && (
            <>
              <div className="form-group">
                <label>
                  <Trans>Tags</Trans>
                </label>
                <OfferTagForm />
              </div>

              <div className="d-flex justify-content-between">
                <label className="form-label">
                  <Trans>Select Template</Trans>
                </label>

                <label className="form-label">
                  <Trans>Optional</Trans>
                </label>
              </div>

              <OfferTemplateForm />

              <OfferTemplateModal show={showTemplate} onClose={toggleTemplateModal} />
            </>
          )}
        </div>

        <div className="panel d-flex justify-content-between">
          <Field name="versions.items">
            {({ field, form }: { field: any; form: FormikProps<any> }) => {
              if (!field.value) return '';

              return (
                <>
                  <button type="button" className="button" onClick={toggleVersionModal}>
                    <label>
                      <Trans>Version History</Trans>
                    </label>
                    <i className="icon-restore" />
                  </button>

                  <OfferVersionModal
                    show={showVersion}
                    id={form.values.id}
                    items={field.value}
                    onClose={toggleVersionModal}
                  />
                </>
              );
            }}
          </Field>

          <button type="button" className="button" onClick={onSortable}>
            <Trans>Toggle Sort</Trans>
            <i className="icon-sort" />
          </button>
        </div>

        <div className="form-group">&nbsp;</div>
      </div>
    </div>
  );
};

export default OfferSidebar;
