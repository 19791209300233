import { cloneDeep } from 'lodash';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { pick } from 'lodash';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import DocumentationElementsCreateForms from '../components/documentation/DocumentationElementsCreateForms';
import ErrorQuery from '../components/form/ErrorQuery';
import LoadingForm from '../components/common/LoadingForm';
import {
  GET_DOCUMENT_TEMPLATE_ELEMENT,
  UPDATE_DOCUMENT_TEMPLATE_ELEMENT,
  validateDocumentTemplateElementForm,
} from '../schema/documentTemplateElement';
import {
  getDocumentTemplateElement,
  getDocumentTemplateElementVariables,
} from '../schema/types/getDocumentTemplateElement';
import {
  updateDocumentTemplateElement,
  updateDocumentTemplateElementVariables,
} from '../schema/types/updateDocumentTemplateElement';
import { DefaultRouteParams } from '../utils/const';
import { toastErrorQuery } from '../utils/error';

const keys = ['title', 'content', 'public'];

const DocumentElementUpdatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ match, history }) => {
  const [mutate] = useMutation<updateDocumentTemplateElement, updateDocumentTemplateElementVariables>(
    UPDATE_DOCUMENT_TEMPLATE_ELEMENT,
  );

  const { data, loading, error } = useQuery<getDocumentTemplateElement, getDocumentTemplateElementVariables>(
    GET_DOCUMENT_TEMPLATE_ELEMENT,
    {
      variables: {
        query: match.params.id,
      },
    },
  );

  if (loading) return <LoadingForm />;
  if (error) return <ErrorQuery error={error} />;
  if (!data) return <div />;

  const model = cloneDeep(data.documentTemplateElement);

  return (
    <div className="document-form-page">
      <div className="card-body">
        <Formik
          enableReinitialize
          initialValues={model}
          validationSchema={yup.object().shape(validateDocumentTemplateElementForm)}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const payload: any = pick(values, keys);

              const result: any = await mutate({
                variables: {
                  id: match.params.id,
                  payload,
                },
              });

              if (result.errors) {
                toastErrorQuery(result.errors);
              } else {
                toast.success(i18n._(t`Success!`));
                history.push('/documentation/element');
              }
            } catch (e) {
              toastErrorQuery(e);
            }

            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => <DocumentationElementsCreateForms loading={isSubmitting} />}
        </Formik>
      </div>
    </div>
  );
};

export default withRouter(DocumentElementUpdatePage);
