import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent, useCallback } from 'react';
import Select from 'react-select';
import { GET_TIME_TRACKER_PROJECTS } from '../../schema/timeTracker';
import { getTimeTrackerProjects, getTimeTrackerProjectsVariables } from '../../schema/types/getTimeTrackerProjects';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {
  name: string;
};

const ReportProjectForm: FunctionComponent<Props> = ({ name }) => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getTimeTrackerProjects, getTimeTrackerProjectsVariables>(
    GET_TIME_TRACKER_PROJECTS,
    {
      variables,
    },
  );

  const handleLoad = useCallback((value: string) => search$.next(value), [search$]);

  if (!data && !loading) return <div />;

  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (values: any) => {
          const result = values ? values.map((el: any) => el.value) : [];
          form.setFieldValue(name, result);
        };

        const model = field.value
          ? field.value.map((el: any) => ({
              value: el.id,
              label: el.title,
            }))
          : [];

        const options = loading
          ? []
          : data?.timeTrackerProjects.items.map((el) => ({
              value: el.id,
              label: `${el.customer.name} - ${el.title}`,
            }));

        return (
          <Select
            defaultValue={model}
            options={options}
            isLoading={loading}
            isMulti
            cacheOptions
            defaultOptions
            onChange={handleChange}
            onInputChange={handleLoad}
            placeholder={i18n._(t`All Projects`)}
            {...selectOptions}
          />
        );
      }}
    </Field>
  );
};

export default ReportProjectForm;
