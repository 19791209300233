import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { pick } from 'lodash';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import ProductUpdateForms from '../components/product/ProductUpdateForms';
import ErrorQuery from '../components/form/ErrorQuery';
import LoadingForm from '../components/common/LoadingForm';
import { GET_PRODUCT, UPDATE_PRODUCT, validateProductForm } from '../schema/product';
import { getProduct, getProductVariables } from '../schema/types/getProduct';
import { updateProduct, updateProductVariables } from '../schema/types/updateProduct';
import { DefaultRouteParams } from '../utils/const';
import { toastErrorQuery } from '../utils/error';
import NotFoundPage from './NotFoundPage';

const keys = [
  'idNumber',
  'title',
  'price',
  'youtubeUrl',
  'content',
  'billing',
  'reactionTime',
  'tagNames',
  'photoIds',
  'reports',
  'tdArticle',
  'bexioAccountId',
  'timeTrackerEnabled',
];

const ProductUpdatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ match }) => {
  const [mutate] = useMutation<updateProduct, updateProductVariables>(UPDATE_PRODUCT);

  const { data, loading, error } = useQuery<getProduct, getProductVariables>(GET_PRODUCT, {
    variables: { query: match.params.id },
  });

  if (!data && loading) return <LoadingForm />;
  if (error) return <ErrorQuery error={error} />;
  if (!data) return <NotFoundPage />;

  return (
    <div className="product-form-page">
      <Formik
        enableReinitialize
        initialValues={data.product}
        validationSchema={yup.object().shape(validateProductForm)}
        onSubmit={async (values, { setSubmitting, setValues }) => {
          try {
            const payload: any = pick(values, keys);
            payload.price = Number(payload.price);
            payload.reactionTime = Number(payload.reactionTime);
            payload.reports = payload.reports.map((el: any) => pick(el, ['productId', 'pieces', 'contractTypeId']));

            if (!payload.bexioAccountId) payload.bexioAccountId = 'default';

            const result: any = await mutate({
              variables: {
                id: match.params.id,
                payload,
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              setValues(result.data.updateProduct.product);
              toast.success(i18n._(t`Success!`));
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => <ProductUpdateForms loading={isSubmitting} />}
      </Formik>
    </div>
  );
};

export default withRouter(ProductUpdatePage);
