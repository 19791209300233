import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent, useCallback } from 'react';
import Select from 'react-select';
import { getUsers, getUsersVariables } from '../../schema/types/getUsers';
import { User } from '../../schema/types/User';
import { GET_USERS } from '../../schema/user';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {
  value: User | null;
  onSubmit: (value: User) => void;
};

const TimeTrackerUserField: FunctionComponent<Props> = ({ value, onSubmit }) => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getUsers, getUsersVariables>(GET_USERS, {
    variables,
    fetchPolicy: 'cache-first',
  });

  const handleLoad = (value: string) => search$.next(value);

  const handleChange = useCallback(
    (value: any) => {
      onSubmit(value.value);
    },
    [onSubmit],
  );

  const model = value
    ? {
        value: value.id,
        label: value.fullName,
      }
    : null;

  const options = loading
    ? []
    : data!.users.items.map((el: any) => ({
        value: el,
        label: el.fullName,
      }));

  if (!data && !loading) return <div />;

  return (
    <Select
      value={model}
      options={options}
      isLoading={loading}
      onChange={handleChange}
      onInputChange={handleLoad}
      placeholder={i18n._(t`Search...`)}
      {...selectOptions}
    />
  );
};

export default TimeTrackerUserField;
