import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Form, Formik } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { GET_SMS, SEND_SMS, validateSMSForm } from '../../schema/sms';
import { sendSms, sendSmsVariables } from '../../schema/types/sendSms';
import { toastErrorQuery } from '../../utils/error';
import { useVariables } from '../../utils/hooks';
import ButtonSubmit from '../form/ButtonSubmit';
import SendSMSForm from './SendSMSForm';
import SMSLogs from './SMSLogs';

type Props = {
  show: boolean;
  onClose: () => void;
};

const SendSMSModal: FunctionComponent<Props> = ({ show, onClose }) => {
  const [showMessages, setShowMessages] = useState(false);
  const toggleMessages = useCallback(() => setShowMessages((prevState) => !prevState), [setShowMessages]);

  const { limit, offset } = useVariables({
    limit: 10,
  });

  const variables = {
    query: {
      limit,
      offset,
    },
  };

  const [mutate] = useMutation<sendSms, sendSmsVariables>(SEND_SMS, {
    refetchQueries: [
      {
        query: GET_SMS,
        variables,
      },
    ],
  });

  const model: any = {
    cellphone: '',
    message: '',
    shadowLog: true,
  };

  return (
    <Modal isOpen={show} className="Modal modal-open ticket-modal" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Send SMS</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              initialValues={model}
              validationSchema={yup.object().shape(validateSMSForm)}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  const result: any = await mutate({
                    variables: {
                      payload: values,
                    },
                  });

                  if (result.errors) {
                    toastErrorQuery(result.errors);
                  } else {
                    resetForm();
                    toast.success(i18n._(t`Success!`));
                    onClose();
                  }
                } catch (e) {
                  toastErrorQuery(e);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting, submitForm }) => {
                const handleSubmit = (e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  submitForm();
                };

                return (
                  <Form onSubmitCapture={handleSubmit}>
                    <div className="modal-body">
                      <SendSMSForm />

                      <a className="form-group text-primary" onClick={toggleMessages}>
                        {!showMessages ? <Trans>View Last Messages</Trans> : <Trans>Hide Last Messages</Trans>}
                      </a>

                      {showMessages && <SMSLogs />}
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={onClose}>
                        <Trans>Cancel</Trans>
                      </button>

                      <ButtonSubmit className="btn btn-primary" title={i18n._(t`Send`)} loading={isSubmitting} />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SendSMSModal;
