import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { GET_COMPANIES_FOR_PERSON } from '../../schema/company';
import { CompanyStatus } from '../../schema/graphql-global-types';
import { getCompanies, getCompaniesVariables } from '../../schema/types/getCompanies';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {};

const TimeTrackerCompanyForm: FunctionComponent<Props> = () => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
        status: [CompanyStatus.Customer],
      },
    },
  };

  const { data, loading } = useQuery<getCompanies, getCompaniesVariables>(GET_COMPANIES_FOR_PERSON, {
    variables,
    fetchPolicy: 'cache-first',
  });

  if (!data && !loading) return <div />;

  return (
    <Field name="customer">
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (value: any) => {
          form.setFieldValue('customer', value.value);
          form.setFieldValue('customerId', value.value.id);
        };

        const handleLoad = (value: string) => search$.next(value);

        const model = field.value
          ? {
              value: field.value,
              label: field.value.name || field.value.name,
            }
          : null;

        const options = loading
          ? []
          : data!.companies.items.map((el: any) => ({
              value: el,
              label: el.name,
            }));

        return (
          <Select
            value={model}
            options={options}
            isLoading={loading}
            onChange={handleChange}
            onInputChange={handleLoad}
            placeholder={i18n._(t`Search...`)}
            {...selectOptions}
          />
        );
      }}
    </Field>
  );
};

export default TimeTrackerCompanyForm;
