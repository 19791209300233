import classnames from 'classnames';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { createStorageObject } from '../../schema/types/createStorageObject';
import { CREATE_STORAGE_OBJECT, createStorageObjectVariables } from '../../schema/upload';

type Props = {
  image?: string | false;
  placeholder?: string | false;
  onUpload?: (id: any) => void;
};

const ImageUploader: FunctionComponent<Props> = ({ image, placeholder = '', onUpload }) => {
  const [preview, setPreview] = useState<any>();
  const [progress, setProgress] = useState(0);

  const [mutate] = useMutation<createStorageObject, createStorageObjectVariables>(CREATE_STORAGE_OBJECT);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = () => {
        setPreview(reader.result);
        setProgress(5);
      };

      acceptedFiles.forEach((file: File) => reader.readAsDataURL(file));

      const result = await mutate({
        variables: {
          payload: {
            filename: file.name,
            mime: file.type,
          },
        },
      });

      const xhr = new XMLHttpRequest();

      // progress bar
      xhr.upload.addEventListener(
        'progress',
        (e: ProgressEvent) => {
          setProgress(Number((e.loaded / e.total) * 100));
        },
        false,
      );

      // done upload
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 201) {
            // @ts-ignore
            onUpload(result.data.createStorageObject.storageObject.id);
          } else {
            toast.error(`Unable to upload: ${result.data?.createStorageObject.storageObject.filename}`);
          }

          setProgress(0);
        }
      };

      if (result.data) {
        // up!
        xhr.open('PUT', result.data.createStorageObject.uploadUrl, true);
        result.data.createStorageObject.uploadExtraHeaders.forEach((el) => {
          xhr.setRequestHeader(el.key, el.value);
        });
        xhr.send(file);
      }
    },
    [mutate, setPreview, setProgress, onUpload],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className={classnames('image-uploader single-image-uploader', { active: isDragActive })} {...getRootProps()}>
      <input {...getInputProps()} accept="image/*" />
      {!preview && !image ? (
        <div className="image-uploader-content">
          {!isDragActive ? (
            <>
              <i className="icon-upload" />
              <br />
              <Trans>Drag & drop or click</Trans>
              <br />
              <div className="placeholder">{placeholder}</div>
            </>
          ) : (
            <Trans>Drop</Trans>
          )}
        </div>
      ) : (
        <>
          <img src={preview || image} />
          <div className="actions">
            <i className="icon-upload" />
            <Trans>Change</Trans>
          </div>
        </>
      )}
      {progress > 0 && <div className="progress-bar" style={{ width: `${progress}%` }} />}
    </div>
  );
};

export default ImageUploader;
