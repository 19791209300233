import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { omit, pick } from 'lodash';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import PersonUpdateForms from '../components/person/PersonUpdateForms';
import ErrorQuery from '../components/form/ErrorQuery';
import LoadingForm from '../components/common/LoadingForm';
import { GET_PERSON, personKeys, UPDATE_PERSON, validatePersonForm } from '../schema/person';
import { getPerson, getPersonVariables } from '../schema/types/getPerson';
import { updatePerson, updatePersonVariables } from '../schema/types/updatePerson';
import { DefaultRouteParams } from '../utils/const';
import { toastErrorQuery } from '../utils/error';
import { omitDeep } from '../utils/utils';
import NotFoundPage from './NotFoundPage';

const PersonUpdatePage: FunctionComponent<RouteComponentProps<DefaultRouteParams>> = ({ match }) => {
  const [mutate] = useMutation<updatePerson, updatePersonVariables>(UPDATE_PERSON);

  const { data, loading, error } = useQuery<getPerson, getPersonVariables>(GET_PERSON, {
    variables: { query: match.params.id },
  });

  if (!data && loading) return <LoadingForm />;
  if (error) return <ErrorQuery error={error} />;
  if (!data) return <NotFoundPage />;

  return (
    <div className="person-form-page">
      <Formik
        enableReinitialize
        initialValues={data.person}
        validationSchema={yup.object().shape(validatePersonForm)}
        onSubmit={async (values, { setSubmitting, setValues }) => {
          try {
            const payload: any = omitDeep(pick(values, personKeys), ['__typename']);
            payload.companies = payload.companies.map((el: any) => pick(el, ['companyId', 'permission']));
            payload.reminders = payload.reminders
              .filter((el: any) => !el.archivedAt)
              .map((el: any) => omit(el, ['archivedAt']));

            const result: any = await mutate({
              variables: {
                id: match.params.id,
                payload,
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              setValues(result.data.updatePerson.person);
              toast.success(i18n._(t`Success!`));
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => <PersonUpdateForms loading={isSubmitting} />}
      </Formik>
    </div>
  );
};

export default withRouter(PersonUpdatePage);
