import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import ChangePasswordPage from '../containers/ChangePasswordPage';
import PersonAssetPage from '../containers/PersonAssetPage';
import PersonCompanyPage from '../containers/PersonCompanyPage';
import PersonContractDetailPage from '../containers/PersonContractDetailPage';
import PersonContractPage from '../containers/PersonContractPage';
import PersonDocumentationPublicPage from '../containers/PersonDocumentationPage';
import PersonHomePage from '../containers/PersonHomePage';
import PersonProCloudTicketPage from '../containers/PersonProCloudTicketPage';
import PersonTicketCreatePage from '../containers/PersonTicketCreatePage';
import PersonTicketUpdatePage from '../containers/PersonTicketUpdatePage';
import PersonTimeTrackerPage from '../containers/PersonTimeTrackerPage';
import ProfilePage from '../containers/ProfilePage';
import SelfAuditPage from '../containers/SelfAuditPage';

export type Route = {
  help?: string;
  icon?: string;
  exact?: boolean;
  hide?: boolean;
  name: string;
  href: string;
  children?: Array<Route>;
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
};

const sharedRoutes: Route[] = [
  {
    help: 'Customer/Dashboard',
    icon: 'icon-accounts',
    name: i18n._(t`Dashboard`),
    href: '/',
    exact: true,
    component: PersonHomePage,
  },
  {
    icon: 'icon-tickets',
    name: i18n._(t`Your Tickets`),
    href: '/support/ticket/procloud',
    component: PersonProCloudTicketPage,
  },
  {
    href: '/support/procloud/add',
    name: i18n._(t`Add a Ticket`),
    hide: true,
    component: PersonTicketCreatePage,
  },
  {
    href: '/support/procloud/:id',
    name: i18n._(t`Update Ticket`),
    hide: true,
    component: PersonTicketUpdatePage,
  },
  {
    href: '/profile',
    name: i18n._(t`Profile`),
    component: ProfilePage,
    hide: true,
  },
  {
    href: '/change-password',
    name: i18n._(t`Change Password`),
    component: ChangePasswordPage,
    hide: true,
  },
];

export const personUserRoutes: Route[] = [
  ...sharedRoutes,
  {
    icon: 'icon-document',
    name: i18n._(t`Documentation`),
    href: '/documentation',
    children: [
      {
        href: '/documentation/public',
        name: i18n._(t`General Documentation`),
        component: PersonDocumentationPublicPage,
      },
    ],
  },
];

export const personAdminRoutes: Route[] = [
  ...sharedRoutes,
  {
    icon: 'icon-document',
    name: i18n._(t`Documentation`),
    href: '/documentation',
    children: [
      {
        href: '/documentation/public',
        name: i18n._(t`General Documentation`),
        component: PersonDocumentationPublicPage,
      },
      {
        href: '/documentation/individual',
        name: i18n._(t`Personal Documentation`),
        component: PersonDocumentationPublicPage,
      },
      {
        href: '/documentation/self-audit',
        name: i18n._(t`Self Audit`),
        component: SelfAuditPage,
      },
    ],
  },
  {
    icon: 'icon-assets',
    name: i18n._(t`Services`),
    href: '/service',
    children: [
      {
        href: '/service/contract',
        name: i18n._(t`My Contracts`),
        component: PersonContractPage,
      },
      {
        href: '/service/contract/:id',
        name: i18n._(t`Contract Details`),
        component: PersonContractDetailPage,
        hide: true,
      },
      {
        href: '/service/managed',
        name: i18n._(t`Managed Services`),
        component: PersonAssetPage,
      },
      {
        href: '/service/project',
        name: i18n._(t`Projects`),
        component: PersonTimeTrackerPage,
      },
    ],
  },
  {
    icon: 'icon-settings',
    name: i18n._(t`Company Profile`),
    href: '/settings/company',
    component: PersonCompanyPage,
  },
  {
    href: '/profile',
    name: i18n._(t`My Profile`),
    component: ProfilePage,
    hide: true,
  },
  {
    href: '/change-password',
    name: i18n._(t`Change Password`),
    component: ChangePasswordPage,
    hide: true,
  },
];
