import React, { FunctionComponent } from 'react';

type Props = {};

const LoadingForm: FunctionComponent<Props> = () => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="ph-item">
          <div className="ph-col-12">
            <div className="ph-row">
              <div className="ph-col-12 big" />
            </div>
            <div className="row">
              <div className="col-lg-6 col-xl-4">
                <div className="ph-row">
                  <div className="ph-col-8 big" />
                  <div className="ph-col-6 big" />
                  <div className="ph-col-10 big" />
                  <div className="ph-col-12 big" />
                  <div className="ph-col-12 big" />
                </div>
              </div>
              <div className="col-lg-6 col-xl-4">
                <div className="ph-row">
                  <div className="ph-col-8 big" />
                  <div className="ph-col-6 big" />
                  <div className="ph-col-10 big" />
                  <div className="ph-col-12 big" />
                  <div className="ph-col-12 big" />
                </div>
              </div>
              <div className="col-lg-6 col-xl-4">
                <div className="ph-row">
                  <div className="ph-col-8 big" />
                  <div className="ph-col-6 big" />
                  <div className="ph-col-10 big" />
                  <div className="ph-col-12 big" />
                  <div className="ph-col-12 big" />
                </div>
              </div>
              <div className="col-lg-6 col-xl-4">
                <div className="ph-row">
                  <div className="ph-col-8 big" />
                  <div className="ph-col-6 big" />
                  <div className="ph-col-10 big" />
                  <div className="ph-col-12 big" />
                  <div className="ph-col-12 big" />
                </div>
              </div>
              <div className="col-lg-6 col-xl-4">
                <div className="ph-row">
                  <div className="ph-col-8 big" />
                  <div className="ph-col-6 big" />
                  <div className="ph-col-10 big" />
                  <div className="ph-col-12 big" />
                  <div className="ph-col-12 big" />
                </div>
              </div>
              <div className="col-lg-6 col-xl-4">
                <div className="ph-row">
                  <div className="ph-col-8 big" />
                  <div className="ph-col-6 big" />
                  <div className="ph-col-10 big" />
                  <div className="ph-col-12 big" />
                  <div className="ph-col-12 big" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingForm;
