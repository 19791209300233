import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import { GET_TEXT_TEMPLATE_VARIABLES } from '../../schema/textTemplate';
import { textTemplateDefaultVariables } from '../../schema/types/textTemplateDefaultVariables';
import ErrorQuery from '../form/ErrorQuery';
import LoadingQuery from '../common/LoadingQuery';

type Prop = {
  onSelect: (item: string) => () => void;
};

const TextTemplateVariables: FunctionComponent<Prop> = ({ onSelect }) => {
  const { data, loading, error } = useQuery<textTemplateDefaultVariables>(GET_TEXT_TEMPLATE_VARIABLES, {
    fetchPolicy: 'cache-first',
  });

  if (error) return <ErrorQuery error={error} />;
  if (loading) return <LoadingQuery />;
  if (!data) return <div />;

  return (
    <div className="history-item">
      <label className="mr-2">
        <Trans>Variables</Trans>:
      </label>
      {data!.textTemplateDefaultVariables.map((el: any, index: any) => (
        <a key={index} className="badge text-dark mr-2" onClick={onSelect(el)}>{`{{${el}}}`}</a>
      ))}
    </div>
  );
};

export default TextTemplateVariables;
