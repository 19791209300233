import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, FormikProps } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import Modal from 'react-modal';
import { TenantSubscriptionType } from '../../schema/graphql-global-types';
import MainTitle from '../common/MainTitle';
import ButtonSubmit from '../form/ButtonSubmit';
import CompanyInfoForm from '../company/CompanyInfoForm';
import FieldRadio from '../form/FieldRadio';
import TenantDetailForm from './TenantDetailForm';

type Props = {
  loading?: boolean;
};

const TenantUpdateForms: FunctionComponent<Props> = ({ loading }) => {
  const [showCancel, setShowCancel] = useState(false);
  const toggleShowCancel = useCallback(() => setShowCancel((prevState) => !prevState), []);

  return (
    <Form>
      <div className="table-actions form-table-actions">
        <div className="breadcrumbs-area">
          <MainTitle title={i18n._(t`Account Settings`)} />
        </div>

        <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={loading} />
      </div>

      <div className="card">
        <div className="row">
          <div className="col-xl-6 panel-column-left">
            <div className="card-header">
              <Trans>Company Details</Trans>
            </div>

            <TenantDetailForm />
          </div>

          <div className="col-xl-6">
            <div className="card-header">
              <Trans>Contact Information</Trans>
            </div>

            <div className="card-body">
              <CompanyInfoForm />
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <Trans>Subscription Model</Trans>
        </div>

        <div className="row">
          <div className="form-group col-md-3">
            <FieldRadio
              name="subscriptionType"
              label={i18n._(t`Power Offer`)}
              value={TenantSubscriptionType.PowerOffer}
            />
          </div>

          <div className="form-group col-md-3">
            <FieldRadio
              name="subscriptionType"
              label={i18n._(t`Service Provider`)}
              value={TenantSubscriptionType.ServiceProvider}
            />
          </div>

          <div className="form-group col-md-6">
            <div className="form-group">
              <Field name="subscriptionType">
                {({ field, form }: { field: any; form: FormikProps<any> }) => {
                  const handleChange = () => {
                    form.setFieldValue('subscriptionType', TenantSubscriptionType.Cancelled);
                    setShowCancel(false);
                  };

                  return (
                    <div className="custom-control custom-radio">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`subscriptionType-${TenantSubscriptionType.Cancelled}`}
                        checked={field.value === TenantSubscriptionType.Cancelled}
                        onChange={toggleShowCancel}
                        value={TenantSubscriptionType.Cancelled}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`subscriptionType-${TenantSubscriptionType.Cancelled}`}
                      >
                        <Trans>Cancel my contract to the next billing cycle</Trans>
                      </label>

                      <Modal
                        isOpen={showCancel}
                        className="Modal modal-open"
                        overlayClassName="Overlay"
                        closeTimeoutMS={200}
                      >
                        <div className="modal fade show" style={{ display: 'block' }}>
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-body" style={{ fontSize: 20 }}>
                                <Trans>
                                  Please confirm to cancel the contract. All your data will be deleted irrevocably.
                                </Trans>
                              </div>

                              <div className="modal-footer">
                                <button type="button" className="btn btn-link" onClick={toggleShowCancel}>
                                  <Trans>Cancel</Trans>
                                </button>

                                <button type="button" className="btn btn-primary" onClick={handleChange}>
                                  <Trans>Confirm</Trans>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  );
                }}
              </Field>
            </div>
          </div>
        </div>

        <div className="post-content">
          <Trans>More information:</Trans>{' '}
          <a href="https://www.multicloud.center/pricing" target="_blank">
            https://www.multicloud.center/pricing
          </a>
        </div>
      </div>
    </Form>
  );
};

export default TenantUpdateForms;
