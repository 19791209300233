import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { User } from '../../schema/types/User';
import TimeTrackerUserField from './TimeTrackerUserField';

type Props = {
  name: string;
};

const TimeTrackerUserForm: FunctionComponent<Props> = ({ name }) => {
  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (value: User) => {
          form.setFieldValue(name, value);
          form.setFieldValue(`${name}Id`, value.id);
        };

        return <TimeTrackerUserField value={field.value} onSubmit={handleChange} />;
      }}
    </Field>
  );
};

export default TimeTrackerUserForm;
