import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import Modal from 'react-modal';
import OfferTemplates from './OfferTemplates';

type Props = {
  show: boolean;
  onClose: () => void;
};

const OfferTemplateModal: FunctionComponent<Props> = ({ show, onClose }) => {
  return (
    <Modal
      isOpen={show}
      className="Modal modal-open"
      overlayClassName="Overlay"
      closeTimeoutMS={200}
    >
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Templates</Trans>
              </h2>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={onClose}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              <OfferTemplates />
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={onClose}
              >
                <Trans>Close</Trans>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OfferTemplateModal;
