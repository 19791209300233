import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent, useCallback, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { ProductContractType } from '../../schema/types/ProductContractType';
import ButtonDelete from '../form/ButtonDelete';
import ProductContractForm from './ProductContractForm';
import ProductContractModal from './ProductContractModal';

type Props = {
  name: string;
  onRemove: () => void;
};

const ProductReportingFormItem: FunctionComponent<Props> = ({ name, onRemove }) => {
  const key = Math.random();

  const [showContract, setShowContract] = useState(false);
  const toggleContractModal = useCallback(() => setShowContract((prevState) => !prevState), []);

  return (
    <tr>
      <td className="column title">
        <Field name={`${name}.product.title`}>
          {({ field }: { field: any; form: FormikProps<any> }) => field.value}
        </Field>
      </td>
      <td className="column number">
        <Field name={`${name}.pieces`} type="number" className="form-control" placeholder={i18n._(t`Pieces`)} />
      </td>
      <td className="column number">
        <Trans>Contract</Trans>
      </td>

      <td className="column title">
        <ProductContractForm name={name} />
      </td>

      <td className="column action">
        <button type="button" data-tip data-for={`category-${key}`} className="button" onClick={toggleContractModal}>
          <i className="icon-edit" />
        </button>

        <ReactTooltip id={`category-${key}`}>
          <Trans>Manage Contracts</Trans>
        </ReactTooltip>

        <Field name={name}>
          {({ form }: { form: FormikProps<any> }) => {
            const handleSelect = (item: ProductContractType) => {
              form.setFieldValue(`${name}.contractTypeId`, item.id);
              form.setFieldValue(`${name}.contractType`, {
                id: item.id,
                name: item.name,
              });
              toggleContractModal();
            };

            return <ProductContractModal show={showContract} onSelect={handleSelect} onClose={toggleContractModal} />;
          }}
        </Field>
      </td>

      <td className="column action">
        <ButtonDelete onClick={onRemove} />
      </td>
    </tr>
  );
};

export default ProductReportingFormItem;
