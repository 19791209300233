import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import { NavLink } from 'react-router-dom';
import { ContractProduct } from '../../schema/types/ContractProduct';
import { dateOptions, monthNames } from '../../utils/const';
import { getStorage } from '../../utils/storage';
import CurrencyDisplay from '../common/CurrencyDisplay';
import DateFormat from '../common/DateFormat';
import MainTitle from '../common/MainTitle';
import NumberDisplay from '../common/NumberDisplay';
import PersonContractActivity from './PersonContractActivity';
import { cloneDeep } from 'lodash';

type Props = {
  onAdjustmentChange: (date: string) => void;
  model: any;
};

const PersonContractForm: FunctionComponent<Props> = ({ onAdjustmentChange, model }) => {
  const [showRow, setShowRow] = useState(Array(model.products.length));

  const subtotal = model.products.reduce((value: number, el: ContractProduct) => {
    return el.price * el.pieces + value;
  }, 0);

  const total = model.products.reduce((value: number, el: ContractProduct) => {
    return (el.price - (el.price * el.discountPercent) / 100) * el.pieces + value;
  }, 0);

  const totalDiscount = model.products.reduce((value: number, el: ContractProduct) => {
    return el.discountPercent + value;
  }, 0);

  const toggleRow = useCallback(
    (index: number) => () =>
      setShowRow((prevState) => {
        const model = cloneDeep(prevState);
        model[index] = !model[index];

        return model;
      }),
    [],
  );

  const handleChange = useCallback(
    (now: Date) => {
      const next = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1));
      onAdjustmentChange(next.toISOString());
    },
    [onAdjustmentChange],
  );

  const adjustmentDate = new Date(model.adjustmentDate);

  return (
    <div className="contract-form-page">
      <div className="form-breadcrumbs-area">
        <NavLink to="/service/contract">
          <Trans>My Contracts</Trans>
        </NavLink>

        <i className="fa fa-angle-left" />

        <Trans>Contract details</Trans>
      </div>

      <div className="breadcrumbs-area">
        <MainTitle title={model.name} />
      </div>

      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-3 col-md-6">
              <div className="form-group">
                <label>
                  <Trans>Contract</Trans>
                </label>
                <div>{model.name}</div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="form-group">
                <label>
                  <Trans>Contract Start</Trans>
                </label>
                <div>
                  <DateFormat value={new Date(model.startedAt)} format={dateOptions} />
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="form-group">
                <label>
                  <Trans>Contract Cycle</Trans>
                </label>
                <div>{model.cycle}</div>
              </div>
            </div>

            {model.cycle === 'Yearly' && (
              <div className="col-xl-3 col-md-6">
                <div className="form-group">
                  <label>
                    <Trans>Cycle Month</Trans>
                  </label>
                  <div>
                    {i18n._(t`First of`)} {monthNames()[model.yearlyInterval]}
                  </div>
                </div>
              </div>
            )}

            <div className="col-xl-3 col-md-6">
              <div className="form-group">
                <label>
                  <Trans>Display Contract to</Trans>
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <i className="icon-calendar" />
                    </div>
                  </div>
                  <div className="form-control">
                    <DatePicker
                      selected={new Date(adjustmentDate.getFullYear(), adjustmentDate.getMonth(), 2)}
                      onChange={handleChange}
                      className="datepicker-control"
                      locale={getStorage('language') || 'en'}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body table-responsive document-product-table">
          <table className="custom-table">
            <thead className="custom-table-header">
              <tr>
                <th className="column actions" />
                <th className="column title">
                  <Trans>Product</Trans>
                </th>
                <th className="column number">
                  <Trans>Price</Trans>
                </th>
                <th className="column number">
                  <Trans>Pieces</Trans>
                </th>
                <th className="column number">{totalDiscount > 0 && <Trans>Discount %</Trans>}</th>
                <th className="column total">
                  <Trans>Total</Trans>
                </th>
              </tr>
            </thead>

            <tbody className="custom-table-body">
              {model.products &&
                model.products.map((el: ContractProduct, index: number) => {
                  const value = (el.price - (el.price * el.discountPercent) / 100) * el.pieces;

                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td className="column actions" onClick={toggleRow(index)}>
                          {el.product.content && (
                            <button type="button" className="button">
                              {showRow[index] ? <i className="icon-dropdown" /> : <i className="icon-expand" />}
                            </button>
                          )}
                        </td>
                        <td className="column title">{el.title}</td>
                        <td className="column number">{el.price}</td>
                        <td className="column number">{el.pieces}</td>
                        <td className="column number">{totalDiscount > 0 ? `${el.discountPercent} %` : ''}</td>
                        <td className="column total">
                          <NumberDisplay value={value} />
                        </td>
                      </tr>
                      {el.product.content && showRow[index] ? (
                        <tr>
                          <td
                            className="column post-content"
                            dangerouslySetInnerHTML={{ __html: el.product.content }}
                            colSpan={6}
                          />
                        </tr>
                      ) : null}
                    </React.Fragment>
                  );
                })}
            </tbody>
          </table>

          {subtotal > 0 && (
            <table className="table text-right mt-3">
              <tbody>
                <tr>
                  <td>
                    <Trans>Subtotal</Trans>
                  </td>
                  <td className="total text-left">
                    <CurrencyDisplay value={subtotal} />
                  </td>
                </tr>
                <tr>
                  <td>
                    (<Trans>Total Discount</Trans>
                  </td>
                  <td className="total text-left">
                    <CurrencyDisplay value={subtotal - total} />)
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <Trans>TOTAL</Trans>
                    </strong>
                  </td>
                  <td className="total text-left">
                    <strong>
                      <CurrencyDisplay value={total} />
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div className="main-title">
        <Trans>Last Activities</Trans>
      </div>

      <div className="mb-5">
        <PersonContractActivity items={model.productAdjustments.items} />
      </div>
    </div>
  );
};

export default PersonContractForm;
