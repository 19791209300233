import { useQuery } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { CompanyPermission } from '../../schema/graphql-global-types';
import { GET_PERSONS_FOR_COMPANY } from '../../schema/person';
import { CompanyPerson } from '../../schema/types/CompanyPerson';
import { getPersons, getPersonsVariables } from '../../schema/types/getPersons';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';
import CompanyPersonFormItem from './CompanyPersonFormItem';

type Props = {};

const CompanyPersonForm: FunctionComponent<Props> = () => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getPersons, getPersonsVariables>(GET_PERSONS_FOR_COMPANY, {
    variables,
  });

  if (!data && !loading) return <div />;

  return (
    <Field name="persons">
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (value: any) => {
          const model = cloneDeep(field.value);

          if (!model.some((el: CompanyPerson) => el.person.id === value.value.id)) {
            model.push({
              person: value.value,
              personId: value.value.id,
              permission: CompanyPermission.NoAccess,
            });

            form.setFieldValue('persons', model);
          }

          if (model.length === 1) {
            form.setFieldValue('primaryPersonId', model[0].personId);
            form.setFieldValue('primaryPerson', model[0].person);
          }
        };

        const handleRemove = (index: number) => () => {
          const model = cloneDeep(field.value);
          model.splice(index, 1);
          form.setFieldValue('persons', model);

          if (model.length === 1) {
            form.setFieldValue('primaryPersonId', model[0].personId);
            form.setFieldValue('primaryPerson', model[0].person);
          }
        };

        const handlePrimary = (id: any) => () => {
          form.setFieldValue('primaryPersonId', id);
          form.setFieldValue('primaryPerson', { id });
        };

        const handleLoad = (value: string) => search$.next(value);

        const options = loading
          ? []
          : data!.persons.items.map((el) => ({
              value: el,
              label: el.fullName,
            }));

        return (
          <div className="company-person-form">
            <div className="form-group">
              <label>
                <Trans>Add a Company Person</Trans>
              </label>
              <Select
                value={null}
                options={options}
                isLoading={loading}
                onChange={handleChange}
                onInputChange={handleLoad}
                placeholder={i18n._(t`Search...`)}
                {...selectOptions}
              />
            </div>

            <div className="form-group items">
              {field.value.map((el: CompanyPerson, index: number) => (
                <CompanyPersonFormItem
                  key={el.person.id}
                  index={index}
                  primary={form.values.primaryPerson && form.values.primaryPerson.id === el.person.id}
                  model={el}
                  onPrimary={handlePrimary(el.person.id)}
                  onRemove={handleRemove(index)}
                />
              ))}
            </div>
          </div>
        );
      }}
    </Field>
  );
};

export default CompanyPersonForm;
