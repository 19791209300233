import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import ReactTable, { CellInfo } from 'react-table';
import { NavLink } from 'react-router-dom';
import MainTitle from '../components/common/MainTitle';
import TableActions from '../components/common/TableActions';
import HeatLevel from '../components/company/HeatLevel';
import ButtonArchive from '../components/form/ButtonArchive';
import ButtonEdit from '../components/form/ButtonEdit';
import ButtonLogin from '../components/form/ButtonLogin';
import LoadingTable from '../components/common/LoadingTable';
import { GET_COMPANIES, ARCHIVE_COMPANY, UNARCHIVE_COMPANY } from '../schema/company';
import { OrderDirection } from '../schema/graphql-global-types';
import { archiveCompany, archiveCompanyVariables } from '../schema/types/archiveCompany';
import { getCompanies, getCompaniesVariables } from '../schema/types/getCompanies';
import { unarchiveCompany, unarchiveCompanyVariables } from '../schema/types/unarchiveCompany';
import { tableOptions, tablePollingInterval } from '../utils/const';
import ErrorQuery from '../components/form/ErrorQuery';
import { Company } from '../schema/types/Company';
import { useVariables } from '../utils/hooks';
import { cloneDeep } from 'lodash';
import { DataProxy } from '@apollo/client/cache';
import { setStorage } from '../utils/storage';
import { getFilter, getOrder, getPhone } from '../utils/utils';

const CompanyPage: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);
  const {
    search$,
    search,
    filter$,
    filter,
    archived,
    setArchived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
  } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const updateQuery = (proxy: DataProxy, id: any) => {
    const data: any = cloneDeep(proxy.readQuery({ query: GET_COMPANIES, variables }));
    data.companies.items = data.companies.items.filter((el: any) => el.id !== id);
    proxy.writeQuery({ query: GET_COMPANIES, variables, data });
  };

  const [mutateArchive] = useMutation<archiveCompany, archiveCompanyVariables>(ARCHIVE_COMPANY, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.archiveCompany.company.id);
    },
  });
  const [mutateUnarchive] = useMutation<unarchiveCompany, unarchiveCompanyVariables>(UNARCHIVE_COMPANY, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.unarchiveCompany.company.id);
    },
  });

  const handleArchive = useCallback(
    (id: any) => () => {
      if (archived) {
        mutateUnarchive({
          variables: {
            id,
          },
          optimisticResponse: {
            unarchiveCompany: {
              company: {
                id,
                __typename: 'Company',
              },
              __typename: 'MutateCompanyResponse',
            },
          },
        });
      } else {
        mutateArchive({
          variables: {
            id,
          },
          optimisticResponse: {
            archiveCompany: {
              company: {
                id,
                __typename: 'Company',
              },
              __typename: 'MutateCompanyResponse',
            },
          },
        });
      }
    },
    [archived, mutateArchive, mutateUnarchive],
  );

  const handleLogin = useCallback(
    (item: Company) => () => {
      setStorage('companyId', item.id);
      setStorage('viewAs', item.name);
      window.location.href = '/';
    },
    [],
  );

  const { data, previousData, loading, error } = useQuery<getCompanies, getCompaniesVariables>(GET_COMPANIES, {
    variables,
    pollInterval: tablePollingInterval,
  });

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.companies.items ?? previousData?.companies.items ?? [];
  const pages = data && data.companies ? Math.ceil(data.companies.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Company`),
      accessor: 'name',
      sortable: true,
      filterable: true,
      Cell: (cell: CellInfo) => <NavLink to={`/crm/company/${cell.original.id}`}>{cell.value}</NavLink>,
    },
    {
      Header: i18n._(t`Primary contact`),
      accessor: 'primaryPerson.fullName',
      sortable: true,
      filterable: true,
      Cell: (cell: CellInfo) => (
        <>
          {cell.original.primaryPerson ? (
            <NavLink to={`/crm/person/${cell.original.primaryPerson.id}`}>{cell.value}</NavLink>
          ) : (
            <div />
          )}
        </>
      ),
    },
    {
      Header: i18n._(t`Phone`),
      accessor: 'generalPhone',
      sortable: true,
      filterable: true,
      Cell: (cell: CellInfo) => <a href={`tel:${getPhone(cell.value)}`}>{cell.value}</a>,
    },
    {
      Header: i18n._(t`Email`),
      accessor: 'generalEmail',
      sortable: true,
      filterable: true,
      Cell: (cell: CellInfo) => <a href={`mailto:${cell.value}`}>{cell.value}</a>,
    },
    {
      Header: i18n._(t`Last Log`),
      accessor: 'lastLog.content',
      Cell: (cell: CellInfo) => <span className="text-gray60">{cell.value.slice(0, 200)}...</span>,
    },
    {
      Header: i18n._(t`Status`),
      accessor: 'status',
      sortable: true,
      filterable: true,
      width: 100,
      className: 'text-center',
      Cell: (cell: CellInfo) => <span className="badge">{cell.value}</span>,
      Filter: (params: any) => (
        <select
          className="form-control"
          onChange={(event) => params.onChange(event.target.value ? [event.target.value] : '')}
          value={params.filter ? params.filter.value : ''}
        >
          <option value="">{i18n._(t`All`)}</option>
          <option value="Default">{i18n._(t`Default`)}</option>
          <option value="Cold">{i18n._(t`Cold`)}</option>
          <option value="Touched">{i18n._(t`Touched`)}</option>
          <option value="Customer">{i18n._(t`Customer`)}</option>
          <option value="Partner">{i18n._(t`Partner`)}</option>
        </select>
      ),
    },
    {
      Header: i18n._(t`Heat Level`),
      accessor: 'heatLevel',
      sortable: true,
      filterable: true,
      width: 140,
      className: 'text-center',
      Filter: (params: any) => (
        <select
          className="form-control"
          onChange={(event) => params.onChange(event.target.value ? [event.target.value] : '')}
          value={params.filter ? params.filter.value[0] : ''}
        >
          <option value="">{i18n._(t`All`)}</option>
          <option value={0}>0</option>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
        </select>
      ),
      Cell: (cell: CellInfo) => <HeatLevel value={cell.original.heatLevel} />,
    },
    {
      Header: i18n._(t`Actions`),
      width: 110,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <>
          <NavLink to={`/crm/company/${cell.original.id}`}>
            <ButtonEdit />
          </NavLink>
          <ButtonArchive onClick={handleArchive(cell.original.id)} />
          <ButtonLogin onClick={handleLogin(cell.original)} />
        </>
      ),
    },
  ];

  return (
    <div className="company-page">
      <div className="card-body">
        <div className="table-actions">
          <div className="breadcrumbs-area">
            <MainTitle title={i18n._(t`Company`)} tips="CRM/Company" />
          </div>

          <TableActions onSearch={(e) => search$.next(e.target.value)} archived={archived} onArchive={setArchived} />

          <NavLink to="/crm/company/add" className="btn btn-sm btn-primary">
            <i className="icon-add" />
            <Trans>Add new company</Trans>
          </NavLink>
        </div>

        {!loaded ? (
          <LoadingTable />
        ) : (
          <ReactTable
            data={items}
            columns={columns}
            pages={pages}
            pageSize={limit}
            manual
            onFetchData={(state) => {
              const filter = getFilter(state, {
                name: 'searchName',
                'primaryPerson.fullName': 'searchPrimaryContactName',
                generalPhone: 'searchGeneralPhone',
                generalEmail: 'searchGeneralEmail',
              });

              if (filter.heatLevel) filter.heatLevel = Number(filter.heatLevel);

              filter$.next(filter);

              let orderDir = OrderDirection.Desc;
              if (state.sorted[0]) {
                orderDir = state.sorted[0].desc ? OrderDirection.Desc : OrderDirection.Asc;
              }

              setOffset(state.page * state.pageSize);
              setOrderBy(
                getOrder(state, {
                  'primaryPerson.fullName': 'primaryContactFullName',
                }),
              );
              setOrderDirection(orderDir);
            }}
            onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
            {...tableOptions()}
          />
        )}
      </div>
    </div>
  );
};

export default CompanyPage;
