import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { GET_STATS_ASSETS_BY_STATUS } from '../../schema/stats';
import { statsAssetsByStatus } from '../../schema/types/statsAssetsByStatus';
import LoadingQuery from '../common/LoadingQuery';
import ErrorQuery from '../form/ErrorQuery';

const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: false,
  tooltips: {
    enabled: false,
  },
  cutoutPercentage: 80,
};

const HomeAssetChart: FunctionComponent = () => {
  const { data, loading, error } = useQuery<statsAssetsByStatus>(GET_STATS_ASSETS_BY_STATUS, {
    fetchPolicy: 'cache-first',
  });

  const { charts, total } = useMemo(() => {
    let results: any[] = [];

    let total = 0;

    data?.statsAssetsByStatus.forEach((el) => {
      switch (el.status) {
        case 'Status3Green':
          total += el.count;
          results.push({
            title: i18n._(t`Up`),
            value: el.count,
            data: {
              datasets: [
                {
                  data: [el.count, 0],
                  backgroundColor: ['#81c945', '#EFEFF5'],
                  hoverBackgroundColor: ['#81c945', '#EFEFF5'],
                  borderColor: 'transparent',
                },
              ],
            },
          });
          break;
        case 'Status5Red':
          total += el.count;
          results.push({
            title: i18n._(t`Down`),
            value: el.count,
            data: {
              datasets: [
                {
                  data: [el.count, 0],
                  backgroundColor: ['#f66a7c', '#EFEFF5'],
                  hoverBackgroundColor: ['#f66a7c', '#EFEFF5'],
                  borderColor: 'transparent',
                },
              ],
            },
          });
          break;
        case 'Status4Yellow':
          total += el.count;
          results.push({
            title: i18n._(t`Up again`),
            value: el.count,
            data: {
              datasets: [
                {
                  data: [el.count, 0],
                  backgroundColor: ['#db7e12', '#EFEFF5'],
                  hoverBackgroundColor: ['#db7e12', '#EFEFF5'],
                  borderColor: 'transparent',
                },
              ],
            },
          });
          break;
        case 'Status2Blue':
          total += el.count;
          results.push({
            title: i18n._(t`Maintenance`),
            value: el.count,
            data: {
              datasets: [
                {
                  data: [el.count, 0],
                  backgroundColor: ['#41acff', '#EFEFF5'],
                  hoverBackgroundColor: ['#41acff', '#EFEFF5'],
                  borderColor: 'transparent',
                },
              ],
            },
          });
          break;
        default:
          break;
      }
    });

    results.forEach((el) => {
      el.data.datasets[0].data[1] = total - el.data.datasets[0].data[0];
    });

    return {
      charts: results,
      total,
    };
  }, [data]);

  if (error) return <ErrorQuery error={error} />;
  if (loading && !data) return <LoadingQuery />;
  if (!data) return <div />;

  return (
    <div className="card">
      <div className="card-header">
        <Trans>Assets</Trans>
      </div>

      <div className="row">
        {charts.map((el, index) => (
          <div key={index} className="form-group col-md-6 col-xl-3">
            <div className="d-flex align-items-center">
              <div className="monitor-charts">
                <Doughnut data={el.data} options={options} width={150} height={150} />
              </div>

              <div className="flex-grow-1 ml-3">
                <div className="form-label mb-2">{el.title}</div>

                <div className="main-title mb-0">
                  {el.value}

                  <span className="form-label ml-2">{Math.round((el.value / total) * 100)}%</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeAssetChart;
