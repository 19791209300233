import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { pick } from 'lodash';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import ErrorQuery from '../components/form/ErrorQuery';
import LoadingForm from '../components/common/LoadingForm';
import TenantUpdateForms from '../components/tenant/TenantUpdateForms';
import { TenantConfigPayload, TenantSubscriptionType } from '../schema/graphql-global-types';
import { GET_TENANT_CONFIG, tenantConfigKeys, UPDATE_TENANT_CONFIG, validateTenantConfigForm } from '../schema/tenant';
import { getTenantConfig } from '../schema/types/getTenantConfig';
import { updateTenantConfig, updateTenantConfigVariables } from '../schema/types/updateTenantConfig';
import { toastErrorQuery } from '../utils/error';
import NotFoundPage from './NotFoundPage';

const AccountSettingPage: FunctionComponent = () => {
  const [mutate] = useMutation<updateTenantConfig, updateTenantConfigVariables>(UPDATE_TENANT_CONFIG);

  const { data, loading, error } = useQuery<getTenantConfig, getTenantConfig>(GET_TENANT_CONFIG);

  if (!data && loading) return <LoadingForm />;
  if (error) return <ErrorQuery error={error} />;
  if (!data) return <NotFoundPage />;

  const model: TenantConfigPayload = {
    logoId: '',
    companyName: '',
    nameSuffix: '',
    website: '',
    language: '',
    responsibleUserFirstName: '',
    responsibleUserLastName: '',
    address1: '',
    zip: '',
    canton: '',
    city: '',
    country: '',
    generalPhone: '',
    generalEmail: '',
    invoiceEmail: '',
    subscriptionType: TenantSubscriptionType.PowerOffer,
  };

  return (
    <div className="company-form-page">
      <Formik
        enableReinitialize
        initialValues={data.tenantConfig || model}
        validationSchema={yup.object().shape(validateTenantConfigForm)}
        onSubmit={async (values, { setSubmitting, setValues }) => {
          try {
            const payload: any = pick(values, tenantConfigKeys);

            const result: any = await mutate({
              variables: {
                payload,
              },
            });

            if (result.errors) {
              toastErrorQuery(result.errors);
            } else {
              setValues(result.data.updateCompany.company);
              toast.success(i18n._(t`Success!`));
            }
          } catch (e) {
            toastErrorQuery(e);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => <TenantUpdateForms loading={isSubmitting} />}
      </Formik>
    </div>
  );
};

export default AccountSettingPage;
