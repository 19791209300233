import gql from 'graphql-tag';
import { getStorage, setStorage } from '../utils/storage';

export const sidebarInitialState = {
  open: getStorage('toggleSidebar') === undefined ? true : getStorage('toggleSidebar'),
  openItem: getStorage('toggleSidebarItem') || [...Array(10)],
  darkMode: getStorage('darkMode') === undefined ? false : getStorage('darkMode'),
  __typename: 'SidebarState',
};

document.body.classList[sidebarInitialState.open ? 'remove' : 'add']('sidenav-toggled');

document.body.classList[sidebarInitialState.darkMode ? 'add' : 'remove']('dark');

export const GET_SIDEBAR_STATE = gql`
  query sidebarState {
    sidebarState @client {
      open
      openItem
      darkMode
    }
  }
`;

export const TOGGLE_SIDEBAR = gql`
  mutation toggleSidebar($payload: Boolean) {
    toggleSidebar(payload: $payload) @client
  }
`;

export const TOGGLE_SIDEBAR_ITEM = gql`
  mutation toggleSidebarItem($index: Int!, $payload: Boolean) {
    toggleSidebarItem(index: $index, payload: $payload) @client
  }
`;

export const TOGGLE_DARK_MODE = gql`
  mutation toggleDarkMode($payload: Boolean) {
    toggleDarkMode(payload: $payload) @client
  }
`;

export const toggleSidebar = (_root: any, variables: any, { cache }: any) => {
  const { sidebarState } = cache.readQuery({
    query: GET_SIDEBAR_STATE,
  });

  const open = variables.payload || !sidebarState.open;
  document.body.classList[open ? 'remove' : 'add']('sidenav-toggled');
  setStorage('toggleSidebar', open);

  cache.writeQuery({
    query: GET_SIDEBAR_STATE,
    data: {
      sidebarState: {
        ...sidebarState,
        open,
      },
    },
  });

  return open;
};

export const toggleSidebarItem = (_root: any, variables: any, { cache }: any) => {
  const { sidebarState } = cache.readQuery({
    query: GET_SIDEBAR_STATE,
  });

  const openItem = sidebarState.openItem.map((el: boolean, id: number) => {
    return id === variables.index ? variables.payload || !el : el;
  });
  setStorage('toggleSidebarItem', openItem);

  cache.writeQuery({
    query: GET_SIDEBAR_STATE,
    data: {
      sidebarState: {
        ...sidebarState,
        openItem,
      },
    },
  });

  return openItem;
};

export const toggleDarkMode = (_root: any, variables: any, { cache }: any) => {
  const { sidebarState } = cache.readQuery({
    query: GET_SIDEBAR_STATE,
  });

  const darkMode = variables.payload || !sidebarState.darkMode;
  document.body.classList[darkMode ? 'add' : 'remove']('dark');
  setStorage('darkMode', darkMode);

  cache.writeQuery({
    query: GET_SIDEBAR_STATE,
    data: {
      sidebarState: {
        ...sidebarState,
        darkMode,
      },
    },
  });

  return darkMode;
};
