import gql from 'graphql-tag';

export const THING = gql`
  fragment Thing on Thing {
    id
    type
    name
  }
`;

export const THINGS = gql`
  fragment Things on ThingCollection {
    limit
    offset
    total
    items {
      ...Thing
    }
  }
  ${THING}
`;

export const GET_THINGS = gql`
  query getThings($query: ThingsQuery!) {
    things(query: $query) {
      ...Things
    }
  }
  ${THINGS}
`;

export const THING_DETAIL = gql`
  fragment ThingDetail on Thing {
    ...Thing
  }
  ${THING}
`;

export const GET_THING = gql`
  query getThing($query: ID!) {
    thing(id: $query) {
      ...ThingDetail
    }
  }
  ${THING_DETAIL}
`;
