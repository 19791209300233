export interface Zip {
  zip: number | string;
  city: string;
  canton: string;
}

const zip: Zip[] = [
  {
    zip: 1000,
    city: 'Lausanne',
    canton: 'VD',
  },
  {
    zip: 1003,
    city: 'Lausanne',
    canton: 'VD',
  },
  {
    zip: 1004,
    city: 'Lausanne',
    canton: 'VD',
  },
  {
    zip: 1005,
    city: 'Lausanne',
    canton: 'VD',
  },
  {
    zip: 1006,
    city: 'Lausanne',
    canton: 'VD',
  },
  {
    zip: 1007,
    city: 'Lausanne',
    canton: 'VD',
  },
  {
    zip: 1008,
    city: 'Jouxtens-Mézery',
    canton: 'VD',
  },
  {
    zip: 1008,
    city: 'Prilly',
    canton: 'VD',
  },
  {
    zip: 1009,
    city: 'Pully',
    canton: 'VD',
  },
  {
    zip: 1010,
    city: 'Lausanne',
    canton: 'VD',
  },
  {
    zip: 1011,
    city: 'Lausanne',
    canton: 'VD',
  },
  {
    zip: 1012,
    city: 'Lausanne',
    canton: 'VD',
  },
  {
    zip: 1015,
    city: 'Lausanne',
    canton: 'VD',
  },
  {
    zip: 1018,
    city: 'Lausanne',
    canton: 'VD',
  },
  {
    zip: 1020,
    city: 'Renens VD',
    canton: 'VD',
  },
  {
    zip: 1022,
    city: 'Chavannes-près-Renens',
    canton: 'VD',
  },
  {
    zip: 1023,
    city: 'Crissier',
    canton: 'VD',
  },
  {
    zip: 1024,
    city: 'Ecublens VD',
    canton: 'VD',
  },
  {
    zip: 1025,
    city: 'St-Sulpice VD',
    canton: 'VD',
  },
  {
    zip: 1026,
    city: 'Denges',
    canton: 'VD',
  },
  {
    zip: 1026,
    city: 'Echandens',
    canton: 'VD',
  },
  {
    zip: 1027,
    city: 'Lonay',
    canton: 'VD',
  },
  {
    zip: 1028,
    city: 'Préverenges',
    canton: 'VD',
  },
  {
    zip: 1029,
    city: 'Villars-Ste-Croix',
    canton: 'VD',
  },
  {
    zip: 1030,
    city: 'Bussigny-près-Lausanne',
    canton: 'VD',
  },
  {
    zip: 1031,
    city: 'Mex VD',
    canton: 'VD',
  },
  {
    zip: 1032,
    city: 'Romanel-sur-Lausanne',
    canton: 'VD',
  },
  {
    zip: 1033,
    city: 'Cheseaux-sur-Lausanne',
    canton: 'VD',
  },
  {
    zip: 1034,
    city: 'Boussens',
    canton: 'VD',
  },
  {
    zip: 1035,
    city: 'Bournens',
    canton: 'VD',
  },
  {
    zip: 1036,
    city: 'Sullens',
    canton: 'VD',
  },
  {
    zip: 1037,
    city: 'Etagnières',
    canton: 'VD',
  },
  {
    zip: 1038,
    city: 'Bercher',
    canton: 'VD',
  },
  {
    zip: 1040,
    city: 'St-Barthélemy VD',
    canton: 'VD',
  },
  {
    zip: 1040,
    city: 'Villars-le-Terroir',
    canton: 'VD',
  },
  {
    zip: 1040,
    city: 'Echallens',
    canton: 'VD',
  },
  {
    zip: 1041,
    city: 'Dommartin',
    canton: 'VD',
  },
  {
    zip: 1041,
    city: 'Poliez-Pittet',
    canton: 'VD',
  },
  {
    zip: 1041,
    city: 'Bottens',
    canton: 'VD',
  },
  {
    zip: 1041,
    city: 'Naz',
    canton: 'VD',
  },
  {
    zip: 1041,
    city: 'Montaubion-Chardonney',
    canton: 'VD',
  },
  {
    zip: 1041,
    city: 'Poliez-le-Grand',
    canton: 'VD',
  },
  {
    zip: 1042,
    city: 'Bettens',
    canton: 'VD',
  },
  {
    zip: 1042,
    city: 'Bioley-Orjulaz',
    canton: 'VD',
  },
  {
    zip: 1042,
    city: 'Assens',
    canton: 'VD',
  },
  {
    zip: 1043,
    city: 'Sugnens',
    canton: 'VD',
  },
  {
    zip: 1044,
    city: 'Fey',
    canton: 'VD',
  },
  {
    zip: 1045,
    city: 'Ogens',
    canton: 'VD',
  },
  {
    zip: 1046,
    city: 'Rueyres',
    canton: 'VD',
  },
  {
    zip: 1047,
    city: 'Oppens',
    canton: 'VD',
  },
  {
    zip: 1052,
    city: 'Le Mont-sur-Lausanne',
    canton: 'VD',
  },
  {
    zip: 1053,
    city: 'Bretigny-sur-Morrens',
    canton: 'VD',
  },
  {
    zip: 1053,
    city: 'Cugy VD',
    canton: 'VD',
  },
  {
    zip: 1054,
    city: 'Morrens VD',
    canton: 'VD',
  },
  {
    zip: 1055,
    city: 'Froideville',
    canton: 'VD',
  },
  {
    zip: 1058,
    city: 'Villars-Tiercelin',
    canton: 'VD',
  },
  {
    zip: 1059,
    city: 'Peney-le-Jorat',
    canton: 'VD',
  },
  {
    zip: 1061,
    city: 'Villars-Mendraz',
    canton: 'VD',
  },
  {
    zip: 1062,
    city: 'Sottens',
    canton: 'VD',
  },
  {
    zip: 1063,
    city: 'Peyres-Possens',
    canton: 'VD',
  },
  {
    zip: 1063,
    city: 'Boulens',
    canton: 'VD',
  },
  {
    zip: 1063,
    city: 'Chapelle-sur-Moudon',
    canton: 'VD',
  },
  {
    zip: 1063,
    city: 'Martherenges',
    canton: 'VD',
  },
  {
    zip: 1066,
    city: 'Epalinges',
    canton: 'VD',
  },
  {
    zip: 1068,
    city: 'Les Monts-de-Pully',
    canton: 'VD',
  },
  {
    zip: 1070,
    city: 'Puidoux',
    canton: 'VD',
  },
  {
    zip: 1071,
    city: 'Rivaz',
    canton: 'VD',
  },
  {
    zip: 1071,
    city: 'St-Saphorin (Lavaux)',
    canton: 'VD',
  },
  {
    zip: 1071,
    city: 'Chexbres',
    canton: 'VD',
  },
  {
    zip: 1072,
    city: 'Forel (Lavaux)',
    canton: 'VD',
  },
  {
    zip: 1073,
    city: 'Mollie-Margot',
    canton: 'VD',
  },
  {
    zip: 1073,
    city: 'Savigny',
    canton: 'VD',
  },
  {
    zip: 1076,
    city: 'Ferlens VD',
    canton: 'VD',
  },
  {
    zip: 1077,
    city: 'Servion',
    canton: 'VD',
  },
  {
    zip: 1078,
    city: 'Essertes',
    canton: 'VD',
  },
  {
    zip: 1080,
    city: 'Les Cullayes',
    canton: 'VD',
  },
  {
    zip: 1081,
    city: 'Montpreveyres',
    canton: 'VD',
  },
  {
    zip: 1082,
    city: 'Corcelles-le-Jorat',
    canton: 'VD',
  },
  {
    zip: 1083,
    city: 'Mézières VD',
    canton: 'VD',
  },
  {
    zip: 1084,
    city: 'Carrouge VD',
    canton: 'VD',
  },
  {
    zip: 1085,
    city: 'Vulliens',
    canton: 'VD',
  },
  {
    zip: 1088,
    city: 'Ropraz',
    canton: 'VD',
  },
  {
    zip: 1090,
    city: 'La Croix (Lutry)',
    canton: 'VD',
  },
  {
    zip: 1091,
    city: 'Aran',
    canton: 'VD',
  },
  {
    zip: 1091,
    city: 'Chenaux',
    canton: 'VD',
  },
  {
    zip: 1091,
    city: 'Grandvaux',
    canton: 'VD',
  },
  {
    zip: 1092,
    city: 'Belmont-sur-Lausanne',
    canton: 'VD',
  },
  {
    zip: 1093,
    city: 'La Conversion',
    canton: 'VD',
  },
  {
    zip: 1094,
    city: 'Paudex',
    canton: 'VD',
  },
  {
    zip: 1095,
    city: 'Lutry',
    canton: 'VD',
  },
  {
    zip: 1096,
    city: 'Villette (Lavaux)',
    canton: 'VD',
  },
  {
    zip: 1096,
    city: 'Cully',
    canton: 'VD',
  },
  {
    zip: 1097,
    city: 'Riex',
    canton: 'VD',
  },
  {
    zip: 1098,
    city: 'Epesses',
    canton: 'VD',
  },
  {
    zip: 1110,
    city: 'Morges',
    canton: 'VD',
  },
  {
    zip: 1112,
    city: 'Echichens',
    canton: 'VD',
  },
  {
    zip: 1113,
    city: 'St-Saphorin-sur-Morges',
    canton: 'VD',
  },
  {
    zip: 1114,
    city: 'Colombier VD',
    canton: 'VD',
  },
  {
    zip: 1115,
    city: 'Vullierens',
    canton: 'VD',
  },
  {
    zip: 1116,
    city: 'Cottens VD',
    canton: 'VD',
  },
  {
    zip: 1117,
    city: 'Grancy',
    canton: 'VD',
  },
  {
    zip: 1121,
    city: 'Bremblens',
    canton: 'VD',
  },
  {
    zip: 1122,
    city: 'Romanel-sur-Morges',
    canton: 'VD',
  },
  {
    zip: 1123,
    city: 'Aclens',
    canton: 'VD',
  },
  {
    zip: 1124,
    city: 'Gollion',
    canton: 'VD',
  },
  {
    zip: 1125,
    city: 'Monnaz',
    canton: 'VD',
  },
  {
    zip: 1126,
    city: 'Vaux-sur-Morges',
    canton: 'VD',
  },
  {
    zip: 1127,
    city: 'Clarmont',
    canton: 'VD',
  },
  {
    zip: 1128,
    city: 'Reverolle',
    canton: 'VD',
  },
  {
    zip: 1131,
    city: 'Tolochenaz',
    canton: 'VD',
  },
  {
    zip: 1132,
    city: 'Lully VD',
    canton: 'VD',
  },
  {
    zip: 1134,
    city: 'Vufflens-le-Château',
    canton: 'VD',
  },
  {
    zip: 1134,
    city: 'Chigny',
    canton: 'VD',
  },
  {
    zip: 1135,
    city: 'Denens',
    canton: 'VD',
  },
  {
    zip: 1136,
    city: 'Bussy-Chardonney',
    canton: 'VD',
  },
  {
    zip: 1141,
    city: 'Sévery',
    canton: 'VD',
  },
  {
    zip: 1142,
    city: 'Pampigny',
    canton: 'VD',
  },
  {
    zip: 1143,
    city: 'Apples',
    canton: 'VD',
  },
  {
    zip: 1144,
    city: 'Ballens',
    canton: 'VD',
  },
  {
    zip: 1145,
    city: 'Bière',
    canton: 'VD',
  },
  {
    zip: 1146,
    city: 'Mollens VD',
    canton: 'VD',
  },
  {
    zip: 1147,
    city: 'Montricher',
    canton: 'VD',
  },
  {
    zip: 1148,
    city: 'Cuarnens',
    canton: 'VD',
  },
  {
    zip: 1148,
    city: 'Moiry VD',
    canton: 'VD',
  },
  {
    zip: 1148,
    city: 'La Praz',
    canton: 'VD',
  },
  {
    zip: 1148,
    city: 'Mont-la-Ville',
    canton: 'VD',
  },
  {
    zip: 1148,
    city: 'Chavannes-le-Veyron',
    canton: 'VD',
  },
  {
    zip: 1148,
    city: 'Mauraz',
    canton: 'VD',
  },
  {
    zip: 1148,
    city: 'Villars-Bozon',
    canton: 'VD',
  },
  {
    zip: 1148,
    city: 'La Coudre',
    canton: 'VD',
  },
  {
    zip: 1148,
    city: "L'Isle",
    canton: 'VD',
  },
  {
    zip: 1149,
    city: 'Berolle',
    canton: 'VD',
  },
  {
    zip: 1162,
    city: 'St-Prex',
    canton: 'VD',
  },
  {
    zip: 1163,
    city: 'Etoy',
    canton: 'VD',
  },
  {
    zip: 1164,
    city: 'Buchillon',
    canton: 'VD',
  },
  {
    zip: 1165,
    city: 'Allaman',
    canton: 'VD',
  },
  {
    zip: 1166,
    city: 'Perroy',
    canton: 'VD',
  },
  {
    zip: 1167,
    city: 'Lussy-sur-Morges',
    canton: 'VD',
  },
  {
    zip: 1168,
    city: 'Villars-sous-Yens',
    canton: 'VD',
  },
  {
    zip: 1169,
    city: 'Yens',
    canton: 'VD',
  },
  {
    zip: 1170,
    city: 'Aubonne',
    canton: 'VD',
  },
  {
    zip: 1172,
    city: 'Bougy-Villars',
    canton: 'VD',
  },
  {
    zip: 1173,
    city: 'Féchy',
    canton: 'VD',
  },
  {
    zip: 1174,
    city: 'Montherod',
    canton: 'VD',
  },
  {
    zip: 1174,
    city: 'Pizy',
    canton: 'VD',
  },
  {
    zip: 1175,
    city: 'Lavigny',
    canton: 'VD',
  },
  {
    zip: 1176,
    city: 'St-Livres',
    canton: 'VD',
  },
  {
    zip: 1180,
    city: 'Tartegnin',
    canton: 'VD',
  },
  {
    zip: 1180,
    city: 'Rolle',
    canton: 'VD',
  },
  {
    zip: 1182,
    city: 'Gilly',
    canton: 'VD',
  },
  {
    zip: 1183,
    city: 'Bursins',
    canton: 'VD',
  },
  {
    zip: 1184,
    city: 'Vinzel',
    canton: 'VD',
  },
  {
    zip: 1184,
    city: 'Luins',
    canton: 'VD',
  },
  {
    zip: 1185,
    city: 'Mont-sur-Rolle',
    canton: 'VD',
  },
  {
    zip: 1186,
    city: 'Essertines-sur-Rolle',
    canton: 'VD',
  },
  {
    zip: 1187,
    city: 'St-Oyens',
    canton: 'VD',
  },
  {
    zip: 1188,
    city: 'Gimel',
    canton: 'VD',
  },
  {
    zip: 1188,
    city: 'St-George',
    canton: 'VD',
  },
  {
    zip: 1189,
    city: 'Saubraz',
    canton: 'VD',
  },
  {
    zip: 1195,
    city: 'Dully',
    canton: 'VD',
  },
  {
    zip: 1195,
    city: 'Bursinel',
    canton: 'VD',
  },
  {
    zip: 1196,
    city: 'Gland',
    canton: 'VD',
  },
  {
    zip: 1197,
    city: 'Prangins',
    canton: 'VD',
  },
  {
    zip: 1200,
    city: 'Genève',
    canton: 'GE',
  },
  {
    zip: 1201,
    city: 'Genève',
    canton: 'GE',
  },
  {
    zip: 1202,
    city: 'Genève',
    canton: 'GE',
  },
  {
    zip: 1203,
    city: 'Genève',
    canton: 'GE',
  },
  {
    zip: 1204,
    city: 'Genève',
    canton: 'GE',
  },
  {
    zip: 1205,
    city: 'Genève',
    canton: 'GE',
  },
  {
    zip: 1206,
    city: 'Genève',
    canton: 'GE',
  },
  {
    zip: 1207,
    city: 'Genève',
    canton: 'GE',
  },
  {
    zip: 1208,
    city: 'Genève',
    canton: 'GE',
  },
  {
    zip: 1209,
    city: 'Genève',
    canton: 'GE',
  },
  {
    zip: 1212,
    city: 'Grand-Lancy',
    canton: 'GE',
  },
  {
    zip: 1213,
    city: 'Petit-Lancy',
    canton: 'GE',
  },
  {
    zip: 1213,
    city: 'Onex',
    canton: 'GE',
  },
  {
    zip: 1214,
    city: 'Vernier',
    canton: 'GE',
  },
  {
    zip: 1215,
    city: 'Genève 15 Aéroport',
    canton: 'GE',
  },
  {
    zip: 1216,
    city: 'Cointrin',
    canton: 'GE',
  },
  {
    zip: 1217,
    city: 'Meyrin',
    canton: 'GE',
  },
  {
    zip: 1218,
    city: 'Le Grand-Saconnex',
    canton: 'GE',
  },
  {
    zip: 1219,
    city: 'Le Lignon',
    canton: 'GE',
  },
  {
    zip: 1219,
    city: 'Aïre',
    canton: 'GE',
  },
  {
    zip: 1219,
    city: 'Châtelaine',
    canton: 'GE',
  },
  {
    zip: 1220,
    city: 'Les Avanchets',
    canton: 'GE',
  },
  {
    zip: 1222,
    city: 'Vésenaz',
    canton: 'GE',
  },
  {
    zip: 1223,
    city: 'Cologny',
    canton: 'GE',
  },
  {
    zip: 1224,
    city: 'Chêne-Bougeries',
    canton: 'GE',
  },
  {
    zip: 1225,
    city: 'Chêne-Bourg',
    canton: 'GE',
  },
  {
    zip: 1226,
    city: 'Thônex',
    canton: 'GE',
  },
  {
    zip: 1227,
    city: 'Les Acacias',
    canton: 'GE',
  },
  {
    zip: 1227,
    city: 'Carouge GE',
    canton: 'GE',
  },
  {
    zip: 1228,
    city: 'Plan-les-Ouates',
    canton: 'GE',
  },
  {
    zip: 1231,
    city: 'Conches',
    canton: 'GE',
  },
  {
    zip: 1232,
    city: 'Confignon',
    canton: 'GE',
  },
  {
    zip: 1233,
    city: 'Bernex',
    canton: 'GE',
  },
  {
    zip: 1234,
    city: 'Vessy',
    canton: 'GE',
  },
  {
    zip: 1236,
    city: 'Cartigny',
    canton: 'GE',
  },
  {
    zip: 1237,
    city: 'Avully',
    canton: 'GE',
  },
  {
    zip: 1239,
    city: 'Collex',
    canton: 'GE',
  },
  {
    zip: 1241,
    city: 'Puplinge',
    canton: 'GE',
  },
  {
    zip: 1242,
    city: 'Satigny',
    canton: 'GE',
  },
  {
    zip: 1243,
    city: 'Presinge',
    canton: 'GE',
  },
  {
    zip: 1244,
    city: 'Choulex',
    canton: 'GE',
  },
  {
    zip: 1245,
    city: 'Collonge-Bellerive',
    canton: 'GE',
  },
  {
    zip: 1246,
    city: 'Corsier GE',
    canton: 'GE',
  },
  {
    zip: 1247,
    city: 'Anières',
    canton: 'GE',
  },
  {
    zip: 1248,
    city: 'Hermance',
    canton: 'GE',
  },
  {
    zip: 1251,
    city: 'Gy',
    canton: 'GE',
  },
  {
    zip: 1252,
    city: 'Meinier',
    canton: 'GE',
  },
  {
    zip: 1253,
    city: 'Vandoeuvres',
    canton: 'GE',
  },
  {
    zip: 1254,
    city: 'Jussy',
    canton: 'GE',
  },
  {
    zip: 1255,
    city: 'Veyrier',
    canton: 'GE',
  },
  {
    zip: 1256,
    city: 'Troinex',
    canton: 'GE',
  },
  {
    zip: 1257,
    city: 'La Croix-de-Rozon',
    canton: 'GE',
  },
  {
    zip: 1258,
    city: 'Perly',
    canton: 'GE',
  },
  {
    zip: 1260,
    city: 'Nyon',
    canton: 'VD',
  },
  {
    zip: 1261,
    city: 'Longirod',
    canton: 'VD',
  },
  {
    zip: 1261,
    city: 'Marchissy',
    canton: 'VD',
  },
  {
    zip: 1261,
    city: 'Le Vaud',
    canton: 'VD',
  },
  {
    zip: 1262,
    city: 'Eysins',
    canton: 'VD',
  },
  {
    zip: 1263,
    city: 'Crassier',
    canton: 'VD',
  },
  {
    zip: 1264,
    city: 'St-Cergue',
    canton: 'VD',
  },
  {
    zip: 1265,
    city: 'La Cure',
    canton: 'VD',
  },
  {
    zip: 1266,
    city: 'Duillier',
    canton: 'VD',
  },
  {
    zip: 1267,
    city: 'Vich',
    canton: 'VD',
  },
  {
    zip: 1267,
    city: 'Coinsins',
    canton: 'VD',
  },
  {
    zip: 1268,
    city: 'Burtigny',
    canton: 'VD',
  },
  {
    zip: 1268,
    city: 'Begnins',
    canton: 'VD',
  },
  {
    zip: 1269,
    city: 'Bassins',
    canton: 'VD',
  },
  {
    zip: 1270,
    city: 'Trélex',
    canton: 'VD',
  },
  {
    zip: 1271,
    city: 'Givrins',
    canton: 'VD',
  },
  {
    zip: 1272,
    city: 'Genolier',
    canton: 'VD',
  },
  {
    zip: 1273,
    city: 'Le Muids',
    canton: 'VD',
  },
  {
    zip: 1273,
    city: 'Arzier',
    canton: 'VD',
  },
  {
    zip: 1274,
    city: 'Grens',
    canton: 'VD',
  },
  {
    zip: 1274,
    city: 'Signy',
    canton: 'VD',
  },
  {
    zip: 1275,
    city: 'Chéserex',
    canton: 'VD',
  },
  {
    zip: 1276,
    city: 'Gingins',
    canton: 'VD',
  },
  {
    zip: 1277,
    city: 'Arnex-sur-Nyon',
    canton: 'VD',
  },
  {
    zip: 1277,
    city: 'Borex',
    canton: 'VD',
  },
  {
    zip: 1278,
    city: 'La Rippe',
    canton: 'VD',
  },
  {
    zip: 1279,
    city: 'Bogis-Bossey',
    canton: 'VD',
  },
  {
    zip: 1279,
    city: 'Chavannes-de-Bogis',
    canton: 'VD',
  },
  {
    zip: 1281,
    city: 'Russin',
    canton: 'GE',
  },
  {
    zip: 1283,
    city: 'Dardagny',
    canton: 'GE',
  },
  {
    zip: 1283,
    city: 'La Plaine',
    canton: 'GE',
  },
  {
    zip: 1284,
    city: 'Chancy',
    canton: 'GE',
  },
  {
    zip: 1285,
    city: 'Athenaz (Avusy)',
    canton: 'GE',
  },
  {
    zip: 1286,
    city: 'Soral',
    canton: 'GE',
  },
  {
    zip: 1287,
    city: 'Laconnex',
    canton: 'GE',
  },
  {
    zip: 1288,
    city: 'Aire-la-Ville',
    canton: 'GE',
  },
  {
    zip: 1290,
    city: 'Versoix',
    canton: 'GE',
  },
  {
    zip: 1290,
    city: 'Chavannes-des-Bois',
    canton: 'VD',
  },
  {
    zip: 1291,
    city: 'Commugny',
    canton: 'VD',
  },
  {
    zip: 1292,
    city: 'Chambésy',
    canton: 'GE',
  },
  {
    zip: 1293,
    city: 'Bellevue',
    canton: 'GE',
  },
  {
    zip: 1294,
    city: 'Genthod',
    canton: 'GE',
  },
  {
    zip: 1295,
    city: 'Mies',
    canton: 'VD',
  },
  {
    zip: 1295,
    city: 'Tannay',
    canton: 'VD',
  },
  {
    zip: 1296,
    city: 'Coppet',
    canton: 'VD',
  },
  {
    zip: 1297,
    city: 'Founex',
    canton: 'VD',
  },
  {
    zip: 1298,
    city: 'Céligny',
    canton: 'GE',
  },
  {
    zip: 1299,
    city: 'Crans-près-Céligny',
    canton: 'VD',
  },
  {
    zip: 1302,
    city: 'Vufflens-la-Ville',
    canton: 'VD',
  },
  {
    zip: 1303,
    city: 'Penthaz',
    canton: 'VD',
  },
  {
    zip: 1304,
    city: 'Senarclens',
    canton: 'VD',
  },
  {
    zip: 1304,
    city: 'Dizy',
    canton: 'VD',
  },
  {
    zip: 1304,
    city: 'Allens',
    canton: 'VD',
  },
  {
    zip: 1304,
    city: 'Cossonay-Ville',
    canton: 'VD',
  },
  {
    zip: 1305,
    city: 'Penthalaz',
    canton: 'VD',
  },
  {
    zip: 1306,
    city: 'Daillens',
    canton: 'VD',
  },
  {
    zip: 1307,
    city: 'Lussery-Villars',
    canton: 'VD',
  },
  {
    zip: 1308,
    city: 'La Chaux (Cossonay)',
    canton: 'VD',
  },
  {
    zip: 1312,
    city: 'Eclépens',
    canton: 'VD',
  },
  {
    zip: 1313,
    city: 'Ferreyres',
    canton: 'VD',
  },
  {
    zip: 1315,
    city: 'La Sarraz',
    canton: 'VD',
  },
  {
    zip: 1316,
    city: 'Chevilly',
    canton: 'VD',
  },
  {
    zip: 1317,
    city: 'Orny',
    canton: 'VD',
  },
  {
    zip: 1318,
    city: 'Pompaples',
    canton: 'VD',
  },
  {
    zip: 1321,
    city: 'Arnex-sur-Orbe',
    canton: 'VD',
  },
  {
    zip: 1322,
    city: 'Croy',
    canton: 'VD',
  },
  {
    zip: 1323,
    city: 'Romainmôtier',
    canton: 'VD',
  },
  {
    zip: 1324,
    city: 'Premier',
    canton: 'VD',
  },
  {
    zip: 1325,
    city: 'Vaulion',
    canton: 'VD',
  },
  {
    zip: 1326,
    city: 'Juriens',
    canton: 'VD',
  },
  {
    zip: 1329,
    city: 'Bretonnières',
    canton: 'VD',
  },
  {
    zip: 1337,
    city: 'Vallorbe',
    canton: 'VD',
  },
  {
    zip: 1338,
    city: 'Ballaigues',
    canton: 'VD',
  },
  {
    zip: 1341,
    city: 'Orient',
    canton: 'VD',
  },
  {
    zip: 1342,
    city: 'Le Pont',
    canton: 'VD',
  },
  {
    zip: 1343,
    city: 'Les Charbonnières',
    canton: 'VD',
  },
  {
    zip: 1344,
    city: "L'Abbaye",
    canton: 'VD',
  },
  {
    zip: 1345,
    city: 'Le Lieu',
    canton: 'VD',
  },
  {
    zip: 1346,
    city: 'Les Bioux',
    canton: 'VD',
  },
  {
    zip: 1347,
    city: 'Le Solliat',
    canton: 'VD',
  },
  {
    zip: 1347,
    city: 'Le Sentier',
    canton: 'VD',
  },
  {
    zip: 1348,
    city: 'Le Brassus',
    canton: 'VD',
  },
  {
    zip: 1350,
    city: 'Orbe',
    canton: 'VD',
  },
  {
    zip: 1352,
    city: 'Agiez',
    canton: 'VD',
  },
  {
    zip: 1353,
    city: 'Bofflens',
    canton: 'VD',
  },
  {
    zip: 1354,
    city: 'Montcherand',
    canton: 'VD',
  },
  {
    zip: 1355,
    city: 'Sergey',
    canton: 'VD',
  },
  {
    zip: 1355,
    city: "L'Abergement",
    canton: 'VD',
  },
  {
    zip: 1356,
    city: 'Les Clées',
    canton: 'VD',
  },
  {
    zip: 1356,
    city: 'La Russille',
    canton: 'VD',
  },
  {
    zip: 1357,
    city: 'Lignerolle',
    canton: 'VD',
  },
  {
    zip: 1358,
    city: 'Valeyres-sous-Rances',
    canton: 'VD',
  },
  {
    zip: 1372,
    city: 'Bavois',
    canton: 'VD',
  },
  {
    zip: 1373,
    city: 'Chavornay',
    canton: 'VD',
  },
  {
    zip: 1374,
    city: 'Corcelles-sur-Chavornay',
    canton: 'VD',
  },
  {
    zip: 1375,
    city: 'Penthéréaz',
    canton: 'VD',
  },
  {
    zip: 1376,
    city: 'Eclagnens',
    canton: 'VD',
  },
  {
    zip: 1376,
    city: 'Goumoens-le-Jux',
    canton: 'VD',
  },
  {
    zip: 1376,
    city: 'Goumoens-la-Ville',
    canton: 'VD',
  },
  {
    zip: 1377,
    city: 'Oulens-sous-Echallens',
    canton: 'VD',
  },
  {
    zip: 1400,
    city: 'Yverdon-les-Bains',
    canton: 'VD',
  },
  {
    zip: 1400,
    city: 'Cheseaux-Noréaz',
    canton: 'VD',
  },
  {
    zip: 1404,
    city: 'Cuarny',
    canton: 'VD',
  },
  {
    zip: 1404,
    city: 'Villars-Epeney',
    canton: 'VD',
  },
  {
    zip: 1405,
    city: 'Pomy',
    canton: 'VD',
  },
  {
    zip: 1406,
    city: 'Cronay',
    canton: 'VD',
  },
  {
    zip: 1407,
    city: 'Bioley-Magnoux',
    canton: 'VD',
  },
  {
    zip: 1407,
    city: 'Gossens',
    canton: 'VD',
  },
  {
    zip: 1407,
    city: 'Mézery-près-Donneloye',
    canton: 'VD',
  },
  {
    zip: 1407,
    city: 'Donneloye',
    canton: 'VD',
  },
  {
    zip: 1408,
    city: 'Prahins',
    canton: 'VD',
  },
  {
    zip: 1409,
    city: 'Chanéaz',
    canton: 'VD',
  },
  {
    zip: 1410,
    city: 'Prévondavaux',
    canton: 'FR',
  },
  {
    zip: 1410,
    city: 'St-Cierges',
    canton: 'VD',
  },
  {
    zip: 1410,
    city: 'Denezy',
    canton: 'VD',
  },
  {
    zip: 1410,
    city: 'Correvon',
    canton: 'VD',
  },
  {
    zip: 1410,
    city: 'Thierrens',
    canton: 'VD',
  },
  {
    zip: 1412,
    city: 'Valeyres-sous-Ursins',
    canton: 'VD',
  },
  {
    zip: 1412,
    city: 'Ursins',
    canton: 'VD',
  },
  {
    zip: 1413,
    city: 'Orzens',
    canton: 'VD',
  },
  {
    zip: 1415,
    city: 'Molondin',
    canton: 'VD',
  },
  {
    zip: 1415,
    city: 'Démoret',
    canton: 'VD',
  },
  {
    zip: 1416,
    city: 'Pailly',
    canton: 'VD',
  },
  {
    zip: 1417,
    city: 'Epautheyres',
    canton: 'VD',
  },
  {
    zip: 1417,
    city: 'Essertines-sur-Yverdon',
    canton: 'VD',
  },
  {
    zip: 1418,
    city: 'Vuarrens',
    canton: 'VD',
  },
  {
    zip: 1420,
    city: 'Fiez',
    canton: 'VD',
  },
  {
    zip: 1421,
    city: 'Fontaines-sur-Grandson',
    canton: 'VD',
  },
  {
    zip: 1421,
    city: 'Grandevent',
    canton: 'VD',
  },
  {
    zip: 1422,
    city: 'Grandson',
    canton: 'VD',
  },
  {
    zip: 1423,
    city: 'Fontanezier',
    canton: 'VD',
  },
  {
    zip: 1423,
    city: 'Romairon',
    canton: 'VD',
  },
  {
    zip: 1423,
    city: 'Vaugondry',
    canton: 'VD',
  },
  {
    zip: 1423,
    city: 'Villars-Burquin',
    canton: 'VD',
  },
  {
    zip: 1424,
    city: 'Champagne',
    canton: 'VD',
  },
  {
    zip: 1425,
    city: 'Onnens VD',
    canton: 'VD',
  },
  {
    zip: 1426,
    city: 'Corcelles-près-Concise',
    canton: 'VD',
  },
  {
    zip: 1426,
    city: 'Concise',
    canton: 'VD',
  },
  {
    zip: 1427,
    city: 'Bonvillars',
    canton: 'VD',
  },
  {
    zip: 1428,
    city: 'Mutrux',
    canton: 'VD',
  },
  {
    zip: 1428,
    city: 'Provence',
    canton: 'VD',
  },
  {
    zip: 1429,
    city: 'Giez',
    canton: 'VD',
  },
  {
    zip: 1430,
    city: 'Orges',
    canton: 'VD',
  },
  {
    zip: 1431,
    city: 'Vugelles-La Mothe',
    canton: 'VD',
  },
  {
    zip: 1431,
    city: 'Novalles',
    canton: 'VD',
  },
  {
    zip: 1432,
    city: 'Belmont-sur-Yverdon',
    canton: 'VD',
  },
  {
    zip: 1432,
    city: 'Gressy',
    canton: 'VD',
  },
  {
    zip: 1433,
    city: 'Suchy',
    canton: 'VD',
  },
  {
    zip: 1434,
    city: 'Ependes VD',
    canton: 'VD',
  },
  {
    zip: 1435,
    city: 'Essert-Pittet',
    canton: 'VD',
  },
  {
    zip: 1436,
    city: 'Treycovagnes',
    canton: 'VD',
  },
  {
    zip: 1436,
    city: 'Chamblon',
    canton: 'VD',
  },
  {
    zip: 1437,
    city: 'Suscévaz',
    canton: 'VD',
  },
  {
    zip: 1438,
    city: 'Mathod',
    canton: 'VD',
  },
  {
    zip: 1439,
    city: 'Rances',
    canton: 'VD',
  },
  {
    zip: 1441,
    city: 'Valeyres-sous-Montagny',
    canton: 'VD',
  },
  {
    zip: 1442,
    city: 'Montagny-près-Yverdon',
    canton: 'VD',
  },
  {
    zip: 1443,
    city: 'Champvent',
    canton: 'VD',
  },
  {
    zip: 1443,
    city: 'Essert-sous-Champvent',
    canton: 'VD',
  },
  {
    zip: 1443,
    city: 'Villars-sous-Champvent',
    canton: 'VD',
  },
  {
    zip: 1445,
    city: 'Vuiteboeuf',
    canton: 'VD',
  },
  {
    zip: 1446,
    city: 'Baulmes',
    canton: 'VD',
  },
  {
    zip: 1450,
    city: 'La Sagne (Ste-Croix)',
    canton: 'VD',
  },
  {
    zip: 1450,
    city: 'Le Château-de-Ste-Croix',
    canton: 'VD',
  },
  {
    zip: 1450,
    city: 'Ste-Croix',
    canton: 'VD',
  },
  {
    zip: 1452,
    city: 'Les Rasses',
    canton: 'VD',
  },
  {
    zip: 1453,
    city: 'Mauborget',
    canton: 'VD',
  },
  {
    zip: 1453,
    city: 'Bullet',
    canton: 'VD',
  },
  {
    zip: 1454,
    city: 'La Vraconnaz',
    canton: 'VD',
  },
  {
    zip: 1454,
    city: "L'Auberson",
    canton: 'VD',
  },
  {
    zip: 1462,
    city: 'Yvonand',
    canton: 'VD',
  },
  {
    zip: 1463,
    city: 'Rovray',
    canton: 'VD',
  },
  {
    zip: 1464,
    city: 'Chêne-Pâquier',
    canton: 'VD',
  },
  {
    zip: 1464,
    city: 'Chavannes-le-Chêne',
    canton: 'VD',
  },
  {
    zip: 1468,
    city: 'Cheyres',
    canton: 'FR',
  },
  {
    zip: 1470,
    city: 'Bollion',
    canton: 'FR',
  },
  {
    zip: 1470,
    city: 'Lully FR',
    canton: 'FR',
  },
  {
    zip: 1470,
    city: 'Seiry',
    canton: 'FR',
  },
  {
    zip: 1470,
    city: 'Estavayer-le-Lac',
    canton: 'FR',
  },
  {
    zip: 1473,
    city: 'Font',
    canton: 'FR',
  },
  {
    zip: 1473,
    city: 'Châtillon FR',
    canton: 'FR',
  },
  {
    zip: 1474,
    city: 'Châbles FR',
    canton: 'FR',
  },
  {
    zip: 1475,
    city: 'Autavaux',
    canton: 'FR',
  },
  {
    zip: 1475,
    city: 'Forel FR',
    canton: 'FR',
  },
  {
    zip: 1475,
    city: 'Montbrelloz',
    canton: 'FR',
  },
  {
    zip: 1482,
    city: 'Cugy FR',
    canton: 'FR',
  },
  {
    zip: 1483,
    city: 'Frasses',
    canton: 'FR',
  },
  {
    zip: 1483,
    city: 'Vesin',
    canton: 'FR',
  },
  {
    zip: 1483,
    city: 'Montet (Broye)',
    canton: 'FR',
  },
  {
    zip: 1484,
    city: 'Aumont',
    canton: 'FR',
  },
  {
    zip: 1484,
    city: 'Granges-de-Vesin',
    canton: 'FR',
  },
  {
    zip: 1485,
    city: 'Nuvilly',
    canton: 'FR',
  },
  {
    zip: 1486,
    city: 'Vuissens',
    canton: 'FR',
  },
  {
    zip: 1489,
    city: 'Murist',
    canton: 'FR',
  },
  {
    zip: 1509,
    city: 'Vucherens',
    canton: 'VD',
  },
  {
    zip: 1510,
    city: 'Syens',
    canton: 'VD',
  },
  {
    zip: 1510,
    city: 'Moudon',
    canton: 'VD',
  },
  {
    zip: 1512,
    city: 'Chavannes-sur-Moudon',
    canton: 'VD',
  },
  {
    zip: 1513,
    city: 'Hermenches',
    canton: 'VD',
  },
  {
    zip: 1513,
    city: 'Rossenges',
    canton: 'VD',
  },
  {
    zip: 1514,
    city: 'Bussy-sur-Moudon',
    canton: 'VD',
  },
  {
    zip: 1515,
    city: 'Neyruz-sur-Moudon',
    canton: 'VD',
  },
  {
    zip: 1515,
    city: 'Villars-le-Comte',
    canton: 'VD',
  },
  {
    zip: 1521,
    city: 'Curtilles',
    canton: 'VD',
  },
  {
    zip: 1522,
    city: 'Oulens-sur-Lucens',
    canton: 'VD',
  },
  {
    zip: 1522,
    city: 'Lucens',
    canton: 'VD',
  },
  {
    zip: 1523,
    city: 'Granges-près-Marnand',
    canton: 'VD',
  },
  {
    zip: 1524,
    city: 'Marnand',
    canton: 'VD',
  },
  {
    zip: 1525,
    city: 'Seigneux',
    canton: 'VD',
  },
  {
    zip: 1525,
    city: 'Henniez',
    canton: 'VD',
  },
  {
    zip: 1526,
    city: 'Forel-sur-Lucens',
    canton: 'VD',
  },
  {
    zip: 1526,
    city: 'Cremin',
    canton: 'VD',
  },
  {
    zip: 1527,
    city: 'Villeneuve FR',
    canton: 'FR',
  },
  {
    zip: 1528,
    city: 'Praratoud',
    canton: 'FR',
  },
  {
    zip: 1528,
    city: 'Surpierre',
    canton: 'FR',
  },
  {
    zip: 1529,
    city: 'Cheiry',
    canton: 'FR',
  },
  {
    zip: 1530,
    city: 'Payerne',
    canton: 'VD',
  },
  {
    zip: 1532,
    city: 'Fétigny',
    canton: 'FR',
  },
  {
    zip: 1533,
    city: 'Ménières',
    canton: 'FR',
  },
  {
    zip: 1534,
    city: 'Chapelle (Broye)',
    canton: 'FR',
  },
  {
    zip: 1534,
    city: 'Sassel',
    canton: 'VD',
  },
  {
    zip: 1535,
    city: 'Combremont-le-Grand',
    canton: 'VD',
  },
  {
    zip: 1536,
    city: 'Combremont-le-Petit',
    canton: 'VD',
  },
  {
    zip: 1537,
    city: 'Champtauroz',
    canton: 'VD',
  },
  {
    zip: 1538,
    city: 'Treytorrens (Payerne)',
    canton: 'VD',
  },
  {
    zip: 1541,
    city: 'Morens FR',
    canton: 'FR',
  },
  {
    zip: 1541,
    city: 'Sévaz',
    canton: 'FR',
  },
  {
    zip: 1541,
    city: 'Bussy FR',
    canton: 'FR',
  },
  {
    zip: 1542,
    city: 'Rueyres-les-Prés',
    canton: 'FR',
  },
  {
    zip: 1543,
    city: 'Grandcour',
    canton: 'VD',
  },
  {
    zip: 1544,
    city: 'Gletterens',
    canton: 'FR',
  },
  {
    zip: 1545,
    city: 'Chevroux',
    canton: 'VD',
  },
  {
    zip: 1551,
    city: 'Vers-chez-Perrin',
    canton: 'VD',
  },
  {
    zip: 1552,
    city: 'Trey',
    canton: 'VD',
  },
  {
    zip: 1553,
    city: 'Châtonnaye',
    canton: 'FR',
  },
  {
    zip: 1554,
    city: 'Sédeilles',
    canton: 'VD',
  },
  {
    zip: 1554,
    city: 'Rossens VD',
    canton: 'VD',
  },
  {
    zip: 1555,
    city: 'Villarzel',
    canton: 'VD',
  },
  {
    zip: 1562,
    city: 'Corcelles-près-Payerne',
    canton: 'VD',
  },
  {
    zip: 1563,
    city: 'Dompierre FR',
    canton: 'FR',
  },
  {
    zip: 1564,
    city: 'Domdidier',
    canton: 'FR',
  },
  {
    zip: 1565,
    city: 'Vallon',
    canton: 'FR',
  },
  {
    zip: 1565,
    city: 'Missy',
    canton: 'VD',
  },
  {
    zip: 1566,
    city: 'Les Friques',
    canton: 'FR',
  },
  {
    zip: 1566,
    city: 'St-Aubin FR',
    canton: 'FR',
  },
  {
    zip: 1567,
    city: 'Delley',
    canton: 'FR',
  },
  {
    zip: 1568,
    city: 'Portalban',
    canton: 'FR',
  },
  {
    zip: 1580,
    city: 'Oleyres',
    canton: 'VD',
  },
  {
    zip: 1580,
    city: 'Avenches',
    canton: 'VD',
  },
  {
    zip: 1582,
    city: 'Donatyre',
    canton: 'VD',
  },
  {
    zip: 1583,
    city: 'Villarepos',
    canton: 'FR',
  },
  {
    zip: 1584,
    city: 'Villars-le-Grand',
    canton: 'VD',
  },
  {
    zip: 1585,
    city: 'Bellerive VD',
    canton: 'VD',
  },
  {
    zip: 1585,
    city: 'Cotterd',
    canton: 'VD',
  },
  {
    zip: 1585,
    city: 'Salavaux',
    canton: 'VD',
  },
  {
    zip: 1586,
    city: 'Vallamand',
    canton: 'VD',
  },
  {
    zip: 1587,
    city: 'Montmagny',
    canton: 'VD',
  },
  {
    zip: 1587,
    city: 'Constantine',
    canton: 'VD',
  },
  {
    zip: 1588,
    city: 'Cudrefin',
    canton: 'VD',
  },
  {
    zip: 1589,
    city: 'Chabrey',
    canton: 'VD',
  },
  {
    zip: 1595,
    city: 'Clavaleyres',
    canton: 'BE',
  },
  {
    zip: 1595,
    city: 'Faoug',
    canton: 'VD',
  },
  {
    zip: 1607,
    city: 'Les Tavernes',
    canton: 'VD',
  },
  {
    zip: 1607,
    city: 'Les Thioleyres',
    canton: 'VD',
  },
  {
    zip: 1607,
    city: 'Palézieux-Village',
    canton: 'VD',
  },
  {
    zip: 1607,
    city: 'Palézieux',
    canton: 'VD',
  },
  {
    zip: 1608,
    city: 'Chapelle (Glâne)',
    canton: 'FR',
  },
  {
    zip: 1608,
    city: 'Bussigny-sur-Oron',
    canton: 'VD',
  },
  {
    zip: 1608,
    city: 'Chesalles-sur-Oron',
    canton: 'VD',
  },
  {
    zip: 1608,
    city: 'Oron-le-Châtel',
    canton: 'VD',
  },
  {
    zip: 1609,
    city: 'Besencens',
    canton: 'FR',
  },
  {
    zip: 1609,
    city: 'Fiaugères',
    canton: 'FR',
  },
  {
    zip: 1609,
    city: 'Le Jordil',
    canton: 'FR',
  },
  {
    zip: 1609,
    city: 'St-Martin FR',
    canton: 'FR',
  },
  {
    zip: 1610,
    city: 'Châtillens',
    canton: 'VD',
  },
  {
    zip: 1610,
    city: 'Vuibroye',
    canton: 'VD',
  },
  {
    zip: 1610,
    city: 'Oron-la-Ville',
    canton: 'VD',
  },
  {
    zip: 1611,
    city: 'Le Crêt-près-Semsales',
    canton: 'FR',
  },
  {
    zip: 1612,
    city: 'Ecoteaux',
    canton: 'VD',
  },
  {
    zip: 1613,
    city: 'Maracon',
    canton: 'VD',
  },
  {
    zip: 1614,
    city: 'Granges (Veveyse)',
    canton: 'FR',
  },
  {
    zip: 1615,
    city: 'Bossonnens',
    canton: 'FR',
  },
  {
    zip: 1616,
    city: 'Attalens',
    canton: 'FR',
  },
  {
    zip: 1617,
    city: 'Tatroz',
    canton: 'FR',
  },
  {
    zip: 1617,
    city: 'Remaufens',
    canton: 'FR',
  },
  {
    zip: 1618,
    city: 'Châtel-St-Denis',
    canton: 'FR',
  },
  {
    zip: 1619,
    city: 'Les Paccots',
    canton: 'FR',
  },
  {
    zip: 1623,
    city: 'Semsales',
    canton: 'FR',
  },
  {
    zip: 1624,
    city: 'La Verrerie',
    canton: 'FR',
  },
  {
    zip: 1624,
    city: 'Grattavache',
    canton: 'FR',
  },
  {
    zip: 1624,
    city: 'Progens',
    canton: 'FR',
  },
  {
    zip: 1625,
    city: 'Maules',
    canton: 'FR',
  },
  {
    zip: 1625,
    city: 'Sâles (Gruyère)',
    canton: 'FR',
  },
  {
    zip: 1626,
    city: 'Romanens',
    canton: 'FR',
  },
  {
    zip: 1626,
    city: 'Treyfayes',
    canton: 'FR',
  },
  {
    zip: 1626,
    city: 'Rueyres-Treyfayes',
    canton: 'FR',
  },
  {
    zip: 1627,
    city: 'Vaulruz',
    canton: 'FR',
  },
  {
    zip: 1628,
    city: 'Vuadens',
    canton: 'FR',
  },
  {
    zip: 1630,
    city: 'Bulle',
    canton: 'FR',
  },
  {
    zip: 1632,
    city: 'Riaz',
    canton: 'FR',
  },
  {
    zip: 1633,
    city: 'Vuippens',
    canton: 'FR',
  },
  {
    zip: 1633,
    city: 'Marsens',
    canton: 'FR',
  },
  {
    zip: 1634,
    city: 'La Roche FR',
    canton: 'FR',
  },
  {
    zip: 1635,
    city: 'La Tour-de-Trême',
    canton: 'FR',
  },
  {
    zip: 1636,
    city: 'Broc',
    canton: 'FR',
  },
  {
    zip: 1637,
    city: 'Charmey (Gruyère)',
    canton: 'FR',
  },
  {
    zip: 1638,
    city: 'Morlon',
    canton: 'FR',
  },
  {
    zip: 1642,
    city: 'Sorens',
    canton: 'FR',
  },
  {
    zip: 1643,
    city: 'Gumefens',
    canton: 'FR',
  },
  {
    zip: 1644,
    city: 'Avry-devant-Pont',
    canton: 'FR',
  },
  {
    zip: 1645,
    city: 'Le Bry',
    canton: 'FR',
  },
  {
    zip: 1646,
    city: 'Echarlens',
    canton: 'FR',
  },
  {
    zip: 1647,
    city: 'Corbières',
    canton: 'FR',
  },
  {
    zip: 1648,
    city: 'Hauteville',
    canton: 'FR',
  },
  {
    zip: 1649,
    city: 'Pont-la-Ville',
    canton: 'FR',
  },
  {
    zip: 1651,
    city: 'Villarvolard',
    canton: 'FR',
  },
  {
    zip: 1652,
    city: 'Botterens',
    canton: 'FR',
  },
  {
    zip: 1652,
    city: 'Villarbeney',
    canton: 'FR',
  },
  {
    zip: 1653,
    city: 'Châtel-sur-Montsalvens',
    canton: 'FR',
  },
  {
    zip: 1653,
    city: 'Crésuz',
    canton: 'FR',
  },
  {
    zip: 1654,
    city: 'Cerniat FR',
    canton: 'FR',
  },
  {
    zip: 1656,
    city: 'Im Fang',
    canton: 'FR',
  },
  {
    zip: 1656,
    city: 'Jaun',
    canton: 'FR',
  },
  {
    zip: 1657,
    city: 'Abländschen',
    canton: 'BE',
  },
  {
    zip: 1658,
    city: 'La Tine',
    canton: 'VD',
  },
  {
    zip: 1658,
    city: 'Rossinière',
    canton: 'VD',
  },
  {
    zip: 1659,
    city: 'Flendruz',
    canton: 'VD',
  },
  {
    zip: 1659,
    city: 'Rougemont',
    canton: 'VD',
  },
  {
    zip: 1660,
    city: 'Les Moulins',
    canton: 'VD',
  },
  {
    zip: 1660,
    city: "L'Etivaz",
    canton: 'VD',
  },
  {
    zip: 1660,
    city: "Château-d'Oex",
    canton: 'VD',
  },
  {
    zip: 1660,
    city: 'La Lécherette',
    canton: 'VD',
  },
  {
    zip: 1661,
    city: 'Le Pâquier-Montbarry',
    canton: 'FR',
  },
  {
    zip: 1663,
    city: 'Moléson-sur-Gruyères',
    canton: 'FR',
  },
  {
    zip: 1663,
    city: 'Pringy',
    canton: 'FR',
  },
  {
    zip: 1663,
    city: 'Gruyères',
    canton: 'FR',
  },
  {
    zip: 1663,
    city: 'Epagny',
    canton: 'FR',
  },
  {
    zip: 1665,
    city: 'Estavannens',
    canton: 'FR',
  },
  {
    zip: 1666,
    city: 'Villars-sous-Mont',
    canton: 'FR',
  },
  {
    zip: 1666,
    city: 'Grandvillard',
    canton: 'FR',
  },
  {
    zip: 1667,
    city: 'Enney',
    canton: 'FR',
  },
  {
    zip: 1669,
    city: 'Neirivue',
    canton: 'FR',
  },
  {
    zip: 1669,
    city: "Les Sciernes-d'Albeuve",
    canton: 'FR',
  },
  {
    zip: 1669,
    city: 'Montbovon',
    canton: 'FR',
  },
  {
    zip: 1669,
    city: 'Lessoc',
    canton: 'FR',
  },
  {
    zip: 1669,
    city: 'Albeuve',
    canton: 'FR',
  },
  {
    zip: 1670,
    city: 'Bionnens',
    canton: 'FR',
  },
  {
    zip: 1670,
    city: 'Esmonts',
    canton: 'FR',
  },
  {
    zip: 1670,
    city: 'Ursy',
    canton: 'FR',
  },
  {
    zip: 1673,
    city: 'Auboranges',
    canton: 'FR',
  },
  {
    zip: 1673,
    city: 'Ecublens FR',
    canton: 'FR',
  },
  {
    zip: 1673,
    city: 'Rue',
    canton: 'FR',
  },
  {
    zip: 1673,
    city: 'Gillarens',
    canton: 'FR',
  },
  {
    zip: 1673,
    city: 'Promasens',
    canton: 'FR',
  },
  {
    zip: 1674,
    city: 'Vuarmarens',
    canton: 'FR',
  },
  {
    zip: 1674,
    city: 'Montet (Glâne)',
    canton: 'FR',
  },
  {
    zip: 1674,
    city: 'Morlens',
    canton: 'FR',
  },
  {
    zip: 1675,
    city: 'Vauderens',
    canton: 'FR',
  },
  {
    zip: 1675,
    city: 'Blessens',
    canton: 'FR',
  },
  {
    zip: 1675,
    city: 'Mossel',
    canton: 'FR',
  },
  {
    zip: 1676,
    city: 'Chavannes-les-Forts',
    canton: 'FR',
  },
  {
    zip: 1677,
    city: 'Prez-vers-Siviriez',
    canton: 'FR',
  },
  {
    zip: 1678,
    city: 'Siviriez',
    canton: 'FR',
  },
  {
    zip: 1679,
    city: 'Villaraboud',
    canton: 'FR',
  },
  {
    zip: 1680,
    city: 'Berlens',
    canton: 'FR',
  },
  {
    zip: 1680,
    city: 'Romont FR',
    canton: 'FR',
  },
  {
    zip: 1681,
    city: 'Billens',
    canton: 'FR',
  },
  {
    zip: 1681,
    city: 'Hennens',
    canton: 'FR',
  },
  {
    zip: 1682,
    city: 'Villars-Bramard',
    canton: 'VD',
  },
  {
    zip: 1682,
    city: 'Dompierre VD',
    canton: 'VD',
  },
  {
    zip: 1682,
    city: 'Lovatens',
    canton: 'VD',
  },
  {
    zip: 1682,
    city: 'Cerniaz VD',
    canton: 'VD',
  },
  {
    zip: 1682,
    city: 'Prévonloup',
    canton: 'VD',
  },
  {
    zip: 1683,
    city: 'Brenles',
    canton: 'VD',
  },
  {
    zip: 1683,
    city: 'Chesalles-sur-Moudon',
    canton: 'VD',
  },
  {
    zip: 1683,
    city: 'Sarzens',
    canton: 'VD',
  },
  {
    zip: 1684,
    city: 'Mézières FR',
    canton: 'FR',
  },
  {
    zip: 1685,
    city: 'Villariaz',
    canton: 'FR',
  },
  {
    zip: 1686,
    city: 'Grangettes-près-Romont',
    canton: 'FR',
  },
  {
    zip: 1686,
    city: 'La Neirigue',
    canton: 'FR',
  },
  {
    zip: 1687,
    city: 'Estévenens',
    canton: 'FR',
  },
  {
    zip: 1687,
    city: 'La Magne',
    canton: 'FR',
  },
  {
    zip: 1687,
    city: 'Vuisternens-devant-Romont',
    canton: 'FR',
  },
  {
    zip: 1688,
    city: 'Sommentier',
    canton: 'FR',
  },
  {
    zip: 1688,
    city: 'Lieffrens',
    canton: 'FR',
  },
  {
    zip: 1689,
    city: 'Le Châtelard-près-Romont',
    canton: 'FR',
  },
  {
    zip: 1690,
    city: 'Lussy FR',
    canton: 'FR',
  },
  {
    zip: 1690,
    city: 'Villaz-St-Pierre',
    canton: 'FR',
  },
  {
    zip: 1691,
    city: 'Villarimboud',
    canton: 'FR',
  },
  {
    zip: 1692,
    city: 'Massonnens',
    canton: 'FR',
  },
  {
    zip: 1694,
    city: 'Orsonnens',
    canton: 'FR',
  },
  {
    zip: 1694,
    city: 'Chavannes-sous-Orsonnens',
    canton: 'FR',
  },
  {
    zip: 1694,
    city: 'Villargiroud',
    canton: 'FR',
  },
  {
    zip: 1694,
    city: 'Villarsiviriaux',
    canton: 'FR',
  },
  {
    zip: 1695,
    city: 'Villarlod',
    canton: 'FR',
  },
  {
    zip: 1695,
    city: 'Estavayer-le-Gibloux',
    canton: 'FR',
  },
  {
    zip: 1695,
    city: 'Rueyres-St-Laurent',
    canton: 'FR',
  },
  {
    zip: 1695,
    city: 'Villarsel-le-Gibloux',
    canton: 'FR',
  },
  {
    zip: 1696,
    city: 'Vuisternens-en-Ogoz',
    canton: 'FR',
  },
  {
    zip: 1697,
    city: 'La Joux FR',
    canton: 'FR',
  },
  {
    zip: 1697,
    city: 'Les Ecasseys',
    canton: 'FR',
  },
  {
    zip: 1699,
    city: 'Bouloz',
    canton: 'FR',
  },
  {
    zip: 1699,
    city: 'Pont (Veveyse)',
    canton: 'FR',
  },
  {
    zip: 1699,
    city: 'Porsel',
    canton: 'FR',
  },
  {
    zip: 1700,
    city: 'Fribourg',
    canton: 'FR',
  },
  {
    zip: 1708,
    city: 'Fribourg',
    canton: 'FR',
  },
  {
    zip: 1712,
    city: 'Tafers',
    canton: 'FR',
  },
  {
    zip: 1713,
    city: 'St. Antoni',
    canton: 'FR',
  },
  {
    zip: 1714,
    city: 'Heitenried',
    canton: 'FR',
  },
  {
    zip: 1715,
    city: 'Alterswil FR',
    canton: 'FR',
  },
  {
    zip: 1716,
    city: 'Oberschrot',
    canton: 'FR',
  },
  {
    zip: 1716,
    city: 'Schwarzsee',
    canton: 'FR',
  },
  {
    zip: 1716,
    city: 'Plaffeien',
    canton: 'FR',
  },
  {
    zip: 1717,
    city: 'St. Ursen',
    canton: 'FR',
  },
  {
    zip: 1718,
    city: 'Rechthalten',
    canton: 'FR',
  },
  {
    zip: 1719,
    city: 'Zumholz',
    canton: 'FR',
  },
  {
    zip: 1719,
    city: 'Brünisried',
    canton: 'FR',
  },
  {
    zip: 1720,
    city: 'Chésopelloz',
    canton: 'FR',
  },
  {
    zip: 1720,
    city: 'Corminboeuf',
    canton: 'FR',
  },
  {
    zip: 1721,
    city: 'Cormérod',
    canton: 'FR',
  },
  {
    zip: 1721,
    city: 'Courtion',
    canton: 'FR',
  },
  {
    zip: 1721,
    city: 'Misery',
    canton: 'FR',
  },
  {
    zip: 1722,
    city: 'Bourguillon',
    canton: 'FR',
  },
  {
    zip: 1723,
    city: 'Marly',
    canton: 'FR',
  },
  {
    zip: 1723,
    city: 'Pierrafortscha',
    canton: 'FR',
  },
  {
    zip: 1723,
    city: 'Villarsel-sur-Marly',
    canton: 'FR',
  },
  {
    zip: 1724,
    city: 'Bonnefontaine',
    canton: 'FR',
  },
  {
    zip: 1724,
    city: 'Essert FR',
    canton: 'FR',
  },
  {
    zip: 1724,
    city: 'Ferpicloz',
    canton: 'FR',
  },
  {
    zip: 1724,
    city: 'Montévraz',
    canton: 'FR',
  },
  {
    zip: 1724,
    city: 'Oberried FR',
    canton: 'FR',
  },
  {
    zip: 1724,
    city: 'Senèdes',
    canton: 'FR',
  },
  {
    zip: 1724,
    city: 'Zénauva',
    canton: 'FR',
  },
  {
    zip: 1724,
    city: 'Le Mouret',
    canton: 'FR',
  },
  {
    zip: 1725,
    city: 'Posieux',
    canton: 'FR',
  },
  {
    zip: 1726,
    city: 'Farvagny-le-Petit',
    canton: 'FR',
  },
  {
    zip: 1726,
    city: 'Grenilles',
    canton: 'FR',
  },
  {
    zip: 1726,
    city: 'Posat',
    canton: 'FR',
  },
  {
    zip: 1726,
    city: 'Farvagny',
    canton: 'FR',
  },
  {
    zip: 1727,
    city: 'Magnedens',
    canton: 'FR',
  },
  {
    zip: 1727,
    city: 'Corpataux-Magnedens',
    canton: 'FR',
  },
  {
    zip: 1728,
    city: 'Rossens FR',
    canton: 'FR',
  },
  {
    zip: 1730,
    city: 'Ecuvillens',
    canton: 'FR',
  },
  {
    zip: 1731,
    city: 'Ependes FR',
    canton: 'FR',
  },
  {
    zip: 1732,
    city: 'Arconciel',
    canton: 'FR',
  },
  {
    zip: 1733,
    city: 'Treyvaux',
    canton: 'FR',
  },
  {
    zip: 1734,
    city: 'Tentlingen',
    canton: 'FR',
  },
  {
    zip: 1735,
    city: 'Giffers',
    canton: 'FR',
  },
  {
    zip: 1736,
    city: 'St. Silvester',
    canton: 'FR',
  },
  {
    zip: 1737,
    city: 'Plasselb',
    canton: 'FR',
  },
  {
    zip: 1738,
    city: 'Sangernboden',
    canton: 'BE',
  },
  {
    zip: 1740,
    city: 'Neyruz FR',
    canton: 'FR',
  },
  {
    zip: 1741,
    city: 'Cottens FR',
    canton: 'FR',
  },
  {
    zip: 1742,
    city: 'Autigny',
    canton: 'FR',
  },
  {
    zip: 1744,
    city: 'Chénens',
    canton: 'FR',
  },
  {
    zip: 1745,
    city: 'Lentigny',
    canton: 'FR',
  },
  {
    zip: 1746,
    city: 'Prez-vers-Noréaz',
    canton: 'FR',
  },
  {
    zip: 1747,
    city: 'Corserey',
    canton: 'FR',
  },
  {
    zip: 1748,
    city: 'Torny-le-Grand',
    canton: 'FR',
  },
  {
    zip: 1749,
    city: 'Middes',
    canton: 'FR',
  },
  {
    zip: 1752,
    city: 'Villars-sur-Glâne',
    canton: 'FR',
  },
  {
    zip: 1753,
    city: 'Matran',
    canton: 'FR',
  },
  {
    zip: 1754,
    city: 'Rosé',
    canton: 'FR',
  },
  {
    zip: 1754,
    city: 'Corjolens',
    canton: 'FR',
  },
  {
    zip: 1754,
    city: 'Avry-sur-Matran',
    canton: 'FR',
  },
  {
    zip: 1754,
    city: 'Avry-Centre FR',
    canton: 'FR',
  },
  {
    zip: 1756,
    city: 'Onnens FR',
    canton: 'FR',
  },
  {
    zip: 1756,
    city: 'Lovens',
    canton: 'FR',
  },
  {
    zip: 1757,
    city: 'Noréaz',
    canton: 'FR',
  },
  {
    zip: 1762,
    city: 'Givisiez',
    canton: 'FR',
  },
  {
    zip: 1763,
    city: 'Granges-Paccot',
    canton: 'FR',
  },
  {
    zip: 1772,
    city: 'Ponthaux',
    canton: 'FR',
  },
  {
    zip: 1772,
    city: 'Nierlet-les-Bois',
    canton: 'FR',
  },
  {
    zip: 1772,
    city: 'Grolley',
    canton: 'FR',
  },
  {
    zip: 1773,
    city: 'Léchelles',
    canton: 'FR',
  },
  {
    zip: 1773,
    city: 'Chandon',
    canton: 'FR',
  },
  {
    zip: 1773,
    city: 'Russy',
    canton: 'FR',
  },
  {
    zip: 1774,
    city: 'Montagny-les-Monts',
    canton: 'FR',
  },
  {
    zip: 1774,
    city: 'Cousset',
    canton: 'FR',
  },
  {
    zip: 1775,
    city: 'Mannens',
    canton: 'FR',
  },
  {
    zip: 1775,
    city: 'Grandsivaz',
    canton: 'FR',
  },
  {
    zip: 1776,
    city: 'Montagny-la-Ville',
    canton: 'FR',
  },
  {
    zip: 1782,
    city: 'Autafond',
    canton: 'FR',
  },
  {
    zip: 1782,
    city: 'La Corbaz',
    canton: 'FR',
  },
  {
    zip: 1782,
    city: 'Cormagens',
    canton: 'FR',
  },
  {
    zip: 1782,
    city: 'Lossy',
    canton: 'FR',
  },
  {
    zip: 1782,
    city: 'Formangueires',
    canton: 'FR',
  },
  {
    zip: 1782,
    city: 'Belfaux',
    canton: 'FR',
  },
  {
    zip: 1783,
    city: 'Pensier',
    canton: 'FR',
  },
  {
    zip: 1783,
    city: 'Barberêche',
    canton: 'FR',
  },
  {
    zip: 1784,
    city: 'Cournillens',
    canton: 'FR',
  },
  {
    zip: 1784,
    city: 'Wallenried',
    canton: 'FR',
  },
  {
    zip: 1784,
    city: 'Courtepin',
    canton: 'FR',
  },
  {
    zip: 1785,
    city: 'Cressier FR',
    canton: 'FR',
  },
  {
    zip: 1786,
    city: 'Sugiez',
    canton: 'FR',
  },
  {
    zip: 1787,
    city: 'Môtier (Vully)',
    canton: 'FR',
  },
  {
    zip: 1787,
    city: 'Mur (Vully) FR',
    canton: 'FR',
  },
  {
    zip: 1787,
    city: 'Mur (Vully) VD',
    canton: 'VD',
  },
  {
    zip: 1788,
    city: 'Praz (Vully)',
    canton: 'FR',
  },
  {
    zip: 1789,
    city: 'Lugnorre',
    canton: 'FR',
  },
  {
    zip: 1791,
    city: 'Courtaman',
    canton: 'FR',
  },
  {
    zip: 1792,
    city: 'Guschelmuth',
    canton: 'FR',
  },
  {
    zip: 1792,
    city: 'Cordast',
    canton: 'FR',
  },
  {
    zip: 1793,
    city: 'Jeuss',
    canton: 'FR',
  },
  {
    zip: 1794,
    city: 'Salvenach',
    canton: 'FR',
  },
  {
    zip: 1795,
    city: 'Courlevon',
    canton: 'FR',
  },
  {
    zip: 1796,
    city: 'Courgevaux',
    canton: 'FR',
  },
  {
    zip: 1797,
    city: 'Münchenwiler',
    canton: 'BE',
  },
  {
    zip: 1800,
    city: 'Vevey',
    canton: 'VD',
  },
  {
    zip: 1801,
    city: 'Le Mont-Pèlerin',
    canton: 'VD',
  },
  {
    zip: 1802,
    city: 'Corseaux',
    canton: 'VD',
  },
  {
    zip: 1803,
    city: 'Chardonne',
    canton: 'VD',
  },
  {
    zip: 1804,
    city: 'Corsier-sur-Vevey',
    canton: 'VD',
  },
  {
    zip: 1805,
    city: 'Jongny',
    canton: 'VD',
  },
  {
    zip: 1806,
    city: 'St-Légier-La Chiésaz',
    canton: 'VD',
  },
  {
    zip: 1807,
    city: 'Blonay',
    canton: 'VD',
  },
  {
    zip: 1808,
    city: 'Les Monts-de-Corsier',
    canton: 'VD',
  },
  {
    zip: 1809,
    city: 'Fenil-sur-Corsier',
    canton: 'VD',
  },
  {
    zip: 1814,
    city: 'La Tour-de-Peilz',
    canton: 'VD',
  },
  {
    zip: 1815,
    city: 'Clarens',
    canton: 'VD',
  },
  {
    zip: 1816,
    city: 'Chailly-Montreux',
    canton: 'VD',
  },
  {
    zip: 1817,
    city: 'Brent',
    canton: 'VD',
  },
  {
    zip: 1820,
    city: 'Montreux',
    canton: 'VD',
  },
  {
    zip: 1820,
    city: 'Veytaux',
    canton: 'VD',
  },
  {
    zip: 1820,
    city: 'Territet',
    canton: 'VD',
  },
  {
    zip: 1822,
    city: 'Chernex',
    canton: 'VD',
  },
  {
    zip: 1823,
    city: 'Glion',
    canton: 'VD',
  },
  {
    zip: 1824,
    city: 'Caux',
    canton: 'VD',
  },
  {
    zip: 1832,
    city: 'Villard-sur-Chamby',
    canton: 'VD',
  },
  {
    zip: 1832,
    city: 'Chamby',
    canton: 'VD',
  },
  {
    zip: 1833,
    city: 'Les Avants',
    canton: 'VD',
  },
  {
    zip: 1844,
    city: 'Villeneuve VD',
    canton: 'VD',
  },
  {
    zip: 1845,
    city: 'Noville',
    canton: 'VD',
  },
  {
    zip: 1846,
    city: 'Chessel',
    canton: 'VD',
  },
  {
    zip: 1847,
    city: 'Rennaz',
    canton: 'VD',
  },
  {
    zip: 1852,
    city: 'Roche VD',
    canton: 'VD',
  },
  {
    zip: 1853,
    city: 'Yvorne',
    canton: 'VD',
  },
  {
    zip: 1854,
    city: 'Leysin',
    canton: 'VD',
  },
  {
    zip: 1856,
    city: 'Corbeyrier',
    canton: 'VD',
  },
  {
    zip: 1860,
    city: 'Aigle',
    canton: 'VD',
  },
  {
    zip: 1862,
    city: 'La Comballaz',
    canton: 'VD',
  },
  {
    zip: 1862,
    city: 'Les Mosses',
    canton: 'VD',
  },
  {
    zip: 1863,
    city: 'Le Sépey',
    canton: 'VD',
  },
  {
    zip: 1864,
    city: "Vers-l'Eglise",
    canton: 'VD',
  },
  {
    zip: 1865,
    city: 'Les Diablerets',
    canton: 'VD',
  },
  {
    zip: 1866,
    city: 'La Forclaz VD',
    canton: 'VD',
  },
  {
    zip: 1867,
    city: 'St-Triphon',
    canton: 'VD',
  },
  {
    zip: 1867,
    city: 'Panex',
    canton: 'VD',
  },
  {
    zip: 1867,
    city: 'Ollon VD',
    canton: 'VD',
  },
  {
    zip: 1868,
    city: 'Collombey',
    canton: 'VS',
  },
  {
    zip: 1869,
    city: 'Massongex',
    canton: 'VS',
  },
  {
    zip: 1870,
    city: 'Monthey',
    canton: 'VS',
  },
  {
    zip: 1871,
    city: 'Choëx',
    canton: 'VS',
  },
  {
    zip: 1871,
    city: 'Les Giettes',
    canton: 'VS',
  },
  {
    zip: 1872,
    city: 'Troistorrents',
    canton: 'VS',
  },
  {
    zip: 1873,
    city: 'Champoussin',
    canton: 'VS',
  },
  {
    zip: 1873,
    city: 'Les Crosets',
    canton: 'VS',
  },
  {
    zip: 1873,
    city: "Val-d'Illiez",
    canton: 'VS',
  },
  {
    zip: 1874,
    city: 'Champéry',
    canton: 'VS',
  },
  {
    zip: 1875,
    city: 'Morgins',
    canton: 'VS',
  },
  {
    zip: 1880,
    city: 'Fenalet-sur-Bex',
    canton: 'VD',
  },
  {
    zip: 1880,
    city: 'Frenières-sur-Bex',
    canton: 'VD',
  },
  {
    zip: 1880,
    city: 'Les Plans-sur-Bex',
    canton: 'VD',
  },
  {
    zip: 1880,
    city: 'Bex',
    canton: 'VD',
  },
  {
    zip: 1882,
    city: 'Les Posses-sur-Bex',
    canton: 'VD',
  },
  {
    zip: 1882,
    city: 'Gryon',
    canton: 'VD',
  },
  {
    zip: 1884,
    city: 'Huémoz',
    canton: 'VD',
  },
  {
    zip: 1884,
    city: 'Arveyes',
    canton: 'VD',
  },
  {
    zip: 1884,
    city: 'Villars-sur-Ollon',
    canton: 'VD',
  },
  {
    zip: 1885,
    city: 'Chesières',
    canton: 'VD',
  },
  {
    zip: 1890,
    city: 'Mex VS',
    canton: 'VS',
  },
  {
    zip: 1890,
    city: 'St-Maurice',
    canton: 'VS',
  },
  {
    zip: 1891,
    city: 'Vérossaz',
    canton: 'VS',
  },
  {
    zip: 1892,
    city: 'Lavey-les-Bains',
    canton: 'VD',
  },
  {
    zip: 1892,
    city: 'Morcles',
    canton: 'VD',
  },
  {
    zip: 1892,
    city: 'Lavey-Village',
    canton: 'VD',
  },
  {
    zip: 1893,
    city: 'Muraz (Collombey)',
    canton: 'VS',
  },
  {
    zip: 1895,
    city: 'Vionnaz',
    canton: 'VS',
  },
  {
    zip: 1896,
    city: 'Miex',
    canton: 'VS',
  },
  {
    zip: 1896,
    city: 'Vouvry',
    canton: 'VS',
  },
  {
    zip: 1897,
    city: 'Les Evouettes',
    canton: 'VS',
  },
  {
    zip: 1897,
    city: 'Bouveret',
    canton: 'VS',
  },
  {
    zip: 1898,
    city: 'St-Gingolph',
    canton: 'VS',
  },
  {
    zip: 1899,
    city: 'Torgon',
    canton: 'VS',
  },
  {
    zip: 1902,
    city: 'Evionnaz',
    canton: 'VS',
  },
  {
    zip: 1903,
    city: 'Collonges',
    canton: 'VS',
  },
  {
    zip: 1904,
    city: 'Vernayaz',
    canton: 'VS',
  },
  {
    zip: 1905,
    city: 'Dorénaz',
    canton: 'VS',
  },
  {
    zip: 1906,
    city: 'Charrat',
    canton: 'VS',
  },
  {
    zip: 1907,
    city: 'Saxon',
    canton: 'VS',
  },
  {
    zip: 1908,
    city: 'Riddes',
    canton: 'VS',
  },
  {
    zip: 1911,
    city: 'Mayens-de-Chamoson',
    canton: 'VS',
  },
  {
    zip: 1911,
    city: 'Ovronnaz',
    canton: 'VS',
  },
  {
    zip: 1912,
    city: 'Produit (Leytron)',
    canton: 'VS',
  },
  {
    zip: 1912,
    city: 'Montagnon (Leytron)',
    canton: 'VS',
  },
  {
    zip: 1912,
    city: 'Dugny (Leytron)',
    canton: 'VS',
  },
  {
    zip: 1912,
    city: 'Leytron',
    canton: 'VS',
  },
  {
    zip: 1913,
    city: 'Saillon',
    canton: 'VS',
  },
  {
    zip: 1914,
    city: 'Auddes-sur-Riddes',
    canton: 'VS',
  },
  {
    zip: 1914,
    city: 'Isérables',
    canton: 'VS',
  },
  {
    zip: 1918,
    city: 'La Tzoumaz',
    canton: 'VS',
  },
  {
    zip: 1920,
    city: 'Martigny',
    canton: 'VS',
  },
  {
    zip: 1921,
    city: 'Martigny-Croix',
    canton: 'VS',
  },
  {
    zip: 1922,
    city: 'Les Granges (Salvan)',
    canton: 'VS',
  },
  {
    zip: 1922,
    city: 'Salvan',
    canton: 'VS',
  },
  {
    zip: 1923,
    city: 'Le Trétien',
    canton: 'VS',
  },
  {
    zip: 1923,
    city: 'Les Marécottes',
    canton: 'VS',
  },
  {
    zip: 1925,
    city: 'Le Châtelard VS',
    canton: 'VS',
  },
  {
    zip: 1925,
    city: 'Finhaut',
    canton: 'VS',
  },
  {
    zip: 1926,
    city: 'Fully',
    canton: 'VS',
  },
  {
    zip: 1927,
    city: 'Chemin',
    canton: 'VS',
  },
  {
    zip: 1928,
    city: 'Ravoire',
    canton: 'VS',
  },
  {
    zip: 1929,
    city: 'Trient',
    canton: 'VS',
  },
  {
    zip: 1932,
    city: 'Les Valettes (Bovernier)',
    canton: 'VS',
  },
  {
    zip: 1932,
    city: 'Bovernier',
    canton: 'VS',
  },
  {
    zip: 1933,
    city: 'Vens (Sembrancher)',
    canton: 'VS',
  },
  {
    zip: 1933,
    city: 'Chamoille (Sembrancher)',
    canton: 'VS',
  },
  {
    zip: 1933,
    city: 'La Garde (Sembrancher)',
    canton: 'VS',
  },
  {
    zip: 1933,
    city: 'Sembrancher',
    canton: 'VS',
  },
  {
    zip: 1934,
    city: 'Bruson',
    canton: 'VS',
  },
  {
    zip: 1934,
    city: 'Cotterg (Le Châble VS)',
    canton: 'VS',
  },
  {
    zip: 1934,
    city: 'Villette (Le Châble VS)',
    canton: 'VS',
  },
  {
    zip: 1934,
    city: 'Fontenelle (Le Châble VS)',
    canton: 'VS',
  },
  {
    zip: 1934,
    city: 'Montagnier (Le Châble VS)',
    canton: 'VS',
  },
  {
    zip: 1934,
    city: 'Le Châble VS',
    canton: 'VS',
  },
  {
    zip: 1936,
    city: 'Verbier',
    canton: 'VS',
  },
  {
    zip: 1937,
    city: 'Orsières',
    canton: 'VS',
  },
  {
    zip: 1938,
    city: 'Champex-Lac',
    canton: 'VS',
  },
  {
    zip: 1941,
    city: 'Cries (Vollèges)',
    canton: 'VS',
  },
  {
    zip: 1941,
    city: 'Vollèges',
    canton: 'VS',
  },
  {
    zip: 1942,
    city: 'Levron',
    canton: 'VS',
  },
  {
    zip: 1943,
    city: 'Praz-de-Fort',
    canton: 'VS',
  },
  {
    zip: 1944,
    city: 'La Fouly VS',
    canton: 'VS',
  },
  {
    zip: 1945,
    city: 'Liddes',
    canton: 'VS',
  },
  {
    zip: 1945,
    city: 'Fontaine Dessus (Liddes)',
    canton: 'VS',
  },
  {
    zip: 1945,
    city: 'Fontaine Dessous (Liddes)',
    canton: 'VS',
  },
  {
    zip: 1945,
    city: 'Dranse (Liddes)',
    canton: 'VS',
  },
  {
    zip: 1945,
    city: 'Chandonne (Liddes)',
    canton: 'VS',
  },
  {
    zip: 1945,
    city: 'Rive Haute (Liddes)',
    canton: 'VS',
  },
  {
    zip: 1945,
    city: 'Fornex (Liddes)',
    canton: 'VS',
  },
  {
    zip: 1945,
    city: 'Les Moulins VS (Liddes)',
    canton: 'VS',
  },
  {
    zip: 1945,
    city: 'Vichères (Liddes)',
    canton: 'VS',
  },
  {
    zip: 1945,
    city: 'Palasuit (Liddes)',
    canton: 'VS',
  },
  {
    zip: 1945,
    city: 'Chez Petit (Liddes)',
    canton: 'VS',
  },
  {
    zip: 1945,
    city: 'Petit Vichères (Liddes)',
    canton: 'VS',
  },
  {
    zip: 1946,
    city: 'Bourg-St-Pierre',
    canton: 'VS',
  },
  {
    zip: 1947,
    city: 'Prarreyer (Versegères)',
    canton: 'VS',
  },
  {
    zip: 1947,
    city: 'Les Places (Versegères)',
    canton: 'VS',
  },
  {
    zip: 1947,
    city: 'La Montoz (Versegères)',
    canton: 'VS',
  },
  {
    zip: 1947,
    city: 'Champsec (Versegères)',
    canton: 'VS',
  },
  {
    zip: 1947,
    city: 'Le Fregnoley (Versegères)',
    canton: 'VS',
  },
  {
    zip: 1947,
    city: 'Versegères',
    canton: 'VS',
  },
  {
    zip: 1948,
    city: 'Lourtier',
    canton: 'VS',
  },
  {
    zip: 1948,
    city: 'Fionnay',
    canton: 'VS',
  },
  {
    zip: 1948,
    city: 'Sarreyer',
    canton: 'VS',
  },
  {
    zip: 1948,
    city: 'Le Planchamp (Lourtier)',
    canton: 'VS',
  },
  {
    zip: 1948,
    city: 'Le Morgnes (Lourtier)',
    canton: 'VS',
  },
  {
    zip: 1950,
    city: 'Sion',
    canton: 'VS',
  },
  {
    zip: 1955,
    city: 'St-Pierre-de-Clages',
    canton: 'VS',
  },
  {
    zip: 1955,
    city: 'Les Vérines (Chamoson)',
    canton: 'VS',
  },
  {
    zip: 1955,
    city: 'Némiaz (Chamoson)',
    canton: 'VS',
  },
  {
    zip: 1955,
    city: 'Grugnay (Chamoson)',
    canton: 'VS',
  },
  {
    zip: 1955,
    city: 'Chamoson',
    canton: 'VS',
  },
  {
    zip: 1957,
    city: 'Ardon',
    canton: 'VS',
  },
  {
    zip: 1958,
    city: 'Uvrier',
    canton: 'VS',
  },
  {
    zip: 1958,
    city: 'St-Léonard',
    canton: 'VS',
  },
  {
    zip: 1961,
    city: 'Vernamiège',
    canton: 'VS',
  },
  {
    zip: 1962,
    city: 'Pont-de-la-Morge (Sion)',
    canton: 'VS',
  },
  {
    zip: 1963,
    city: 'Vétroz',
    canton: 'VS',
  },
  {
    zip: 1964,
    city: 'Conthey',
    canton: 'VS',
  },
  {
    zip: 1965,
    city: 'Chandolin-près-Savièse',
    canton: 'VS',
  },
  {
    zip: 1965,
    city: 'Drône VS',
    canton: 'VS',
  },
  {
    zip: 1965,
    city: 'Roumaz (Savièse)',
    canton: 'VS',
  },
  {
    zip: 1965,
    city: 'Granois (Savièse)',
    canton: 'VS',
  },
  {
    zip: 1965,
    city: 'St-Germain (Savièse)',
    canton: 'VS',
  },
  {
    zip: 1965,
    city: 'Ormône (Savièse)',
    canton: 'VS',
  },
  {
    zip: 1965,
    city: 'Mayens-de-la-Zour (Savièse)',
    canton: 'VS',
  },
  {
    zip: 1965,
    city: 'Monteiller-Savièse',
    canton: 'VS',
  },
  {
    zip: 1965,
    city: 'Savièse',
    canton: 'VS',
  },
  {
    zip: 1966,
    city: 'Signèse (Ayent)',
    canton: 'VS',
  },
  {
    zip: 1966,
    city: 'Fortunau (Ayent)',
    canton: 'VS',
  },
  {
    zip: 1966,
    city: 'Luc (Ayent)',
    canton: 'VS',
  },
  {
    zip: 1966,
    city: 'St-Romain (Ayent)',
    canton: 'VS',
  },
  {
    zip: 1966,
    city: 'Saxonne (Ayent)',
    canton: 'VS',
  },
  {
    zip: 1966,
    city: 'Villa (Ayent)',
    canton: 'VS',
  },
  {
    zip: 1966,
    city: 'La Place (Ayent)',
    canton: 'VS',
  },
  {
    zip: 1966,
    city: 'Botyre (Ayent)',
    canton: 'VS',
  },
  {
    zip: 1966,
    city: 'Blignoud (Ayent)',
    canton: 'VS',
  },
  {
    zip: 1966,
    city: 'Argnoud (Ayent)',
    canton: 'VS',
  },
  {
    zip: 1967,
    city: 'Bramois',
    canton: 'VS',
  },
  {
    zip: 1968,
    city: 'Mase',
    canton: 'VS',
  },
  {
    zip: 1969,
    city: 'Liez (St-Martin)',
    canton: 'VS',
  },
  {
    zip: 1969,
    city: 'Trogne (St-Martin)',
    canton: 'VS',
  },
  {
    zip: 1969,
    city: 'Suen (St-Martin)',
    canton: 'VS',
  },
  {
    zip: 1969,
    city: 'Eison (St.Martin)',
    canton: 'VS',
  },
  {
    zip: 1969,
    city: 'St-Martin VS',
    canton: 'VS',
  },
  {
    zip: 1971,
    city: 'Champlan (Grimisuat)',
    canton: 'VS',
  },
  {
    zip: 1971,
    city: 'Coméraz (Grimisuat)',
    canton: 'VS',
  },
  {
    zip: 1971,
    city: 'Grimisuat',
    canton: 'VS',
  },
  {
    zip: 1972,
    city: 'Anzère',
    canton: 'VS',
  },
  {
    zip: 1973,
    city: 'Nax',
    canton: 'VS',
  },
  {
    zip: 1974,
    city: 'Arbaz',
    canton: 'VS',
  },
  {
    zip: 1975,
    city: 'St-Séverin',
    canton: 'VS',
  },
  {
    zip: 1976,
    city: 'Aven',
    canton: 'VS',
  },
  {
    zip: 1976,
    city: 'Daillon',
    canton: 'VS',
  },
  {
    zip: 1976,
    city: 'Erde',
    canton: 'VS',
  },
  {
    zip: 1977,
    city: 'Icogne',
    canton: 'VS',
  },
  {
    zip: 1978,
    city: 'Lens',
    canton: 'VS',
  },
  {
    zip: 1981,
    city: 'Vex',
    canton: 'VS',
  },
  {
    zip: 1982,
    city: 'Euseigne',
    canton: 'VS',
  },
  {
    zip: 1983,
    city: 'Lana (Evolène)',
    canton: 'VS',
  },
  {
    zip: 1983,
    city: 'Evolène',
    canton: 'VS',
  },
  {
    zip: 1984,
    city: 'Les Haudères',
    canton: 'VS',
  },
  {
    zip: 1985,
    city: 'La Sage',
    canton: 'VS',
  },
  {
    zip: 1986,
    city: 'Arolla',
    canton: 'VS',
  },
  {
    zip: 1987,
    city: 'Mâche',
    canton: 'VS',
  },
  {
    zip: 1987,
    city: 'Hérémence',
    canton: 'VS',
  },
  {
    zip: 1988,
    city: 'Les Collons',
    canton: 'VS',
  },
  {
    zip: 1988,
    city: 'Thyon',
    canton: 'VS',
  },
  {
    zip: 1991,
    city: 'Arvillard (Salins)',
    canton: 'VS',
  },
  {
    zip: 1991,
    city: 'Pravidondaz (Salins)',
    canton: 'VS',
  },
  {
    zip: 1991,
    city: 'Turin (Salins)',
    canton: 'VS',
  },
  {
    zip: 1991,
    city: 'Misériez (Salins)',
    canton: 'VS',
  },
  {
    zip: 1991,
    city: 'Salins',
    canton: 'VS',
  },
  {
    zip: 1992,
    city: 'Les Agettes',
    canton: 'VS',
  },
  {
    zip: 1992,
    city: 'Les Mayens-de-Sion',
    canton: 'VS',
  },
  {
    zip: 1992,
    city: 'La Vernaz (Les Agettes)',
    canton: 'VS',
  },
  {
    zip: 1992,
    city: "Crête-à-l'Oeil(Les Agettes)",
    canton: 'VS',
  },
  {
    zip: 1993,
    city: 'Clèbes (Nendaz)',
    canton: 'VS',
  },
  {
    zip: 1993,
    city: 'Veysonnaz',
    canton: 'VS',
  },
  {
    zip: 1994,
    city: 'Aproz (Nendaz)',
    canton: 'VS',
  },
  {
    zip: 1996,
    city: 'Fey (Nendaz)',
    canton: 'VS',
  },
  {
    zip: 1996,
    city: 'Beuson (Nendaz)',
    canton: 'VS',
  },
  {
    zip: 1996,
    city: 'Baar (Nendaz)',
    canton: 'VS',
  },
  {
    zip: 1996,
    city: 'Brignon (Nendaz)',
    canton: 'VS',
  },
  {
    zip: 1996,
    city: 'Bioley-de-Brignon (Nendaz)',
    canton: 'VS',
  },
  {
    zip: 1996,
    city: 'Bieudron (Nendaz)',
    canton: 'VS',
  },
  {
    zip: 1996,
    city: 'Condémines (Nendaz)',
    canton: 'VS',
  },
  {
    zip: 1996,
    city: 'Saclentz (Nendaz)',
    canton: 'VS',
  },
  {
    zip: 1996,
    city: 'Basse-Nendaz',
    canton: 'VS',
  },
  {
    zip: 1997,
    city: 'Siviez (Nendaz)',
    canton: 'VS',
  },
  {
    zip: 1997,
    city: 'Haute-Nendaz',
    canton: 'VS',
  },
  {
    zip: 2000,
    city: 'Neuchâtel',
    canton: 'NE',
  },
  {
    zip: 2012,
    city: 'Auvernier',
    canton: 'NE',
  },
  {
    zip: 2013,
    city: 'Colombier NE',
    canton: 'NE',
  },
  {
    zip: 2014,
    city: 'Bôle',
    canton: 'NE',
  },
  {
    zip: 2015,
    city: 'Areuse',
    canton: 'NE',
  },
  {
    zip: 2016,
    city: 'Cortaillod',
    canton: 'NE',
  },
  {
    zip: 2017,
    city: 'Boudry',
    canton: 'NE',
  },
  {
    zip: 2019,
    city: 'Chambrelien',
    canton: 'NE',
  },
  {
    zip: 2019,
    city: 'Rochefort',
    canton: 'NE',
  },
  {
    zip: 2022,
    city: 'Bevaix',
    canton: 'NE',
  },
  {
    zip: 2023,
    city: 'Gorgier',
    canton: 'NE',
  },
  {
    zip: 2024,
    city: 'St-Aubin-Sauges',
    canton: 'NE',
  },
  {
    zip: 2025,
    city: 'Chez-le-Bart',
    canton: 'NE',
  },
  {
    zip: 2027,
    city: 'Montalchez',
    canton: 'NE',
  },
  {
    zip: 2027,
    city: 'Fresens',
    canton: 'NE',
  },
  {
    zip: 2028,
    city: 'Vaumarcus',
    canton: 'NE',
  },
  {
    zip: 2034,
    city: 'Peseux',
    canton: 'NE',
  },
  {
    zip: 2035,
    city: 'Corcelles NE',
    canton: 'NE',
  },
  {
    zip: 2036,
    city: 'Cormondrèche',
    canton: 'NE',
  },
  {
    zip: 2037,
    city: 'Montmollin',
    canton: 'NE',
  },
  {
    zip: 2037,
    city: 'Montezillon',
    canton: 'NE',
  },
  {
    zip: 2042,
    city: 'Valangin',
    canton: 'NE',
  },
  {
    zip: 2043,
    city: 'Boudevilliers',
    canton: 'NE',
  },
  {
    zip: 2046,
    city: 'Fontaines NE',
    canton: 'NE',
  },
  {
    zip: 2052,
    city: 'La Vue-des-Alpes',
    canton: 'NE',
  },
  {
    zip: 2052,
    city: 'Fontainemelon',
    canton: 'NE',
  },
  {
    zip: 2053,
    city: 'Cernier',
    canton: 'NE',
  },
  {
    zip: 2054,
    city: 'Les Vieux-Prés',
    canton: 'NE',
  },
  {
    zip: 2054,
    city: 'Chézard-St-Martin',
    canton: 'NE',
  },
  {
    zip: 2056,
    city: 'Dombresson',
    canton: 'NE',
  },
  {
    zip: 2057,
    city: 'Villiers',
    canton: 'NE',
  },
  {
    zip: 2058,
    city: 'Le Pâquier NE',
    canton: 'NE',
  },
  {
    zip: 2063,
    city: 'Engollon',
    canton: 'NE',
  },
  {
    zip: 2063,
    city: 'Fenin',
    canton: 'NE',
  },
  {
    zip: 2063,
    city: 'Saules',
    canton: 'NE',
  },
  {
    zip: 2063,
    city: 'Vilars NE',
    canton: 'NE',
  },
  {
    zip: 2065,
    city: 'Savagnier',
    canton: 'NE',
  },
  {
    zip: 2067,
    city: 'Chaumont',
    canton: 'NE',
  },
  {
    zip: 2068,
    city: 'Hauterive NE',
    canton: 'NE',
  },
  {
    zip: 2072,
    city: 'St-Blaise',
    canton: 'NE',
  },
  {
    zip: 2073,
    city: 'Enges',
    canton: 'NE',
  },
  {
    zip: 2074,
    city: 'Marin-Epagnier',
    canton: 'NE',
  },
  {
    zip: 2075,
    city: 'Thielle',
    canton: 'NE',
  },
  {
    zip: 2075,
    city: 'Wavre',
    canton: 'NE',
  },
  {
    zip: 2087,
    city: 'Cornaux NE',
    canton: 'NE',
  },
  {
    zip: 2088,
    city: 'Cressier NE',
    canton: 'NE',
  },
  {
    zip: 2103,
    city: 'Noiraigue',
    canton: 'NE',
  },
  {
    zip: 2105,
    city: 'Travers',
    canton: 'NE',
  },
  {
    zip: 2108,
    city: 'Couvet',
    canton: 'NE',
  },
  {
    zip: 2112,
    city: 'Môtiers NE',
    canton: 'NE',
  },
  {
    zip: 2113,
    city: 'Boveresse',
    canton: 'NE',
  },
  {
    zip: 2114,
    city: 'Fleurier',
    canton: 'NE',
  },
  {
    zip: 2115,
    city: 'Buttes',
    canton: 'NE',
  },
  {
    zip: 2116,
    city: 'Mont-de-Buttes',
    canton: 'NE',
  },
  {
    zip: 2117,
    city: 'La Côte-aux-Fées',
    canton: 'NE',
  },
  {
    zip: 2123,
    city: 'St-Sulpice NE',
    canton: 'NE',
  },
  {
    zip: 2124,
    city: 'Les Sagnettes',
    canton: 'NE',
  },
  {
    zip: 2126,
    city: 'Les Verrières',
    canton: 'NE',
  },
  {
    zip: 2127,
    city: 'Les Bayards',
    canton: 'NE',
  },
  {
    zip: 2149,
    city: 'Champ-du-Moulin',
    canton: 'NE',
  },
  {
    zip: 2149,
    city: 'Fretereules',
    canton: 'NE',
  },
  {
    zip: 2149,
    city: 'Brot-Dessous',
    canton: 'NE',
  },
  {
    zip: 2206,
    city: 'Les Geneveys-sur-Coffrane',
    canton: 'NE',
  },
  {
    zip: 2207,
    city: 'Coffrane',
    canton: 'NE',
  },
  {
    zip: 2208,
    city: 'Les Hauts-Geneveys',
    canton: 'NE',
  },
  {
    zip: 2300,
    city: 'La Chaux-de-Fonds',
    canton: 'NE',
  },
  {
    zip: 2300,
    city: 'La Cibourg',
    canton: 'NE',
  },
  {
    zip: 2314,
    city: 'La Sagne NE',
    canton: 'NE',
  },
  {
    zip: 2316,
    city: 'Petit-Martel',
    canton: 'NE',
  },
  {
    zip: 2316,
    city: 'Les Ponts-de-Martel',
    canton: 'NE',
  },
  {
    zip: 2318,
    city: 'Brot-Plamboz',
    canton: 'NE',
  },
  {
    zip: 2322,
    city: 'Le Crêt-du-Locle',
    canton: 'NE',
  },
  {
    zip: 2325,
    city: 'Les Planchettes',
    canton: 'NE',
  },
  {
    zip: 2325,
    city: 'Les Joux-Derrière',
    canton: 'NE',
  },
  {
    zip: 2333,
    city: 'La Cibourg',
    canton: 'BE',
  },
  {
    zip: 2333,
    city: 'La Ferrière',
    canton: 'BE',
  },
  {
    zip: 2336,
    city: 'Les Bois',
    canton: 'JU',
  },
  {
    zip: 2338,
    city: 'Les Emibois',
    canton: 'JU',
  },
  {
    zip: 2338,
    city: 'Muriaux',
    canton: 'JU',
  },
  {
    zip: 2340,
    city: 'Le Noirmont',
    canton: 'JU',
  },
  {
    zip: 2345,
    city: 'Le Cerneux-Veusil',
    canton: 'JU',
  },
  {
    zip: 2345,
    city: 'La Chaux-des-Breuleux',
    canton: 'JU',
  },
  {
    zip: 2345,
    city: 'Les Breuleux',
    canton: 'JU',
  },
  {
    zip: 2350,
    city: 'Saignelégier',
    canton: 'JU',
  },
  {
    zip: 2353,
    city: 'Les Pommerats',
    canton: 'JU',
  },
  {
    zip: 2354,
    city: 'Goumois',
    canton: 'JU',
  },
  {
    zip: 2360,
    city: 'Le Bémont',
    canton: 'JU',
  },
  {
    zip: 2362,
    city: 'Montfavergier',
    canton: 'JU',
  },
  {
    zip: 2362,
    city: 'Montfaucon',
    canton: 'JU',
  },
  {
    zip: 2363,
    city: 'Les Enfers',
    canton: 'JU',
  },
  {
    zip: 2364,
    city: 'St-Brais',
    canton: 'JU',
  },
  {
    zip: 2400,
    city: 'Le Prévoux',
    canton: 'NE',
  },
  {
    zip: 2400,
    city: 'Le Locle',
    canton: 'NE',
  },
  {
    zip: 2405,
    city: 'La Chaux-du-Milieu',
    canton: 'NE',
  },
  {
    zip: 2406,
    city: 'Le Brouillet',
    canton: 'NE',
  },
  {
    zip: 2406,
    city: 'Les Taillères',
    canton: 'NE',
  },
  {
    zip: 2406,
    city: 'La Châtagne',
    canton: 'NE',
  },
  {
    zip: 2406,
    city: 'La Brévine',
    canton: 'NE',
  },
  {
    zip: 2414,
    city: 'Le Cerneux-Péquignot',
    canton: 'NE',
  },
  {
    zip: 2416,
    city: 'Les Brenets',
    canton: 'NE',
  },
  {
    zip: 2500,
    city: 'Biel/Bienne',
    canton: 'BE',
  },
  {
    zip: 2502,
    city: 'Biel/Bienne',
    canton: 'BE',
  },
  {
    zip: 2503,
    city: 'Biel/Bienne',
    canton: 'BE',
  },
  {
    zip: 2504,
    city: 'Biel/Bienne',
    canton: 'BE',
  },
  {
    zip: 2505,
    city: 'Biel/Bienne',
    canton: 'BE',
  },
  {
    zip: 2512,
    city: 'Tüscherz-Alfermée',
    canton: 'BE',
  },
  {
    zip: 2513,
    city: 'Twann',
    canton: 'BE',
  },
  {
    zip: 2514,
    city: 'Ligerz',
    canton: 'BE',
  },
  {
    zip: 2515,
    city: 'Prêles',
    canton: 'BE',
  },
  {
    zip: 2516,
    city: 'Lamboing',
    canton: 'BE',
  },
  {
    zip: 2517,
    city: 'Diesse',
    canton: 'BE',
  },
  {
    zip: 2518,
    city: 'Nods',
    canton: 'BE',
  },
  {
    zip: 2520,
    city: 'La Neuveville',
    canton: 'BE',
  },
  {
    zip: 2523,
    city: 'Lignières',
    canton: 'NE',
  },
  {
    zip: 2525,
    city: 'Le Landeron',
    canton: 'NE',
  },
  {
    zip: 2532,
    city: 'Magglingen/Macolin',
    canton: 'BE',
  },
  {
    zip: 2533,
    city: 'Evilard',
    canton: 'BE',
  },
  {
    zip: 2534,
    city: "Les Prés-d'Orvin",
    canton: 'BE',
  },
  {
    zip: 2534,
    city: 'Orvin',
    canton: 'BE',
  },
  {
    zip: 2535,
    city: 'Frinvillier',
    canton: 'BE',
  },
  {
    zip: 2536,
    city: 'Plagne',
    canton: 'BE',
  },
  {
    zip: 2537,
    city: 'Vauffelin',
    canton: 'BE',
  },
  {
    zip: 2538,
    city: 'Romont BE',
    canton: 'BE',
  },
  {
    zip: 2540,
    city: 'Grenchen',
    canton: 'SO',
  },
  {
    zip: 2542,
    city: 'Pieterlen',
    canton: 'BE',
  },
  {
    zip: 2543,
    city: 'Lengnau BE',
    canton: 'BE',
  },
  {
    zip: 2544,
    city: 'Bettlach',
    canton: 'SO',
  },
  {
    zip: 2545,
    city: 'Selzach',
    canton: 'SO',
  },
  {
    zip: 2552,
    city: 'Orpund',
    canton: 'BE',
  },
  {
    zip: 2553,
    city: 'Safnern',
    canton: 'BE',
  },
  {
    zip: 2554,
    city: 'Meinisberg',
    canton: 'BE',
  },
  {
    zip: 2555,
    city: 'Brügg BE',
    canton: 'BE',
  },
  {
    zip: 2556,
    city: 'Scheuren',
    canton: 'BE',
  },
  {
    zip: 2556,
    city: 'Schwadernau',
    canton: 'BE',
  },
  {
    zip: 2557,
    city: 'Studen BE',
    canton: 'BE',
  },
  {
    zip: 2558,
    city: 'Aegerten',
    canton: 'BE',
  },
  {
    zip: 2560,
    city: 'Nidau',
    canton: 'BE',
  },
  {
    zip: 2562,
    city: 'Port',
    canton: 'BE',
  },
  {
    zip: 2563,
    city: 'Ipsach',
    canton: 'BE',
  },
  {
    zip: 2564,
    city: 'Bellmund',
    canton: 'BE',
  },
  {
    zip: 2565,
    city: 'Jens',
    canton: 'BE',
  },
  {
    zip: 2572,
    city: 'Mörigen',
    canton: 'BE',
  },
  {
    zip: 2572,
    city: 'Sutz',
    canton: 'BE',
  },
  {
    zip: 2575,
    city: 'Hagneck',
    canton: 'BE',
  },
  {
    zip: 2575,
    city: 'Gerolfingen',
    canton: 'BE',
  },
  {
    zip: 2575,
    city: 'Täuffelen',
    canton: 'BE',
  },
  {
    zip: 2576,
    city: 'Lüscherz',
    canton: 'BE',
  },
  {
    zip: 2577,
    city: 'Finsterhennen',
    canton: 'BE',
  },
  {
    zip: 2577,
    city: 'Siselen BE',
    canton: 'BE',
  },
  {
    zip: 2603,
    city: 'Péry',
    canton: 'BE',
  },
  {
    zip: 2604,
    city: 'La Heutte',
    canton: 'BE',
  },
  {
    zip: 2605,
    city: 'Sonceboz-Sombeval',
    canton: 'BE',
  },
  {
    zip: 2606,
    city: 'Corgémont',
    canton: 'BE',
  },
  {
    zip: 2607,
    city: 'Cortébert',
    canton: 'BE',
  },
  {
    zip: 2608,
    city: 'Montagne-de-Courtelary',
    canton: 'BE',
  },
  {
    zip: 2608,
    city: 'Courtelary',
    canton: 'BE',
  },
  {
    zip: 2610,
    city: 'Mont-Soleil',
    canton: 'BE',
  },
  {
    zip: 2610,
    city: 'Mont-Crosin',
    canton: 'BE',
  },
  {
    zip: 2610,
    city: 'Les Pontins',
    canton: 'BE',
  },
  {
    zip: 2610,
    city: 'St-Imier',
    canton: 'BE',
  },
  {
    zip: 2612,
    city: 'Cormoret',
    canton: 'BE',
  },
  {
    zip: 2613,
    city: 'Villeret',
    canton: 'BE',
  },
  {
    zip: 2615,
    city: 'Montagne-de-Sonvilier',
    canton: 'BE',
  },
  {
    zip: 2615,
    city: 'Sonvilier',
    canton: 'BE',
  },
  {
    zip: 2616,
    city: 'La Cibourg',
    canton: 'BE',
  },
  {
    zip: 2616,
    city: 'Renan BE',
    canton: 'BE',
  },
  {
    zip: 2710,
    city: 'Tavannes',
    canton: 'BE',
  },
  {
    zip: 2712,
    city: 'Le Fuet',
    canton: 'BE',
  },
  {
    zip: 2713,
    city: 'Bellelay',
    canton: 'BE',
  },
  {
    zip: 2714,
    city: 'Le Prédame',
    canton: 'JU',
  },
  {
    zip: 2714,
    city: 'Les Genevez JU',
    canton: 'JU',
  },
  {
    zip: 2715,
    city: 'Monible',
    canton: 'BE',
  },
  {
    zip: 2715,
    city: 'Châtelat',
    canton: 'BE',
  },
  {
    zip: 2716,
    city: 'Sornetan',
    canton: 'BE',
  },
  {
    zip: 2717,
    city: 'Fornet-Dessous',
    canton: 'BE',
  },
  {
    zip: 2717,
    city: 'Rebévelier',
    canton: 'BE',
  },
  {
    zip: 2718,
    city: 'Fornet-Dessus',
    canton: 'JU',
  },
  {
    zip: 2718,
    city: 'Lajoux JU',
    canton: 'JU',
  },
  {
    zip: 2720,
    city: 'La Tanne',
    canton: 'BE',
  },
  {
    zip: 2720,
    city: 'Tramelan',
    canton: 'BE',
  },
  {
    zip: 2722,
    city: 'Les Reussilles',
    canton: 'BE',
  },
  {
    zip: 2723,
    city: 'Mont-Tramelan',
    canton: 'BE',
  },
  {
    zip: 2732,
    city: 'Saicourt',
    canton: 'BE',
  },
  {
    zip: 2732,
    city: 'Saules BE',
    canton: 'BE',
  },
  {
    zip: 2732,
    city: 'Loveresse',
    canton: 'BE',
  },
  {
    zip: 2732,
    city: 'Reconvilier',
    canton: 'BE',
  },
  {
    zip: 2733,
    city: 'Pontenet',
    canton: 'BE',
  },
  {
    zip: 2735,
    city: 'Bévilard',
    canton: 'BE',
  },
  {
    zip: 2735,
    city: 'Champoz',
    canton: 'BE',
  },
  {
    zip: 2735,
    city: 'Malleray',
    canton: 'BE',
  },
  {
    zip: 2736,
    city: 'Sorvilier',
    canton: 'BE',
  },
  {
    zip: 2738,
    city: 'Court',
    canton: 'BE',
  },
  {
    zip: 2740,
    city: 'Moutier',
    canton: 'BE',
  },
  {
    zip: 2742,
    city: 'Perrefitte',
    canton: 'BE',
  },
  {
    zip: 2743,
    city: 'Eschert',
    canton: 'BE',
  },
  {
    zip: 2744,
    city: 'Belprahon',
    canton: 'BE',
  },
  {
    zip: 2745,
    city: 'Grandval',
    canton: 'BE',
  },
  {
    zip: 2746,
    city: 'Crémines',
    canton: 'BE',
  },
  {
    zip: 2747,
    city: 'Seehof',
    canton: 'BE',
  },
  {
    zip: 2747,
    city: 'Corcelles BE',
    canton: 'BE',
  },
  {
    zip: 2748,
    city: 'Souboz',
    canton: 'BE',
  },
  {
    zip: 2748,
    city: 'Les Ecorcheresses',
    canton: 'BE',
  },
  {
    zip: 2762,
    city: 'Roches BE',
    canton: 'BE',
  },
  {
    zip: 2800,
    city: 'Delémont',
    canton: 'JU',
  },
  {
    zip: 2802,
    city: 'Develier',
    canton: 'JU',
  },
  {
    zip: 2803,
    city: 'Bourrignon',
    canton: 'JU',
  },
  {
    zip: 2805,
    city: 'Soyhières',
    canton: 'JU',
  },
  {
    zip: 2806,
    city: 'Mettembert',
    canton: 'JU',
  },
  {
    zip: 2807,
    city: 'Lucelle',
    canton: 'JU',
  },
  {
    zip: 2807,
    city: 'Pleigne',
    canton: 'JU',
  },
  {
    zip: 2812,
    city: 'Movelier',
    canton: 'JU',
  },
  {
    zip: 2813,
    city: 'Ederswiler',
    canton: 'JU',
  },
  {
    zip: 2814,
    city: 'Roggenburg',
    canton: 'BL',
  },
  {
    zip: 2822,
    city: 'Courroux',
    canton: 'JU',
  },
  {
    zip: 2823,
    city: 'Courcelon',
    canton: 'JU',
  },
  {
    zip: 2824,
    city: 'Vicques',
    canton: 'JU',
  },
  {
    zip: 2825,
    city: 'Courchapoix',
    canton: 'JU',
  },
  {
    zip: 2826,
    city: 'Corban',
    canton: 'JU',
  },
  {
    zip: 2827,
    city: 'Schelten',
    canton: 'BE',
  },
  {
    zip: 2827,
    city: 'Mervelier',
    canton: 'JU',
  },
  {
    zip: 2828,
    city: 'Montsevelier',
    canton: 'JU',
  },
  {
    zip: 2829,
    city: 'Vermes',
    canton: 'JU',
  },
  {
    zip: 2830,
    city: 'Vellerat',
    canton: 'JU',
  },
  {
    zip: 2830,
    city: 'Courrendlin',
    canton: 'JU',
  },
  {
    zip: 2832,
    city: 'Rebeuvelier',
    canton: 'JU',
  },
  {
    zip: 2842,
    city: 'Rossemaison',
    canton: 'JU',
  },
  {
    zip: 2843,
    city: 'Châtillon JU',
    canton: 'JU',
  },
  {
    zip: 2852,
    city: 'Courtételle',
    canton: 'JU',
  },
  {
    zip: 2853,
    city: 'Courfaivre',
    canton: 'JU',
  },
  {
    zip: 2854,
    city: 'Bassecourt',
    canton: 'JU',
  },
  {
    zip: 2855,
    city: 'Glovelier',
    canton: 'JU',
  },
  {
    zip: 2856,
    city: 'Boécourt',
    canton: 'JU',
  },
  {
    zip: 2857,
    city: 'Montavon',
    canton: 'JU',
  },
  {
    zip: 2863,
    city: 'Undervelier',
    canton: 'JU',
  },
  {
    zip: 2864,
    city: 'Soulce',
    canton: 'JU',
  },
  {
    zip: 2873,
    city: 'Saulcy',
    canton: 'JU',
  },
  {
    zip: 2874,
    city: 'Montfavergier',
    canton: 'JU',
  },
  {
    zip: 2874,
    city: 'St-Brais',
    canton: 'JU',
  },
  {
    zip: 2875,
    city: 'Les Enfers',
    canton: 'JU',
  },
  {
    zip: 2875,
    city: 'Montfaucon',
    canton: 'JU',
  },
  {
    zip: 2877,
    city: 'Le Bémont JU',
    canton: 'JU',
  },
  {
    zip: 2882,
    city: 'St-Ursanne',
    canton: 'JU',
  },
  {
    zip: 2883,
    city: 'Montmelon',
    canton: 'JU',
  },
  {
    zip: 2884,
    city: 'Montenol',
    canton: 'JU',
  },
  {
    zip: 2885,
    city: 'Epauvillers',
    canton: 'JU',
  },
  {
    zip: 2886,
    city: 'Epiquerez',
    canton: 'JU',
  },
  {
    zip: 2887,
    city: 'Soubey',
    canton: 'JU',
  },
  {
    zip: 2888,
    city: 'Seleute',
    canton: 'JU',
  },
  {
    zip: 2889,
    city: 'Ocourt',
    canton: 'JU',
  },
  {
    zip: 2900,
    city: 'Porrentruy',
    canton: 'JU',
  },
  {
    zip: 2902,
    city: 'Fontenais',
    canton: 'JU',
  },
  {
    zip: 2903,
    city: 'Villars-sur Fontenais',
    canton: 'JU',
  },
  {
    zip: 2904,
    city: 'Bressaucourt',
    canton: 'JU',
  },
  {
    zip: 2905,
    city: 'Courtedoux',
    canton: 'JU',
  },
  {
    zip: 2906,
    city: 'Chevenez',
    canton: 'JU',
  },
  {
    zip: 2907,
    city: 'Rocourt',
    canton: 'JU',
  },
  {
    zip: 2908,
    city: 'Grandfontaine',
    canton: 'JU',
  },
  {
    zip: 2912,
    city: "Roche-d'Or",
    canton: 'JU',
  },
  {
    zip: 2912,
    city: 'Réclère',
    canton: 'JU',
  },
  {
    zip: 2914,
    city: 'Damvant',
    canton: 'JU',
  },
  {
    zip: 2915,
    city: 'Bure',
    canton: 'JU',
  },
  {
    zip: 2916,
    city: 'Fahy',
    canton: 'JU',
  },
  {
    zip: 2922,
    city: 'Courchavon',
    canton: 'JU',
  },
  {
    zip: 2923,
    city: 'Courtemaîche',
    canton: 'JU',
  },
  {
    zip: 2924,
    city: 'Montignez',
    canton: 'JU',
  },
  {
    zip: 2925,
    city: 'Buix',
    canton: 'JU',
  },
  {
    zip: 2926,
    city: 'Boncourt',
    canton: 'JU',
  },
  {
    zip: 2932,
    city: 'Coeuve',
    canton: 'JU',
  },
  {
    zip: 2933,
    city: 'Damphreux',
    canton: 'JU',
  },
  {
    zip: 2933,
    city: 'Lugnez',
    canton: 'JU',
  },
  {
    zip: 2935,
    city: 'Beurnevésin',
    canton: 'JU',
  },
  {
    zip: 2942,
    city: 'Alle',
    canton: 'JU',
  },
  {
    zip: 2943,
    city: 'Vendlincourt',
    canton: 'JU',
  },
  {
    zip: 2944,
    city: 'Bonfol',
    canton: 'JU',
  },
  {
    zip: 2946,
    city: 'Miécourt',
    canton: 'JU',
  },
  {
    zip: 2947,
    city: 'Charmoille',
    canton: 'JU',
  },
  {
    zip: 2950,
    city: 'Courtemautruy',
    canton: 'JU',
  },
  {
    zip: 2950,
    city: 'Courgenay',
    canton: 'JU',
  },
  {
    zip: 2952,
    city: 'Cornol',
    canton: 'JU',
  },
  {
    zip: 2953,
    city: 'Fregiécourt',
    canton: 'JU',
  },
  {
    zip: 2953,
    city: 'Pleujouse',
    canton: 'JU',
  },
  {
    zip: 2954,
    city: 'Asuel',
    canton: 'JU',
  },
  {
    zip: 3000,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 3004,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 3005,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 3006,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 3007,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 3008,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 3010,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 3011,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 3012,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 3013,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 3014,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 3015,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 3018,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 3019,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 3020,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 3027,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 3032,
    city: 'Hinterkappelen',
    canton: 'BE',
  },
  {
    zip: 3033,
    city: 'Wohlen b. Bern',
    canton: 'BE',
  },
  {
    zip: 3034,
    city: 'Murzelen',
    canton: 'BE',
  },
  {
    zip: 3035,
    city: 'Frieswil',
    canton: 'BE',
  },
  {
    zip: 3036,
    city: 'Detligen',
    canton: 'BE',
  },
  {
    zip: 3037,
    city: 'Herrenschwanden',
    canton: 'BE',
  },
  {
    zip: 3038,
    city: 'Kirchlindach',
    canton: 'BE',
  },
  {
    zip: 3042,
    city: 'Ortschwaben',
    canton: 'BE',
  },
  {
    zip: 3043,
    city: 'Uettligen',
    canton: 'BE',
  },
  {
    zip: 3044,
    city: 'Innerberg',
    canton: 'BE',
  },
  {
    zip: 3045,
    city: 'Meikirch',
    canton: 'BE',
  },
  {
    zip: 3046,
    city: 'Wahlendorf',
    canton: 'BE',
  },
  {
    zip: 3047,
    city: 'Bremgarten b. Bern',
    canton: 'BE',
  },
  {
    zip: 3048,
    city: 'Worblaufen',
    canton: 'BE',
  },
  {
    zip: 3049,
    city: 'Säriswil',
    canton: 'BE',
  },
  {
    zip: 3052,
    city: 'Zollikofen',
    canton: 'BE',
  },
  {
    zip: 3053,
    city: 'Deisswil b. Münchenbuchsee',
    canton: 'BE',
  },
  {
    zip: 3053,
    city: 'Diemerswil',
    canton: 'BE',
  },
  {
    zip: 3053,
    city: 'Wiggiswil',
    canton: 'BE',
  },
  {
    zip: 3053,
    city: 'Lätti',
    canton: 'BE',
  },
  {
    zip: 3053,
    city: 'Münchenbuchsee',
    canton: 'BE',
  },
  {
    zip: 3054,
    city: 'Schüpfen',
    canton: 'BE',
  },
  {
    zip: 3063,
    city: 'Ittigen',
    canton: 'BE',
  },
  {
    zip: 3065,
    city: 'Bolligen',
    canton: 'BE',
  },
  {
    zip: 3066,
    city: 'Stettlen',
    canton: 'BE',
  },
  {
    zip: 3067,
    city: 'Boll',
    canton: 'BE',
  },
  {
    zip: 3068,
    city: 'Utzigen',
    canton: 'BE',
  },
  {
    zip: 3072,
    city: 'Ostermundigen',
    canton: 'BE',
  },
  {
    zip: 3073,
    city: 'Gümligen',
    canton: 'BE',
  },
  {
    zip: 3074,
    city: 'Muri b. Bern',
    canton: 'BE',
  },
  {
    zip: 3075,
    city: 'Rüfenacht BE',
    canton: 'BE',
  },
  {
    zip: 3076,
    city: 'Worb',
    canton: 'BE',
  },
  {
    zip: 3077,
    city: 'Enggistein',
    canton: 'BE',
  },
  {
    zip: 3078,
    city: 'Richigen',
    canton: 'BE',
  },
  {
    zip: 3082,
    city: 'Schlosswil',
    canton: 'BE',
  },
  {
    zip: 3083,
    city: 'Trimstein',
    canton: 'BE',
  },
  {
    zip: 3084,
    city: 'Wabern',
    canton: 'BE',
  },
  {
    zip: 3086,
    city: 'Englisberg',
    canton: 'BE',
  },
  {
    zip: 3086,
    city: 'Zimmerwald',
    canton: 'BE',
  },
  {
    zip: 3087,
    city: 'Niedermuhlern',
    canton: 'BE',
  },
  {
    zip: 3088,
    city: 'Oberbütschel',
    canton: 'BE',
  },
  {
    zip: 3088,
    city: 'Rüeggisberg',
    canton: 'BE',
  },
  {
    zip: 3089,
    city: 'Hinterfultigen',
    canton: 'BE',
  },
  {
    zip: 3095,
    city: 'Spiegel b. Bern',
    canton: 'BE',
  },
  {
    zip: 3096,
    city: 'Oberbalm',
    canton: 'BE',
  },
  {
    zip: 3097,
    city: 'Liebefeld',
    canton: 'BE',
  },
  {
    zip: 3098,
    city: 'Köniz',
    canton: 'BE',
  },
  {
    zip: 3098,
    city: 'Schliern b. Köniz',
    canton: 'BE',
  },
  {
    zip: 3099,
    city: 'Rüti b. Riggisberg',
    canton: 'BE',
  },
  {
    zip: 3110,
    city: 'Münsingen',
    canton: 'BE',
  },
  {
    zip: 3111,
    city: 'Tägertschi',
    canton: 'BE',
  },
  {
    zip: 3112,
    city: 'Allmendingen b. Bern',
    canton: 'BE',
  },
  {
    zip: 3113,
    city: 'Rubigen',
    canton: 'BE',
  },
  {
    zip: 3114,
    city: 'Wichtrach',
    canton: 'BE',
  },
  {
    zip: 3115,
    city: 'Gerzensee',
    canton: 'BE',
  },
  {
    zip: 3116,
    city: 'Mühledorf BE',
    canton: 'BE',
  },
  {
    zip: 3116,
    city: 'Noflen BE',
    canton: 'BE',
  },
  {
    zip: 3116,
    city: 'Kirchdorf BE',
    canton: 'BE',
  },
  {
    zip: 3122,
    city: 'Kehrsatz',
    canton: 'BE',
  },
  {
    zip: 3123,
    city: 'Belp',
    canton: 'BE',
  },
  {
    zip: 3124,
    city: 'Belpberg',
    canton: 'BE',
  },
  {
    zip: 3125,
    city: 'Toffen',
    canton: 'BE',
  },
  {
    zip: 3126,
    city: 'Gelterfingen',
    canton: 'BE',
  },
  {
    zip: 3126,
    city: 'Kaufdorf',
    canton: 'BE',
  },
  {
    zip: 3127,
    city: 'Lohnstorf',
    canton: 'BE',
  },
  {
    zip: 3127,
    city: 'Mühlethurnen',
    canton: 'BE',
  },
  {
    zip: 3128,
    city: 'Kirchenthurnen',
    canton: 'BE',
  },
  {
    zip: 3128,
    city: 'Rümligen',
    canton: 'BE',
  },
  {
    zip: 3132,
    city: 'Riggisberg',
    canton: 'BE',
  },
  {
    zip: 3144,
    city: 'Gasel',
    canton: 'BE',
  },
  {
    zip: 3145,
    city: 'Niederscherli',
    canton: 'BE',
  },
  {
    zip: 3147,
    city: 'Mittelhäusern',
    canton: 'BE',
  },
  {
    zip: 3148,
    city: 'Lanzenhäusern',
    canton: 'BE',
  },
  {
    zip: 3150,
    city: 'Schwarzenburg',
    canton: 'BE',
  },
  {
    zip: 3152,
    city: 'Mamishaus',
    canton: 'BE',
  },
  {
    zip: 3153,
    city: 'Rüschegg Gambach',
    canton: 'BE',
  },
  {
    zip: 3154,
    city: 'Rüschegg Heubach',
    canton: 'BE',
  },
  {
    zip: 3155,
    city: 'Helgisried-Rohrbach',
    canton: 'BE',
  },
  {
    zip: 3156,
    city: 'Riffenmatt',
    canton: 'BE',
  },
  {
    zip: 3157,
    city: 'Milken',
    canton: 'BE',
  },
  {
    zip: 3158,
    city: 'Guggisberg',
    canton: 'BE',
  },
  {
    zip: 3159,
    city: 'Riedstätt',
    canton: 'BE',
  },
  {
    zip: 3172,
    city: 'Niederwangen b. Bern',
    canton: 'BE',
  },
  {
    zip: 3173,
    city: 'Oberwangen b. Bern',
    canton: 'BE',
  },
  {
    zip: 3174,
    city: 'Thörishaus',
    canton: 'BE',
  },
  {
    zip: 3175,
    city: 'Flamatt',
    canton: 'FR',
  },
  {
    zip: 3176,
    city: 'Neuenegg',
    canton: 'BE',
  },
  {
    zip: 3177,
    city: 'Laupen BE',
    canton: 'BE',
  },
  {
    zip: 3178,
    city: 'Bösingen',
    canton: 'FR',
  },
  {
    zip: 3179,
    city: 'Kriechenwil',
    canton: 'BE',
  },
  {
    zip: 3182,
    city: 'Ueberstorf',
    canton: 'FR',
  },
  {
    zip: 3183,
    city: 'Albligen',
    canton: 'BE',
  },
  {
    zip: 3184,
    city: 'Wünnewil',
    canton: 'FR',
  },
  {
    zip: 3185,
    city: 'Schmitten FR',
    canton: 'FR',
  },
  {
    zip: 3186,
    city: 'Düdingen',
    canton: 'FR',
  },
  {
    zip: 3202,
    city: 'Frauenkappelen',
    canton: 'BE',
  },
  {
    zip: 3203,
    city: 'Mühleberg',
    canton: 'BE',
  },
  {
    zip: 3204,
    city: 'Rosshäusern',
    canton: 'BE',
  },
  {
    zip: 3205,
    city: 'Gümmenen',
    canton: 'BE',
  },
  {
    zip: 3206,
    city: 'Rizenbach',
    canton: 'BE',
  },
  {
    zip: 3206,
    city: 'Ferenbalm',
    canton: 'BE',
  },
  {
    zip: 3206,
    city: 'Biberen',
    canton: 'BE',
  },
  {
    zip: 3206,
    city: 'Gammen',
    canton: 'BE',
  },
  {
    zip: 3206,
    city: 'Wallenbuch',
    canton: 'FR',
  },
  {
    zip: 3207,
    city: 'Wileroltigen',
    canton: 'BE',
  },
  {
    zip: 3207,
    city: 'Golaten',
    canton: 'BE',
  },
  {
    zip: 3208,
    city: 'Gurbrü',
    canton: 'BE',
  },
  {
    zip: 3210,
    city: 'Kerzers',
    canton: 'FR',
  },
  {
    zip: 3212,
    city: 'Kleingurmels',
    canton: 'FR',
  },
  {
    zip: 3212,
    city: 'Gurmels',
    canton: 'FR',
  },
  {
    zip: 3213,
    city: 'Liebistorf',
    canton: 'FR',
  },
  {
    zip: 3213,
    city: 'Kleinbösingen',
    canton: 'FR',
  },
  {
    zip: 3214,
    city: 'Ulmiz',
    canton: 'FR',
  },
  {
    zip: 3215,
    city: 'Gempenach',
    canton: 'FR',
  },
  {
    zip: 3215,
    city: 'Büchslen',
    canton: 'FR',
  },
  {
    zip: 3215,
    city: 'Lurtigen',
    canton: 'FR',
  },
  {
    zip: 3216,
    city: 'Agriswil',
    canton: 'FR',
  },
  {
    zip: 3216,
    city: 'Ried b. Kerzers',
    canton: 'FR',
  },
  {
    zip: 3225,
    city: 'Müntschemier',
    canton: 'BE',
  },
  {
    zip: 3226,
    city: 'Treiten',
    canton: 'BE',
  },
  {
    zip: 3232,
    city: 'Ins',
    canton: 'BE',
  },
  {
    zip: 3233,
    city: 'Tschugg',
    canton: 'BE',
  },
  {
    zip: 3234,
    city: 'Vinelz',
    canton: 'BE',
  },
  {
    zip: 3235,
    city: 'Erlach',
    canton: 'BE',
  },
  {
    zip: 3236,
    city: 'Gampelen',
    canton: 'BE',
  },
  {
    zip: 3237,
    city: 'Brüttelen',
    canton: 'BE',
  },
  {
    zip: 3238,
    city: 'Gals',
    canton: 'BE',
  },
  {
    zip: 3250,
    city: 'Lyss',
    canton: 'BE',
  },
  {
    zip: 3251,
    city: 'Ruppoldsried',
    canton: 'BE',
  },
  {
    zip: 3251,
    city: 'Wengi b. Büren',
    canton: 'BE',
  },
  {
    zip: 3252,
    city: 'Worben',
    canton: 'BE',
  },
  {
    zip: 3253,
    city: 'Schnottwil',
    canton: 'SO',
  },
  {
    zip: 3254,
    city: 'Balm b. Messen',
    canton: 'SO',
  },
  {
    zip: 3254,
    city: 'Messen',
    canton: 'SO',
  },
  {
    zip: 3255,
    city: 'Rapperswil BE',
    canton: 'BE',
  },
  {
    zip: 3256,
    city: 'Dieterswil',
    canton: 'BE',
  },
  {
    zip: 3256,
    city: 'Bangerten b. Dieterswil',
    canton: 'BE',
  },
  {
    zip: 3256,
    city: 'Seewil',
    canton: 'BE',
  },
  {
    zip: 3257,
    city: 'Ammerzwil BE',
    canton: 'BE',
  },
  {
    zip: 3257,
    city: 'Grossaffoltern',
    canton: 'BE',
  },
  {
    zip: 3262,
    city: 'Suberg',
    canton: 'BE',
  },
  {
    zip: 3263,
    city: 'Büetigen',
    canton: 'BE',
  },
  {
    zip: 3264,
    city: 'Diessbach b. Büren',
    canton: 'BE',
  },
  {
    zip: 3266,
    city: 'Wiler b. Seedorf',
    canton: 'BE',
  },
  {
    zip: 3267,
    city: 'Seedorf BE',
    canton: 'BE',
  },
  {
    zip: 3268,
    city: 'Lobsigen',
    canton: 'BE',
  },
  {
    zip: 3270,
    city: 'Aarberg',
    canton: 'BE',
  },
  {
    zip: 3271,
    city: 'Radelfingen b. Aarberg',
    canton: 'BE',
  },
  {
    zip: 3272,
    city: 'Epsach',
    canton: 'BE',
  },
  {
    zip: 3272,
    city: 'Walperswil',
    canton: 'BE',
  },
  {
    zip: 3273,
    city: 'Kappelen',
    canton: 'BE',
  },
  {
    zip: 3274,
    city: 'Hermrigen',
    canton: 'BE',
  },
  {
    zip: 3274,
    city: 'Bühl b. Aarberg',
    canton: 'BE',
  },
  {
    zip: 3274,
    city: 'Merzligen',
    canton: 'BE',
  },
  {
    zip: 3280,
    city: 'Meyriez',
    canton: 'FR',
  },
  {
    zip: 3280,
    city: 'Greng',
    canton: 'FR',
  },
  {
    zip: 3280,
    city: 'Murten',
    canton: 'FR',
  },
  {
    zip: 3282,
    city: 'Bargen BE',
    canton: 'BE',
  },
  {
    zip: 3283,
    city: 'Niederried b. Kallnach',
    canton: 'BE',
  },
  {
    zip: 3283,
    city: 'Kallnach',
    canton: 'BE',
  },
  {
    zip: 3284,
    city: 'Fräschels',
    canton: 'FR',
  },
  {
    zip: 3285,
    city: 'Galmiz',
    canton: 'FR',
  },
  {
    zip: 3286,
    city: 'Muntelier',
    canton: 'FR',
  },
  {
    zip: 3292,
    city: 'Busswil b. Büren',
    canton: 'BE',
  },
  {
    zip: 3293,
    city: 'Dotzigen',
    canton: 'BE',
  },
  {
    zip: 3294,
    city: 'Meienried',
    canton: 'BE',
  },
  {
    zip: 3294,
    city: 'Büren an der Aare',
    canton: 'BE',
  },
  {
    zip: 3295,
    city: 'Rüti b. Büren',
    canton: 'BE',
  },
  {
    zip: 3296,
    city: 'Arch',
    canton: 'BE',
  },
  {
    zip: 3297,
    city: 'Leuzigen',
    canton: 'BE',
  },
  {
    zip: 3298,
    city: 'Oberwil b. Büren',
    canton: 'BE',
  },
  {
    zip: 3302,
    city: 'Moosseedorf',
    canton: 'BE',
  },
  {
    zip: 3303,
    city: 'Zuzwil BE',
    canton: 'BE',
  },
  {
    zip: 3303,
    city: 'Ballmoos',
    canton: 'BE',
  },
  {
    zip: 3303,
    city: 'Münchringen',
    canton: 'BE',
  },
  {
    zip: 3303,
    city: 'Jegenstorf',
    canton: 'BE',
  },
  {
    zip: 3305,
    city: 'Iffwil',
    canton: 'BE',
  },
  {
    zip: 3305,
    city: 'Scheunen',
    canton: 'BE',
  },
  {
    zip: 3306,
    city: 'Etzelkofen',
    canton: 'BE',
  },
  {
    zip: 3307,
    city: 'Brunnenthal',
    canton: 'SO',
  },
  {
    zip: 3308,
    city: 'Grafenried',
    canton: 'BE',
  },
  {
    zip: 3309,
    city: 'Kernenried',
    canton: 'BE',
  },
  {
    zip: 3309,
    city: 'Zauggenried',
    canton: 'BE',
  },
  {
    zip: 3312,
    city: 'Fraubrunnen',
    canton: 'BE',
  },
  {
    zip: 3313,
    city: 'Büren zum Hof',
    canton: 'BE',
  },
  {
    zip: 3314,
    city: 'Schalunen',
    canton: 'BE',
  },
  {
    zip: 3315,
    city: 'Kräiligen',
    canton: 'BE',
  },
  {
    zip: 3315,
    city: 'Bätterkinden',
    canton: 'BE',
  },
  {
    zip: 3317,
    city: 'Limpach',
    canton: 'BE',
  },
  {
    zip: 3317,
    city: 'Mülchi',
    canton: 'BE',
  },
  {
    zip: 3322,
    city: 'Mattstetten',
    canton: 'BE',
  },
  {
    zip: 3322,
    city: 'Urtenen-Schönbühl',
    canton: 'BE',
  },
  {
    zip: 3323,
    city: 'Bäriswil BE',
    canton: 'BE',
  },
  {
    zip: 3324,
    city: 'Mötschwil',
    canton: 'BE',
  },
  {
    zip: 3324,
    city: 'Hindelbank',
    canton: 'BE',
  },
  {
    zip: 3325,
    city: 'Hettiswil b. Hindelbank',
    canton: 'BE',
  },
  {
    zip: 3326,
    city: 'Krauchthal',
    canton: 'BE',
  },
  {
    zip: 3360,
    city: 'Herzogenbuchsee',
    canton: 'BE',
  },
  {
    zip: 3362,
    city: 'Niederönz',
    canton: 'BE',
  },
  {
    zip: 3363,
    city: 'Oberönz',
    canton: 'BE',
  },
  {
    zip: 3365,
    city: 'Seeberg',
    canton: 'BE',
  },
  {
    zip: 3365,
    city: 'Grasswil',
    canton: 'BE',
  },
  {
    zip: 3366,
    city: 'Bettenhausen',
    canton: 'BE',
  },
  {
    zip: 3366,
    city: 'Bollodingen',
    canton: 'BE',
  },
  {
    zip: 3367,
    city: 'Ochlenberg',
    canton: 'BE',
  },
  {
    zip: 3367,
    city: 'Thörigen',
    canton: 'BE',
  },
  {
    zip: 3368,
    city: 'Bleienbach',
    canton: 'BE',
  },
  {
    zip: 3372,
    city: 'Wanzwil',
    canton: 'BE',
  },
  {
    zip: 3373,
    city: 'Heimenhausen',
    canton: 'BE',
  },
  {
    zip: 3373,
    city: 'Röthenbach Herzogenbuchsee',
    canton: 'BE',
  },
  {
    zip: 3374,
    city: 'Wangenried',
    canton: 'BE',
  },
  {
    zip: 3375,
    city: 'Inkwil',
    canton: 'BE',
  },
  {
    zip: 3376,
    city: 'Graben',
    canton: 'BE',
  },
  {
    zip: 3376,
    city: 'Berken',
    canton: 'BE',
  },
  {
    zip: 3377,
    city: 'Walliswil b. Wangen',
    canton: 'BE',
  },
  {
    zip: 3380,
    city: 'Walliswil b. Niederbipp',
    canton: 'BE',
  },
  {
    zip: 3380,
    city: 'Wangen an der Aare',
    canton: 'BE',
  },
  {
    zip: 3400,
    city: 'Burgdorf',
    canton: 'BE',
  },
  {
    zip: 3412,
    city: 'Heimiswil',
    canton: 'BE',
  },
  {
    zip: 3413,
    city: 'Kaltacker',
    canton: 'BE',
  },
  {
    zip: 3414,
    city: 'Oberburg',
    canton: 'BE',
  },
  {
    zip: 3415,
    city: 'Schafhausen im Emmental',
    canton: 'BE',
  },
  {
    zip: 3415,
    city: 'Rüegsauschachen',
    canton: 'BE',
  },
  {
    zip: 3415,
    city: 'Hasle b. Burgdorf',
    canton: 'BE',
  },
  {
    zip: 3416,
    city: 'Affoltern im Emmental',
    canton: 'BE',
  },
  {
    zip: 3417,
    city: 'Rüegsau',
    canton: 'BE',
  },
  {
    zip: 3418,
    city: 'Rüegsbach',
    canton: 'BE',
  },
  {
    zip: 3419,
    city: 'Biembach im Emmental',
    canton: 'BE',
  },
  {
    zip: 3421,
    city: 'Rüti b. Lyssach',
    canton: 'BE',
  },
  {
    zip: 3421,
    city: 'Lyssach',
    canton: 'BE',
  },
  {
    zip: 3422,
    city: 'Rüdtligen',
    canton: 'BE',
  },
  {
    zip: 3422,
    city: 'Alchenflüh',
    canton: 'BE',
  },
  {
    zip: 3422,
    city: 'Kirchberg BE',
    canton: 'BE',
  },
  {
    zip: 3423,
    city: 'Ersigen',
    canton: 'BE',
  },
  {
    zip: 3424,
    city: 'Niederösch',
    canton: 'BE',
  },
  {
    zip: 3424,
    city: 'Oberösch',
    canton: 'BE',
  },
  {
    zip: 3425,
    city: 'Willadingen',
    canton: 'BE',
  },
  {
    zip: 3425,
    city: 'Koppigen',
    canton: 'BE',
  },
  {
    zip: 3426,
    city: 'Aefligen',
    canton: 'BE',
  },
  {
    zip: 3427,
    city: 'Utzenstorf',
    canton: 'BE',
  },
  {
    zip: 3428,
    city: 'Wiler b. Utzenstorf',
    canton: 'BE',
  },
  {
    zip: 3429,
    city: 'Hellsau',
    canton: 'BE',
  },
  {
    zip: 3429,
    city: 'Höchstetten',
    canton: 'BE',
  },
  {
    zip: 3432,
    city: 'Lützelflüh-Goldbach',
    canton: 'BE',
  },
  {
    zip: 3433,
    city: 'Schwanden im Emmental',
    canton: 'BE',
  },
  {
    zip: 3434,
    city: 'Obergoldbach',
    canton: 'BE',
  },
  {
    zip: 3435,
    city: 'Ramsei',
    canton: 'BE',
  },
  {
    zip: 3436,
    city: 'Zollbrück',
    canton: 'BE',
  },
  {
    zip: 3437,
    city: 'Rüderswil',
    canton: 'BE',
  },
  {
    zip: 3438,
    city: 'Lauperswil',
    canton: 'BE',
  },
  {
    zip: 3439,
    city: 'Ranflüh',
    canton: 'BE',
  },
  {
    zip: 3452,
    city: 'Grünenmatt',
    canton: 'BE',
  },
  {
    zip: 3453,
    city: 'Heimisbach',
    canton: 'BE',
  },
  {
    zip: 3454,
    city: 'Sumiswald',
    canton: 'BE',
  },
  {
    zip: 3455,
    city: 'Grünen',
    canton: 'BE',
  },
  {
    zip: 3456,
    city: 'Trachselwald',
    canton: 'BE',
  },
  {
    zip: 3457,
    city: 'Wasen im Emmental',
    canton: 'BE',
  },
  {
    zip: 3462,
    city: 'Weier im Emmental',
    canton: 'BE',
  },
  {
    zip: 3463,
    city: 'Häusernmoos im Emmental',
    canton: 'BE',
  },
  {
    zip: 3464,
    city: 'Schmidigen-Mühleweg',
    canton: 'BE',
  },
  {
    zip: 3465,
    city: 'Dürrenroth',
    canton: 'BE',
  },
  {
    zip: 3472,
    city: 'Rumendingen',
    canton: 'BE',
  },
  {
    zip: 3472,
    city: 'Wynigen',
    canton: 'BE',
  },
  {
    zip: 3473,
    city: 'Alchenstorf',
    canton: 'BE',
  },
  {
    zip: 3474,
    city: 'Rüedisbach',
    canton: 'BE',
  },
  {
    zip: 3475,
    city: 'Riedtwil',
    canton: 'BE',
  },
  {
    zip: 3475,
    city: 'Hermiswil',
    canton: 'BE',
  },
  {
    zip: 3476,
    city: 'Oschwand',
    canton: 'BE',
  },
  {
    zip: 3503,
    city: 'Gysenstein',
    canton: 'BE',
  },
  {
    zip: 3504,
    city: 'Niederhünigen',
    canton: 'BE',
  },
  {
    zip: 3504,
    city: 'Oberhünigen',
    canton: 'BE',
  },
  {
    zip: 3506,
    city: 'Grosshöchstetten',
    canton: 'BE',
  },
  {
    zip: 3507,
    city: 'Biglen',
    canton: 'BE',
  },
  {
    zip: 3508,
    city: 'Arni BE',
    canton: 'BE',
  },
  {
    zip: 3510,
    city: 'Freimettigen',
    canton: 'BE',
  },
  {
    zip: 3510,
    city: 'Häutligen',
    canton: 'BE',
  },
  {
    zip: 3510,
    city: 'Konolfingen',
    canton: 'BE',
  },
  {
    zip: 3512,
    city: 'Walkringen',
    canton: 'BE',
  },
  {
    zip: 3513,
    city: 'Bigenthal',
    canton: 'BE',
  },
  {
    zip: 3531,
    city: 'Oberthal',
    canton: 'BE',
  },
  {
    zip: 3532,
    city: 'Mirchel',
    canton: 'BE',
  },
  {
    zip: 3532,
    city: 'Zäziwil',
    canton: 'BE',
  },
  {
    zip: 3533,
    city: 'Bowil',
    canton: 'BE',
  },
  {
    zip: 3534,
    city: 'Signau',
    canton: 'BE',
  },
  {
    zip: 3535,
    city: 'Schüpbach',
    canton: 'BE',
  },
  {
    zip: 3536,
    city: 'Aeschau',
    canton: 'BE',
  },
  {
    zip: 3537,
    city: 'Eggiwil',
    canton: 'BE',
  },
  {
    zip: 3538,
    city: 'Röthenbach im Emmental',
    canton: 'BE',
  },
  {
    zip: 3543,
    city: 'Emmenmatt',
    canton: 'BE',
  },
  {
    zip: 3550,
    city: 'Langnau im Emmental',
    canton: 'BE',
  },
  {
    zip: 3551,
    city: 'Oberfrittenbach',
    canton: 'BE',
  },
  {
    zip: 3552,
    city: 'Bärau',
    canton: 'BE',
  },
  {
    zip: 3553,
    city: 'Gohl',
    canton: 'BE',
  },
  {
    zip: 3555,
    city: 'Trubschachen',
    canton: 'BE',
  },
  {
    zip: 3556,
    city: 'Trub',
    canton: 'BE',
  },
  {
    zip: 3557,
    city: 'Fankhaus (Trub)',
    canton: 'BE',
  },
  {
    zip: 3600,
    city: 'Thun',
    canton: 'BE',
  },
  {
    zip: 3603,
    city: 'Thun',
    canton: 'BE',
  },
  {
    zip: 3604,
    city: 'Thun',
    canton: 'BE',
  },
  {
    zip: 3608,
    city: 'Thun',
    canton: 'BE',
  },
  {
    zip: 3612,
    city: 'Steffisburg',
    canton: 'BE',
  },
  {
    zip: 3613,
    city: 'Steffisburg',
    canton: 'BE',
  },
  {
    zip: 3614,
    city: 'Unterlangenegg',
    canton: 'BE',
  },
  {
    zip: 3615,
    city: 'Heimenschwand',
    canton: 'BE',
  },
  {
    zip: 3616,
    city: 'Schwarzenegg',
    canton: 'BE',
  },
  {
    zip: 3617,
    city: 'Fahrni b. Thun',
    canton: 'BE',
  },
  {
    zip: 3618,
    city: 'Süderen',
    canton: 'BE',
  },
  {
    zip: 3619,
    city: 'Eriz',
    canton: 'BE',
  },
  {
    zip: 3619,
    city: 'Innereriz',
    canton: 'BE',
  },
  {
    zip: 3622,
    city: 'Homberg b. Thun',
    canton: 'BE',
  },
  {
    zip: 3623,
    city: 'Teuffenthal b. Thun',
    canton: 'BE',
  },
  {
    zip: 3623,
    city: 'Horrenbach',
    canton: 'BE',
  },
  {
    zip: 3623,
    city: 'Buchen BE',
    canton: 'BE',
  },
  {
    zip: 3624,
    city: 'Schwendibach',
    canton: 'BE',
  },
  {
    zip: 3624,
    city: 'Goldiwil (Thun)',
    canton: 'BE',
  },
  {
    zip: 3625,
    city: 'Heiligenschwendi',
    canton: 'BE',
  },
  {
    zip: 3626,
    city: 'Hünibach',
    canton: 'BE',
  },
  {
    zip: 3627,
    city: 'Heimberg',
    canton: 'BE',
  },
  {
    zip: 3628,
    city: 'Kienersrüti',
    canton: 'BE',
  },
  {
    zip: 3628,
    city: 'Uttigen',
    canton: 'BE',
  },
  {
    zip: 3629,
    city: 'Oppligen',
    canton: 'BE',
  },
  {
    zip: 3629,
    city: 'Jaberg',
    canton: 'BE',
  },
  {
    zip: 3629,
    city: 'Kiesen',
    canton: 'BE',
  },
  {
    zip: 3631,
    city: 'Höfen b. Thun',
    canton: 'BE',
  },
  {
    zip: 3632,
    city: 'Niederstocken',
    canton: 'BE',
  },
  {
    zip: 3632,
    city: 'Oberstocken',
    canton: 'BE',
  },
  {
    zip: 3633,
    city: 'Amsoldingen',
    canton: 'BE',
  },
  {
    zip: 3634,
    city: 'Thierachern',
    canton: 'BE',
  },
  {
    zip: 3635,
    city: 'Uebeschi',
    canton: 'BE',
  },
  {
    zip: 3636,
    city: 'Längenbühl',
    canton: 'BE',
  },
  {
    zip: 3636,
    city: 'Forst b. Längenbühl',
    canton: 'BE',
  },
  {
    zip: 3638,
    city: 'Pohlern',
    canton: 'BE',
  },
  {
    zip: 3638,
    city: 'Blumenstein',
    canton: 'BE',
  },
  {
    zip: 3645,
    city: 'Gwatt (Thun)',
    canton: 'BE',
  },
  {
    zip: 3645,
    city: 'Zwieselberg',
    canton: 'BE',
  },
  {
    zip: 3646,
    city: 'Einigen',
    canton: 'BE',
  },
  {
    zip: 3647,
    city: 'Reutigen',
    canton: 'BE',
  },
  {
    zip: 3652,
    city: 'Hilterfingen',
    canton: 'BE',
  },
  {
    zip: 3653,
    city: 'Oberhofen am Thunersee',
    canton: 'BE',
  },
  {
    zip: 3654,
    city: 'Gunten',
    canton: 'BE',
  },
  {
    zip: 3655,
    city: 'Sigriswil',
    canton: 'BE',
  },
  {
    zip: 3656,
    city: 'Tschingel ob Gunten',
    canton: 'BE',
  },
  {
    zip: 3656,
    city: 'Aeschlen ob Gunten',
    canton: 'BE',
  },
  {
    zip: 3656,
    city: 'Ringoldswil',
    canton: 'BE',
  },
  {
    zip: 3657,
    city: 'Schwanden (Sigriswil)',
    canton: 'BE',
  },
  {
    zip: 3658,
    city: 'Merligen',
    canton: 'BE',
  },
  {
    zip: 3661,
    city: 'Uetendorf',
    canton: 'BE',
  },
  {
    zip: 3662,
    city: 'Seftigen',
    canton: 'BE',
  },
  {
    zip: 3663,
    city: 'Gurzelen',
    canton: 'BE',
  },
  {
    zip: 3664,
    city: 'Burgistein',
    canton: 'BE',
  },
  {
    zip: 3665,
    city: 'Wattenwil',
    canton: 'BE',
  },
  {
    zip: 3671,
    city: 'Herbligen',
    canton: 'BE',
  },
  {
    zip: 3671,
    city: 'Brenzikofen',
    canton: 'BE',
  },
  {
    zip: 3672,
    city: 'Aeschlen b. Oberdiessbach',
    canton: 'BE',
  },
  {
    zip: 3672,
    city: 'Oberdiessbach',
    canton: 'BE',
  },
  {
    zip: 3673,
    city: 'Linden',
    canton: 'BE',
  },
  {
    zip: 3674,
    city: 'Bleiken b. Oberdiessbach',
    canton: 'BE',
  },
  {
    zip: 3700,
    city: 'Spiez',
    canton: 'BE',
  },
  {
    zip: 3702,
    city: 'Hondrich',
    canton: 'BE',
  },
  {
    zip: 3703,
    city: 'Aeschiried',
    canton: 'BE',
  },
  {
    zip: 3703,
    city: 'Aeschi b. Spiez',
    canton: 'BE',
  },
  {
    zip: 3704,
    city: 'Krattigen',
    canton: 'BE',
  },
  {
    zip: 3705,
    city: 'Faulensee',
    canton: 'BE',
  },
  {
    zip: 3706,
    city: 'Leissigen',
    canton: 'BE',
  },
  {
    zip: 3707,
    city: 'Därligen',
    canton: 'BE',
  },
  {
    zip: 3711,
    city: 'Emdthal',
    canton: 'BE',
  },
  {
    zip: 3711,
    city: 'Mülenen',
    canton: 'BE',
  },
  {
    zip: 3713,
    city: 'Reichenbach im Kandertal',
    canton: 'BE',
  },
  {
    zip: 3714,
    city: 'Wengi b. Frutigen',
    canton: 'BE',
  },
  {
    zip: 3714,
    city: 'Frutigen',
    canton: 'BE',
  },
  {
    zip: 3715,
    city: 'Adelboden',
    canton: 'BE',
  },
  {
    zip: 3716,
    city: 'Kandergrund',
    canton: 'BE',
  },
  {
    zip: 3717,
    city: 'Blausee-Mitholz',
    canton: 'BE',
  },
  {
    zip: 3718,
    city: 'Kandersteg',
    canton: 'BE',
  },
  {
    zip: 3722,
    city: 'Scharnachtal',
    canton: 'BE',
  },
  {
    zip: 3723,
    city: 'Kiental',
    canton: 'BE',
  },
  {
    zip: 3724,
    city: 'Ried (Frutigen)',
    canton: 'BE',
  },
  {
    zip: 3725,
    city: 'Achseten',
    canton: 'BE',
  },
  {
    zip: 3752,
    city: 'Wimmis',
    canton: 'BE',
  },
  {
    zip: 3753,
    city: 'Oey',
    canton: 'BE',
  },
  {
    zip: 3754,
    city: 'Diemtigen',
    canton: 'BE',
  },
  {
    zip: 3755,
    city: 'Horboden',
    canton: 'BE',
  },
  {
    zip: 3756,
    city: 'Zwischenflüh',
    canton: 'BE',
  },
  {
    zip: 3757,
    city: 'Schwenden im Diemtigtal',
    canton: 'BE',
  },
  {
    zip: 3758,
    city: 'Latterbach',
    canton: 'BE',
  },
  {
    zip: 3762,
    city: 'Erlenbach im Simmental',
    canton: 'BE',
  },
  {
    zip: 3763,
    city: 'Därstetten',
    canton: 'BE',
  },
  {
    zip: 3764,
    city: 'Weissenburg',
    canton: 'BE',
  },
  {
    zip: 3765,
    city: 'Oberwil im Simmental',
    canton: 'BE',
  },
  {
    zip: 3766,
    city: 'Boltigen',
    canton: 'BE',
  },
  {
    zip: 3770,
    city: 'Zweisimmen',
    canton: 'BE',
  },
  {
    zip: 3771,
    city: 'Blankenburg',
    canton: 'BE',
  },
  {
    zip: 3772,
    city: 'St. Stephan',
    canton: 'BE',
  },
  {
    zip: 3773,
    city: 'Matten (St. Stephan)',
    canton: 'BE',
  },
  {
    zip: 3775,
    city: 'Lenk im Simmental',
    canton: 'BE',
  },
  {
    zip: 3776,
    city: 'Oeschseite',
    canton: 'BE',
  },
  {
    zip: 3777,
    city: 'Saanenmöser',
    canton: 'BE',
  },
  {
    zip: 3778,
    city: 'Schönried',
    canton: 'BE',
  },
  {
    zip: 3780,
    city: 'Gstaad',
    canton: 'BE',
  },
  {
    zip: 3781,
    city: 'Turbach',
    canton: 'BE',
  },
  {
    zip: 3782,
    city: 'Lauenen b. Gstaad',
    canton: 'BE',
  },
  {
    zip: 3783,
    city: 'Grund b. Gstaad',
    canton: 'BE',
  },
  {
    zip: 3784,
    city: 'Feutersoey',
    canton: 'BE',
  },
  {
    zip: 3785,
    city: 'Gsteig b. Gstaad',
    canton: 'BE',
  },
  {
    zip: 3792,
    city: 'Saanen',
    canton: 'BE',
  },
  {
    zip: 3800,
    city: 'Sundlauenen',
    canton: 'BE',
  },
  {
    zip: 3800,
    city: 'Interlaken',
    canton: 'BE',
  },
  {
    zip: 3800,
    city: 'Unterseen',
    canton: 'BE',
  },
  {
    zip: 3800,
    city: 'Matten b. Interlaken',
    canton: 'BE',
  },
  {
    zip: 3801,
    city: 'Jungfraujoch',
    canton: 'VS',
  },
  {
    zip: 3803,
    city: 'Beatenberg',
    canton: 'BE',
  },
  {
    zip: 3804,
    city: 'Habkern',
    canton: 'BE',
  },
  {
    zip: 3805,
    city: 'Goldswil b. Interlaken',
    canton: 'BE',
  },
  {
    zip: 3806,
    city: 'Bönigen b. Interlaken',
    canton: 'BE',
  },
  {
    zip: 3807,
    city: 'Iseltwald',
    canton: 'BE',
  },
  {
    zip: 3812,
    city: 'Wilderswil',
    canton: 'BE',
  },
  {
    zip: 3813,
    city: 'Saxeten',
    canton: 'BE',
  },
  {
    zip: 3814,
    city: 'Gsteigwiler',
    canton: 'BE',
  },
  {
    zip: 3815,
    city: 'Zweilütschinen',
    canton: 'BE',
  },
  {
    zip: 3815,
    city: 'Gündlischwand',
    canton: 'BE',
  },
  {
    zip: 3816,
    city: 'Lütschental',
    canton: 'BE',
  },
  {
    zip: 3816,
    city: 'Burglauenen',
    canton: 'BE',
  },
  {
    zip: 3818,
    city: 'Grindelwald',
    canton: 'BE',
  },
  {
    zip: 3822,
    city: 'Isenfluh',
    canton: 'BE',
  },
  {
    zip: 3822,
    city: 'Lauterbrunnen',
    canton: 'BE',
  },
  {
    zip: 3823,
    city: 'Kleine Scheidegg',
    canton: 'BE',
  },
  {
    zip: 3823,
    city: 'Eigergletscher',
    canton: 'BE',
  },
  {
    zip: 3823,
    city: 'Wengen',
    canton: 'BE',
  },
  {
    zip: 3824,
    city: 'Stechelberg',
    canton: 'BE',
  },
  {
    zip: 3825,
    city: 'Mürren',
    canton: 'BE',
  },
  {
    zip: 3826,
    city: 'Gimmelwald',
    canton: 'BE',
  },
  {
    zip: 3852,
    city: 'Ringgenberg BE',
    canton: 'BE',
  },
  {
    zip: 3853,
    city: 'Niederried b. Interlaken',
    canton: 'BE',
  },
  {
    zip: 3854,
    city: 'Oberried am Brienzersee',
    canton: 'BE',
  },
  {
    zip: 3855,
    city: 'Schwanden b. Brienz',
    canton: 'BE',
  },
  {
    zip: 3855,
    city: 'Brienz BE',
    canton: 'BE',
  },
  {
    zip: 3855,
    city: 'Axalp',
    canton: 'BE',
  },
  {
    zip: 3856,
    city: 'Brienzwiler',
    canton: 'BE',
  },
  {
    zip: 3857,
    city: 'Unterbach BE',
    canton: 'BE',
  },
  {
    zip: 3858,
    city: 'Hofstetten b. Brienz',
    canton: 'BE',
  },
  {
    zip: 3860,
    city: 'Rosenlaui',
    canton: 'BE',
  },
  {
    zip: 3860,
    city: 'Schattenhalb',
    canton: 'BE',
  },
  {
    zip: 3860,
    city: 'Meiringen',
    canton: 'BE',
  },
  {
    zip: 3860,
    city: 'Brünig',
    canton: 'BE',
  },
  {
    zip: 3862,
    city: 'Innertkirchen',
    canton: 'BE',
  },
  {
    zip: 3863,
    city: 'Gadmen',
    canton: 'BE',
  },
  {
    zip: 3864,
    city: 'Guttannen',
    canton: 'BE',
  },
  {
    zip: 3900,
    city: 'Gamsen',
    canton: 'VS',
  },
  {
    zip: 3900,
    city: 'Brigerbad',
    canton: 'VS',
  },
  {
    zip: 3900,
    city: 'Brig',
    canton: 'VS',
  },
  {
    zip: 3901,
    city: 'Rothwald',
    canton: 'VS',
  },
  {
    zip: 3902,
    city: 'Glis',
    canton: 'VS',
  },
  {
    zip: 3903,
    city: 'Birgisch',
    canton: 'VS',
  },
  {
    zip: 3903,
    city: 'Mund',
    canton: 'VS',
  },
  {
    zip: 3904,
    city: 'Naters',
    canton: 'VS',
  },
  {
    zip: 3905,
    city: 'Saas-Almagell',
    canton: 'VS',
  },
  {
    zip: 3906,
    city: 'Saas-Fee',
    canton: 'VS',
  },
  {
    zip: 3907,
    city: 'Simplon Hospiz',
    canton: 'VS',
  },
  {
    zip: 3907,
    city: 'Gabi (Simplon)',
    canton: 'VS',
  },
  {
    zip: 3907,
    city: 'Gondo',
    canton: 'VS',
  },
  {
    zip: 3907,
    city: 'Simplon Dorf',
    canton: 'VS',
  },
  {
    zip: 3908,
    city: 'Saas-Balen',
    canton: 'VS',
  },
  {
    zip: 3910,
    city: 'Saas-Grund',
    canton: 'VS',
  },
  {
    zip: 3911,
    city: 'Ried-Brig',
    canton: 'VS',
  },
  {
    zip: 3912,
    city: 'Termen',
    canton: 'VS',
  },
  {
    zip: 3913,
    city: 'Rosswald',
    canton: 'VS',
  },
  {
    zip: 3914,
    city: 'Belalp',
    canton: 'VS',
  },
  {
    zip: 3914,
    city: 'Blatten b. Naters',
    canton: 'VS',
  },
  {
    zip: 3916,
    city: 'Ferden',
    canton: 'VS',
  },
  {
    zip: 3917,
    city: 'Goppenstein',
    canton: 'VS',
  },
  {
    zip: 3917,
    city: 'Kippel',
    canton: 'VS',
  },
  {
    zip: 3918,
    city: 'Wiler (Lötschen)',
    canton: 'VS',
  },
  {
    zip: 3919,
    city: 'Blatten (Lötschen)',
    canton: 'VS',
  },
  {
    zip: 3920,
    city: 'Zermatt',
    canton: 'VS',
  },
  {
    zip: 3922,
    city: 'Eisten',
    canton: 'VS',
  },
  {
    zip: 3922,
    city: 'Kalpetran',
    canton: 'VS',
  },
  {
    zip: 3922,
    city: 'Stalden VS',
    canton: 'VS',
  },
  {
    zip: 3923,
    city: 'Törbel',
    canton: 'VS',
  },
  {
    zip: 3924,
    city: 'Gasenried',
    canton: 'VS',
  },
  {
    zip: 3924,
    city: 'St. Niklaus VS',
    canton: 'VS',
  },
  {
    zip: 3925,
    city: 'Grächen',
    canton: 'VS',
  },
  {
    zip: 3926,
    city: 'Embd',
    canton: 'VS',
  },
  {
    zip: 3927,
    city: 'Herbriggen',
    canton: 'VS',
  },
  {
    zip: 3928,
    city: 'Randa',
    canton: 'VS',
  },
  {
    zip: 3929,
    city: 'Täsch',
    canton: 'VS',
  },
  {
    zip: 3930,
    city: 'Eyholz',
    canton: 'VS',
  },
  {
    zip: 3930,
    city: 'Visp',
    canton: 'VS',
  },
  {
    zip: 3931,
    city: 'Lalden',
    canton: 'VS',
  },
  {
    zip: 3932,
    city: 'Visperterminen',
    canton: 'VS',
  },
  {
    zip: 3933,
    city: 'Staldenried',
    canton: 'VS',
  },
  {
    zip: 3934,
    city: 'Zeneggen',
    canton: 'VS',
  },
  {
    zip: 3935,
    city: 'Bürchen',
    canton: 'VS',
  },
  {
    zip: 3937,
    city: 'Baltschieder',
    canton: 'VS',
  },
  {
    zip: 3938,
    city: 'Ausserberg',
    canton: 'VS',
  },
  {
    zip: 3939,
    city: 'Eggerberg',
    canton: 'VS',
  },
  {
    zip: 3940,
    city: 'Steg VS',
    canton: 'VS',
  },
  {
    zip: 3942,
    city: 'Niedergesteln',
    canton: 'VS',
  },
  {
    zip: 3942,
    city: 'St. German',
    canton: 'VS',
  },
  {
    zip: 3942,
    city: 'Raron',
    canton: 'VS',
  },
  {
    zip: 3943,
    city: 'Eischoll',
    canton: 'VS',
  },
  {
    zip: 3944,
    city: 'Unterbäch VS',
    canton: 'VS',
  },
  {
    zip: 3945,
    city: 'Gampel',
    canton: 'VS',
  },
  {
    zip: 3945,
    city: 'Niedergampel',
    canton: 'VS',
  },
  {
    zip: 3946,
    city: 'Gruben',
    canton: 'VS',
  },
  {
    zip: 3946,
    city: 'Turtmann',
    canton: 'VS',
  },
  {
    zip: 3947,
    city: 'Ergisch',
    canton: 'VS',
  },
  {
    zip: 3948,
    city: 'Unterems',
    canton: 'VS',
  },
  {
    zip: 3948,
    city: 'Oberems',
    canton: 'VS',
  },
  {
    zip: 3949,
    city: 'Hohtenn',
    canton: 'VS',
  },
  {
    zip: 3951,
    city: 'Agarn',
    canton: 'VS',
  },
  {
    zip: 3952,
    city: 'Susten',
    canton: 'VS',
  },
  {
    zip: 3953,
    city: 'Inden',
    canton: 'VS',
  },
  {
    zip: 3953,
    city: 'Varen',
    canton: 'VS',
  },
  {
    zip: 3953,
    city: 'Leuk Stadt',
    canton: 'VS',
  },
  {
    zip: 3954,
    city: 'Leukerbad',
    canton: 'VS',
  },
  {
    zip: 3955,
    city: 'Albinen',
    canton: 'VS',
  },
  {
    zip: 3956,
    city: 'Guttet-Feschel',
    canton: 'VS',
  },
  {
    zip: 3957,
    city: 'Bratsch',
    canton: 'VS',
  },
  {
    zip: 3957,
    city: 'Erschmatt',
    canton: 'VS',
  },
  {
    zip: 3960,
    city: 'Corin-de-la-Crête',
    canton: 'VS',
  },
  {
    zip: 3960,
    city: 'Loc',
    canton: 'VS',
  },
  {
    zip: 3960,
    city: 'Muraz (Sierre)',
    canton: 'VS',
  },
  {
    zip: 3960,
    city: 'Niouc',
    canton: 'VS',
  },
  {
    zip: 3960,
    city: 'Sierre',
    canton: 'VS',
  },
  {
    zip: 3961,
    city: 'Chandolin',
    canton: 'VS',
  },
  {
    zip: 3961,
    city: 'Ayer',
    canton: 'VS',
  },
  {
    zip: 3961,
    city: 'St-Jean VS',
    canton: 'VS',
  },
  {
    zip: 3961,
    city: 'Vissoie',
    canton: 'VS',
  },
  {
    zip: 3961,
    city: 'St-Luc',
    canton: 'VS',
  },
  {
    zip: 3961,
    city: 'Zinal',
    canton: 'VS',
  },
  {
    zip: 3961,
    city: 'Grimentz',
    canton: 'VS',
  },
  {
    zip: 3963,
    city: 'Montana',
    canton: 'VS',
  },
  {
    zip: 3963,
    city: 'Crans-Montana',
    canton: 'VS',
  },
  {
    zip: 3963,
    city: 'Aminona',
    canton: 'VS',
  },
  {
    zip: 3965,
    city: 'Chippis',
    canton: 'VS',
  },
  {
    zip: 3966,
    city: 'Réchy',
    canton: 'VS',
  },
  {
    zip: 3966,
    city: 'Chalais',
    canton: 'VS',
  },
  {
    zip: 3967,
    city: 'Vercorin',
    canton: 'VS',
  },
  {
    zip: 3968,
    city: 'Veyras',
    canton: 'VS',
  },
  {
    zip: 3970,
    city: 'Salgesch',
    canton: 'VS',
  },
  {
    zip: 3971,
    city: "Chermignon-d'en-Bas",
    canton: 'VS',
  },
  {
    zip: 3971,
    city: 'Ollon VS',
    canton: 'VS',
  },
  {
    zip: 3971,
    city: 'Chermignon',
    canton: 'VS',
  },
  {
    zip: 3972,
    city: 'Miège',
    canton: 'VS',
  },
  {
    zip: 3973,
    city: 'Venthône',
    canton: 'VS',
  },
  {
    zip: 3974,
    city: 'Mollens VS',
    canton: 'VS',
  },
  {
    zip: 3975,
    city: 'Randogne',
    canton: 'VS',
  },
  {
    zip: 3976,
    city: 'Champzabé',
    canton: 'VS',
  },
  {
    zip: 3976,
    city: 'Noës',
    canton: 'VS',
  },
  {
    zip: 3977,
    city: 'Granges VS',
    canton: 'VS',
  },
  {
    zip: 3978,
    city: 'Flanthey',
    canton: 'VS',
  },
  {
    zip: 3979,
    city: 'Grône',
    canton: 'VS',
  },
  {
    zip: 3982,
    city: 'Bitsch',
    canton: 'VS',
  },
  {
    zip: 3983,
    city: 'Bister',
    canton: 'VS',
  },
  {
    zip: 3983,
    city: 'Filet',
    canton: 'VS',
  },
  {
    zip: 3983,
    city: 'Goppisberg',
    canton: 'VS',
  },
  {
    zip: 3983,
    city: 'Greich',
    canton: 'VS',
  },
  {
    zip: 3983,
    city: 'Mörel',
    canton: 'VS',
  },
  {
    zip: 3984,
    city: 'Fieschertal',
    canton: 'VS',
  },
  {
    zip: 3984,
    city: 'Fiesch',
    canton: 'VS',
  },
  {
    zip: 3985,
    city: 'Geschinen',
    canton: 'VS',
  },
  {
    zip: 3985,
    city: 'Münster VS',
    canton: 'VS',
  },
  {
    zip: 3986,
    city: 'Ried-Mörel',
    canton: 'VS',
  },
  {
    zip: 3987,
    city: 'Riederalp',
    canton: 'VS',
  },
  {
    zip: 3988,
    city: 'Obergesteln',
    canton: 'VS',
  },
  {
    zip: 3988,
    city: 'Ulrichen',
    canton: 'VS',
  },
  {
    zip: 3989,
    city: 'Niederwald',
    canton: 'VS',
  },
  {
    zip: 3989,
    city: 'Blitzingen',
    canton: 'VS',
  },
  {
    zip: 3989,
    city: 'Biel VS',
    canton: 'VS',
  },
  {
    zip: 3989,
    city: 'Ritzingen',
    canton: 'VS',
  },
  {
    zip: 3989,
    city: 'Selkingen',
    canton: 'VS',
  },
  {
    zip: 3991,
    city: 'Betten',
    canton: 'VS',
  },
  {
    zip: 3992,
    city: 'Bettmeralp',
    canton: 'VS',
  },
  {
    zip: 3993,
    city: 'Grengiols',
    canton: 'VS',
  },
  {
    zip: 3994,
    city: 'Lax',
    canton: 'VS',
  },
  {
    zip: 3994,
    city: 'Martisberg',
    canton: 'VS',
  },
  {
    zip: 3995,
    city: 'Ausserbinn',
    canton: 'VS',
  },
  {
    zip: 3995,
    city: 'Mühlebach (Goms)',
    canton: 'VS',
  },
  {
    zip: 3995,
    city: 'Steinhaus',
    canton: 'VS',
  },
  {
    zip: 3995,
    city: 'Ernen',
    canton: 'VS',
  },
  {
    zip: 3996,
    city: 'Binn',
    canton: 'VS',
  },
  {
    zip: 3997,
    city: 'Bellwald',
    canton: 'VS',
  },
  {
    zip: 3998,
    city: 'Gluringen',
    canton: 'VS',
  },
  {
    zip: 3998,
    city: 'Reckingen VS',
    canton: 'VS',
  },
  {
    zip: 3999,
    city: 'Oberwald',
    canton: 'VS',
  },
  {
    zip: 4000,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 4001,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 4031,
    city: 'Basel Universitätsspital',
    canton: 'BS',
  },
  {
    zip: 4051,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 4052,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 4053,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 4054,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 4055,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 4056,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 4057,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 4058,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 4059,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 4101,
    city: 'Bruderholz',
    canton: 'BL',
  },
  {
    zip: 4102,
    city: 'Binningen',
    canton: 'BL',
  },
  {
    zip: 4103,
    city: 'Bottmingen',
    canton: 'BL',
  },
  {
    zip: 4104,
    city: 'Oberwil BL',
    canton: 'BL',
  },
  {
    zip: 4105,
    city: 'Biel-Benken BL',
    canton: 'BL',
  },
  {
    zip: 4106,
    city: 'Therwil',
    canton: 'BL',
  },
  {
    zip: 4107,
    city: 'Ettingen',
    canton: 'BL',
  },
  {
    zip: 4108,
    city: 'Witterswil',
    canton: 'SO',
  },
  {
    zip: 4112,
    city: 'Bättwil',
    canton: 'SO',
  },
  {
    zip: 4112,
    city: 'Flüh',
    canton: 'SO',
  },
  {
    zip: 4114,
    city: 'Hofstetten SO',
    canton: 'SO',
  },
  {
    zip: 4115,
    city: 'Mariastein',
    canton: 'SO',
  },
  {
    zip: 4116,
    city: 'Metzerlen',
    canton: 'SO',
  },
  {
    zip: 4117,
    city: 'Burg im Leimental',
    canton: 'BL',
  },
  {
    zip: 4118,
    city: 'Rodersdorf',
    canton: 'SO',
  },
  {
    zip: 4123,
    city: 'Allschwil',
    canton: 'BL',
  },
  {
    zip: 4124,
    city: 'Schönenbuch',
    canton: 'BL',
  },
  {
    zip: 4125,
    city: 'Riehen',
    canton: 'BS',
  },
  {
    zip: 4126,
    city: 'Bettingen',
    canton: 'BS',
  },
  {
    zip: 4127,
    city: 'Birsfelden',
    canton: 'BL',
  },
  {
    zip: 4132,
    city: 'Muttenz',
    canton: 'BL',
  },
  {
    zip: 4133,
    city: 'Pratteln',
    canton: 'BL',
  },
  {
    zip: 4142,
    city: 'Münchenstein',
    canton: 'BL',
  },
  {
    zip: 4143,
    city: 'Dornach',
    canton: 'SO',
  },
  {
    zip: 4144,
    city: 'Arlesheim',
    canton: 'BL',
  },
  {
    zip: 4145,
    city: 'Gempen',
    canton: 'SO',
  },
  {
    zip: 4146,
    city: 'Hochwald',
    canton: 'SO',
  },
  {
    zip: 4147,
    city: 'Aesch BL',
    canton: 'BL',
  },
  {
    zip: 4148,
    city: 'Pfeffingen',
    canton: 'BL',
  },
  {
    zip: 4153,
    city: 'Reinach BL',
    canton: 'BL',
  },
  {
    zip: 4202,
    city: 'Duggingen',
    canton: 'BL',
  },
  {
    zip: 4203,
    city: 'Grellingen',
    canton: 'BL',
  },
  {
    zip: 4204,
    city: 'Himmelried',
    canton: 'SO',
  },
  {
    zip: 4206,
    city: 'Seewen SO',
    canton: 'SO',
  },
  {
    zip: 4207,
    city: 'Bretzwil',
    canton: 'BL',
  },
  {
    zip: 4208,
    city: 'Nunningen',
    canton: 'SO',
  },
  {
    zip: 4222,
    city: 'Zwingen',
    canton: 'BL',
  },
  {
    zip: 4223,
    city: 'Blauen',
    canton: 'BL',
  },
  {
    zip: 4224,
    city: 'Nenzlingen',
    canton: 'BL',
  },
  {
    zip: 4225,
    city: 'Brislach',
    canton: 'BL',
  },
  {
    zip: 4226,
    city: 'Breitenbach',
    canton: 'SO',
  },
  {
    zip: 4227,
    city: 'Büsserach',
    canton: 'SO',
  },
  {
    zip: 4228,
    city: 'Erschwil',
    canton: 'SO',
  },
  {
    zip: 4229,
    city: 'Beinwil SO',
    canton: 'SO',
  },
  {
    zip: 4232,
    city: 'Fehren',
    canton: 'SO',
  },
  {
    zip: 4233,
    city: 'Meltingen',
    canton: 'SO',
  },
  {
    zip: 4234,
    city: 'Zullwil',
    canton: 'SO',
  },
  {
    zip: 4242,
    city: 'Laufen',
    canton: 'BL',
  },
  {
    zip: 4243,
    city: 'Dittingen',
    canton: 'BL',
  },
  {
    zip: 4244,
    city: 'Röschenz',
    canton: 'BL',
  },
  {
    zip: 4245,
    city: 'Kleinlützel',
    canton: 'SO',
  },
  {
    zip: 4246,
    city: 'Wahlen b. Laufen',
    canton: 'BL',
  },
  {
    zip: 4247,
    city: 'Grindel',
    canton: 'SO',
  },
  {
    zip: 4252,
    city: 'Bärschwil',
    canton: 'SO',
  },
  {
    zip: 4253,
    city: 'Liesberg',
    canton: 'BL',
  },
  {
    zip: 4254,
    city: 'Liesberg Dorf',
    canton: 'BL',
  },
  {
    zip: 4302,
    city: 'Augst BL',
    canton: 'BL',
  },
  {
    zip: 4303,
    city: 'Kaiseraugst',
    canton: 'AG',
  },
  {
    zip: 4304,
    city: 'Giebenach',
    canton: 'BL',
  },
  {
    zip: 4305,
    city: 'Olsberg',
    canton: 'AG',
  },
  {
    zip: 4310,
    city: 'Rheinfelden',
    canton: 'AG',
  },
  {
    zip: 4312,
    city: 'Magden',
    canton: 'AG',
  },
  {
    zip: 4313,
    city: 'Möhlin',
    canton: 'AG',
  },
  {
    zip: 4314,
    city: 'Zeiningen',
    canton: 'AG',
  },
  {
    zip: 4315,
    city: 'Zuzgen',
    canton: 'AG',
  },
  {
    zip: 4316,
    city: 'Hellikon',
    canton: 'AG',
  },
  {
    zip: 4317,
    city: 'Wegenstetten',
    canton: 'AG',
  },
  {
    zip: 4322,
    city: 'Mumpf',
    canton: 'AG',
  },
  {
    zip: 4323,
    city: 'Wallbach',
    canton: 'AG',
  },
  {
    zip: 4324,
    city: 'Obermumpf',
    canton: 'AG',
  },
  {
    zip: 4325,
    city: 'Schupfart',
    canton: 'AG',
  },
  {
    zip: 4332,
    city: 'Stein AG',
    canton: 'AG',
  },
  {
    zip: 4333,
    city: 'Münchwilen AG',
    canton: 'AG',
  },
  {
    zip: 4334,
    city: 'Sisseln AG',
    canton: 'AG',
  },
  {
    zip: 4402,
    city: 'Frenkendorf',
    canton: 'BL',
  },
  {
    zip: 4410,
    city: 'Liestal',
    canton: 'BL',
  },
  {
    zip: 4411,
    city: 'Seltisberg',
    canton: 'BL',
  },
  {
    zip: 4412,
    city: 'Nuglar',
    canton: 'SO',
  },
  {
    zip: 4413,
    city: 'Büren SO',
    canton: 'SO',
  },
  {
    zip: 4414,
    city: 'Füllinsdorf',
    canton: 'BL',
  },
  {
    zip: 4415,
    city: 'Lausen',
    canton: 'BL',
  },
  {
    zip: 4416,
    city: 'Bubendorf',
    canton: 'BL',
  },
  {
    zip: 4417,
    city: 'Ziefen',
    canton: 'BL',
  },
  {
    zip: 4418,
    city: 'Reigoldswil',
    canton: 'BL',
  },
  {
    zip: 4419,
    city: 'Lupsingen',
    canton: 'BL',
  },
  {
    zip: 4421,
    city: 'St. Pantaleon',
    canton: 'SO',
  },
  {
    zip: 4422,
    city: 'Arisdorf',
    canton: 'BL',
  },
  {
    zip: 4423,
    city: 'Hersberg',
    canton: 'BL',
  },
  {
    zip: 4424,
    city: 'Arboldswil',
    canton: 'BL',
  },
  {
    zip: 4425,
    city: 'Titterten',
    canton: 'BL',
  },
  {
    zip: 4426,
    city: 'Lauwil',
    canton: 'BL',
  },
  {
    zip: 4431,
    city: 'Bennwil',
    canton: 'BL',
  },
  {
    zip: 4432,
    city: 'Lampenberg',
    canton: 'BL',
  },
  {
    zip: 4433,
    city: 'Ramlinsburg',
    canton: 'BL',
  },
  {
    zip: 4434,
    city: 'Hölstein',
    canton: 'BL',
  },
  {
    zip: 4435,
    city: 'Niederdorf',
    canton: 'BL',
  },
  {
    zip: 4436,
    city: 'Liedertswil',
    canton: 'BL',
  },
  {
    zip: 4436,
    city: 'Oberdorf BL',
    canton: 'BL',
  },
  {
    zip: 4437,
    city: 'Waldenburg',
    canton: 'BL',
  },
  {
    zip: 4438,
    city: 'Langenbruck',
    canton: 'BL',
  },
  {
    zip: 4441,
    city: 'Thürnen',
    canton: 'BL',
  },
  {
    zip: 4442,
    city: 'Diepflingen',
    canton: 'BL',
  },
  {
    zip: 4443,
    city: 'Wittinsburg',
    canton: 'BL',
  },
  {
    zip: 4444,
    city: 'Rümlingen',
    canton: 'BL',
  },
  {
    zip: 4445,
    city: 'Häfelfingen',
    canton: 'BL',
  },
  {
    zip: 4446,
    city: 'Buckten',
    canton: 'BL',
  },
  {
    zip: 4447,
    city: 'Känerkinden',
    canton: 'BL',
  },
  {
    zip: 4448,
    city: 'Läufelfingen',
    canton: 'BL',
  },
  {
    zip: 4450,
    city: 'Sissach',
    canton: 'BL',
  },
  {
    zip: 4451,
    city: 'Wintersingen',
    canton: 'BL',
  },
  {
    zip: 4452,
    city: 'Itingen',
    canton: 'BL',
  },
  {
    zip: 4453,
    city: 'Nusshof',
    canton: 'BL',
  },
  {
    zip: 4455,
    city: 'Zunzgen',
    canton: 'BL',
  },
  {
    zip: 4456,
    city: 'Tenniken',
    canton: 'BL',
  },
  {
    zip: 4457,
    city: 'Diegten',
    canton: 'BL',
  },
  {
    zip: 4458,
    city: 'Eptingen',
    canton: 'BL',
  },
  {
    zip: 4460,
    city: 'Gelterkinden',
    canton: 'BL',
  },
  {
    zip: 4461,
    city: 'Böckten',
    canton: 'BL',
  },
  {
    zip: 4462,
    city: 'Rickenbach BL',
    canton: 'BL',
  },
  {
    zip: 4463,
    city: 'Buus',
    canton: 'BL',
  },
  {
    zip: 4464,
    city: 'Maisprach',
    canton: 'BL',
  },
  {
    zip: 4465,
    city: 'Hemmiken',
    canton: 'BL',
  },
  {
    zip: 4466,
    city: 'Ormalingen',
    canton: 'BL',
  },
  {
    zip: 4467,
    city: 'Rothenfluh',
    canton: 'BL',
  },
  {
    zip: 4468,
    city: 'Kienberg',
    canton: 'SO',
  },
  {
    zip: 4469,
    city: 'Anwil',
    canton: 'BL',
  },
  {
    zip: 4492,
    city: 'Tecknau',
    canton: 'BL',
  },
  {
    zip: 4493,
    city: 'Wenslingen',
    canton: 'BL',
  },
  {
    zip: 4494,
    city: 'Oltingen',
    canton: 'BL',
  },
  {
    zip: 4495,
    city: 'Zeglingen',
    canton: 'BL',
  },
  {
    zip: 4496,
    city: 'Kilchberg BL',
    canton: 'BL',
  },
  {
    zip: 4497,
    city: 'Rünenberg',
    canton: 'BL',
  },
  {
    zip: 4500,
    city: 'Solothurn',
    canton: 'SO',
  },
  {
    zip: 4512,
    city: 'Bellach',
    canton: 'SO',
  },
  {
    zip: 4513,
    city: 'Langendorf',
    canton: 'SO',
  },
  {
    zip: 4514,
    city: 'Lommiswil',
    canton: 'SO',
  },
  {
    zip: 4515,
    city: 'Weissenstein b. Solothurn',
    canton: 'SO',
  },
  {
    zip: 4515,
    city: 'Oberdorf SO',
    canton: 'SO',
  },
  {
    zip: 4522,
    city: 'Rüttenen',
    canton: 'SO',
  },
  {
    zip: 4523,
    city: 'Niederwil SO',
    canton: 'SO',
  },
  {
    zip: 4524,
    city: 'Balmberg',
    canton: 'SO',
  },
  {
    zip: 4524,
    city: 'Oberbalmberg',
    canton: 'SO',
  },
  {
    zip: 4524,
    city: 'Günsberg',
    canton: 'SO',
  },
  {
    zip: 4525,
    city: 'Balm b. Günsberg',
    canton: 'SO',
  },
  {
    zip: 4528,
    city: 'Zuchwil',
    canton: 'SO',
  },
  {
    zip: 4532,
    city: 'Feldbrunnen',
    canton: 'SO',
  },
  {
    zip: 4533,
    city: 'Riedholz',
    canton: 'SO',
  },
  {
    zip: 4534,
    city: 'Flumenthal',
    canton: 'SO',
  },
  {
    zip: 4535,
    city: 'Hubersdorf',
    canton: 'SO',
  },
  {
    zip: 4535,
    city: 'Kammersrohr',
    canton: 'SO',
  },
  {
    zip: 4536,
    city: 'Attiswil',
    canton: 'BE',
  },
  {
    zip: 4537,
    city: 'Wiedlisbach',
    canton: 'BE',
  },
  {
    zip: 4538,
    city: 'Oberbipp',
    canton: 'BE',
  },
  {
    zip: 4539,
    city: 'Rumisberg',
    canton: 'BE',
  },
  {
    zip: 4539,
    city: 'Farnern',
    canton: 'BE',
  },
  {
    zip: 4542,
    city: 'Luterbach',
    canton: 'SO',
  },
  {
    zip: 4543,
    city: 'Deitingen',
    canton: 'SO',
  },
  {
    zip: 4552,
    city: 'Derendingen',
    canton: 'SO',
  },
  {
    zip: 4553,
    city: 'Subingen',
    canton: 'SO',
  },
  {
    zip: 4554,
    city: 'Hüniken',
    canton: 'SO',
  },
  {
    zip: 4554,
    city: 'Etziken',
    canton: 'SO',
  },
  {
    zip: 4556,
    city: 'Bolken',
    canton: 'SO',
  },
  {
    zip: 4556,
    city: 'Burgäschi',
    canton: 'SO',
  },
  {
    zip: 4556,
    city: 'Steinhof SO',
    canton: 'SO',
  },
  {
    zip: 4556,
    city: 'Aeschi SO',
    canton: 'SO',
  },
  {
    zip: 4557,
    city: 'Horriwil',
    canton: 'SO',
  },
  {
    zip: 4558,
    city: 'Hersiwil',
    canton: 'SO',
  },
  {
    zip: 4558,
    city: 'Heinrichswil',
    canton: 'SO',
  },
  {
    zip: 4558,
    city: 'Winistorf',
    canton: 'SO',
  },
  {
    zip: 4562,
    city: 'Biberist',
    canton: 'SO',
  },
  {
    zip: 4563,
    city: 'Gerlafingen',
    canton: 'SO',
  },
  {
    zip: 4564,
    city: 'Zielebach',
    canton: 'BE',
  },
  {
    zip: 4564,
    city: 'Obergerlafingen',
    canton: 'SO',
  },
  {
    zip: 4565,
    city: 'Recherswil',
    canton: 'SO',
  },
  {
    zip: 4566,
    city: 'Halten',
    canton: 'SO',
  },
  {
    zip: 4566,
    city: 'Oekingen',
    canton: 'SO',
  },
  {
    zip: 4566,
    city: 'Kriegstetten',
    canton: 'SO',
  },
  {
    zip: 4571,
    city: 'Ichertswil',
    canton: 'SO',
  },
  {
    zip: 4571,
    city: 'Lüterkofen',
    canton: 'SO',
  },
  {
    zip: 4573,
    city: 'Lohn-Ammannsegg',
    canton: 'SO',
  },
  {
    zip: 4574,
    city: 'Lüsslingen',
    canton: 'SO',
  },
  {
    zip: 4574,
    city: 'Nennigkofen',
    canton: 'SO',
  },
  {
    zip: 4576,
    city: 'Tscheppach',
    canton: 'SO',
  },
  {
    zip: 4577,
    city: 'Hessigkofen',
    canton: 'SO',
  },
  {
    zip: 4578,
    city: 'Bibern SO',
    canton: 'SO',
  },
  {
    zip: 4579,
    city: 'Gossliwil',
    canton: 'SO',
  },
  {
    zip: 4581,
    city: 'Küttigkofen',
    canton: 'SO',
  },
  {
    zip: 4582,
    city: 'Brügglen',
    canton: 'SO',
  },
  {
    zip: 4583,
    city: 'Aetigkofen',
    canton: 'SO',
  },
  {
    zip: 4583,
    city: 'Mühledorf SO',
    canton: 'SO',
  },
  {
    zip: 4584,
    city: 'Gächliwil',
    canton: 'SO',
  },
  {
    zip: 4584,
    city: 'Lüterswil',
    canton: 'SO',
  },
  {
    zip: 4585,
    city: 'Biezwil',
    canton: 'SO',
  },
  {
    zip: 4586,
    city: 'Kyburg-Buchegg',
    canton: 'SO',
  },
  {
    zip: 4587,
    city: 'Aetingen',
    canton: 'SO',
  },
  {
    zip: 4588,
    city: 'Unterramsern',
    canton: 'SO',
  },
  {
    zip: 4588,
    city: 'Oberramsern',
    canton: 'SO',
  },
  {
    zip: 4588,
    city: 'Brittern',
    canton: 'SO',
  },
  {
    zip: 4600,
    city: 'Olten',
    canton: 'SO',
  },
  {
    zip: 4612,
    city: 'Wangen b. Olten',
    canton: 'SO',
  },
  {
    zip: 4613,
    city: 'Rickenbach SO',
    canton: 'SO',
  },
  {
    zip: 4614,
    city: 'Hägendorf',
    canton: 'SO',
  },
  {
    zip: 4615,
    city: 'Allerheiligenberg',
    canton: 'SO',
  },
  {
    zip: 4616,
    city: 'Kappel SO',
    canton: 'SO',
  },
  {
    zip: 4617,
    city: 'Gunzgen',
    canton: 'SO',
  },
  {
    zip: 4618,
    city: 'Boningen',
    canton: 'SO',
  },
  {
    zip: 4622,
    city: 'Egerkingen',
    canton: 'SO',
  },
  {
    zip: 4623,
    city: 'Neuendorf',
    canton: 'SO',
  },
  {
    zip: 4624,
    city: 'Härkingen',
    canton: 'SO',
  },
  {
    zip: 4625,
    city: 'Oberbuchsiten',
    canton: 'SO',
  },
  {
    zip: 4626,
    city: 'Niederbuchsiten',
    canton: 'SO',
  },
  {
    zip: 4628,
    city: 'Wolfwil',
    canton: 'SO',
  },
  {
    zip: 4629,
    city: 'Fulenbach',
    canton: 'SO',
  },
  {
    zip: 4632,
    city: 'Trimbach',
    canton: 'SO',
  },
  {
    zip: 4633,
    city: 'Hauenstein',
    canton: 'SO',
  },
  {
    zip: 4634,
    city: 'Wisen SO',
    canton: 'SO',
  },
  {
    zip: 4652,
    city: 'Winznau',
    canton: 'SO',
  },
  {
    zip: 4653,
    city: 'Obergösgen',
    canton: 'SO',
  },
  {
    zip: 4654,
    city: 'Lostorf',
    canton: 'SO',
  },
  {
    zip: 4655,
    city: 'Rohr b. Olten',
    canton: 'SO',
  },
  {
    zip: 4655,
    city: 'Stüsslingen',
    canton: 'SO',
  },
  {
    zip: 4656,
    city: 'Starrkirch-Wil',
    canton: 'SO',
  },
  {
    zip: 4657,
    city: 'Dulliken',
    canton: 'SO',
  },
  {
    zip: 4658,
    city: 'Däniken SO',
    canton: 'SO',
  },
  {
    zip: 4663,
    city: 'Aarburg',
    canton: 'AG',
  },
  {
    zip: 4665,
    city: 'Oftringen',
    canton: 'AG',
  },
  {
    zip: 4702,
    city: 'Oensingen',
    canton: 'SO',
  },
  {
    zip: 4703,
    city: 'Kestenholz',
    canton: 'SO',
  },
  {
    zip: 4704,
    city: 'Wolfisberg',
    canton: 'BE',
  },
  {
    zip: 4704,
    city: 'Niederbipp',
    canton: 'BE',
  },
  {
    zip: 4710,
    city: 'Balsthal',
    canton: 'SO',
  },
  {
    zip: 4712,
    city: 'Laupersdorf',
    canton: 'SO',
  },
  {
    zip: 4713,
    city: 'Matzendorf',
    canton: 'SO',
  },
  {
    zip: 4714,
    city: 'Aedermannsdorf',
    canton: 'SO',
  },
  {
    zip: 4715,
    city: 'Herbetswil',
    canton: 'SO',
  },
  {
    zip: 4716,
    city: 'Gänsbrunnen',
    canton: 'SO',
  },
  {
    zip: 4716,
    city: 'Welschenrohr',
    canton: 'SO',
  },
  {
    zip: 4717,
    city: 'Mümliswil',
    canton: 'SO',
  },
  {
    zip: 4718,
    city: 'Holderbank SO',
    canton: 'SO',
  },
  {
    zip: 4719,
    city: 'Ramiswil',
    canton: 'SO',
  },
  {
    zip: 4800,
    city: 'Zofingen',
    canton: 'AG',
  },
  {
    zip: 4802,
    city: 'Strengelbach',
    canton: 'AG',
  },
  {
    zip: 4803,
    city: 'Vordemwald',
    canton: 'AG',
  },
  {
    zip: 4805,
    city: 'Brittnau',
    canton: 'AG',
  },
  {
    zip: 4806,
    city: 'Wikon',
    canton: 'LU',
  },
  {
    zip: 4812,
    city: 'Mühlethal',
    canton: 'AG',
  },
  {
    zip: 4813,
    city: 'Uerkheim',
    canton: 'AG',
  },
  {
    zip: 4814,
    city: 'Bottenwil',
    canton: 'AG',
  },
  {
    zip: 4852,
    city: 'Rothrist',
    canton: 'AG',
  },
  {
    zip: 4853,
    city: 'Riken AG',
    canton: 'AG',
  },
  {
    zip: 4853,
    city: 'Murgenthal',
    canton: 'AG',
  },
  {
    zip: 4856,
    city: 'Glashütten',
    canton: 'AG',
  },
  {
    zip: 4900,
    city: 'Langenthal',
    canton: 'BE',
  },
  {
    zip: 4911,
    city: 'Schwarzhäusern',
    canton: 'BE',
  },
  {
    zip: 4912,
    city: 'Aarwangen',
    canton: 'BE',
  },
  {
    zip: 4913,
    city: 'Bannwil',
    canton: 'BE',
  },
  {
    zip: 4914,
    city: 'Roggwil BE',
    canton: 'BE',
  },
  {
    zip: 4915,
    city: 'St. Urban',
    canton: 'LU',
  },
  {
    zip: 4916,
    city: 'Untersteckholz',
    canton: 'BE',
  },
  {
    zip: 4917,
    city: 'Busswil b. Melchnau',
    canton: 'BE',
  },
  {
    zip: 4917,
    city: 'Melchnau',
    canton: 'BE',
  },
  {
    zip: 4919,
    city: 'Reisiswil',
    canton: 'BE',
  },
  {
    zip: 4922,
    city: 'Thunstetten',
    canton: 'BE',
  },
  {
    zip: 4922,
    city: 'Bützberg',
    canton: 'BE',
  },
  {
    zip: 4923,
    city: 'Wynau',
    canton: 'BE',
  },
  {
    zip: 4924,
    city: 'Obersteckholz',
    canton: 'BE',
  },
  {
    zip: 4932,
    city: 'Gutenburg',
    canton: 'BE',
  },
  {
    zip: 4932,
    city: 'Lotzwil',
    canton: 'BE',
  },
  {
    zip: 4933,
    city: 'Rütschelen',
    canton: 'BE',
  },
  {
    zip: 4934,
    city: 'Madiswil',
    canton: 'BE',
  },
  {
    zip: 4935,
    city: 'Leimiswil',
    canton: 'BE',
  },
  {
    zip: 4936,
    city: 'Kleindietwil',
    canton: 'BE',
  },
  {
    zip: 4937,
    city: 'Ursenbach',
    canton: 'BE',
  },
  {
    zip: 4938,
    city: 'Rohrbachgraben',
    canton: 'BE',
  },
  {
    zip: 4938,
    city: 'Rohrbach',
    canton: 'BE',
  },
  {
    zip: 4942,
    city: 'Walterswil BE',
    canton: 'BE',
  },
  {
    zip: 4943,
    city: 'Oeschenbach',
    canton: 'BE',
  },
  {
    zip: 4944,
    city: 'Auswil',
    canton: 'BE',
  },
  {
    zip: 4950,
    city: 'Huttwil',
    canton: 'BE',
  },
  {
    zip: 4952,
    city: 'Eriswil',
    canton: 'BE',
  },
  {
    zip: 4953,
    city: 'Schwarzenbach (Huttwil)',
    canton: 'BE',
  },
  {
    zip: 4954,
    city: 'Wyssachen',
    canton: 'BE',
  },
  {
    zip: 4955,
    city: 'Gondiswil',
    canton: 'BE',
  },
  {
    zip: 5000,
    city: 'Aarau',
    canton: 'AG',
  },
  {
    zip: 5004,
    city: 'Aarau',
    canton: 'AG',
  },
  {
    zip: 5012,
    city: 'Eppenberg',
    canton: 'SO',
  },
  {
    zip: 5012,
    city: 'Wöschnau',
    canton: 'SO',
  },
  {
    zip: 5012,
    city: 'Schönenwerd',
    canton: 'SO',
  },
  {
    zip: 5013,
    city: 'Niedergösgen',
    canton: 'SO',
  },
  {
    zip: 5014,
    city: 'Gretzenbach',
    canton: 'SO',
  },
  {
    zip: 5015,
    city: 'Erlinsbach SO',
    canton: 'SO',
  },
  {
    zip: 5017,
    city: 'Barmelweid',
    canton: 'AG',
  },
  {
    zip: 5018,
    city: 'Erlinsbach',
    canton: 'AG',
  },
  {
    zip: 5022,
    city: 'Rombach',
    canton: 'AG',
  },
  {
    zip: 5023,
    city: 'Biberstein',
    canton: 'AG',
  },
  {
    zip: 5024,
    city: 'Küttigen',
    canton: 'AG',
  },
  {
    zip: 5025,
    city: 'Asp',
    canton: 'AG',
  },
  {
    zip: 5026,
    city: 'Densbüren',
    canton: 'AG',
  },
  {
    zip: 5027,
    city: 'Herznach',
    canton: 'AG',
  },
  {
    zip: 5028,
    city: 'Ueken',
    canton: 'AG',
  },
  {
    zip: 5032,
    city: 'Aarau Rohr',
    canton: 'AG',
  },
  {
    zip: 5033,
    city: 'Buchs AG',
    canton: 'AG',
  },
  {
    zip: 5034,
    city: 'Suhr',
    canton: 'AG',
  },
  {
    zip: 5035,
    city: 'Unterentfelden',
    canton: 'AG',
  },
  {
    zip: 5036,
    city: 'Oberentfelden',
    canton: 'AG',
  },
  {
    zip: 5037,
    city: 'Muhen',
    canton: 'AG',
  },
  {
    zip: 5040,
    city: 'Schöftland',
    canton: 'AG',
  },
  {
    zip: 5042,
    city: 'Hirschthal',
    canton: 'AG',
  },
  {
    zip: 5043,
    city: 'Holziken',
    canton: 'AG',
  },
  {
    zip: 5044,
    city: 'Schlossrued',
    canton: 'AG',
  },
  {
    zip: 5046,
    city: 'Schmiedrued',
    canton: 'AG',
  },
  {
    zip: 5046,
    city: 'Walde AG',
    canton: 'AG',
  },
  {
    zip: 5046,
    city: 'Schmiedrued-Walde',
    canton: 'AG',
  },
  {
    zip: 5053,
    city: 'Wittwil',
    canton: 'AG',
  },
  {
    zip: 5053,
    city: 'Staffelbach',
    canton: 'AG',
  },
  {
    zip: 5054,
    city: 'Kirchleerau',
    canton: 'AG',
  },
  {
    zip: 5054,
    city: 'Moosleerau',
    canton: 'AG',
  },
  {
    zip: 5056,
    city: 'Attelwil',
    canton: 'AG',
  },
  {
    zip: 5057,
    city: 'Reitnau',
    canton: 'AG',
  },
  {
    zip: 5058,
    city: 'Wiliberg',
    canton: 'AG',
  },
  {
    zip: 5062,
    city: 'Oberhof',
    canton: 'AG',
  },
  {
    zip: 5063,
    city: 'Wölflinswil',
    canton: 'AG',
  },
  {
    zip: 5064,
    city: 'Wittnau',
    canton: 'AG',
  },
  {
    zip: 5070,
    city: 'Frick',
    canton: 'AG',
  },
  {
    zip: 5072,
    city: 'Oeschgen',
    canton: 'AG',
  },
  {
    zip: 5073,
    city: 'Gipf-Oberfrick',
    canton: 'AG',
  },
  {
    zip: 5074,
    city: 'Eiken',
    canton: 'AG',
  },
  {
    zip: 5075,
    city: 'Hornussen',
    canton: 'AG',
  },
  {
    zip: 5076,
    city: 'Bözen',
    canton: 'AG',
  },
  {
    zip: 5077,
    city: 'Elfingen',
    canton: 'AG',
  },
  {
    zip: 5078,
    city: 'Effingen',
    canton: 'AG',
  },
  {
    zip: 5079,
    city: 'Zeihen',
    canton: 'AG',
  },
  {
    zip: 5080,
    city: 'Laufenburg',
    canton: 'AG',
  },
  {
    zip: 5082,
    city: 'Kaisten',
    canton: 'AG',
  },
  {
    zip: 5083,
    city: 'Ittenthal',
    canton: 'AG',
  },
  {
    zip: 5084,
    city: 'Rheinsulz',
    canton: 'AG',
  },
  {
    zip: 5085,
    city: 'Sulz AG',
    canton: 'AG',
  },
  {
    zip: 5102,
    city: 'Rupperswil',
    canton: 'AG',
  },
  {
    zip: 5103,
    city: 'Wildegg',
    canton: 'AG',
  },
  {
    zip: 5103,
    city: 'Möriken AG',
    canton: 'AG',
  },
  {
    zip: 5105,
    city: 'Auenstein',
    canton: 'AG',
  },
  {
    zip: 5106,
    city: 'Veltheim AG',
    canton: 'AG',
  },
  {
    zip: 5107,
    city: 'Schinznach Dorf',
    canton: 'AG',
  },
  {
    zip: 5108,
    city: 'Oberflachs',
    canton: 'AG',
  },
  {
    zip: 5112,
    city: 'Thalheim AG',
    canton: 'AG',
  },
  {
    zip: 5113,
    city: 'Holderbank AG',
    canton: 'AG',
  },
  {
    zip: 5116,
    city: 'Schinznach Bad',
    canton: 'AG',
  },
  {
    zip: 5200,
    city: 'Brugg AG',
    canton: 'AG',
  },
  {
    zip: 5210,
    city: 'Windisch',
    canton: 'AG',
  },
  {
    zip: 5212,
    city: 'Hausen AG',
    canton: 'AG',
  },
  {
    zip: 5213,
    city: 'Villnachern',
    canton: 'AG',
  },
  {
    zip: 5222,
    city: 'Umiken',
    canton: 'AG',
  },
  {
    zip: 5223,
    city: 'Riniken',
    canton: 'AG',
  },
  {
    zip: 5224,
    city: 'Gallenkirch',
    canton: 'AG',
  },
  {
    zip: 5224,
    city: 'Linn',
    canton: 'AG',
  },
  {
    zip: 5224,
    city: 'Unterbözberg',
    canton: 'AG',
  },
  {
    zip: 5225,
    city: 'Oberbözberg',
    canton: 'AG',
  },
  {
    zip: 5233,
    city: 'Stilli',
    canton: 'AG',
  },
  {
    zip: 5234,
    city: 'Villigen',
    canton: 'AG',
  },
  {
    zip: 5235,
    city: 'Rüfenach AG',
    canton: 'AG',
  },
  {
    zip: 5236,
    city: 'Remigen',
    canton: 'AG',
  },
  {
    zip: 5237,
    city: 'Mönthal',
    canton: 'AG',
  },
  {
    zip: 5242,
    city: 'Birr',
    canton: 'AG',
  },
  {
    zip: 5242,
    city: 'Lupfig',
    canton: 'AG',
  },
  {
    zip: 5243,
    city: 'Mülligen',
    canton: 'AG',
  },
  {
    zip: 5244,
    city: 'Birrhard',
    canton: 'AG',
  },
  {
    zip: 5245,
    city: 'Habsburg',
    canton: 'AG',
  },
  {
    zip: 5246,
    city: 'Scherz',
    canton: 'AG',
  },
  {
    zip: 5272,
    city: 'Gansingen',
    canton: 'AG',
  },
  {
    zip: 5273,
    city: 'Oberhofen AG',
    canton: 'AG',
  },
  {
    zip: 5274,
    city: 'Mettau',
    canton: 'AG',
  },
  {
    zip: 5275,
    city: 'Etzgen',
    canton: 'AG',
  },
  {
    zip: 5276,
    city: 'Wil AG',
    canton: 'AG',
  },
  {
    zip: 5277,
    city: 'Hottwil',
    canton: 'AG',
  },
  {
    zip: 5300,
    city: 'Turgi',
    canton: 'AG',
  },
  {
    zip: 5301,
    city: 'Siggenthal Station',
    canton: 'AG',
  },
  {
    zip: 5303,
    city: 'Würenlingen',
    canton: 'AG',
  },
  {
    zip: 5304,
    city: 'Endingen',
    canton: 'AG',
  },
  {
    zip: 5305,
    city: 'Unterendingen',
    canton: 'AG',
  },
  {
    zip: 5306,
    city: 'Tegerfelden',
    canton: 'AG',
  },
  {
    zip: 5312,
    city: 'Döttingen',
    canton: 'AG',
  },
  {
    zip: 5313,
    city: 'Klingnau',
    canton: 'AG',
  },
  {
    zip: 5314,
    city: 'Kleindöttingen',
    canton: 'AG',
  },
  {
    zip: 5315,
    city: 'Böttstein',
    canton: 'AG',
  },
  {
    zip: 5316,
    city: 'Felsenau AG',
    canton: 'AG',
  },
  {
    zip: 5316,
    city: 'Leuggern',
    canton: 'AG',
  },
  {
    zip: 5317,
    city: 'Hettenschwil',
    canton: 'AG',
  },
  {
    zip: 5318,
    city: 'Mandach',
    canton: 'AG',
  },
  {
    zip: 5322,
    city: 'Koblenz',
    canton: 'AG',
  },
  {
    zip: 5323,
    city: 'Rietheim',
    canton: 'AG',
  },
  {
    zip: 5324,
    city: 'Full-Reuenthal',
    canton: 'AG',
  },
  {
    zip: 5325,
    city: 'Leibstadt',
    canton: 'AG',
  },
  {
    zip: 5326,
    city: 'Schwaderloch',
    canton: 'AG',
  },
  {
    zip: 5330,
    city: 'Bad Zurzach',
    canton: 'AG',
  },
  {
    zip: 5332,
    city: 'Rekingen AG',
    canton: 'AG',
  },
  {
    zip: 5333,
    city: 'Baldingen',
    canton: 'AG',
  },
  {
    zip: 5334,
    city: 'Böbikon',
    canton: 'AG',
  },
  {
    zip: 5400,
    city: 'Baden',
    canton: 'AG',
  },
  {
    zip: 5404,
    city: 'Baden',
    canton: 'AG',
  },
  {
    zip: 5405,
    city: 'Dättwil AG',
    canton: 'AG',
  },
  {
    zip: 5406,
    city: 'Rütihof',
    canton: 'AG',
  },
  {
    zip: 5408,
    city: 'Ennetbaden',
    canton: 'AG',
  },
  {
    zip: 5412,
    city: 'Gebenstorf',
    canton: 'AG',
  },
  {
    zip: 5413,
    city: 'Birmenstorf AG',
    canton: 'AG',
  },
  {
    zip: 5415,
    city: 'Hertenstein AG',
    canton: 'AG',
  },
  {
    zip: 5415,
    city: 'Rieden AG',
    canton: 'AG',
  },
  {
    zip: 5415,
    city: 'Nussbaumen AG',
    canton: 'AG',
  },
  {
    zip: 5416,
    city: 'Kirchdorf AG',
    canton: 'AG',
  },
  {
    zip: 5417,
    city: 'Untersiggenthal',
    canton: 'AG',
  },
  {
    zip: 5420,
    city: 'Ehrendingen',
    canton: 'AG',
  },
  {
    zip: 5423,
    city: 'Freienwil',
    canton: 'AG',
  },
  {
    zip: 5425,
    city: 'Schneisingen',
    canton: 'AG',
  },
  {
    zip: 5426,
    city: 'Lengnau AG',
    canton: 'AG',
  },
  {
    zip: 5430,
    city: 'Wettingen',
    canton: 'AG',
  },
  {
    zip: 5432,
    city: 'Neuenhof',
    canton: 'AG',
  },
  {
    zip: 5436,
    city: 'Würenlos',
    canton: 'AG',
  },
  {
    zip: 5442,
    city: 'Fislisbach',
    canton: 'AG',
  },
  {
    zip: 5443,
    city: 'Niederrohrdorf',
    canton: 'AG',
  },
  {
    zip: 5444,
    city: 'Künten',
    canton: 'AG',
  },
  {
    zip: 5445,
    city: 'Eggenwil',
    canton: 'AG',
  },
  {
    zip: 5452,
    city: 'Oberrohrdorf',
    canton: 'AG',
  },
  {
    zip: 5453,
    city: 'Remetschwil',
    canton: 'AG',
  },
  {
    zip: 5454,
    city: 'Bellikon',
    canton: 'AG',
  },
  {
    zip: 5462,
    city: 'Siglistorf',
    canton: 'AG',
  },
  {
    zip: 5463,
    city: 'Wislikofen',
    canton: 'AG',
  },
  {
    zip: 5464,
    city: 'Rümikon AG',
    canton: 'AG',
  },
  {
    zip: 5465,
    city: 'Mellikon',
    canton: 'AG',
  },
  {
    zip: 5466,
    city: 'Kaiserstuhl AG',
    canton: 'AG',
  },
  {
    zip: 5467,
    city: 'Fisibach',
    canton: 'AG',
  },
  {
    zip: 5502,
    city: 'Hunzenschwil',
    canton: 'AG',
  },
  {
    zip: 5503,
    city: 'Schafisheim',
    canton: 'AG',
  },
  {
    zip: 5504,
    city: 'Othmarsingen',
    canton: 'AG',
  },
  {
    zip: 5505,
    city: 'Brunegg',
    canton: 'AG',
  },
  {
    zip: 5506,
    city: 'Mägenwil',
    canton: 'AG',
  },
  {
    zip: 5507,
    city: 'Mellingen',
    canton: 'AG',
  },
  {
    zip: 5512,
    city: 'Wohlenschwil',
    canton: 'AG',
  },
  {
    zip: 5522,
    city: 'Tägerig',
    canton: 'AG',
  },
  {
    zip: 5524,
    city: 'Nesselnbach',
    canton: 'AG',
  },
  {
    zip: 5524,
    city: 'Niederwil AG',
    canton: 'AG',
  },
  {
    zip: 5525,
    city: 'Fischbach-Göslikon',
    canton: 'AG',
  },
  {
    zip: 5600,
    city: 'Lenzburg',
    canton: 'AG',
  },
  {
    zip: 5600,
    city: 'Ammerswil AG',
    canton: 'AG',
  },
  {
    zip: 5603,
    city: 'Staufen',
    canton: 'AG',
  },
  {
    zip: 5604,
    city: 'Hendschiken',
    canton: 'AG',
  },
  {
    zip: 5605,
    city: 'Dottikon',
    canton: 'AG',
  },
  {
    zip: 5606,
    city: 'Dintikon',
    canton: 'AG',
  },
  {
    zip: 5607,
    city: 'Hägglingen',
    canton: 'AG',
  },
  {
    zip: 5608,
    city: 'Stetten AG',
    canton: 'AG',
  },
  {
    zip: 5610,
    city: 'Wohlen AG',
    canton: 'AG',
  },
  {
    zip: 5611,
    city: 'Anglikon',
    canton: 'AG',
  },
  {
    zip: 5612,
    city: 'Villmergen',
    canton: 'AG',
  },
  {
    zip: 5613,
    city: 'Hilfikon',
    canton: 'AG',
  },
  {
    zip: 5614,
    city: 'Sarmenstorf',
    canton: 'AG',
  },
  {
    zip: 5615,
    city: 'Fahrwangen',
    canton: 'AG',
  },
  {
    zip: 5616,
    city: 'Meisterschwanden',
    canton: 'AG',
  },
  {
    zip: 5617,
    city: 'Tennwil',
    canton: 'AG',
  },
  {
    zip: 5618,
    city: 'Bettwil',
    canton: 'AG',
  },
  {
    zip: 5619,
    city: 'Büttikon AG',
    canton: 'AG',
  },
  {
    zip: 5619,
    city: 'Uezwil',
    canton: 'AG',
  },
  {
    zip: 5620,
    city: 'Bremgarten AG',
    canton: 'AG',
  },
  {
    zip: 5621,
    city: 'Zufikon',
    canton: 'AG',
  },
  {
    zip: 5622,
    city: 'Waltenschwil',
    canton: 'AG',
  },
  {
    zip: 5623,
    city: 'Boswil',
    canton: 'AG',
  },
  {
    zip: 5624,
    city: 'Waldhäusern AG',
    canton: 'AG',
  },
  {
    zip: 5624,
    city: 'Bünzen',
    canton: 'AG',
  },
  {
    zip: 5625,
    city: 'Kallern',
    canton: 'AG',
  },
  {
    zip: 5626,
    city: 'Hermetschwil-Staffeln',
    canton: 'AG',
  },
  {
    zip: 5627,
    city: 'Besenbüren',
    canton: 'AG',
  },
  {
    zip: 5628,
    city: 'Aristau',
    canton: 'AG',
  },
  {
    zip: 5630,
    city: 'Muri AG',
    canton: 'AG',
  },
  {
    zip: 5632,
    city: 'Buttwil',
    canton: 'AG',
  },
  {
    zip: 5634,
    city: 'Merenschwand',
    canton: 'AG',
  },
  {
    zip: 5636,
    city: 'Benzenschwil',
    canton: 'AG',
  },
  {
    zip: 5637,
    city: 'Geltwil',
    canton: 'AG',
  },
  {
    zip: 5637,
    city: 'Beinwil (Freiamt)',
    canton: 'AG',
  },
  {
    zip: 5642,
    city: 'Mühlau',
    canton: 'AG',
  },
  {
    zip: 5643,
    city: 'Alikon',
    canton: 'AG',
  },
  {
    zip: 5643,
    city: 'Meienberg',
    canton: 'AG',
  },
  {
    zip: 5643,
    city: 'Sins',
    canton: 'AG',
  },
  {
    zip: 5644,
    city: 'Auw',
    canton: 'AG',
  },
  {
    zip: 5645,
    city: 'Aettenschwil',
    canton: 'AG',
  },
  {
    zip: 5645,
    city: 'Fenkrieden',
    canton: 'AG',
  },
  {
    zip: 5646,
    city: 'Abtwil AG',
    canton: 'AG',
  },
  {
    zip: 5647,
    city: 'Oberrüti',
    canton: 'AG',
  },
  {
    zip: 5702,
    city: 'Niederlenz',
    canton: 'AG',
  },
  {
    zip: 5703,
    city: 'Seon',
    canton: 'AG',
  },
  {
    zip: 5704,
    city: 'Egliswil',
    canton: 'AG',
  },
  {
    zip: 5705,
    city: 'Hallwil',
    canton: 'AG',
  },
  {
    zip: 5706,
    city: 'Boniswil',
    canton: 'AG',
  },
  {
    zip: 5707,
    city: 'Seengen',
    canton: 'AG',
  },
  {
    zip: 5708,
    city: 'Birrwil',
    canton: 'AG',
  },
  {
    zip: 5712,
    city: 'Beinwil am See',
    canton: 'AG',
  },
  {
    zip: 5722,
    city: 'Gränichen',
    canton: 'AG',
  },
  {
    zip: 5723,
    city: 'Teufenthal AG',
    canton: 'AG',
  },
  {
    zip: 5724,
    city: 'Dürrenäsch',
    canton: 'AG',
  },
  {
    zip: 5725,
    city: 'Leutwil',
    canton: 'AG',
  },
  {
    zip: 5726,
    city: 'Unterkulm',
    canton: 'AG',
  },
  {
    zip: 5727,
    city: 'Oberkulm',
    canton: 'AG',
  },
  {
    zip: 5728,
    city: 'Gontenschwil',
    canton: 'AG',
  },
  {
    zip: 5732,
    city: 'Zetzwil',
    canton: 'AG',
  },
  {
    zip: 5733,
    city: 'Leimbach AG',
    canton: 'AG',
  },
  {
    zip: 5734,
    city: 'Reinach AG',
    canton: 'AG',
  },
  {
    zip: 5735,
    city: 'Pfeffikon LU',
    canton: 'LU',
  },
  {
    zip: 5736,
    city: 'Burg AG',
    canton: 'AG',
  },
  {
    zip: 5737,
    city: 'Menziken',
    canton: 'AG',
  },
  {
    zip: 5742,
    city: 'Kölliken',
    canton: 'AG',
  },
  {
    zip: 5745,
    city: 'Safenwil',
    canton: 'AG',
  },
  {
    zip: 5746,
    city: 'Walterswil SO',
    canton: 'SO',
  },
  {
    zip: 6000,
    city: 'Luzern',
    canton: 'LU',
  },
  {
    zip: 6003,
    city: 'Luzern',
    canton: 'LU',
  },
  {
    zip: 6004,
    city: 'Luzern',
    canton: 'LU',
  },
  {
    zip: 6005,
    city: 'Luzern',
    canton: 'LU',
  },
  {
    zip: 6005,
    city: 'St. Niklausen LU',
    canton: 'LU',
  },
  {
    zip: 6006,
    city: 'Luzern',
    canton: 'LU',
  },
  {
    zip: 6010,
    city: 'Kriens',
    canton: 'LU',
  },
  {
    zip: 6010,
    city: 'Pilatus Kulm',
    canton: 'OW',
  },
  {
    zip: 6012,
    city: 'Obernau',
    canton: 'LU',
  },
  {
    zip: 6013,
    city: 'Eigenthal',
    canton: 'LU',
  },
  {
    zip: 6014,
    city: 'Luzern',
    canton: 'LU',
  },
  {
    zip: 6015,
    city: 'Luzern',
    canton: 'LU',
  },
  {
    zip: 6016,
    city: 'Hellbühl',
    canton: 'LU',
  },
  {
    zip: 6017,
    city: 'Ruswil',
    canton: 'LU',
  },
  {
    zip: 6018,
    city: 'Buttisholz',
    canton: 'LU',
  },
  {
    zip: 6019,
    city: 'Sigigen',
    canton: 'LU',
  },
  {
    zip: 6020,
    city: 'Emmenbrücke',
    canton: 'LU',
  },
  {
    zip: 6022,
    city: 'Grosswangen',
    canton: 'LU',
  },
  {
    zip: 6023,
    city: 'Rothenburg',
    canton: 'LU',
  },
  {
    zip: 6024,
    city: 'Hildisrieden',
    canton: 'LU',
  },
  {
    zip: 6025,
    city: 'Neudorf',
    canton: 'LU',
  },
  {
    zip: 6026,
    city: 'Rain',
    canton: 'LU',
  },
  {
    zip: 6027,
    city: 'Römerswil LU',
    canton: 'LU',
  },
  {
    zip: 6028,
    city: 'Herlisberg',
    canton: 'LU',
  },
  {
    zip: 6030,
    city: 'Ebikon',
    canton: 'LU',
  },
  {
    zip: 6032,
    city: 'Emmen',
    canton: 'LU',
  },
  {
    zip: 6033,
    city: 'Buchrain',
    canton: 'LU',
  },
  {
    zip: 6034,
    city: 'Inwil',
    canton: 'LU',
  },
  {
    zip: 6035,
    city: 'Perlen',
    canton: 'LU',
  },
  {
    zip: 6036,
    city: 'Dierikon',
    canton: 'LU',
  },
  {
    zip: 6037,
    city: 'Root',
    canton: 'LU',
  },
  {
    zip: 6038,
    city: 'Gisikon',
    canton: 'LU',
  },
  {
    zip: 6038,
    city: 'Honau',
    canton: 'LU',
  },
  {
    zip: 6039,
    city: 'Root Längenbold',
    canton: 'LU',
  },
  {
    zip: 6042,
    city: 'Dietwil',
    canton: 'AG',
  },
  {
    zip: 6043,
    city: 'Adligenswil',
    canton: 'LU',
  },
  {
    zip: 6044,
    city: 'Udligenswil',
    canton: 'LU',
  },
  {
    zip: 6045,
    city: 'Meggen',
    canton: 'LU',
  },
  {
    zip: 6047,
    city: 'Kastanienbaum',
    canton: 'LU',
  },
  {
    zip: 6048,
    city: 'Horw',
    canton: 'LU',
  },
  {
    zip: 6052,
    city: 'Hergiswil NW',
    canton: 'NW',
  },
  {
    zip: 6053,
    city: 'Alpnachstad',
    canton: 'OW',
  },
  {
    zip: 6055,
    city: 'Alpnach Dorf',
    canton: 'OW',
  },
  {
    zip: 6056,
    city: 'Kägiswil',
    canton: 'OW',
  },
  {
    zip: 6060,
    city: 'Sarnen',
    canton: 'OW',
  },
  {
    zip: 6060,
    city: 'Ramersberg',
    canton: 'OW',
  },
  {
    zip: 6062,
    city: 'Wilen (Sarnen)',
    canton: 'OW',
  },
  {
    zip: 6063,
    city: 'Stalden (Sarnen)',
    canton: 'OW',
  },
  {
    zip: 6064,
    city: 'Kerns',
    canton: 'OW',
  },
  {
    zip: 6066,
    city: 'St. Niklausen OW',
    canton: 'OW',
  },
  {
    zip: 6067,
    city: 'Melchtal',
    canton: 'OW',
  },
  {
    zip: 6068,
    city: 'Melchsee-Frutt',
    canton: 'OW',
  },
  {
    zip: 6072,
    city: 'Sachseln',
    canton: 'OW',
  },
  {
    zip: 6073,
    city: 'Flüeli-Ranft',
    canton: 'OW',
  },
  {
    zip: 6074,
    city: 'Giswil',
    canton: 'OW',
  },
  {
    zip: 6078,
    city: 'Bürglen OW',
    canton: 'OW',
  },
  {
    zip: 6078,
    city: 'Lungern',
    canton: 'OW',
  },
  {
    zip: 6083,
    city: 'Hasliberg Hohfluh',
    canton: 'BE',
  },
  {
    zip: 6084,
    city: 'Hasliberg Wasserwendi',
    canton: 'BE',
  },
  {
    zip: 6085,
    city: 'Hasliberg Goldern',
    canton: 'BE',
  },
  {
    zip: 6086,
    city: 'Hasliberg Reuti',
    canton: 'BE',
  },
  {
    zip: 6102,
    city: 'Malters',
    canton: 'LU',
  },
  {
    zip: 6103,
    city: 'Schwarzenberg LU',
    canton: 'LU',
  },
  {
    zip: 6105,
    city: 'Schachen LU',
    canton: 'LU',
  },
  {
    zip: 6106,
    city: 'Werthenstein',
    canton: 'LU',
  },
  {
    zip: 6110,
    city: 'Fontannen b. Wolhusen',
    canton: 'LU',
  },
  {
    zip: 6110,
    city: 'Wolhusen',
    canton: 'LU',
  },
  {
    zip: 6112,
    city: 'Doppleschwand',
    canton: 'LU',
  },
  {
    zip: 6113,
    city: 'Romoos',
    canton: 'LU',
  },
  {
    zip: 6114,
    city: 'Steinhuserberg',
    canton: 'LU',
  },
  {
    zip: 6122,
    city: 'Menznau',
    canton: 'LU',
  },
  {
    zip: 6123,
    city: 'Geiss',
    canton: 'LU',
  },
  {
    zip: 6125,
    city: 'Menzberg',
    canton: 'LU',
  },
  {
    zip: 6126,
    city: 'Daiwil',
    canton: 'LU',
  },
  {
    zip: 6130,
    city: 'Willisau',
    canton: 'LU',
  },
  {
    zip: 6132,
    city: 'Rohrmatt',
    canton: 'LU',
  },
  {
    zip: 6133,
    city: 'Hergiswil b. Willisau',
    canton: 'LU',
  },
  {
    zip: 6142,
    city: 'Gettnau',
    canton: 'LU',
  },
  {
    zip: 6143,
    city: 'Ohmstal',
    canton: 'LU',
  },
  {
    zip: 6144,
    city: 'Zell LU',
    canton: 'LU',
  },
  {
    zip: 6145,
    city: 'Fischbach LU',
    canton: 'LU',
  },
  {
    zip: 6146,
    city: 'Grossdietwil',
    canton: 'LU',
  },
  {
    zip: 6147,
    city: 'Altbüron',
    canton: 'LU',
  },
  {
    zip: 6152,
    city: 'Hüswil',
    canton: 'LU',
  },
  {
    zip: 6153,
    city: 'Ufhusen',
    canton: 'LU',
  },
  {
    zip: 6154,
    city: 'Hofstatt',
    canton: 'LU',
  },
  {
    zip: 6156,
    city: 'Luthern Bad',
    canton: 'LU',
  },
  {
    zip: 6156,
    city: 'Luthern',
    canton: 'LU',
  },
  {
    zip: 6162,
    city: 'Rengg',
    canton: 'LU',
  },
  {
    zip: 6162,
    city: 'Finsterwald b. Entlebuch',
    canton: 'LU',
  },
  {
    zip: 6162,
    city: 'Entlebuch',
    canton: 'LU',
  },
  {
    zip: 6163,
    city: 'Ebnet',
    canton: 'LU',
  },
  {
    zip: 6166,
    city: 'Hasle LU',
    canton: 'LU',
  },
  {
    zip: 6167,
    city: 'Bramboden',
    canton: 'LU',
  },
  {
    zip: 6170,
    city: 'Schüpfheim',
    canton: 'LU',
  },
  {
    zip: 6173,
    city: 'Flühli LU',
    canton: 'LU',
  },
  {
    zip: 6174,
    city: 'Sörenberg',
    canton: 'LU',
  },
  {
    zip: 6182,
    city: 'Escholzmatt',
    canton: 'LU',
  },
  {
    zip: 6192,
    city: 'Wiggen',
    canton: 'LU',
  },
  {
    zip: 6196,
    city: 'Marbach LU',
    canton: 'LU',
  },
  {
    zip: 6197,
    city: 'Schangnau',
    canton: 'BE',
  },
  {
    zip: 6203,
    city: 'Sempach Station',
    canton: 'LU',
  },
  {
    zip: 6204,
    city: 'Sempach',
    canton: 'LU',
  },
  {
    zip: 6205,
    city: 'Eich',
    canton: 'LU',
  },
  {
    zip: 6206,
    city: 'Neuenkirch',
    canton: 'LU',
  },
  {
    zip: 6207,
    city: 'Nottwil',
    canton: 'LU',
  },
  {
    zip: 6208,
    city: 'Oberkirch LU',
    canton: 'LU',
  },
  {
    zip: 6210,
    city: 'Sursee',
    canton: 'LU',
  },
  {
    zip: 6211,
    city: 'Buchs LU',
    canton: 'LU',
  },
  {
    zip: 6212,
    city: 'Kaltbach',
    canton: 'LU',
  },
  {
    zip: 6212,
    city: 'St. Erhard',
    canton: 'LU',
  },
  {
    zip: 6213,
    city: 'Knutwil',
    canton: 'LU',
  },
  {
    zip: 6214,
    city: 'Schenkon',
    canton: 'LU',
  },
  {
    zip: 6215,
    city: 'Schwarzenbach Beromünster',
    canton: 'LU',
  },
  {
    zip: 6215,
    city: 'Beromünster',
    canton: 'LU',
  },
  {
    zip: 6216,
    city: 'Mauensee',
    canton: 'LU',
  },
  {
    zip: 6217,
    city: 'Kottwil',
    canton: 'LU',
  },
  {
    zip: 6218,
    city: 'Ettiswil',
    canton: 'LU',
  },
  {
    zip: 6221,
    city: 'Rickenbach LU',
    canton: 'LU',
  },
  {
    zip: 6222,
    city: 'Gunzwil',
    canton: 'LU',
  },
  {
    zip: 6231,
    city: 'Schlierbach',
    canton: 'LU',
  },
  {
    zip: 6232,
    city: 'Geuensee',
    canton: 'LU',
  },
  {
    zip: 6233,
    city: 'Büron',
    canton: 'LU',
  },
  {
    zip: 6234,
    city: 'Kulmerau',
    canton: 'LU',
  },
  {
    zip: 6234,
    city: 'Triengen',
    canton: 'LU',
  },
  {
    zip: 6235,
    city: 'Winikon',
    canton: 'LU',
  },
  {
    zip: 6236,
    city: 'Wilihof',
    canton: 'LU',
  },
  {
    zip: 6242,
    city: 'Wauwil',
    canton: 'LU',
  },
  {
    zip: 6243,
    city: 'Egolzwil',
    canton: 'LU',
  },
  {
    zip: 6244,
    city: 'Nebikon',
    canton: 'LU',
  },
  {
    zip: 6245,
    city: 'Ebersecken',
    canton: 'LU',
  },
  {
    zip: 6246,
    city: 'Altishofen',
    canton: 'LU',
  },
  {
    zip: 6247,
    city: 'Schötz',
    canton: 'LU',
  },
  {
    zip: 6248,
    city: 'Alberswil',
    canton: 'LU',
  },
  {
    zip: 6252,
    city: 'Dagmersellen',
    canton: 'LU',
  },
  {
    zip: 6253,
    city: 'Uffikon',
    canton: 'LU',
  },
  {
    zip: 6260,
    city: 'Reidermoos',
    canton: 'LU',
  },
  {
    zip: 6260,
    city: 'Hintermoos',
    canton: 'LU',
  },
  {
    zip: 6260,
    city: 'Mehlsecken',
    canton: 'LU',
  },
  {
    zip: 6260,
    city: 'Reiden',
    canton: 'LU',
  },
  {
    zip: 6262,
    city: 'Langnau b. Reiden',
    canton: 'LU',
  },
  {
    zip: 6263,
    city: 'Richenthal',
    canton: 'LU',
  },
  {
    zip: 6264,
    city: 'Pfaffnau',
    canton: 'LU',
  },
  {
    zip: 6265,
    city: 'Roggliswil',
    canton: 'LU',
  },
  {
    zip: 6274,
    city: 'Eschenbach LU',
    canton: 'LU',
  },
  {
    zip: 6275,
    city: 'Ballwil',
    canton: 'LU',
  },
  {
    zip: 6276,
    city: 'Hohenrain',
    canton: 'LU',
  },
  {
    zip: 6277,
    city: 'Kleinwangen',
    canton: 'LU',
  },
  {
    zip: 6277,
    city: 'Lieli LU',
    canton: 'LU',
  },
  {
    zip: 6280,
    city: 'Hochdorf',
    canton: 'LU',
  },
  {
    zip: 6280,
    city: 'Urswil',
    canton: 'LU',
  },
  {
    zip: 6283,
    city: 'Baldegg',
    canton: 'LU',
  },
  {
    zip: 6284,
    city: 'Sulz LU',
    canton: 'LU',
  },
  {
    zip: 6284,
    city: 'Gelfingen',
    canton: 'LU',
  },
  {
    zip: 6285,
    city: 'Retschwil',
    canton: 'LU',
  },
  {
    zip: 6285,
    city: 'Hitzkirch',
    canton: 'LU',
  },
  {
    zip: 6286,
    city: 'Altwis',
    canton: 'LU',
  },
  {
    zip: 6287,
    city: 'Aesch LU',
    canton: 'LU',
  },
  {
    zip: 6288,
    city: 'Schongau',
    canton: 'LU',
  },
  {
    zip: 6289,
    city: 'Müswangen',
    canton: 'LU',
  },
  {
    zip: 6289,
    city: 'Hämikon',
    canton: 'LU',
  },
  {
    zip: 6294,
    city: 'Ermensee',
    canton: 'LU',
  },
  {
    zip: 6295,
    city: 'Mosen',
    canton: 'LU',
  },
  {
    zip: 6300,
    city: 'Zug',
    canton: 'ZG',
  },
  {
    zip: 6300,
    city: 'Zugerberg',
    canton: 'ZG',
  },
  {
    zip: 6312,
    city: 'Steinhausen',
    canton: 'ZG',
  },
  {
    zip: 6313,
    city: 'Edlibach',
    canton: 'ZG',
  },
  {
    zip: 6313,
    city: 'Finstersee',
    canton: 'ZG',
  },
  {
    zip: 6313,
    city: 'Menzingen',
    canton: 'ZG',
  },
  {
    zip: 6314,
    city: 'Neuägeri',
    canton: 'ZG',
  },
  {
    zip: 6314,
    city: 'Unterägeri',
    canton: 'ZG',
  },
  {
    zip: 6315,
    city: 'Morgarten',
    canton: 'ZG',
  },
  {
    zip: 6315,
    city: 'Alosen',
    canton: 'ZG',
  },
  {
    zip: 6315,
    city: 'Oberägeri',
    canton: 'ZG',
  },
  {
    zip: 6317,
    city: 'Oberwil b. Zug',
    canton: 'ZG',
  },
  {
    zip: 6318,
    city: 'Walchwil',
    canton: 'ZG',
  },
  {
    zip: 6319,
    city: 'Allenwinden',
    canton: 'ZG',
  },
  {
    zip: 6330,
    city: 'Cham',
    canton: 'ZG',
  },
  {
    zip: 6331,
    city: 'Hünenberg',
    canton: 'ZG',
  },
  {
    zip: 6332,
    city: 'Hagendorn',
    canton: 'ZG',
  },
  {
    zip: 6333,
    city: 'Hünenberg See',
    canton: 'ZG',
  },
  {
    zip: 6340,
    city: 'Baar',
    canton: 'ZG',
  },
  {
    zip: 6340,
    city: 'Sihlbrugg',
    canton: 'ZG',
  },
  {
    zip: 6343,
    city: 'Buonas',
    canton: 'ZG',
  },
  {
    zip: 6343,
    city: 'Risch',
    canton: 'ZG',
  },
  {
    zip: 6343,
    city: 'Holzhäusern ZG',
    canton: 'ZG',
  },
  {
    zip: 6343,
    city: 'Rotkreuz',
    canton: 'ZG',
  },
  {
    zip: 6344,
    city: 'Meierskappel',
    canton: 'LU',
  },
  {
    zip: 6345,
    city: 'Neuheim',
    canton: 'ZG',
  },
  {
    zip: 6353,
    city: 'Weggis',
    canton: 'LU',
  },
  {
    zip: 6354,
    city: 'Vitznau',
    canton: 'LU',
  },
  {
    zip: 6356,
    city: 'Rigi Kaltbad',
    canton: 'LU',
  },
  {
    zip: 6362,
    city: 'Stansstad',
    canton: 'NW',
  },
  {
    zip: 6363,
    city: 'Obbürgen',
    canton: 'NW',
  },
  {
    zip: 6363,
    city: 'Bürgenstock',
    canton: 'NW',
  },
  {
    zip: 6363,
    city: 'Fürigen',
    canton: 'NW',
  },
  {
    zip: 6365,
    city: 'Kehrsiten',
    canton: 'NW',
  },
  {
    zip: 6370,
    city: 'Stans',
    canton: 'NW',
  },
  {
    zip: 6370,
    city: 'Oberdorf NW',
    canton: 'NW',
  },
  {
    zip: 6372,
    city: 'Ennetmoos',
    canton: 'NW',
  },
  {
    zip: 6373,
    city: 'Ennetbürgen',
    canton: 'NW',
  },
  {
    zip: 6374,
    city: 'Buochs',
    canton: 'NW',
  },
  {
    zip: 6375,
    city: 'Beckenried',
    canton: 'NW',
  },
  {
    zip: 6376,
    city: 'Emmetten',
    canton: 'NW',
  },
  {
    zip: 6377,
    city: 'Seelisberg',
    canton: 'UR',
  },
  {
    zip: 6382,
    city: 'Büren NW',
    canton: 'NW',
  },
  {
    zip: 6383,
    city: 'Niederrickenbach',
    canton: 'NW',
  },
  {
    zip: 6383,
    city: 'Wiesenberg',
    canton: 'NW',
  },
  {
    zip: 6383,
    city: 'Wirzweli',
    canton: 'NW',
  },
  {
    zip: 6383,
    city: 'Dallenwil',
    canton: 'NW',
  },
  {
    zip: 6386,
    city: 'Wolfenschiessen',
    canton: 'NW',
  },
  {
    zip: 6387,
    city: 'Oberrickenbach',
    canton: 'NW',
  },
  {
    zip: 6388,
    city: 'Grafenort',
    canton: 'OW',
  },
  {
    zip: 6390,
    city: 'Engelberg',
    canton: 'OW',
  },
  {
    zip: 6402,
    city: 'Merlischachen',
    canton: 'SZ',
  },
  {
    zip: 6403,
    city: 'Küssnacht am Rigi',
    canton: 'SZ',
  },
  {
    zip: 6404,
    city: 'Greppen',
    canton: 'LU',
  },
  {
    zip: 6405,
    city: 'Immensee',
    canton: 'SZ',
  },
  {
    zip: 6410,
    city: 'Rigi Klösterli',
    canton: 'SZ',
  },
  {
    zip: 6410,
    city: 'Rigi Staffel',
    canton: 'SZ',
  },
  {
    zip: 6410,
    city: 'Rigi Kulm',
    canton: 'SZ',
  },
  {
    zip: 6410,
    city: 'Rigi Scheidegg',
    canton: 'SZ',
  },
  {
    zip: 6410,
    city: 'Goldau',
    canton: 'SZ',
  },
  {
    zip: 6414,
    city: 'Oberarth',
    canton: 'SZ',
  },
  {
    zip: 6415,
    city: 'Arth',
    canton: 'SZ',
  },
  {
    zip: 6416,
    city: 'Steinerberg',
    canton: 'SZ',
  },
  {
    zip: 6417,
    city: 'Sattel',
    canton: 'SZ',
  },
  {
    zip: 6418,
    city: 'Rothenthurm',
    canton: 'SZ',
  },
  {
    zip: 6422,
    city: 'Steinen',
    canton: 'SZ',
  },
  {
    zip: 6423,
    city: 'Seewen SZ',
    canton: 'SZ',
  },
  {
    zip: 6424,
    city: 'Lauerz',
    canton: 'SZ',
  },
  {
    zip: 6430,
    city: 'Schwyz',
    canton: 'SZ',
  },
  {
    zip: 6432,
    city: 'Rickenbach b. Schwyz',
    canton: 'SZ',
  },
  {
    zip: 6433,
    city: 'Stoos SZ',
    canton: 'SZ',
  },
  {
    zip: 6434,
    city: 'Illgau',
    canton: 'SZ',
  },
  {
    zip: 6436,
    city: 'Ried (Muotathal)',
    canton: 'SZ',
  },
  {
    zip: 6436,
    city: 'Bisisthal',
    canton: 'SZ',
  },
  {
    zip: 6436,
    city: 'Muotathal',
    canton: 'SZ',
  },
  {
    zip: 6438,
    city: 'Ibach',
    canton: 'SZ',
  },
  {
    zip: 6440,
    city: 'Brunnen',
    canton: 'SZ',
  },
  {
    zip: 6441,
    city: 'Rütli',
    canton: 'UR',
  },
  {
    zip: 6442,
    city: 'Gersau',
    canton: 'SZ',
  },
  {
    zip: 6443,
    city: 'Morschach',
    canton: 'SZ',
  },
  {
    zip: 6452,
    city: 'Riemenstalden',
    canton: 'SZ',
  },
  {
    zip: 6452,
    city: 'Sisikon',
    canton: 'UR',
  },
  {
    zip: 6454,
    city: 'Flüelen',
    canton: 'UR',
  },
  {
    zip: 6460,
    city: 'Altdorf UR',
    canton: 'UR',
  },
  {
    zip: 6461,
    city: 'Isenthal',
    canton: 'UR',
  },
  {
    zip: 6462,
    city: 'Seedorf UR',
    canton: 'UR',
  },
  {
    zip: 6463,
    city: 'Bürglen UR',
    canton: 'UR',
  },
  {
    zip: 6464,
    city: 'Spiringen',
    canton: 'UR',
  },
  {
    zip: 6465,
    city: 'Unterschächen',
    canton: 'UR',
  },
  {
    zip: 6466,
    city: 'Bauen',
    canton: 'UR',
  },
  {
    zip: 6467,
    city: 'Schattdorf',
    canton: 'UR',
  },
  {
    zip: 6468,
    city: 'Attinghausen',
    canton: 'UR',
  },
  {
    zip: 6469,
    city: 'Haldi b. Schattdorf',
    canton: 'UR',
  },
  {
    zip: 6472,
    city: 'Erstfeld',
    canton: 'UR',
  },
  {
    zip: 6473,
    city: 'Silenen',
    canton: 'UR',
  },
  {
    zip: 6474,
    city: 'Amsteg',
    canton: 'UR',
  },
  {
    zip: 6475,
    city: 'Bristen',
    canton: 'UR',
  },
  {
    zip: 6476,
    city: 'Intschi',
    canton: 'UR',
  },
  {
    zip: 6482,
    city: 'Gurtnellen',
    canton: 'UR',
  },
  {
    zip: 6484,
    city: 'Wassen UR',
    canton: 'UR',
  },
  {
    zip: 6485,
    city: 'Meien',
    canton: 'UR',
  },
  {
    zip: 6487,
    city: 'Göschenen',
    canton: 'UR',
  },
  {
    zip: 6490,
    city: 'Andermatt',
    canton: 'UR',
  },
  {
    zip: 6491,
    city: 'Realp',
    canton: 'UR',
  },
  {
    zip: 6493,
    city: 'Hospental',
    canton: 'UR',
  },
  {
    zip: 6500,
    city: 'Bellinzona',
    canton: 'TI',
  },
  {
    zip: 6503,
    city: 'Bellinzona',
    canton: 'TI',
  },
  {
    zip: 6512,
    city: 'Giubiasco',
    canton: 'TI',
  },
  {
    zip: 6513,
    city: 'Monte Carasso',
    canton: 'TI',
  },
  {
    zip: 6514,
    city: 'Sementina',
    canton: 'TI',
  },
  {
    zip: 6515,
    city: 'Gudo',
    canton: 'TI',
  },
  {
    zip: 6516,
    city: 'Cugnasco',
    canton: 'TI',
  },
  {
    zip: 6517,
    city: 'Arbedo',
    canton: 'TI',
  },
  {
    zip: 6518,
    city: 'Gorduno',
    canton: 'TI',
  },
  {
    zip: 6523,
    city: 'Preonzo',
    canton: 'TI',
  },
  {
    zip: 6524,
    city: 'Moleno',
    canton: 'TI',
  },
  {
    zip: 6525,
    city: 'Gnosca',
    canton: 'TI',
  },
  {
    zip: 6526,
    city: 'Prosito',
    canton: 'TI',
  },
  {
    zip: 6527,
    city: 'Lodrino',
    canton: 'TI',
  },
  {
    zip: 6528,
    city: 'Camorino',
    canton: 'TI',
  },
  {
    zip: 6532,
    city: 'Castione',
    canton: 'TI',
  },
  {
    zip: 6533,
    city: 'Lumino',
    canton: 'TI',
  },
  {
    zip: 6534,
    city: 'S. Vittore',
    canton: 'GR',
  },
  {
    zip: 6535,
    city: 'Roveredo GR',
    canton: 'GR',
  },
  {
    zip: 6537,
    city: 'Grono',
    canton: 'GR',
  },
  {
    zip: 6538,
    city: 'Verdabbio',
    canton: 'GR',
  },
  {
    zip: 6540,
    city: 'Castaneda',
    canton: 'GR',
  },
  {
    zip: 6541,
    city: 'Sta. Maria in Calanca',
    canton: 'GR',
  },
  {
    zip: 6542,
    city: 'Buseno',
    canton: 'GR',
  },
  {
    zip: 6543,
    city: 'Arvigo',
    canton: 'GR',
  },
  {
    zip: 6544,
    city: 'Braggio',
    canton: 'GR',
  },
  {
    zip: 6545,
    city: 'Selma',
    canton: 'GR',
  },
  {
    zip: 6546,
    city: 'Cauco',
    canton: 'GR',
  },
  {
    zip: 6547,
    city: 'Augio',
    canton: 'GR',
  },
  {
    zip: 6548,
    city: 'Rossa',
    canton: 'GR',
  },
  {
    zip: 6549,
    city: 'Laura',
    canton: 'GR',
  },
  {
    zip: 6556,
    city: 'Leggia',
    canton: 'GR',
  },
  {
    zip: 6557,
    city: 'Cama',
    canton: 'GR',
  },
  {
    zip: 6558,
    city: 'Lostallo',
    canton: 'GR',
  },
  {
    zip: 6562,
    city: 'Soazza',
    canton: 'GR',
  },
  {
    zip: 6563,
    city: 'Mesocco',
    canton: 'GR',
  },
  {
    zip: 6565,
    city: 'S. Bernardino',
    canton: 'GR',
  },
  {
    zip: 6571,
    city: 'Indemini',
    canton: 'TI',
  },
  {
    zip: 6572,
    city: 'Quartino',
    canton: 'TI',
  },
  {
    zip: 6573,
    city: 'Magadino',
    canton: 'TI',
  },
  {
    zip: 6574,
    city: 'Vira (Gambarogno)',
    canton: 'TI',
  },
  {
    zip: 6575,
    city: 'Vairano',
    canton: 'TI',
  },
  {
    zip: 6575,
    city: 'S. Nazzaro',
    canton: 'TI',
  },
  {
    zip: 6576,
    city: 'Gerra (Gambarogno)',
    canton: 'TI',
  },
  {
    zip: 6577,
    city: 'Ranzo',
    canton: 'TI',
  },
  {
    zip: 6578,
    city: 'Caviano',
    canton: 'TI',
  },
  {
    zip: 6579,
    city: 'Piazzogna',
    canton: 'TI',
  },
  {
    zip: 6582,
    city: 'Pianezzo',
    canton: 'TI',
  },
  {
    zip: 6583,
    city: 'S. Antonio (Val Morobbia)',
    canton: 'TI',
  },
  {
    zip: 6584,
    city: 'Carena',
    canton: 'TI',
  },
  {
    zip: 6592,
    city: 'S. Antonino',
    canton: 'TI',
  },
  {
    zip: 6593,
    city: 'Cadenazzo',
    canton: 'TI',
  },
  {
    zip: 6594,
    city: 'Contone',
    canton: 'TI',
  },
  {
    zip: 6595,
    city: 'Riazzino',
    canton: 'TI',
  },
  {
    zip: 6596,
    city: 'Gordola',
    canton: 'TI',
  },
  {
    zip: 6597,
    city: 'Agarone',
    canton: 'TI',
  },
  {
    zip: 6598,
    city: 'Tenero',
    canton: 'TI',
  },
  {
    zip: 6599,
    city: 'Robasacco',
    canton: 'TI',
  },
  {
    zip: 6600,
    city: 'Locarno',
    canton: 'TI',
  },
  {
    zip: 6600,
    city: 'Muralto',
    canton: 'TI',
  },
  {
    zip: 6600,
    city: 'Solduno',
    canton: 'TI',
  },
  {
    zip: 6605,
    city: 'Monte Brè sopra Locarno',
    canton: 'TI',
  },
  {
    zip: 6605,
    city: 'Locarno',
    canton: 'TI',
  },
  {
    zip: 6611,
    city: 'Mosogno',
    canton: 'TI',
  },
  {
    zip: 6611,
    city: 'Gresso',
    canton: 'TI',
  },
  {
    zip: 6611,
    city: 'Crana',
    canton: 'TI',
  },
  {
    zip: 6612,
    city: 'Ascona',
    canton: 'TI',
  },
  {
    zip: 6613,
    city: 'Porto Ronco',
    canton: 'TI',
  },
  {
    zip: 6614,
    city: 'Isole di Brissago',
    canton: 'TI',
  },
  {
    zip: 6614,
    city: 'Brissago',
    canton: 'TI',
  },
  {
    zip: 6616,
    city: 'Losone',
    canton: 'TI',
  },
  {
    zip: 6618,
    city: 'Arcegno',
    canton: 'TI',
  },
  {
    zip: 6622,
    city: 'Ronco sopra Ascona',
    canton: 'TI',
  },
  {
    zip: 6631,
    city: 'Corippo',
    canton: 'TI',
  },
  {
    zip: 6632,
    city: 'Vogorno',
    canton: 'TI',
  },
  {
    zip: 6633,
    city: 'Lavertezzo',
    canton: 'TI',
  },
  {
    zip: 6634,
    city: 'Brione (Verzasca)',
    canton: 'TI',
  },
  {
    zip: 6635,
    city: 'Gerra (Verzasca)',
    canton: 'TI',
  },
  {
    zip: 6636,
    city: 'Frasco',
    canton: 'TI',
  },
  {
    zip: 6637,
    city: 'Sonogno',
    canton: 'TI',
  },
  {
    zip: 6644,
    city: 'Orselina',
    canton: 'TI',
  },
  {
    zip: 6645,
    city: 'Brione sopra Minusio',
    canton: 'TI',
  },
  {
    zip: 6646,
    city: 'Contra',
    canton: 'TI',
  },
  {
    zip: 6647,
    city: 'Mergoscia',
    canton: 'TI',
  },
  {
    zip: 6648,
    city: 'Minusio',
    canton: 'TI',
  },
  {
    zip: 6652,
    city: 'Tegna',
    canton: 'TI',
  },
  {
    zip: 6653,
    city: 'Verscio',
    canton: 'TI',
  },
  {
    zip: 6654,
    city: 'Cavigliano',
    canton: 'TI',
  },
  {
    zip: 6655,
    city: 'Verdasio',
    canton: 'TI',
  },
  {
    zip: 6655,
    city: 'Rasa',
    canton: 'TI',
  },
  {
    zip: 6655,
    city: 'Intragna',
    canton: 'TI',
  },
  {
    zip: 6656,
    city: 'Golino',
    canton: 'TI',
  },
  {
    zip: 6657,
    city: 'Palagnedra',
    canton: 'TI',
  },
  {
    zip: 6658,
    city: 'Borgnone',
    canton: 'TI',
  },
  {
    zip: 6659,
    city: 'Moneto',
    canton: 'TI',
  },
  {
    zip: 6659,
    city: 'Camedo',
    canton: 'TI',
  },
  {
    zip: 6661,
    city: 'Auressio',
    canton: 'TI',
  },
  {
    zip: 6661,
    city: 'Berzona',
    canton: 'TI',
  },
  {
    zip: 6661,
    city: 'Loco',
    canton: 'TI',
  },
  {
    zip: 6662,
    city: 'Russo',
    canton: 'TI',
  },
  {
    zip: 6663,
    city: 'Comologno',
    canton: 'TI',
  },
  {
    zip: 6663,
    city: 'Spruga',
    canton: 'TI',
  },
  {
    zip: 6664,
    city: 'Vergeletto',
    canton: 'TI',
  },
  {
    zip: 6670,
    city: 'Avegno',
    canton: 'TI',
  },
  {
    zip: 6672,
    city: 'Gordevio',
    canton: 'TI',
  },
  {
    zip: 6673,
    city: 'Maggia',
    canton: 'TI',
  },
  {
    zip: 6674,
    city: 'Riveo',
    canton: 'TI',
  },
  {
    zip: 6674,
    city: 'Someo',
    canton: 'TI',
  },
  {
    zip: 6675,
    city: 'Cevio',
    canton: 'TI',
  },
  {
    zip: 6676,
    city: 'Bignasco',
    canton: 'TI',
  },
  {
    zip: 6677,
    city: 'Moghegno',
    canton: 'TI',
  },
  {
    zip: 6677,
    city: 'Aurigeno',
    canton: 'TI',
  },
  {
    zip: 6678,
    city: 'Coglio',
    canton: 'TI',
  },
  {
    zip: 6678,
    city: 'Lodano',
    canton: 'TI',
  },
  {
    zip: 6678,
    city: 'Giumaglio',
    canton: 'TI',
  },
  {
    zip: 6682,
    city: 'Linescio',
    canton: 'TI',
  },
  {
    zip: 6683,
    city: 'Cerentino',
    canton: 'TI',
  },
  {
    zip: 6683,
    city: 'Niva (Vallemaggia)',
    canton: 'TI',
  },
  {
    zip: 6684,
    city: 'Campo (Vallemaggia)',
    canton: 'TI',
  },
  {
    zip: 6684,
    city: 'Cimalmotto',
    canton: 'TI',
  },
  {
    zip: 6685,
    city: 'Bosco/Gurin',
    canton: 'TI',
  },
  {
    zip: 6690,
    city: 'Cavergno',
    canton: 'TI',
  },
  {
    zip: 6690,
    city: 'S. Carlo (Val Bavona)',
    canton: 'TI',
  },
  {
    zip: 6692,
    city: 'Brontallo',
    canton: 'TI',
  },
  {
    zip: 6692,
    city: 'Menzonio',
    canton: 'TI',
  },
  {
    zip: 6693,
    city: 'Broglio',
    canton: 'TI',
  },
  {
    zip: 6694,
    city: 'Prato-Sornico',
    canton: 'TI',
  },
  {
    zip: 6695,
    city: 'Peccia',
    canton: 'TI',
  },
  {
    zip: 6695,
    city: 'Piano di Peccia',
    canton: 'TI',
  },
  {
    zip: 6696,
    city: 'Fusio',
    canton: 'TI',
  },
  {
    zip: 6702,
    city: 'Claro',
    canton: 'TI',
  },
  {
    zip: 6703,
    city: 'Osogna',
    canton: 'TI',
  },
  {
    zip: 6705,
    city: 'Cresciano',
    canton: 'TI',
  },
  {
    zip: 6707,
    city: 'Iragna',
    canton: 'TI',
  },
  {
    zip: 6710,
    city: 'Biasca',
    canton: 'TI',
  },
  {
    zip: 6710,
    city: 'Biasca Stazione',
    canton: 'TI',
  },
  {
    zip: 6713,
    city: 'Malvaglia',
    canton: 'TI',
  },
  {
    zip: 6714,
    city: 'Semione',
    canton: 'TI',
  },
  {
    zip: 6715,
    city: 'Dongio',
    canton: 'TI',
  },
  {
    zip: 6716,
    city: 'Leontica',
    canton: 'TI',
  },
  {
    zip: 6716,
    city: 'Lottigna',
    canton: 'TI',
  },
  {
    zip: 6716,
    city: 'Acquarossa',
    canton: 'TI',
  },
  {
    zip: 6717,
    city: 'Dangio-Torre',
    canton: 'TI',
  },
  {
    zip: 6717,
    city: 'Torre',
    canton: 'TI',
  },
  {
    zip: 6718,
    city: 'Camperio',
    canton: 'TI',
  },
  {
    zip: 6718,
    city: 'Olivone',
    canton: 'TI',
  },
  {
    zip: 6719,
    city: 'Aquila',
    canton: 'TI',
  },
  {
    zip: 6720,
    city: 'Campo (Blenio)',
    canton: 'TI',
  },
  {
    zip: 6720,
    city: 'Ghirone',
    canton: 'TI',
  },
  {
    zip: 6721,
    city: 'Ludiano',
    canton: 'TI',
  },
  {
    zip: 6721,
    city: 'Motto (Blenio)',
    canton: 'TI',
  },
  {
    zip: 6722,
    city: 'Corzoneso',
    canton: 'TI',
  },
  {
    zip: 6723,
    city: 'Prugiasco',
    canton: 'TI',
  },
  {
    zip: 6723,
    city: 'Castro',
    canton: 'TI',
  },
  {
    zip: 6723,
    city: 'Marolta',
    canton: 'TI',
  },
  {
    zip: 6724,
    city: 'Ponto Valentino',
    canton: 'TI',
  },
  {
    zip: 6724,
    city: 'Largario',
    canton: 'TI',
  },
  {
    zip: 6742,
    city: 'Pollegio',
    canton: 'TI',
  },
  {
    zip: 6743,
    city: 'Bodio TI',
    canton: 'TI',
  },
  {
    zip: 6744,
    city: 'Personico',
    canton: 'TI',
  },
  {
    zip: 6745,
    city: 'Giornico',
    canton: 'TI',
  },
  {
    zip: 6746,
    city: 'Calonico',
    canton: 'TI',
  },
  {
    zip: 6746,
    city: 'Nivo',
    canton: 'TI',
  },
  {
    zip: 6746,
    city: 'Lavorgo',
    canton: 'TI',
  },
  {
    zip: 6747,
    city: 'Chironico',
    canton: 'TI',
  },
  {
    zip: 6748,
    city: 'Anzonico',
    canton: 'TI',
  },
  {
    zip: 6749,
    city: 'Sobrio',
    canton: 'TI',
  },
  {
    zip: 6749,
    city: 'Cavagnago',
    canton: 'TI',
  },
  {
    zip: 6760,
    city: 'Molare',
    canton: 'TI',
  },
  {
    zip: 6760,
    city: 'Calpiogna',
    canton: 'TI',
  },
  {
    zip: 6760,
    city: 'Carì',
    canton: 'TI',
  },
  {
    zip: 6760,
    city: 'Rossura',
    canton: 'TI',
  },
  {
    zip: 6760,
    city: 'Faido',
    canton: 'TI',
  },
  {
    zip: 6760,
    city: 'Campello',
    canton: 'TI',
  },
  {
    zip: 6763,
    city: 'Mairengo',
    canton: 'TI',
  },
  {
    zip: 6763,
    city: 'Osco',
    canton: 'TI',
  },
  {
    zip: 6764,
    city: 'Chiggiogna',
    canton: 'TI',
  },
  {
    zip: 6772,
    city: 'Rodi-Fiesso',
    canton: 'TI',
  },
  {
    zip: 6773,
    city: 'Prato (Leventina)',
    canton: 'TI',
  },
  {
    zip: 6774,
    city: 'Dalpe',
    canton: 'TI',
  },
  {
    zip: 6775,
    city: 'Ambrì',
    canton: 'TI',
  },
  {
    zip: 6776,
    city: 'Piotta',
    canton: 'TI',
  },
  {
    zip: 6777,
    city: 'Quinto',
    canton: 'TI',
  },
  {
    zip: 6777,
    city: 'Varenzo',
    canton: 'TI',
  },
  {
    zip: 6780,
    city: 'Madrano',
    canton: 'TI',
  },
  {
    zip: 6780,
    city: 'Airolo',
    canton: 'TI',
  },
  {
    zip: 6781,
    city: 'Villa Bedretto',
    canton: 'TI',
  },
  {
    zip: 6781,
    city: 'Bedretto',
    canton: 'TI',
  },
  {
    zip: 6802,
    city: 'Rivera',
    canton: 'TI',
  },
  {
    zip: 6803,
    city: 'Camignolo',
    canton: 'TI',
  },
  {
    zip: 6804,
    city: 'Bironico',
    canton: 'TI',
  },
  {
    zip: 6805,
    city: 'Mezzovico',
    canton: 'TI',
  },
  {
    zip: 6806,
    city: 'Sigirino',
    canton: 'TI',
  },
  {
    zip: 6807,
    city: 'Taverne',
    canton: 'TI',
  },
  {
    zip: 6808,
    city: 'Torricella',
    canton: 'TI',
  },
  {
    zip: 6809,
    city: 'Medeglia',
    canton: 'TI',
  },
  {
    zip: 6810,
    city: 'Isone',
    canton: 'TI',
  },
  {
    zip: 6814,
    city: 'Lamone',
    canton: 'TI',
  },
  {
    zip: 6814,
    city: 'Cadempino',
    canton: 'TI',
  },
  {
    zip: 6815,
    city: 'Melide',
    canton: 'TI',
  },
  {
    zip: 6816,
    city: 'Bissone',
    canton: 'TI',
  },
  {
    zip: 6817,
    city: 'Maroggia',
    canton: 'TI',
  },
  {
    zip: 6818,
    city: 'Melano',
    canton: 'TI',
  },
  {
    zip: 6821,
    city: 'Rovio',
    canton: 'TI',
  },
  {
    zip: 6822,
    city: 'Arogno',
    canton: 'TI',
  },
  {
    zip: 6823,
    city: 'Pugerna',
    canton: 'TI',
  },
  {
    zip: 6825,
    city: 'Capolago',
    canton: 'TI',
  },
  {
    zip: 6826,
    city: 'Riva San Vitale',
    canton: 'TI',
  },
  {
    zip: 6827,
    city: 'Brusino Arsizio',
    canton: 'TI',
  },
  {
    zip: 6828,
    city: 'Balerna',
    canton: 'TI',
  },
  {
    zip: 6830,
    city: 'Chiasso',
    canton: 'TI',
  },
  {
    zip: 6832,
    city: 'Seseglio',
    canton: 'TI',
  },
  {
    zip: 6832,
    city: 'Pedrinate',
    canton: 'TI',
  },
  {
    zip: 6833,
    city: 'Vacallo',
    canton: 'TI',
  },
  {
    zip: 6834,
    city: 'Morbio Inferiore',
    canton: 'TI',
  },
  {
    zip: 6835,
    city: 'Morbio Superiore',
    canton: 'TI',
  },
  {
    zip: 6837,
    city: 'Bruzella',
    canton: 'TI',
  },
  {
    zip: 6837,
    city: 'Caneggio',
    canton: 'TI',
  },
  {
    zip: 6838,
    city: 'Cabbio',
    canton: 'TI',
  },
  {
    zip: 6838,
    city: 'Scudellate',
    canton: 'TI',
  },
  {
    zip: 6838,
    city: 'Muggio',
    canton: 'TI',
  },
  {
    zip: 6839,
    city: 'Sagno',
    canton: 'TI',
  },
  {
    zip: 6850,
    city: 'Mendrisio',
    canton: 'TI',
  },
  {
    zip: 6852,
    city: 'Genestrerio',
    canton: 'TI',
  },
  {
    zip: 6853,
    city: 'Ligornetto',
    canton: 'TI',
  },
  {
    zip: 6854,
    city: 'S. Pietro',
    canton: 'TI',
  },
  {
    zip: 6855,
    city: 'Stabio',
    canton: 'TI',
  },
  {
    zip: 6862,
    city: 'Rancate',
    canton: 'TI',
  },
  {
    zip: 6863,
    city: 'Besazio',
    canton: 'TI',
  },
  {
    zip: 6864,
    city: 'Arzo',
    canton: 'TI',
  },
  {
    zip: 6865,
    city: 'Tremona',
    canton: 'TI',
  },
  {
    zip: 6866,
    city: 'Meride',
    canton: 'TI',
  },
  {
    zip: 6867,
    city: 'Serpiano',
    canton: 'TI',
  },
  {
    zip: 6872,
    city: 'Salorino',
    canton: 'TI',
  },
  {
    zip: 6872,
    city: 'Somazzo',
    canton: 'TI',
  },
  {
    zip: 6873,
    city: 'Corteglia',
    canton: 'TI',
  },
  {
    zip: 6874,
    city: 'Castel San Pietro',
    canton: 'TI',
  },
  {
    zip: 6875,
    city: 'Casima',
    canton: 'TI',
  },
  {
    zip: 6875,
    city: 'Monte',
    canton: 'TI',
  },
  {
    zip: 6877,
    city: 'Coldrerio',
    canton: 'TI',
  },
  {
    zip: 6883,
    city: 'Novazzano',
    canton: 'TI',
  },
  {
    zip: 6900,
    city: 'Lugano',
    canton: 'TI',
  },
  {
    zip: 6900,
    city: 'Massagno',
    canton: 'TI',
  },
  {
    zip: 6900,
    city: 'Paradiso',
    canton: 'TI',
  },
  {
    zip: 6911,
    city: "Campione d'Italia",
    canton: 'IT',
  },
  {
    zip: 6912,
    city: 'Pazzallo',
    canton: 'TI',
  },
  {
    zip: 6913,
    city: 'Carabbia',
    canton: 'TI',
  },
  {
    zip: 6914,
    city: 'Carona',
    canton: 'TI',
  },
  {
    zip: 6915,
    city: 'Pambio-Noranco',
    canton: 'TI',
  },
  {
    zip: 6916,
    city: 'Grancia',
    canton: 'TI',
  },
  {
    zip: 6917,
    city: 'Barbengo',
    canton: 'TI',
  },
  {
    zip: 6918,
    city: 'Figino',
    canton: 'TI',
  },
  {
    zip: 6919,
    city: 'Carabietta',
    canton: 'TI',
  },
  {
    zip: 6921,
    city: 'Vico Morcote',
    canton: 'TI',
  },
  {
    zip: 6922,
    city: 'Morcote',
    canton: 'TI',
  },
  {
    zip: 6924,
    city: 'Sorengo',
    canton: 'TI',
  },
  {
    zip: 6925,
    city: 'Gentilino',
    canton: 'TI',
  },
  {
    zip: 6926,
    city: 'Montagnola',
    canton: 'TI',
  },
  {
    zip: 6927,
    city: 'Agra',
    canton: 'TI',
  },
  {
    zip: 6928,
    city: 'Manno',
    canton: 'TI',
  },
  {
    zip: 6929,
    city: 'Gravesano',
    canton: 'TI',
  },
  {
    zip: 6930,
    city: 'Bedano',
    canton: 'TI',
  },
  {
    zip: 6932,
    city: 'Breganzona',
    canton: 'TI',
  },
  {
    zip: 6933,
    city: 'Muzzano',
    canton: 'TI',
  },
  {
    zip: 6934,
    city: 'Bioggio',
    canton: 'TI',
  },
  {
    zip: 6935,
    city: 'Bosco Luganese',
    canton: 'TI',
  },
  {
    zip: 6936,
    city: 'Cademario',
    canton: 'TI',
  },
  {
    zip: 6937,
    city: 'Breno',
    canton: 'TI',
  },
  {
    zip: 6938,
    city: 'Vezio',
    canton: 'TI',
  },
  {
    zip: 6938,
    city: 'Fescoggia',
    canton: 'TI',
  },
  {
    zip: 6939,
    city: 'Arosio',
    canton: 'TI',
  },
  {
    zip: 6939,
    city: 'Mugena',
    canton: 'TI',
  },
  {
    zip: 6942,
    city: 'Savosa',
    canton: 'TI',
  },
  {
    zip: 6943,
    city: 'Vezia',
    canton: 'TI',
  },
  {
    zip: 6944,
    city: 'Cureglia',
    canton: 'TI',
  },
  {
    zip: 6945,
    city: 'Origlio',
    canton: 'TI',
  },
  {
    zip: 6946,
    city: 'Ponte Capriasca',
    canton: 'TI',
  },
  {
    zip: 6947,
    city: 'Vaglio',
    canton: 'TI',
  },
  {
    zip: 6948,
    city: 'Porza',
    canton: 'TI',
  },
  {
    zip: 6949,
    city: 'Comano',
    canton: 'TI',
  },
  {
    zip: 6950,
    city: 'Tesserete',
    canton: 'TI',
  },
  {
    zip: 6951,
    city: 'Insone',
    canton: 'TI',
  },
  {
    zip: 6951,
    city: 'Odogno',
    canton: 'TI',
  },
  {
    zip: 6951,
    city: 'Colla',
    canton: 'TI',
  },
  {
    zip: 6951,
    city: 'Bogno',
    canton: 'TI',
  },
  {
    zip: 6951,
    city: 'Cozzo',
    canton: 'TI',
  },
  {
    zip: 6951,
    city: 'Signôra',
    canton: 'TI',
  },
  {
    zip: 6951,
    city: 'Scareglia',
    canton: 'TI',
  },
  {
    zip: 6952,
    city: 'Canobbio',
    canton: 'TI',
  },
  {
    zip: 6953,
    city: 'Lugaggia',
    canton: 'TI',
  },
  {
    zip: 6954,
    city: 'Sala Capriasca',
    canton: 'TI',
  },
  {
    zip: 6954,
    city: 'Bigorio',
    canton: 'TI',
  },
  {
    zip: 6955,
    city: 'Cagiallo',
    canton: 'TI',
  },
  {
    zip: 6955,
    city: 'Oggio',
    canton: 'TI',
  },
  {
    zip: 6956,
    city: 'Lopagno',
    canton: 'TI',
  },
  {
    zip: 6957,
    city: 'Roveredo TI',
    canton: 'TI',
  },
  {
    zip: 6958,
    city: 'Bidogno',
    canton: 'TI',
  },
  {
    zip: 6958,
    city: 'Corticiasca',
    canton: 'TI',
  },
  {
    zip: 6959,
    city: 'Cimadera',
    canton: 'TI',
  },
  {
    zip: 6959,
    city: 'Certara',
    canton: 'TI',
  },
  {
    zip: 6959,
    city: 'Curtina',
    canton: 'TI',
  },
  {
    zip: 6959,
    city: 'Piandera Paese',
    canton: 'TI',
  },
  {
    zip: 6959,
    city: 'Maglio di Colla',
    canton: 'TI',
  },
  {
    zip: 6962,
    city: 'Viganello',
    canton: 'TI',
  },
  {
    zip: 6963,
    city: 'Cureggia',
    canton: 'TI',
  },
  {
    zip: 6963,
    city: 'Pregassona',
    canton: 'TI',
  },
  {
    zip: 6964,
    city: 'Davesco-Soragno',
    canton: 'TI',
  },
  {
    zip: 6965,
    city: 'Cadro',
    canton: 'TI',
  },
  {
    zip: 6966,
    city: 'Villa Luganese',
    canton: 'TI',
  },
  {
    zip: 6967,
    city: 'Dino',
    canton: 'TI',
  },
  {
    zip: 6968,
    city: 'Sonvico',
    canton: 'TI',
  },
  {
    zip: 6974,
    city: 'Aldesago',
    canton: 'TI',
  },
  {
    zip: 6976,
    city: 'Castagnola',
    canton: 'TI',
  },
  {
    zip: 6977,
    city: 'Ruvigliana',
    canton: 'TI',
  },
  {
    zip: 6978,
    city: 'Gandria',
    canton: 'TI',
  },
  {
    zip: 6979,
    city: 'Brè sopra Lugano',
    canton: 'TI',
  },
  {
    zip: 6980,
    city: 'Castelrotto',
    canton: 'TI',
  },
  {
    zip: 6981,
    city: 'Bedigliora',
    canton: 'TI',
  },
  {
    zip: 6981,
    city: 'Biogno-Beride',
    canton: 'TI',
  },
  {
    zip: 6981,
    city: 'Bombinasco',
    canton: 'TI',
  },
  {
    zip: 6981,
    city: 'Banco',
    canton: 'TI',
  },
  {
    zip: 6982,
    city: 'Agno',
    canton: 'TI',
  },
  {
    zip: 6983,
    city: 'Magliaso',
    canton: 'TI',
  },
  {
    zip: 6984,
    city: 'Pura',
    canton: 'TI',
  },
  {
    zip: 6986,
    city: 'Miglieglia',
    canton: 'TI',
  },
  {
    zip: 6986,
    city: 'Curio',
    canton: 'TI',
  },
  {
    zip: 6986,
    city: 'Novaggio',
    canton: 'TI',
  },
  {
    zip: 6987,
    city: 'Caslano',
    canton: 'TI',
  },
  {
    zip: 6988,
    city: 'Ponte Tresa',
    canton: 'TI',
  },
  {
    zip: 6989,
    city: 'Purasca',
    canton: 'TI',
  },
  {
    zip: 6990,
    city: "Cassina d'Agno",
    canton: 'TI',
  },
  {
    zip: 6991,
    city: 'Neggio',
    canton: 'TI',
  },
  {
    zip: 6992,
    city: 'Cimo',
    canton: 'TI',
  },
  {
    zip: 6992,
    city: 'Vernate',
    canton: 'TI',
  },
  {
    zip: 6993,
    city: 'Iseo',
    canton: 'TI',
  },
  {
    zip: 6994,
    city: 'Aranno',
    canton: 'TI',
  },
  {
    zip: 6995,
    city: 'Madonna del Piano',
    canton: 'TI',
  },
  {
    zip: 6995,
    city: 'Molinazzo di Monteggio',
    canton: 'TI',
  },
  {
    zip: 6996,
    city: 'Ponte Cremenaga',
    canton: 'TI',
  },
  {
    zip: 6997,
    city: 'Sessa',
    canton: 'TI',
  },
  {
    zip: 6998,
    city: 'Termine',
    canton: 'TI',
  },
  {
    zip: 6999,
    city: 'Astano',
    canton: 'TI',
  },
  {
    zip: 7000,
    city: 'Chur',
    canton: 'GR',
  },
  {
    zip: 7012,
    city: 'Felsberg',
    canton: 'GR',
  },
  {
    zip: 7013,
    city: 'Domat/Ems',
    canton: 'GR',
  },
  {
    zip: 7014,
    city: 'Trin',
    canton: 'GR',
  },
  {
    zip: 7015,
    city: 'Tamins',
    canton: 'GR',
  },
  {
    zip: 7016,
    city: 'Trin Mulin',
    canton: 'GR',
  },
  {
    zip: 7017,
    city: 'Flims Dorf',
    canton: 'GR',
  },
  {
    zip: 7018,
    city: 'Flims Waldhaus',
    canton: 'GR',
  },
  {
    zip: 7019,
    city: 'Fidaz',
    canton: 'GR',
  },
  {
    zip: 7023,
    city: 'Haldenstein',
    canton: 'GR',
  },
  {
    zip: 7026,
    city: 'Maladers',
    canton: 'GR',
  },
  {
    zip: 7027,
    city: 'Lüen',
    canton: 'GR',
  },
  {
    zip: 7027,
    city: 'Castiel',
    canton: 'GR',
  },
  {
    zip: 7027,
    city: 'Calfreisen',
    canton: 'GR',
  },
  {
    zip: 7028,
    city: 'Pagig',
    canton: 'GR',
  },
  {
    zip: 7028,
    city: 'St. Peter',
    canton: 'GR',
  },
  {
    zip: 7029,
    city: 'Peist',
    canton: 'GR',
  },
  {
    zip: 7031,
    city: 'Laax GR',
    canton: 'GR',
  },
  {
    zip: 7032,
    city: 'Laax GR 2',
    canton: 'GR',
  },
  {
    zip: 7050,
    city: 'Arosa',
    canton: 'GR',
  },
  {
    zip: 7056,
    city: 'Molinis',
    canton: 'GR',
  },
  {
    zip: 7057,
    city: 'Langwies',
    canton: 'GR',
  },
  {
    zip: 7058,
    city: 'Litzirüti',
    canton: 'GR',
  },
  {
    zip: 7062,
    city: 'Passugg-Araschgen',
    canton: 'GR',
  },
  {
    zip: 7063,
    city: 'Praden',
    canton: 'GR',
  },
  {
    zip: 7064,
    city: 'Tschiertschen',
    canton: 'GR',
  },
  {
    zip: 7074,
    city: 'Malix',
    canton: 'GR',
  },
  {
    zip: 7075,
    city: 'Churwalden',
    canton: 'GR',
  },
  {
    zip: 7076,
    city: 'Parpan',
    canton: 'GR',
  },
  {
    zip: 7077,
    city: 'Valbella',
    canton: 'GR',
  },
  {
    zip: 7078,
    city: 'Lenzerheide/Lai',
    canton: 'GR',
  },
  {
    zip: 7082,
    city: 'Vaz/Obervaz',
    canton: 'GR',
  },
  {
    zip: 7083,
    city: 'Lantsch/Lenz',
    canton: 'GR',
  },
  {
    zip: 7084,
    city: 'Brienz/Brinzauls GR',
    canton: 'GR',
  },
  {
    zip: 7104,
    city: 'Arezen',
    canton: 'GR',
  },
  {
    zip: 7104,
    city: 'Versam',
    canton: 'GR',
  },
  {
    zip: 7106,
    city: 'Tenna',
    canton: 'GR',
  },
  {
    zip: 7107,
    city: 'Safien Platz',
    canton: 'GR',
  },
  {
    zip: 7109,
    city: 'Thalkirch',
    canton: 'GR',
  },
  {
    zip: 7110,
    city: 'Peiden',
    canton: 'GR',
  },
  {
    zip: 7111,
    city: 'Pitasch',
    canton: 'GR',
  },
  {
    zip: 7112,
    city: 'Duvin',
    canton: 'GR',
  },
  {
    zip: 7113,
    city: 'Camuns',
    canton: 'GR',
  },
  {
    zip: 7114,
    city: 'Uors (Lumnezia)',
    canton: 'GR',
  },
  {
    zip: 7115,
    city: 'Surcasti',
    canton: 'GR',
  },
  {
    zip: 7116,
    city: 'Tersnaus',
    canton: 'GR',
  },
  {
    zip: 7116,
    city: 'St. Martin (Lugnez)',
    canton: 'GR',
  },
  {
    zip: 7122,
    city: 'Valendas',
    canton: 'GR',
  },
  {
    zip: 7122,
    city: 'Carrera',
    canton: 'GR',
  },
  {
    zip: 7126,
    city: 'Castrisch',
    canton: 'GR',
  },
  {
    zip: 7127,
    city: 'Sevgein',
    canton: 'GR',
  },
  {
    zip: 7128,
    city: 'Riein',
    canton: 'GR',
  },
  {
    zip: 7130,
    city: 'Schnaus',
    canton: 'GR',
  },
  {
    zip: 7130,
    city: 'Ilanz',
    canton: 'GR',
  },
  {
    zip: 7132,
    city: 'Vals',
    canton: 'GR',
  },
  {
    zip: 7133,
    city: 'Obersaxen Affeier',
    canton: 'GR',
  },
  {
    zip: 7134,
    city: 'Obersaxen Meierhof',
    canton: 'GR',
  },
  {
    zip: 7135,
    city: 'Obersaxen Giraniga',
    canton: 'GR',
  },
  {
    zip: 7136,
    city: 'Obersaxen Friggahüs',
    canton: 'GR',
  },
  {
    zip: 7137,
    city: 'Flond',
    canton: 'GR',
  },
  {
    zip: 7138,
    city: 'Surcuolm',
    canton: 'GR',
  },
  {
    zip: 7141,
    city: 'Luven',
    canton: 'GR',
  },
  {
    zip: 7142,
    city: 'Cumbel',
    canton: 'GR',
  },
  {
    zip: 7143,
    city: 'Morissen',
    canton: 'GR',
  },
  {
    zip: 7144,
    city: 'Vella',
    canton: 'GR',
  },
  {
    zip: 7145,
    city: 'Degen',
    canton: 'GR',
  },
  {
    zip: 7146,
    city: 'Vattiz',
    canton: 'GR',
  },
  {
    zip: 7147,
    city: 'Vignogn',
    canton: 'GR',
  },
  {
    zip: 7148,
    city: 'Surin',
    canton: 'GR',
  },
  {
    zip: 7148,
    city: 'Lumbrein',
    canton: 'GR',
  },
  {
    zip: 7149,
    city: 'Vrin',
    canton: 'GR',
  },
  {
    zip: 7151,
    city: 'Schluein',
    canton: 'GR',
  },
  {
    zip: 7152,
    city: 'Sagogn',
    canton: 'GR',
  },
  {
    zip: 7153,
    city: 'Falera',
    canton: 'GR',
  },
  {
    zip: 7154,
    city: 'Ruschein',
    canton: 'GR',
  },
  {
    zip: 7155,
    city: 'Ladir',
    canton: 'GR',
  },
  {
    zip: 7156,
    city: 'Pigniu',
    canton: 'GR',
  },
  {
    zip: 7156,
    city: 'Rueun',
    canton: 'GR',
  },
  {
    zip: 7157,
    city: 'Siat',
    canton: 'GR',
  },
  {
    zip: 7158,
    city: 'Waltensburg/Vuorz',
    canton: 'GR',
  },
  {
    zip: 7159,
    city: 'Andiast',
    canton: 'GR',
  },
  {
    zip: 7162,
    city: 'Tavanasa',
    canton: 'GR',
  },
  {
    zip: 7163,
    city: 'Danis',
    canton: 'GR',
  },
  {
    zip: 7164,
    city: 'Dardin',
    canton: 'GR',
  },
  {
    zip: 7165,
    city: 'Breil/Brigels',
    canton: 'GR',
  },
  {
    zip: 7166,
    city: 'Trun',
    canton: 'GR',
  },
  {
    zip: 7167,
    city: 'Zignau',
    canton: 'GR',
  },
  {
    zip: 7168,
    city: 'Schlans',
    canton: 'GR',
  },
  {
    zip: 7172,
    city: 'Rabius',
    canton: 'GR',
  },
  {
    zip: 7173,
    city: 'Surrein',
    canton: 'GR',
  },
  {
    zip: 7174,
    city: 'S. Benedetg',
    canton: 'GR',
  },
  {
    zip: 7175,
    city: 'Sumvitg',
    canton: 'GR',
  },
  {
    zip: 7176,
    city: 'Cumpadials',
    canton: 'GR',
  },
  {
    zip: 7180,
    city: 'Disentis/Mustér',
    canton: 'GR',
  },
  {
    zip: 7182,
    city: 'Cavardiras',
    canton: 'GR',
  },
  {
    zip: 7183,
    city: 'Mompé Medel',
    canton: 'GR',
  },
  {
    zip: 7184,
    city: 'Curaglia',
    canton: 'GR',
  },
  {
    zip: 7185,
    city: 'Platta',
    canton: 'GR',
  },
  {
    zip: 7186,
    city: 'Segnas',
    canton: 'GR',
  },
  {
    zip: 7187,
    city: 'Camischolas',
    canton: 'GR',
  },
  {
    zip: 7188,
    city: 'Sedrun',
    canton: 'GR',
  },
  {
    zip: 7189,
    city: 'Rueras',
    canton: 'GR',
  },
  {
    zip: 7201,
    city: 'Untervaz Bahnhof',
    canton: 'GR',
  },
  {
    zip: 7202,
    city: 'Says',
    canton: 'GR',
  },
  {
    zip: 7203,
    city: 'Trimmis',
    canton: 'GR',
  },
  {
    zip: 7204,
    city: 'Untervaz',
    canton: 'GR',
  },
  {
    zip: 7205,
    city: 'Zizers',
    canton: 'GR',
  },
  {
    zip: 7206,
    city: 'Igis',
    canton: 'GR',
  },
  {
    zip: 7208,
    city: 'Malans GR',
    canton: 'GR',
  },
  {
    zip: 7212,
    city: 'Seewis Dorf',
    canton: 'GR',
  },
  {
    zip: 7213,
    city: 'Valzeina',
    canton: 'GR',
  },
  {
    zip: 7214,
    city: 'Seewis-Pardisla',
    canton: 'GR',
  },
  {
    zip: 7214,
    city: 'Seewis-Schmitten',
    canton: 'GR',
  },
  {
    zip: 7214,
    city: 'Grüsch',
    canton: 'GR',
  },
  {
    zip: 7215,
    city: 'Fanas',
    canton: 'GR',
  },
  {
    zip: 7220,
    city: 'Schiers',
    canton: 'GR',
  },
  {
    zip: 7222,
    city: 'Mittellunden',
    canton: 'GR',
  },
  {
    zip: 7223,
    city: 'Buchen im Prättigau',
    canton: 'GR',
  },
  {
    zip: 7224,
    city: 'Putz',
    canton: 'GR',
  },
  {
    zip: 7226,
    city: 'Stels',
    canton: 'GR',
  },
  {
    zip: 7226,
    city: 'Fajauna',
    canton: 'GR',
  },
  {
    zip: 7228,
    city: 'Schuders',
    canton: 'GR',
  },
  {
    zip: 7228,
    city: 'Pusserein',
    canton: 'GR',
  },
  {
    zip: 7231,
    city: 'Pragg-Jenaz',
    canton: 'GR',
  },
  {
    zip: 7232,
    city: 'Furna',
    canton: 'GR',
  },
  {
    zip: 7233,
    city: 'Jenaz',
    canton: 'GR',
  },
  {
    zip: 7235,
    city: 'Fideris',
    canton: 'GR',
  },
  {
    zip: 7240,
    city: 'Küblis',
    canton: 'GR',
  },
  {
    zip: 7241,
    city: 'Conters im Prättigau',
    canton: 'GR',
  },
  {
    zip: 7242,
    city: 'Luzein',
    canton: 'GR',
  },
  {
    zip: 7243,
    city: 'Pany',
    canton: 'GR',
  },
  {
    zip: 7244,
    city: 'Gadenstätt',
    canton: 'GR',
  },
  {
    zip: 7245,
    city: 'Ascharina',
    canton: 'GR',
  },
  {
    zip: 7246,
    city: 'St. Antönien',
    canton: 'GR',
  },
  {
    zip: 7247,
    city: 'Saas im Prättigau',
    canton: 'GR',
  },
  {
    zip: 7249,
    city: 'Serneus',
    canton: 'GR',
  },
  {
    zip: 7250,
    city: 'Klosters',
    canton: 'GR',
  },
  {
    zip: 7252,
    city: 'Klosters Dorf',
    canton: 'GR',
  },
  {
    zip: 7260,
    city: 'Davos Dorf',
    canton: 'GR',
  },
  {
    zip: 7265,
    city: 'Davos Wolfgang',
    canton: 'GR',
  },
  {
    zip: 7270,
    city: 'Davos Platz',
    canton: 'GR',
  },
  {
    zip: 7272,
    city: 'Davos Clavadel',
    canton: 'GR',
  },
  {
    zip: 7276,
    city: 'Davos Frauenkirch',
    canton: 'GR',
  },
  {
    zip: 7277,
    city: 'Davos Glaris',
    canton: 'GR',
  },
  {
    zip: 7278,
    city: 'Davos Monstein',
    canton: 'GR',
  },
  {
    zip: 7302,
    city: 'Landquart',
    canton: 'GR',
  },
  {
    zip: 7303,
    city: 'Mastrils',
    canton: 'GR',
  },
  {
    zip: 7304,
    city: 'Maienfeld',
    canton: 'GR',
  },
  {
    zip: 7306,
    city: 'Fläsch',
    canton: 'GR',
  },
  {
    zip: 7307,
    city: 'Jenins',
    canton: 'GR',
  },
  {
    zip: 7310,
    city: 'Bad Ragaz',
    canton: 'SG',
  },
  {
    zip: 7312,
    city: 'Pfäfers',
    canton: 'SG',
  },
  {
    zip: 7313,
    city: 'St. Margrethenberg',
    canton: 'SG',
  },
  {
    zip: 7314,
    city: 'Vadura',
    canton: 'SG',
  },
  {
    zip: 7315,
    city: 'Vättis',
    canton: 'SG',
  },
  {
    zip: 7317,
    city: 'Vasön',
    canton: 'SG',
  },
  {
    zip: 7317,
    city: 'Valens',
    canton: 'SG',
  },
  {
    zip: 7320,
    city: 'Sargans',
    canton: 'SG',
  },
  {
    zip: 7323,
    city: 'Wangs',
    canton: 'SG',
  },
  {
    zip: 7324,
    city: 'Vilters',
    canton: 'SG',
  },
  {
    zip: 7325,
    city: 'Schwendi im Weisstannental',
    canton: 'SG',
  },
  {
    zip: 7326,
    city: 'Weisstannen',
    canton: 'SG',
  },
  {
    zip: 7402,
    city: 'Bonaduz',
    canton: 'GR',
  },
  {
    zip: 7403,
    city: 'Rhäzüns',
    canton: 'GR',
  },
  {
    zip: 7404,
    city: 'Feldis/Veulden',
    canton: 'GR',
  },
  {
    zip: 7405,
    city: 'Rothenbrunnen',
    canton: 'GR',
  },
  {
    zip: 7407,
    city: 'Trans',
    canton: 'GR',
  },
  {
    zip: 7408,
    city: 'Realta',
    canton: 'GR',
  },
  {
    zip: 7408,
    city: 'Cazis',
    canton: 'GR',
  },
  {
    zip: 7411,
    city: 'Sils im Domleschg',
    canton: 'GR',
  },
  {
    zip: 7412,
    city: 'Scharans',
    canton: 'GR',
  },
  {
    zip: 7413,
    city: 'Fürstenaubruck',
    canton: 'GR',
  },
  {
    zip: 7414,
    city: 'Fürstenau',
    canton: 'GR',
  },
  {
    zip: 7415,
    city: 'Pratval',
    canton: 'GR',
  },
  {
    zip: 7415,
    city: 'Rodels',
    canton: 'GR',
  },
  {
    zip: 7416,
    city: 'Almens',
    canton: 'GR',
  },
  {
    zip: 7417,
    city: 'Paspels',
    canton: 'GR',
  },
  {
    zip: 7418,
    city: 'Tumegl/Tomils',
    canton: 'GR',
  },
  {
    zip: 7419,
    city: 'Scheid',
    canton: 'GR',
  },
  {
    zip: 7421,
    city: 'Summaprada',
    canton: 'GR',
  },
  {
    zip: 7422,
    city: 'Tartar',
    canton: 'GR',
  },
  {
    zip: 7423,
    city: 'Sarn',
    canton: 'GR',
  },
  {
    zip: 7423,
    city: 'Portein',
    canton: 'GR',
  },
  {
    zip: 7424,
    city: 'Präz',
    canton: 'GR',
  },
  {
    zip: 7424,
    city: 'Dalin',
    canton: 'GR',
  },
  {
    zip: 7425,
    city: 'Masein',
    canton: 'GR',
  },
  {
    zip: 7426,
    city: 'Flerden',
    canton: 'GR',
  },
  {
    zip: 7427,
    city: 'Urmein',
    canton: 'GR',
  },
  {
    zip: 7428,
    city: 'Tschappina',
    canton: 'GR',
  },
  {
    zip: 7428,
    city: 'Glaspass',
    canton: 'GR',
  },
  {
    zip: 7430,
    city: 'Rongellen',
    canton: 'GR',
  },
  {
    zip: 7430,
    city: 'Thusis',
    canton: 'GR',
  },
  {
    zip: 7431,
    city: 'Mutten',
    canton: 'GR',
  },
  {
    zip: 7431,
    city: 'Obermutten',
    canton: 'GR',
  },
  {
    zip: 7432,
    city: 'Zillis',
    canton: 'GR',
  },
  {
    zip: 7433,
    city: 'Farden',
    canton: 'GR',
  },
  {
    zip: 7433,
    city: 'Wergenstein',
    canton: 'GR',
  },
  {
    zip: 7433,
    city: 'Lohn GR',
    canton: 'GR',
  },
  {
    zip: 7433,
    city: 'Donat',
    canton: 'GR',
  },
  {
    zip: 7433,
    city: 'Mathon',
    canton: 'GR',
  },
  {
    zip: 7434,
    city: 'Sufers',
    canton: 'GR',
  },
  {
    zip: 7435,
    city: 'Splügen',
    canton: 'GR',
  },
  {
    zip: 7436,
    city: 'Medels im Rheinwald',
    canton: 'GR',
  },
  {
    zip: 7437,
    city: 'Nufenen',
    canton: 'GR',
  },
  {
    zip: 7438,
    city: 'Hinterrhein',
    canton: 'GR',
  },
  {
    zip: 7440,
    city: 'Andeer',
    canton: 'GR',
  },
  {
    zip: 7442,
    city: 'Clugin',
    canton: 'GR',
  },
  {
    zip: 7443,
    city: 'Pignia',
    canton: 'GR',
  },
  {
    zip: 7444,
    city: 'Ausserferrera',
    canton: 'GR',
  },
  {
    zip: 7445,
    city: 'Innerferrera',
    canton: 'GR',
  },
  {
    zip: 7446,
    city: 'Campsut-Cröt',
    canton: 'GR',
  },
  {
    zip: 7447,
    city: 'Cresta (Avers)',
    canton: 'GR',
  },
  {
    zip: 7447,
    city: 'Am Bach (Avers)',
    canton: 'GR',
  },
  {
    zip: 7448,
    city: 'Juf',
    canton: 'GR',
  },
  {
    zip: 7450,
    city: 'Tiefencastel',
    canton: 'GR',
  },
  {
    zip: 7451,
    city: 'Alvaschein',
    canton: 'GR',
  },
  {
    zip: 7452,
    city: 'Cunter',
    canton: 'GR',
  },
  {
    zip: 7453,
    city: 'Tinizong',
    canton: 'GR',
  },
  {
    zip: 7454,
    city: 'Rona',
    canton: 'GR',
  },
  {
    zip: 7455,
    city: 'Mulegns',
    canton: 'GR',
  },
  {
    zip: 7456,
    city: 'Sur',
    canton: 'GR',
  },
  {
    zip: 7456,
    city: 'Marmorera',
    canton: 'GR',
  },
  {
    zip: 7457,
    city: 'Bivio',
    canton: 'GR',
  },
  {
    zip: 7458,
    city: 'Mon',
    canton: 'GR',
  },
  {
    zip: 7459,
    city: 'Stierva',
    canton: 'GR',
  },
  {
    zip: 7460,
    city: 'Savognin',
    canton: 'GR',
  },
  {
    zip: 7462,
    city: 'Salouf',
    canton: 'GR',
  },
  {
    zip: 7463,
    city: 'Riom',
    canton: 'GR',
  },
  {
    zip: 7464,
    city: 'Parsonz',
    canton: 'GR',
  },
  {
    zip: 7472,
    city: 'Surava',
    canton: 'GR',
  },
  {
    zip: 7473,
    city: 'Alvaneu Bad',
    canton: 'GR',
  },
  {
    zip: 7477,
    city: 'Filisur',
    canton: 'GR',
  },
  {
    zip: 7482,
    city: 'Stugl/Stuls',
    canton: 'GR',
  },
  {
    zip: 7482,
    city: 'Preda',
    canton: 'GR',
  },
  {
    zip: 7482,
    city: 'Bergün/Bravuogn',
    canton: 'GR',
  },
  {
    zip: 7484,
    city: 'Latsch',
    canton: 'GR',
  },
  {
    zip: 7492,
    city: 'Alvaneu Dorf',
    canton: 'GR',
  },
  {
    zip: 7493,
    city: 'Schmitten (Albula)',
    canton: 'GR',
  },
  {
    zip: 7494,
    city: 'Wiesen GR',
    canton: 'GR',
  },
  {
    zip: 7500,
    city: 'St. Moritz',
    canton: 'GR',
  },
  {
    zip: 7502,
    city: 'Bever',
    canton: 'GR',
  },
  {
    zip: 7503,
    city: 'Samedan',
    canton: 'GR',
  },
  {
    zip: 7504,
    city: 'Pontresina',
    canton: 'GR',
  },
  {
    zip: 7505,
    city: 'Celerina/Schlarigna',
    canton: 'GR',
  },
  {
    zip: 7512,
    city: 'Champfèr',
    canton: 'GR',
  },
  {
    zip: 7513,
    city: 'Silvaplana-Surlej',
    canton: 'GR',
  },
  {
    zip: 7513,
    city: 'Silvaplana',
    canton: 'GR',
  },
  {
    zip: 7514,
    city: 'Fex',
    canton: 'GR',
  },
  {
    zip: 7514,
    city: 'Sils/Segl Maria',
    canton: 'GR',
  },
  {
    zip: 7515,
    city: 'Sils/Segl Baselgia',
    canton: 'GR',
  },
  {
    zip: 7516,
    city: 'Maloja',
    canton: 'GR',
  },
  {
    zip: 7517,
    city: 'Plaun da Lej',
    canton: 'GR',
  },
  {
    zip: 7522,
    city: 'La Punt-Chamues-ch',
    canton: 'GR',
  },
  {
    zip: 7523,
    city: 'Madulain',
    canton: 'GR',
  },
  {
    zip: 7524,
    city: 'Zuoz',
    canton: 'GR',
  },
  {
    zip: 7525,
    city: 'S-chanf',
    canton: 'GR',
  },
  {
    zip: 7526,
    city: 'Cinuos-chel',
    canton: 'GR',
  },
  {
    zip: 7527,
    city: 'Brail',
    canton: 'GR',
  },
  {
    zip: 7530,
    city: 'Zernez',
    canton: 'GR',
  },
  {
    zip: 7532,
    city: 'Tschierv',
    canton: 'GR',
  },
  {
    zip: 7533,
    city: 'Fuldera',
    canton: 'GR',
  },
  {
    zip: 7534,
    city: 'Lü',
    canton: 'GR',
  },
  {
    zip: 7535,
    city: 'Valchava',
    canton: 'GR',
  },
  {
    zip: 7536,
    city: 'Sta. Maria Val Müstair',
    canton: 'GR',
  },
  {
    zip: 7537,
    city: 'Müstair',
    canton: 'GR',
  },
  {
    zip: 7542,
    city: 'Susch',
    canton: 'GR',
  },
  {
    zip: 7543,
    city: 'Lavin',
    canton: 'GR',
  },
  {
    zip: 7545,
    city: 'Guarda',
    canton: 'GR',
  },
  {
    zip: 7546,
    city: 'Ardez',
    canton: 'GR',
  },
  {
    zip: 7550,
    city: 'Scuol',
    canton: 'GR',
  },
  {
    zip: 7551,
    city: 'Ftan',
    canton: 'GR',
  },
  {
    zip: 7552,
    city: 'Vulpera',
    canton: 'GR',
  },
  {
    zip: 7553,
    city: 'Tarasp',
    canton: 'GR',
  },
  {
    zip: 7554,
    city: 'Crusch',
    canton: 'GR',
  },
  {
    zip: 7554,
    city: 'Sent',
    canton: 'GR',
  },
  {
    zip: 7556,
    city: 'Ramosch',
    canton: 'GR',
  },
  {
    zip: 7557,
    city: 'Vnà',
    canton: 'GR',
  },
  {
    zip: 7558,
    city: 'Strada',
    canton: 'GR',
  },
  {
    zip: 7559,
    city: 'Tschlin',
    canton: 'GR',
  },
  {
    zip: 7560,
    city: 'Martina',
    canton: 'GR',
  },
  {
    zip: 7562,
    city: 'Samnaun-Compatsch',
    canton: 'GR',
  },
  {
    zip: 7563,
    city: 'Samnaun Dorf',
    canton: 'GR',
  },
  {
    zip: 7602,
    city: 'Casaccia',
    canton: 'GR',
  },
  {
    zip: 7603,
    city: 'Vicosoprano',
    canton: 'GR',
  },
  {
    zip: 7604,
    city: 'Borgonovo',
    canton: 'GR',
  },
  {
    zip: 7605,
    city: 'Stampa',
    canton: 'GR',
  },
  {
    zip: 7606,
    city: 'Promontogno',
    canton: 'GR',
  },
  {
    zip: 7608,
    city: 'Castasegna',
    canton: 'GR',
  },
  {
    zip: 7610,
    city: 'Soglio',
    canton: 'GR',
  },
  {
    zip: 7710,
    city: 'Ospizio Bernina',
    canton: 'GR',
  },
  {
    zip: 7710,
    city: 'Alp Grüm',
    canton: 'GR',
  },
  {
    zip: 7741,
    city: 'S. Carlo (Poschiavo)',
    canton: 'GR',
  },
  {
    zip: 7742,
    city: 'Sfazù',
    canton: 'GR',
  },
  {
    zip: 7742,
    city: 'La Rösa',
    canton: 'GR',
  },
  {
    zip: 7742,
    city: 'Poschiavo',
    canton: 'GR',
  },
  {
    zip: 7743,
    city: 'Miralago',
    canton: 'GR',
  },
  {
    zip: 7743,
    city: 'Brusio',
    canton: 'GR',
  },
  {
    zip: 7744,
    city: 'Campocologno',
    canton: 'GR',
  },
  {
    zip: 7745,
    city: 'Li Curt',
    canton: 'GR',
  },
  {
    zip: 7746,
    city: 'Le Prese',
    canton: 'GR',
  },
  {
    zip: 7747,
    city: 'Viano',
    canton: 'GR',
  },
  {
    zip: 7748,
    city: 'Campascio',
    canton: 'GR',
  },
  {
    zip: 8000,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8001,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8002,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8003,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8004,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8005,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8006,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8008,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8032,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8037,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8038,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8041,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8044,
    city: 'Gockhausen',
    canton: 'ZH',
  },
  {
    zip: 8044,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8045,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8046,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8047,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8048,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8049,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8050,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8051,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8052,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8053,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8055,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8057,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8063,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8064,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8099,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8102,
    city: 'Oberengstringen',
    canton: 'ZH',
  },
  {
    zip: 8103,
    city: 'Unterengstringen',
    canton: 'ZH',
  },
  {
    zip: 8104,
    city: 'Weiningen ZH',
    canton: 'ZH',
  },
  {
    zip: 8105,
    city: 'Regensdorf',
    canton: 'ZH',
  },
  {
    zip: 8105,
    city: 'Watt',
    canton: 'ZH',
  },
  {
    zip: 8106,
    city: 'Adlikon b. Regensdorf',
    canton: 'ZH',
  },
  {
    zip: 8107,
    city: 'Buchs ZH',
    canton: 'ZH',
  },
  {
    zip: 8108,
    city: 'Dällikon',
    canton: 'ZH',
  },
  {
    zip: 8109,
    city: 'Kloster Fahr',
    canton: 'AG',
  },
  {
    zip: 8112,
    city: 'Otelfingen',
    canton: 'ZH',
  },
  {
    zip: 8113,
    city: 'Boppelsen',
    canton: 'ZH',
  },
  {
    zip: 8114,
    city: 'Dänikon ZH',
    canton: 'ZH',
  },
  {
    zip: 8115,
    city: 'Hüttikon',
    canton: 'ZH',
  },
  {
    zip: 8117,
    city: 'Fällanden',
    canton: 'ZH',
  },
  {
    zip: 8118,
    city: 'Pfaffhausen',
    canton: 'ZH',
  },
  {
    zip: 8121,
    city: 'Benglen',
    canton: 'ZH',
  },
  {
    zip: 8122,
    city: 'Binz',
    canton: 'ZH',
  },
  {
    zip: 8123,
    city: 'Ebmatingen',
    canton: 'ZH',
  },
  {
    zip: 8124,
    city: 'Maur',
    canton: 'ZH',
  },
  {
    zip: 8125,
    city: 'Zollikerberg',
    canton: 'ZH',
  },
  {
    zip: 8126,
    city: 'Zumikon',
    canton: 'ZH',
  },
  {
    zip: 8127,
    city: 'Forch',
    canton: 'ZH',
  },
  {
    zip: 8132,
    city: 'Hinteregg',
    canton: 'ZH',
  },
  {
    zip: 8132,
    city: 'Egg b. Zürich',
    canton: 'ZH',
  },
  {
    zip: 8133,
    city: 'Esslingen',
    canton: 'ZH',
  },
  {
    zip: 8134,
    city: 'Adliswil',
    canton: 'ZH',
  },
  {
    zip: 8135,
    city: 'Sihlbrugg Station',
    canton: 'ZH',
  },
  {
    zip: 8135,
    city: 'Sihlwald',
    canton: 'ZH',
  },
  {
    zip: 8135,
    city: 'Langnau am Albis',
    canton: 'ZH',
  },
  {
    zip: 8136,
    city: 'Gattikon',
    canton: 'ZH',
  },
  {
    zip: 8142,
    city: 'Uitikon Waldegg',
    canton: 'ZH',
  },
  {
    zip: 8143,
    city: 'Uetliberg',
    canton: 'ZH',
  },
  {
    zip: 8143,
    city: 'Stallikon',
    canton: 'ZH',
  },
  {
    zip: 8152,
    city: 'Glattpark (Opfikon)',
    canton: 'ZH',
  },
  {
    zip: 8152,
    city: 'Glattbrugg',
    canton: 'ZH',
  },
  {
    zip: 8152,
    city: 'Opfikon',
    canton: 'ZH',
  },
  {
    zip: 8153,
    city: 'Rümlang',
    canton: 'ZH',
  },
  {
    zip: 8154,
    city: 'Oberglatt ZH',
    canton: 'ZH',
  },
  {
    zip: 8155,
    city: 'Niederhasli',
    canton: 'ZH',
  },
  {
    zip: 8156,
    city: 'Oberhasli',
    canton: 'ZH',
  },
  {
    zip: 8157,
    city: 'Dielsdorf',
    canton: 'ZH',
  },
  {
    zip: 8158,
    city: 'Regensberg',
    canton: 'ZH',
  },
  {
    zip: 8162,
    city: 'Steinmaur',
    canton: 'ZH',
  },
  {
    zip: 8164,
    city: 'Bachs',
    canton: 'ZH',
  },
  {
    zip: 8165,
    city: 'Oberweningen',
    canton: 'ZH',
  },
  {
    zip: 8165,
    city: 'Schleinikon',
    canton: 'ZH',
  },
  {
    zip: 8165,
    city: 'Schöfflisdorf',
    canton: 'ZH',
  },
  {
    zip: 8166,
    city: 'Niederweningen',
    canton: 'ZH',
  },
  {
    zip: 8172,
    city: 'Niederglatt ZH',
    canton: 'ZH',
  },
  {
    zip: 8173,
    city: 'Neerach',
    canton: 'ZH',
  },
  {
    zip: 8174,
    city: 'Stadel b. Niederglatt',
    canton: 'ZH',
  },
  {
    zip: 8175,
    city: 'Windlach',
    canton: 'ZH',
  },
  {
    zip: 8180,
    city: 'Bülach',
    canton: 'ZH',
  },
  {
    zip: 8181,
    city: 'Höri',
    canton: 'ZH',
  },
  {
    zip: 8182,
    city: 'Hochfelden',
    canton: 'ZH',
  },
  {
    zip: 8184,
    city: 'Bachenbülach',
    canton: 'ZH',
  },
  {
    zip: 8185,
    city: 'Winkel',
    canton: 'ZH',
  },
  {
    zip: 8187,
    city: 'Weiach',
    canton: 'ZH',
  },
  {
    zip: 8192,
    city: 'Zweidlen',
    canton: 'ZH',
  },
  {
    zip: 8192,
    city: 'Glattfelden',
    canton: 'ZH',
  },
  {
    zip: 8193,
    city: 'Eglisau',
    canton: 'ZH',
  },
  {
    zip: 8194,
    city: 'Hüntwangen',
    canton: 'ZH',
  },
  {
    zip: 8195,
    city: 'Wasterkingen',
    canton: 'ZH',
  },
  {
    zip: 8196,
    city: 'Wil ZH',
    canton: 'ZH',
  },
  {
    zip: 8197,
    city: 'Rafz',
    canton: 'ZH',
  },
  {
    zip: 8200,
    city: 'Schaffhausen',
    canton: 'SH',
  },
  {
    zip: 8203,
    city: 'Schaffhausen',
    canton: 'SH',
  },
  {
    zip: 8207,
    city: 'Schaffhausen',
    canton: 'SH',
  },
  {
    zip: 8208,
    city: 'Schaffhausen',
    canton: 'SH',
  },
  {
    zip: 8212,
    city: 'Neuhausen am Rheinfall',
    canton: 'SH',
  },
  {
    zip: 8212,
    city: 'Nohl',
    canton: 'ZH',
  },
  {
    zip: 8213,
    city: 'Neunkirch',
    canton: 'SH',
  },
  {
    zip: 8214,
    city: 'Gächlingen',
    canton: 'SH',
  },
  {
    zip: 8215,
    city: 'Hallau',
    canton: 'SH',
  },
  {
    zip: 8216,
    city: 'Oberhallau',
    canton: 'SH',
  },
  {
    zip: 8217,
    city: 'Wilchingen',
    canton: 'SH',
  },
  {
    zip: 8218,
    city: 'Osterfingen',
    canton: 'SH',
  },
  {
    zip: 8219,
    city: 'Trasadingen',
    canton: 'SH',
  },
  {
    zip: 8222,
    city: 'Beringen',
    canton: 'SH',
  },
  {
    zip: 8223,
    city: 'Guntmadingen',
    canton: 'SH',
  },
  {
    zip: 8224,
    city: 'Löhningen',
    canton: 'SH',
  },
  {
    zip: 8225,
    city: 'Siblingen',
    canton: 'SH',
  },
  {
    zip: 8226,
    city: 'Schleitheim',
    canton: 'SH',
  },
  {
    zip: 8228,
    city: 'Beggingen',
    canton: 'SH',
  },
  {
    zip: 8231,
    city: 'Hemmental',
    canton: 'SH',
  },
  {
    zip: 8232,
    city: 'Merishausen',
    canton: 'SH',
  },
  {
    zip: 8233,
    city: 'Bargen SH',
    canton: 'SH',
  },
  {
    zip: 8234,
    city: 'Stetten SH',
    canton: 'SH',
  },
  {
    zip: 8235,
    city: 'Lohn SH',
    canton: 'SH',
  },
  {
    zip: 8236,
    city: 'Opfertshofen SH',
    canton: 'SH',
  },
  {
    zip: 8236,
    city: 'Büttenhardt',
    canton: 'SH',
  },
  {
    zip: 8238,
    city: 'Büsingen',
    canton: 'DE',
  },
  {
    zip: 8239,
    city: 'Dörflingen',
    canton: 'SH',
  },
  {
    zip: 8240,
    city: 'Thayngen',
    canton: 'SH',
  },
  {
    zip: 8241,
    city: 'Barzheim',
    canton: 'SH',
  },
  {
    zip: 8242,
    city: 'Hofen SH',
    canton: 'SH',
  },
  {
    zip: 8242,
    city: 'Bibern SH',
    canton: 'SH',
  },
  {
    zip: 8243,
    city: 'Altdorf SH',
    canton: 'SH',
  },
  {
    zip: 8245,
    city: 'Feuerthalen',
    canton: 'ZH',
  },
  {
    zip: 8246,
    city: 'Langwiesen',
    canton: 'ZH',
  },
  {
    zip: 8247,
    city: 'Flurlingen',
    canton: 'ZH',
  },
  {
    zip: 8248,
    city: 'Uhwiesen',
    canton: 'ZH',
  },
  {
    zip: 8252,
    city: 'Schlatt TG',
    canton: 'TG',
  },
  {
    zip: 8253,
    city: 'Willisdorf',
    canton: 'TG',
  },
  {
    zip: 8253,
    city: 'Diessenhofen',
    canton: 'TG',
  },
  {
    zip: 8254,
    city: 'Basadingen',
    canton: 'TG',
  },
  {
    zip: 8255,
    city: 'Schlattingen',
    canton: 'TG',
  },
  {
    zip: 8259,
    city: 'Kaltenbach',
    canton: 'TG',
  },
  {
    zip: 8259,
    city: 'Etzwilen',
    canton: 'TG',
  },
  {
    zip: 8259,
    city: 'Wagenhausen',
    canton: 'TG',
  },
  {
    zip: 8259,
    city: 'Rheinklingen',
    canton: 'TG',
  },
  {
    zip: 8260,
    city: 'Stein am Rhein',
    canton: 'SH',
  },
  {
    zip: 8261,
    city: 'Hemishofen',
    canton: 'SH',
  },
  {
    zip: 8262,
    city: 'Ramsen',
    canton: 'SH',
  },
  {
    zip: 8263,
    city: 'Buch SH',
    canton: 'SH',
  },
  {
    zip: 8264,
    city: 'Eschenz',
    canton: 'TG',
  },
  {
    zip: 8265,
    city: 'Mammern',
    canton: 'TG',
  },
  {
    zip: 8266,
    city: 'Steckborn',
    canton: 'TG',
  },
  {
    zip: 8267,
    city: 'Berlingen',
    canton: 'TG',
  },
  {
    zip: 8268,
    city: 'Salenstein',
    canton: 'TG',
  },
  {
    zip: 8268,
    city: 'Mannenbach-Salenstein',
    canton: 'TG',
  },
  {
    zip: 8269,
    city: 'Fruthwilen',
    canton: 'TG',
  },
  {
    zip: 8272,
    city: 'Ermatingen',
    canton: 'TG',
  },
  {
    zip: 8273,
    city: 'Triboltingen',
    canton: 'TG',
  },
  {
    zip: 8274,
    city: 'Gottlieben',
    canton: 'TG',
  },
  {
    zip: 8274,
    city: 'Tägerwilen',
    canton: 'TG',
  },
  {
    zip: 8280,
    city: 'Kreuzlingen',
    canton: 'TG',
  },
  {
    zip: 8302,
    city: 'Kloten',
    canton: 'ZH',
  },
  {
    zip: 8303,
    city: 'Bassersdorf',
    canton: 'ZH',
  },
  {
    zip: 8304,
    city: 'Wallisellen',
    canton: 'ZH',
  },
  {
    zip: 8305,
    city: 'Dietlikon',
    canton: 'ZH',
  },
  {
    zip: 8306,
    city: 'Brüttisellen',
    canton: 'ZH',
  },
  {
    zip: 8307,
    city: 'Ottikon b. Kemptthal',
    canton: 'ZH',
  },
  {
    zip: 8307,
    city: 'Effretikon',
    canton: 'ZH',
  },
  {
    zip: 8308,
    city: 'Agasul',
    canton: 'ZH',
  },
  {
    zip: 8308,
    city: 'Illnau',
    canton: 'ZH',
  },
  {
    zip: 8309,
    city: 'Nürensdorf',
    canton: 'ZH',
  },
  {
    zip: 8310,
    city: 'Kemptthal',
    canton: 'ZH',
  },
  {
    zip: 8310,
    city: 'Grafstal',
    canton: 'ZH',
  },
  {
    zip: 8311,
    city: 'Brütten',
    canton: 'ZH',
  },
  {
    zip: 8312,
    city: 'Winterberg ZH',
    canton: 'ZH',
  },
  {
    zip: 8314,
    city: 'Kyburg',
    canton: 'ZH',
  },
  {
    zip: 8315,
    city: 'Lindau',
    canton: 'ZH',
  },
  {
    zip: 8317,
    city: 'Tagelswangen',
    canton: 'ZH',
  },
  {
    zip: 8320,
    city: 'Fehraltorf',
    canton: 'ZH',
  },
  {
    zip: 8322,
    city: 'Madetswil',
    canton: 'ZH',
  },
  {
    zip: 8330,
    city: 'Hermatswil',
    canton: 'ZH',
  },
  {
    zip: 8330,
    city: 'Pfäffikon ZH',
    canton: 'ZH',
  },
  {
    zip: 8331,
    city: 'Auslikon',
    canton: 'ZH',
  },
  {
    zip: 8332,
    city: 'Russikon',
    canton: 'ZH',
  },
  {
    zip: 8335,
    city: 'Hittnau',
    canton: 'ZH',
  },
  {
    zip: 8340,
    city: 'Hinwil',
    canton: 'ZH',
  },
  {
    zip: 8342,
    city: 'Wernetshausen',
    canton: 'ZH',
  },
  {
    zip: 8344,
    city: 'Bäretswil',
    canton: 'ZH',
  },
  {
    zip: 8345,
    city: 'Adetswil',
    canton: 'ZH',
  },
  {
    zip: 8352,
    city: 'Ricketwil (Winterthur)',
    canton: 'ZH',
  },
  {
    zip: 8352,
    city: 'Elsau',
    canton: 'ZH',
  },
  {
    zip: 8353,
    city: 'Elgg',
    canton: 'ZH',
  },
  {
    zip: 8354,
    city: 'Hofstetten ZH',
    canton: 'ZH',
  },
  {
    zip: 8355,
    city: 'Aadorf',
    canton: 'TG',
  },
  {
    zip: 8356,
    city: 'Ettenhausen TG',
    canton: 'TG',
  },
  {
    zip: 8357,
    city: 'Guntershausen b. Aadorf',
    canton: 'TG',
  },
  {
    zip: 8360,
    city: 'Wallenwil',
    canton: 'TG',
  },
  {
    zip: 8360,
    city: 'Eschlikon TG',
    canton: 'TG',
  },
  {
    zip: 8362,
    city: 'Balterswil',
    canton: 'TG',
  },
  {
    zip: 8363,
    city: 'Bichelsee',
    canton: 'TG',
  },
  {
    zip: 8370,
    city: 'Busswil TG',
    canton: 'TG',
  },
  {
    zip: 8370,
    city: 'Sirnach',
    canton: 'TG',
  },
  {
    zip: 8372,
    city: 'Wiezikon b. Sirnach',
    canton: 'TG',
  },
  {
    zip: 8374,
    city: 'Oberwangen TG',
    canton: 'TG',
  },
  {
    zip: 8374,
    city: 'Dussnang',
    canton: 'TG',
  },
  {
    zip: 8376,
    city: 'Fischingen',
    canton: 'TG',
  },
  {
    zip: 8376,
    city: 'Au TG',
    canton: 'TG',
  },
  {
    zip: 8400,
    city: 'Winterthur',
    canton: 'ZH',
  },
  {
    zip: 8404,
    city: 'Reutlingen (Winterthur)',
    canton: 'ZH',
  },
  {
    zip: 8404,
    city: 'Stadel (Winterthur)',
    canton: 'ZH',
  },
  {
    zip: 8404,
    city: 'Winterthur',
    canton: 'ZH',
  },
  {
    zip: 8405,
    city: 'Winterthur',
    canton: 'ZH',
  },
  {
    zip: 8406,
    city: 'Winterthur',
    canton: 'ZH',
  },
  {
    zip: 8408,
    city: 'Winterthur',
    canton: 'ZH',
  },
  {
    zip: 8409,
    city: 'Winterthur',
    canton: 'ZH',
  },
  {
    zip: 8412,
    city: 'Aesch (Neftenbach)',
    canton: 'ZH',
  },
  {
    zip: 8412,
    city: 'Riet (Neftenbach)',
    canton: 'ZH',
  },
  {
    zip: 8412,
    city: 'Hünikon (Neftenbach)',
    canton: 'ZH',
  },
  {
    zip: 8413,
    city: 'Neftenbach',
    canton: 'ZH',
  },
  {
    zip: 8414,
    city: 'Buch am Irchel',
    canton: 'ZH',
  },
  {
    zip: 8415,
    city: 'Berg am Irchel',
    canton: 'ZH',
  },
  {
    zip: 8415,
    city: 'Gräslikon',
    canton: 'ZH',
  },
  {
    zip: 8416,
    city: 'Flaach',
    canton: 'ZH',
  },
  {
    zip: 8418,
    city: 'Schlatt b. Winterthur',
    canton: 'ZH',
  },
  {
    zip: 8421,
    city: 'Dättlikon',
    canton: 'ZH',
  },
  {
    zip: 8422,
    city: 'Pfungen',
    canton: 'ZH',
  },
  {
    zip: 8424,
    city: 'Embrach',
    canton: 'ZH',
  },
  {
    zip: 8425,
    city: 'Oberembrach',
    canton: 'ZH',
  },
  {
    zip: 8426,
    city: 'Lufingen',
    canton: 'ZH',
  },
  {
    zip: 8427,
    city: 'Freienstein',
    canton: 'ZH',
  },
  {
    zip: 8427,
    city: 'Rorbas',
    canton: 'ZH',
  },
  {
    zip: 8428,
    city: 'Teufen ZH',
    canton: 'ZH',
  },
  {
    zip: 8442,
    city: 'Hettlingen',
    canton: 'ZH',
  },
  {
    zip: 8444,
    city: 'Henggart',
    canton: 'ZH',
  },
  {
    zip: 8447,
    city: 'Dachsen',
    canton: 'ZH',
  },
  {
    zip: 8450,
    city: 'Andelfingen',
    canton: 'ZH',
  },
  {
    zip: 8451,
    city: 'Kleinandelfingen',
    canton: 'ZH',
  },
  {
    zip: 8452,
    city: 'Adlikon b. Andelfingen',
    canton: 'ZH',
  },
  {
    zip: 8453,
    city: 'Alten',
    canton: 'ZH',
  },
  {
    zip: 8454,
    city: 'Buchberg',
    canton: 'SH',
  },
  {
    zip: 8455,
    city: 'Rüdlingen',
    canton: 'SH',
  },
  {
    zip: 8457,
    city: 'Humlikon',
    canton: 'ZH',
  },
  {
    zip: 8458,
    city: 'Dorf',
    canton: 'ZH',
  },
  {
    zip: 8459,
    city: 'Volken',
    canton: 'ZH',
  },
  {
    zip: 8460,
    city: 'Marthalen',
    canton: 'ZH',
  },
  {
    zip: 8461,
    city: 'Oerlingen',
    canton: 'ZH',
  },
  {
    zip: 8462,
    city: 'Rheinau',
    canton: 'ZH',
  },
  {
    zip: 8463,
    city: 'Benken ZH',
    canton: 'ZH',
  },
  {
    zip: 8464,
    city: 'Ellikon am Rhein',
    canton: 'ZH',
  },
  {
    zip: 8465,
    city: 'Rudolfingen',
    canton: 'ZH',
  },
  {
    zip: 8465,
    city: 'Wildensbuch',
    canton: 'ZH',
  },
  {
    zip: 8466,
    city: 'Trüllikon',
    canton: 'ZH',
  },
  {
    zip: 8467,
    city: 'Truttikon',
    canton: 'ZH',
  },
  {
    zip: 8468,
    city: 'Waltalingen',
    canton: 'ZH',
  },
  {
    zip: 8468,
    city: 'Guntalingen',
    canton: 'ZH',
  },
  {
    zip: 8471,
    city: 'Rutschwil (Dägerlen)',
    canton: 'ZH',
  },
  {
    zip: 8471,
    city: 'Dägerlen',
    canton: 'ZH',
  },
  {
    zip: 8471,
    city: 'Oberwil (Dägerlen)',
    canton: 'ZH',
  },
  {
    zip: 8471,
    city: 'Berg (Dägerlen)',
    canton: 'ZH',
  },
  {
    zip: 8471,
    city: 'Bänk (Dägerlen)',
    canton: 'ZH',
  },
  {
    zip: 8472,
    city: 'Seuzach',
    canton: 'ZH',
  },
  {
    zip: 8474,
    city: 'Dinhard',
    canton: 'ZH',
  },
  {
    zip: 8475,
    city: 'Ossingen',
    canton: 'ZH',
  },
  {
    zip: 8476,
    city: 'Unterstammheim',
    canton: 'ZH',
  },
  {
    zip: 8477,
    city: 'Oberstammheim',
    canton: 'ZH',
  },
  {
    zip: 8478,
    city: 'Thalheim an der Thur',
    canton: 'ZH',
  },
  {
    zip: 8479,
    city: 'Altikon',
    canton: 'ZH',
  },
  {
    zip: 8482,
    city: 'Sennhof (Winterthur)',
    canton: 'ZH',
  },
  {
    zip: 8483,
    city: 'Kollbrunn',
    canton: 'ZH',
  },
  {
    zip: 8484,
    city: 'Neschwil',
    canton: 'ZH',
  },
  {
    zip: 8484,
    city: 'Theilingen',
    canton: 'ZH',
  },
  {
    zip: 8484,
    city: 'Weisslingen',
    canton: 'ZH',
  },
  {
    zip: 8486,
    city: 'Rikon im Tösstal',
    canton: 'ZH',
  },
  {
    zip: 8487,
    city: 'Rämismühle',
    canton: 'ZH',
  },
  {
    zip: 8487,
    city: 'Zell ZH',
    canton: 'ZH',
  },
  {
    zip: 8488,
    city: 'Turbenthal',
    canton: 'ZH',
  },
  {
    zip: 8489,
    city: 'Wildberg',
    canton: 'ZH',
  },
  {
    zip: 8492,
    city: 'Wila',
    canton: 'ZH',
  },
  {
    zip: 8493,
    city: 'Saland',
    canton: 'ZH',
  },
  {
    zip: 8494,
    city: 'Bauma',
    canton: 'ZH',
  },
  {
    zip: 8495,
    city: 'Schmidrüti',
    canton: 'ZH',
  },
  {
    zip: 8496,
    city: 'Steg im Tösstal',
    canton: 'ZH',
  },
  {
    zip: 8497,
    city: 'Fischenthal',
    canton: 'ZH',
  },
  {
    zip: 8498,
    city: 'Gibswil-Ried',
    canton: 'ZH',
  },
  {
    zip: 8499,
    city: 'Sternenberg',
    canton: 'ZH',
  },
  {
    zip: 8500,
    city: 'Frauenfeld',
    canton: 'TG',
  },
  {
    zip: 8500,
    city: 'Gerlikon',
    canton: 'TG',
  },
  {
    zip: 8505,
    city: 'Dettighofen',
    canton: 'TG',
  },
  {
    zip: 8505,
    city: 'Pfyn',
    canton: 'TG',
  },
  {
    zip: 8506,
    city: 'Lanzenneunforn',
    canton: 'TG',
  },
  {
    zip: 8507,
    city: 'Hörhausen',
    canton: 'TG',
  },
  {
    zip: 8508,
    city: 'Homburg',
    canton: 'TG',
  },
  {
    zip: 8512,
    city: 'Lustdorf',
    canton: 'TG',
  },
  {
    zip: 8512,
    city: 'Wetzikon TG',
    canton: 'TG',
  },
  {
    zip: 8512,
    city: 'Thundorf',
    canton: 'TG',
  },
  {
    zip: 8514,
    city: 'Amlikon-Bissegg',
    canton: 'TG',
  },
  {
    zip: 8522,
    city: 'Häuslenen',
    canton: 'TG',
  },
  {
    zip: 8522,
    city: 'Aawangen',
    canton: 'TG',
  },
  {
    zip: 8523,
    city: 'Hagenbuch ZH',
    canton: 'ZH',
  },
  {
    zip: 8524,
    city: 'Buch b. Frauenfeld',
    canton: 'TG',
  },
  {
    zip: 8524,
    city: 'Uesslingen',
    canton: 'TG',
  },
  {
    zip: 8525,
    city: 'Niederneunforn',
    canton: 'TG',
  },
  {
    zip: 8525,
    city: 'Wilen b. Neunforn',
    canton: 'TG',
  },
  {
    zip: 8526,
    city: 'Oberneunforn',
    canton: 'TG',
  },
  {
    zip: 8532,
    city: 'Weiningen TG',
    canton: 'TG',
  },
  {
    zip: 8532,
    city: 'Warth',
    canton: 'TG',
  },
  {
    zip: 8535,
    city: 'Herdern',
    canton: 'TG',
  },
  {
    zip: 8536,
    city: 'Hüttwilen',
    canton: 'TG',
  },
  {
    zip: 8537,
    city: 'Nussbaumen TG',
    canton: 'TG',
  },
  {
    zip: 8537,
    city: 'Uerschhausen',
    canton: 'TG',
  },
  {
    zip: 8542,
    city: 'Wiesendangen',
    canton: 'ZH',
  },
  {
    zip: 8543,
    city: 'Bertschikon',
    canton: 'ZH',
  },
  {
    zip: 8543,
    city: 'Gundetswil',
    canton: 'ZH',
  },
  {
    zip: 8543,
    city: 'Kefikon ZH',
    canton: 'ZH',
  },
  {
    zip: 8544,
    city: 'Attikon',
    canton: 'ZH',
  },
  {
    zip: 8545,
    city: 'Rickenbach ZH',
    canton: 'ZH',
  },
  {
    zip: 8545,
    city: 'Rickenbach Sulz',
    canton: 'ZH',
  },
  {
    zip: 8546,
    city: 'Kefikon TG',
    canton: 'TG',
  },
  {
    zip: 8546,
    city: 'Islikon',
    canton: 'TG',
  },
  {
    zip: 8546,
    city: 'Menzengrüt',
    canton: 'ZH',
  },
  {
    zip: 8547,
    city: 'Gachnang',
    canton: 'TG',
  },
  {
    zip: 8548,
    city: 'Ellikon an der Thur',
    canton: 'ZH',
  },
  {
    zip: 8552,
    city: 'Felben-Wellhausen',
    canton: 'TG',
  },
  {
    zip: 8553,
    city: 'Eschikofen',
    canton: 'TG',
  },
  {
    zip: 8553,
    city: 'Harenwilen',
    canton: 'TG',
  },
  {
    zip: 8553,
    city: 'Mettendorf TG',
    canton: 'TG',
  },
  {
    zip: 8553,
    city: 'Hüttlingen',
    canton: 'TG',
  },
  {
    zip: 8554,
    city: 'Müllheim-Wigoltingen',
    canton: 'TG',
  },
  {
    zip: 8554,
    city: 'Bonau',
    canton: 'TG',
  },
  {
    zip: 8555,
    city: 'Müllheim Dorf',
    canton: 'TG',
  },
  {
    zip: 8556,
    city: 'Engwang',
    canton: 'TG',
  },
  {
    zip: 8556,
    city: 'Illhart',
    canton: 'TG',
  },
  {
    zip: 8556,
    city: 'Lamperswil TG',
    canton: 'TG',
  },
  {
    zip: 8556,
    city: 'Wigoltingen',
    canton: 'TG',
  },
  {
    zip: 8558,
    city: 'Raperswilen',
    canton: 'TG',
  },
  {
    zip: 8560,
    city: 'Märstetten',
    canton: 'TG',
  },
  {
    zip: 8561,
    city: 'Ottoberg',
    canton: 'TG',
  },
  {
    zip: 8564,
    city: 'Lipperswil',
    canton: 'TG',
  },
  {
    zip: 8564,
    city: 'Engwilen',
    canton: 'TG',
  },
  {
    zip: 8564,
    city: 'Wäldi',
    canton: 'TG',
  },
  {
    zip: 8564,
    city: 'Sonterswil',
    canton: 'TG',
  },
  {
    zip: 8564,
    city: 'Wagerswil',
    canton: 'TG',
  },
  {
    zip: 8564,
    city: 'Hattenhausen',
    canton: 'TG',
  },
  {
    zip: 8564,
    city: 'Gunterswilen',
    canton: 'TG',
  },
  {
    zip: 8564,
    city: 'Hefenhausen',
    canton: 'TG',
  },
  {
    zip: 8565,
    city: 'Hugelshofen',
    canton: 'TG',
  },
  {
    zip: 8566,
    city: 'Dotnacht',
    canton: 'TG',
  },
  {
    zip: 8566,
    city: 'Neuwilen',
    canton: 'TG',
  },
  {
    zip: 8566,
    city: 'Ellighausen',
    canton: 'TG',
  },
  {
    zip: 8566,
    city: 'Lippoldswilen',
    canton: 'TG',
  },
  {
    zip: 8570,
    city: 'Weinfelden',
    canton: 'TG',
  },
  {
    zip: 8572,
    city: 'Andhausen',
    canton: 'TG',
  },
  {
    zip: 8572,
    city: 'Graltshausen',
    canton: 'TG',
  },
  {
    zip: 8572,
    city: 'Guntershausen b. Berg',
    canton: 'TG',
  },
  {
    zip: 8572,
    city: 'Berg TG',
    canton: 'TG',
  },
  {
    zip: 8573,
    city: 'Alterswilen',
    canton: 'TG',
  },
  {
    zip: 8573,
    city: 'Altishausen',
    canton: 'TG',
  },
  {
    zip: 8573,
    city: 'Siegershausen',
    canton: 'TG',
  },
  {
    zip: 8574,
    city: 'Illighausen',
    canton: 'TG',
  },
  {
    zip: 8574,
    city: 'Lengwil-Oberhofen',
    canton: 'TG',
  },
  {
    zip: 8575,
    city: 'Istighofen',
    canton: 'TG',
  },
  {
    zip: 8575,
    city: 'Bürglen TG',
    canton: 'TG',
  },
  {
    zip: 8576,
    city: 'Mauren TG',
    canton: 'TG',
  },
  {
    zip: 8577,
    city: 'Toos',
    canton: 'TG',
  },
  {
    zip: 8577,
    city: 'Schönholzerswilen',
    canton: 'TG',
  },
  {
    zip: 8580,
    city: 'Sommeri',
    canton: 'TG',
  },
  {
    zip: 8580,
    city: 'Hagenwil b. Amriswil',
    canton: 'TG',
  },
  {
    zip: 8580,
    city: 'Hefenhofen',
    canton: 'TG',
  },
  {
    zip: 8580,
    city: 'Amriswil',
    canton: 'TG',
  },
  {
    zip: 8581,
    city: 'Schocherswil',
    canton: 'TG',
  },
  {
    zip: 8582,
    city: 'Dozwil',
    canton: 'TG',
  },
  {
    zip: 8583,
    city: 'Götighofen',
    canton: 'TG',
  },
  {
    zip: 8583,
    city: 'Donzhausen',
    canton: 'TG',
  },
  {
    zip: 8583,
    city: 'Sulgen',
    canton: 'TG',
  },
  {
    zip: 8584,
    city: 'Leimbach TG',
    canton: 'TG',
  },
  {
    zip: 8584,
    city: 'Opfershofen TG',
    canton: 'TG',
  },
  {
    zip: 8585,
    city: 'Happerswil',
    canton: 'TG',
  },
  {
    zip: 8585,
    city: 'Eggethof',
    canton: 'TG',
  },
  {
    zip: 8585,
    city: 'Zuben',
    canton: 'TG',
  },
  {
    zip: 8585,
    city: 'Schönenbaumgarten',
    canton: 'TG',
  },
  {
    zip: 8585,
    city: 'Herrenhof',
    canton: 'TG',
  },
  {
    zip: 8585,
    city: 'Birwinken',
    canton: 'TG',
  },
  {
    zip: 8585,
    city: 'Klarsreuti',
    canton: 'TG',
  },
  {
    zip: 8585,
    city: 'Mattwil',
    canton: 'TG',
  },
  {
    zip: 8585,
    city: 'Langrickenbach',
    canton: 'TG',
  },
  {
    zip: 8586,
    city: 'Andwil TG',
    canton: 'TG',
  },
  {
    zip: 8586,
    city: 'Kümmertshausen',
    canton: 'TG',
  },
  {
    zip: 8586,
    city: 'Riedt b. Erlen',
    canton: 'TG',
  },
  {
    zip: 8586,
    city: 'Buchackern',
    canton: 'TG',
  },
  {
    zip: 8586,
    city: 'Engishofen',
    canton: 'TG',
  },
  {
    zip: 8586,
    city: 'Ennetaach',
    canton: 'TG',
  },
  {
    zip: 8586,
    city: 'Buch b. Kümmertshausen',
    canton: 'TG',
  },
  {
    zip: 8586,
    city: 'Erlen',
    canton: 'TG',
  },
  {
    zip: 8587,
    city: 'Oberaach',
    canton: 'TG',
  },
  {
    zip: 8588,
    city: 'Zihlschlacht',
    canton: 'TG',
  },
  {
    zip: 8589,
    city: 'Sitterdorf',
    canton: 'TG',
  },
  {
    zip: 8590,
    city: 'Romanshorn',
    canton: 'TG',
  },
  {
    zip: 8592,
    city: 'Uttwil',
    canton: 'TG',
  },
  {
    zip: 8593,
    city: 'Kesswil',
    canton: 'TG',
  },
  {
    zip: 8594,
    city: 'Güttingen',
    canton: 'TG',
  },
  {
    zip: 8595,
    city: 'Altnau',
    canton: 'TG',
  },
  {
    zip: 8596,
    city: 'Münsterlingen',
    canton: 'TG',
  },
  {
    zip: 8596,
    city: 'Scherzingen',
    canton: 'TG',
  },
  {
    zip: 8597,
    city: 'Landschlacht',
    canton: 'TG',
  },
  {
    zip: 8598,
    city: 'Bottighofen',
    canton: 'TG',
  },
  {
    zip: 8599,
    city: 'Salmsach',
    canton: 'TG',
  },
  {
    zip: 8600,
    city: 'Dübendorf',
    canton: 'ZH',
  },
  {
    zip: 8602,
    city: 'Wangen b. Dübendorf',
    canton: 'ZH',
  },
  {
    zip: 8603,
    city: 'Schwerzenbach',
    canton: 'ZH',
  },
  {
    zip: 8604,
    city: 'Volketswil',
    canton: 'ZH',
  },
  {
    zip: 8605,
    city: 'Gutenswil',
    canton: 'ZH',
  },
  {
    zip: 8606,
    city: 'Nänikon',
    canton: 'ZH',
  },
  {
    zip: 8606,
    city: 'Greifensee',
    canton: 'ZH',
  },
  {
    zip: 8607,
    city: 'Aathal-Seegräben',
    canton: 'ZH',
  },
  {
    zip: 8608,
    city: 'Bubikon',
    canton: 'ZH',
  },
  {
    zip: 8610,
    city: 'Uster',
    canton: 'ZH',
  },
  {
    zip: 8614,
    city: 'Sulzbach',
    canton: 'ZH',
  },
  {
    zip: 8614,
    city: 'Bertschikon (Gossau ZH)',
    canton: 'ZH',
  },
  {
    zip: 8615,
    city: 'Wermatswil',
    canton: 'ZH',
  },
  {
    zip: 8615,
    city: 'Freudwil',
    canton: 'ZH',
  },
  {
    zip: 8616,
    city: 'Riedikon',
    canton: 'ZH',
  },
  {
    zip: 8617,
    city: 'Mönchaltorf',
    canton: 'ZH',
  },
  {
    zip: 8618,
    city: 'Oetwil am See',
    canton: 'ZH',
  },
  {
    zip: 8620,
    city: 'Wetzikon ZH',
    canton: 'ZH',
  },
  {
    zip: 8623,
    city: 'Wetzikon ZH',
    canton: 'ZH',
  },
  {
    zip: 8624,
    city: 'Grüt (Gossau ZH)',
    canton: 'ZH',
  },
  {
    zip: 8625,
    city: 'Gossau ZH',
    canton: 'ZH',
  },
  {
    zip: 8626,
    city: 'Ottikon (Gossau ZH)',
    canton: 'ZH',
  },
  {
    zip: 8627,
    city: 'Grüningen',
    canton: 'ZH',
  },
  {
    zip: 8630,
    city: 'Rüti ZH',
    canton: 'ZH',
  },
  {
    zip: 8632,
    city: 'Tann',
    canton: 'ZH',
  },
  {
    zip: 8633,
    city: 'Wolfhausen',
    canton: 'ZH',
  },
  {
    zip: 8634,
    city: 'Hombrechtikon',
    canton: 'ZH',
  },
  {
    zip: 8635,
    city: 'Dürnten',
    canton: 'ZH',
  },
  {
    zip: 8636,
    city: 'Wald ZH',
    canton: 'ZH',
  },
  {
    zip: 8637,
    city: 'Laupen ZH',
    canton: 'ZH',
  },
  {
    zip: 8638,
    city: 'Goldingen',
    canton: 'SG',
  },
  {
    zip: 8639,
    city: 'Faltigberg',
    canton: 'ZH',
  },
  {
    zip: 8640,
    city: 'Rapperswil SG',
    canton: 'SG',
  },
  {
    zip: 8640,
    city: 'Hurden',
    canton: 'SZ',
  },
  {
    zip: 8645,
    city: 'Jona',
    canton: 'SG',
  },
  {
    zip: 8646,
    city: 'Wagen',
    canton: 'SG',
  },
  {
    zip: 8700,
    city: 'Küsnacht ZH',
    canton: 'ZH',
  },
  {
    zip: 8702,
    city: 'Zollikon',
    canton: 'ZH',
  },
  {
    zip: 8703,
    city: 'Erlenbach ZH',
    canton: 'ZH',
  },
  {
    zip: 8704,
    city: 'Herrliberg',
    canton: 'ZH',
  },
  {
    zip: 8706,
    city: 'Meilen',
    canton: 'ZH',
  },
  {
    zip: 8707,
    city: 'Uetikon am See',
    canton: 'ZH',
  },
  {
    zip: 8708,
    city: 'Männedorf',
    canton: 'ZH',
  },
  {
    zip: 8712,
    city: 'Stäfa',
    canton: 'ZH',
  },
  {
    zip: 8713,
    city: 'Uerikon',
    canton: 'ZH',
  },
  {
    zip: 8714,
    city: 'Feldbach',
    canton: 'ZH',
  },
  {
    zip: 8715,
    city: 'Bollingen',
    canton: 'SG',
  },
  {
    zip: 8716,
    city: 'Schmerikon',
    canton: 'SG',
  },
  {
    zip: 8717,
    city: 'Benken SG',
    canton: 'SG',
  },
  {
    zip: 8718,
    city: 'Schänis',
    canton: 'SG',
  },
  {
    zip: 8722,
    city: 'Kaltbrunn',
    canton: 'SG',
  },
  {
    zip: 8723,
    city: 'Rufi',
    canton: 'SG',
  },
  {
    zip: 8725,
    city: 'Gebertingen',
    canton: 'SG',
  },
  {
    zip: 8725,
    city: 'Ernetschwil',
    canton: 'SG',
  },
  {
    zip: 8726,
    city: 'Ricken SG',
    canton: 'SG',
  },
  {
    zip: 8727,
    city: 'Walde SG',
    canton: 'SG',
  },
  {
    zip: 8730,
    city: 'Uznach',
    canton: 'SG',
  },
  {
    zip: 8732,
    city: 'Neuhaus SG',
    canton: 'SG',
  },
  {
    zip: 8733,
    city: 'Eschenbach SG',
    canton: 'SG',
  },
  {
    zip: 8734,
    city: 'Ermenswil',
    canton: 'SG',
  },
  {
    zip: 8735,
    city: 'Rüeterswil',
    canton: 'SG',
  },
  {
    zip: 8735,
    city: 'St. Gallenkappel',
    canton: 'SG',
  },
  {
    zip: 8737,
    city: 'Gommiswald',
    canton: 'SG',
  },
  {
    zip: 8738,
    city: 'Uetliburg SG',
    canton: 'SG',
  },
  {
    zip: 8739,
    city: 'Rieden SG',
    canton: 'SG',
  },
  {
    zip: 8750,
    city: 'Klöntal',
    canton: 'GL',
  },
  {
    zip: 8750,
    city: 'Glarus',
    canton: 'GL',
  },
  {
    zip: 8750,
    city: 'Riedern',
    canton: 'GL',
  },
  {
    zip: 8751,
    city: 'Urnerboden',
    canton: 'UR',
  },
  {
    zip: 8752,
    city: 'Näfels',
    canton: 'GL',
  },
  {
    zip: 8753,
    city: 'Mollis',
    canton: 'GL',
  },
  {
    zip: 8754,
    city: 'Netstal',
    canton: 'GL',
  },
  {
    zip: 8755,
    city: 'Ennenda',
    canton: 'GL',
  },
  {
    zip: 8756,
    city: 'Mitlödi',
    canton: 'GL',
  },
  {
    zip: 8757,
    city: 'Filzbach',
    canton: 'GL',
  },
  {
    zip: 8758,
    city: 'Obstalden',
    canton: 'GL',
  },
  {
    zip: 8762,
    city: 'Schwändi b. Schwanden',
    canton: 'GL',
  },
  {
    zip: 8762,
    city: 'Sool',
    canton: 'GL',
  },
  {
    zip: 8762,
    city: 'Schwanden GL',
    canton: 'GL',
  },
  {
    zip: 8765,
    city: 'Engi',
    canton: 'GL',
  },
  {
    zip: 8766,
    city: 'Matt',
    canton: 'GL',
  },
  {
    zip: 8767,
    city: 'Elm',
    canton: 'GL',
  },
  {
    zip: 8772,
    city: 'Nidfurn',
    canton: 'GL',
  },
  {
    zip: 8773,
    city: 'Haslen GL',
    canton: 'GL',
  },
  {
    zip: 8774,
    city: 'Leuggelbach',
    canton: 'GL',
  },
  {
    zip: 8775,
    city: 'Luchsingen',
    canton: 'GL',
  },
  {
    zip: 8775,
    city: 'Hätzingen',
    canton: 'GL',
  },
  {
    zip: 8777,
    city: 'Diesbach GL',
    canton: 'GL',
  },
  {
    zip: 8777,
    city: 'Betschwanden',
    canton: 'GL',
  },
  {
    zip: 8782,
    city: 'Rüti GL',
    canton: 'GL',
  },
  {
    zip: 8783,
    city: 'Linthal',
    canton: 'GL',
  },
  {
    zip: 8784,
    city: 'Braunwald',
    canton: 'GL',
  },
  {
    zip: 8800,
    city: 'Thalwil',
    canton: 'ZH',
  },
  {
    zip: 8802,
    city: 'Kilchberg ZH',
    canton: 'ZH',
  },
  {
    zip: 8803,
    city: 'Rüschlikon',
    canton: 'ZH',
  },
  {
    zip: 8804,
    city: 'Au ZH',
    canton: 'ZH',
  },
  {
    zip: 8805,
    city: 'Richterswil',
    canton: 'ZH',
  },
  {
    zip: 8806,
    city: 'Bäch SZ',
    canton: 'SZ',
  },
  {
    zip: 8807,
    city: 'Freienbach',
    canton: 'SZ',
  },
  {
    zip: 8808,
    city: 'Pfäffikon SZ',
    canton: 'SZ',
  },
  {
    zip: 8810,
    city: 'Horgen',
    canton: 'ZH',
  },
  {
    zip: 8815,
    city: 'Horgenberg',
    canton: 'ZH',
  },
  {
    zip: 8816,
    city: 'Hirzel',
    canton: 'ZH',
  },
  {
    zip: 8820,
    city: 'Wädenswil',
    canton: 'ZH',
  },
  {
    zip: 8824,
    city: 'Schönenberg ZH',
    canton: 'ZH',
  },
  {
    zip: 8825,
    city: 'Hütten',
    canton: 'ZH',
  },
  {
    zip: 8832,
    city: 'Wilen b. Wollerau',
    canton: 'SZ',
  },
  {
    zip: 8832,
    city: 'Wollerau',
    canton: 'SZ',
  },
  {
    zip: 8833,
    city: 'Samstagern',
    canton: 'ZH',
  },
  {
    zip: 8834,
    city: 'Schindellegi',
    canton: 'SZ',
  },
  {
    zip: 8835,
    city: 'Feusisberg',
    canton: 'SZ',
  },
  {
    zip: 8836,
    city: 'Bennau',
    canton: 'SZ',
  },
  {
    zip: 8840,
    city: 'Trachslau',
    canton: 'SZ',
  },
  {
    zip: 8840,
    city: 'Einsiedeln',
    canton: 'SZ',
  },
  {
    zip: 8841,
    city: 'Gross',
    canton: 'SZ',
  },
  {
    zip: 8842,
    city: 'Unteriberg',
    canton: 'SZ',
  },
  {
    zip: 8843,
    city: 'Oberiberg',
    canton: 'SZ',
  },
  {
    zip: 8844,
    city: 'Euthal',
    canton: 'SZ',
  },
  {
    zip: 8845,
    city: 'Studen SZ',
    canton: 'SZ',
  },
  {
    zip: 8846,
    city: 'Willerzell',
    canton: 'SZ',
  },
  {
    zip: 8847,
    city: 'Egg SZ',
    canton: 'SZ',
  },
  {
    zip: 8849,
    city: 'Alpthal',
    canton: 'SZ',
  },
  {
    zip: 8852,
    city: 'Altendorf',
    canton: 'SZ',
  },
  {
    zip: 8853,
    city: 'Lachen SZ',
    canton: 'SZ',
  },
  {
    zip: 8854,
    city: 'Siebnen',
    canton: 'SZ',
  },
  {
    zip: 8854,
    city: 'Galgenen',
    canton: 'SZ',
  },
  {
    zip: 8855,
    city: 'Wangen SZ',
    canton: 'SZ',
  },
  {
    zip: 8856,
    city: 'Tuggen',
    canton: 'SZ',
  },
  {
    zip: 8857,
    city: 'Vorderthal',
    canton: 'SZ',
  },
  {
    zip: 8858,
    city: 'Innerthal',
    canton: 'SZ',
  },
  {
    zip: 8862,
    city: 'Schübelbach',
    canton: 'SZ',
  },
  {
    zip: 8863,
    city: 'Buttikon SZ',
    canton: 'SZ',
  },
  {
    zip: 8864,
    city: 'Reichenburg',
    canton: 'SZ',
  },
  {
    zip: 8865,
    city: 'Bilten',
    canton: 'GL',
  },
  {
    zip: 8866,
    city: 'Ziegelbrücke',
    canton: 'GL',
  },
  {
    zip: 8867,
    city: 'Niederurnen',
    canton: 'GL',
  },
  {
    zip: 8868,
    city: 'Oberurnen',
    canton: 'GL',
  },
  {
    zip: 8872,
    city: 'Weesen',
    canton: 'SG',
  },
  {
    zip: 8873,
    city: 'Amden',
    canton: 'SG',
  },
  {
    zip: 8874,
    city: 'Mühlehorn',
    canton: 'GL',
  },
  {
    zip: 8877,
    city: 'Murg',
    canton: 'SG',
  },
  {
    zip: 8878,
    city: 'Quinten',
    canton: 'SG',
  },
  {
    zip: 8880,
    city: 'Walenstadt',
    canton: 'SG',
  },
  {
    zip: 8881,
    city: 'Knoblisbühl',
    canton: 'SG',
  },
  {
    zip: 8881,
    city: 'Tscherlach',
    canton: 'SG',
  },
  {
    zip: 8881,
    city: 'Walenstadtberg',
    canton: 'SG',
  },
  {
    zip: 8882,
    city: 'Unterterzen',
    canton: 'SG',
  },
  {
    zip: 8883,
    city: 'Quarten',
    canton: 'SG',
  },
  {
    zip: 8884,
    city: 'Oberterzen',
    canton: 'SG',
  },
  {
    zip: 8885,
    city: 'Mols',
    canton: 'SG',
  },
  {
    zip: 8886,
    city: 'Mädris-Vermol',
    canton: 'SG',
  },
  {
    zip: 8887,
    city: 'Mels',
    canton: 'SG',
  },
  {
    zip: 8888,
    city: 'Heiligkreuz (Mels)',
    canton: 'SG',
  },
  {
    zip: 8889,
    city: 'Plons',
    canton: 'SG',
  },
  {
    zip: 8890,
    city: 'Flums',
    canton: 'SG',
  },
  {
    zip: 8892,
    city: 'Berschis',
    canton: 'SG',
  },
  {
    zip: 8893,
    city: 'Flums Hochwiese',
    canton: 'SG',
  },
  {
    zip: 8894,
    city: 'Flumserberg Saxli',
    canton: 'SG',
  },
  {
    zip: 8895,
    city: 'Flumserberg Portels',
    canton: 'SG',
  },
  {
    zip: 8896,
    city: 'Flumserberg Bergheim',
    canton: 'SG',
  },
  {
    zip: 8897,
    city: 'Flumserberg Tannenheim',
    canton: 'SG',
  },
  {
    zip: 8898,
    city: 'Flumserberg Tannenbodenalp',
    canton: 'SG',
  },
  {
    zip: 8902,
    city: 'Urdorf',
    canton: 'ZH',
  },
  {
    zip: 8903,
    city: 'Birmensdorf ZH',
    canton: 'ZH',
  },
  {
    zip: 8904,
    city: 'Aesch ZH',
    canton: 'ZH',
  },
  {
    zip: 8905,
    city: 'Islisberg',
    canton: 'AG',
  },
  {
    zip: 8905,
    city: 'Arni AG',
    canton: 'AG',
  },
  {
    zip: 8906,
    city: 'Bonstetten',
    canton: 'ZH',
  },
  {
    zip: 8907,
    city: 'Wettswil',
    canton: 'ZH',
  },
  {
    zip: 8908,
    city: 'Hedingen',
    canton: 'ZH',
  },
  {
    zip: 8909,
    city: 'Zwillikon',
    canton: 'ZH',
  },
  {
    zip: 8910,
    city: 'Affoltern am Albis',
    canton: 'ZH',
  },
  {
    zip: 8911,
    city: 'Rifferswil',
    canton: 'ZH',
  },
  {
    zip: 8912,
    city: 'Obfelden',
    canton: 'ZH',
  },
  {
    zip: 8913,
    city: 'Ottenbach',
    canton: 'ZH',
  },
  {
    zip: 8914,
    city: 'Aeugstertal',
    canton: 'ZH',
  },
  {
    zip: 8914,
    city: 'Aeugst am Albis',
    canton: 'ZH',
  },
  {
    zip: 8915,
    city: 'Hausen am Albis',
    canton: 'ZH',
  },
  {
    zip: 8916,
    city: 'Jonen',
    canton: 'AG',
  },
  {
    zip: 8917,
    city: 'Oberlunkhofen',
    canton: 'AG',
  },
  {
    zip: 8918,
    city: 'Unterlunkhofen',
    canton: 'AG',
  },
  {
    zip: 8919,
    city: 'Rottenschwil',
    canton: 'AG',
  },
  {
    zip: 8925,
    city: 'Ebertswil',
    canton: 'ZH',
  },
  {
    zip: 8926,
    city: 'Hauptikon',
    canton: 'ZH',
  },
  {
    zip: 8926,
    city: 'Uerzlikon',
    canton: 'ZH',
  },
  {
    zip: 8926,
    city: 'Kappel am Albis',
    canton: 'ZH',
  },
  {
    zip: 8932,
    city: 'Mettmenstetten',
    canton: 'ZH',
  },
  {
    zip: 8933,
    city: 'Maschwanden',
    canton: 'ZH',
  },
  {
    zip: 8934,
    city: 'Knonau',
    canton: 'ZH',
  },
  {
    zip: 8942,
    city: 'Oberrieden',
    canton: 'ZH',
  },
  {
    zip: 8951,
    city: 'Fahrweid',
    canton: 'ZH',
  },
  {
    zip: 8952,
    city: 'Schlieren',
    canton: 'ZH',
  },
  {
    zip: 8953,
    city: 'Dietikon',
    canton: 'ZH',
  },
  {
    zip: 8954,
    city: 'Geroldswil',
    canton: 'ZH',
  },
  {
    zip: 8955,
    city: 'Oetwil an der Limmat',
    canton: 'ZH',
  },
  {
    zip: 8956,
    city: 'Killwangen',
    canton: 'AG',
  },
  {
    zip: 8957,
    city: 'Spreitenbach',
    canton: 'AG',
  },
  {
    zip: 8962,
    city: 'Bergdietikon',
    canton: 'AG',
  },
  {
    zip: 8964,
    city: 'Rudolfstetten',
    canton: 'AG',
  },
  {
    zip: 8965,
    city: 'Berikon',
    canton: 'AG',
  },
  {
    zip: 8966,
    city: 'Oberwil-Lieli',
    canton: 'AG',
  },
  {
    zip: 8967,
    city: 'Widen',
    canton: 'AG',
  },
  {
    zip: 9000,
    city: 'St. Gallen',
    canton: 'SG',
  },
  {
    zip: 9007,
    city: 'St. Gallen',
    canton: 'SG',
  },
  {
    zip: 9008,
    city: 'St. Gallen',
    canton: 'SG',
  },
  {
    zip: 9010,
    city: 'St. Gallen',
    canton: 'SG',
  },
  {
    zip: 9011,
    city: 'St. Gallen',
    canton: 'SG',
  },
  {
    zip: 9012,
    city: 'St. Gallen',
    canton: 'SG',
  },
  {
    zip: 9014,
    city: 'St. Gallen',
    canton: 'SG',
  },
  {
    zip: 9015,
    city: 'St. Gallen',
    canton: 'SG',
  },
  {
    zip: 9016,
    city: 'St. Gallen',
    canton: 'SG',
  },
  {
    zip: 9030,
    city: 'Abtwil SG',
    canton: 'SG',
  },
  {
    zip: 9032,
    city: 'Engelburg',
    canton: 'SG',
  },
  {
    zip: 9033,
    city: 'Untereggen',
    canton: 'SG',
  },
  {
    zip: 9034,
    city: 'Eggersriet',
    canton: 'SG',
  },
  {
    zip: 9035,
    city: 'Grub AR',
    canton: 'AR',
  },
  {
    zip: 9036,
    city: 'Grub SG',
    canton: 'SG',
  },
  {
    zip: 9037,
    city: 'Speicherschwendi',
    canton: 'AR',
  },
  {
    zip: 9038,
    city: 'Rehetobel',
    canton: 'AR',
  },
  {
    zip: 9042,
    city: 'Speicher',
    canton: 'AR',
  },
  {
    zip: 9043,
    city: 'Trogen',
    canton: 'AR',
  },
  {
    zip: 9044,
    city: 'Wald AR',
    canton: 'AR',
  },
  {
    zip: 9050,
    city: 'Appenzell Eggerstanden',
    canton: 'AI',
  },
  {
    zip: 9050,
    city: 'Appenzell Enggenhütten',
    canton: 'AI',
  },
  {
    zip: 9050,
    city: 'Appenzell Meistersrüte',
    canton: 'AI',
  },
  {
    zip: 9050,
    city: 'Appenzell Steinegg',
    canton: 'AI',
  },
  {
    zip: 9050,
    city: 'Appenzell Schlatt',
    canton: 'AI',
  },
  {
    zip: 9050,
    city: 'Appenzell',
    canton: 'AI',
  },
  {
    zip: 9052,
    city: 'Niederteufen',
    canton: 'AR',
  },
  {
    zip: 9053,
    city: 'Teufen AR',
    canton: 'AR',
  },
  {
    zip: 9054,
    city: 'Haslen AI',
    canton: 'AI',
  },
  {
    zip: 9055,
    city: 'Bühler',
    canton: 'AR',
  },
  {
    zip: 9056,
    city: 'Gais',
    canton: 'AR',
  },
  {
    zip: 9057,
    city: 'Schwende',
    canton: 'AI',
  },
  {
    zip: 9057,
    city: 'Wasserauen',
    canton: 'AI',
  },
  {
    zip: 9057,
    city: 'Weissbad',
    canton: 'AI',
  },
  {
    zip: 9058,
    city: 'Brülisau',
    canton: 'AI',
  },
  {
    zip: 9062,
    city: 'Lustmühle',
    canton: 'AR',
  },
  {
    zip: 9063,
    city: 'Stein AR',
    canton: 'AR',
  },
  {
    zip: 9064,
    city: 'Hundwil',
    canton: 'AR',
  },
  {
    zip: 9100,
    city: 'Herisau',
    canton: 'AR',
  },
  {
    zip: 9103,
    city: 'Schwellbrunn',
    canton: 'AR',
  },
  {
    zip: 9104,
    city: 'Waldstatt',
    canton: 'AR',
  },
  {
    zip: 9105,
    city: 'Schönengrund',
    canton: 'AR',
  },
  {
    zip: 9107,
    city: 'Urnäsch',
    canton: 'AR',
  },
  {
    zip: 9108,
    city: 'Gontenbad',
    canton: 'AI',
  },
  {
    zip: 9108,
    city: 'Jakobsbad',
    canton: 'AI',
  },
  {
    zip: 9108,
    city: 'Gonten',
    canton: 'AI',
  },
  {
    zip: 9112,
    city: 'Schachen b. Herisau',
    canton: 'AR',
  },
  {
    zip: 9113,
    city: 'Degersheim',
    canton: 'SG',
  },
  {
    zip: 9114,
    city: 'Hoffeld',
    canton: 'SG',
  },
  {
    zip: 9115,
    city: 'Dicken',
    canton: 'SG',
  },
  {
    zip: 9116,
    city: 'Wolfertswil',
    canton: 'SG',
  },
  {
    zip: 9122,
    city: 'Ebersol',
    canton: 'SG',
  },
  {
    zip: 9122,
    city: 'Mogelsberg',
    canton: 'SG',
  },
  {
    zip: 9123,
    city: 'Nassen',
    canton: 'SG',
  },
  {
    zip: 9125,
    city: 'Brunnadern',
    canton: 'SG',
  },
  {
    zip: 9126,
    city: 'Necker',
    canton: 'SG',
  },
  {
    zip: 9127,
    city: 'St. Peterzell',
    canton: 'SG',
  },
  {
    zip: 9200,
    city: 'Gossau SG',
    canton: 'SG',
  },
  {
    zip: 9203,
    city: 'Niederwil SG',
    canton: 'SG',
  },
  {
    zip: 9204,
    city: 'Andwil SG',
    canton: 'SG',
  },
  {
    zip: 9205,
    city: 'Waldkirch',
    canton: 'SG',
  },
  {
    zip: 9212,
    city: 'Arnegg',
    canton: 'SG',
  },
  {
    zip: 9213,
    city: 'Hauptwil',
    canton: 'TG',
  },
  {
    zip: 9214,
    city: 'Kradolf',
    canton: 'TG',
  },
  {
    zip: 9215,
    city: 'Schönenberg an der Thur',
    canton: 'TG',
  },
  {
    zip: 9215,
    city: 'Buhwil',
    canton: 'TG',
  },
  {
    zip: 9216,
    city: 'Heldswil',
    canton: 'TG',
  },
  {
    zip: 9216,
    city: 'Hohentannen',
    canton: 'TG',
  },
  {
    zip: 9217,
    city: 'Neukirch an der Thur',
    canton: 'TG',
  },
  {
    zip: 9220,
    city: 'Bischofszell',
    canton: 'TG',
  },
  {
    zip: 9223,
    city: 'Schweizersholz',
    canton: 'TG',
  },
  {
    zip: 9223,
    city: 'Halden',
    canton: 'TG',
  },
  {
    zip: 9225,
    city: 'Wilen (Gottshaus)',
    canton: 'TG',
  },
  {
    zip: 9225,
    city: 'St. Pelagiberg',
    canton: 'TG',
  },
  {
    zip: 9230,
    city: 'Flawil',
    canton: 'SG',
  },
  {
    zip: 9231,
    city: 'Egg (Flawil)',
    canton: 'SG',
  },
  {
    zip: 9240,
    city: 'Niederglatt SG',
    canton: 'SG',
  },
  {
    zip: 9240,
    city: 'Uzwil',
    canton: 'SG',
  },
  {
    zip: 9242,
    city: 'Oberuzwil',
    canton: 'SG',
  },
  {
    zip: 9243,
    city: 'Jonschwil',
    canton: 'SG',
  },
  {
    zip: 9244,
    city: 'Niederuzwil',
    canton: 'SG',
  },
  {
    zip: 9245,
    city: 'Sonnental',
    canton: 'SG',
  },
  {
    zip: 9245,
    city: 'Oberbüren',
    canton: 'SG',
  },
  {
    zip: 9246,
    city: 'Niederbüren',
    canton: 'SG',
  },
  {
    zip: 9247,
    city: 'Henau',
    canton: 'SG',
  },
  {
    zip: 9248,
    city: 'Bichwil',
    canton: 'SG',
  },
  {
    zip: 9249,
    city: 'Oberstetten',
    canton: 'SG',
  },
  {
    zip: 9249,
    city: 'Niederstetten',
    canton: 'SG',
  },
  {
    zip: 9249,
    city: 'Algetshausen',
    canton: 'SG',
  },
  {
    zip: 9300,
    city: 'Wittenbach',
    canton: 'SG',
  },
  {
    zip: 9304,
    city: 'Bernhardzell',
    canton: 'SG',
  },
  {
    zip: 9305,
    city: 'Berg SG',
    canton: 'SG',
  },
  {
    zip: 9306,
    city: 'Freidorf TG',
    canton: 'TG',
  },
  {
    zip: 9308,
    city: 'Lömmenschwil',
    canton: 'SG',
  },
  {
    zip: 9312,
    city: 'Häggenschwil',
    canton: 'SG',
  },
  {
    zip: 9313,
    city: 'Muolen',
    canton: 'SG',
  },
  {
    zip: 9314,
    city: 'Steinebrunn',
    canton: 'TG',
  },
  {
    zip: 9315,
    city: 'Winden',
    canton: 'TG',
  },
  {
    zip: 9315,
    city: 'Neukirch (Egnach)',
    canton: 'TG',
  },
  {
    zip: 9320,
    city: 'Frasnacht',
    canton: 'TG',
  },
  {
    zip: 9320,
    city: 'Stachen',
    canton: 'TG',
  },
  {
    zip: 9320,
    city: 'Arbon',
    canton: 'TG',
  },
  {
    zip: 9322,
    city: 'Egnach',
    canton: 'TG',
  },
  {
    zip: 9323,
    city: 'Steinach',
    canton: 'SG',
  },
  {
    zip: 9325,
    city: 'Roggwil TG',
    canton: 'TG',
  },
  {
    zip: 9326,
    city: 'Horn',
    canton: 'TG',
  },
  {
    zip: 9327,
    city: 'Tübach',
    canton: 'SG',
  },
  {
    zip: 9400,
    city: 'Rorschach',
    canton: 'SG',
  },
  {
    zip: 9402,
    city: 'Mörschwil',
    canton: 'SG',
  },
  {
    zip: 9403,
    city: 'Goldach',
    canton: 'SG',
  },
  {
    zip: 9404,
    city: 'Rorschacherberg',
    canton: 'SG',
  },
  {
    zip: 9405,
    city: 'Wienacht-Tobel',
    canton: 'AR',
  },
  {
    zip: 9410,
    city: 'Heiden',
    canton: 'AR',
  },
  {
    zip: 9411,
    city: 'Reute AR',
    canton: 'AR',
  },
  {
    zip: 9413,
    city: 'Oberegg',
    canton: 'AI',
  },
  {
    zip: 9414,
    city: 'Schachen b. Reute',
    canton: 'AR',
  },
  {
    zip: 9422,
    city: 'Staad SG',
    canton: 'SG',
  },
  {
    zip: 9423,
    city: 'Altenrhein',
    canton: 'SG',
  },
  {
    zip: 9424,
    city: 'Rheineck',
    canton: 'SG',
  },
  {
    zip: 9425,
    city: 'Thal',
    canton: 'SG',
  },
  {
    zip: 9426,
    city: 'Lutzenberg',
    canton: 'AR',
  },
  {
    zip: 9427,
    city: 'Zelg (Wolfhalden)',
    canton: 'AR',
  },
  {
    zip: 9427,
    city: 'Wolfhalden',
    canton: 'AR',
  },
  {
    zip: 9428,
    city: 'Walzenhausen',
    canton: 'AR',
  },
  {
    zip: 9430,
    city: 'St. Margrethen SG',
    canton: 'SG',
  },
  {
    zip: 9434,
    city: 'Au SG',
    canton: 'SG',
  },
  {
    zip: 9435,
    city: 'Heerbrugg',
    canton: 'SG',
  },
  {
    zip: 9436,
    city: 'Balgach',
    canton: 'SG',
  },
  {
    zip: 9437,
    city: 'Marbach SG',
    canton: 'SG',
  },
  {
    zip: 9442,
    city: 'Büriswilen',
    canton: 'AI',
  },
  {
    zip: 9442,
    city: 'Berneck',
    canton: 'SG',
  },
  {
    zip: 9443,
    city: 'Widnau',
    canton: 'SG',
  },
  {
    zip: 9444,
    city: 'Diepoldsau',
    canton: 'SG',
  },
  {
    zip: 9445,
    city: 'Rebstein',
    canton: 'SG',
  },
  {
    zip: 9450,
    city: 'Lüchingen',
    canton: 'SG',
  },
  {
    zip: 9450,
    city: 'Altstätten SG',
    canton: 'SG',
  },
  {
    zip: 9451,
    city: 'Kriessern',
    canton: 'SG',
  },
  {
    zip: 9452,
    city: 'Hinterforst',
    canton: 'SG',
  },
  {
    zip: 9453,
    city: 'Eichberg',
    canton: 'SG',
  },
  {
    zip: 9462,
    city: 'Montlingen',
    canton: 'SG',
  },
  {
    zip: 9463,
    city: 'Oberriet SG',
    canton: 'SG',
  },
  {
    zip: 9464,
    city: 'Lienz',
    canton: 'SG',
  },
  {
    zip: 9464,
    city: 'Rüthi (Rheintal)',
    canton: 'SG',
  },
  {
    zip: 9465,
    city: 'Salez',
    canton: 'SG',
  },
  {
    zip: 9466,
    city: 'Sennwald',
    canton: 'SG',
  },
  {
    zip: 9467,
    city: 'Frümsen',
    canton: 'SG',
  },
  {
    zip: 9468,
    city: 'Sax',
    canton: 'SG',
  },
  {
    zip: 9469,
    city: 'Haag (Rheintal)',
    canton: 'SG',
  },
  {
    zip: 9470,
    city: 'Buchs SG',
    canton: 'SG',
  },
  {
    zip: 9470,
    city: 'Werdenberg',
    canton: 'SG',
  },
  {
    zip: 9472,
    city: 'Grabserberg',
    canton: 'SG',
  },
  {
    zip: 9472,
    city: 'Grabs',
    canton: 'SG',
  },
  {
    zip: 9473,
    city: 'Gams',
    canton: 'SG',
  },
  {
    zip: 9475,
    city: 'Sevelen',
    canton: 'SG',
  },
  {
    zip: 9476,
    city: 'Fontnas',
    canton: 'SG',
  },
  {
    zip: 9476,
    city: 'Weite',
    canton: 'SG',
  },
  {
    zip: 9477,
    city: 'Trübbach',
    canton: 'SG',
  },
  {
    zip: 9478,
    city: 'Azmoos',
    canton: 'SG',
  },
  {
    zip: 9479,
    city: 'Gretschins',
    canton: 'SG',
  },
  {
    zip: 9479,
    city: 'Malans SG',
    canton: 'SG',
  },
  {
    zip: 9479,
    city: 'Oberschan',
    canton: 'SG',
  },
  {
    zip: 9485,
    city: 'Nendeln',
    canton: 'FL',
  },
  {
    zip: 9486,
    city: 'Schaanwald',
    canton: 'FL',
  },
  {
    zip: 9487,
    city: 'Gamprin-Bendern',
    canton: 'FL',
  },
  {
    zip: 9488,
    city: 'Schellenberg',
    canton: 'FL',
  },
  {
    zip: 9490,
    city: 'Vaduz',
    canton: 'FL',
  },
  {
    zip: 9491,
    city: 'Ruggell',
    canton: 'FL',
  },
  {
    zip: 9492,
    city: 'Eschen',
    canton: 'FL',
  },
  {
    zip: 9493,
    city: 'Mauren FL',
    canton: 'FL',
  },
  {
    zip: 9494,
    city: 'Schaan',
    canton: 'FL',
  },
  {
    zip: 9495,
    city: 'Triesen',
    canton: 'FL',
  },
  {
    zip: 9496,
    city: 'Balzers',
    canton: 'FL',
  },
  {
    zip: 9497,
    city: 'Triesenberg',
    canton: 'FL',
  },
  {
    zip: 9498,
    city: 'Planken',
    canton: 'FL',
  },
  {
    zip: 9500,
    city: 'Wil SG',
    canton: 'SG',
  },
  {
    zip: 9502,
    city: 'Braunau',
    canton: 'TG',
  },
  {
    zip: 9503,
    city: 'Stehrenberg',
    canton: 'TG',
  },
  {
    zip: 9503,
    city: 'Lanterswil',
    canton: 'TG',
  },
  {
    zip: 9504,
    city: 'Friltschen',
    canton: 'TG',
  },
  {
    zip: 9506,
    city: 'Lommis',
    canton: 'TG',
  },
  {
    zip: 9507,
    city: 'Stettfurt',
    canton: 'TG',
  },
  {
    zip: 9508,
    city: 'Weingarten-Kalthäusern',
    canton: 'TG',
  },
  {
    zip: 9512,
    city: 'Rossrüti',
    canton: 'SG',
  },
  {
    zip: 9514,
    city: 'Wuppenau',
    canton: 'TG',
  },
  {
    zip: 9515,
    city: 'Hosenruck',
    canton: 'TG',
  },
  {
    zip: 9517,
    city: 'Mettlen',
    canton: 'TG',
  },
  {
    zip: 9523,
    city: 'Züberwangen',
    canton: 'SG',
  },
  {
    zip: 9524,
    city: 'Zuzwil SG',
    canton: 'SG',
  },
  {
    zip: 9525,
    city: 'Lenggenwil',
    canton: 'SG',
  },
  {
    zip: 9526,
    city: 'Zuckenriet',
    canton: 'SG',
  },
  {
    zip: 9527,
    city: 'Niederhelfenschwil',
    canton: 'SG',
  },
  {
    zip: 9532,
    city: 'Rickenbach b. Wil',
    canton: 'TG',
  },
  {
    zip: 9533,
    city: 'Kirchberg SG',
    canton: 'SG',
  },
  {
    zip: 9534,
    city: 'Gähwil',
    canton: 'SG',
  },
  {
    zip: 9535,
    city: 'Wilen b. Wil',
    canton: 'TG',
  },
  {
    zip: 9536,
    city: 'Schwarzenbach SG',
    canton: 'SG',
  },
  {
    zip: 9542,
    city: 'Münchwilen TG',
    canton: 'TG',
  },
  {
    zip: 9543,
    city: 'St. Margarethen TG',
    canton: 'TG',
  },
  {
    zip: 9545,
    city: 'Wängi',
    canton: 'TG',
  },
  {
    zip: 9546,
    city: 'Tuttwil',
    canton: 'TG',
  },
  {
    zip: 9547,
    city: 'Wittenwil',
    canton: 'TG',
  },
  {
    zip: 9548,
    city: 'Matzingen',
    canton: 'TG',
  },
  {
    zip: 9552,
    city: 'Bronschhofen',
    canton: 'SG',
  },
  {
    zip: 9553,
    city: 'Bettwiesen',
    canton: 'TG',
  },
  {
    zip: 9554,
    city: 'Tägerschen',
    canton: 'TG',
  },
  {
    zip: 9555,
    city: 'Tobel',
    canton: 'TG',
  },
  {
    zip: 9556,
    city: 'Zezikon',
    canton: 'TG',
  },
  {
    zip: 9556,
    city: 'Affeltrangen',
    canton: 'TG',
  },
  {
    zip: 9562,
    city: 'Buch b. Märwil',
    canton: 'TG',
  },
  {
    zip: 9562,
    city: 'Märwil',
    canton: 'TG',
  },
  {
    zip: 9565,
    city: 'Rothenhausen',
    canton: 'TG',
  },
  {
    zip: 9565,
    city: 'Schmidshof',
    canton: 'TG',
  },
  {
    zip: 9565,
    city: 'Oppikon',
    canton: 'TG',
  },
  {
    zip: 9565,
    city: 'Oberbussnang',
    canton: 'TG',
  },
  {
    zip: 9565,
    city: 'Bussnang',
    canton: 'TG',
  },
  {
    zip: 9573,
    city: 'Littenheid',
    canton: 'TG',
  },
  {
    zip: 9601,
    city: 'Lütisburg Station',
    canton: 'SG',
  },
  {
    zip: 9602,
    city: 'Müselbach',
    canton: 'SG',
  },
  {
    zip: 9602,
    city: 'Bazenheid',
    canton: 'SG',
  },
  {
    zip: 9604,
    city: 'Oberrindal',
    canton: 'SG',
  },
  {
    zip: 9604,
    city: 'Unterrindal',
    canton: 'SG',
  },
  {
    zip: 9604,
    city: 'Lütisburg',
    canton: 'SG',
  },
  {
    zip: 9606,
    city: 'Bütschwil',
    canton: 'SG',
  },
  {
    zip: 9607,
    city: 'Mosnang',
    canton: 'SG',
  },
  {
    zip: 9608,
    city: 'Ganterschwil',
    canton: 'SG',
  },
  {
    zip: 9612,
    city: 'Dreien',
    canton: 'SG',
  },
  {
    zip: 9613,
    city: 'Mühlrüti',
    canton: 'SG',
  },
  {
    zip: 9614,
    city: 'Libingen',
    canton: 'SG',
  },
  {
    zip: 9615,
    city: 'Dietfurt',
    canton: 'SG',
  },
  {
    zip: 9620,
    city: 'Lichtensteig',
    canton: 'SG',
  },
  {
    zip: 9621,
    city: 'Oberhelfenschwil',
    canton: 'SG',
  },
  {
    zip: 9622,
    city: 'Krinau',
    canton: 'SG',
  },
  {
    zip: 9630,
    city: 'Wattwil',
    canton: 'SG',
  },
  {
    zip: 9631,
    city: 'Ulisbach',
    canton: 'SG',
  },
  {
    zip: 9633,
    city: 'Bächli (Hemberg)',
    canton: 'SG',
  },
  {
    zip: 9633,
    city: 'Hemberg',
    canton: 'SG',
  },
  {
    zip: 9642,
    city: 'Ebnat-Kappel',
    canton: 'SG',
  },
  {
    zip: 9643,
    city: 'Krummenau',
    canton: 'SG',
  },
  {
    zip: 9650,
    city: 'Nesslau',
    canton: 'SG',
  },
  {
    zip: 9651,
    city: 'Ennetbühl',
    canton: 'SG',
  },
  {
    zip: 9652,
    city: 'Neu St. Johann',
    canton: 'SG',
  },
  {
    zip: 9655,
    city: 'Stein SG',
    canton: 'SG',
  },
  {
    zip: 9656,
    city: 'Alt St. Johann',
    canton: 'SG',
  },
  {
    zip: 9657,
    city: 'Unterwasser',
    canton: 'SG',
  },
  {
    zip: 9658,
    city: 'Wildhaus',
    canton: 'SG',
  },
  {
    zip: 4901,
    city: 'Langenthal',
    canton: 'BE',
  },
  {
    zip: 6010,
    city: 'Kriens',
    canton: 'OW',
  },
  {
    zip: 8501,
    city: 'Frauenfeld',
    canton: 'TG',
  },
  {
    zip: 7001,
    city: 'Chur',
    canton: 'GR',
  },
  {
    zip: 9001,
    city: 'St. Gallen',
    canton: 'SG',
  },
  {
    zip: 9013,
    city: 'St. Gallen',
    canton: 'SG',
  },
  {
    zip: 3401,
    city: 'Burgdorf',
    canton: 'BE',
  },
  {
    zip: 2500,
    city: 'Biel/Bienne 4',
    canton: 'BE',
  },
  {
    zip: 3001,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 8022,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 4902,
    city: 'Langenthal',
    canton: 'BE',
  },
  {
    zip: 4002,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 4020,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 4153,
    city: 'Reinach BL1',
    canton: 'BL',
  },
  {
    zip: 4010,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 4005,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 4009,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 2501,
    city: 'Biel/Bienne',
    canton: 'BE',
  },
  {
    zip: 2555,
    city: 'Brügg',
    canton: 'BE',
  },
  {
    zip: 7004,
    city: 'Chur',
    canton: 'GR',
  },
  {
    zip: 7007,
    city: 'Chur',
    canton: 'GR',
  },
  {
    zip: 6304,
    city: 'Zug',
    canton: 'ZG',
  },
  {
    zip: 3030,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 6021,
    city: 'Emmenbrücke',
    canton: 'LU',
  },
  {
    zip: 6039,
    city: 'Root D4',
    canton: 'LU',
  },
  {
    zip: 3172,
    city: 'Niederwangen bei Bern',
    canton: 'BE',
  },
  {
    zip: 3775,
    city: 'Lenk',
    canton: 'BE',
  },
  {
    zip: 9201,
    city: 'Gossau SG',
    canton: 'SG',
  },
  {
    zip: 8580,
    city: 'Hatswil',
    canton: 'TG',
  },
  {
    zip: 6343,
    city: 'Rotkreuz',
    canton: 'NW',
  },
  {
    zip: 6281,
    city: 'Hochdorf',
    canton: 'LU',
  },
  {
    zip: 8508,
    city: 'Unterhörstetten',
    canton: 'TG',
  },
  {
    zip: 9214,
    city: 'Kradolf-Schönenberg',
    canton: 'TG',
  },
  {
    zip: 8132,
    city: 'Egg bei Zürich',
    canton: 'ZH',
  },
  {
    zip: 8574,
    city: 'Lengwil',
    canton: 'TG',
  },
  {
    zip: 6002,
    city: 'Luzern',
    canton: 'LU',
  },
  {
    zip: 9437,
    city: 'Marbach',
    canton: 'SG',
  },
  {
    zip: 4053,
    city: 'Basel-Dreispitz',
    canton: 'BS',
  },
  {
    zip: 4503,
    city: 'Solothurn',
    canton: 'SO',
  },
  {
    zip: 4603,
    city: 'Olten',
    canton: 'SO',
  },
  {
    zip: 8734,
    city: 'Ermenswil SG',
    canton: 'SG',
  },
  {
    zip: 6371,
    city: 'Stans',
    canton: 'NW',
  },
  {
    zip: 3295,
    city: 'Rüti bei Büren',
    canton: 'BE',
  },
  {
    zip: 9006,
    city: 'St. Gallen',
    canton: 'SG',
  },
  {
    zip: 7563,
    city: 'Samnaun',
    canton: 'GR',
  },
  {
    zip: 8201,
    city: 'Schaffhausen',
    canton: 'SH',
  },
  {
    zip: 3415,
    city: 'Hasle bei Burgdorf',
    canton: 'BE',
  },
  {
    zip: 3602,
    city: 'Thun',
    canton: 'BE',
  },
  {
    zip: 8804,
    city: 'Au-Wädenswil',
    canton: 'ZH',
  },
  {
    zip: 6391,
    city: 'Engelberg',
    canton: 'OW',
  },
  {
    zip: 3053,
    city: 'Deisswil bei Münchenbuchsee',
    canton: 'BE',
  },
  {
    zip: 8031,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8040,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 9501,
    city: 'Wil SG',
    canton: 'SG',
  },
  {
    zip: 8036,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8042,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8085,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 6302,
    city: 'Zug',
    canton: 'ZG',
  },
  {
    zip: 9101,
    city: 'Herisau',
    canton: 'AR',
  },
  {
    zip: 8021,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 9470,
    city: 'Buchs',
    canton: 'SG',
  },
  {
    zip: 3000,
    city: 'Bern 22',
    canton: 'BE',
  },
  {
    zip: 4016,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 8058,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 3321,
    city: 'Schönbühl',
    canton: 'BE',
  },
  {
    zip: 9044,
    city: 'Wald',
    canton: 'AR',
  },
  {
    zip: 3132,
    city: 'Belp',
    canton: 'BE',
  },
  {
    zip: 6301,
    city: 'Zug',
    canton: 'ZG',
  },
  {
    zip: 8010,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 4502,
    city: 'Solothurn',
    canton: 'SO',
  },
  {
    zip: 6431,
    city: 'Schwyz',
    canton: 'SZ',
  },
  {
    zip: 5001,
    city: 'Aarau',
    canton: 'AG',
  },
  {
    zip: 8027,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8645,
    city: 'Rapperswil-Jona',
    canton: 'SG',
  },
  {
    zip: 8602,
    city: 'Wangen bei Dübendorf',
    canton: 'ZH',
  },
  {
    zip: 3074,
    city: 'Muri bei Bern',
    canton: 'BE',
  },
  {
    zip: 6341,
    city: 'Baar',
    canton: 'ZG',
  },
  {
    zip: 8607,
    city: 'Seegräben',
    canton: 'ZH',
  },
  {
    zip: 8080,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8058,
    city: 'Zürich-Flughafen',
    canton: 'ZH',
  },
  {
    zip: 8090,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 4018,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 8152,
    city: 'Glattparck (Opfikon)',
    canton: 'ZH',
  },
  {
    zip: 4601,
    city: 'Olten',
    canton: 'SO',
  },
  {
    zip: 8401,
    city: 'Winterthur',
    canton: 'ZH',
  },
  {
    zip: 3000,
    city: 'Bern 31',
    canton: 'BE',
  },
  {
    zip: 6144,
    city: 'Zell',
    canton: 'LU',
  },
  {
    zip: 3854,
    city: 'Oberried Brienz',
    canton: 'BE',
  },
  {
    zip: 8717,
    city: 'Benken',
    canton: 'SG',
  },
  {
    zip: 4612,
    city: 'Wangen bei Olten',
    canton: 'SO',
  },
  {
    zip: 8832,
    city: 'Wilen',
    canton: 'SZ',
  },
  {
    zip: 8606,
    city: 'Uster',
    canton: 'ZH',
  },
  {
    zip: 3050,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 8560,
    city: 'Märstetten Station',
    canton: 'TG',
  },
  {
    zip: 3800,
    city: 'Matten bei Interlaken',
    canton: 'BE',
  },
  {
    zip: 7005,
    city: 'Chur',
    canton: 'GR',
  },
  {
    zip: 5405,
    city: 'Dättwil',
    canton: 'AG',
  },
  {
    zip: 9471,
    city: 'Buchs SG',
    canton: 'SG',
  },
  {
    zip: 3322,
    city: 'Schönbühl',
    canton: 'BE',
  },
  {
    zip: 4030,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 8024,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8092,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8060,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 9004,
    city: 'St. Gallen',
    canton: 'SG',
  },
  {
    zip: 8060,
    city: 'Zürich-Flughafen',
    canton: 'ZH',
  },
  {
    zip: 8604,
    city: 'Hegnau-Zürich',
    canton: 'ZH',
  },
  {
    zip: 5405,
    city: 'Baden-Dättwil',
    canton: 'AG',
  },
  {
    zip: 8107,
    city: 'Buchs',
    canton: 'ZH',
  },
  {
    zip: 4030,
    city: 'Basel-Mulhouse',
    canton: 'BS',
  },
  {
    zip: '4010 Basel',
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 8808,
    city: 'Pfäffikon',
    canton: 'SZ',
  },
  {
    zip: 4019,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 3900,
    city: 'Brig-Glis',
    canton: 'VS',
  },
  {
    zip: 3000,
    city: 'Bern 14',
    canton: 'BE',
  },
  {
    zip: 7166,
    city: 'Truns',
    canton: 'GR',
  },
  {
    zip: 8901,
    city: 'Urdorf',
    canton: 'ZH',
  },
  {
    zip: 8806,
    city: 'Bäch',
    canton: 'SZ',
  },
  {
    zip: 7002,
    city: 'Chur',
    canton: 'GR',
  },
  {
    zip: 8602,
    city: 'Wangen',
    canton: 'ZH',
  },
  {
    zip: 3075,
    city: 'Rüfenacht',
    canton: 'BE',
  },
  {
    zip: 9325,
    city: 'Roggwil',
    canton: 'TG',
  },
  {
    zip: 8472,
    city: 'Ober-Ohringen',
    canton: 'ZH',
  },
  {
    zip: 3003,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 6011,
    city: 'Kriens',
    canton: 'LU',
  },
  {
    zip: 2500,
    city: 'Biel/Bienne 3',
    canton: 'BE',
  },
  {
    zip: 8030,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 8898,
    city: 'Flumserberg',
    canton: 'SG',
  },
  {
    zip: 8026,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 4013,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 8402,
    city: 'Winterthur',
    canton: 'ZH',
  },
  {
    zip: 3173,
    city: 'Oberwangen bei Bern',
    canton: 'BE',
  },
  {
    zip: 8586,
    city: 'Riedt bei Erlen',
    canton: 'TG',
  },
  {
    zip: 6000,
    city: 'Luzern 16',
    canton: 'LU',
  },
  {
    zip: 4023,
    city: 'Basel',
    canton: 'BL',
  },
  {
    zip: 8058,
    city: 'Kloten',
    canton: 'ZH',
  },
  {
    zip: 3023,
    city: 'Bern',
    canton: 'BE',
  },
  {
    zip: 9303,
    city: 'Wittenbach',
    canton: 'SG',
  },
  {
    zip: 4070,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 8023,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 4953,
    city: 'Schwarzenbach',
    canton: 'BE',
  },
  {
    zip: 8021,
    city: 'Wallisellen',
    canton: 'ZH',
  },
  {
    zip: 8058,
    city: 'Zürich Airport',
    canton: 'ZH',
  },
  {
    zip: 8472,
    city: 'Ohringen',
    canton: 'ZH',
  },
  {
    zip: 8964,
    city: 'Rudolfstetten-Friedlisberg',
    canton: 'AG',
  },
  {
    zip: 9105,
    city: 'Wald-Schönengrund',
    canton: 'AR',
  },
  {
    zip: 4658,
    city: 'Däniken',
    canton: 'SO',
  },
  {
    zip: 8034,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 9464,
    city: 'Rüthi',
    canton: 'SG',
  },
  {
    zip: 5232,
    city: 'Villigen PSI',
    canton: 'AG',
  },
  {
    zip: 8532,
    city: 'Warth-Weiningen',
    canton: 'TG',
  },
  {
    zip: 6017,
    city: 'Kriens',
    canton: 'LU',
  },
  {
    zip: 8604,
    city: 'Hegnau-Volketswil',
    canton: 'ZH',
  },
  {
    zip: 3267,
    city: 'Seedorf',
    canton: 'BE',
  },
  {
    zip: 6431,
    city: 'Schwyz',
    canton: 'SO',
  },
  {
    zip: 3270,
    city: 'Aarberg',
    canton: 'BL',
  },
  {
    zip: 8640,
    city: 'Rapperswil',
    canton: 'SG',
  },
  {
    zip: 3003,
    city: 'Köniz',
    canton: 'BE',
  },
  {
    zip: 3292,
    city: 'Busswil BE',
    canton: 'BE',
  },
  {
    zip: 6302,
    city: 'Zug',
    canton: 'SZ',
  },
  {
    zip: 8808,
    city: 'Freienbach',
    canton: 'SZ',
  },
  {
    zip: 8762,
    city: 'Schwanden',
    canton: 'GL',
  },
  {
    zip: 3001,
    city: 'Bern',
    canton: 'ZH',
  },
  {
    zip: 8907,
    city: 'Wettswil am Albis',
    canton: 'ZH',
  },
  {
    zip: 3000,
    city: 'Bern 15',
    canton: 'BE',
  },
  {
    zip: 3000,
    city: 'Bern 5',
    canton: 'BE',
  },
  {
    zip: 8604,
    city: 'Hegnau',
    canton: 'ZH',
  },
  {
    zip: 9423,
    city: 'Thal',
    canton: 'SG',
  },
  {
    zip: 6317,
    city: 'Oberwil bei Zug',
    canton: 'ZG',
  },
  {
    zip: 8093,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 4509,
    city: 'Solothurn',
    canton: 'SO',
  },
  {
    zip: 9200,
    city: 'Gossau',
    canton: 'SG',
  },
  {
    zip: 6047,
    city: 'Kastanienbaum - Luzern',
    canton: 'LU',
  },
  {
    zip: 9015,
    city: 'St.Gallen',
    canton: 'SG',
  },
  {
    zip: 8152,
    city: 'Glattpark',
    canton: 'ZH',
  },
  {
    zip: 9016,
    city: 'St.Gallen',
    canton: 'SG',
  },
  {
    zip: 9500,
    city: 'Wil',
    canton: 'SG',
  },
  {
    zip: 8910,
    city: 'Affoltern',
    canton: 'ZH',
  },
  {
    zip: 8700,
    city: 'Küsnacht',
    canton: 'ZH',
  },
  {
    zip: 8707,
    city: 'Uetikon',
    canton: 'ZH',
  },
  {
    zip: 9200,
    city: 'St.Gallen',
    canton: 'SG',
  },
  {
    zip: 8330,
    city: 'Pfäffikon',
    canton: 'ZH',
  },
  {
    zip: 8620,
    city: 'Wetzikon',
    canton: 'ZH',
  },
  {
    zip: 4132,
    city: 'Muttenz',
    canton: 'ZH',
  },
  {
    zip: 4104,
    city: 'Oberwil',
    canton: 'BL',
  },
  {
    zip: 3000,
    city: 'Bern 23',
    canton: 'BE',
  },
  {
    zip: 4133,
    city: 'Pratteln 1',
    canton: 'BL',
  },
  {
    zip: 3177,
    city: 'Laupen',
    canton: 'BE',
  },
  {
    zip: 3013,
    city: 'Bern',
    canton: 'ZH',
  },
  {
    zip: 8834,
    city: 'Feusisberg',
    canton: 'SZ',
  },
  {
    zip: 5201,
    city: 'Brugg',
    canton: 'ZH',
  },
  {
    zip: 3000,
    city: 'Bern 13',
    canton: 'BE',
  },
  {
    zip: 3172,
    city: 'Niederwangen',
    canton: 'BE',
  },
  {
    zip: 8640,
    city: 'Rapperswil - Jona',
    canton: 'SG',
  },
  {
    zip: 9001,
    city: 'St.Gallen',
    canton: 'SG',
  },
  {
    zip: 9501,
    city: 'Wil',
    canton: 'SG',
  },
  {
    zip: 4147,
    city: 'Aesch/BL',
    canton: 'BL',
  },
  {
    zip: 4665,
    city: 'Oftringen',
    canton: 'ZH',
  },
  {
    zip: 9056,
    city: 'Gais',
    canton: 'ZH',
  },
  {
    zip: 6020,
    city: 'Emmen',
    canton: 'LU',
  },
  {
    zip: 4901,
    city: 'Langenthal',
    canton: 'ZH',
  },
  {
    zip: 1701,
    city: 'Fribourg',
    canton: 'ZH',
  },
  {
    zip: 9008,
    city: 'St. Gallen',
    canton: 'ZH',
  },
  {
    zip: 5612,
    city: 'Villmergen',
    canton: 'ZH',
  },
  {
    zip: 4503,
    city: 'Solothurn',
    canton: 'ZH',
  },
  {
    zip: 6301,
    city: 'Zug',
    canton: 'ZH',
  },
  {
    zip: 4603,
    city: 'Olten',
    canton: 'BE',
  },
  {
    zip: 6061,
    city: 'Sarnen',
    canton: 'OW',
  },
  {
    zip: 8622,
    city: 'Wetzikon',
    canton: 'ZH',
  },
  {
    zip: 8084,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 4012,
    city: 'Basel',
    canton: 'BS',
  },
  {
    zip: 5401,
    city: 'Baden',
    canton: 'AG',
  },
  {
    zip: 6009,
    city: 'Luzern',
    canton: 'LU',
  },
  {
    zip: 8091,
    city: 'Zürich',
    canton: 'ZH',
  },
  {
    zip: 6735,
    city: 'Beckenried',
    canton: 'NW',
  },
  {
    zip: 9401,
    city: 'Rorschach',
    canton: 'SG',
  },
  {
    zip: 8202,
    city: 'Kloten',
    canton: 'ZH',
  },
  {
    zip: 8403,
    city: 'Winterthur',
    canton: 'ZH',
  },
];

export default zip;
