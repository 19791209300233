import { useMutation } from '@apollo/client';
import { useFormikContext } from 'formik';
import { omit } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { CHAT_GPT_ANSWER_CONVERSATION, CHAT_GPT_ANSWER_TICKET } from '../../schema/chat';
import { ChatGPTMessageInput, ChatGPTRole } from '../../schema/graphql-global-types';
import {
  chatGPTAnswerConversation,
  chatGPTAnswerConversationVariables,
} from '../../schema/types/chatGPTAnswerConversation';
import { chatGPTAnswerTicket, chatGPTAnswerTicketVariables } from '../../schema/types/chatGPTAnswerTicket';
import { TicketDetail } from '../../schema/types/TicketDetail';
import { toastErrorQuery } from '../../utils/error';
import { nl2br } from '../../utils/utils';
import ChatGPTForm from '../form/ChatGPTForm';

type Props = {};

const TicketChatGPTForm: FunctionComponent<Props> = () => {
  const [value, setValue] = useState('');
  const { values, setFieldValue } = useFormikContext<TicketDetail>();
  const [answers, setAnswers] = useState<ChatGPTMessageInput[]>([]);

  const [answerTicket, { loading: answeringTicket }] = useMutation<chatGPTAnswerTicket, chatGPTAnswerTicketVariables>(
    CHAT_GPT_ANSWER_TICKET,
    {
      variables: {
        query: {
          ticketId: values.id,
        },
      },
    },
  );
  const [answerConversation, { loading: answeringConversation }] = useMutation<
    chatGPTAnswerConversation,
    chatGPTAnswerConversationVariables
  >(CHAT_GPT_ANSWER_CONVERSATION);

  const handleAnswerTicket = async () => {
    try {
      const result = await answerTicket();

      if (result.errors) {
        toastErrorQuery(result.errors);
      } else if (result.data?.chatGPTAnswerTicket) {
        setFieldValue('content', nl2br(result.data.chatGPTAnswerTicket));
      }
    } catch (e: any) {
      toastErrorQuery(e);
    }
  };

  const handleAnswerConversation = async () => {
    try {
      const result = await answerConversation({
        variables: {
          query: {
            messages: [
              ...answers,
              {
                content: value,
                role: ChatGPTRole.user,
              },
            ],
          },
        },
      });

      if (result.errors) {
        toastErrorQuery(result.errors);
      } else if (result.data?.chatGPTAnswerConversation.length) {
        setAnswers(result.data.chatGPTAnswerConversation.map((el) => omit(el, ['__typename'])));
        setFieldValue(
          'content',
          nl2br(result.data.chatGPTAnswerConversation[result.data.chatGPTAnswerConversation.length - 1].content),
        );
        setValue('');
      }
    } catch (e: any) {
      toastErrorQuery(e);
    }
  };

  return (
    <ChatGPTForm
      value={value}
      onChange={setValue}
      onAnswerConversation={handleAnswerConversation}
      onAnswerAuto={handleAnswerTicket}
      loadingAnswerConversation={answeringConversation}
      loadingAnswerAuto={answeringTicket}
      type="ticket"
    />
  );
};

export default TicketChatGPTForm;
