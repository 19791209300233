import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import copy from 'copy-to-clipboard';
import { Field, FormikProps } from 'formik';
import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import FieldCheckbox from '../form/FieldCheckbox';
import FieldErrorMessage from '../form/FieldErrorMessage';
import AssetMonitorModal from './AssetMonitorModal';

const handleCopy = (value: string) => () => {
  copy(value);
  toast.success(i18n._(t`Copied!`));
};

type Props = {};

const AssetMonitoringForm: FC<Props> = () => {
  const [showMonitor, setShowMonitor] = useState(false);

  const toggleMonitor = () => setShowMonitor((prevState) => !prevState);

  return (
    <div>
      <div className="row">
        <div className="form-group col-xl-6">
          <label>
            <Trans>Uptime SLA</Trans>
          </label>

          <div className="row align-items-center">
            <div className="col-sm-4 mb-2 mb-sm-0">
              <div className="input-group">
                <Field type="text" className="form-control" name="uptimeSla" />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="icon-percent" />
                  </span>
                </div>
              </div>

              <FieldErrorMessage name="uptimeSla" />
            </div>

            <div className="col-sm-6">
              <FieldCheckbox name="displayOnCustomer" label={i18n._(t`Uptime visible for Customer`)} />
            </div>
          </div>
        </div>
      </div>

      <div className="row asset-uptime-monitor">
        <Field name="ninjaRmmDeviceUrl">
          {({ field }: { field: any }) => {
            if (!field.value) return '';

            return (
              <div className="form-group col-xl-6 column">
                <label>
                  <Trans>NinjaRMM</Trans>
                </label>

                <div className="info">
                  <div className="name text-ellipsis">{field.value}</div>

                  <div className="actions">
                    <button type="button" onClick={handleCopy(field.value)}>
                      <i className="icon-copy" />
                    </button>
                  </div>
                </div>
              </div>
            );
          }}
        </Field>

        <div className="form-group col-xl-6 column">
          <label>
            <Trans>Uptime Monitor</Trans>
          </label>

          <Field name="monitorConnected">
            {({ field, form }: { field: any; form: FormikProps<any> }) => {
              const handleDisconnect = () => {
                form.setFieldValue('disconnectMonitor', true);
                form.setFieldValue('monitorConnected', false);
                form.setFieldValue('monitor', null);
              };

              const handleSubmit = (value: any) => {
                form.setFieldValue('disconnectMonitor', false);
                form.setFieldValue('monitorConnected', true);
                form.setFieldValue('monitor', value);
                toggleMonitor();
              };

              return (
                <div>
                  {!field.value ? (
                    <div>
                      <button type="button" className="btn btn-link" onClick={toggleMonitor}>
                        <Trans>Connect Uptime Monitor</Trans>
                      </button>
                    </div>
                  ) : (
                    <div className="info">
                      <div className="name text-ellipsis">
                        {form.values.monitor ? (
                          <>
                            <Trans>Monitor</Trans> {form.values.id}
                          </>
                        ) : (
                          <Trans>Fetching monitor...</Trans>
                        )}
                      </div>

                      <div className="actions">
                        <button type="button" data-tip data-for="edit" onClick={toggleMonitor}>
                          <i className="icon-edit" />
                        </button>

                        <ReactTooltip id="edit">
                          <Trans>Edit</Trans>
                        </ReactTooltip>

                        {field.value && (
                          <button type="button" data-tip data-for="disconnect" onClick={handleDisconnect}>
                            <i className="icon-delete" />
                          </button>
                        )}

                        <ReactTooltip id="disconnect">
                          <Trans>Disconnect</Trans>
                        </ReactTooltip>
                      </div>
                    </div>
                  )}

                  <AssetMonitorModal
                    value={form.values.monitor}
                    show={showMonitor}
                    onSubmit={handleSubmit}
                    onClose={toggleMonitor}
                  />
                </div>
              );
            }}
          </Field>
        </div>
      </div>
    </div>
  );
};

export default AssetMonitoringForm;
