import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import React, { FunctionComponent } from 'react';
import { BudgetUnit } from '../../schema/graphql-global-types';
import { useCurrency } from '../../utils/hooks';
import FieldCheckbox from '../form/FieldCheckbox';
import FieldErrorMessage from '../form/FieldErrorMessage';
import FieldTime from '../form/FieldTime';
import TimeTrackerCompanyForm from './TimeTrackerCompanyForm';
import TimeTrackerRateForm from './TimeTrackerRateForm';

type Props = {};

const TimeTrackerDetailForm: FunctionComponent<Props> = () => {
  const { data } = useCurrency();

  return (
    <div className="row">
      <div className="col-xl-6 panel-column-left">
        <div className="card-header">
          <Trans>General Information</Trans>
        </div>

        <div className="form-group">
          <label>
            <Trans>Customer</Trans>
          </label>

          <TimeTrackerCompanyForm />
        </div>

        <div className="form-group">
          <label>
            <Trans>Project</Trans>
          </label>

          <Field type="text" className="form-control" name="title" />

          <FieldErrorMessage name="title" />
        </div>

        <div className="form-group">
          <label>
            <Trans>Budget</Trans>
          </label>

          <div className="row">
            <div className="col form-group">
              <Field name="budgetUnit">
                {({ field }: { field: FieldInputProps<BudgetUnit> }) => {
                  if (field.value === BudgetUnit.Hour) return <FieldTime name="budgetCount" />;

                  return <Field type="text" className="form-control" name="budgetCount" />;
                }}
              </Field>
            </div>

            <div className="col-4 form-group">
              <Field component="select" className="form-control" name="budgetUnit">
                <option value={BudgetUnit.Currency}>{data?.currencyConfig.currency}</option>
                <option value={BudgetUnit.Hour}>{i18n._(t`Hours`)}</option>
                <option value={BudgetUnit.Entry}>{i18n._(t`Entries`)}</option>
              </Field>
            </div>
          </div>
        </div>

        <FieldCheckbox name="enableInCustomerView" label={i18n._(t`Display in customer portal`)} />
      </div>

      <div className="col-xl-6">
        <div className="card-header">
          <Trans>Add Special Rates</Trans>
        </div>

        <TimeTrackerRateForm name="specialRates" />
      </div>
    </div>
  );
};

export default TimeTrackerDetailForm;
