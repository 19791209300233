import { omit } from 'lodash';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { GET_OUTLOOK_APP_CONFIG, UPDATE_OUTLOOK_APP_CONFIG } from '../../schema/config';
import { OutlookAppConfigPayload } from '../../schema/graphql-global-types';
import { getOutlookAppConfig } from '../../schema/types/getOutlookAppConfig';
import { updateOutlookAppConfig, updateOutlookAppConfigVariables } from '../../schema/types/updateOutlookAppConfig';
import { toastErrorQuery } from '../../utils/error';
import { validateRequired } from '../../utils/validations';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldErrorMessage from '../form/FieldErrorMessage';

type Props = {
  show: boolean;
  onClose: () => void;
};

const ConfigurationAppMailModal: FunctionComponent<Props> = ({ show, onClose }) => {
  const outlookAppConfig = useQuery<getOutlookAppConfig>(GET_OUTLOOK_APP_CONFIG);

  const [mutate] = useMutation<updateOutlookAppConfig, updateOutlookAppConfigVariables>(UPDATE_OUTLOOK_APP_CONFIG, {
    refetchQueries: [
      {
        query: GET_OUTLOOK_APP_CONFIG,
      },
    ],
    awaitRefetchQueries: true,
  });

  const model: OutlookAppConfigPayload = {
    appId:
      (outlookAppConfig.data &&
        outlookAppConfig.data.outlookAppConfig &&
        outlookAppConfig.data.outlookAppConfig.appId) ||
      '',
    appSecret: '',
  };

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>App Mail Connector</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              enableReinitialize
              initialValues={model}
              validationSchema={yup.object().shape({
                appId: validateRequired,
                appSecret: validateRequired,
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  const payload: any = omit(values, ['__typename']);

                  const result: any = await mutate({
                    variables: {
                      payload,
                    },
                  });

                  if (result.errors) {
                    toastErrorQuery(result.errors);
                  } else {
                    toast.success(i18n._(t`Success!`));
                    resetForm();
                    onClose();
                  }
                } catch (e) {
                  toastErrorQuery(e);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting, submitForm }) => {
                const handleSubmit = (e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  submitForm();
                };

                return (
                  <Form onSubmitCapture={handleSubmit}>
                    <div className="modal-body">
                      <div className="form-group">
                        <label>
                          <Trans>App ID</Trans>
                        </label>
                        <Field type="text" className="form-control" name="appId" autoFocus />

                        <FieldErrorMessage name="appId" />
                      </div>

                      <div className="form-group">
                        <label>
                          <Trans>App Secret</Trans>
                        </label>
                        <Field type="password" className="form-control" name="appSecret" />

                        <FieldErrorMessage name="appSecret" />
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={onClose}>
                        <Trans>Close</Trans>
                      </button>

                      <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save`)} loading={isSubmitting} />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfigurationAppMailModal;
