import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import ReactTable, { CellInfo } from 'react-table';
import { NavLink } from 'react-router-dom';
import MainTitle from '../components/common/MainTitle';
import TableActions from '../components/common/TableActions';
import UserAvatarName from '../components/common/UserAvatarName';
import ButtonArchive from '../components/form/ButtonArchive';
import ButtonEdit from '../components/form/ButtonEdit';
import LoadingTable from '../components/common/LoadingTable';
import FilterHeader from '../components/form/FilterHeader';
import { GET_COMPANIES_FOR_FILTER } from '../schema/company';
import { GET_PERSONS, ARCHIVE_PERSON, UNARCHIVE_PERSON } from '../schema/person';
import { OrderDirection } from '../schema/graphql-global-types';
import { archivePerson, archivePersonVariables } from '../schema/types/archivePerson';
import { getPersons, getPersonsVariables } from '../schema/types/getPersons';
import { unarchivePerson, unarchivePersonVariables } from '../schema/types/unarchivePerson';
import { tableOptions, tablePollingInterval } from '../utils/const';
import ErrorQuery from '../components/form/ErrorQuery';
import { cloneDeep } from 'lodash';
import { DataProxy } from '@apollo/client/cache';
import { useVariables } from '../utils/hooks';
import { getFilter, getOrder, getPhone } from '../utils/utils';

const PersonPage: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);
  const {
    search$,
    search,
    filter$,
    filter,
    archived,
    setArchived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
  } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const updateQuery = (proxy: DataProxy, id: any) => {
    const data: any = cloneDeep(proxy.readQuery({ query: GET_PERSONS, variables }));
    data.persons.items = data.persons.items.filter((el: any) => el.id !== id);
    proxy.writeQuery({ query: GET_PERSONS, variables, data });
  };

  const [mutateArchive] = useMutation<archivePerson, archivePersonVariables>(ARCHIVE_PERSON, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.archivePerson.person.id);
    },
  });

  const [mutateUnarchive] = useMutation<unarchivePerson, unarchivePersonVariables>(UNARCHIVE_PERSON, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.unarchivePerson.person.id);
    },
  });

  const handleArchive = useCallback(
    (id: any) => () => {
      if (archived) {
        mutateUnarchive({
          variables: {
            id,
          },
          optimisticResponse: {
            unarchivePerson: {
              person: {
                id,
                __typename: 'Person',
              },
              __typename: 'MutatePersonResponse',
            },
          },
        });
      } else {
        mutateArchive({
          variables: {
            id,
          },
          optimisticResponse: {
            archivePerson: {
              person: {
                id,
                __typename: 'Person',
              },
              __typename: 'MutatePersonResponse',
            },
          },
        });
      }
    },
    [archived, mutateArchive, mutateUnarchive],
  );

  const { data, previousData, loading, error } = useQuery<getPersons, getPersonsVariables>(GET_PERSONS, {
    variables,
    pollInterval: tablePollingInterval,
  });

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.persons.items ?? previousData?.persons.items ?? [];
  const pages = data && data.persons ? Math.ceil(data.persons.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Name`),
      accessor: 'fullName',
      sortable: true,
      Cell: (cell: CellInfo) => (
        <NavLink to={`/crm/person/${cell.original.id}`}>
          <UserAvatarName user={cell.original} />
        </NavLink>
      ),
    },
    {
      Header: i18n._(t`Company`),
      accessor: 'company.name',
      filterable: true,
      Cell: (cell: CellInfo) => (
        <div className="w-100">
          {cell.original.companies.map((el: any) => (
            <div key={el.company.id} className="my-2">
              <NavLink to={`/crm/company/${el.company.id}`}>{el.company.name}</NavLink>
            </div>
          ))}
        </div>
      ),
      Filter: (params: any) => (
        <FilterHeader
          query={GET_COMPANIES_FOR_FILTER}
          objectType="companies"
          nameField="name"
          onChange={params.onChange}
        />
      ),
    },
    {
      Header: i18n._(t`Phone`),
      accessor: 'cellphone',
      sortable: true,
      Cell: (cell: CellInfo) => <a href={`tel:${getPhone(cell.value)}`}>{cell.value}</a>,
    },
    {
      Header: i18n._(t`Mail`),
      accessor: 'email',
      sortable: true,
      filterable: true,
      Cell: (cell: CellInfo) => <a href={`mailto:${cell.value}`}>{cell.value}</a>,
    },
    {
      Header: i18n._(t`Last Log`),
      accessor: 'lastLog.content',
    },
    {
      Header: i18n._(t`Actions`),
      width: 90,
      className: 'text-center',
      Cell: (cell: CellInfo) => (
        <>
          <NavLink to={`/crm/person/${cell.original.id}`}>
            <ButtonEdit />
          </NavLink>
          <ButtonArchive onClick={handleArchive(cell.original.id)} />
        </>
      ),
    },
  ];

  return (
    <div className="person-page">
      <div className="card-body">
        <div className="table-actions">
          <div className="breadcrumbs-area">
            <MainTitle title={i18n._(t`Person`)} tips="CRM/Person" />
          </div>

          <TableActions onSearch={(e) => search$.next(e.target.value)} archived={archived} onArchive={setArchived} />

          <NavLink to="/crm/person/add" type="button" className="btn btn-sm btn-primary">
            <i className="icon-add" />
            <Trans>Add new person</Trans>
          </NavLink>
        </div>

        {!loaded ? (
          <LoadingTable />
        ) : (
          <ReactTable
            data={items}
            columns={columns}
            pages={pages}
            pageSize={limit}
            manual
            onFetchData={(state) => {
              filter$.next(
                getFilter(state, {
                  email: 'searchEmail',
                  'company.name': 'companyIds',
                }),
              );

              let orderDir = OrderDirection.Desc;
              if (state.sorted[0]) {
                orderDir = state.sorted[0].desc ? OrderDirection.Desc : OrderDirection.Asc;
              }

              setOffset(state.page * state.pageSize);
              setOrderBy(
                getOrder(state, {
                  'primaryPerson.fullName': 'primaryContactFullName',
                }),
              );
              setOrderDirection(orderDir);
            }}
            onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
            {...tableOptions()}
          />
        )}
      </div>
    </div>
  );
};

export default PersonPage;
