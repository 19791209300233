import DateFormat from '../components/common/DateFormat';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import ReactTable, { CellInfo } from 'react-table';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import MainTitle from '../components/common/MainTitle';
import TableActions from '../components/common/TableActions';
import LoadingTable from '../components/common/LoadingTable';
import FilterHeader from '../components/form/FilterHeader';
import OfferConvertModal from '../components/offer/OfferConvertModal';
import OfferCreateModal from '../components/offer/OfferCreateModal';
import OfferLogModal from '../components/offer/OfferLogModal';
import OfferTextTemplateModal from '../components/offer/OfferTextTemplateModal';
import { GET_COMPANIES_FOR_FILTER } from '../schema/company';
import { GET_OFFERS, ARCHIVE_OFFER, UNARCHIVE_OFFER, FAVORITE_OFFER } from '../schema/offer';
import { OrderDirection } from '../schema/graphql-global-types';
import { GET_ASSET_TAGS } from '../schema/tag';
import { GET_SYSTEM_TEXT_TEMPLATE } from '../schema/textTemplate';
import { archiveOffer, archiveOfferVariables } from '../schema/types/archiveOffer';
import { favoriteOffer, favoriteOfferVariables } from '../schema/types/favoriteOffer';
import { getOffers, getOffersVariables } from '../schema/types/getOffers';
import { Tag } from '../schema/types/Tag';
import { unarchiveOffer, unarchiveOfferVariables } from '../schema/types/unarchiveOffer';
import { dateOptions, tableOptions, tablePollingInterval } from '../utils/const';
import ErrorQuery from '../components/form/ErrorQuery';
import { Offer } from '../schema/types/Offer';
import { useVariables } from '../utils/hooks';
import { cloneDeep } from 'lodash';
import { DataProxy } from '@apollo/client/cache';
import { getFilter, getOrder, getPhone } from '../utils/utils';
import { useQuery, useMutation } from '@apollo/client';

const OfferPage: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);
  const {
    search$,
    search,
    filter$,
    filter,
    archived,
    setArchived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
  } = useVariables();

  const variables = useMemo(
    () => ({
      query: {
        limit,
        offset,
        orderBy,
        orderDirection,
        filter: {
          search,
          archived,
          ...filter,
          template: false,
        },
      },
    }),
    [limit, offset, orderBy, orderDirection, search, archived, filter],
  );

  useQuery(GET_SYSTEM_TEXT_TEMPLATE, {
    variables: {
      key: 'offer_reminder',
    },
  });

  const updateQuery = (proxy: DataProxy, id: any) => {
    const data: any = cloneDeep(proxy.readQuery({ query: GET_OFFERS, variables }));
    data.offers.items = data.offers.items.filter((el: any) => el.id !== id);
    proxy.writeQuery({ query: GET_OFFERS, variables, data });
  };

  const [mutateArchive] = useMutation<archiveOffer, archiveOfferVariables>(ARCHIVE_OFFER, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.archiveOffer.offer.id);
    },
  });
  const [mutateUnarchive] = useMutation<unarchiveOffer, unarchiveOfferVariables>(UNARCHIVE_OFFER, {
    update: (proxy, { data }) => {
      updateQuery(proxy, data!.unarchiveOffer.offer.id);
    },
  });

  const handleArchive = useCallback(
    (id: any) => () => {
      if (archived) {
        mutateUnarchive({
          variables: {
            id,
          },
          optimisticResponse: {
            unarchiveOffer: {
              offer: {
                id,
                __typename: 'Offer',
              },
              __typename: 'MutateOfferResponse',
            },
          },
        });
      } else {
        mutateArchive({
          variables: {
            id,
          },
          optimisticResponse: {
            archiveOffer: {
              offer: {
                id,
                __typename: 'Offer',
              },
              __typename: 'MutateOfferResponse',
            },
          },
        });
      }
    },
    [archived, mutateArchive, mutateUnarchive],
  );

  const [mutate] = useMutation<favoriteOffer, favoriteOfferVariables>(FAVORITE_OFFER);

  const [showAdd, setShowAdd] = useState(false);
  const [modalModel, setModalModel] = useState<Offer>();
  const [showLog, setShowLog] = useState(false);
  const [showRemind, setShowRemind] = useState(false);
  const [showConvert, setShowConvert] = useState(false);

  const toggleAdd = useCallback(() => setShowAdd((prevState) => !prevState), []);
  const toggleLog = useCallback(() => setShowLog((prevState) => !prevState), []);
  const toggleRemind = useCallback(() => setShowRemind((prevState) => !prevState), []);
  const toggleConvert = useCallback(() => setShowConvert((prevState) => !prevState), []);

  const handleLog = useCallback(
    (model: any) => () => {
      setModalModel(model);
      toggleLog();
    },
    [setModalModel, toggleLog],
  );

  const handleRemind = useCallback(
    (model: any) => () => {
      setModalModel(model);
      toggleRemind();
    },
    [setModalModel, toggleRemind],
  );

  const handleConvert = useCallback(
    (model: any) => () => {
      setModalModel(model);
      toggleConvert();
    },
    [setModalModel, toggleConvert],
  );

  const { data, previousData, loading, error, refetch } = useQuery<getOffers, getOffersVariables>(GET_OFFERS, {
    variables,
    pollInterval: tablePollingInterval,
  });

  const handleFavorite = useCallback(
    (item: Offer, favorite: boolean) => () => {
      mutate({
        variables: {
          id: item.id,
          payload: {
            favorite,
          },
        },
        update: () => {
          refetch();
        },
      });
    },
    [mutate, refetch],
  );

  const defaultTemplate = useQuery(GET_SYSTEM_TEXT_TEMPLATE, {
    variables: {
      key: 'offer_reminder',
    },
  });

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.offers.items ?? previousData?.offers.items ?? [];
  const pages = data && data.offers ? Math.ceil(data.offers.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Title`),
      accessor: 'title',
      sortable: true,
      Cell: (cell: CellInfo) => <NavLink to={`/crm/offer/${cell.original.id}`}>{cell.value}</NavLink>,
    },
    {
      Header: i18n._(t`Date`),
      accessor: 'createdAt',
      sortable: true,
      className: 'text-center',
      Cell: (cell: CellInfo) => <DateFormat value={new Date(cell.value)} format={dateOptions} />,
    },
    {
      Header: i18n._(t`Company`),
      accessor: 'company.name',
      sortable: true,
      filterable: true,
      Cell: (cell: CellInfo) => (
        <>{cell.original.company && <NavLink to={`/crm/company/${cell.original.company.id}`}>{cell.value}</NavLink>}</>
      ),
      Filter: (params: any) => (
        <FilterHeader
          query={GET_COMPANIES_FOR_FILTER}
          objectType="companies"
          nameField="name"
          onChange={params.onChange}
        />
      ),
    },
    {
      Header: i18n._(t`Name`),
      accessor: 'fullName',
      sortable: true,
      filterable: true,
    },
    {
      Header: i18n._(t`Phone`),
      accessor: 'company.generalPhone',
      Cell: (cell: CellInfo) => <a href={`tel:${getPhone(cell.value)}`}>{cell.value}</a>,
    },
    {
      Header: i18n._(t`Last Log`),
      accessor: 'lastLog.content',
    },
    {
      Header: i18n._(t`Tags`),
      accessor: 'tags',
      filterable: true,
      Cell: (cell: CellInfo) => (
        <>
          {cell.value.map((el: Tag) => (
            <span key={el.id} className="badge mr-1 mb-1">
              {el.name}
            </span>
          ))}
        </>
      ),
      Filter: (params: any) => (
        <FilterHeader query={GET_ASSET_TAGS} objectType="assetTags" nameField="name" onChange={params.onChange} />
      ),
    },
    {
      Header: i18n._(t`Actions`),
      width: 90,
      className: 'text-center has-dots',
      Cell: (cell: CellInfo) => (
        <>
          <a
            href={cell.original.internalUrl}
            data-tip
            data-for={`preview-${cell.original.id}`}
            className="button text-xs"
            target="_blank"
          >
            <i className="icon-preview" />
          </a>
          <ReactTooltip id={`preview-${cell.original.id}`}>
            <Trans>Preview</Trans>
          </ReactTooltip>

          <div className="dots-menu">
            <i className="icon-internal-menu" />

            <div className="dropdown">
              <NavLink to={`/crm/offer/${cell.original.id}`} className="action">
                <i className="icon-edit" />
                <Trans>Edit</Trans>
              </NavLink>

              <button type="button" className="action" onClick={handleRemind(cell.original)}>
                <i className="icon-notification" />
                <Trans>Send Reminder</Trans>
              </button>

              <button type="button" className="action" onClick={handleConvert(cell.original)}>
                <i className="icon-convert" />
                <Trans>Convert Offer</Trans>
              </button>

              <button type="button" className="action" onClick={handleLog(cell.original)}>
                <i className="icon-time" />
                <Trans>Add Log</Trans>
              </button>

              <button type="button" className="action" onClick={handleFavorite(cell.original, !cell.original.favorite)}>
                {cell.original.favorite ? (
                  <i className="icon-like---filled text-warning" />
                ) : (
                  <i className="icon-like" />
                )}
                <Trans>Favorite</Trans>
              </button>

              <button type="button" className="action" onClick={handleArchive(cell.original.id)}>
                <i className="icon-delete" />
                <Trans>Archive</Trans>
              </button>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <div className="offer-page">
      <div className="card-body">
        <div className="table-actions">
          <NavLink to="/crm/template" data-tip data-for="toggle-template" className="link-action">
            T
          </NavLink>

          <ReactTooltip id="toggle-template">
            <Trans>Templates</Trans>
          </ReactTooltip>

          <div className="breadcrumbs-area">
            <MainTitle title={i18n._(t`Offer`)} tips="CRM/Offers" />
          </div>

          <TableActions onSearch={(e) => search$.next(e.target.value)} archived={archived} onArchive={setArchived} />

          <button type="button" className="btn btn-sm btn-primary" onClick={toggleAdd}>
            <i className="icon-add" />
            <Trans>Add new offer</Trans>
          </button>
        </div>

        {!loaded ? (
          <LoadingTable />
        ) : (
          <ReactTable
            data={items}
            columns={columns}
            pages={pages}
            pageSize={limit}
            manual
            onFetchData={(state) => {
              filter$.next(
                getFilter(state, {
                  fullName: 'searchName',
                  'company.name': 'companyIds',
                  tags: 'tagIds',
                }),
              );

              let orderDir = OrderDirection.Desc;
              if (state.sorted[0]) {
                orderDir = state.sorted[0].desc ? OrderDirection.Desc : OrderDirection.Asc;
              }

              setOffset(state.page * state.pageSize);
              setOrderBy(
                getOrder(state, {
                  'company.name': 'companyName',
                }),
              );
              setOrderDirection(orderDir);
            }}
            onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
            {...tableOptions()}
          />
        )}

        <OfferCreateModal show={showAdd} onClose={toggleAdd} />

        {modalModel && (
          <>
            <OfferLogModal model={modalModel} variables={variables} show={showLog} onClose={toggleLog} />

            <OfferTextTemplateModal
              to={modalModel!.email || ''}
              offerId={modalModel!.id}
              template={
                modalModel!.favoriteTextTemplate || (defaultTemplate.data && defaultTemplate.data.systemTextTemplate)
              }
              show={showRemind}
              onClose={toggleRemind}
            />

            <OfferConvertModal model={modalModel} show={showConvert} onClose={toggleConvert} />
          </>
        )}
      </div>
    </div>
  );
};

export default OfferPage;
