import { useQuery } from '@apollo/client';
import React, { FunctionComponent } from 'react';
import { GET_ASSET_LOCATION_TAGS } from '../../schema/tag';
import { getAssetLocationTags, getAssetLocationTagsVariables } from '../../schema/types/getAssetLocationTags';
import { useVariables } from '../../utils/hooks';
import SingleTagSelect from '../form/SingleTagSelect';

type Props = {};

const AssetLocationForm: FunctionComponent<Props> = () => {
  const { search$, search, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
      },
    },
  };

  const { data, loading } = useQuery<getAssetLocationTags, getAssetLocationTagsVariables>(GET_ASSET_LOCATION_TAGS, {
    variables,
  });

  if (!data && !loading) return <div />;

  return (
    <SingleTagSelect
      name="assetLocationName"
      search$={search$}
      loading={loading}
      data={data?.assetLocationTags.items}
    />
  );
};

export default AssetLocationForm;
