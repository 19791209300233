import { useMutation } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Form, Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { ImportMicrosoftOffice365InvoicePayload } from '../../schema/graphql-global-types';
import { IMPORT_AZURE_RI_INVOICE } from '../../schema/import';
import { importAzureRIInvoice, importAzureRIInvoiceVariables } from '../../schema/types/importAzureRIInvoice';
import { toastErrorQuery } from '../../utils/error';
import ButtonSubmit from '../form/ButtonSubmit';
import FieldCheckbox from '../form/FieldCheckbox';
import FieldDatePicker from '../form/FieldDatepicker';

const today = new Date();

type Props = {
  storageObjectId: string;
  show: boolean;
  onClose: () => void;
};

const ImportGenerateInvoiceModalAzure: FunctionComponent<Props> = ({ storageObjectId, show, onClose }) => {
  const [mutate] = useMutation<importAzureRIInvoice, importAzureRIInvoiceVariables>(IMPORT_AZURE_RI_INVOICE);

  const model: ImportMicrosoftOffice365InvoicePayload = {
    storageObjectId,
    validFromDate: today,
    send: false,
  };

  return (
    <Modal isOpen={show} className="Modal modal-open" overlayClassName="Overlay" closeTimeoutMS={200}>
      <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                <Trans>Generate Invoice</Trans>
              </h2>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <Formik
              enableReinitialize
              initialValues={model}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const validFromDate = new Date(values.validFromDate);

                  const result: any = await mutate({
                    variables: {
                      payload: {
                        ...values,
                        validFromDate: new Date(
                          Date.UTC(validFromDate.getFullYear(), validFromDate.getMonth(), validFromDate.getDate()),
                        ),
                      },
                    },
                  });

                  if (result.errors) {
                    toastErrorQuery(result.errors);
                  } else {
                    toast.success(i18n._(t`Success!`));
                    setTimeout(() => {
                      onClose();
                    }, 2000);
                  }
                } catch (e) {
                  toastErrorQuery(e);
                  setSubmitting(false);
                }
              }}
            >
              {({ isSubmitting }) => {
                return (
                  <Form>
                    <div className="modal-body">
                      <div className="form-group">
                        <label>
                          <Trans>How would you like to proceed?</Trans>
                        </label>
                      </div>

                      <div className="form-group">
                        <label>
                          <Trans>Set Bookkeeping Date</Trans>
                        </label>

                        <FieldDatePicker name="validFromDate" />
                      </div>

                      <div className="form-group">
                        <FieldCheckbox name="send" label={i18n._(t`Generate and send`)} />
                      </div>
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-link" onClick={onClose}>
                        <Trans>Close</Trans>
                      </button>

                      <ButtonSubmit className="btn btn-primary" title={i18n._(t`Generate`)} loading={isSubmitting} />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImportGenerateInvoiceModalAzure;
