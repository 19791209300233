import { useQuery } from '@apollo/client';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import CreatableSelect from 'react-select/creatable';
import { getUsers, getUsersVariables } from '../../schema/types/getUsers';
import { GET_USERS } from '../../schema/user';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {
  name: string;
};

const ProductContractUserForm: FunctionComponent<Props> = ({ name }) => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getUsers, getUsersVariables>(GET_USERS, {
    variables,
  });

  if (!data && !loading) return <div />;

  return (
    <Field name={name}>
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (values: any) => {
          form.setFieldValue(name, values ? values.map((el: any) => el.value) : []);
        };

        const handleLoad = (value: string) => search$.next(value);

        const model = field.value
          ? field.value.map((el: any) => ({
              value: el,
              label: el,
            }))
          : [];

        const options = loading
          ? []
          : data!.users.items.map((el) => ({
              value: el.email,
              label: el.email,
            }));

        return (
          <CreatableSelect
            value={model}
            options={options}
            isLoading={loading}
            isMulti
            cacheOptions
            defaultOptions
            onChange={handleChange}
            onInputChange={handleLoad}
            placeholder=" "
            {...selectOptions}
          />
        );
      }}
    </Field>
  );
};

export default ProductContractUserForm;
