import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Field, Form } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ContractProductAdjustment } from '../../schema/types/ContractProductAdjustment';
import { ContractReportingProductAdjustment } from '../../schema/types/ContractReportingProductAdjustment';
import { getSession, removeSession } from '../../utils/session';
import ButtonSubmit from '../form/ButtonSubmit';
import ContractActivity from './ContractActivity';
import AdjustmentDatepicker from './ContractAdjustmentDatepicker';
import ContractDetailForm from './ContractDetailForm';
import ContractInfoForm from './ContractInfoForm';
import ContractReportingActivity from './ContractReportingActivity';
import ContractReportingInfoForm from './ContractReportingInfoForm';

type Props = {
  onAdjustmentChange: (date: string) => void;
  loading?: boolean;
  contractActivities: ContractProductAdjustment[];
  reportingActivities: ContractReportingProductAdjustment[];
};

const ContractUpdateForms: FunctionComponent<Props> = ({
  onAdjustmentChange,
  loading,
  contractActivities,
  reportingActivities,
}) => {
  const [notAdded, setNotAdded] = useState(getSession('notAdded'));
  removeSession('notAdded');

  useEffect(() => () => setNotAdded(null), []);

  return (
    <Form>
      <div className="form-breadcrumbs-area">
        <NavLink to="/crm/contract">
          <Trans>Contract</Trans>
        </NavLink>

        <i className="fa fa-angle-left" />

        <Field name="id">
          {({ field }: { field: FieldInputProps<string> }) => {
            if (field.value) return <Trans>Edit contract</Trans>;

            return <Trans>Add new contract</Trans>;
          }}
        </Field>
      </div>

      <div className="table-actions form-table-actions">
        <div className="breadcrumbs-area">
          <Field name="name">
            {({ field }: { field: FieldInputProps<string> }) => {
              return <div className="main-title">{field.value}</div>;
            }}
          </Field>
        </div>

        <ButtonSubmit className="btn btn-primary" title={i18n._(t`Save updates`)} loading={loading} />
      </div>

      <div className="contract-form-page">
        {notAdded && notAdded.length > 0 && (
          <div className="card">
            <div className="form-group text-danger">
              <Trans>The following products are not converted because of their different cycle:</Trans>
            </div>
            <ol>
              {notAdded.map((el: any) => (
                <li key={el.id}>
                  <NavLink to={`/settings/product/${el.id}`} target="_blank">
                    {el.title}
                  </NavLink>
                </li>
              ))}
            </ol>
          </div>
        )}

        <div className="card">
          <div className="card-body">
            <ContractDetailForm />
          </div>
        </div>

        <div className="card">
          <div className="card-header d-flex align-items-center">
            <Trans>Contract</Trans>

            <label className="form-label ml-auto">
              <Trans>Adjustments are made to</Trans>
            </label>

            <div className="ml-3">
              <AdjustmentDatepicker name="adjustmentDate" onChange={onAdjustmentChange} />
            </div>
          </div>

          <div className="card-body">
            <ContractInfoForm />
          </div>

          <hr />

          <div className="card-header">
            <Trans>Last Contract Activities</Trans>
          </div>

          <ContractActivity items={contractActivities} />
        </div>

        <div className="card">
          <div className="card-header">
            <Trans>Reporting</Trans>
          </div>

          <div className="card-body">
            <ContractReportingInfoForm />
          </div>

          <hr />

          <div className="card-header">
            <Trans>Last Reporting Activities</Trans>
          </div>

          <ContractReportingActivity items={reportingActivities} />
        </div>
      </div>
    </Form>
  );
};

export default ContractUpdateForms;
