import { useQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { GET_PERSONS_FOR_COMPANY } from '../../schema/person';
import { getPersons, getPersonsVariables } from '../../schema/types/getPersons';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

type Props = {
  companyId: any;
};

const AssetPersonSearch: FunctionComponent<Props> = ({ companyId }) => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
        companyIds: [companyId],
      },
    },
  };

  const { data, loading } = useQuery<getPersons, getPersonsVariables>(GET_PERSONS_FOR_COMPANY, {
    variables,
  });

  if (!data && !loading) return <div />;

  return (
    <Field name="customerResponsiblePersons">
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (value: any) => {
          const persons: any = cloneDeep(form.values.customerResponsiblePersons);
          persons.push({
            id: value.value,
            fullName: value.label,
          });
          form.setFieldValue('customerResponsiblePersons', persons);

          const ids: any = cloneDeep(form.values.customerResponsiblePersonIds);
          ids.push(value.value);
          form.setFieldValue('customerResponsiblePersonIds', ids);
        };

        const handleLoad = (value: string) => search$.next(value);

        const options = loading
          ? []
          : data!.persons.items.map((el) => ({
              value: el.id,
              label: el.fullName,
            }));

        return (
          <Select
            options={options}
            isLoading={loading}
            onChange={handleChange}
            onInputChange={handleLoad}
            placeholder={i18n._(t`Search...`)}
            {...selectOptions}
          />
        );
      }}
    </Field>
  );
};

export default AssetPersonSearch;
