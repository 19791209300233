import { cloneDeep } from '@apollo/client/utilities';
import { Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import AssetPersonFormItem from './AssetPersonFormItem';
import AssetPersonSearch from './AssetPersonSearch';

type Props = {
  companyId: any;
};

const AssetPersonForm: FunctionComponent<Props> = ({ companyId }) => {
  return (
    <div className="contract-detail-form">
      <label className="form-label">
        <Trans>Customer Responsibility</Trans>
      </label>

      <div className="form-group">
        <AssetPersonSearch companyId={companyId} />
      </div>

      <Field name="customerResponsiblePersons">
        {({ field, form }: { field: any; form: FormikProps<any> }) => {
          const handleRemove = (index: number) => () => {
            const model = cloneDeep(field.value);
            model.splice(index, 1);
            form.setFieldValue('customerResponsiblePersons', model);

            const ids = cloneDeep(form.values.customerResponsiblePersonIds);
            ids.splice(index, 1);
            form.setFieldValue('customerResponsiblePersonIds', ids);
          };

          if (!field.value?.length) return null;

          return (
            <div className="table custom-table">
              {field.value &&
                field.value.map((el: any, index: number) => (
                  <AssetPersonFormItem
                    key={index}
                    name={`customerResponsiblePersons[${index}]`}
                    onRemove={handleRemove(index)}
                  />
                ))}
            </div>
          );
        }}
      </Field>
    </div>
  );
};

export default AssetPersonForm;
