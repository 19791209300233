import { useQuery } from '@apollo/client';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { GET_DOCUMENT_CATEGORIES } from '../../schema/documentCategory';
import { getDocumentCategories, getDocumentCategoriesVariables } from '../../schema/types/getDocumentCategories';
import { selectOptions } from '../../utils/const';
import { useVariables } from '../../utils/hooks';

const DocumentationCategoryForm: FunctionComponent = () => {
  const { search$, search, filter, archived, limit, offset, orderBy, orderDirection } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
      },
    },
  };

  const { data, loading } = useQuery<getDocumentCategories, getDocumentCategoriesVariables>(GET_DOCUMENT_CATEGORIES, {
    variables,
  });

  if (!data && !loading) return <div />;

  return (
    <Field name="category">
      {({ field, form }: { field: any; form: FormikProps<any> }) => {
        const handleChange = (value: any) => {
          form.setFieldValue('categoryId', value.value);
          form.setFieldValue('category', {
            id: value.value,
            name: value.label,
          });
        };

        const handleLoad = (value: string) => search$.next(value);

        const model = field.value
          ? {
              value: field.value.id,
              label: field.value.name,
            }
          : null;

        const options = loading
          ? []
          : data!.documentCategories.items.map((el: any) => ({
              value: el.id,
              label: el.name,
            }));

        return (
          <Select
            value={model}
            options={options}
            isLoading={loading}
            onChange={handleChange}
            onInputChange={handleLoad}
            placeholder={i18n._(t`Choose Category`)}
            {...selectOptions}
          />
        );
      }}
    </Field>
  );
};

export default DocumentationCategoryForm;
