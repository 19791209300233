import { useQuery } from '@apollo/client';
import DateFormat from '../components/common/DateFormat';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import React, { FunctionComponent, useState } from 'react';
import ReactTable, { CellInfo } from 'react-table';
import { NavLink } from 'react-router-dom';
import LoadingTable from '../components/common/LoadingTable';
import MainTitle from '../components/common/MainTitle';
import TableActions from '../components/common/TableActions';
import FilterHeader from '../components/form/FilterHeader';
import logo from '../img/logo_homer_150x40.svg';
import { GET_DOCUMENT_CATEGORIES } from '../schema/documentCategory';
import { GET_PUBLIC_DOCUMENTS } from '../schema/documentPublic';
import { DocumentVisibility, OrderDirection } from '../schema/graphql-global-types';
import { GET_DOCUMENT_TAGS } from '../schema/tag';
import { getPublicDocuments, getPublicDocumentsVariables } from '../schema/types/getPublicDocuments';
import { Tag } from '../schema/types/Tag';
import { dateTimeOptions, tableOptions, tablePollingInterval } from '../utils/const';
import ErrorQuery from '../components/form/ErrorQuery';
import { useVariables } from '../utils/hooks';
import useAuthLayout from '../utils/useAuthLayout';
import { getFilter, getOrder } from '../utils/utils';

const DocumentationPublicPage: FunctionComponent = () => {
  useAuthLayout();
  document.querySelector('body')!.classList.remove('bg-gradient-primary');

  const [loaded, setLoaded] = useState(false);
  const {
    search$,
    search,
    filter$,
    filter,
    archived,
    setArchived,
    limit,
    setLimit,
    offset,
    setOffset,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
  } = useVariables();

  const variables = {
    query: {
      limit,
      offset,
      orderBy,
      orderDirection,
      filter: {
        search,
        archived,
        ...filter,
        template: false,
      },
    },
  };

  const { data, previousData, loading, error } = useQuery<getPublicDocuments, getPublicDocumentsVariables>(
    GET_PUBLIC_DOCUMENTS,
    {
      variables,
      pollInterval: tablePollingInterval,
    },
  );

  if (!loading && !loaded) setLoaded(true);
  if (error) return <ErrorQuery error={error} />;

  const items = data?.publicDocuments.items ?? previousData?.publicDocuments.items ?? [];
  const pages = data && data.publicDocuments ? Math.ceil(data.publicDocuments.total / limit) : -1;

  const columns = [
    {
      Header: i18n._(t`Title`),
      accessor: 'title',
      sortable: true,
      Cell: (cell: CellInfo) => (
        <a href={cell.original.publicUrl} target="_blank">
          {cell.value}
        </a>
      ),
    },
    {
      Header: i18n._(t`Category`),
      accessor: 'category.name',
      filterable: true,
      Filter: (params: any) => (
        <FilterHeader
          query={GET_DOCUMENT_CATEGORIES}
          filters={{
            documentVisibility: DocumentVisibility.Public,
          }}
          objectType="documentCategories"
          nameField="name"
          onChange={params.onChange}
        />
      ),
    },
    {
      Header: i18n._(t`Tags`),
      accessor: 'tags',
      filterable: true,
      Cell: (cell: CellInfo) => (
        <>
          {cell.value.map((el: Tag) => (
            <span key={el.id} className="badge mr-1 mb-1">
              {el.name}
            </span>
          ))}
        </>
      ),
      Filter: (params: any) => (
        <FilterHeader
          query={GET_DOCUMENT_TAGS}
          filters={{
            documentVisibility: DocumentVisibility.Public,
          }}
          objectType="documentTags"
          nameField="name"
          onChange={params.onChange}
        />
      ),
    },
    {
      Header: i18n._(t`Last Edit`),
      accessor: 'updatedAt',
      sortable: true,
      Cell: (cell: CellInfo) => <DateFormat value={new Date(cell.value)} format={dateTimeOptions} />,
    },
  ];

  return (
    <div className="document-page">
      <div className="card-body" style={{ minHeight: '100vh' }}>
        <nav className="container">
          <NavLink to="/public" className="mt-2 mb-5">
            <img alt="..." src={logo} style={{ width: '150px' }} />
          </NavLink>
        </nav>

        <div className="container">
          <div className="table-actions">
            <div className="breadcrumbs-area">
              <MainTitle title={i18n._(t`Public Documentations`)} />
            </div>

            <TableActions onSearch={(e) => search$.next(e.target.value)} archived={archived} onArchive={setArchived} />
          </div>

          {!loaded ? (
            <LoadingTable />
          ) : (
            <ReactTable
              data={items}
              columns={columns}
              pages={pages}
              pageSize={limit}
              manual
              onFetchData={(state) => {
                const f = getFilter(state, {
                  tags: 'tagIds',
                  'category.name': 'categoryIds',
                });

                filter$.next(f);

                let orderDir = OrderDirection.Desc;
                if (state.sorted[0]) {
                  orderDir = state.sorted[0].desc ? OrderDirection.Desc : OrderDirection.Asc;
                }

                setOffset(state.page * state.pageSize);
                setOrderBy(
                  getOrder(state, {
                    'editor.fullName': 'editorName',
                  }),
                );
                setOrderDirection(orderDir);
              }}
              onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
              {...tableOptions()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentationPublicPage;
