import React, { FC, useMemo } from 'react';
import { Person } from '../../schema/types/Person';
import { User } from '../../schema/types/User';
import UserAvatar from './UserAvatar';

type Props = {
  user?: Partial<User | Person>;
  image?: string | null;
  name?: string | null;
};

const UserAvatarName: FC<Props> = ({ user, image, name }) => {
  const fullName = useMemo(() => user?.fullName || name, [name, user]);

  return (
    <div className="user-avatar-name">
      <UserAvatar user={user} image={image} name={fullName} />

      <div className="name">{fullName}</div>
    </div>
  );
};

export default UserAvatarName;
